import { Box, CircularProgress, FormControl, FormHelperText, InputAdornment, InputLabel, MenuItem, Select } from "@bigfish/admin-ui/core";
import React from "react";
import { I18n } from "I18n/I18n";
import { Constants } from "Utils/Constants";

type SelectOptionType = { id: string };

type Props<T extends SelectOptionType> = {
    value: T | null;
    onChange: (value: T | null) => void;
    label?: string;
    error?: boolean;
    required?: boolean;
    name?: string;
    fullWidth?: boolean;
    variant?: "filled" | "outlined" | "standard";
    helperText?: React.ReactNode;
    accessor: keyof T; // key of select option
    fetch: () => Promise<T[]>;
};

type State<T extends SelectOptionType> = {
    data: T[];
    isLoading: boolean;
};

class RemoteSelect<T extends SelectOptionType> extends React.Component<Props<T>, State<T>> {
    private NOT_SELECTED_OPTION: T = {
        id: Constants.nullFormValue,
        [this.props.accessor]: I18n.formatMessage({ id: "components.inputs.remoteSelect.notSelected" }),
    } as T;

    constructor(props: Props<T>) {
        super(props);

        const people: T[] = [this.NOT_SELECTED_OPTION];
        if (this.props.value) {
            people.push(this.props.value);
        }
        this.state = { data: people, isLoading: true };
    }

    public async componentDidMount(): Promise<void> {
        const data = await this.props.fetch();
        this.setState({ data: [this.NOT_SELECTED_OPTION, ...data], isLoading: false });
    }

    private renderMenuItem = (data: T) => {
        return (
            <MenuItem key={data.id} value={data.id}>
                {data[this.props.accessor]}
            </MenuItem>
        );
    };

    private onChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
        const id = event.target.value;
        const selectedItem = this.state.data.find(p => p.id === id);
        this.props.onChange(selectedItem || null);
    };

    public render() {
        return (
            <>
                <Box my="30px" />
                <FormControl variant="outlined" fullWidth>
                    <InputLabel id="category-select">{this.props.label}</InputLabel>
                    <Select
                        labelId="category-select"
                        value={this.props.value?.id || Constants.nullFormValue}
                        onChange={this.onChange}
                        label={this.props.label}
                        error={this.props.error}
                        required={this.props.required}
                        name={this.props.name}
                        fullWidth={this.props.fullWidth}
                        variant={this.props.variant}
                        endAdornment={
                            this.state.isLoading ? (
                                <InputAdornment position="end" style={{ marginRight: 30 }}>
                                    <CircularProgress color="secondary" size={30} />
                                </InputAdornment>
                            ) : null
                        }
                    >
                        {this.state.data.map(this.renderMenuItem)}
                    </Select>
                    <FormHelperText error={this.props.error}>{this.props.helperText}</FormHelperText>
                </FormControl>
            </>
        );
    }
}

export default RemoteSelect;
