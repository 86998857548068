import { gql } from "@apollo/client";

export const badgeListItemFragment = gql`
    fragment BadgeListItem on Badge {
        id
        name
        type
        created_at
        active_from
        is_active
        is_visible
    }
`;
