import React from "react";
import { Box, Typography } from "@bigfish/admin-ui/core";
import { FormikProps } from "formik";
import { PageCard } from "@bigfish/admin-ui/components";
import { I18n } from "I18n/I18n";
import { Product, ProductParamStructure, ProductParamValueInput } from "Api/graphql/admin/types";
import { ProductFormValues } from "./ProductForm";
import ProductPricesDataGrid from "./ProductPricesDataGrid";
import { uniqBy } from "lodash";
import { ObjectUtils } from "Utils/ObjectUtils";
import { ProductParamManualValue } from "./ProductEditPage";

type Props = {
    product: Product;
    productParamStructures: ProductParamStructure[];
    formProps: FormikProps<ProductFormValues>;
};

export const DataTab = (props: Props) => {
    const badges = [
        ...props.product.badges_featured.map(bf => bf.name),
        ...props.product.badges_notifications.map(bn => bn.name),
        ...props.product.badges_product_params.map(bpp => bpp.name),
    ].flat();

    const renderProductParams = () => {
        // We exclude those that are handled manually in the form
        const automaticParams = props.formProps.values.params.filter(p => {
            return !ObjectUtils.enumAsArray<ProductParamManualValue>(ProductParamManualValue)
                .map(ppmv => `${ppmv}`)
                .includes(p.product_param_id);
        });

        const allProductParamIds = automaticParams.map(p => p.product_param_id);
        const paramsGroupsMap = props.productParamStructures
            .filter(pps => allProductParamIds.includes(pps.id))
            // .map(pps => {
            //     return pps.group
            //         ? {
            //               param_id: pps.id,
            //               param_name: pps.name,
            //               group_id: pps.group.id,
            //               group_name: pps.group.name,
            //           }
            //         : null;
            // })
            .filter(Boolean);

        const renderParams = (param: ProductParamValueInput) => {
            return (
                <div key={param.product_param_id}>
                    <Typography color="textSecondary" gutterBottom>
                        {props.productParamStructures.find(pps => pps.id === param.product_param_id)?.name ?? ""}
                    </Typography>
                    <Typography variant="subtitle1">
                        {param.values[0] === "false"
                            ? I18n.formatMessage({ id: "common.no" })
                            : param.values[0] === "true"
                            ? I18n.formatMessage({ id: "common.yes" })
                            : param.values[0]}
                    </Typography>
                    <Box mb="15px" />
                </div>
            );
        };

        const otherParams = automaticParams.filter(p => !paramsGroupsMap.map(pgm => pgm!.id).includes(p.product_param_id)).map(renderParams);

        return (
            <div>
                {uniqBy(paramsGroupsMap, "group_id").forEach(pgm => {
                    return (
                        <div>
                            <PageCard.Heading title={pgm?.group_name} />
                            {automaticParams
                                .filter(p => paramsGroupsMap.find(pgmFound => pgmFound!.id === p.product_param_id && pgmFound!.group_name === pgm!.group_name))
                                .map(renderParams)}
                        </div>
                    );
                })}
                {otherParams.length > 0 && (
                    <div>
                        <PageCard.Heading title={I18n.formatMessage({ id: "pages.product.form.otherParamsHeading" })} />
                        {otherParams}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div>
            <Box mt="30px" />
            <PageCard.Heading title={I18n.formatMessage({ id: "pages.product.form.tabs.data.pricesHeader" })} />
            {props.product.prices && <ProductPricesDataGrid prices={props.product.prices} />}
            <Box mt="30px" />

            {badges.length > 0 && (
                <div>
                    <PageCard.Heading title={I18n.formatMessage({ id: "pages.product.form.badgesHeading" })} />
                    <Typography color="textSecondary" gutterBottom>
                        {I18n.formatMessage({ id: "pages.product.form.badgesLabel" })}
                    </Typography>
                    <Typography variant="subtitle1">{badges.join(", ")}</Typography>
                </div>
            )}
            <Box mt="30px" />
            {renderProductParams()}
        </div>
    );
};
