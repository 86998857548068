import { ActiveBreadcrumbItem } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { BlocksFormValues, PageForm, PageFormValues, pageValidator } from "./PageForm";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { Loading } from "Components/Loading";
import { PageLayout } from "Components/PageLayout";
import { Link } from "Components/Link";
import { Breadcrumbs } from "Components/Breadcrumbs";
import Prompt from "Components/Prompt";
import { Page, Page_blocks, Site } from "Api/graphql/admin/types";
import { Box } from "@bigfish/admin-ui/core";
import { I18n } from "I18n/I18n";
import { ApiUtils } from "Utils/ApiUtils";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import { FunctionConfirmModal } from "Components/FunctionConfirmModal";

type RouteParams = {
    id?: string;
};

type Props = WithSnackbarProps & RouteComponentProps<RouteParams>;

type State = {
    isLoading: boolean;
    page: Page | null;
    selectSites: Site[];
    isDeleteDialogVisible: boolean;
};

class PageEditPage extends React.Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        page: null,
        selectSites: [],
        isDeleteDialogVisible: false,
    };

    public async componentDidMount(): Promise<void> {
        if (!this.props.match.params.id) {
            return;
        }

        try {
            const page = await Api.getPageById(Number.parseInt(this.props.match.params.id, 10));
            const selectSites = await Api.listSites();
            this.setState({ page, selectSites, isLoading: false });
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
            this.props.history.push(Path.pageList);
        }
    }

    private onSubmit = async (values: PageFormValues, formikHelpers: FormikHelpers<PageFormValues>): Promise<boolean> => {
        try {
            const page = await Api.updatePage(this.state.page!.id, ApiUtils.getPageInput(values, FormType.edit));
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.page.onEditSubmit.succeed" }), { variant: "success" });
            this.setState({ page });
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.page.onEditSubmit.error" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
        }
        return false;
    };

    private getBreadcrumb = () => {
        return (
            <Breadcrumbs>
                <Link to={Path.pageList} color="inherit">
                    {I18n.formatMessage({ id: "pages.page.editBreadcrumb" })}
                </Link>
                <ActiveBreadcrumbItem aria-current="page">{this.state.page?.name || ""}</ActiveBreadcrumbItem>
            </Breadcrumbs>
        );
    };

    private getBlocksToInitialValues = (blocks: Page_blocks[]): BlocksFormValues[] => {
        return blocks.map(b => {
            return {
                id: b.id,
                delete: false,
                title: b.__typename === "PageBlockBanner" || b.__typename === "PageBlockProductList" || b.__typename === "PageBlockHtml" ? b.title : "",
                // TODO: https://jira.bigfish.hu/browse/ROS-1841 (not released for prod site yet)
                // type: b.__typename === "PageBlockBanner" || b.__typename === "PageBlockProductList" || b.__typename === "PageBlockHtml" ? b.type : PageBlockType.newsletter,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                type: b.type,
                banners: b.__typename === "PageBlockBanner" ? b.banners : undefined,
                product_list: b.__typename === "PageBlockProductList" ? b.product_list : undefined,
                product_limit: b.__typename === "PageBlockProductList" ? b.product_limit ?? undefined : undefined,
                is_scrollable: b.__typename === "PageBlockProductList" ? b.is_scrollable : false,
                product_sort_by: b.__typename === "PageBlockProductList" ? ApiUtils.getProductSortByFromBackend(b.product_sort_by) : null,
                is_filterable: b.__typename === "PageBlockProductList" ? b.is_filterable : false,
                content: b.__typename === "PageBlockHtml" ? b.content : undefined,
                layout: b.__typename === "PageBlockBanner" ? b.layout : undefined,
            };
        });
    };

    private onDeleteButtonClick = (): void => {
        this.setState({ isDeleteDialogVisible: true });
    };

    private onDeleteDialogClose = (): void => {
        this.setState({ isDeleteDialogVisible: false });
    };

    private onDeleteClick = async (): Promise<void> => {
        try {
            await Api.deletePage(this.state.page!.id);
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.page.edit.deleteSucceed" }), { variant: "success" });
            this.setState({ page: null });
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.page.edit.deleteFailed" }), { variant: "error" });
        }
    };

    public render() {
        const { isLoading, page, selectSites } = this.state;

        if (isLoading) {
            return <Loading breadcrumb={this.getBreadcrumb()} />;
        }

        if (!page) {
            return <Redirect to={Path.pageList} />;
        }

        const initialValues: PageFormValues = {
            name: page.name,
            title: page.title,
            url: page.url,
            is_active: page.is_active,
            active_from: page.active_from ? DateUtils.format(DateUtils.parseISO(page.active_from), DateFormat.input) : "",
            active_to: page.active_to ? DateUtils.format(DateUtils.parseISO(page.active_to), DateFormat.input) : "",
            meta_title: page.meta_title,
            meta_description: page.meta_description,
            og_title: page.og_title,
            og_description: page.og_description,
            og_image: page.og_image,
            blocks: this.getBlocksToInitialValues(page.blocks),
            is_header: page.is_header,
            is_footer: page.is_footer,
            site_id: page.site?.id ?? null,
        };

        return (
            <PageLayout breadcrumb={this.getBreadcrumb()}>
                <Formik initialValues={initialValues} onSubmit={this.onSubmit} validate={pageValidator(FormType.edit)} validateOnBlur={false} enableReinitialize={true}>
                    {props => (
                        <>
                            <PageForm formType={FormType.edit} formProps={props} page={page} selectSites={selectSites} onDeleteClick={this.onDeleteButtonClick} />
                            <Prompt when={props.dirty} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props)} />
                        </>
                    )}
                </Formik>
                <FunctionConfirmModal
                    title={I18n.formatMessage({ id: "pages.page.edit.deleteModal.title" })}
                    description={I18n.formatMessage({ id: "pages.page.edit.deleteModal.description" })}
                    isVisible={this.state.isDeleteDialogVisible}
                    onClose={this.onDeleteDialogClose}
                    onFunctionClick={this.onDeleteClick}
                    leftButtonLabel={I18n.formatMessage({ id: "common.cancel" })}
                    rightButtonLabel={I18n.formatMessage({ id: "common.delete" })}
                />
                <Box mt="40px" />
            </PageLayout>
        );
    }
}

export default withSnackbar(withRouter(PageEditPage));
