/* eslint-disable @typescript-eslint/no-explicit-any */
import { DateRangeFilter, OrderDirection } from "Api/graphql/admin/types";
import * as qs from "query-string";
import { ObjectUtils } from "Utils/ObjectUtils";
import { IntervalFilterValue } from "./DataGrid";
import { DataGridUtils } from "./DataGridUtils";

export interface ParsedUrlQueryParams {
    [key: string]: string | string[] | number[] | null | undefined;
}

export type Sortable = {
    direction: OrderDirection;
    field: string | any;
};

export type Filterable = {
    [key: string]: any | null | undefined;
};

export type DataGridParams<SortOrder extends Sortable, Filter extends Filterable> = {
    limit: number;
    page: number;
    sortBy?: SortOrder;
    filters?: Filter;
};

export type UrlQueryObjectValue = string[] | string | number | boolean | null | undefined;

export type UrlQueryObject = { [key: string]: UrlQueryObjectValue };

export abstract class UrlQueryParser<SortOrder extends Sortable, Filter extends Filterable> {
    private parseQuery(queryParams: string): ParsedUrlQueryParams {
        return qs.parse(queryParams);
    }

    protected abstract getParams(parsedParams: ParsedUrlQueryParams): { sortBy?: SortOrder; filters?: Filter };

    public parse(queryParams: string): DataGridParams<SortOrder, Filter> {
        const parsedParams: ParsedUrlQueryParams = this.parseQuery(queryParams);
        return {
            ...this.getParams(parsedParams),
            limit: UrlQueryParser.getInteger(parsedParams, "limit") || DataGridUtils.DEFAULT_LIMIT,
            page: UrlQueryParser.getInteger(parsedParams, "page") || 1,
        };
    }

    protected abstract getUrlQueryObject(options: DataGridParams<SortOrder, Filter>): UrlQueryObject;

    public getUrlQueryString(options: DataGridParams<SortOrder, Filter>): string {
        const urlQueryObject = this.getUrlQueryObject(options);
        const params = Object.keys(urlQueryObject).reduce((prevState: string[], key: string): string[] => {
            const value = urlQueryObject[key];
            if (value === null || value === undefined || value === "") {
                return prevState;
            }
            prevState.push(`${key}=${value}`);
            return prevState;
        }, []);

        return encodeURI(params.join("&"));
    }

    public static getString(params: ParsedUrlQueryParams, key: string): string | undefined {
        const value = params[key];
        return typeof value === "string" && !!value ? value : undefined;
    }

    public static getInteger(params: ParsedUrlQueryParams, key: string): number | undefined {
        const value = params[key];
        if (value !== null && value !== undefined && !Array.isArray(value)) {
            const integerValue = Number.parseInt(value, 10);
            return Number.isInteger(integerValue) ? integerValue : undefined;
        }
        return undefined;
    }

    public static getFloat(params: ParsedUrlQueryParams, key: string): number | undefined {
        const value = params[key];
        if (value !== null && value !== undefined && !Array.isArray(value)) {
            const floatValue = Number.parseFloat(value);
            return typeof floatValue !== "undefined" && Number.isNaN(floatValue) ? undefined : floatValue;
        }
        return undefined;
    }

    public static getBoolean(params: ParsedUrlQueryParams, key: string): boolean | undefined {
        const value = params[key];
        if (value === "true") {
            return true;
        }
        if (value === "false") {
            return false;
        }
        return undefined;
    }

    public static getStringArray(params: ParsedUrlQueryParams, key: string): string[] | undefined {
        const value = params[key];
        const returned = Array.isArray(value) ? (value as number[]).map(v => `${v}`) : undefined;
        return returned;
    }

    public static getEnum<T>(params: ParsedUrlQueryParams, key: string, enumObject?: Object): T | undefined {
        const value = UrlQueryParser.getString(params, key);
        if (value && enumObject && ObjectUtils.isEnumContains(enumObject, value)) {
            return (value as unknown) as T;
        }
        return undefined;
    }

    public static getDateRangeFilter(params: ParsedUrlQueryParams, key: string): DateRangeFilter | undefined {
        try {
            const filterValue = JSON.parse(decodeURIComponent(UrlQueryParser.getString(params, key) ?? ""));
            return { from: filterValue.from || undefined, to: filterValue.to || undefined };
        } catch {
            return undefined;
        }
    }

    public static getIntegerRangeFilter(params: ParsedUrlQueryParams, key: string): IntervalFilterValue | undefined {
        try {
            const filterValue = JSON.parse(decodeURIComponent(UrlQueryParser.getString(params, key) ?? ""));
            return { start: filterValue.start || undefined, end: filterValue.end || undefined };
        } catch {
            return undefined;
        }
    }
}
