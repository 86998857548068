import { gql } from "@apollo/client";

export const listGiftCardsExportQuery = gql`
    query listGiftCardsExport($filters: GiftCardOrderFilters, $sortBy: GiftCardOrderSort) {
        listGiftCardsExport(filters: $filters, sortBy: $sortBy) {
            url
            send_in_email
        }
    }
`;
