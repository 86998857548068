import React from "react";
import { UploadedImage } from "@bigfish/admin-ui/components";
import { Box, Grid, Typography } from "@bigfish/admin-ui/core";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { GalleryImage } from "./GalleryForm";
import { MediaLibraryHelper } from "Utils/MediaLibraryHelper";
import { I18n } from "I18n/I18n";

type SortableUploadedImageProps = {
    caption: string | null;
    url: string;
    style?: React.CSSProperties;
};

const SortableUploadedImage = SortableElement(({ url, style }: SortableUploadedImageProps) => {
    return (
        <Grid item style={style}>
            <UploadedImage name={url} source={MediaLibraryHelper.getImageUrl(url)} />
        </Grid>
    );
});

type Props = {
    value: GalleryImage[];
    itemStyle?: React.CSSProperties;
};

class GallerySortList extends React.Component<Props> {
    render() {
        return (
            <Box p="30px 0 0">
                <Grid container spacing={3}>
                    {this.props.value.length === 0 ? (
                        <Box m="auto" height="100px">
                            <Typography>{I18n.formatMessage({ id: "pages.gallery.form.sort.emptyList" })}</Typography>
                        </Box>
                    ) : (
                        this.props.value.map((image: GalleryImage, index: number) => (
                            <SortableUploadedImage key={image.url + index} caption={image.caption} url={image.url} index={index} style={this.props.itemStyle} />
                        ))
                    )}
                </Grid>
            </Box>
        );
    }
}

export default SortableContainer(GallerySortList);
