import React from "react";
import { TypeFilterValue, TypeRowProps } from "@inovua/reactdatagrid-community/types";
import { IconButton, withStyles, WithStyles } from "@bigfish/admin-ui/core";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import { I18n } from "I18n/I18n";
import { dataGridStyles } from "@bigfish/admin-ui/styles";
import UnControlledDataGrid from "Components/DataGrid/UCDataGrid";
import { CustomTypeColumn, DataGridUtils } from "Utils/DataGridUtils";
import { Constants } from "Utils/Constants";
import { BadgeListItem, BadgeType } from "Api/graphql/admin/types";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import { Close } from "@bigfish/admin-ui/icons";
import { DataSource } from "Components/DataGrid/DataGridUtils";
import { ListItemActive } from "Components/ListItemActive";
import { ObjectUtils } from "Utils/ObjectUtils";

type Props = { onRemoveClick(badgeId: number): void } & WithStyles<typeof dataGridStyles> & {
        badges: BadgeListItem[];
        onRowClick: (id: number) => void;
    };

interface State {
    dataSource: () => Promise<DataSource<BadgeListItem>>;
    filterValues: TypeFilterValue;
}

class ProductListBadgeListDataGrid extends React.Component<Props, State> {
    private getColumns = (): CustomTypeColumn[] => {
        return [
            { name: "id", header: I18n.formatMessage({ id: "pages.badgeList.grid.column.id" }), defaultWidth: 100, sortable: false, filterDelay: Constants.filterDelayMS },
            {
                name: "name",
                header: I18n.formatMessage({ id: "pages.badgeList.grid.column.name" }),
                defaultFlex: 1,
                minWidth: 170,
                sortable: false,
                filterDelay: Constants.filterDelayMS,
            },
            {
                name: "type",
                defaultFlex: 1,
                minWidth: 170,
                header: I18n.formatMessage({ id: "pages.badgeList.grid.column.type" }),
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: ObjectUtils.enumAsArray<BadgeType>(BadgeType)
                        .map((type: BadgeType) => {
                            return { id: type, label: I18n.formatMessage({ id: `enums.badgeType.${type}` }) };
                        })
                        .sort((a, b) => a.label.localeCompare(b.label)),
                },
                render: ({ data }: { data: BadgeListItem }) => {
                    return <div>{I18n.formatMessage({ id: `enums.badgeType.${data.type}` })}</div>;
                },
                sortable: false,
            },
            {
                name: "created_at",
                filterEditor: DateFilter,
                header: I18n.formatMessage({ id: "pages.badgeList.grid.column.created_at" }),
                defaultWidth: 170,
                render: ({ data }: { data: BadgeListItem }) => {
                    return <div>{data.created_at ? DateUtils.format(data.created_at, DateFormat.minuteDateTime) : ""}</div>;
                },
                sortable: false,
            },
            {
                name: "is_visible",
                textAlign: "center",
                header: I18n.formatMessage({ id: "pages.badgeList.grid.column.is_visible" }),
                defaultWidth: Constants.columnWidth.statusIcon,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: [
                        { id: "true", label: I18n.formatMessage({ id: "common.form.isActive.true" }).toLowerCase() },
                        { id: "false", label: I18n.formatMessage({ id: "common.form.isActive.false" }).toLowerCase() },
                    ],
                },
                render: ({ data }: { data: BadgeListItem }) => <ListItemActive isActive={data.is_visible} fromDate={data.active_from} />,
                sortable: false,
            },
            {
                name: "functions",
                defaultWidth: 95,
                header: "",
                textAlign: "center",
                sortable: false,
                render: ({ data }: { data: BadgeListItem }) => {
                    return (
                        <IconButton className="table-delete-icon" onClick={() => this.props.onRemoveClick(data.id)}>
                            <Close />
                        </IconButton>
                    );
                },
            },
        ];
    };

    private getDataSource = () => async (): Promise<DataSource<BadgeListItem>> => {
        return { data: this.props.badges.filter(badge => this.state.filterValues?.every(DataGridUtils.filter(badge))), count: this.props.badges.length };
    };

    state: State = {
        dataSource: this.getDataSource(),
        filterValues: [],
    };

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevProps.badges !== this.props.badges || this.state.filterValues !== prevState.filterValues) {
            this.setState({ dataSource: this.getDataSource() });
        }
    }

    render() {
        return (
            <UnControlledDataGrid<BadgeListItem>
                className={this.props.classes.dataGrid}
                rowHeight={50}
                style={{ minHeight: 86 + this.props.badges.length * 50 }}
                dataSource={this.state.dataSource}
                columns={this.getColumns()}
                activeCell={null}
                onRowClick={(rowProps: TypeRowProps) => this.props.onRowClick(rowProps.data.id)}
            />
        );
    }
}

export default withStyles(dataGridStyles, { withTheme: true })(ProductListBadgeListDataGrid);
