import React, { useCallback } from "react";
import { Form, FormType } from "Components/Form";
import { Button, FormControl, Grid, Icon, TextField, Box, InputAdornment, FormControlLabel, Typography } from "@bigfish/admin-ui/core";
import { Field, FieldProps, FormikErrors, FormikProps } from "formik";
import { Campaign, CampaignInput } from "Api/graphql/admin/types";
import { I18n } from "Src/i18n/I18n";
import { FullscreenLoader, PageCard, SavePanel } from "@bigfish/admin-ui/components";
import { Path } from "Utils/Path";
import { useHistory } from "react-router-dom";
import { I18nHelpers } from "I18n/I18nHelpers";
import { Validator } from "Utils/Validator";
import { StatisticsPaper } from "Components/StatisticsPaper";
import { CustomFormHelperText } from "Components/CustomFormHelperText";
import { EventBusy } from "@bigfish/admin-ui/icons";
import { Checkbox } from "@bigfish/admin-ui/core";
import MediaLibraryImageInput from "Components/Inputs/MediaLibraryImageInput";

export type CampaignFormValues = CampaignInput;

type Props = {
    formType: FormType;
    formProps: FormikProps<CampaignFormValues>;
    campaign?: Campaign;
};

export const campaignValidator = (values: CampaignFormValues): FormikErrors<CampaignFormValues> => {
    const errors: { [key in keyof CampaignFormValues]?: any } = {};

    if (values.active_from && values.active_to) {
        errors.active_to = Validator.endDateCantBeBeforeStart(values.active_from, values.active_to);
        errors.active_from = errors.active_from || Validator.startDateCantBeAfterEnd(values.active_from, values.active_to);
    }

    return Form.cleanupFormikErrors(errors);
};

export const CampaignForm = (props: Props) => {
    const history = useHistory();
    const onCancelClick = useCallback(() => {
        history.push(Path.campaignList);
    }, [history]);

    return (
        <Form formProps={props.formProps}>
            <PageCard.Container>
                <PageCard.Heading title={I18n.formatMessage({ id: "common.form.content" })} />

                <Box mt="30px" />

                <Field name="name" validate={I18nHelpers.formatValidator(Validator.required)}>
                    {({ field, meta }: FieldProps) => (
                        <TextField
                            type="text"
                            label={I18n.formatMessage({ id: "pages.campaign.form.nameLabel" })}
                            fullWidth
                            variant="outlined"
                            required
                            helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                            error={meta.touched && !!meta.error}
                            {...field}
                        />
                    )}
                </Field>

                <Box mt="30px">
                    <Field name="description" validate={I18nHelpers.formatValidator(Validator.required)}>
                        {({ field, meta }: FieldProps) => (
                            <TextField
                                type="text"
                                label={I18n.formatMessage({ id: "pages.campaign.form.descriptionLabel" })}
                                multiline
                                fullWidth
                                variant="outlined"
                                error={meta.touched && !!meta.error}
                                helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                                {...field}
                            />
                        )}
                    </Field>
                </Box>

                <Box mt="30px" />

                <Field name="target_url">
                    {({ field, meta }: FieldProps) => (
                        <TextField
                            type="text"
                            label={I18n.formatMessage({ id: "pages.campaign.form.targetUrlLabel" })}
                            fullWidth
                            variant="outlined"
                            required
                            helperText={Form.getHelperText(meta, "")}
                            error={meta.touched && !!meta.error}
                            {...field}
                        />
                    )}
                </Field>

                <Box mt="30px" />

                <Field name="target_title">
                    {({ field, meta }: FieldProps) => (
                        <TextField
                            type="text"
                            label={I18n.formatMessage({ id: "pages.campaign.form.targetTitleLabel" })}
                            fullWidth
                            variant="outlined"
                            required
                            helperText={Form.getHelperText(meta, "")}
                            error={meta.touched && !!meta.error}
                            {...field}
                        />
                    )}
                </Field>

                <Box mt="30px" />

                <PageCard.Heading title={I18n.formatMessage({ id: "pages.campaign.form.images" })} />

                <Box mt="30px" />

                <Grid container>
                    <Grid item>
                        <Box mb="30px">
                            <Typography variant="body1" component="h3" color="textSecondary">
                                {I18n.formatMessage({ id: "pages.campaign.imageForegroundDesktopLabel" })}
                            </Typography>

                            <Field name="image_foreground_desktop">
                                {({ field, meta }: FieldProps) => (
                                    <div>
                                        <MediaLibraryImageInput
                                            id="campaign-image_foreground_desktop"
                                            name="image_foreground_desktop"
                                            value={field.value}
                                            onChange={(value: string | null) => {
                                                props.formProps.setFieldValue("image_foreground_desktop", value);
                                                props.formProps.setFieldTouched("image_foreground_desktop", true);
                                            }}
                                        />
                                        <CustomFormHelperText meta={meta} />
                                    </div>
                                )}
                            </Field>
                        </Box>
                    </Grid>

                    {/* TODO: Temporarily removed: https://bigfishhu.atlassian.net/browse/ROS-2442 */}
                    {/* <Grid item>
                        <Box mb="30px">
                            <Typography variant="body1" component="h3" color="textSecondary">
                                {I18n.formatMessage({ id: "pages.campaign.imageBackgroundDesktopLabel" })}
                            </Typography>

                            <Field name="image_background_desktop">
                                {({ field, meta }: FieldProps) => (
                                    <div>
                                        <MediaLibraryImageInput
                                            id="campaign-image_background_desktop"
                                            name="image_background_desktop"
                                            value={field.value}
                                            onChange={(value: string | null) => {
                                                props.formProps.setFieldValue("image_background_desktop", value);
                                                props.formProps.setFieldTouched("image_background_desktop", true);
                                            }}
                                        />
                                        <CustomFormHelperText meta={meta} />
                                    </div>
                                )}
                            </Field>
                        </Box>
                    </Grid> */}

                    <Grid item>
                        <Box mb="30px">
                            <Typography variant="body1" component="h3" color="textSecondary">
                                {I18n.formatMessage({ id: "pages.campaign.imageForegroundMobileLabel" })}
                            </Typography>

                            <Field name="image_foreground_mobile">
                                {({ field, meta }: FieldProps) => (
                                    <div>
                                        <MediaLibraryImageInput
                                            id="campaign-image_foreground_mobile"
                                            name="image_foreground_mobile"
                                            value={field.value}
                                            onChange={(value: string | null) => {
                                                props.formProps.setFieldValue("image_foreground_mobile", value);
                                                props.formProps.setFieldTouched("image_foreground_mobile", true);
                                            }}
                                        />
                                        <CustomFormHelperText meta={meta} />
                                    </div>
                                )}
                            </Field>
                        </Box>
                    </Grid>

                    {/* TODO: Temporarily removed: https://bigfishhu.atlassian.net/browse/ROS-2442 */}
                    {/* <Grid item>
                        <Box mb="30px">
                            <Typography variant="body1" component="h3" color="textSecondary">
                                {I18n.formatMessage({ id: "pages.campaign.imageBackgroundMobileLabel" })}
                            </Typography>

                            <Field name="image_background_mobile">
                                {({ field, meta }: FieldProps) => (
                                    <div>
                                        <MediaLibraryImageInput
                                            id="campaign-image_background_mobile"
                                            name="image_background_mobile"
                                            value={field.value}
                                            onChange={(value: string | null) => {
                                                props.formProps.setFieldValue("image_background_mobile", value);
                                                props.formProps.setFieldTouched("image_background_mobile", true);
                                            }}
                                        />
                                        <CustomFormHelperText meta={meta} />
                                    </div>
                                )}
                            </Field>
                        </Box>
                    </Grid> */}
                </Grid>

                <Box mt="30px" />

                <PageCard.Heading title={I18n.formatMessage({ id: "common.form.settings" })} />

                <Grid container>
                    <Grid item>
                        <Box mr="30px" mb="30px">
                            <Field name="active_from" validate={I18nHelpers.formatValidator(Validator.required)}>
                                {({ field, meta }: FieldProps) => (
                                    <TextField
                                        id="active_from"
                                        label={I18n.formatMessage({ id: "pages.campaign.form.activeFromLabel" })}
                                        type="datetime-local"
                                        className={`datetime-local ${props.formProps.values.active_from ? "datetime-local-filled" : ""}`}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            endAdornment: props.formProps.values.active_from ? (
                                                <InputAdornment
                                                    position="end"
                                                    onClick={() => {
                                                        props.formProps.setFieldValue("active_from", "");
                                                        props.formProps.setFieldTouched("active_from", true);
                                                    }}
                                                >
                                                    <EventBusy />
                                                </InputAdornment>
                                            ) : null,
                                        }}
                                        {...field}
                                        value={field.value}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            props.formProps.setTouched({ ...props.formProps.touched, active_from: true });
                                            field.onChange(e);
                                        }}
                                        error={meta.touched && !!meta.error}
                                        helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                                    />
                                )}
                            </Field>
                        </Box>
                    </Grid>

                    <Grid item>
                        <Field name="active_to" validate={I18nHelpers.formatValidator(Validator.required)}>
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    id="active_to"
                                    label={I18n.formatMessage({ id: "pages.campaign.form.activeToLabel" })}
                                    className={`datetime-local ${props.formProps.values.active_to ? "datetime-local-filled" : ""}`}
                                    type="datetime-local"
                                    placeholder=""
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        endAdornment: props.formProps.values.active_to ? (
                                            <InputAdornment
                                                position="end"
                                                onClick={() => {
                                                    props.formProps.setFieldValue("active_to", "");
                                                    props.formProps.setFieldTouched("active_to", true);
                                                }}
                                            >
                                                <EventBusy />
                                            </InputAdornment>
                                        ) : null,
                                    }}
                                    {...field}
                                    value={field.value}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        props.formProps.setTouched({ ...props.formProps.touched, active_to: true });
                                        field.onChange(e);
                                    }}
                                    error={meta.touched && !!meta.error}
                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                                />
                            )}
                        </Field>
                    </Grid>
                </Grid>

                <Field name="is_active">
                    {({ field, meta }: FieldProps) =>
                        field.value !== undefined && (
                            <Box mt="15px" ml="10px">
                                <FormControl error={meta.touched && !!meta.error}>
                                    <FormControlLabel
                                        control={<Checkbox {...field} checked={field.value} />}
                                        label={I18n.formatMessage({ id: "pages.campaign.form.isActiveLabel" })}
                                    />
                                </FormControl>
                                <CustomFormHelperText meta={meta} />
                            </Box>
                        )
                    }
                </Field>
            </PageCard.Container>

            {props.formType === FormType.edit && props.campaign && (
                <StatisticsPaper
                    createdAt={props.campaign.created_at}
                    createdBy={props.campaign.created_by?.name}
                    updatedAt={props.campaign.updated_at}
                    updatedBy={props.campaign.updated_by?.name}
                />
            )}

            <SavePanel>
                <Grid container justify="space-between">
                    <Button variant="outlined" color="primary" onClick={onCancelClick}>
                        {I18n.formatMessage({ id: "common.cancel" })}
                    </Button>
                    <Button
                        type="submit"
                        startIcon={<Icon className="fas fa-save" />}
                        variant="contained"
                        color="secondary"
                        disabled={!props.formProps.dirty || !props.formProps.isValid}
                    >
                        {I18n.formatMessage({ id: `common.${props.formType === FormType.create ? "create" : "save"}` })}
                    </Button>
                </Grid>
            </SavePanel>
            <FullscreenLoader visible={props.formProps.isSubmitting} />
        </Form>
    );
};
