import { gql } from "@apollo/client";
import { siteFragment } from "./siteFragment";

export const redirectionFragment = gql`
    fragment Redirection on Redirection {
        id
        from_site {
            ...Site
        }
        from
        to_site {
            ...Site
        }
        to
        active_from
        active_to
        is_active
        is_permanent
        is_regex
        forward
        created_at
        updated_at
    }
    ${siteFragment}
`;
