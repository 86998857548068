import React from "react";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { Box, Grid, Typography, withStyles, WithStyles } from "@bigfish/admin-ui/core";
import { dataGridStyles } from "@bigfish/admin-ui/styles";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import { OrderRatingFilters, RatingListItem, OrderRatingSort, OrderRatingSortField } from "Api/graphql/admin/types";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import DataGrid from "Components/DataGrid/DataGrid";
import { DataGridUrlQueryParser, QueryParserType } from "Components/DataGrid/DataGridUrlQueryParser";
import { DataSource, FilterValue } from "Components/DataGrid/DataGridUtils";
import { DataGridParams } from "Components/DataGrid/UrlQueryParser";
import { I18n } from "I18n/I18n";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import { Constants } from "Utils/Constants";
import { CustomTypeColumn } from "Utils/DataGridUtils";
import { PageCard } from "@bigfish/admin-ui/components";

type Props = WithSnackbarProps & WithStyles<typeof dataGridStyles>;

type State = {
    aggregations: {
        avg_rating_purchase: number | null;
        avg_rating_courier: number | null;
        avg_rating_recommend: number | null;
    };
};

class RatingListDataGrid extends React.Component<Props, State> {
    public readonly state: State = {
        aggregations: {
            avg_rating_purchase: null,
            avg_rating_courier: null,
            avg_rating_recommend: null,
        },
    };

    private getColumns = (): CustomTypeColumn[] => {
        return [
            { name: "id", header: I18n.formatMessage({ id: "pages.ratingList.grid.column.id" }), defaultWidth: 200, filterDelay: Constants.filterDelayMS },
            {
                name: "order_increment_id",
                header: I18n.formatMessage({ id: "pages.ratingList.grid.column.order_increment_id" }),
                defaultFlex: 1,
                minWidth: 300,
                filterDelay: Constants.filterDelayMS,
            },
            { name: "rating_courier", header: I18n.formatMessage({ id: "pages.ratingList.grid.column.rating_courier" }), defaultWidth: 200, filterEditor: NumberFilter },
            { name: "note_courier", header: I18n.formatMessage({ id: "pages.ratingList.grid.column.note_courier" }), defaultWidth: 200, sortable: false },
            { name: "rating_purchase", header: I18n.formatMessage({ id: "pages.ratingList.grid.column.rating_purchase" }), defaultWidth: 200, filterEditor: NumberFilter },
            { name: "note_purchase", header: I18n.formatMessage({ id: "pages.ratingList.grid.column.note_purchase" }), defaultWidth: 200, sortable: false },
            { name: "rating_recommend", header: I18n.formatMessage({ id: "pages.ratingList.grid.column.rating_recommend" }), defaultWidth: 200, filterEditor: NumberFilter },
            {
                name: "created_at",
                width: 300,
                header: I18n.formatMessage({ id: "pages.ratingList.grid.column.created_at" }),
                filterEditor: DateFilter,
                dateFormat: "YYYY-MM-DD",
            },
            {
                name: "updated_at",
                width: 300,
                header: I18n.formatMessage({ id: "pages.ratingList.grid.column.updated_at" }),
                filterEditor: DateFilter,
                dateFormat: "YYYY-MM-DD",
            },
        ];
    };

    private dataSource = async (props: DataGridParams<OrderRatingSort, OrderRatingFilters>): Promise<DataSource<RatingListItem>> => {
        try {
            const result = await Api.listRatings({
                first: props.limit,
                page: props.page,
                filters: {
                    ...props.filters,
                    rating_courier: props.filters?.rating_courier
                        ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          { min: Number.parseInt(props.filters.rating_courier.start, 10), max: Number.parseInt(props.filters.rating_courier.end, 10) }
                        : undefined,
                    rating_purchase: props.filters?.rating_purchase
                        ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          { min: Number.parseInt(props.filters.rating_purchase.start, 10), max: Number.parseInt(props.filters.rating_purchase.end, 10) }
                        : undefined,
                    rating_recommend: props.filters?.rating_recommend
                        ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          { min: Number.parseInt(props.filters.rating_recommend.start, 10), max: Number.parseInt(props.filters.rating_recommend.end, 10) }
                        : undefined,
                },
                sortBy: props.sortBy,
            });

            this.setState({
                aggregations: {
                    avg_rating_purchase: result.aggregations.avg_rating_purchase,
                    avg_rating_courier: result.aggregations.avg_rating_courier,
                    avg_rating_recommend: result.aggregations.avg_rating_recommend,
                },
            });

            return { data: result.data, count: result.paginatorInfo.total };
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }
        return { data: [], count: 0 };
    };

    private urlQueryParser = new DataGridUrlQueryParser({
        filters: {
            id: QueryParserType.integer,
            order_increment_id: QueryParserType.integer,
            rating_purchase: QueryParserType.integerRange,
            rating_courier: QueryParserType.integerRange,
            rating_recommend: QueryParserType.integerRange,
            created_at: QueryParserType.dateRange,
            updated_at: QueryParserType.dateRange,
        },
        sortField: OrderRatingSortField,
    });

    private sortFieldToSortOrder = {
        [OrderRatingSortField.id]: "id",
        [OrderRatingSortField.order_increment_id]: "order_increment_id",
        [OrderRatingSortField.rating_purchase]: "rating_purchase",
        [OrderRatingSortField.rating_courier]: "rating_courier",
        [OrderRatingSortField.rating_recommend]: "rating_recommend",
        [OrderRatingSortField.created_at]: "created_at",
        [OrderRatingSortField.updated_at]: "updated_at",
    };

    private filterValues: FilterValue[] = [
        { name: "id", operator: "eq", type: "number" },
        { name: "order_increment_id", operator: "eq", type: "number" },
        { name: "rating_purchase", operator: "inrange", type: "number" },
        { name: "rating_courier", operator: "inrange", type: "number" },
        { name: "rating_recommend", operator: "inrange", type: "number" },
        { name: "created_at", operator: "inrange", type: "date" },
        { name: "updated_at", operator: "inrange", type: "date" },
    ];

    render() {
        const { avg_rating_courier, avg_rating_purchase, avg_rating_recommend } = this.state.aggregations;

        return (
            <div>
                <PageCard.Container>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <Typography color="textSecondary" gutterBottom>
                                {I18n.formatMessage({ id: "pages.ratingList.avgLabel.avg_rating_courier" })}
                            </Typography>
                            {avg_rating_courier !== null ? <Typography variant="body2">{avg_rating_courier.toFixed(2)}</Typography> : "-"}
                        </Grid>
                        <Grid item xs={4}>
                            <Typography color="textSecondary" gutterBottom>
                                {I18n.formatMessage({ id: "pages.ratingList.avgLabel.avg_rating_purchase" })}
                            </Typography>
                            {avg_rating_purchase !== null ? <Typography variant="body2">{avg_rating_purchase.toFixed(2)}</Typography> : "-"}
                        </Grid>
                        <Grid item xs={4}>
                            <Typography color="textSecondary" gutterBottom>
                                {I18n.formatMessage({ id: "pages.ratingList.avgLabel.avg_rating_recommend" })}
                            </Typography>
                            {avg_rating_recommend !== null ? <Typography variant="body2">{avg_rating_recommend.toFixed(2)}</Typography> : "-"}
                        </Grid>
                    </Grid>
                </PageCard.Container>

                <Box mt="30px" />

                <PageCard.Container>
                    <DataGrid
                        className={this.props.classes.dataGrid}
                        urlQueryParser={this.urlQueryParser}
                        rowHeight={50}
                        style={{ minHeight: 800 }}
                        dataSource={this.dataSource}
                        filterValues={this.filterValues}
                        columns={this.getColumns()}
                        activeCell={null}
                        rowLink={undefined}
                        rowStyle={{ cursor: "auto" }}
                        sortFieldToSortOrder={this.sortFieldToSortOrder}
                    />
                </PageCard.Container>
            </div>
        );
    }
}

export default withSnackbar(withStyles(dataGridStyles, { withTheme: true })(RatingListDataGrid));
