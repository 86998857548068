import { gql } from "@apollo/client";

export const productVariantListItemFragment = gql`
    fragment ProductVariantListItem on ProductSimple {
        id
        lfdnr
        name
        main_categories {
            id
            name
        }
        categories {
            id
            name
        }
        is_visible
        created_at
        active_from
    }
`;
