import { gql } from "@apollo/client";
import { adminFragment } from "../fragments/adminFragment";

export const adminLoginMutation = gql`
    mutation adminLogin($credentials: Login!) {
        login(credentials: $credentials) {
            token
            admin {
                ...Admin
            }
            media_library_token
        }
    }
    ${adminFragment}
`;
