import { Box, Typography } from "@bigfish/admin-ui/core";
import { ActiveBreadcrumbItem } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { CampaignForm, CampaignFormValues, campaignValidator } from "./CampaignForm";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { PageLayout } from "Components/PageLayout";
import { Link } from "Components/Link";
import { Breadcrumbs } from "Components/Breadcrumbs";
import Prompt from "Components/Prompt";
import { I18n } from "I18n/I18n";
import { DateFormat, DateUtils } from "Utils/DateUtils";

type Props = RouteComponentProps & WithSnackbarProps;

type State = {
    isCreateSucceed: boolean;
};

class CampaignCreatePage extends React.Component<Props, State> {
    public readonly state: State = {
        isCreateSucceed: false,
    };

    private onSubmit = async (values: CampaignFormValues, formikHelpers: FormikHelpers<CampaignFormValues>, isRedirectHandled?: boolean): Promise<boolean> => {
        try {
            const campaignId = await Api.createCampaign({
                ...values,
                active_from: values.active_from ? DateUtils.format(values.active_from, DateFormat.apiDateTime) : null,
                active_to: values.active_to ? DateUtils.format(values.active_to, DateFormat.apiDateTime) : null,
            });
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.campaign.onCreateSubmit.succeed" }), { variant: "success" });
            if (!isRedirectHandled) {
                this.setState({ isCreateSucceed: true }, () => this.props.history.push(Path.campaignEdit(`${campaignId}`)));
            }
            return true;
        } catch (error) {
            let errorMessage = null;

            if (error instanceof ApiError) {
                errorMessage = error?.message;
                Form.submitFailed(formikHelpers, error);
            }

            this.props.enqueueSnackbar(errorMessage || I18n.formatMessage({ id: "pages.campaign.onCreateSubmit.error" }), { variant: "error" });
        }
        return false;
    };

    public render() {
        if (this.state.isCreateSucceed) {
            return <Redirect to={Path.campaignList} />;
        }

        const initialValues: CampaignFormValues = {
            name: "",
            description: "",
            target_url: "",
            target_title: "",
            image_foreground_desktop: "",
            image_background_desktop: "",
            image_foreground_mobile: "",
            image_background_mobile: "",
            active_from: null,
            active_to: null,
            is_active: true,
        };

        return (
            <PageLayout
                breadcrumb={
                    <Breadcrumbs>
                        <Link to={Path.campaignList} color="inherit">
                            {I18n.formatMessage({ id: "pages.campaign.createBreadcrumb" })}
                        </Link>
                        <ActiveBreadcrumbItem aria-current="page">{I18n.formatMessage({ id: "pages.campaign.new" })}</ActiveBreadcrumbItem>
                    </Breadcrumbs>
                }
            >
                <Typography variant="h1">{I18n.formatMessage({ id: "pages.campaign.new" })}</Typography>
                <Formik initialValues={initialValues} onSubmit={this.onSubmit} validate={campaignValidator} validateOnBlur={false}>
                    {props => (
                        <>
                            <CampaignForm formType={FormType.create} formProps={props} />
                            <Prompt when={props.dirty && !this.state.isCreateSucceed} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props, true)} />
                        </>
                    )}
                </Formik>
                <Box mt="40px" />
            </PageLayout>
        );
    }
}

export default withRouter(withSnackbar(CampaignCreatePage));
