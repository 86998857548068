import { gql } from "@apollo/client";

export const createBannerMutation = gql`
    mutation createBanner($input: BannerInput!, $csrf: String) {
        createBanner(input: $input, csrf: $csrf) {
            ... on BannerNormal {
                id
            }
            ... on BannerProductList {
                id
            }
            ... on BannerProduct {
                id
            }
        }
    }
`;
