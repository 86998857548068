import React, { useCallback, useMemo, useState } from "react";
import uniqWith from "lodash/uniqWith";
import { I18n } from "I18n/I18n";
import { Box, Button, Icon, Typography } from "@bigfish/admin-ui/core";
import { BadgeListItem } from "Api/graphql/admin/types";
import BadgeListDataGrid from "Pages/BadgeList/BadgeListDataGrid";
import { createModal } from "Components/createModal";
import { Constants } from "Utils/Constants";
import ModalBadgeCreatePage from "Pages/ModalBadge/ModalBadgeCreatePage";
import "./styles.css";

const AddBadgeModal = createModal({ modalWidth: 1280 });
const CreatePageDialog = createModal(Constants.getModalPageStyles());

interface Props {
    description?: string;
    isSingleSelect?: boolean;
    initialSelectedBadges: BadgeListItem[];
    onClose: () => void;
    onAddClick: (selectedBadges: BadgeListItem[]) => void;
}

export const AddBadgeDialog = ({ description, isSingleSelect, initialSelectedBadges, onClose, onAddClick }: Props) => {
    const [selectedBadges, setSelectedBadges] = useState(initialSelectedBadges);
    const [isCreateOpen, setIsCreateOpen] = useState<boolean>(false);
    const [listKey, setListKey] = useState<number>(0);
    const selectedBadgeIds = useMemo(() => selectedBadges.map(p => p.id), [selectedBadges]);

    const handleAddBadges = useCallback(
        (badgesToAdd: BadgeListItem[]) => {
            isSingleSelect ? setSelectedBadges(badgesToAdd) : setSelectedBadges(uniqWith([...selectedBadges, ...badgesToAdd], (p1, p2) => p1.id === p2.id));
        },
        [selectedBadges, isSingleSelect]
    );

    const handleRemoveBadges = useCallback(
        (badgesToRemove: BadgeListItem[]) => {
            setSelectedBadges(selectedBadges.filter(badge => !badgesToRemove.some(badgeToRemove => badgeToRemove.id === badge.id)));
        },
        [selectedBadges]
    );

    return (
        <AddBadgeModal
            title={I18n.formatMessage({ id: "components.addBadgeDialog.title" })}
            leftButtonsComponent={
                <div>
                    {!isSingleSelect && (
                        <Typography variant="subtitle2">{I18n.formatMessage({ id: "common.selectedItems" }, { selectedItems: selectedBadgeIds.length })}</Typography>
                    )}
                    <Button startIcon={<Icon className="fa fa-plus-circle" />} variant="contained" size="medium" color="secondary" onClick={() => setIsCreateOpen(true)}>
                        {I18n.formatMessage({ id: "components.addBadgeDialog.createBadge" })}
                    </Button>
                </div>
            }
            rightButtonsComponent={
                <>
                    <Button variant="outlined" color="primary" onClick={onClose} style={{ marginRight: "20px" }}>
                        {I18n.formatMessage({ id: "common.cancel" })}
                    </Button>
                    <Button variant="contained" color="primary" onClick={() => onAddClick(selectedBadges)}>
                        {I18n.formatMessage({ id: "common.add" })}
                    </Button>
                </>
            }
            open
            onClose={onClose}
        >
            {description && (
                <Box mt="30px" mb="30px">
                    <Typography variant="body2" color="textSecondary">
                        {description}
                    </Typography>
                </Box>
            )}
            <BadgeListDataGrid
                key={listKey}
                isSingleSelect={isSingleSelect}
                selectedBadgeIds={selectedBadgeIds}
                onAddBadges={handleAddBadges}
                onRemoveBadges={handleRemoveBadges}
                minWidth={500}
                disableRowClick
            />
            {isCreateOpen && (
                <CreatePageDialog title={null} open onClose={() => setIsCreateOpen(false)}>
                    <ModalBadgeCreatePage
                        onModalClose={() => {
                            setIsCreateOpen(false);
                            setListKey(listKey + 1);
                        }}
                    />
                </CreatePageDialog>
            )}
        </AddBadgeModal>
    );
};
