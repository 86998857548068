import { ActiveBreadcrumbItem, TitleBar } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { UserForm } from "./UserForm";
import { UserFormValues } from "./ProfileTab";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { Loading } from "Components/Loading";
import { PageLayout } from "Components/PageLayout";
import { Link } from "Components/Link";
import { Breadcrumbs } from "Components/Breadcrumbs";
import Prompt from "Components/Prompt";
import { User } from "Api/graphql/admin/types";
import { Box, Button, Icon } from "@bigfish/admin-ui/core";
import { I18n } from "I18n/I18n";
import { FunctionalButton } from "Components/FunctionalButton";
import { FunctionConfirmModal } from "Components/FunctionConfirmModal";
import { DeleteForever } from "@bigfish/admin-ui/icons";

type RouteParams = {
    id?: string;
};

type Props = WithSnackbarProps & RouteComponentProps<RouteParams>;

type State = {
    isLoading: boolean;
    user: User | null;
    isDeleteDialogVisible: boolean;
};

class UserEditPage extends React.Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        user: null,
        isDeleteDialogVisible: false,
    };

    public async componentDidMount(): Promise<void> {
        this.fetchUser();
    }

    private onSubmit = async (values: UserFormValues, formikHelpers: FormikHelpers<UserFormValues>): Promise<boolean> => {
        try {
            const user = await Api.updateUser(
                this.state.user!.id,
                {
                    firstname: values.firstname,
                    lastname: values.lastname,
                    email: values.email,
                    banktransfer_enabled: values.banktransfer_enabled,
                    cod_disabled: values.cod_disabled,
                    is_active: values.is_active,
                    newsletter: values.newsletter,
                },
                values.replacement_rplus_card.length > 0 ? null : values.remove_rplus_card,
                values.replacement_rplus_card.length > 0 ? values.replacement_rplus_card : null
            );
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.user.onEditSubmit.succeed" }), { variant: "success" });
            this.setState({ user });
            return true;
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message ?? I18n.formatMessage({ id: "pages.user.onEditSubmit.error" }), { variant: "error" });
                Form.submitFailed(formikHelpers, error);
            }
        }
        return false;
    };

    private onDeleteButtonClick = (): void => {
        this.setState({ isDeleteDialogVisible: true });
    };

    private onDeleteDialogClose = (): void => {
        this.setState({ isDeleteDialogVisible: false });
    };

    private onDeleteClick = async (): Promise<void> => {
        try {
            await Api.deleteUser(this.state.user!.id);
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.user.edit.deleteSucceed" }), { variant: "success" });
            this.setState({ user: null });
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.user.edit.deleteError" }), { variant: "error" });
        }
    };

    private onAdminLoginAsUserClick = async (): Promise<void> => {
        try {
            const token = await Api.adminLoginAsUser(this.state.user!.id);
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.user.edit.loginAsUserSucceed" }), { variant: "success" });
            if (token) {
                // this is a workaround for iOS Safari blocking window.open calls from async functions
                setTimeout(() => window.open(`${process.env.REACT_APP_WEBSHOP_URL}/token-login/${token}`, "_blank"));
            }
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.user.edit.loginAsUserError" }), { variant: "error" });
        }
    };

    private getUserName = () => {
        return [this.state.user?.lastname, this.state.user?.firstname].filter(n => !!n).join(" ");
    };

    private getBreadcrumb = () => {
        return (
            <Breadcrumbs>
                <Link to={Path.userList} color="inherit">
                    {I18n.formatMessage({ id: "pages.user.editBreadcrumb" })}
                </Link>
                <ActiveBreadcrumbItem aria-current="page">{this.getUserName()}</ActiveBreadcrumbItem>
            </Breadcrumbs>
        );
    };

    private fetchUser = async () => {
        if (!this.props.match.params.id) {
            return;
        }

        try {
            const user = await Api.getUserById(Number.parseInt(this.props.match.params.id, 10));
            this.setState({ user, isLoading: false });
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
                this.props.history.push(Path.userList);
            }
        }
    };

    public render() {
        const { isLoading, user } = this.state;

        if (isLoading) {
            return <Loading breadcrumb={this.getBreadcrumb()} />;
        }

        if (!user) {
            return <Redirect to={Path.userList} />;
        }

        const initialValues: UserFormValues = {
            firstname: user.firstname ?? "",
            lastname: user.lastname ?? "",
            email: user.email,
            banktransfer_enabled: user.banktransfer_enabled,
            cod_disabled: user.cod_disabled,
            is_active: user.is_active,
            newsletter: user.newsletter ?? "",
            rplus_card_number: user.rossmann_plus_profile?.card_number ?? "",
            remove_rplus_card: false,
            replacement_rplus_card: "",
        };

        return (
            <PageLayout breadcrumb={this.getBreadcrumb()}>
                <TitleBar
                    title={this.getUserName()}
                    rightButtonsComponent={
                        <div>
                            <Button startIcon={<Icon className="fas fa-user" />} variant="contained" color="secondary" onClick={this.onAdminLoginAsUserClick}>
                                {I18n.formatMessage({ id: "pages.user.edit.adminLoginAsUserButton" })}
                            </Button>
                            <FunctionalButton onClick={this.onDeleteButtonClick} icon={<DeleteForever />} />
                        </div>
                    }
                />
                <Formik initialValues={initialValues} onSubmit={this.onSubmit} enableReinitialize={true}>
                    {props => (
                        <>
                            <UserForm formType={FormType.edit} formProps={props} user={user} onRefetchUser={this.fetchUser} />
                            <Prompt when={props.dirty} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props)} />
                        </>
                    )}
                </Formik>
                <Box mt="40px" />
                <FunctionConfirmModal
                    title={I18n.formatMessage({ id: "pages.user.edit.deleteModal.title" })}
                    description={I18n.formatMessage({ id: "pages.user.edit.deleteModal.description" })}
                    isVisible={this.state.isDeleteDialogVisible}
                    onClose={this.onDeleteDialogClose}
                    onFunctionClick={this.onDeleteClick}
                    leftButtonLabel={I18n.formatMessage({ id: "common.cancel" })}
                    rightButtonLabel={I18n.formatMessage({ id: "common.delete" })}
                />
            </PageLayout>
        );
    }
}

export default withSnackbar(withRouter(UserEditPage));
