import { AdminLayout, SimpleAppBar } from "@bigfish/admin-ui/components";
import React from "react";
import { brand } from "Utils/Brand";
import Drawer from "./Drawer";
import UserMenu from "./UserMenu";
import { connect } from "react-redux";
import { ApplicationState } from "Redux/rootReducer";
import { AuthSelectors } from "Redux/selectors/authSelectors";
import { Path } from "Utils/Path";

type ComponentProps = {
    children: JSX.Element | JSX.Element[];
    breadcrumb?: React.ReactElement;
};

type ReduxProps = {
    isStoreOnlyAdmin: boolean;
};

type Props = ComponentProps & ReduxProps;

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { isStoreOnlyAdmin: AuthSelectors.isStoreOnlyAdmin(state.auth) && !!state.auth.admin?.store };
};

export const PageLayout = connect(mapStateToProps)((props: Props) => {
    return (
        <AdminLayout
            topComponent={<SimpleAppBar userComponent={<UserMenu />} breadcrumbsComponent={props.breadcrumb} linkProps={{ href: Path.dashboard }} />}
            drawerComponent={props.isStoreOnlyAdmin ? undefined : <Drawer />}
            brandSettings={brand}
        >
            {props.children}
        </AdminLayout>
    );
});
