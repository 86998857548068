import React from "react";
import { Api } from "Api/Api";
import { TypeRowProps } from "@inovua/reactdatagrid-community/types";
import DataGrid from "Components/DataGrid/DataGrid";
import { WebsiteNotificationFilters, WebsiteNotificationSort, WebsiteNotificationListItem, WebsiteNotificationSortField, PermissionType } from "Api/graphql/admin/types";
import { DataGridUrlQueryParser, QueryParserType } from "Components/DataGrid/DataGridUrlQueryParser";
import { ApiError } from "Api/ApiError";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { withStyles, WithStyles } from "@bigfish/admin-ui/core";
import { Path } from "Utils/Path";
import { dataGridStyles } from "@bigfish/admin-ui/styles";
import { I18n } from "I18n/I18n";
import { DataSource, FilterValue } from "Components/DataGrid/DataGridUtils";
import { DataGridParams } from "Components/DataGrid/UrlQueryParser";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import { connect } from "react-redux";
import { ApplicationState } from "Redux/rootReducer";
import { AuthSelectors } from "Redux/selectors/authSelectors";
import { CustomTypeColumn } from "Utils/DataGridUtils";
import { Constants } from "Utils/Constants";

type ReduxProps = {
    permissions: PermissionType[];
    isSuperadmin: boolean;
};

type Props = WithSnackbarProps & WithStyles<typeof dataGridStyles> & ReduxProps;

class WebsiteNotificationListDataGrid extends React.Component<Props> {
    private getColumns = (): CustomTypeColumn[] => {
        return [
            { name: "id", header: I18n.formatMessage({ id: "pages.websiteNotificationList.grid.column.id" }), defaultWidth: 100, filterDelay: Constants.filterDelayMS },
            {
                name: "name",
                header: I18n.formatMessage({ id: "pages.websiteNotificationList.grid.column.name" }),
                defaultFlex: 1,
                minWidth: 320,
                filterDelay: Constants.filterDelayMS,
            },
            {
                name: "active_from",
                filterEditor: DateFilter,
                header: I18n.formatMessage({ id: "pages.websiteNotificationList.grid.column.active_from" }),
                defaultFlex: 1,
                minWidth: 357,
                dateFormat: "YYYY-MM-DD",
                render: ({ data }: { data: WebsiteNotificationListItem }) => {
                    return <div>{data.active_from ? DateUtils.format(data.active_from, DateFormat.minuteDateTime) : ""}</div>;
                },
            },
            {
                name: "active_to",
                filterEditor: DateFilter,
                header: I18n.formatMessage({ id: "pages.websiteNotificationList.grid.column.active_to" }),
                defaultFlex: 1,
                minWidth: 357,
                dateFormat: "YYYY-MM-DD",
                render: ({ data }: { data: WebsiteNotificationListItem }) => {
                    return <div>{data.active_to ? DateUtils.format(data.active_to, DateFormat.minuteDateTime) : ""}</div>;
                },
            },
        ];
    };

    private filterValues: FilterValue[] = [
        { name: "id", operator: "eq", type: "number" },
        { name: "name", operator: "contains", type: "string" },
        { name: "active_from", operator: "inrange", type: "date" },
        { name: "active_to", operator: "inrange", type: "date" },
    ];

    private urlQueryParser = new DataGridUrlQueryParser({
        filters: {
            id: QueryParserType.integer,
            name: QueryParserType.string,
            active_from: QueryParserType.dateRange,
            active_to: QueryParserType.dateRange,
        },
        sortField: WebsiteNotificationSortField,
    });

    private sortFieldToSortOrder = {
        [WebsiteNotificationSortField.id]: "id",
        [WebsiteNotificationSortField.name]: "name",
        [WebsiteNotificationSortField.active_from]: "active_from",
        [WebsiteNotificationSortField.active_to]: "active_to",
    };

    private dataSource = async (props: DataGridParams<WebsiteNotificationSort, WebsiteNotificationFilters>): Promise<DataSource<WebsiteNotificationListItem>> => {
        try {
            const result = await Api.listWebsiteNotifications({
                first: props.limit,
                page: props.page,
                filters: props.filters,
                sortBy: props.sortBy,
            });

            return { data: result.data, count: result.paginatorInfo.total };
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }
        return { data: [], count: 0 };
    };

    private getRowLink = (rowProps: TypeRowProps): string => {
        return Path.websiteNotificationEdit(rowProps.data.id);
    };

    render() {
        return (
            <DataGrid
                className={this.props.classes.dataGrid}
                urlQueryParser={this.urlQueryParser}
                rowHeight={50}
                style={{ minHeight: 800 }}
                dataSource={this.dataSource}
                filterValues={this.filterValues}
                columns={this.getColumns()}
                rowLink={this.getRowLink}
                activeCell={null}
                sortFieldToSortOrder={this.sortFieldToSortOrder}
                onRowClick={this.props.isSuperadmin || this.props.permissions.includes(PermissionType.website_notification_update) ? undefined : () => {}}
            />
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { permissions: AuthSelectors.getPermissions(state.auth), isSuperadmin: AuthSelectors.isSuperadmin(state.auth) };
};

export default withSnackbar(connect(mapStateToProps)(withStyles(dataGridStyles, { withTheme: true })(WebsiteNotificationListDataGrid)));
