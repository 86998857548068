import { gql } from "@apollo/client";

export const benefitListItemFragment = gql`
    fragment BenefitListItem on Benefit {
        id
        title
        created_at
        updated_at
    }
`;
