import React from "react";
import { Box, withStyles, WithStyles } from "@bigfish/admin-ui/core";
import { I18n } from "I18n/I18n";
import { dataGridStyles } from "@bigfish/admin-ui/styles";
import UnControlledDataGrid from "Components/DataGrid/UCDataGrid";
import { CustomTypeColumn } from "Utils/DataGridUtils";
import { DataSource } from "Components/DataGrid/DataGridUtils";
import { GiftCardOrderItem } from "Api/graphql/admin/types";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import { Constants } from "Utils/Constants";
import { TypeRowProps } from "@inovua/reactdatagrid-community/types";
import { FunctionConfirmModal } from "Components/FunctionConfirmModal";
import { withSnackbar, WithSnackbarProps } from "notistack";

type ComponentProps = {
    items: GiftCardOrderItem[];
};

type Props = WithStyles<typeof dataGridStyles> & WithSnackbarProps & ComponentProps;

type State = {
    inspectedItem: GiftCardOrderItem | null;
};

class GiftCardOrderItemListDataGrid extends React.Component<Props, State> {
    public readonly state: State = {
        inspectedItem: null,
    };

    private getDataSource = () => async (): Promise<DataSource<GiftCardOrderItem>> => {
        return { data: this.props.items, count: this.props.items.length };
    };

    private getColumns = (): CustomTypeColumn[] => {
        return [
            {
                name: "id",
                header: I18n.formatMessage({ id: "pages.giftCardOrderItemList.grid.column.id" }),
                defaultWidth: 130,
                filterDelay: Constants.filterDelayMS,
                sortable: false,
                filterable: false,
            },
            {
                name: "quantity",
                header: I18n.formatMessage({ id: "pages.giftCardOrderItemList.grid.column.quantity" }),
                defaultWidth: 220,
                sortable: false,
                filterEditor: NumberFilter,
                filterable: false,
            },
            {
                name: "value",
                header: I18n.formatMessage({ id: "pages.giftCardOrderItemList.grid.column.value" }),
                defaultWidth: 220,
                sortable: false,
                filterEditor: NumberFilter,
                filterable: false,
                render: ({ value }: { value: number }) => {
                    return <div>{I18n.formatCurrency(value)}</div>;
                },
            },
            {
                name: "design",
                header: I18n.formatMessage({ id: "pages.giftCardOrderItemList.grid.column.design" }),
                defaultWidth: 220,
                sortable: false,
                filterEditor: NumberFilter,
                filterable: false,
            },
            {
                name: "sub_total",
                header: I18n.formatMessage({ id: "pages.giftCardOrderItemList.grid.column.sub_total" }),
                defaultWidth: 220,
                sortable: false,
                filterable: false,
                filterEditor: NumberFilter,
                render: ({ value }: { value: number }) => {
                    return <div>{I18n.formatCurrency(value)}</div>;
                },
            },
            {
                name: "name_from",
                header: I18n.formatMessage({ id: "pages.giftCardOrderItemList.grid.column.name_from" }),
                defaultFlex: 1,
                minWidth: 250,
                sortable: false,
                filterable: false,
                filterDelay: Constants.filterDelayMS,
            },
            {
                name: "name_to",
                header: I18n.formatMessage({ id: "pages.giftCardOrderItemList.grid.column.name_to" }),
                defaultFlex: 1,
                minWidth: 250,
                sortable: false,
                filterable: false,
                filterDelay: Constants.filterDelayMS,
            },
            {
                name: "mail_to",
                header: I18n.formatMessage({ id: "pages.giftCardOrderItemList.grid.column.mail_to" }),
                defaultFlex: 1,
                minWidth: 250,
                sortable: false,
                filterable: false,
                render: ({ data }: { data: GiftCardOrderItem }) => {
                    return <div>{data.mail_to && data.mail_to.length > 0 ? data.mail_to.map(mt => mt).join(", ") : ""}</div>;
                },
            },
        ];
    };

    render() {
        const { inspectedItem } = this.state;

        return (
            <div>
                <Box mt="30px" />

                <p>{I18n.formatMessage({ id: "pages.giftCardOrderItemList.info" })}</p>

                <Box mt="30px" />

                <UnControlledDataGrid<GiftCardOrderItem>
                    className={this.props.classes.dataGrid}
                    rowHeight={50}
                    style={{ minHeight: 103 + this.props.items.length * 50 }}
                    dataSource={this.getDataSource()}
                    columns={this.getColumns()}
                    activeCell={null}
                    onRowClick={(rowProps: TypeRowProps) => (rowProps.data ? this.setState({ inspectedItem: rowProps.data }) : {})}
                    pagination={false}
                />
                <FunctionConfirmModal
                    title={I18n.formatMessage({ id: "pages.giftCardOrderItemList.inspectModalTitle" })}
                    description={inspectedItem?.mail_to?.join(", ") || null}
                    isVisible={!!inspectedItem}
                    onClose={() => this.setState({ inspectedItem: null })}
                    onFunctionClick={() => {}}
                />
            </div>
        );
    }
}

export default withSnackbar(withStyles(dataGridStyles, { withTheme: true })(GiftCardOrderItemListDataGrid));
