import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Location } from "history";
import { DefaultModal } from "@bigfish/admin-ui/components";
import { Box, Button } from "@bigfish/admin-ui/core";
import { I18n } from "I18n/I18n";

type ComponentProps = {
    when: boolean;
    hasSaveButton: boolean;
    onSave: () => Promise<boolean>;
    shouldBlock?: (location: Location) => boolean;
};

type Props = ComponentProps & RouteComponentProps;

type State = {
    location: Location | null;
    action: string | null;
    isSubmitting: boolean;
};

class Prompt extends React.Component<Props, State> {
    public readonly state: State = {
        location: null,
        action: null,
        isSubmitting: false,
    };

    private preventBack: null | (() => void) = null;

    public componentDidMount(): void {
        this.preventBack = this.props.history.block((location: Location, action: string): string | false | void => {
            if (this.props.when) {
                if (this.props.location.pathname === location.pathname) {
                    // Navigate to same page re-rendering current page - no need for prompt
                    return;
                }
                if (this.props.shouldBlock && this.props.shouldBlock(location)) {
                    return false;
                }
                if (!this.state.location) {
                    this.setState({ location, action });
                    return false;
                }
            }
        });
    }

    public componentWillUnmount() {
        this.preventBack && this.preventBack();
    }

    private navigateToLocation = () => {
        switch (this.state.action) {
            case "PUSH":
                this.props.history.push(this.state.location!);
                break;
            case "POP":
                this.props.history.goBack();
                break;
            case "REPLACE":
                this.props.history.replace(this.state.location!);
                break;
        }
    };

    private onSaveClick = (): void => {
        this.setState(
            { isSubmitting: true },
            async (): Promise<void> => {
                const result = await this.props.onSave();
                if (result) {
                    this.navigateToLocation();
                } else {
                    this.setState({ isSubmitting: false });
                }
            }
        );
    };

    private onLeaveClick = (): void => {
        this.navigateToLocation();
    };

    private onCloseClick = () => {
        this.setState({ location: null, action: null });
    };

    public render() {
        return (
            <DefaultModal
                title={I18n.formatMessage({ id: "components.prompt.title" })}
                open={!!this.state.location}
                leftButtonsComponent={
                    <Button variant="outlined" color="primary" onClick={this.onCloseClick} disabled={this.state.isSubmitting}>
                        {I18n.formatMessage({ id: "common.cancel" })}
                    </Button>
                }
                rightButtonsComponent={
                    this.props.hasSaveButton ? (
                        <>
                            <Box>
                                <Button variant="outlined" color="error" onClick={this.onLeaveClick} disabled={this.state.isSubmitting} style={{ marginRight: 20 }}>
                                    {I18n.formatMessage({ id: "components.prompt.leave" })}
                                </Button>

                                <Button variant="contained" color="primary" onClick={this.onSaveClick} disabled={this.state.isSubmitting}>
                                    {I18n.formatMessage({ id: "common.save" })}
                                </Button>
                            </Box>
                        </>
                    ) : (
                        <Button variant="outlined" color="error" onClick={this.onLeaveClick} disabled={this.state.isSubmitting}>
                            {I18n.formatMessage({ id: "components.prompt.leave" })}
                        </Button>
                    )
                }
                onClose={this.onCloseClick}
            >
                <div dangerouslySetInnerHTML={{ __html: I18n.formatMessage({ id: "components.prompt.description" }) }} />
            </DefaultModal>
        );
    }
}

export default withRouter(Prompt);
