import React from "react";
import { TypeFilterValue } from "@inovua/reactdatagrid-community/types";
import { withStyles, WithStyles } from "@bigfish/admin-ui/core";
import { I18n } from "I18n/I18n";
import { dataGridStyles } from "@bigfish/admin-ui/styles";
import UnControlledDataGrid from "Components/DataGrid/UCDataGrid";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import { CustomTypeColumn, DataGridUtils } from "Utils/DataGridUtils";
import { DataSource } from "Components/DataGrid/DataGridUtils";
import { ProductPrice } from "Api/graphql/admin/types";
import { Constants } from "Utils/Constants";

type Props = WithStyles<typeof dataGridStyles> & {
    prices: ProductPrice[];
};

interface State {
    dataSource: () => Promise<DataSource<ProductPrice>>;
    filterValues: TypeFilterValue;
}

class ProductListBadgeListDataGrid extends React.Component<Props, State> {
    private getColumns = (): CustomTypeColumn[] => {
        return [
            {
                name: "price",
                header: I18n.formatMessage({ id: "pages.product.form.tabs.data.column.price" }),
                defaultFlex: 1,
                minWidth: 200,
                sortable: false,
                filterDelay: Constants.filterDelayMS,
                render: ({ data }: { data: ProductPrice }) => {
                    return data.price ? I18n.formatCurrency(data.price) : null;
                },
            },
            {
                name: "price_unit",
                header: I18n.formatMessage({ id: "pages.product.form.tabs.data.column.price_unit" }),
                defaultFlex: 1,
                minWidth: 200,
                sortable: false,
                filterDelay: Constants.filterDelayMS,
                render: ({ data }: { data: ProductPrice }) => {
                    return data.price_unit ? `${I18n.formatMessage({ id: "common.hufPerPiece" }, { huf: data.price_unit })}` : null;
                },
            },
            {
                name: "active_from",
                filterEditor: DateFilter,
                header: I18n.formatMessage({ id: "pages.product.form.tabs.data.column.active_from" }),
                defaultFlex: 1,
                minWidth: 200,
                render: ({ data }: { data: ProductPrice }) => {
                    return <div>{data.active_from ? DateUtils.format(data.active_from, DateFormat.minuteDateTime) : ""}</div>;
                },
                sortable: false,
            },
            {
                name: "active_to",
                filterEditor: DateFilter,
                header: I18n.formatMessage({ id: "pages.product.form.tabs.data.column.active_to" }),
                defaultFlex: 1,
                minWidth: 200,
                render: ({ data }: { data: ProductPrice }) => {
                    return <div>{data.active_to ? DateUtils.format(data.active_to, DateFormat.minuteDateTime) : ""}</div>;
                },
                sortable: false,
            },
        ];
    };

    private getDataSource = () => async (): Promise<DataSource<ProductPrice>> => {
        return { data: this.props.prices.filter(product => this.state.filterValues?.every(DataGridUtils.filter(product))), count: this.props.prices.length };
    };

    state: State = {
        dataSource: this.getDataSource(),
        filterValues: [],
    };

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevProps.prices !== this.props.prices || this.state.filterValues !== prevState.filterValues) {
            this.setState({ dataSource: this.getDataSource() });
        }
    }

    render() {
        return (
            <UnControlledDataGrid<ProductPrice>
                className={this.props.classes.dataGrid}
                rowHeight={50}
                style={{ minHeight: 43 + this.props.prices.length * 50 }}
                dataSource={this.state.dataSource}
                columns={this.getColumns()}
                activeCell={null}
                pagination={false}
            />
        );
    }
}

export default withStyles(dataGridStyles, { withTheme: true })(ProductListBadgeListDataGrid);
