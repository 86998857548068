import { ActiveBreadcrumbItem, TitleBar } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { MenuForm, MenuFormValues, MenuItemInputWithDelete, menuValidator } from "./MenuForm";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { Loading } from "Components/Loading";
import { PageLayout } from "Components/PageLayout";
import { Link } from "Components/Link";
import { Breadcrumbs } from "Components/Breadcrumbs";
import Prompt from "Components/Prompt";
import { Menu, MenuItemInput, MenuItemTypeEnum, Menu_items, Menu_items_items } from "Api/graphql/admin/types";
import { Box } from "@bigfish/admin-ui/core";
import { I18n } from "I18n/I18n";
import { FunctionConfirmModal } from "Components/FunctionConfirmModal";
import { FunctionalButton } from "Components/FunctionalButton";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import { ObjectUtils } from "Utils/ObjectUtils";
import { DeleteForever } from "@bigfish/admin-ui/icons";

type RouteParams = {
    id?: string;
};

type Props = WithSnackbarProps & RouteComponentProps<RouteParams>;

type State = {
    isLoading: boolean;
    menu: Menu | null;
    isDeleteDialogVisible: boolean;
};

class MenuEditPage extends React.Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        menu: null,
        isDeleteDialogVisible: false,
    };

    public async componentDidMount(): Promise<void> {
        if (!this.props.match.params.id) {
            return;
        }

        try {
            const menu = await Api.getMenuById(Number.parseInt(this.props.match.params.id, 10));
            this.setState({ menu, isLoading: false });
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
            this.props.history.push(Path.menuList);
        }
    }

    private onSubmit = async (values: MenuFormValues, formikHelpers: FormikHelpers<MenuFormValues>): Promise<boolean> => {
        const getItems = (items: MenuItemInputWithDelete[]): MenuItemInput[] => {
            return items
                .filter(i => !i.delete)
                .map(i => {
                    return {
                        type: i.type,
                        model_id: i.model_id,
                        title: i.title,
                        url: i.type === MenuItemTypeEnum.url ? i.url : null,
                        popup: i.popup,
                        is_active: i.is_active,
                        page_id_overlay: i.page_id_overlay,
                        items: i.items ? getItems(i.items) : undefined,
                    };
                });
        };

        try {
            const menu = await Api.updateMenu(this.state.menu!.id, {
                ...values,
                items: getItems(values.items),
                active_from: values.active_from ? DateUtils.format(values.active_from, DateFormat.apiDateTime) : null,
                active_to: values.active_to ? DateUtils.format(values.active_to, DateFormat.apiDateTime) : null,
            });
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.menu.onEditSubmit.succeed" }), { variant: "success" });
            this.setState({ menu });
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.menu.onEditSubmit.error" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
        }
        return false;
    };

    private getBreadcrumb = () => {
        return (
            <Breadcrumbs>
                <Link to={Path.menuList} color="inherit">
                    {I18n.formatMessage({ id: "pages.menu.editBreadcrumb" })}
                </Link>
                <ActiveBreadcrumbItem aria-current="page">{this.state.menu?.id || ""}</ActiveBreadcrumbItem>
            </Breadcrumbs>
        );
    };

    private onDeleteButtonClick = (): void => {
        this.setState({ isDeleteDialogVisible: true });
    };

    private onDeleteDialogClose = (): void => {
        this.setState({ isDeleteDialogVisible: false });
    };

    private onDeleteClick = async (): Promise<void> => {
        try {
            await Api.deleteMenu(this.state.menu!.id);
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.menu.edit.deleteSucceed" }), { variant: "success" });
            this.setState({ menu: null });
        } catch (error) {
            const errorMessage = error instanceof ApiError ? ` ${error.message}` ?? "" : "";
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.menu.edit.deleteError" }) + errorMessage, { variant: "error", transitionDuration: { appear: 7000 } });
        }
    };

    public render() {
        const { isLoading, menu } = this.state;

        if (isLoading) {
            return <Loading breadcrumb={this.getBreadcrumb()} />;
        }

        if (!menu) {
            return <Redirect to={Path.menuList} />;
        }

        const convertTypes = (items: Menu_items[]) => {
            const filtered = items.filter(i =>
                ObjectUtils.enumAsArray<MenuItemTypeEnum>(MenuItemTypeEnum)
                    .map(m => `${m}`)
                    .includes(i.type.id)
            );

            const convertType = (item: Menu_items, id: string): any => {
                const children = item.items;
                return {
                    ...item,
                    id,
                    type: item.type.id,
                    page_id_overlay: item.page_overlay?.id ?? undefined,
                    is_active: item.is_active,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    items: children ? children.map((i: Menu_items_items, index: number) => convertType(i, `${id}-${index}`)) : undefined,
                };
            };

            return filtered.map((item: Menu_items, index: number) => {
                return { ...convertType(item, `${index}`) };
            });
        };

        const initialValues: MenuFormValues = {
            slug: menu.slug,
            name: menu.name,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            items: convertTypes(menu.items),
            is_active: menu.is_active,
            active_from: menu.active_from ? DateUtils.format(DateUtils.parseISO(menu.active_from), DateFormat.input) : "",
            active_to: menu.active_to ? DateUtils.format(DateUtils.parseISO(menu.active_to), DateFormat.input) : "",
        };

        return (
            <PageLayout breadcrumb={this.getBreadcrumb()}>
                <TitleBar title={menu.id} rightButtonsComponent={<FunctionalButton onClick={this.onDeleteButtonClick} icon={<DeleteForever />} />} />
                <Formik initialValues={initialValues} onSubmit={this.onSubmit} validate={menuValidator()} enableReinitialize={true}>
                    {props => (
                        <>
                            <MenuForm formType={FormType.edit} formProps={props} menu={menu} />
                            <Prompt when={props.dirty} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props)} />
                        </>
                    )}
                </Formik>
                <Box mt="40px" />
                <FunctionConfirmModal
                    title={I18n.formatMessage({ id: "pages.menu.edit.deleteModal.title" })}
                    description={I18n.formatMessage({ id: "pages.menu.edit.deleteModal.description" })}
                    isVisible={this.state.isDeleteDialogVisible}
                    onClose={this.onDeleteDialogClose}
                    onFunctionClick={this.onDeleteClick}
                    leftButtonLabel={I18n.formatMessage({ id: "common.cancel" })}
                    rightButtonLabel={I18n.formatMessage({ id: "common.delete" })}
                />
            </PageLayout>
        );
    }
}

export default withSnackbar(withRouter(MenuEditPage));
