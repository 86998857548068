import React from "react";
import { Api } from "Api/Api";
import { TypeRowProps } from "@inovua/reactdatagrid-community/types";
import DataGrid from "Components/DataGrid/DataGrid";
import { User, UserOrderItem, OrderSortField, OrderStatusEnum, OrderFilters, OrderSort, ShippingMethodSelectItem, PaymentMethodSelectItem } from "Api/graphql/admin/types";
import { ApiError } from "Api/ApiError";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { Box, Grid, Typography, withStyles, WithStyles } from "@bigfish/admin-ui/core";
import { Path } from "Utils/Path";
import { dataGridStyles } from "@bigfish/admin-ui/styles";
import { Constants } from "Utils/Constants";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import { I18n } from "I18n/I18n";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import { DataGridUrlQueryParser, QueryParserType } from "Components/DataGrid/DataGridUrlQueryParser";
import { DataSource, FilterValue } from "Components/DataGrid/DataGridUtils";
import { DataGridParams } from "Components/DataGrid/UrlQueryParser";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import { CustomTypeColumn } from "Utils/DataGridUtils";

type ComponentProps = {
    user?: User;
};

type Props = ComponentProps & WithSnackbarProps & WithStyles<typeof dataGridStyles>;

type State = {
    selectShippingMethods: ShippingMethodSelectItem[];
    selectPaymentMethods: PaymentMethodSelectItem[];
};

class UserListDataGrid extends React.Component<Props, State> {
    public readonly state: State = {
        selectShippingMethods: [],
        selectPaymentMethods: [],
    };

    public async componentDidMount(): Promise<void> {
        try {
            const selectShippingMethods = await Api.listShippingMethodSelectItems({ first: 9999 });
            const selectPaymentMethods = await Api.listPaymentMethodSelectItems({ first: 9999 });
            this.setState({ selectShippingMethods: selectShippingMethods.data, selectPaymentMethods: selectPaymentMethods.data });
        } catch (error) {
            this.props.enqueueSnackbar(error.message, { variant: "error" });
        }
    }

    private getColumns = (): CustomTypeColumn[] => {
        return [
            { name: "id", header: I18n.formatMessage({ id: "pages.user.form.ordersDataGrid.column.id" }), defaultWidth: 100, filterDelay: Constants.filterDelayMS },
            {
                name: "created_at",
                filterEditor: DateFilter,
                header: I18n.formatMessage({ id: "pages.user.form.ordersDataGrid.column.created_at" }),
                defaultWidth: Constants.columnWidth.dateTime,
                render: ({ data }: { data: UserOrderItem }) => {
                    return <div>{data.created_at ? DateUtils.format(data.created_at, DateFormat.minuteDateTime) : ""}</div>;
                },
            },
            {
                name: "grand_total",
                header: I18n.formatMessage({ id: "pages.user.form.ordersDataGrid.column.grand_total" }),
                filterEditor: NumberFilter,
                defaultWidth: 184,
                filterDelay: Constants.filterDelayMS,
            },
            {
                name: "shipping_method_name",
                header: I18n.formatMessage({ id: "pages.user.form.ordersDataGrid.column.shipping_method_name" }),
                defaultWidth: 184,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: this.state.selectShippingMethods.map(ssm => {
                        return { id: ssm.id, label: Constants.getShippingMethodTitle(ssm.name) };
                    }),
                },
                filterDelay: Constants.filterDelayMS,
                render: ({ data }: { data: UserOrderItem }) => {
                    return <div>{Constants.getShippingMethodTitle(data.shipping_method_name)}</div>;
                },
            },
            {
                name: "payment_method_name",
                header: I18n.formatMessage({ id: "pages.user.form.ordersDataGrid.column.payment_method_name" }),
                defaultWidth: 184,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: this.state.selectPaymentMethods.map(spm => {
                        return { id: spm.id, label: Constants.getPaymentMethodTitle(spm.name) };
                    }),
                },
                filterDelay: Constants.filterDelayMS,
                render: ({ data }: { data: UserOrderItem }) => {
                    return <div>{Constants.getPaymentMethodTitle(data.payment_method_name)}</div>;
                },
            },
            {
                name: "payment_status_title",
                header: I18n.formatMessage({ id: "pages.user.form.ordersDataGrid.column.payment_status_title" }),
                defaultWidth: 188,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: [
                        { id: OrderStatusEnum.open, label: Constants.getOrderStatusTitle(OrderStatusEnum.open) },
                        { id: OrderStatusEnum.in_progress, label: Constants.getOrderStatusTitle(OrderStatusEnum.in_progress) },
                        { id: OrderStatusEnum.completed, label: Constants.getOrderStatusTitle(OrderStatusEnum.completed) },
                        { id: OrderStatusEnum.canceled, label: Constants.getOrderStatusTitle(OrderStatusEnum.canceled) },
                        { id: OrderStatusEnum.modified, label: Constants.getOrderStatusTitle(OrderStatusEnum.modified) },
                    ],
                },
                render: ({ data }: { data: UserOrderItem }) => {
                    return <div>{data.payment_status_title}</div>;
                },
            },
        ];
    };

    private filterValues: FilterValue[] = [
        { name: "id", operator: "eq", type: "number" },
        { name: "created_at", operator: "inrange", type: "date" },
        { name: "grand_total", operator: "inrange", type: "number" },
        { name: "shipping_method_name", operator: "contains", type: "string" },
        { name: "payment_method_name", operator: "contains", type: "string" },
        { name: "payment_status_title", operator: "eq", type: "select" },
    ];

    private urlQueryParser = new DataGridUrlQueryParser({
        filters: {
            id: QueryParserType.integer,
            created_at: QueryParserType.dateRange,
            grand_total: QueryParserType.integerRange,
            shipping_method_name: QueryParserType.string,
            payment_method_name: QueryParserType.string,
            payment_status_title: QueryParserType.enum,
        },
        sortField: OrderSortField,
    });

    private sortFieldToSortOrder = {
        [OrderSortField.id]: "id",
        [OrderSortField.created_at]: "status",
        [OrderSortField.grand_total]: "grand_total",
        [OrderSortField.shipping_method_name]: "shipping_method_name",
        [OrderSortField.payment_method_name]: "payment_method_name",
        [OrderSortField.payment_status_title]: "payment_status_title",
    };

    private dataSource = async (props: DataGridParams<OrderSort, OrderFilters>): Promise<DataSource<UserOrderItem>> => {
        try {
            const order = await Api.listUserOrders({
                filters: {
                    user_id: this.props.user!.id,
                    id: props.filters?.id,
                    created_at: props.filters?.created_at,
                    payment_method_id: props.filters?.payment_method_id,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    shipping_method_id: props.filters?.shipping_method_name ?? undefined,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    payment_method_id: props.filters?.payment_method_name ?? undefined,
                    grand_total: props.filters?.grand_total
                        ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          { min: Number.parseInt(props.filters.grand_total.start, 10), max: Number.parseInt(props.filters.grand_total.end, 10) }
                        : undefined,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    status: props.filters?.payment_status_title ?? undefined,
                },
                sortBy: props.sortBy,
                first: props.limit,
                page: props.page,
            });
            return { data: order.data, count: order.paginatorInfo.total };
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }
        return { data: [], count: 0 };
    };

    private getRowLink = (rowProps: TypeRowProps): string => {
        return Path.userEdit(rowProps.data.id);
    };

    render() {
        if (!this.props.user) return null;

        return (
            <div>
                <Box mt="30px" />
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <Typography color="textSecondary" gutterBottom>
                            {I18n.formatMessage({ id: "pages.user.form.orderNumberLabel" })}
                        </Typography>
                        <Typography variant="subtitle1">{this.props.user.order_count}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography color="textSecondary" gutterBottom>
                            {I18n.formatMessage({ id: "pages.user.form.orderGrandTotalLabel" })}
                        </Typography>
                        <Typography variant="subtitle1">{I18n.formatCurrency(this.props.user.order_total_sum)}</Typography>
                    </Grid>
                </Grid>
                <Box mt="30px" />
                <DataGrid
                    className={this.props.classes.dataGrid}
                    urlQueryParser={this.urlQueryParser}
                    rowHeight={50}
                    style={{ minHeight: 800 }}
                    dataSource={this.dataSource}
                    filterValues={this.filterValues}
                    columns={this.getColumns()}
                    rowLink={this.getRowLink}
                    activeCell={null}
                    sortFieldToSortOrder={this.sortFieldToSortOrder}
                    onRowClick={() => {}}
                />
            </div>
        );
    }
}

export default withSnackbar(withStyles(dataGridStyles, { withTheme: true })(UserListDataGrid));
