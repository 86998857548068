import { gql } from "@apollo/client";
import { userFragment } from "../fragments/userFragment";

export const updateUserMutation = gql`
    mutation updateUser($id: Int!, $input: UserInput!, $remove_rplus_card: Boolean, $replacement_rplus_card: String, $csrf: String) {
        updateUser(id: $id, input: $input, remove_rplus_card: $remove_rplus_card, replacement_rplus_card: $replacement_rplus_card, csrf: $csrf) {
            ...User
        }
    }
    ${userFragment}
`;
