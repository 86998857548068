import React, { useCallback } from "react";
import { Form, FormType } from "Components/Form";
import { Button, FormControl, FormControlLabel, Grid, Icon, TextField, Typography, Box, InputAdornment } from "@bigfish/admin-ui/core";
import { Field, FieldProps, FormikErrors, FormikProps } from "formik";
import { Checkbox } from "@bigfish/admin-ui/core";
import { I18n } from "Src/i18n/I18n";
import { FullscreenLoader, PageCard, SavePanel, TabsComponent } from "@bigfish/admin-ui/components";
import { Path } from "Utils/Path";
import { useHistory } from "react-router-dom";
import { I18nHelpers } from "I18n/I18nHelpers";
import { Validator } from "Utils/Validator";
import { ProductCategory, ProductCategoryBannerInput, ProductCategoryUpdateInput } from "Api/graphql/admin/types";
import MediaLibraryImageInput from "Components/Inputs/MediaLibraryImageInput";
import { CustomFormHelperText } from "Components/CustomFormHelperText";
import { Constants } from "Utils/Constants";
import RichTextEditor from "Components/Inputs/RichTextEditor/RichTextEditor";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import { EventBusy } from "@bigfish/admin-ui/icons";
import SelectInput from "Components/SelectInput";
import { ObjectUtils } from "Utils/ObjectUtils";
import { ProductListSortFriendlyValues } from "Pages/Page/PageForm";

export type ProductCategoryFormValues = Omit<ProductCategoryUpdateInput, "product_sort_by"> & { product_sort_by?: ProductListSortFriendlyValues | null };

type Props = {
    formType: FormType;
    formProps: FormikProps<ProductCategoryFormValues>;
    productCategory?: ProductCategory;
};

export const productCategoryValidator = (values: ProductCategoryFormValues): FormikErrors<ProductCategoryFormValues> => {
    const errors: { [key in keyof ProductCategoryFormValues]?: any } = {};

    const bannerErrors = values.banners
        .map((banner: ProductCategoryBannerInput, index: number) => {
            const returned: { active_from?: string; active_to?: string } = {};
            let active_to = undefined;
            let active_from = undefined;

            if (banner.active_from && banner.active_to) {
                const otherBanners = values.banners.filter((_otherBanner: ProductCategoryBannerInput, otherIndex: number) => index !== otherIndex);
                active_to = [Validator.endDateCantBeBeforeStart(banner.active_from, banner.active_to), Validator.dateOverlaps(banner.active_to, otherBanners)]
                    .filter(Boolean)
                    .join(" ");
                active_from = [Validator.startDateCantBeAfterEnd(banner.active_from, banner.active_to), Validator.dateOverlaps(banner.active_from, otherBanners)]
                    .filter(Boolean)
                    .join(" ");
            }

            if (active_from) returned.active_from = active_from;
            if (active_to) returned.active_to = active_to;

            return returned;
        })
        .filter(be => Object.values(be).length > 0);

    const errorsWithBanners = { ...errors };
    if (bannerErrors.find(b => !!Object.values(b))) {
        errorsWithBanners.banners = bannerErrors;
    }
    return Form.cleanupFormikErrors(errorsWithBanners);
};

export const ProductCategoryForm = (props: Props) => {
    const history = useHistory();
    const onCancelClick = useCallback(() => {
        history.push(Path.productCategoryList);
    }, [history]);
    const [bannerTab, setBannerTab] = React.useState<number>(0);

    const renderBannerForm = (index: number) => {
        return (
            <div key={`banner-${index}`}>
                <Field name={`banners[${index}].is_active`}>
                    {({ field, meta }: FieldProps) =>
                        field.value !== undefined && (
                            <Box mt="30px" ml="10px">
                                <FormControl error={meta.touched && !!meta.error}>
                                    <FormControlLabel
                                        control={<Checkbox {...field} checked={field.value} />}
                                        label={I18n.formatMessage({ id: "pages.productCategory.form.bannerEnabledLabel" })}
                                    />
                                </FormControl>
                                <CustomFormHelperText meta={meta} />
                            </Box>
                        )
                    }
                </Field>
                <Box mt="30px" />
                <Typography variant="body1" component="h3" color="textSecondary">
                    {I18n.formatMessage({ id: "pages.productCategory.form.bannerImageLabel" })}
                </Typography>
                <Box mt="15px" className="image-section-root">
                    <Box className="image-section-image-field">
                        <div className="og_image_url_wrapper">
                            <Field name={`banners[${index}].image`}>
                                {({ field, meta }: FieldProps) => (
                                    <div>
                                        <MediaLibraryImageInput
                                            id={`banners[${index}].image`}
                                            name={`banners[${index}].image`}
                                            value={field.value}
                                            onChange={(value: string | null) => {
                                                props.formProps.setFieldValue(`banners[${index}].image`, value);
                                                props.formProps.setFieldTouched(`banners[${index}].image`, true);
                                            }}
                                        />
                                        <CustomFormHelperText
                                            meta={meta}
                                            defaultHelperText={
                                                <div style={{ whiteSpace: "nowrap" }}>{I18n.formatMessage({ id: "pages.productCategory.form.bannerImage.helperText" })}</div>
                                            }
                                        />
                                    </div>
                                )}
                            </Field>
                        </div>
                    </Box>
                    <Box className="image-section-alt-field">
                        <Field name={`banners[${index}].image_title`} validate={I18nHelpers.formatValidator(Validator.altTitleMaxLength)}>
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "common.form.altTitleTag" })}
                                    fullWidth
                                    variant="outlined"
                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.maxCharacter" }, { max: Constants.altTitleMaxLength }))}
                                    error={meta.touched && !!meta.error}
                                    inputProps={{
                                        maxLength: Constants.altTitleMaxLength,
                                    }}
                                    {...field}
                                />
                            )}
                        </Field>
                    </Box>
                </Box>
                <Box mt="60px" />
                <Grid container spacing={3}>
                    <Grid item xs={9}>
                        <Field name={`banners[${index}].link`}>
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "pages.productCategory.form.bannerLinkLabel" })}
                                    fullWidth
                                    variant="outlined"
                                    required
                                    helperText={Form.getHelperText(meta, "")}
                                    error={meta.touched && !!meta.error}
                                    {...field}
                                />
                            )}
                        </Field>
                    </Grid>
                    <Grid item xs={3}>
                        <Field name={`banners[${index}].link_new_tab`}>
                            {({ field, meta }: FieldProps) =>
                                field.value !== undefined && (
                                    <div>
                                        <FormControl error={meta.touched && !!meta.error}>
                                            <FormControlLabel
                                                control={<Checkbox {...field} checked={field.value} />}
                                                label={I18n.formatMessage({ id: "pages.productCategory.form.bannerLinkNewTabLabel" })}
                                            />
                                        </FormControl>
                                        <CustomFormHelperText meta={meta} />
                                    </div>
                                )
                            }
                        </Field>
                    </Grid>
                </Grid>
                <Box mt="30px" />

                <Grid container>
                    <Grid item>
                        <Box mr="30px">
                            <Field name={`banners[${index}].active_from`}>
                                {({ field, meta }: FieldProps) => (
                                    <TextField
                                        id={`banner_active_from_[${index}]`}
                                        label={I18n.formatMessage({ id: "pages.productCategory.form.bannerActiveFromLabel" })}
                                        type="datetime-local"
                                        className={`datetime-local ${props.formProps.values.banners[index].active_from ? "datetime-local-filled" : ""}`}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            endAdornment: props.formProps.values.banners[index].active_from ? (
                                                <InputAdornment
                                                    position="end"
                                                    onClick={() => {
                                                        props.formProps.setFieldValue(`banners[${index}].active_from`, "");
                                                        props.formProps.setFieldTouched(`banners[${index}].active_from`, true);
                                                    }}
                                                >
                                                    <EventBusy />
                                                </InputAdornment>
                                            ) : null,
                                        }}
                                        {...field}
                                        value={field.value}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            props.formProps.setFieldTouched(`banners[${index}].active_from`, true);
                                            field.onChange(e);
                                        }}
                                        error={meta.touched && !!meta.error}
                                        helperText={Form.getHelperText(meta, "")}
                                    />
                                )}
                            </Field>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Field name={`banners[${index}].active_to`}>
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    id={`banner_active_to_[${index}]`}
                                    label={I18n.formatMessage({ id: "pages.productCategory.form.bannerActiveToLabel" })}
                                    className={`datetime-local ${props.formProps.values.banners[index].active_to ? "datetime-local-filled" : ""}`}
                                    type="datetime-local"
                                    placeholder=""
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        endAdornment: props.formProps.values.banners[index].active_to ? (
                                            <InputAdornment
                                                position="end"
                                                onClick={() => {
                                                    props.formProps.setFieldValue(`banners[${index}].active_to`, "");
                                                    props.formProps.setFieldTouched(`banners[${index}].active_to`, true);
                                                }}
                                            >
                                                <EventBusy />
                                            </InputAdornment>
                                        ) : null,
                                    }}
                                    {...field}
                                    value={field.value}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        props.formProps.setFieldTouched(`banners[${index}].active_to`, true);
                                        field.onChange(e);
                                    }}
                                    error={meta.touched && !!meta.error}
                                    helperText={Form.getHelperText(meta, "")}
                                />
                            )}
                        </Field>
                    </Grid>
                </Grid>
                <Box mt="30px" />
                <Button
                    variant="contained"
                    size="medium"
                    color="secondary"
                    onClick={() => {
                        const newBanners = [...props.formProps.values.banners];
                        newBanners.splice(index, 1);
                        props.formProps.setFieldValue("banners", newBanners);
                        setBannerTab(newBanners.length - 1);
                    }}
                    startIcon={<Icon className="fas fa-trash" />}
                >
                    {I18n.formatMessage({ id: "pages.productCategory.form.banners.delete" })}
                </Button>
            </div>
        );
    };

    return (
        <Form formProps={props.formProps}>
            <PageCard.Container>
                <PageCard.Heading title={I18n.formatMessage({ id: "common.form.basicData" })} />

                <Box mt="30px" />

                <Typography color="textSecondary" gutterBottom>
                    {I18n.formatMessage({ id: "pages.productCategory.form.categoryLabel" })}
                </Typography>
                <Typography variant="body2">{props.productCategory?.name ?? ""}</Typography>

                <Box mt="30px" />

                <Field name="slug" validate={I18nHelpers.formatValidator(Validator.required)}>
                    {({ field, meta }: FieldProps) => (
                        <TextField
                            type="text"
                            label={I18n.formatMessage({ id: "pages.productCategory.form.slugLabel" })}
                            fullWidth
                            variant="outlined"
                            helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                            error={meta.touched && !!meta.error}
                            {...field}
                        />
                    )}
                </Field>

                <Box mt="30px" />

                <Typography color="textSecondary" gutterBottom>
                    {I18n.formatMessage({ id: "pages.productCategory.form.categoryIconLabel" })}
                </Typography>
                <Box mt="15px" className="image-section-root">
                    <Box className="image-section-image-field">
                        <div className="og_image_url_wrapper">
                            <Field name="icon">
                                {({ field, meta }: FieldProps) => (
                                    <div>
                                        <MediaLibraryImageInput
                                            id="icon"
                                            name="icon"
                                            value={field.value}
                                            onChange={(value: string | null) => {
                                                props.formProps.setFieldValue("icon", value);
                                                props.formProps.setFieldTouched("icon", true);
                                            }}
                                        />
                                        <CustomFormHelperText
                                            meta={meta}
                                            defaultHelperText={
                                                <div style={{ whiteSpace: "nowrap" }}>{I18n.formatMessage({ id: "pages.productCategory.form.icon.helperText" })}</div>
                                            }
                                        />
                                    </div>
                                )}
                            </Field>
                        </div>
                    </Box>
                    <Box className="image-section-alt-field">
                        <Field name="icon_title" validate={I18nHelpers.formatValidator(Validator.altTitleMaxLength)}>
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "common.form.altTitleTag" })}
                                    fullWidth
                                    variant="outlined"
                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.maxCharacter" }, { max: Constants.altTitleMaxLength }))}
                                    error={meta.touched && !!meta.error}
                                    inputProps={{
                                        maxLength: Constants.altTitleMaxLength,
                                    }}
                                    {...field}
                                />
                            )}
                        </Field>
                    </Box>
                </Box>

                <Box mt="60px" />

                <Typography variant="body1" component="h3" color="textSecondary">
                    {I18n.formatMessage({ id: "pages.productCategory.form.descriptionLabel" })}
                </Typography>
                <Box mt="15px" />
                <Field name="description">
                    {({ field, meta }: FieldProps) => (
                        <div>
                            <RichTextEditor
                                config="basic"
                                {...field}
                                onChange={(content: string) => {
                                    props.formProps.setFieldValue("description", content);
                                }}
                            />
                            <CustomFormHelperText meta={meta} />
                        </div>
                    )}
                </Field>

                {!props.productCategory?.parent?.id && (
                    <>
                        <Box mt="30px" />

                        <Field name="product_sort_by">
                            {({ field, meta }: FieldProps) => (
                                <SelectInput
                                    label={I18n.formatMessage({ id: "pages.page.form.productListBlock.productSortByLabel" })}
                                    fullWidth
                                    variant="outlined"
                                    options={ObjectUtils.enumAsArray<ProductListSortFriendlyValues>(ProductListSortFriendlyValues).map(value => ({
                                        id: value,
                                        title: I18n.formatMessage({ id: `enums.productListSortFriendlyValues.${value}` }),
                                    }))}
                                    helperText={Form.getHelperText(meta, "")}
                                    {...field}
                                    error={meta.touched && !!meta.error}
                                />
                            )}
                        </Field>
                    </>
                )}

                <Box mt="30px" />

                <PageCard.Heading
                    title={I18n.formatMessage({ id: "pages.productCategory.form.bannerData" })}
                    rightContent={
                        <Button
                            startIcon={<Icon className="fa fa-plus-circle" />}
                            variant="contained"
                            size="medium"
                            color="secondary"
                            disabled={props.formProps.values.banners.length === 3}
                            onClick={() => {
                                props.formProps.setFieldValue("banners", [
                                    ...props.formProps.values.banners,
                                    {
                                        is_active: true,
                                        active_from: null,
                                        active_to: null,
                                        image: "",
                                        image_title: null,
                                        link: null,
                                        link_new_tab: false,
                                    },
                                ]);
                                setBannerTab(props.formProps.values.banners.length);
                            }}
                        >
                            {I18n.formatMessage({ id: "pages.productCategory.form.banners.newBanner" })}
                        </Button>
                    }
                />

                {props.formProps.values.banners.length > 0 ? (
                    <TabsComponent
                        ariaLabel="product-category-banners-form-tabs"
                        tabs={props.formProps.values.banners.map((banner: ProductCategoryBannerInput, index: number) => {
                            return {
                                label: I18n.formatMessage({ id: "pages.productCategory.form.banners.tabLabel" }, { index: index + 1 }),
                                id: `banner-${index}`,
                                content: renderBannerForm(index),
                            };
                        })}
                        currentTab={bannerTab}
                        changeTab={(_event: React.ChangeEvent<{}>, newValue: number) => setBannerTab(newValue)}
                    />
                ) : (
                    <Typography variant="body1" component="h3" color="textSecondary">
                        {I18n.formatMessage({ id: "pages.productCategory.form.banners.noBanners" })}
                    </Typography>
                )}

                <Box mt="30px" />
                <PageCard.Heading title={I18n.formatMessage({ id: "common.form.settings" })} />

                <Typography variant="body1" component="h3" color="textSecondary">
                    {I18n.formatMessage({ id: "pages.productCategory.form.statusLabel" })}
                </Typography>

                <Field name="is_active">
                    {({ field, meta }: FieldProps) =>
                        field.value !== undefined && (
                            <Box mt="15px" ml="10px">
                                <FormControl error={meta.touched && !!meta.error}>
                                    <FormControlLabel
                                        control={<Checkbox {...field} checked={field.value} />}
                                        label={I18n.formatMessage({ id: "pages.productCategory.form.isActiveLabel" })}
                                    />
                                </FormControl>
                                <CustomFormHelperText meta={meta} />
                            </Box>
                        )
                    }
                </Field>

                <Box mt="30px" />

                <Field name="include_in_menu">
                    {({ field, meta }: FieldProps) =>
                        field.value !== undefined && (
                            <Box mt="15px" ml="10px">
                                <FormControl error={meta.touched && !!meta.error}>
                                    <FormControlLabel
                                        control={<Checkbox {...field} checked={field.value} />}
                                        label={I18n.formatMessage({ id: "pages.productCategory.form.includeInMenuLabel" })}
                                    />
                                </FormControl>
                                <CustomFormHelperText meta={meta} />
                            </Box>
                        )
                    }
                </Field>

                <Box mt="30px" />

                <Field name="is_featured">
                    {({ field, meta }: FieldProps) =>
                        field.value !== undefined && (
                            <Box mt="15px" ml="10px">
                                <FormControl error={meta.touched && !!meta.error}>
                                    <FormControlLabel
                                        control={<Checkbox {...field} checked={field.value} />}
                                        label={I18n.formatMessage({ id: "pages.productCategory.form.isFeaturedLabel" })}
                                    />
                                </FormControl>
                                <CustomFormHelperText meta={meta} />
                            </Box>
                        )
                    }
                </Field>

                <Box mt="30px" />

                <Typography variant="body1" component="h3" color="textSecondary">
                    {I18n.formatMessage({ id: "pages.productCategory.form.feedLabel" })}
                </Typography>

                <Field name="feed_facebook">
                    {({ field, meta }: FieldProps) =>
                        field.value !== undefined && (
                            <Box mt="15px" ml="10px">
                                <FormControl error={meta.touched && !!meta.error}>
                                    <FormControlLabel
                                        control={<Checkbox {...field} checked={field.value} />}
                                        label={I18n.formatMessage({ id: "pages.productCategory.form.feedFacebookLabel" })}
                                    />
                                </FormControl>
                                <CustomFormHelperText meta={meta} />
                            </Box>
                        )
                    }
                </Field>

                <Box mt="30px" />

                <Field name="feed_google">
                    {({ field, meta }: FieldProps) =>
                        field.value !== undefined && (
                            <Box mt="15px" ml="10px">
                                <FormControl error={meta.touched && !!meta.error}>
                                    <FormControlLabel
                                        control={<Checkbox {...field} checked={field.value} />}
                                        label={I18n.formatMessage({ id: "pages.productCategory.form.feedGoogleLabel" })}
                                    />
                                </FormControl>
                                <CustomFormHelperText meta={meta} />
                            </Box>
                        )
                    }
                </Field>

                <Box mt="30px" />

                <Field name="feed_google_merchant">
                    {({ field, meta }: FieldProps) =>
                        field.value !== undefined && (
                            <Box mt="15px" ml="10px">
                                <FormControl error={meta.touched && !!meta.error}>
                                    <FormControlLabel
                                        control={<Checkbox {...field} checked={field.value} />}
                                        label={I18n.formatMessage({ id: "pages.productCategory.form.feedGoogleMerchantLabel" })}
                                    />
                                </FormControl>
                                <CustomFormHelperText meta={meta} />
                            </Box>
                        )
                    }
                </Field>

                <Box mt="30px" />

                <Field name="feed_dsa">
                    {({ field, meta }: FieldProps) =>
                        field.value !== undefined && (
                            <Box mt="15px" ml="10px">
                                <FormControl error={meta.touched && !!meta.error}>
                                    <FormControlLabel
                                        control={<Checkbox {...field} checked={field.value} />}
                                        label={I18n.formatMessage({ id: "pages.productCategory.form.feedDsaLabel" })}
                                    />
                                </FormControl>
                                <CustomFormHelperText meta={meta} />
                            </Box>
                        )
                    }
                </Field>

                <Box mt="30px" />

                <PageCard.Heading title="Meta adatok" />
                <Typography variant="body1" component="h3" color="textSecondary">
                    {I18n.formatMessage({ id: "pages.content.form.ogImage" })}
                </Typography>
                <Box className="image-section-root">
                    <Box className="image-section-image-field">
                        <div className="og_image_url_wrapper">
                            <Field key="og_image" name="og_image">
                                {({ field, meta }: FieldProps) => (
                                    <div>
                                        <MediaLibraryImageInput
                                            id="product-category-og_image_url"
                                            name="og_image"
                                            value={field.value}
                                            onChange={(value: string | null) => {
                                                props.formProps.setFieldValue("og_image", value);
                                                props.formProps.setFieldTouched("og_image", true);
                                            }}
                                        />
                                        <CustomFormHelperText meta={meta} />
                                    </div>
                                )}
                            </Field>
                        </div>
                    </Box>
                    <Box className="image-section-title-tag-field">
                        <Field key={I18n.formatMessage({ id: "common.form.ogTitle" })} name="og_title" validate={I18nHelpers.formatValidator(Validator.ogTitleMaxLength)}>
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "common.form.ogTitle" })}
                                    fullWidth
                                    variant="outlined"
                                    error={meta.touched && !!meta.error}
                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.maxCharacter" }, { max: Constants.ogTitleMaxLength }))}
                                    inputProps={{
                                        maxLength: Constants.ogTitleMaxLength,
                                    }}
                                    {...field}
                                />
                            )}
                        </Field>
                    </Box>
                    <Box className="image-section-og-description-field">
                        <Field
                            key={I18n.formatMessage({ id: "common.form.ogDescription" })}
                            name="og_description"
                            validate={I18nHelpers.formatValidator(Validator.ogDescriptionMaxLength)}
                        >
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "common.form.ogDescription" })}
                                    fullWidth
                                    variant="outlined"
                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.maxCharacter" }, { max: Constants.ogDescriptionMaxLength }))}
                                    error={meta.touched && !!meta.error}
                                    inputProps={{
                                        maxLength: Constants.ogDescriptionMaxLength,
                                    }}
                                    {...field}
                                />
                            )}
                        </Field>
                    </Box>
                    <Box className="image-section-meta-description-field">
                        <Field
                            key={I18n.formatMessage({ id: "common.form.metaDescription" })}
                            name="meta_description"
                            validate={I18nHelpers.formatValidator(Validator.metaDescriptionMaxLength)}
                        >
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "common.form.metaDescription" })}
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.maxCharacter" }, { max: Constants.metaDescriptionMaxLength }))}
                                    error={meta.touched && !!meta.error}
                                    inputProps={{
                                        maxLength: Constants.metaDescriptionMaxLength,
                                    }}
                                    {...field}
                                />
                            )}
                        </Field>
                    </Box>
                </Box>
            </PageCard.Container>

            <PageCard.Container>
                <PageCard.Heading title={I18n.formatMessage({ id: "common.statistics.title" })} />
                <Box mt="30px" mb="30px">
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Typography color="textSecondary" gutterBottom>
                                {I18n.formatMessage({ id: "common.statistics.updatedAtLabel" })}
                            </Typography>
                            <Typography variant="body2">
                                {props.productCategory?.updated_at ? DateUtils.format(props.productCategory?.updated_at, DateFormat.minuteDateTime) : ""}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography color="textSecondary" gutterBottom>
                                {I18n.formatMessage({ id: "common.statistics.updatedByLabel" })}
                            </Typography>
                            <Typography variant="body2">{props.productCategory?.updated_by?.name ?? null}</Typography>
                        </Grid>
                    </Grid>
                </Box>
            </PageCard.Container>

            <SavePanel>
                <Grid container justify="space-between">
                    <Button variant="outlined" color="primary" onClick={onCancelClick}>
                        {I18n.formatMessage({ id: "common.cancel" })}
                    </Button>
                    <Button
                        type="submit"
                        startIcon={<Icon className="fas fa-save" />}
                        variant="contained"
                        color="secondary"
                        disabled={!props.formProps.dirty || !props.formProps.isValid}
                    >
                        {I18n.formatMessage({ id: `common.${props.formType === FormType.create ? "create" : "save"}` })}
                    </Button>
                </Grid>
            </SavePanel>
            <FullscreenLoader visible={props.formProps.isSubmitting} />
        </Form>
    );
};
