import React from "react";
import { TypeRowProps } from "@inovua/reactdatagrid-community/types";
import { withStyles, WithStyles } from "@bigfish/admin-ui/core";
import { I18n } from "I18n/I18n";
import { dataGridStyles } from "@bigfish/admin-ui/styles";
import UnControlledDataGrid from "Components/DataGrid/UCDataGrid";
import { RoleListItem, PermissionType } from "Api/graphql/admin/types";
import { Api } from "Api/Api";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { ApiError } from "Api/ApiError";
import { Path } from "Utils/Path";
import { connect } from "react-redux";
import { ApplicationState } from "Redux/rootReducer";
import { AuthSelectors } from "Redux/selectors/authSelectors";
import { DataSource } from "Components/DataGrid/DataGridUtils";
import { CustomTypeColumn } from "Utils/DataGridUtils";
import { Constants } from "Utils/Constants";

type ReduxProps = {
    permissions: PermissionType[];
    isSuperadmin: boolean;
};

type Props = ReduxProps & WithSnackbarProps & WithStyles<typeof dataGridStyles>;

class RoleListDataGrid extends React.Component<Props> {
    private getColumns = (): CustomTypeColumn[] => {
        return [
            { name: "id", header: I18n.formatMessage({ id: "pages.roleList.grid.column.id" }), defaultWidth: 100, sortable: false, filterDelay: Constants.filterDelayMS },
            {
                name: "title",
                header: I18n.formatMessage({ id: "pages.roleList.grid.column.title" }),
                defaultFlex: 1,
                minWidth: 170,
                sortable: false,
                filterDelay: Constants.filterDelayMS,
            },
        ];
    };

    private getDataSource = async (): Promise<DataSource<RoleListItem>> => {
        try {
            const result = await Api.listRoles({ first: 9999 });
            return { data: result.data, count: result.paginatorInfo.total };
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }
        return { data: [], count: 0 };
    };

    private getRowLink = (rowProps: TypeRowProps): string => {
        return Path.roleEdit(rowProps.data.id);
    };

    render() {
        return (
            <UnControlledDataGrid<RoleListItem>
                className={this.props.classes.dataGrid}
                rowHeight={50}
                style={{ minHeight: 800 }}
                dataSource={this.getDataSource}
                columns={this.getColumns()}
                activeCell={null}
                rowLink={this.getRowLink}
                onRowClick={this.props.isSuperadmin || this.props.permissions.includes(PermissionType.role_update) ? undefined : () => {}}
            />
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { permissions: AuthSelectors.getPermissions(state.auth), isSuperadmin: AuthSelectors.isSuperadmin(state.auth) };
};

export default withSnackbar(connect(mapStateToProps)(withStyles(dataGridStyles, { withTheme: true })(RoleListDataGrid)));
