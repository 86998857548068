import React from "react";
import { Api } from "Api/Api";
import { TypeRowProps } from "@inovua/reactdatagrid-community/types";
import DataGrid from "Components/DataGrid/DataGrid";
import { GalleryField, GalleryFilters, GalleryListItem, GalleryOrder } from "Api/graphql/admin/types";
import { DataGridUrlQueryParser, QueryParserType } from "Components/DataGrid/DataGridUrlQueryParser";
import { ApiError } from "Api/ApiError";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { Path } from "Utils/Path";
import { I18n } from "I18n/I18n";
import { FilterValue, DataSource } from "Components/DataGrid/DataGridUtils";
import { DataGridParams } from "Components/DataGrid/UrlQueryParser";
import { CustomTypeColumn } from "Utils/DataGridUtils";
import { Constants } from "Utils/Constants";

type Props = WithSnackbarProps;

class GalleryListDataGrid extends React.Component<Props> {
    private getColumns = (): CustomTypeColumn[] => {
        return [
            { name: "id", header: I18n.formatMessage({ id: "pages.galleryList.grid.column.id" }), width: 132, filterDelay: Constants.filterDelayMS },
            {
                name: "title",
                header: I18n.formatMessage({ id: "pages.galleryList.grid.column.title" }),
                defaultFlex: 1,
                minWidth: 300,
                filterDelay: Constants.filterDelayMS,
            },
            {
                name: "handle",
                header: I18n.formatMessage({ id: "pages.galleryList.grid.column.handle" }),
                sortable: false,
                width: 220,
            },
            { name: "image_count", header: I18n.formatMessage({ id: "pages.galleryList.grid.column.imageCount" }), width: 210, sortable: false },
            { name: "updated_at", header: I18n.formatMessage({ id: "pages.galleryList.grid.column.updatedAt" }), width: 210 },
        ];
    };

    private filterValue: FilterValue[] = [
        { name: "id", operator: "eq", type: "number" },
        { name: "title", operator: "contains", type: "string" },
    ];

    private urlQueryParser = new DataGridUrlQueryParser({
        filters: {
            id: QueryParserType.string,
            title: QueryParserType.string,
        },
        sortField: GalleryField,
    });

    private sortFieldToSortOrder = {
        [GalleryField.id]: "id",
        [GalleryField.title]: "title",
        [GalleryField.updated_at]: "updated_at",
    };

    private dataSource = async (props: DataGridParams<GalleryOrder, GalleryFilters>): Promise<DataSource<GalleryListItem>> => {
        try {
            const result = await Api.galleries({ first: props.limit, page: props.page, filters: props.filters, sortBy: props.sortBy });
            return { data: result.data, count: result.paginatorInfo.total };
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }
        return { data: [], count: 0 };
    };

    private getRowLink = (rowProps: TypeRowProps): string => {
        return Path.galleryEdit(rowProps.data.id);
    };

    render() {
        return (
            <DataGrid
                urlQueryParser={this.urlQueryParser}
                rowHeight={50}
                style={{ minHeight: 800 }}
                dataSource={this.dataSource}
                filterValues={this.filterValue}
                columns={this.getColumns()}
                rowLink={this.getRowLink}
                sortFieldToSortOrder={this.sortFieldToSortOrder}
                activeCell={null}
            />
        );
    }
}

export default withSnackbar(GalleryListDataGrid);
