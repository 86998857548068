import { gql } from "@apollo/client";
import { redirectionListItemFragment } from "../fragments/redirectionListItemFragment";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";

export const redirectionsQuery = gql`
    query redirections($filters: RedirectionFilters, $sortBy: RedirectionOrder, $first: Int!, $page: Int) {
        redirections(first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            data {
                ...RedirectionListItem
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${redirectionListItemFragment}
    ${paginatorInfoFragment}
`;
