import React, { useCallback, useState } from "react";
import { Form } from "Components/Form";
import { Button, Grid, Icon, TextField, Box, Tooltip } from "@bigfish/admin-ui/core";
import { FastField, Field, FieldProps, FormikProps } from "formik";
import { GiftCardDesign, GiftCardOrderPrizeInput, GiftCardOrderTypeWithTitle } from "Api/graphql/admin/types";
import { I18n } from "Src/i18n/I18n";
import { FullscreenLoader, PageCard, SavePanel } from "@bigfish/admin-ui/components";
import { Path } from "Utils/Path";
import { useHistory } from "react-router-dom";
import { I18nHelpers } from "I18n/I18nHelpers";
import { Validator } from "Utils/Validator";
import { MediaLibraryHelper } from "Utils/MediaLibraryHelper";
import "../styles.css";
import { GiftCardPrizeModal } from "./GiftCardModal";
import UnControlledDataGrid from "Components/DataGrid/UCDataGrid";
import styled from "styled-components";
import SelectInput from "Components/SelectInput";

export type GiftCardOrderPrizeInputLocal = Omit<GiftCardOrderPrizeInput, "gift_cards"> & { gift_cards: Array<LocalGiftCard> };

export type LocalGiftCard = { index: number; value: number | null; mail_to: string };

type Props = {
    designs: GiftCardDesign[];
    types: GiftCardOrderTypeWithTitle[];
    formProps: FormikProps<GiftCardOrderPrizeInputLocal>;
};

export const GiftCardOrderPrizeForm = (props: Props) => {
    const history = useHistory();
    const onCancelClick = useCallback(() => {
        history.push(Path.giftCardOrderList);
    }, [history]);

    const [editedGiftCardIndex, setEditedGiftCardIndex] = useState<number | "new" | null>(null);

    const renderGiftCardTable = (
        <UnControlledDataGrid
            dataSource={() => Promise.resolve({ data: props.formProps.values.gift_cards, count: props.formProps.values.gift_cards.length })}
            columns={(() => {
                return [
                    {
                        name: "value",
                        header: I18n.formatMessage({ id: "pages.giftCardOrderPrize.form.table.columns.value" }),
                        minWidth: 200,
                        render: ({ value }: { value: string }) => {
                            return <div>{I18n.formatCurrency(Number.parseInt(value, 10))}</div>;
                        },
                    },
                    {
                        name: "mail_to",
                        header: I18n.formatMessage({ id: "pages.giftCardOrderPrize.form.table.columns.mail_to" }),
                        defaultFlex: 1,
                    },
                    {
                        name: "index",
                        header: "",
                        minWidth: 200,
                        render: ({ value: index }: { value: number }) => {
                            return (
                                <FunctionButtons>
                                    <Tooltip
                                        className="draggable-icon-wrapper"
                                        onClick={() => setEditedGiftCardIndex(index)}
                                        title={I18n.formatMessage({ id: "common.edit" })}
                                        arrow
                                    >
                                        <Icon className="fa fa-pencil-alt draggable-icon" />
                                    </Tooltip>
                                    <Tooltip
                                        className="draggable-icon-wrapper"
                                        onClick={() => {
                                            const newGiftCards = [...props.formProps.values.gift_cards];
                                            newGiftCards.splice(index, 1);
                                            props.formProps.setFieldValue(
                                                "gift_cards",
                                                newGiftCards.map((gc: LocalGiftCard, index: number) => ({ ...gc, index }))
                                            );
                                        }}
                                        title={I18n.formatMessage({ id: "common.delete" })}
                                        arrow
                                    >
                                        <Icon className="fas fa-trash draggable-icon" />
                                    </Tooltip>
                                </FunctionButtons>
                            );
                        },
                    },
                ];
            })()}
            style={{ maxHeight: 800, minHeight: 200 }}
            emptyText={I18n.formatMessage({ id: "pages.giftCardOrderPrize.form.table.empty" })}
            pagination={false}
        />
    );

    return (
        <Form formProps={props.formProps}>
            <PageCard.Container>
                <PageCard.Heading title={I18n.formatMessage({ id: "pages.giftCardOrderPrize.form.designLabel" })} />
                <Box mt="30px" />

                {props.designs.map(d => (
                    <img
                        key={`design-${d.id}`}
                        className={`image-radio-wrapper ${props.formProps.values.design === d.id ? "design-selected" : ""}`}
                        src={`${MediaLibraryHelper.getImageUrl(`w_220,f_auto,q_90/${d.slug}.jpeg`)}`}
                        onClick={() => props.formProps.setFieldValue("design", d.id)}
                    />
                ))}

                <Box mt="30px" />

                <PageCard.Heading title={I18n.formatMessage({ id: "pages.giftCardOrderPrize.form.basicDataSection" })} />
                <Box mt="30px" />

                <Field name="name_from" validate={I18nHelpers.formatValidator(Validator.required)}>
                    {({ field, meta }: FieldProps) => (
                        <TextField
                            type="text"
                            label={I18n.formatMessage({ id: "pages.giftCardOrderPrize.form.nameFromLabel" })}
                            fullWidth
                            variant="outlined"
                            required
                            helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                            error={meta.touched && !!meta.error}
                            {...field}
                        />
                    )}
                </Field>

                <Box mt="30px" />

                <Field name="message">
                    {({ field, meta }: FieldProps) => (
                        <TextField
                            type="text"
                            label={I18n.formatMessage({ id: "pages.giftCardOrderPrize.form.messageLabel" })}
                            fullWidth
                            multiline
                            variant="outlined"
                            helperText={Form.getHelperText(meta, "")}
                            error={meta.touched && !!meta.error}
                            {...field}
                        />
                    )}
                </Field>

                <Box mt="30px" />

                <Field name="payment_reference" validate={I18nHelpers.formatValidator(Validator.required)}>
                    {({ field, meta }: FieldProps) => (
                        <TextField
                            type="text"
                            label={I18n.formatMessage({ id: "pages.giftCardOrderPrize.form.paymentReferenceLabel" })}
                            fullWidth
                            variant="outlined"
                            required
                            helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                            error={meta.touched && !!meta.error}
                            {...field}
                        />
                    )}
                </Field>

                <Box mt="30px" />

                <FastField name="type" validate={I18nHelpers.formatValidator(Validator.required)}>
                    {({ field, meta }: FieldProps) => (
                        <SelectInput
                            label={I18n.formatMessage({ id: "pages.giftCardOrderPrize.form.typeLabel" })}
                            fullWidth
                            variant="outlined"
                            options={props.types}
                            helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                            {...field}
                            error={meta.touched && !!meta.error}
                        />
                    )}
                </FastField>

                <Box mt="30px" />

                <PageCard.Heading
                    title={I18n.formatMessage({ id: "pages.giftCardOrderPrize.form.winnersSection" })}
                    rightContent={
                        <Button
                            startIcon={<Icon className="fa fa-plus-circle" />}
                            variant="contained"
                            size="medium"
                            color="secondary"
                            onClick={() => setEditedGiftCardIndex("new")}
                        >
                            {I18n.formatMessage({ id: "pages.giftCardOrderPrize.form.newWinnerButton" })}
                        </Button>
                    }
                />

                <Box mt="30px" />

                {renderGiftCardTable}
            </PageCard.Container>

            <SavePanel>
                <Grid container justify="space-between">
                    <Button variant="outlined" color="primary" onClick={onCancelClick}>
                        {I18n.formatMessage({ id: "common.cancel" })}
                    </Button>
                    <Button
                        type="submit"
                        startIcon={<Icon className="fas fa-save" />}
                        variant="contained"
                        color="secondary"
                        disabled={!props.formProps.dirty || !props.formProps.isValid}
                    >
                        {I18n.formatMessage({ id: "common.create" })}
                    </Button>
                </Grid>
            </SavePanel>
            <FullscreenLoader visible={props.formProps.isSubmitting} />

            {editedGiftCardIndex !== null && (
                <GiftCardPrizeModal
                    title={I18n.formatMessage({ id: `pages.giftCardOrderPrize.modal.title${editedGiftCardIndex === "new" ? "Create" : "Edit"}` })}
                    isVisible={true}
                    onClose={() => setEditedGiftCardIndex(null)}
                    editedItem={editedGiftCardIndex !== null && editedGiftCardIndex !== "new" ? props.formProps.values.gift_cards[editedGiftCardIndex] : "new"}
                    onSave={(value: string, mail_to: string) => {
                        if (editedGiftCardIndex === "new") {
                            props.formProps.setFieldValue("gift_cards", [
                                ...props.formProps.values.gift_cards,
                                { index: props.formProps.values.gift_cards.length, value, mail_to },
                            ]);
                        } else {
                            if (editedGiftCardIndex === null) return;

                            const newGiftCards = [...props.formProps.values.gift_cards];
                            newGiftCards[editedGiftCardIndex] = { index: editedGiftCardIndex, value: Number.parseInt(value, 10), mail_to };
                            props.formProps.setFieldValue("gift_cards", newGiftCards);
                        }
                        setEditedGiftCardIndex(null);
                    }}
                />
            )}
        </Form>
    );
};

const FunctionButtons = styled.div`
    width: 50px;
    display: flex;
    justify-content: space-between;
`;
