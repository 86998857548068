import React, { useCallback, useState } from "react";
import { Form, FormType } from "Components/Form";
import { Button, FormControl, FormControlLabel, Grid, Icon, TextField, Typography, Box, Tooltip } from "@bigfish/admin-ui/core";
import { Field, FieldProps, FormikProps } from "formik";
import { User, UserInput } from "Api/graphql/admin/types";
import { Checkbox } from "@bigfish/admin-ui/core";
import { I18n } from "Src/i18n/I18n";
import { FullscreenLoader, PageCard, SavePanel } from "@bigfish/admin-ui/components";
import { Path } from "Utils/Path";
import { useHistory } from "react-router-dom";
import { I18nHelpers } from "I18n/I18nHelpers";
import { Validator } from "Utils/Validator";
import { CustomFormHelperText } from "Components/CustomFormHelperText";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import "./styles.css";
import { Api } from "Api/Api";
import { useSnackbar } from "notistack";
import { ApiError } from "Api/ApiError";
import { FunctionConfirmModal } from "Components/FunctionConfirmModal";
import { ReplaceCardToPlasticModal } from "./ReplaceCardToPlasticModal";
import { AssignModal } from "./AssignModal";
import styled from "styled-components";
import { ChangeModal } from "./ChangeModal";

export type UserFormValues = UserInput & { rplus_card_number: string; remove_rplus_card: boolean; replacement_rplus_card: string };

type Props = {
    formType: FormType;
    formProps: FormikProps<UserFormValues>;
    user?: User;
    onRefetchUser: () => Promise<void>;
};

export const ProfileTab = (props: Props) => {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const onCancelClick = useCallback(() => {
        history.push(Path.userList);
    }, [history]);

    const [rossmannPlusCardNumber, setRossmannPlusCardNumber] = useState<string | null>(props.user?.rossmann_plus_profile?.card_number ?? null);
    const [isReplaceToVirtualSuccess, setIsReplaceToVirtualSuccess] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isPlasticModalOpen, setIsPlasticModalOpen] = useState<boolean>(false);
    const [isVirtualModalOpen, setIsVirtualModalOpen] = useState<boolean>(false);
    const [isAssignModalOpen, setIsAssignModalOpen] = useState<boolean>(false);
    const [isChangeModalOpen, setIsChangeModalOpen] = useState<boolean>(false);

    const onReplaceToVirtualClick = async () => {
        setIsLoading(true);

        if (!props.user) return;

        try {
            const cardNumberResult = await Api.replaceCardToVirtual(props.user.id);
            setRossmannPlusCardNumber(cardNumberResult ?? "");
            setIsReplaceToVirtualSuccess(true);
            enqueueSnackbar(I18n.formatMessage({ id: "pages.user.form.replaceCardToVirtualSuccess" }), { variant: "success" });
        } catch (error) {
            setIsLoading(false);
            if (error instanceof ApiError) {
                enqueueSnackbar(error.message, { variant: "error" });
                return;
            }
            enqueueSnackbar(I18n.formatMessage({ id: "pages.user.form.replaceCardToVirtualError" }), { variant: "error" });
        } finally {
            setIsLoading(false);
        }
    };

    const onReplaceToPlasticClick = async (cardNumber: string) => {
        setIsLoading(true);

        const { user } = props;
        if (!user) return;

        try {
            const response = await Api.updateUser(
                user.id,
                {
                    firstname: user.firstname ?? "",
                    lastname: user.lastname ?? "",
                    email: user.email,
                    banktransfer_enabled: user.banktransfer_enabled,
                    cod_disabled: user.cod_disabled,
                    is_active: user.is_active,
                    newsletter: user.newsletter,
                },
                null,
                cardNumber
            );

            if (response?.id) {
                setRossmannPlusCardNumber(response.rossmann_plus_profile?.card_number ?? "");
                enqueueSnackbar(I18n.formatMessage({ id: "pages.user.form.replaceCardToPlasticSuccess" }), { variant: "success" });
            }
        } catch (error) {
            if (error instanceof ApiError) {
                const replacementRplusCardError = error.fieldErrors.find(e => e.name === "replacement_rplus_card")?.message;
                if (replacementRplusCardError) {
                    enqueueSnackbar(replacementRplusCardError, { variant: "error" });
                    return;
                }
            }
            enqueueSnackbar(I18n.formatMessage({ id: "pages.user.form.replaceCardToPlasticError" }), { variant: "error" });
            return;
        } finally {
            setIsLoading(false);
        }
    };

    const onAssignClick = async (cardNumber: string) => {
        setIsLoading(true);

        const { user } = props;
        if (!user) return;

        try {
            const response = await Api.assignCardNumber(user.id, cardNumber);

            if (response) {
                setRossmannPlusCardNumber(response);
                enqueueSnackbar(I18n.formatMessage({ id: "pages.user.form.assignSuccess" }), { variant: "success" });
                props.onRefetchUser();
            }
        } catch (error) {
            if (error instanceof ApiError) {
                enqueueSnackbar(error.message, { variant: "error" });
                return;
            }
            enqueueSnackbar(I18n.formatMessage({ id: "pages.user.form.assignError" }), { variant: "error" });
            return;
        } finally {
            setIsLoading(false);
        }
    };

    const onChangeClick = async (cardNumber: string) => {
        setIsLoading(true);

        const { user } = props;
        if (!user) return;

        try {
            const response = await Api.changeCardNumber(user.id, cardNumber);

            if (response) {
                setRossmannPlusCardNumber(response);
                enqueueSnackbar(I18n.formatMessage({ id: "pages.user.form.changeSuccess" }), { variant: "success" });
            }
        } catch (error) {
            if (error instanceof ApiError) {
                enqueueSnackbar(error.message, { variant: "error" });
                return;
            }
            enqueueSnackbar(I18n.formatMessage({ id: "pages.user.form.changeError" }), { variant: "error" });
            return;
        } finally {
            setIsLoading(false);
        }
    };

    const renderButtons = () => {
        const assignButton = (
            <Button
                startIcon={<Icon className="fas fa-user-check" />}
                variant="outlined"
                size="medium"
                color="secondary"
                onClick={() => setIsAssignModalOpen(true)}
                disabled={isLoading}
            >
                {I18n.formatMessage({ id: "pages.user.form.rossmannPlusAssignCardNumber" })}
            </Button>
        );

        const toPlasticButton = (
            <Button
                startIcon={<Icon className="far fa-credit-card" />}
                variant="outlined"
                size="medium"
                color="secondary"
                onClick={() => setIsPlasticModalOpen(true)}
                disabled={isLoading}
            >
                {I18n.formatMessage({ id: "pages.user.form.rossmannPlusReplaceCardToPlastic" })}
            </Button>
        );

        const replaceButton = (
            <Button
                startIcon={<Icon className="fas fa-globe" />}
                variant="outlined"
                size="medium"
                color="secondary"
                onClick={() => setIsVirtualModalOpen(true)}
                disabled={isLoading}
            >
                {I18n.formatMessage({ id: "pages.user.form.rossmannPlusReplaceCardToVirtual" })}
            </Button>
        );

        if (!props.user?.rossmann_plus_profile?.card_number) return assignButton;

        if (!props.user.rossmann_plus_profile.is_virtual_card && !isReplaceToVirtualSuccess) {
            return (
                <>
                    {toPlasticButton}
                    <Box mt="10px" />
                    {replaceButton}
                </>
            );
        }

        return replaceButton;
    };

    return (
        <>
            <Form formProps={props.formProps}>
                <Box mt="30px" />

                <PageCard.Heading title={I18n.formatMessage({ id: "pages.user.form.personalDataSection" })} />

                <Box mt="30px" />

                <Field name="lastname" validate={I18nHelpers.formatValidator(Validator.required)}>
                    {({ field, meta }: FieldProps) => (
                        <TextField
                            type="text"
                            label={I18n.formatMessage({ id: "pages.user.form.lastNameLabel" })}
                            fullWidth
                            variant="outlined"
                            required
                            helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                            error={meta.touched && !!meta.error}
                            {...field}
                        />
                    )}
                </Field>

                <Box mt="30px" />

                <Field name="firstname" validate={I18nHelpers.formatValidator(Validator.required)}>
                    {({ field, meta }: FieldProps) => (
                        <TextField
                            type="text"
                            label={I18n.formatMessage({ id: "pages.user.form.firstNameLabel" })}
                            fullWidth
                            variant="outlined"
                            required
                            helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                            error={meta.touched && !!meta.error}
                            {...field}
                        />
                    )}
                </Field>

                <Box mt="30px" />

                <Field name="email" validate={I18nHelpers.formatValidator(Validator.required)}>
                    {({ field, meta }: FieldProps) => (
                        <TextField
                            type="text"
                            label={I18n.formatMessage({ id: "pages.user.form.emailLabel" })}
                            fullWidth
                            variant="outlined"
                            required
                            helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                            error={meta.touched && !!meta.error}
                            {...field}
                        />
                    )}
                </Field>

                <Box mt="30px" />

                <Typography color="textSecondary" gutterBottom>
                    {I18n.formatMessage({ id: "pages.user.form.phoneNumberLabel" })}
                </Typography>
                <Typography variant="subtitle1">{props.user?.phone_number ? `${props.user.country_code} ${props.user.area_code} ${props.user.phone_number}` : "-"}</Typography>

                <>
                    <Box mt="30px" />
                    <PageCard.Heading title={I18n.formatMessage({ id: "pages.user.form.rossmannPlusSection" })} />

                    <Typography color="textSecondary" gutterBottom>
                        {I18n.formatMessage({ id: "pages.user.form.rossmannPlusReplaceCardNumber" })}
                    </Typography>

                    <CardNumberContainer>
                        <Typography variant="subtitle1">{rossmannPlusCardNumber || "-"}</Typography>
                        {!!rossmannPlusCardNumber && (
                            <Tooltip title={I18n.formatMessage({ id: "pages.user.form.rossmannPlusReplaceCardNumber.changeTooltip" })}>
                                <Icon className="fa fa-pencil-alt" onClick={() => setIsChangeModalOpen(true)} />
                            </Tooltip>
                        )}
                    </CardNumberContainer>
                    <br />
                    {renderButtons()}
                </>

                <Box mt="30px" />

                <PageCard.Heading title={I18n.formatMessage({ id: "pages.user.form.accountSection" })} />

                <Typography color="textSecondary" gutterBottom>
                    {I18n.formatMessage({ id: "pages.user.form.idLabel" })}
                </Typography>
                <Typography variant="subtitle1">{props.user?.id}</Typography>

                <Box mt="30px" />

                <Typography color="textSecondary" gutterBottom>
                    {I18n.formatMessage({ id: "pages.user.form.createdAtLabel" })}
                </Typography>
                <Typography variant="subtitle1">{props.user?.created_at ? DateUtils.format(props.user.created_at, DateFormat.minuteDateTime) : ""}</Typography>

                <Box mt="30px" />

                <Typography color="textSecondary" gutterBottom>
                    {I18n.formatMessage({ id: "pages.user.form.lastLogin" })}
                </Typography>
                <Typography variant="subtitle1">{props.user?.last_login ? DateUtils.format(props.user.last_login, DateFormat.minuteDateTime) : ""}</Typography>

                <Box mt="30px" />

                <Typography variant="body1" component="h3" color="textSecondary">
                    {I18n.formatMessage({ id: "pages.user.form.bankTransferEnabledLabel" })}
                </Typography>

                <Field name="banktransfer_enabled">
                    {({ field, meta }: FieldProps) =>
                        field.value !== undefined && (
                            <Box mt="15px" ml="10px">
                                <FormControl error={meta.touched && !!meta.error}>
                                    <FormControlLabel control={<Checkbox {...field} checked={field.value} />} label={I18n.formatMessage({ id: "common.yes" })} />
                                </FormControl>
                                <CustomFormHelperText meta={meta} />
                            </Box>
                        )
                    }
                </Field>

                <Box mt="30px" />

                <Typography variant="body1" component="h3" color="textSecondary">
                    {I18n.formatMessage({ id: "pages.user.form.codDisabledLabel" })}
                </Typography>

                <Field name="cod_disabled">
                    {({ field, meta }: FieldProps) =>
                        field.value !== undefined && (
                            <Box mt="15px" ml="10px">
                                <FormControl error={meta.touched && !!meta.error}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                {...field}
                                                checked={!field.value}
                                                onChange={() => {
                                                    props.formProps.setFieldValue("cod_disabled", !props.formProps.values.cod_disabled);
                                                    props.formProps.setFieldTouched("cod_disabled", true);
                                                }}
                                            />
                                        }
                                        label={I18n.formatMessage({ id: "common.yes" })}
                                    />
                                </FormControl>
                                <CustomFormHelperText meta={meta} />
                            </Box>
                        )
                    }
                </Field>

                <Box mt="30px" />

                <Typography variant="body1" component="h3" color="textSecondary">
                    {I18n.formatMessage({ id: "pages.user.form.newsletterLabel" })}
                </Typography>

                <Field name="newsletter">
                    {({ field, meta }: FieldProps) =>
                        field.value !== undefined && (
                            <Box mt="15px" ml="10px">
                                <FormControl error={meta.touched && !!meta.error}>
                                    <FormControlLabel
                                        control={<Checkbox {...field} checked={field.value} />}
                                        label={I18n.formatMessage({ id: "pages.user.form.newsletterCheckboxLabel" })}
                                    />
                                </FormControl>
                                <CustomFormHelperText meta={meta} />
                            </Box>
                        )
                    }
                </Field>

                <Box mt="30px" />

                <Typography variant="body1" component="h3" color="textSecondary">
                    {I18n.formatMessage({ id: "pages.user.form.isActiveLabel" })}
                </Typography>

                <Field name="is_active">
                    {({ field, meta }: FieldProps) =>
                        field.value !== undefined && (
                            <Box mt="15px" ml="10px">
                                <FormControl error={meta.touched && !!meta.error}>
                                    <FormControlLabel
                                        control={<Checkbox {...field} checked={field.value} />}
                                        label={I18n.formatMessage({ id: "pages.user.form.isActiveCheckboxLabel" })}
                                    />
                                </FormControl>
                                <CustomFormHelperText meta={meta} />
                            </Box>
                        )
                    }
                </Field>

                <SavePanel>
                    <Grid container justify="space-between">
                        <Button variant="outlined" color="primary" onClick={onCancelClick}>
                            {I18n.formatMessage({ id: "common.cancel" })}
                        </Button>
                        <Button
                            type="submit"
                            startIcon={<Icon className="fas fa-save" />}
                            variant="contained"
                            color="secondary"
                            disabled={!props.formProps.dirty || !props.formProps.isValid}
                        >
                            {I18n.formatMessage({ id: `common.${props.formType === FormType.create ? "create" : "save"}` })}
                        </Button>
                    </Grid>
                </SavePanel>
                <FullscreenLoader visible={props.formProps.isSubmitting} />
            </Form>
            <FunctionConfirmModal
                title={I18n.formatMessage({ id: "pages.user.form.replaceCardToVirtualModal.title" })}
                description={I18n.formatMessage({ id: "pages.user.form.replaceCardToVirtualModal.description" })}
                isVisible={isVirtualModalOpen}
                onClose={() => setIsVirtualModalOpen(false)}
                onFunctionClick={() => {
                    setIsVirtualModalOpen(false);
                    onReplaceToVirtualClick();
                }}
                leftButtonLabel={I18n.formatMessage({ id: "common.cancel" })}
                rightButtonLabel={I18n.formatMessage({ id: "common.yes" })}
            />

            <ReplaceCardToPlasticModal onClose={() => setIsPlasticModalOpen(false)} isVisible={isPlasticModalOpen} onReplaceToPlasticClick={onReplaceToPlasticClick} />

            <AssignModal onClose={() => setIsAssignModalOpen(false)} isVisible={isAssignModalOpen} onSubmit={onAssignClick} />

            <ChangeModal onClose={() => setIsChangeModalOpen(false)} isVisible={isChangeModalOpen} onSubmit={onChangeClick} />
        </>
    );
};

const CardNumberContainer = styled.div`
    display: flex;
    align-items: center;

    span {
        margin-left: 5px;
        font-size: 17px;
        color: #7895c1;
        cursor: pointer;
    }
`;
