import { gql } from "@apollo/client";
import { productVariantListItemFragment } from "../fragments/productVariantListItemFragment";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";

export const listProductVariantsQuery = gql`
    query listProductVariants($filters: ProductFilters, $sortBy: ProductSort, $first: Int!, $page: Int) {
        listProductVariants(first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            data {
                ...ProductVariantListItem
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${productVariantListItemFragment}
    ${paginatorInfoFragment}
`;
