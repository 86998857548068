import { gql } from "@apollo/client";

export const orderItemFragment = gql`
    fragment OrderItem on OrderItem {
        id
        product {
            name
            lfdnr
            ean
        }
        quantity
        unit_price
        sub_total
        discount_total
        discounts {
            promotion_name
            amount
        }
        type
        quantity_missing
        quantity_delivered
    }
`;
