import React, { useCallback } from "react";
import { Form, FormType } from "Components/Form";
import { Button, FormControl, FormControlLabel, Grid, Icon, TextField, Typography, Box } from "@bigfish/admin-ui/core";
import { Field, FieldProps, FormikErrors, FormikProps } from "formik";
import { Checkbox } from "@bigfish/admin-ui/core";
import { I18n } from "Src/i18n/I18n";
import { FullscreenLoader, PageCard, SavePanel } from "@bigfish/admin-ui/components";
import { Path } from "Utils/Path";
import { useHistory } from "react-router-dom";
import { I18nHelpers } from "I18n/I18nHelpers";
import { Validator } from "Utils/Validator";
import { ProductBrand, ProductBrandInput } from "Api/graphql/admin/types";
import MediaLibraryImageInput from "Components/Inputs/MediaLibraryImageInput";
import { CustomFormHelperText } from "Components/CustomFormHelperText";
import { Constants } from "Utils/Constants";
import ProductCatalogDataGrid from "Pages/ProductCatalog/ProductCatalogDataGrid";
import { DateFormat, DateUtils } from "Utils/DateUtils";

export type ProductBrandFormValues = ProductBrandInput;

type Props = {
    formType: FormType;
    formProps: FormikProps<ProductBrandFormValues>;
    productBrand?: ProductBrand;
};

export const productBrandValidator = () => (values: ProductBrandFormValues): FormikErrors<ProductBrandFormValues> => {
    const errors: { [key in keyof ProductBrandFormValues]?: any } = {};

    errors.slug = I18nHelpers.formatValidator(Validator.slug)(values.slug);

    return Form.cleanupFormikErrors(errors);
};

export const ProductBrandForm = (props: Props) => {
    const history = useHistory();
    const onCancelClick = useCallback(() => {
        history.push(Path.productBrandList);
    }, [history]);

    return (
        <Form formProps={props.formProps}>
            <PageCard.Container>
                <PageCard.Heading title={I18n.formatMessage({ id: "common.form.basicData" })} />

                <Box mt="30px" />
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Typography color="textSecondary" gutterBottom>
                            {I18n.formatMessage({ id: "pages.productBrand.form.idLabel" })}
                        </Typography>
                        <Typography variant="body2">{props.productBrand?.id ?? ""}</Typography>
                    </Grid>
                    <Grid item xs={6} />
                </Grid>

                <Box mt="30px" />

                <Grid container spacing={3}>
                    <Grid item xs={5}>
                        <Typography color="textSecondary" gutterBottom>
                            {I18n.formatMessage({ id: "pages.productBrand.form.evoNameLabel" })}
                        </Typography>
                        <Typography variant="body2">{props.productBrand?.evo_name ?? ""}</Typography>
                    </Grid>
                    <Grid item xs={1} className="overwrite-arrow">
                        <i className="fas fa-arrow-left"></i>
                    </Grid>
                    <Grid item xs={6}>
                        <Field name="name" validate={I18nHelpers.formatValidator(Validator.required)}>
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "pages.productBrand.form.nameLabel" })}
                                    fullWidth
                                    variant="outlined"
                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                                    error={meta.touched && !!meta.error}
                                    {...field}
                                />
                            )}
                        </Field>
                    </Grid>
                </Grid>

                <Box mt="30px" />
                <Field name="slug" validate={I18nHelpers.formatValidator(Validator.required)}>
                    {({ field, meta }: FieldProps) => (
                        <TextField
                            type="text"
                            label={I18n.formatMessage({ id: "pages.productBrand.form.slugLabel" })}
                            fullWidth
                            variant="outlined"
                            helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                            error={meta.touched && !!meta.error}
                            {...field}
                        />
                    )}
                </Field>
                <Box mt="30px" />
                <PageCard.Heading title={I18n.formatMessage({ id: "common.form.settings" })} />
                <Typography variant="body1" component="h3" color="textSecondary">
                    {I18n.formatMessage({ id: "pages.productBrand.form.statusLabel" })}
                </Typography>

                <Field name="is_public">
                    {({ field, meta }: FieldProps) =>
                        field.value !== undefined && (
                            <Box mt="15px" ml="10px">
                                <FormControl error={meta.touched && !!meta.error}>
                                    <FormControlLabel
                                        control={<Checkbox {...field} checked={field.value} />}
                                        label={I18n.formatMessage({ id: "pages.productBrand.form.isActiveLabel" })}
                                    />
                                </FormControl>
                                <CustomFormHelperText meta={meta} />
                            </Box>
                        )
                    }
                </Field>

                <Box mt="30px" />

                <Field name="is_rossmann">
                    {({ field, meta }: FieldProps) =>
                        field.value !== undefined && (
                            <Box mt="15px" ml="10px">
                                <FormControl error={meta.touched && !!meta.error}>
                                    <FormControlLabel
                                        control={<Checkbox {...field} checked={field.value} />}
                                        label={I18n.formatMessage({ id: "pages.productBrand.form.isRossmannLabel" })}
                                    />
                                </FormControl>
                                <CustomFormHelperText meta={meta} />
                            </Box>
                        )
                    }
                </Field>
                <Box mt="30px" />
                <PageCard.Heading title={I18n.formatMessage({ id: "common.form.content" })} />
                <Typography variant="body1" component="h3" color="textSecondary">
                    {I18n.formatMessage({ id: "pages.productBrand.form.logoLabel" })}
                </Typography>
                <Box mt="15px" className="image-section-root">
                    <Box className="image-section-image-field">
                        <div className="og_image_url_wrapper">
                            <Field name="logo">
                                {({ field, meta }: FieldProps) => (
                                    <div>
                                        <MediaLibraryImageInput
                                            id="logo"
                                            name="logo"
                                            value={field.value}
                                            onChange={(value: string | null) => {
                                                props.formProps.setFieldValue("logo", value);
                                                props.formProps.setFieldTouched("logo", true);
                                            }}
                                        />
                                        <CustomFormHelperText
                                            meta={meta}
                                            defaultHelperText={<div style={{ whiteSpace: "nowrap" }}>{I18n.formatMessage({ id: "pages.productBrand.form.logo.helperText" })}</div>}
                                        />
                                    </div>
                                )}
                            </Field>
                        </div>
                    </Box>
                    <Box className="image-section-alt-field">
                        <Field name="logo_title" validate={I18nHelpers.formatValidator(Validator.altTitleMaxLength)}>
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "common.form.altTitleTag" })}
                                    fullWidth
                                    variant="outlined"
                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.maxCharacter" }, { max: Constants.altTitleMaxLength }))}
                                    error={meta.touched && !!meta.error}
                                    inputProps={{
                                        maxLength: Constants.altTitleMaxLength,
                                    }}
                                    {...field}
                                />
                            )}
                        </Field>
                    </Box>
                </Box>

                <Box mt="60px" />

                <PageCard.Heading title={I18n.formatMessage({ id: "pages.productBrand.form.productsSection" })} />

                <Box mt="30px" />
                <ProductCatalogDataGrid isReadOnly productBrandId={props.productBrand?.id} />
                <Box mt="30px" />

                <PageCard.Heading title={I18n.formatMessage({ id: "common.form.metaData" })} />
                <Typography variant="body1" component="h3" color="textSecondary">
                    {I18n.formatMessage({ id: "pages.content.form.ogImage" })}
                </Typography>
                <Box className="image-section-root">
                    <Box className="image-section-image-field">
                        <div className="og_image_url_wrapper">
                            <Field key={"og_image"} name={"og_image"}>
                                {({ field, meta }: FieldProps) => (
                                    <div>
                                        <MediaLibraryImageInput
                                            id="product-category-og_image_url"
                                            name="og_image"
                                            value={field.value}
                                            onChange={(value: string | null) => {
                                                props.formProps.setFieldValue("og_image", value);
                                                props.formProps.setFieldTouched("og_image", true);
                                            }}
                                        />
                                        <CustomFormHelperText meta={meta} />
                                    </div>
                                )}
                            </Field>
                        </div>
                    </Box>
                    <Box className="image-section-title-tag-field">
                        <Field key={I18n.formatMessage({ id: "common.form.ogTitle" })} name="og_title" validate={I18nHelpers.formatValidator(Validator.ogTitleMaxLength)}>
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "common.form.ogTitle" })}
                                    fullWidth
                                    variant="outlined"
                                    error={meta.touched && !!meta.error}
                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.maxCharacter" }, { max: Constants.ogTitleMaxLength }))}
                                    inputProps={{
                                        maxLength: Constants.ogTitleMaxLength,
                                    }}
                                    {...field}
                                />
                            )}
                        </Field>
                    </Box>
                    <Box className="image-section-og-description-field">
                        <Field
                            key={I18n.formatMessage({ id: "common.form.ogDescription" })}
                            name="og_description"
                            validate={I18nHelpers.formatValidator(Validator.ogDescriptionMaxLength)}
                        >
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "common.form.ogDescription" })}
                                    fullWidth
                                    variant="outlined"
                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.maxCharacter" }, { max: Constants.ogDescriptionMaxLength }))}
                                    error={meta.touched && !!meta.error}
                                    inputProps={{
                                        maxLength: Constants.ogDescriptionMaxLength,
                                    }}
                                    {...field}
                                />
                            )}
                        </Field>
                    </Box>
                    <Box className="image-section-meta-description-field">
                        <Field
                            key={I18n.formatMessage({ id: "common.form.metaDescription" })}
                            name="meta_description"
                            validate={I18nHelpers.formatValidator(Validator.metaDescriptionMaxLength)}
                        >
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "common.form.metaDescription" })}
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.maxCharacter" }, { max: Constants.metaDescriptionMaxLength }))}
                                    error={meta.touched && !!meta.error}
                                    inputProps={{
                                        maxLength: Constants.metaDescriptionMaxLength,
                                    }}
                                    {...field}
                                />
                            )}
                        </Field>
                    </Box>
                </Box>
            </PageCard.Container>

            <PageCard.Container>
                <PageCard.Heading title={I18n.formatMessage({ id: "common.statistics.title" })} />
                <Box mt="30px" mb="30px">
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Typography color="textSecondary" gutterBottom>
                                {I18n.formatMessage({ id: "common.statistics.createdAtLabel" })}
                            </Typography>
                            <Typography variant="body2">
                                {props.productBrand?.created_at ? DateUtils.format(props.productBrand?.created_at, DateFormat.minuteDateTime) : null}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} />
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Typography color="textSecondary" gutterBottom>
                                {I18n.formatMessage({ id: "common.statistics.updatedAtLabel" })}
                            </Typography>
                            <Typography variant="body2">
                                {props.productBrand?.updated_at ? DateUtils.format(props.productBrand?.updated_at, DateFormat.minuteDateTime) : null}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography color="textSecondary" gutterBottom>
                                {I18n.formatMessage({ id: "common.statistics.updatedByLabel" })}
                            </Typography>
                            <Typography variant="body2">{props.productBrand?.updated_by?.name ?? null}</Typography>
                        </Grid>
                    </Grid>
                </Box>
            </PageCard.Container>

            <SavePanel>
                <Grid container justify="space-between">
                    <Button variant="outlined" color="primary" onClick={onCancelClick}>
                        {I18n.formatMessage({ id: "common.cancel" })}
                    </Button>
                    <Button
                        type="submit"
                        startIcon={<Icon className="fas fa-save" />}
                        variant="contained"
                        color="secondary"
                        disabled={!props.formProps.dirty || !props.formProps.isValid}
                    >
                        {props.formType === FormType.create ? "Létrehozás" : I18n.formatMessage({ id: "common.save" })}
                    </Button>
                </Grid>
            </SavePanel>
            <FullscreenLoader visible={props.formProps.isSubmitting} />
        </Form>
    );
};
