import { gql } from "@apollo/client";

export const adminFragment = gql`
    fragment Admin on Admin {
        id
        name
        email
        is_active
        created_at
        updated_at
        phone
        last_login
        roles {
            id
            name
            title
            permissions {
                id
                name
            }
        }
        store {
            id
            name
        }
    }
`;
