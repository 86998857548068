import React from "react";
import { Api } from "Api/Api";
import { TypeRowProps } from "@inovua/reactdatagrid-community/types";
import DataGrid from "Components/DataGrid/DataGrid";
import { RedirectionFilters, RedirectionListItem, RedirectionOrder, RedirectionField, Site } from "Api/graphql/admin/types";
import { DataGridUrlQueryParser, QueryParserType } from "Components/DataGrid/DataGridUrlQueryParser";
import { ApiError } from "Api/ApiError";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { Path } from "Utils/Path";
import { I18n } from "I18n/I18n";
import { DataSource, FilterValue } from "Components/DataGrid/DataGridUtils";
import { DataGridParams } from "Components/DataGrid/UrlQueryParser";
import { Icon } from "@bigfish/admin-ui/core";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import { CustomTypeColumn } from "Utils/DataGridUtils";
import { Constants } from "Utils/Constants";

type Props = WithSnackbarProps;

type State = {
    selectSites: Site[];
};

class RedirectionDataGrid extends React.Component<Props> {
    public readonly state: State = {
        selectSites: [],
    };

    public async componentDidMount(): Promise<void> {
        try {
            const fetchedSites = await Api.listSites();
            this.setState({ selectSites: fetchedSites.filter(fs => fs.name !== "app") });
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }
    }

    private getColumns = (): CustomTypeColumn[] => {
        return [
            { name: "id", header: I18n.formatMessage({ id: "pages.redirectionList.grid.column.id" }), width: 132, filterDelay: Constants.filterDelayMS },
            {
                name: "from_site_id",
                defaultWidth: 140,
                header: I18n.formatMessage({ id: "pages.redirectionList.grid.column.from_site_id" }),
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: this.state.selectSites.map(s => {
                        return { id: s.id, label: s.name };
                    }),
                },
                render: ({ data }: { data: RedirectionListItem }) => {
                    return data.from_site?.name;
                },
                sortable: false,
            },
            {
                name: "from",
                header: I18n.formatMessage({ id: "pages.redirectionList.grid.column.from" }),
                defaultFlex: 1,
                minWidth: 200,
                filterDelay: Constants.filterDelayMS,
            },
            {
                name: "to_site_id",
                defaultWidth: 140,
                header: I18n.formatMessage({ id: "pages.redirectionList.grid.column.to_site_id" }),
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: this.state.selectSites.map(s => {
                        return { id: s.id, label: s.name };
                    }),
                },
                render: ({ data }: { data: RedirectionListItem }) => {
                    return data.to_site?.name;
                },
                sortable: false,
            },
            {
                name: "to",
                header: I18n.formatMessage({ id: "pages.redirectionList.grid.column.to" }),
                defaultFlex: 1,
                minWidth: 200,
                filterDelay: Constants.filterDelayMS,
            },
            {
                name: "created_at",
                minWidth: 200,
                header: I18n.formatMessage({ id: "pages.redirectionList.grid.column.created_at" }),
            },
            {
                name: "updated_at",
                minWidth: 200,
                header: I18n.formatMessage({ id: "pages.redirectionList.grid.column.updated_at" }),
            },
            {
                textAlign: "center",
                width: 160,
                render: ({ value }: { value: boolean }) => {
                    if (value) {
                        return <Icon className="fa fa-check-circle" color="secondary" title={I18n.formatMessage({ id: "pages.redirectionList.grid.column.status.active" })} />;
                    }
                    return (
                        <Icon
                            className="fa fa-times-circle"
                            color="primary"
                            style={{ color: "#BDC2E6" }}
                            title={I18n.formatMessage({ id: "pages.redirectionList.grid.column.status.inactive" })}
                        />
                    );
                },
                sortable: false,
                name: "is_active",
                header: I18n.formatMessage({ id: "pages.redirectionList.grid.column.status" }),
            },
        ];
    };

    private filterValues: FilterValue[] = [
        { name: "id", operator: "eq", type: "number" },
        { name: "from_site_id", operator: "eq", type: "select" },
        { name: "from", operator: "contains", type: "string" },
        { name: "to_site_id", operator: "eq", type: "select" },
        { name: "to", operator: "contains", type: "string" },
    ];

    private urlQueryParser = new DataGridUrlQueryParser({
        filters: {
            id: QueryParserType.integer,
            from_site_id: QueryParserType.enum,
            from: QueryParserType.string,
            to_site_id: QueryParserType.enum,
            to: QueryParserType.string,
            created_at: QueryParserType.dateRange,
            updated_at: QueryParserType.dateRange,
        },
        sortField: RedirectionField,
    });

    private sortFieldToSortOrder = {
        [RedirectionField.id]: "id",
        [RedirectionField.from]: "from",
        [RedirectionField.to]: "to",
        [RedirectionField.created_at]: "created_at",
        [RedirectionField.updated_at]: "updated_at",
    };

    private dataSource = async (props: DataGridParams<RedirectionOrder, RedirectionFilters>): Promise<DataSource<RedirectionListItem>> => {
        try {
            const result = await Api.redirections({
                first: props.limit,
                page: props.page,
                filters: props.filters
                    ? {
                          ...props.filters,
                          from_site_id: typeof props.filters.from_site_id === "string" ? [props.filters.from_site_id ?? ""] : props.filters.from_site_id,
                          to_site_id: typeof props.filters.to_site_id === "string" ? [props.filters.to_site_id ?? ""] : props.filters.to_site_id,
                      }
                    : undefined,
                sortBy: props.sortBy,
            });
            return { data: result.data, count: result.paginatorInfo.total };
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }
        return { data: [], count: 0 };
    };

    private getRowLink = (rowProps: TypeRowProps): string => {
        return Path.redirectionEdit(rowProps.data.id);
    };

    render() {
        return (
            <DataGrid
                urlQueryParser={this.urlQueryParser}
                rowHeight={50}
                style={{ minHeight: 800 }}
                dataSource={this.dataSource}
                filterValues={this.filterValues}
                columns={this.getColumns()}
                rowLink={this.getRowLink}
                activeCell={null}
                sortFieldToSortOrder={this.sortFieldToSortOrder}
            />
        );
    }
}

export default withSnackbar(RedirectionDataGrid);
