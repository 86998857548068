import { gql } from "@apollo/client";
import { mailTemplateListItemFragment } from "../fragments/mailTemplateListItemFragment";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";

export const listMailTemplatesQuery = gql`
    query listMailTemplates($filters: MailTemplateFilters, $sortBy: MailTemplateOrder, $first: Int!, $page: Int) {
        listMailTemplates(first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            data {
                ...MailTemplateListItem
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${mailTemplateListItemFragment}
    ${paginatorInfoFragment}
`;
