import { gql } from "@apollo/client";

export const userListItemFragment = gql`
    fragment UserListItem on UserSimple {
        id
        name
        email
        card_number
        phone_number_full
        zip_code
        created_at
        is_active
    }
`;
