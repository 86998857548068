import { gql } from "@apollo/client";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";
import { textContentForMenuFragment } from "../fragments/textContentForMenuFragment";

export const listAdminContentsForMenuQuery = gql`
    query listAdminContentsForMenu($filters: ContentFilters, $sortBy: ContentOrder, $first: Int!, $page: Int) {
        adminContents(first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            data {
                ... on TextContent {
                    ...TextContentForMenu
                }
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${textContentForMenuFragment}
    ${paginatorInfoFragment}
`;
