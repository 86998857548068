import React from "react";
import { FormHelperText } from "@bigfish/admin-ui/core";
import { FieldMetaProps } from "formik";

type Props = {
    meta: FieldMetaProps<{}>;
    defaultHelperText?: string | JSX.Element | null;
    disableNoWrap?: boolean;
    alwaysShowError?: boolean;
};

export const CustomFormHelperText = ({ meta, defaultHelperText, disableNoWrap, alwaysShowError }: Props) => {
    const hasError = alwaysShowError ? !!meta.error : meta.touched && !!meta.error;
    return (
        <div>
            <FormHelperText className={disableNoWrap ? "" : "helper-text-nowrap"} error={hasError}>
                {hasError ? meta.error : defaultHelperText}
            </FormHelperText>
        </div>
    );
};
