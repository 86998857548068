import { gql } from "@apollo/client";

export const menuListItemFragment = gql`
    fragment MenuListItem on Menu {
        id
        slug
        name
        created_at
        updated_at
    }
`;
