import React from "react";
import { Link as AUILink } from "@bigfish/admin-ui/core";
import { Link as ReactRouterLink, LinkProps } from "react-router-dom";
import { Variant } from "@material-ui/core/styles/createTypography";

type Props = {
    variant?: Variant;
    color?: "initial" | "inherit" | "primary" | "secondary" | "textPrimary" | "textSecondary" | "error";
} & LinkProps;

export const Link = (props: Props) => {
    return <AUILink component={ReactRouterLink} {...props} color={props.color || "inherit"} />;
};
