import { gql } from "@apollo/client";

export const pageBlockHtmlFragment = gql`
    fragment PageBlockHtml on PageBlockHtml {
        id
        type
        title
        content
    }
`;
