import { ActiveBreadcrumbItem } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { ModalBadgeForm, BadgeFormValues, badgeValidator } from "./ModalBadgeForm";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { Loading } from "Components/Loading";
import { Link } from "Components/Link";
import { Breadcrumbs } from "Components/Breadcrumbs";
import Prompt from "Components/Prompt";
import { Badge, BadgeListItem } from "Api/graphql/admin/types";
import { Box } from "@bigfish/admin-ui/core";
import { I18n } from "I18n/I18n";

type ComponentProps = {
    id: number;
    onModalClose: () => void;
    onUpdate: (badge: BadgeListItem | null) => void;
};

type Props = ComponentProps & WithSnackbarProps & RouteComponentProps;

type State = {
    isLoading: boolean;
    isDeleteDialogVisible: boolean;
    badge: Badge | null;
};

class ModalBadgeEditPage extends React.Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        isDeleteDialogVisible: false,
        badge: null,
    };

    public async componentDidMount(): Promise<void> {
        try {
            const badge = await Api.getBadgeById(this.props.id);
            this.setState({ badge, isLoading: false });
        } catch (error) {
            this.props.enqueueSnackbar(error.message, { variant: "error" });
            this.props.history.push(Path.badgeList);
        }
    }

    private onSubmit = async (values: BadgeFormValues, formikHelpers: FormikHelpers<BadgeFormValues>): Promise<boolean> => {
        try {
            if (!values.type) return false;
            const badge = await Api.updateBadge(this.state.badge!.id, { ...values, type: values.type });
            if (!badge) return false;
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.badge.onEditSubmit.succeed" }), { variant: "success" });
            this.setState({ badge });
            this.props.onUpdate({
                __typename: "Badge",
                id: badge.id,
                name: badge.name,
                type: badge.type,
                created_at: badge.created_at,
                is_active: badge.is_active,
                active_from: badge.active_from,
                is_visible: badge.is_visible,
            });
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.badge.onEditSubmit.error" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
        }
        return false;
    };

    private getBreadcrumb = () => {
        return (
            <Breadcrumbs>
                <Link to={Path.badgeList} color="inherit">
                    {I18n.formatMessage({ id: "pages.badge.editBreadcrumb" })}
                </Link>
                <ActiveBreadcrumbItem aria-current="page">{this.state.badge?.name || ""}</ActiveBreadcrumbItem>
            </Breadcrumbs>
        );
    };

    public render() {
        const { isLoading, badge } = this.state;

        if (isLoading) {
            return <Loading breadcrumb={this.getBreadcrumb()} />;
        }

        if (!badge) {
            this.props.onModalClose();
            return null;
        }

        const initialValues: BadgeFormValues = {
            name: badge.name,
            type: badge.type,
            image: badge.image ?? "",
            image_title: badge.image_title,
            info: badge.info,
            product_param_id: badge.product_param?.id ?? "",
            is_active: badge.is_active,
            active_from: badge.active_from ?? null,
            active_to: badge.active_to,
        };

        return (
            <div className="modal-grey-background">
                <Formik initialValues={initialValues} onSubmit={this.onSubmit} validate={badgeValidator(FormType.edit)} enableReinitialize={true}>
                    {props => (
                        <>
                            <ModalBadgeForm formType={FormType.edit} formProps={props} badge={badge} onCancelClick={this.props.onModalClose} />
                            <Prompt when={props.dirty} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props)} />
                        </>
                    )}
                </Formik>
                <Box mt="40px" />
            </div>
        );
    }
}

export default withSnackbar(withRouter(ModalBadgeEditPage));
