import { gql } from "@apollo/client";
import { productCategoryBannerFragment } from "./productCategoryBannerFragment";

export const productCategoryFragment = gql`
    fragment ProductCategory on ProductCategory {
        id
        name
        icon
        slug
        icon_title
        description
        banners {
            ...ProductCategoryBanner
        }
        is_active
        include_in_menu
        is_featured
        feed_facebook
        feed_google
        feed_google_merchant
        feed_dsa
        og_image
        og_title
        og_description
        meta_description
        updated_at
        updated_by {
            id
            name
        }
        product_sort_by {
            field
            direction
        }
        parent {
            id
        }
    }
    ${productCategoryBannerFragment}
`;
