import React from "react";
import { Button, IconButton } from "@bigfish/admin-ui/core";

type Props = {
    onClick?: () => void;
    disabled?: boolean;
    icon?: JSX.Element;
    label?: string;
};

export const FunctionalButton = (props: Props) => {
    return props.label ? (
        <Button
            style={props.disabled ? undefined : { color: "#294475", border: "1px solid #7895C1" }}
            aria-label="Primary"
            startIcon={props.icon || null}
            variant="outlined"
            color="secondary"
            disabled={!!props.disabled}
            onClick={props.onClick ? props.onClick : () => {}}
        >
            {props.label}
        </Button>
    ) : props.icon ? (
        <IconButton
            disabled={!!props.disabled}
            className="MuiButton-outlined MuiButton-outlinedPrimary MuiButton-outlinedSizeLarge"
            aria-label="Primary"
            onClick={props.onClick ? props.onClick : () => {}}
        >
            {props.icon}
        </IconButton>
    ) : null;
};
