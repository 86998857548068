import { gql } from "@apollo/client";
import { fulfillmentFragment } from "../fragments/fulfillmentFragment";

export const getFulfillmentByIdQuery = gql`
    query getFulfillmentById($first: Int!, $filters: FulfillmentFilters) {
        listFulfillment(first: $first, filters: $filters) {
            data {
                ...Fulfillment
            }
        }
    }
    ${fulfillmentFragment}
`;
