import { gql } from "@apollo/client";
import { todoFragment } from "../fragments/todoFragment";

export const getTodoByIdQuery = gql`
    query getTodoById($id: Int!) {
        todo(id: $id) {
            ...Todo
        }
    }
    ${todoFragment}
`;
