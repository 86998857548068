import { CircularProgress, FormControl, FormHelperText, InputAdornment, InputLabel, MenuItem, Select } from "@bigfish/admin-ui/core";
import { Close } from "@bigfish/admin-ui/icons";
import React from "react";
import "./styles.css";

export type SelectOption = {
    id: string | number;
    title: string;
};

type SelectOptionType = { id: string | number };

type Props<T extends SelectOptionType> = {
    value: string | number;
    onChange: (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>, child: React.ReactNode) => void;
    label?: string;
    error?: boolean;
    required?: boolean;
    name?: string;
    fullWidth?: boolean;
    variant?: "filled" | "outlined" | "standard";
    helperText?: React.ReactNode;
    disabled?: boolean;
    getTitle: (data: T) => string; // key of select option
    fetch: () => Promise<T[]>;
    deletable?: boolean;
    onDelete?: () => void;
};

type State<T extends SelectOptionType> = {
    data: T[];
    isLoading: boolean;
};

class FetchedSelectInput<T extends SelectOptionType> extends React.Component<Props<T>, State<T>> {
    public readonly state: State<T> = {
        data: [],
        isLoading: true,
    };

    private renderMenuItem = (data: T) => {
        return (
            <MenuItem key={data.id} value={data.id}>
                {this.props.getTitle(data)}
            </MenuItem>
        );
    };

    public async componentDidMount(): Promise<void> {
        const data = await this.props.fetch();
        this.setState({ data, isLoading: false });
    }

    public render() {
        return (
            <FormControl variant="outlined" fullWidth>
                <InputLabel error={!!this.props.error}>{this.props.label}</InputLabel>
                <Select
                    value={this.props.value}
                    onChange={this.props.onChange}
                    label={this.props.label}
                    error={this.props.error}
                    required={this.props.required}
                    name={this.props.name}
                    fullWidth={this.props.fullWidth}
                    variant={this.props.variant}
                    disabled={this.props.disabled}
                    endAdornment={
                        this.state.isLoading ? (
                            <InputAdornment position="end" className="fetched-select-input-adornment">
                                <CircularProgress color="secondary" size={30} />
                            </InputAdornment>
                        ) : this.props.deletable ? (
                            <InputAdornment position="end" className="fetched-select-input-adornment fetched-select-clickable">
                                <Close onClick={this.props.onDelete} />
                            </InputAdornment>
                        ) : null
                    }
                >
                    {this.state.data.map(this.renderMenuItem)}
                </Select>
                <FormHelperText error={!!this.props.error}>{this.props.helperText}</FormHelperText>
            </FormControl>
        );
    }
}

export default FetchedSelectInput;
