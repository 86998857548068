import { ProductCategorySelectItem, ProductCategorySelectItem_children } from "Api/graphql/admin/types";

export const ProductCategoryUtils = {
    getMainCategories(categorySelectItems: ProductCategorySelectItem[]): ProductCategorySelectItem[] {
        return categorySelectItems.filter(c => c.children !== null && c.children.length > 0);
    },

    getCategories(categorySelectItems: ProductCategorySelectItem[]) {
        const mainCategories = categorySelectItems.filter(c => c.children !== null && c.children.length > 0);

        const returnedCategories: Array<{ id: number; name: string }> = [];

        const getChildren = (category: ProductCategorySelectItem | ProductCategorySelectItem_children, namePrefix: string) => {
            if (category.children === null || category.children.length === 0) {
                return;
            }
            category.children.forEach(child => {
                returnedCategories.push({ id: child.id, name: `${namePrefix ? `${namePrefix}/` : ""}${category.name}/${child.name}` });
                if ("children" in child) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    getChildren(child, `${namePrefix ? `${namePrefix}/` : ""}${category.name}`);
                }
            });
        };

        mainCategories.forEach(c => getChildren(c, ""));

        return returnedCategories;
    },
};
