import { gql } from "@apollo/client";
import { childProductCategoryForMenuFieldsFragment } from "./childProductCategoryForMenuFieldsFragment";
import { productCategoryForMenuFieldsFragment } from "./productCategoryForMenuFieldsFragment";

export const productCategoryForMenuFragment = gql`
    fragment ProductCategoryForMenu on ProductCategorySimple {
        ...ProductCategoryForMenuFields
        ...ChildProductCategoryForMenuFields
    }
    ${productCategoryForMenuFieldsFragment}
    ${childProductCategoryForMenuFieldsFragment}
`;
