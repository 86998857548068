import React from "react";
import { Form, FormType } from "Components/Form";
import { Button, FormControl, FormControlLabel, Grid, Icon, TextField, Typography, Box } from "@bigfish/admin-ui/core";
import { Field, FieldProps, FormikErrors, FormikProps } from "formik";
import { Badge, BadgeInput, BadgeType, ProductParamSelectItem } from "Api/graphql/admin/types";
import { Checkbox } from "@bigfish/admin-ui/core";
import { I18n } from "Src/i18n/I18n";
import { FullscreenLoader, PageCard } from "@bigfish/admin-ui/components";
import { ObjectUtils } from "Utils/ObjectUtils";
import SelectInput from "Components/SelectInput";
import { Constants } from "Utils/Constants";
import { I18nHelpers } from "I18n/I18nHelpers";
import { Validator } from "Utils/Validator";
import { StatisticsPaper } from "Components/StatisticsPaper";
import { CustomFormHelperText } from "Components/CustomFormHelperText";
import MediaLibraryImageInput from "Components/Inputs/MediaLibraryImageInput";
import FetchedSelect from "Components/FetchedSelect/FetchedSelect";
import { Api } from "Api/Api";
import { useSnackbar } from "notistack";

export type BadgeFormValues = Omit<BadgeInput, "type"> & { type: BadgeType | null };

type Props = {
    formType: FormType;
    formProps: FormikProps<BadgeFormValues>;
    badge?: Badge;
    onCancelClick: () => void;
};

export const badgeValidator = (formType: FormType) => (values: BadgeFormValues): FormikErrors<BadgeFormValues> => {
    const errors: { [key in keyof BadgeFormValues]?: any } = {};

    if (!errors.active_from && formType === FormType.create) {
        errors.active_from = I18nHelpers.formatValidator(Validator.startDatePast)(values.active_from);
    }

    if (values.active_from && values.active_to) {
        errors.active_to = Validator.endDateCantBeBeforeStart(values.active_from, values.active_to);
        errors.active_from = errors.active_from || Validator.startDateCantBeAfterEnd(values.active_from, values.active_to);
    }

    if (values.type === BadgeType.product_param) {
        errors.product_param_id = I18nHelpers.formatValidator(Validator.required)(values.product_param_id);
    }

    return Form.cleanupFormikErrors(errors);
};

export const ModalBadgeForm = (props: Props) => {
    const { enqueueSnackbar } = useSnackbar();

    return (
        <Form formProps={props.formProps}>
            <PageCard.Container>
                <PageCard.Heading title={I18n.formatMessage({ id: "common.form.content" })} />

                <Box mt="30px" />

                {props.formType === FormType.edit && (
                    <div>
                        <Typography color="textSecondary" gutterBottom>
                            {I18n.formatMessage({ id: "pages.badge.form.idLabel" })}
                        </Typography>
                        <Typography variant="subtitle1">{props.badge?.id}</Typography>
                    </div>
                )}

                <Box mt="30px" />

                <Field name="type" validate={I18nHelpers.formatValidator(Validator.required)}>
                    {({ field, meta }: FieldProps) => (
                        <SelectInput
                            label={I18n.formatMessage({ id: "pages.badge.form.typeLabel" })}
                            fullWidth
                            variant="outlined"
                            options={[
                                ...ObjectUtils.enumAsArray<BadgeType>(BadgeType).map((type: BadgeType) => {
                                    return { id: type, title: I18n.formatMessage({ id: `enums.badgeType.${type}` }) };
                                }),
                            ]}
                            helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                            {...field}
                            error={meta.touched && !!meta.error}
                        />
                    )}
                </Field>

                <Box mt="30px" />
                <Field name="name" validate={I18nHelpers.formatValidator(Validator.required)}>
                    {({ field, meta }: FieldProps) => (
                        <TextField
                            type="text"
                            label={I18n.formatMessage({ id: "pages.badge.form.nameLabel" })}
                            fullWidth
                            variant="outlined"
                            required
                            helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                            error={meta.touched && !!meta.error}
                            {...field}
                        />
                    )}
                </Field>

                <Box mt="30px" />

                <Field name="info" validate={I18nHelpers.formatValidator(Validator.required)}>
                    {({ field, meta }: FieldProps) => (
                        <TextField
                            type="text"
                            label={I18n.formatMessage({ id: "pages.badge.form.infoLabel" })}
                            fullWidth
                            variant="outlined"
                            required
                            error={meta.touched && !!meta.error}
                            helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                            {...field}
                        />
                    )}
                </Field>

                <Box mt="30px" />

                <Typography variant="body1" component="h3" color="textSecondary">
                    {I18n.formatMessage({ id: "pages.badge.form.imageLabel" })}
                </Typography>
                <Box className="image-section-root">
                    <Box className="image-section-image-field">
                        <div className="og_image_url_wrapper">
                            <Field name="image" validate={I18nHelpers.formatValidator(Validator.required)}>
                                {({ field, meta }: FieldProps) => (
                                    <div>
                                        <MediaLibraryImageInput
                                            id="product-category-og_image_url"
                                            {...field}
                                            name="image"
                                            value={field.value}
                                            onChange={(value: string | null) => {
                                                props.formProps.setFieldValue("image", value);
                                                props.formProps.setFieldTouched("image", true);
                                            }}
                                        />
                                        <CustomFormHelperText
                                            meta={meta}
                                            defaultHelperText={<div style={{ whiteSpace: "nowrap" }}>{I18n.formatMessage({ id: "pages.badge.form.infoHelperText" })}</div>}
                                        />
                                    </div>
                                )}
                            </Field>
                        </div>
                    </Box>
                    <Box className="image-section-alt-field">
                        <Field name="image_title" validate={I18nHelpers.formatValidator(Validator.altTitleMaxLength)}>
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "common.form.altTitleTag" })}
                                    fullWidth
                                    variant="outlined"
                                    inputProps={{
                                        maxLength: Constants.altTitleMaxLength,
                                    }}
                                    {...field}
                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.maxCharacter" }, { max: Constants.altTitleMaxLength }))}
                                    error={meta.touched && !!meta.error}
                                />
                            )}
                        </Field>
                    </Box>
                </Box>

                <Box mt="60px" mb="30px">
                    <Typography variant="subtitle1">{I18n.formatMessage({ id: "pages.badge.form.productParamHint" })}</Typography>
                </Box>

                <Field name="product_param_id">
                    {({ field, meta }: FieldProps) => (
                        <FetchedSelect
                            label={I18n.formatMessage({ id: "pages.badge.form.productParamIdLabel" })}
                            fullWidth
                            variant="outlined"
                            getTitle={(productParam: ProductParamSelectItem) => productParam.name ?? ""}
                            fetch={async (): Promise<ProductParamSelectItem[]> => {
                                try {
                                    const productParams = await Api.listProductParamSelectItems({ first: 9999 });
                                    return productParams.data;
                                } catch (error) {
                                    enqueueSnackbar(error.message, { variant: "error" });
                                    return [];
                                }
                            }}
                            disabled={props.formProps.values.type !== BadgeType.product_param}
                            helperText={Form.getHelperText(meta, "")}
                            {...field}
                            error={meta.touched && !!meta.error}
                        />
                    )}
                </Field>

                <Box mt="30px" />

                <PageCard.Heading title={I18n.formatMessage({ id: "common.form.settings" })} />
                <Typography variant="body1" component="h3" color="textSecondary">
                    {I18n.formatMessage({ id: "pages.badge.form.statusLabel" })}
                </Typography>

                <Field name="is_active">
                    {({ field, meta }: FieldProps) =>
                        field.value !== undefined && (
                            <Box mt="15px" ml="10px">
                                <FormControl error={meta.touched && !!meta.error}>
                                    <FormControlLabel
                                        control={<Checkbox {...field} checked={field.value} />}
                                        label={I18n.formatMessage({ id: "pages.badge.form.isActiveLabel" })}
                                    />
                                </FormControl>
                                <CustomFormHelperText meta={meta} />
                            </Box>
                        )
                    }
                </Field>
            </PageCard.Container>

            {props.formType === FormType.edit && props.badge && (
                <StatisticsPaper
                    createdAt={props.badge.created_at}
                    createdBy={props.badge.created_by?.name}
                    updatedAt={props.badge.updated_at}
                    updatedBy={props.badge.updated_by?.name}
                />
            )}

            <Grid container justify="space-between">
                <Button variant="outlined" color="primary" onClick={props.onCancelClick}>
                    {I18n.formatMessage({ id: "common.cancel" })}
                </Button>
                <Button
                    type="submit"
                    startIcon={<Icon className="fas fa-save" />}
                    variant="contained"
                    color="secondary"
                    disabled={!props.formProps.dirty || !props.formProps.isValid}
                >
                    {I18n.formatMessage({ id: `common.${props.formType === FormType.create ? "create" : "save"}` })}
                </Button>
            </Grid>
            <FullscreenLoader visible={props.formProps.isSubmitting} />
        </Form>
    );
};
