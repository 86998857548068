import { gql } from "@apollo/client";

export const productCatalogItemFragment = gql`
    fragment ProductCatalogItem on ProductSimple {
        id
        lfdnr
        name
        main_categories {
            id
            name
        }
        categories {
            id
            name
        }
        is_visible
        active_from
        is_description
        is_image
        ean
        price
        stock_qty
        slug
    }
`;
