import { gql } from "@apollo/client";
import { documentListItemFragment } from "../fragments/documentListItemFragment";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";

export const documentsQuery = gql`
    query documents($sortBy: DocumentOrder, $first: Int!, $page: Int) {
        documents(first: $first, sortBy: $sortBy, page: $page) {
            data {
                ...DocumentListItem
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${documentListItemFragment}
    ${paginatorInfoFragment}
`;
