import React from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import { dataGridStyles } from "@bigfish/admin-ui/styles";
import { TypeColumn, TypeDataGridProps, TypeRowProps } from "@inovua/reactdatagrid-community/types";
import { I18n } from "I18n/I18n";
import { useHistory } from "react-router";
import { DataSource, DataSourceProps } from "./DataGridUtils";
import { makeStyles } from "@bigfish/admin-ui/core";

type DataSourceType<DataType extends Object> = DataType[] | ((values: DataSourceProps<DataType>) => Promise<DataSource<DataType>>);

type ComponentProps<DataType extends Object> = {
    dataSource: DataSourceType<DataType>;
    columns: TypeColumn[];
    rowLink?: (rowProps: TypeRowProps) => string;
};

type DataGridProps<T> = Partial<Omit<TypeDataGridProps, "dataSource" | "columns">> & ComponentProps<T>;

const useStyles = makeStyles(dataGridStyles);

function UnControlledDataGrid<T>(props: DataGridProps<T>) {
    const history = useHistory();
    const classes = useStyles();

    const {
        className,
        showColumnMenuTool,
        idProperty,
        loadingText,
        emptyText,
        i18n,
        renderColumnFilterContextMenu,
        resizable,
        enableSelection,
        showEmptyRows,
        showHoverRows,
        showZebraRows,
        columnUserSelect,
        showHeader,
        rowHeight,
        showCellBorders,
        style,
        dataSource,
        columns,
        onRowClick,
        activateRowOnFocus,
        rowLink,
        pagination,
        defaultLimit,
        defaultSkip,
        remoteFilter,
        ...otherProps
    } = props;

    const rowClick = (rowProps: TypeRowProps, event: MouseEvent) => {
        if (rowLink) {
            const path = rowLink(rowProps);
            if (event.ctrlKey || event.metaKey) {
                event.preventDefault();
                window.open(path, "_blank");
            } else {
                history.push(path);
            }
        }
    };

    return (
        <ReactDataGrid
            className={className ?? classes.dataGrid}
            showColumnMenuTool={showColumnMenuTool ?? false}
            idProperty={idProperty ?? "id"}
            loadingText={loadingText ?? I18n.formatMessage({ id: "common.loading" })}
            emptyText={emptyText ?? I18n.formatMessage({ id: "components.dataGrid.emptyText" })}
            i18n={i18n ?? I18n.dataGrid}
            renderColumnFilterContextMenu={renderColumnFilterContextMenu ?? (() => null)}
            resizable={resizable ?? false}
            enableSelection={enableSelection ?? false}
            showEmptyRows={showEmptyRows ?? false}
            showHoverRows={showHoverRows ?? true}
            showZebraRows={showZebraRows ?? false}
            columnUserSelect={columnUserSelect ?? true}
            showHeader={showHeader ?? true}
            rowHeight={rowHeight ?? 50}
            showCellBorders={showCellBorders ?? "horizontal"}
            style={style ?? { minHeight: 800 }}
            dataSource={dataSource}
            columns={columns}
            onRowClick={onRowClick ?? rowClick}
            activateRowOnFocus={activateRowOnFocus ?? false}
            pagination={pagination ?? "local"}
            defaultLimit={defaultLimit ?? 20}
            defaultSkip={defaultSkip ?? 0}
            remoteFilter={remoteFilter ?? false}
            {...otherProps}
        />
    );
}

export default UnControlledDataGrid;
