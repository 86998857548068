import { gql } from "@apollo/client";

export const maintenanceFragment = gql`
    fragment Maintenance on Maintenance {
        id
        active_from
        active_to
        scopes
        notification
        maintenance
        is_active
    }
`;
