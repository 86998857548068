import { gql } from "@apollo/client";
import { campaignFragment } from "../fragments/campaignFragment";

export const getCampaignByIdQuery = gql`
    query getCampaignById($id: Int!) {
        campaign(id: $id) {
            ...Campaign
        }
    }
    ${campaignFragment}
`;
