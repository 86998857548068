import React, { useState } from "react";
import { TableRow, TableCell, Tooltip } from "@bigfish/admin-ui/core";
import { ProductCategoryChild } from "Api/graphql/admin/types";
import { Link } from "Components/Link";
import { Path } from "Utils/Path";
import { I18n } from "I18n/I18n";
import "./styles.css";
import { ProductCategoryToReorder } from "./ProductCategoryListPage";
import { Api } from "Api/Api";
import { WithSnackbarProps, withSnackbar } from "notistack";
import { ApiError } from "Api/ApiError";

type Props = {
    productCategory: ProductCategoryChild;
    level: number;
    areCategoriesEditable: boolean;
    onReorderClick?: (productCategory: ProductCategoryToReorder) => void;
} & WithSnackbarProps;

enum LoadingReason {
    open = "open",
    reorder = "reorder",
}

export const ProductCategoryRow = withSnackbar(({ productCategory, level, areCategoriesEditable, onReorderClick, enqueueSnackbar }: Props) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [fetchLoading, setFetchLoading] = useState<LoadingReason | null>(null);
    const [childrenCategories, setChildrenCategories] = useState<ProductCategoryChild[]>([]);

    const fetchChildren = async (fetchedForReorder?: boolean): Promise<void> => {
        setFetchLoading(fetchedForReorder ? LoadingReason.reorder : LoadingReason.open);
        await (async () => {
            try {
                const fetchedChildrenCategories = await Api.getProductCategoryChildren(productCategory.id);

                // No need to store when clicking reorder
                if (!fetchedForReorder) {
                    setChildrenCategories(fetchedChildrenCategories);
                }

                if (fetchedForReorder && !!onReorderClick) {
                    onReorderClick({
                        modalTitle: I18n.formatMessage({ id: "pages.productCategoryList.reorderCategoriesModal.title" }, { name: productCategory.name }),
                        children: fetchedChildrenCategories,
                    });
                }
            } catch (error) {
                if (error instanceof ApiError) {
                    enqueueSnackbar(error.message, { variant: "error" });
                }
            }
        })();
        setFetchLoading(null);
    };

    return (
        <>
            <TableRow>
                <TableCell
                    style={{ width: "100%" }}
                    onClick={() => {
                        if (productCategory.has_child) {
                            setIsOpen(!isOpen);
                            fetchChildren();
                        }
                    }}
                >
                    <span style={{ paddingLeft: `${(level - 1) * 31}px` }} />
                    <i
                        style={{ visibility: productCategory.has_child ? "visible" : "hidden" }}
                        className={`${
                            fetchLoading === LoadingReason.open ? "fas fa-spinner fa-pulse" : isOpen ? "fas fa-caret-square-down" : "far fa-caret-square-right"
                        } product-category-row-caret`}
                    />{" "}
                    {areCategoriesEditable ? (
                        <Link to={Path.productCategoryEdit(`${productCategory.id}`)} color="inherit">
                            {productCategory.name}
                        </Link>
                    ) : (
                        <>{productCategory.name}</>
                    )}
                </TableCell>
                <TableCell className="product-category-row-status-icons">
                    {productCategory.has_child && !!onReorderClick && (
                        <Tooltip
                            title={I18n.formatMessage({ id: "pages.productCategoryList.productCategoryRow.reorder" })}
                            arrow
                            onClick={async () => {
                                await fetchChildren(true);
                            }}
                        >
                            <i className={`${fetchLoading === LoadingReason.reorder ? "fas fa-spinner fa-pulse" : "fas fa-bars"} product-category-row-reorder-icon`}></i>
                        </Tooltip>
                    )}
                    {productCategory.is_featured && (
                        <Tooltip title={I18n.formatMessage({ id: "pages.productCategoryList.productCategoryRow.featured" })} arrow>
                            <i className="fas fa-star product-category-row-status-icon product-category-row-status-icon-featured"></i>
                        </Tooltip>
                    )}
                    {productCategory.is_active ? (
                        <Tooltip title={I18n.formatMessage({ id: "pages.productCategoryList.productCategoryRow.statusIconActive.true" })} arrow>
                            <i className="fas fa-check-circle product-category-row-status-icon product-category-row-status-icon-is_active-true"></i>
                        </Tooltip>
                    ) : (
                        <Tooltip title={I18n.formatMessage({ id: "pages.productCategoryList.productCategoryRow.statusIconActive.false" })} arrow>
                            <i className="fas fa-times-circle product-category-row-status-icon product-category-row-status-icon-is_active-false"></i>
                        </Tooltip>
                    )}
                </TableCell>
            </TableRow>

            {isOpen && productCategory.has_child && childrenCategories.length > 0
                ? childrenCategories.map(child => {
                      return (
                          <ProductCategoryRow
                              key={child.id}
                              areCategoriesEditable={areCategoriesEditable}
                              productCategory={child}
                              level={level + 1}
                              onReorderClick={onReorderClick}
                          />
                      );
                  })
                : null}
        </>
    );
});
