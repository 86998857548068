import React, { useEffect, useState } from "react";
import { I18n } from "I18n/I18n";
import { Box, Button, Checkbox, FormControl, FormControlLabel, Icon, TextField } from "@bigfish/admin-ui/core";
import { createModal } from "Components/createModal";
import { NewButton, NormalMenuItem } from "./MenuForm";
import { Form, FormType } from "Components/Form";
import { Field, FieldProps, FormikProps } from "formik";
import { MenuItemInput, MenuItemTypeEnum, PageForSelect, TextContentForMenu } from "Api/graphql/admin/types";
import { I18nHelpers } from "I18n/I18nHelpers";
import { Validator } from "Utils/Validator";
import "./styles.css";
import { Api } from "Api/Api";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { ApiError } from "Api/ApiError";
import { Autocomplete } from "@bigfish/admin-ui/lab";
import FetchedSelect from "Components/FetchedSelect/FetchedSelect";

const MenuItemEditModalDialog = createModal({ modalWidth: 900 });

type Props = {
    menuItem: NormalMenuItem | NewButton;
    formType: FormType;
    formProps: FormikProps<MenuItemInputLocal>;
    onClose: () => void;
} & WithSnackbarProps;

enum ButtonType {
    back = "back",
    more = "more",
}

type UrlOption = {
    id: string | number;
    title: string;
    type?: MenuItemTypeEnum;
    url?: string;
    buttonType?: ButtonType;
    moreCount?: number;
};

export type MenuItemInputLocal = Omit<MenuItemInput, "items"> & { id: string; delete: boolean };

export const MenuItemFormModal = withSnackbar(({ menuItem, formProps, formType, onClose, enqueueSnackbar }: Props) => {
    const [displayedTitle, setDisplayedTitle] = useState<string | null>(null);
    const [isDisplayTitleInitialized, setIsDisplayTitleInitialized] = useState<boolean>(false);

    const initialUrlOptions = [
        { id: MenuItemTypeEnum.page, title: I18n.formatMessage({ id: "pages.menu.form.menuItemFormModal.urlOptionType.page" }) },
        { id: MenuItemTypeEnum.productCategory, title: I18n.formatMessage({ id: "pages.menu.form.menuItemFormModal.urlOptionType.productCategory" }) },
        { id: MenuItemTypeEnum.product, title: I18n.formatMessage({ id: "pages.menu.form.menuItemFormModal.urlOptionType.product" }) },
        { id: MenuItemTypeEnum.productList, title: I18n.formatMessage({ id: "pages.menu.form.menuItemFormModal.urlOptionType.productList" }) },
        { id: MenuItemTypeEnum.productBrand, title: I18n.formatMessage({ id: "pages.menu.form.menuItemFormModal.urlOptionType.productBrand" }) },
        { id: MenuItemTypeEnum.article, title: I18n.formatMessage({ id: "pages.menu.form.menuItemFormModal.urlOptionType.article" }) },
    ];

    const [isUrlOpen, setIsUrlOpen] = useState<boolean>(false);
    const [urlOptions, setUrlOptions] = useState<UrlOption[]>(initialUrlOptions);

    const maxResults = 300;

    useEffect(() => {
        const menuItemType = (menuItem as NormalMenuItem).type;

        if (formType === FormType.edit && menuItemType) {
            updateOptions(menuItemType, true);
        }
    }, [menuItem]);

    useEffect(() => {
        const normalMenuItem = menuItem as NormalMenuItem;

        if (!isDisplayTitleInitialized && formType === FormType.edit && (menuItem as NormalMenuItem).type !== MenuItemTypeEnum.url) {
            const foundUrlOption = urlOptions.find(o => o.type === normalMenuItem.type && o.id === normalMenuItem.model_id?.toString());

            if (foundUrlOption) {
                setIsDisplayTitleInitialized(true);
            }

            formProps.setFieldValue("url", foundUrlOption?.title ?? normalMenuItem.url ?? "");
            setDisplayedTitle(foundUrlOption?.title ?? normalMenuItem.url ?? "");
            formProps.setFieldValue("type", normalMenuItem.type);
            formProps.setFieldValue("model_id", normalMenuItem.model_id);
        }
    }, [urlOptions]);

    const updateOptions = async (type: string, shouldSetSelectedUrl?: boolean) => {
        const normalMenuItem = menuItem as NormalMenuItem;
        const newUrlOptions: UrlOption[] = [{ id: "backButton", title: I18n.formatMessage({ id: "common.back" }), buttonType: ButtonType.back }];
        let isMoreTextDisplayed = false;
        let moreCount = 0;

        try {
            if (type === MenuItemTypeEnum.page) {
                const { data, paginatorInfo } = await Api.listPagesForMenu({ first: maxResults });
                newUrlOptions.push(
                    ...data.map(p => {
                        return { id: `${p.id}`, type: MenuItemTypeEnum.page, title: p.name };
                    })
                );

                if (paginatorInfo.total > maxResults) {
                    isMoreTextDisplayed = true;
                    moreCount = paginatorInfo.total - newUrlOptions.length + 1;
                }
            } else if (type === MenuItemTypeEnum.productCategory) {
                const data = await Api.listProductCategoriesForMenu();

                newUrlOptions.push(
                    ...data.map(p => {
                        return {
                            id: `${p.id}`,
                            type: MenuItemTypeEnum.productCategory,
                            title: p.name,
                        };
                    })
                );
            } else if (type === MenuItemTypeEnum.product) {
                const { data, paginatorInfo } = await Api.listProductsForMenu({ first: maxResults });
                newUrlOptions.push(
                    ...data.map(p => {
                        return { id: `${p.id}`, type: MenuItemTypeEnum.product, title: p.name ?? "" };
                    })
                );

                if (paginatorInfo.total > maxResults) {
                    isMoreTextDisplayed = true;
                    moreCount = paginatorInfo.total - newUrlOptions.length + 1;
                }
            } else if (type === MenuItemTypeEnum.productList) {
                const { data, paginatorInfo } = await Api.listProductListsForMenu({ first: maxResults });
                newUrlOptions.push(
                    ...data.map(p => {
                        return { id: `${p.id}`, type: MenuItemTypeEnum.productList, title: p.name };
                    })
                );

                if (paginatorInfo.total > maxResults) {
                    isMoreTextDisplayed = true;
                    moreCount = paginatorInfo.total - newUrlOptions.length + 1;
                }
            } else if (type === MenuItemTypeEnum.productBrand) {
                const { data, paginatorInfo } = await Api.listProductBrandsForMenu({ first: maxResults });
                newUrlOptions.push(
                    ...data.map(p => {
                        return {
                            id: `${p.id}`,
                            type: MenuItemTypeEnum.productBrand,
                            title: p.name ?? "",
                        };
                    })
                );

                if (paginatorInfo.total > maxResults) {
                    isMoreTextDisplayed = true;
                    moreCount = paginatorInfo.total - newUrlOptions.length + 1;
                }
            } else if (type === MenuItemTypeEnum.article) {
                const { data, paginatorInfo } = await Api.listAdminContentsForMenu({ first: maxResults });
                newUrlOptions.push(
                    ...(data as TextContentForMenu[]).map(p => {
                        return { id: `${p.id}`, type: MenuItemTypeEnum.article, title: p.title ?? "" };
                    })
                );
                if (paginatorInfo.total > maxResults) {
                    isMoreTextDisplayed = true;
                    moreCount = paginatorInfo.total - newUrlOptions.length + 1;
                }
            }
        } catch (error) {
            if (error instanceof ApiError) {
                enqueueSnackbar(error.message, { variant: "error" });
            }
        }

        if (isMoreTextDisplayed) {
            newUrlOptions.push({ id: "moreButton", title: I18n.formatMessage({ id: "pages.menu.form.menuItemFormModal.moreOptions" }), buttonType: ButtonType.more, moreCount });
        }

        setUrlOptions(newUrlOptions);

        if (shouldSetSelectedUrl) {
            const foundUrlOption = newUrlOptions.find(o => o.url === normalMenuItem.url);
            if (foundUrlOption) {
                formProps.setFieldValue("title", foundUrlOption.title);
                formProps.setFieldValue("type", foundUrlOption.type);
                formProps.setFieldValue("url", foundUrlOption.url ?? "");
                formProps.setFieldValue("model_id", Number.parseInt(`${foundUrlOption.id}`, 10));
            }
        }
    };

    const renderOption = (props: UrlOption) => {
        if (typeof props.id === "string" && props.buttonType === ButtonType.back) {
            return (
                <>
                    <Icon className="fas fa-arrow-left menu-item-back-arrow" /> {props.title}
                </>
            );
        } else if (typeof props.id === "string" && props.buttonType === ButtonType.more) {
            return <>{I18n.formatMessage({ id: "pages.menu.form.menuItemFormModal.moreOptions" }, { moreCount: props.moreCount })}</>;
        } else {
            return <>{props.title}</>;
        }
    };

    const resetSelectedUrlFields = () => {
        formProps.setFieldValue("url", null);
        formProps.setFieldValue("model_id", null);
        formProps.setFieldValue("type", MenuItemTypeEnum.url);
        formProps.setFieldTouched("url", true);
        formProps.setFieldTouched("model_id", true);
        formProps.setFieldTouched("type", true);
    };

    const getDisplayedTitle = () => {
        if (formProps.values.type === MenuItemTypeEnum.url) {
            return { id: formProps.values.url ?? "", title: formProps.values.url ?? "" };
        }
        return { id: displayedTitle ?? "", title: displayedTitle ?? "" };
    };

    const updateProductResults = async (query: string) => {
        const newUrlOptions: UrlOption[] = [{ id: "backButton", title: I18n.formatMessage({ id: "common.back" }), buttonType: ButtonType.back }];
        let isMoreTextDisplayed = false;
        let moreCount = 0;

        const { data, paginatorInfo } = await Api.listProductsForMenu({ first: maxResults, filters: { name: query } });
        newUrlOptions.push(
            ...data.map(p => {
                return { id: `${p.id}`, type: MenuItemTypeEnum.product, title: p.name ?? "" };
            })
        );

        if (paginatorInfo.total > maxResults) {
            isMoreTextDisplayed = true;
            moreCount = paginatorInfo.total - newUrlOptions.length + 1;
        }

        if (isMoreTextDisplayed) {
            newUrlOptions.push({ id: "moreButton", title: I18n.formatMessage({ id: "pages.menu.form.menuItemFormModal.moreOptions" }), buttonType: ButtonType.more, moreCount });
        }

        setUrlOptions(newUrlOptions);
        setDisplayedTitle(query);
    };

    return (
        <Form formProps={formProps}>
            <MenuItemEditModalDialog
                title={I18n.formatMessage({ id: `pages.menu.form.menuItemFormModal.title.${formType}` })}
                leftButtonsComponent={
                    <Button variant="outlined" color="primary" onClick={onClose}>
                        {I18n.formatMessage({ id: "common.cancel" })}
                    </Button>
                }
                rightButtonsComponent={
                    <Button
                        onClick={formProps.submitForm}
                        startIcon={<Icon className="fas fa-save" />}
                        variant="contained"
                        color="secondary"
                        disabled={!formProps.dirty || !formProps.values.title}
                    >
                        {I18n.formatMessage({ id: "pages.menu.form.menuItemFormModal.saveButton" })}
                    </Button>
                }
                open
                onClose={onClose}
            >
                <>
                    <Field name="title" validate={I18nHelpers.formatValidator(Validator.required)}>
                        {({ field, meta }: FieldProps) => (
                            <TextField
                                type="text"
                                label={I18n.formatMessage({ id: "pages.menu.form.menuItemFormModal.titleLabel" })}
                                fullWidth
                                variant="outlined"
                                required
                                helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                                error={meta.touched && !!meta.error}
                                {...field}
                            />
                        )}
                    </Field>

                    <Box mt="30px" />

                    <Field name="popup">
                        {({ field, meta }: FieldProps) => (
                            <Box mt="30px">
                                <FormControl fullWidth error={meta.touched && !!meta.error}>
                                    <FormControlLabel
                                        control={<Checkbox {...field} checked={field.value} />}
                                        label={I18n.formatMessage({ id: "pages.menu.form.menuItemFormModal.popupLabel" })}
                                    />
                                </FormControl>
                            </Box>
                        )}
                    </Field>

                    <Box mt="30px" />

                    <Field name="is_active">
                        {({ field, meta }: FieldProps) => (
                            <Box mt="30px">
                                <FormControl fullWidth error={meta.touched && !!meta.error}>
                                    <FormControlLabel
                                        control={<Checkbox {...field} checked={field.value} />}
                                        label={I18n.formatMessage({ id: "pages.menu.form.menuItemFormModal.isActiveLabel" })}
                                    />
                                </FormControl>
                            </Box>
                        )}
                    </Field>

                    <Box mt="30px" />

                    <Field name="url">
                        {({ field }: FieldProps) => (
                            <>
                                <Autocomplete
                                    fullWidth
                                    {...field}
                                    options={urlOptions}
                                    onChange={(event: React.ChangeEvent<{}>, value: string | UrlOption | (string | UrlOption)[] | null) => {
                                        if (value === null) {
                                            resetSelectedUrlFields();
                                            setDisplayedTitle(null);
                                            return;
                                        }

                                        if (!value || typeof value !== "object" || !("id" in value)) {
                                            setUrlOptions(initialUrlOptions);
                                            return;
                                        }

                                        const isUrlCategoryClicked = initialUrlOptions.map(o => `${o.id}`).includes(`${value.id}`);
                                        const isBackButtonClicked = typeof value.id === "string" && `${value.id}` === "backButton";

                                        if (isUrlCategoryClicked) {
                                            updateOptions(`${value.id}`);
                                        } else if (isBackButtonClicked) {
                                            setUrlOptions(initialUrlOptions);
                                        } else {
                                            formProps.setFieldValue("url", null);
                                            formProps.setFieldValue("model_id", Number.parseInt(`${value.id}`, 10));
                                            formProps.setFieldValue("type", value.type);
                                            setDisplayedTitle(value.title);
                                            if (!formProps.values.title) {
                                                formProps.setFieldValue("title", value.title);
                                            }
                                            setIsUrlOpen(false);
                                        }
                                    }}
                                    getOptionLabel={option => `${option.title}`}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            onChange={e => {
                                                if (urlOptions.find(o => o.type === MenuItemTypeEnum.product)) {
                                                    formProps.setFieldValue("url", e.target.value);
                                                    updateProductResults(e.target.value);
                                                    return;
                                                } else if (formProps.values.type !== MenuItemTypeEnum.url) {
                                                    resetSelectedUrlFields();
                                                    setDisplayedTitle(null);
                                                }
                                                formProps.setFieldValue("type", MenuItemTypeEnum.url);
                                                formProps.setFieldValue("url", e.target.value);
                                            }}
                                            label={I18n.formatMessage({ id: "pages.menu.form.menuItemFormModal.urlLabel" })}
                                        />
                                    )}
                                    renderOption={renderOption}
                                    open={isUrlOpen}
                                    onBlur={() => setIsUrlOpen(false)}
                                    onFocus={() => setIsUrlOpen(true)}
                                    getOptionDisabled={option => option.buttonType === ButtonType.more}
                                    value={getDisplayedTitle()}
                                />
                            </>
                        )}
                    </Field>

                    <Box mt="30px" />

                    <Field name="page_id_overlay">
                        {({ field, meta }: FieldProps) => (
                            <FetchedSelect
                                label={I18n.formatMessage({ id: "pages.menu.form.menuItemFormModal.pageIdOverlayLabel" })}
                                fullWidth
                                variant="outlined"
                                getTitle={(pageForSelect: PageForSelect) => {
                                    return pageForSelect.name ?? "";
                                }}
                                fetch={async (): Promise<PageForSelect[]> => {
                                    try {
                                        const { data: pages } = await Api.listPagesForSelect({ first: 9999 });
                                        return pages;
                                    } catch (error) {
                                        if (error instanceof ApiError) {
                                            enqueueSnackbar(error.message, { variant: "error" });
                                        }
                                        return [];
                                    }
                                }}
                                helperText={Form.getHelperText(meta, "")}
                                {...field}
                                value={field.value ?? ""}
                                error={meta.touched && !!meta.error}
                                deletable={formProps.values.page_id_overlay !== null && formProps.values.page_id_overlay !== undefined}
                                onDelete={() => {
                                    formProps.setFieldValue("page_id_overlay", null);
                                    formProps.setFieldTouched("page_id_overlay", true);
                                }}
                            />
                        )}
                    </Field>
                </>
            </MenuItemEditModalDialog>
        </Form>
    );
});
