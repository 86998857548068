import { Constants } from "./Constants";

export class NumberUtils {
    public static formatPrice(number: number | undefined | null) {
        return !number ? "-" : number.toLocaleString("hu-HU");
    }
    public static isStringNumber(value: string): boolean {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return !isNaN(value);
    }

    public static getRoundedValue = (valueToRound: number | string | null): number | null => {
        if (!valueToRound) {
            return null;
        }

        const numbersOnly = Number.parseInt(`${valueToRound}`.replace(/\D/g, ""));

        if (numbersOnly) {
            if (numbersOnly <= Constants.giftCardValueMin) {
                return Constants.giftCardValueMin;
            } else if (numbersOnly >= Constants.giftCardValueMax) {
                return Constants.giftCardValueMax;
            } else {
                return Math.round(numbersOnly / 100) * 100;
            }
        }
        return numbersOnly;
    };
}
