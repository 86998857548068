import { gql } from "@apollo/client";
import { maintenanceFragment } from "../fragments/maintenanceFragment";

export const getMaintenanceByIdQuery = gql`
    query getMaintenanceById($id: ID!) {
        maintenance(id: $id) {
            ...Maintenance
        }
    }
    ${maintenanceFragment}
`;
