import React from "react";
import { PageLayout } from "Components/PageLayout";
import ProductGratisDataGrid from "./ProductGratisDataGrid";
import { TitleBar, PageCard } from "@bigfish/admin-ui/components";
import { I18n } from "I18n/I18n";

class ProductGratisListPage extends React.Component {
    public render() {
        return (
            <PageLayout>
                <TitleBar title={I18n.formatMessage({ id: "pages.productGratis.title" })} />
                <PageCard.Container>
                    <ProductGratisDataGrid />
                </PageCard.Container>
            </PageLayout>
        );
    }
}

export default ProductGratisListPage;
