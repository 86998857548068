import { gql } from "@apollo/client";
import { systemSettingOptionFragment } from "./systemSettingOptionFragment";

export const systemSettingListItemFragment = gql`
    fragment SystemSettingListItem on Setting {
        id
        type
        value
        options {
            ...SystemSettingOption
        }
        is_multi
        description
        updated_at
        updated_by {
            id
            name
        }
    }
    ${systemSettingOptionFragment}
`;
