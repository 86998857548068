import { gql } from "@apollo/client";
import { menuFragment } from "../fragments/menuFragment";

export const getMenuByIdQuery = gql`
    query getMenuById($id: Int!) {
        menu(id: $id) {
            ...Menu
        }
    }
    ${menuFragment}
`;
