import React from "react";
import { Api } from "Api/Api";
import { TypeRowProps } from "@inovua/reactdatagrid-community/types";
import DataGrid from "Components/DataGrid/DataGrid";
import { DataGridUrlQueryParser, QueryParserType } from "Components/DataGrid/DataGridUrlQueryParser";
import { ApiError } from "Api/ApiError";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { withStyles, WithStyles } from "@bigfish/admin-ui/core";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import { Path } from "Utils/Path";
import { dataGridStyles } from "@bigfish/admin-ui/styles";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import { Constants } from "Utils/Constants";
import { ProductBrandFilters, ProductBrandListItem, ProductBrandSort, ProductBrandSortField } from "Api/graphql/admin/types";
import { I18n } from "I18n/I18n";
import { IsActiveIcon } from "Components/IsActive";
import { DataSource, FilterValue } from "Components/DataGrid/DataGridUtils";
import { DataGridParams } from "Components/DataGrid/UrlQueryParser";
import { CustomTypeColumn } from "Utils/DataGridUtils";

type Props = WithSnackbarProps & WithStyles<typeof dataGridStyles>;

class ProductBrandListDataGrid extends React.Component<Props> {
    private getColumns = (): CustomTypeColumn[] => {
        return [
            {
                name: "id",
                header: I18n.formatMessage({ id: "pages.productBrandList.grid.column.id" }),
                defaultWidth: Constants.columnWidth.id,
                filterDelay: Constants.filterDelayMS,
            },
            { name: "name", header: I18n.formatMessage({ id: "pages.productBrandList.grid.column.name" }), defaultFlex: 1, minWidth: 357, filterDelay: Constants.filterDelayMS },
            {
                name: "is_rossmann",
                header: I18n.formatMessage({ id: "pages.productBrandList.grid.column.is_rossmann" }),
                textAlign: "center",
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: [
                        { id: "true", label: I18n.formatMessage({ id: "common.yes" }).toLowerCase() },
                        { id: "false", label: I18n.formatMessage({ id: "common.no" }).toLowerCase() },
                    ],
                },
                defaultWidth: 160,
                render: ({ data }: { data: ProductBrandListItem }) => {
                    return I18n.formatMessage({ id: `common.${data.is_rossmann ? "yes" : "no"}` }).toLowerCase();
                },
            },
            {
                name: "is_logo",
                header: I18n.formatMessage({ id: "pages.productBrandList.grid.column.is_logo" }),
                textAlign: "center",
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: [
                        { id: "true", label: I18n.formatMessage({ id: "common.present" }).toLowerCase() },
                        { id: "false", label: I18n.formatMessage({ id: "common.absent" }).toLowerCase() },
                    ],
                },
                defaultWidth: 160,
                render: ({ data }: { data: ProductBrandListItem }) => {
                    return I18n.formatMessage({ id: `common.${data.is_logo ? "present" : "absent"}` }).toLowerCase();
                },
            },
            {
                name: "updated_at",
                filterEditor: DateFilter,
                header: I18n.formatMessage({ id: "pages.productBrandList.grid.column.updated_at" }),
                defaultWidth: Constants.columnWidth.dateTime,
                render: ({ data }: { data: ProductBrandListItem }) => {
                    return <div>{data.updated_at ? DateUtils.format(data.updated_at, DateFormat.minuteDateTime) : ""}</div>;
                },
            },
            {
                name: "is_public",
                header: I18n.formatMessage({ id: "pages.productBrandList.grid.column.is_public" }),
                textAlign: "center",
                defaultWidth: 120,
                render: ({ data }: { data: ProductBrandListItem }) => <IsActiveIcon value={data.is_public} />,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: [
                        { id: "true", label: I18n.formatMessage({ id: "common.form.isActive.true" }).toLowerCase() },
                        { id: "false", label: I18n.formatMessage({ id: "common.form.isActive.false" }).toLowerCase() },
                    ],
                },
            },
        ];
    };

    private filterValues: FilterValue[] = [
        { name: "id", operator: "eq", type: "number" },
        { name: "name", operator: "contains", type: "string" },
        { name: "is_rossmann", operator: "eq", type: "select" },
        { name: "is_logo", operator: "eq", type: "select" },
        { name: "updated_at", operator: "inrange", type: "date" },
        { name: "is_public", operator: "eq", type: "select" },
    ];

    private urlQueryParser = new DataGridUrlQueryParser({
        filters: {
            id: QueryParserType.integer,
            name: QueryParserType.string,
            is_rossmann: QueryParserType.boolean,
            is_logo: QueryParserType.boolean,
            updated_at: QueryParserType.dateRange,
            is_public: QueryParserType.boolean,
        },
        sortField: ProductBrandSortField,
    });

    private sortFieldToSortOrder = {
        [ProductBrandSortField.id]: "id",
        [ProductBrandSortField.name]: "name",
        [ProductBrandSortField.is_rossmann]: "is_rossmann",
        [ProductBrandSortField.is_logo]: "is_logo",
        [ProductBrandSortField.updated_at]: "updated_at",
        [ProductBrandSortField.is_public]: "is_public",
    };

    private dataSource = async (props: DataGridParams<ProductBrandSort, ProductBrandFilters>): Promise<DataSource<ProductBrandListItem>> => {
        try {
            const result = await Api.listProductBrands({
                first: props.limit,
                page: props.page,
                filters: props.filters,
                sortBy: props.sortBy,
            });
            return { data: result.data, count: result.paginatorInfo.total };
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }
        return { data: [], count: 0 };
    };

    private getRowLink = (rowProps: TypeRowProps): string => {
        return Path.productBrandEdit(rowProps.data.id);
    };

    render() {
        return (
            <DataGrid
                className={this.props.classes.dataGrid}
                urlQueryParser={this.urlQueryParser}
                rowHeight={50}
                style={{ minHeight: 800 }}
                dataSource={this.dataSource}
                filterValues={this.filterValues}
                columns={this.getColumns()}
                rowLink={this.getRowLink}
                activeCell={null}
                sortFieldToSortOrder={this.sortFieldToSortOrder}
            />
        );
    }
}

export default withSnackbar(withStyles(dataGridStyles, { withTheme: true })(ProductBrandListDataGrid));
