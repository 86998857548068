import { gql } from "@apollo/client";
import { systemSettingListItemFragment } from "../fragments/systemSettingListItemFragment";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";

export const listSystemSettingsQuery = gql`
    query listSystemSettings($filters: SettingFilters, $sortBy: SettingSort, $first: Int!, $page: Int) {
        listSettings(first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            data {
                ...SystemSettingListItem
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${systemSettingListItemFragment}
    ${paginatorInfoFragment}
`;
