import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@bigfish/admin-ui/core";
import React from "react";
import { CategorySelectItem } from "Api/graphql/admin/types";
import { Api } from "Api/Api";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { I18n } from "I18n/I18n";
import { Constants } from "Utils/Constants";

export const NO_CATEGORY: CategorySelectItem = { id: Constants.nullFormValue, title: I18n.formatMessage({ id: "pages.content.form.category.none" }), __typename: "Category" };

type ComponentProps = {
    value: string; // id of the category
    onChange: (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>, child: React.ReactNode) => void;
    label?: string;
    error?: boolean;
    required?: boolean;
    name?: string;
    fullWidth?: boolean;
    variant?: "filled" | "outlined" | "standard";
    helperText?: string;
};

type Props = ComponentProps & WithSnackbarProps;

type State = {
    categories: CategorySelectItem[];
};

class CategorySelect extends React.Component<Props, State> {
    public readonly state: State = {
        categories: [NO_CATEGORY],
    };

    public async componentDidMount(): Promise<void> {
        try {
            const categories = await Api.getCategoriesForSelect();
            const sortFunc = (a: CategorySelectItem, b: CategorySelectItem): number => {
                const textA = a.title.toUpperCase();
                const textB = b.title.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
            };
            this.setState({ categories: [NO_CATEGORY, ...Array.from(categories).sort(sortFunc)] });
        } catch (error) {
            this.props.enqueueSnackbar(error.message, { variant: "error" });
        }
    }

    private renderMenuItem = (category: CategorySelectItem) => {
        return (
            <MenuItem key={category.id} value={category.id}>
                {category.title}
            </MenuItem>
        );
    };

    public render() {
        return (
            <FormControl variant="outlined" fullWidth>
                <InputLabel id="category-select">{this.props.label}</InputLabel>
                {/* TODO: fix warning when loading in progress */}
                <Select
                    labelId="category-select"
                    value={this.props.value}
                    onChange={this.props.onChange}
                    label={this.props.label}
                    error={this.props.error}
                    required={this.props.required}
                    name={this.props.name}
                    fullWidth={this.props.fullWidth}
                    variant={this.props.variant}
                >
                    {this.state.categories.map(this.renderMenuItem)}
                </Select>
                <FormHelperText>{this.props.helperText}</FormHelperText>
            </FormControl>
        );
    }
}

export default withSnackbar(CategorySelect);
