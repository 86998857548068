import React from "react";
import { Api } from "Api/Api";
import { TypeRowProps } from "@inovua/reactdatagrid-community/types";
import DataGrid from "Components/DataGrid/DataGrid";
import {
    ProductListFilters,
    ProductListSort,
    ProductListListItem,
    ProductListSortField,
    ProductListMode,
    ProductListType,
    listProductLists_listProductLists_data,
    PermissionType,
} from "Api/graphql/admin/types";
import { DataGridUrlQueryParser, QueryParserType } from "Components/DataGrid/DataGridUrlQueryParser";
import { ApiError } from "Api/ApiError";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { Box, Checkbox, withStyles, WithStyles } from "@bigfish/admin-ui/core";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import { Path } from "Utils/Path";
import { dataGridStyles } from "@bigfish/admin-ui/styles";
import { Constants } from "Utils/Constants";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import { TabsComponent } from "@bigfish/admin-ui/components";
import { I18n } from "I18n/I18n";
import { connect } from "react-redux";
import { ApplicationState } from "Redux/rootReducer";
import { AuthSelectors } from "Redux/selectors/authSelectors";
import { DataSource, FilterValue } from "Components/DataGrid/DataGridUtils";
import { DataGridParams } from "Components/DataGrid/UrlQueryParser";
import { ListItemActive } from "Components/ListItemActive";
import { CustomTypeColumn } from "Utils/DataGridUtils";

type ReduxProps = {
    permissions: PermissionType[];
    isSuperadmin: boolean;
};

type ComponentProps = {
    isSingleSelect?: boolean;
    selectedProductListIds?: number[];
    onAddProductLists?(products: ProductListListItem[]): void;
    onRemoveProductLists?(products: ProductListListItem[]): void;
    disableRowClick?: boolean;
    minWidth?: number;
    onlyActive?: boolean;
};

type Props = ComponentProps & WithSnackbarProps & WithStyles<typeof dataGridStyles> & ReduxProps;

type State = {
    currentTab: number;
};

class ProductListListDataGrid extends React.Component<Props, State> {
    public readonly state: State = {
        currentTab: 0,
    };

    private getColumns = (): CustomTypeColumn[] => {
        const columns = [
            ...(this.props.selectedProductListIds
                ? [
                      {
                          name: "selected",
                          header: "",
                          sortable: false,
                          defaultWidth: 60,
                          textAlign: "center",
                          filterEditor: () => {
                              const isAllSelected = this.data.length > 0 && this.data.every(data => this.props.selectedProductListIds?.includes(data.id));
                              const indeterminate = !isAllSelected && this.data.some(data => this.props.selectedProductListIds?.includes(data.id));
                              return (
                                  <Checkbox
                                      checked={isAllSelected}
                                      indeterminate={indeterminate}
                                      onChange={() => (isAllSelected ? this.props.onRemoveProductLists?.(this.data) : this.props.onAddProductLists?.(this.data))}
                                  />
                              );
                          },
                          filterable: true,
                          render: ({ data }: { data: ProductListListItem }) => {
                              const isAdded = this.props.selectedProductListIds?.includes(data.id);
                              return (
                                  <div>
                                      <Checkbox checked={isAdded} onChange={() => (isAdded ? this.props.onRemoveProductLists?.([data]) : this.props.onAddProductLists?.([data]))} />
                                  </div>
                              );
                          },
                      } as CustomTypeColumn,
                  ]
                : []),
            {
                name: "id",
                header: I18n.formatMessage({ id: "pages.productListList.grid.column.id" }),
                defaultWidth: Constants.columnWidth.id,
                filterDelay: Constants.filterDelayMS,
            },
            { name: "name", header: I18n.formatMessage({ id: "pages.productListList.grid.column.name" }), defaultFlex: 1, minWidth: 357, filterDelay: Constants.filterDelayMS },
            {
                name: "mode",
                defaultWidth: 170,
                header: I18n.formatMessage({ id: "pages.productListList.grid.column.mode" }),
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: [
                        { id: ProductListMode.auto, label: I18n.formatMessage({ id: `enums.productListMode.${ProductListMode.auto}` }) },
                        { id: ProductListMode.manual, label: I18n.formatMessage({ id: `enums.productListMode.${ProductListMode.manual}` }) },
                    ],
                },
                render: ({ data }: { data: ProductListListItem }) => {
                    return <div>{I18n.formatMessage({ id: `enums.productListMode.${data.mode}` })}</div>;
                },
            },
            {
                name: "active_from",
                filterEditor: DateFilter,
                header: I18n.formatMessage({ id: "pages.productListList.grid.column.active_from" }),
                defaultFlex: 1,
                minWidth: 357,
                render: ({ data }: { data: ProductListListItem }) => {
                    return <div>{data.active_from ? DateUtils.format(data.active_from, DateFormat.minuteDateTime) : ""}</div>;
                },
            },
            {
                name: "active_to",
                filterEditor: DateFilter,
                header: I18n.formatMessage({ id: "pages.productListList.grid.column.active_to" }),
                defaultFlex: 1,
                minWidth: 357,
                render: ({ data }: { data: ProductListListItem }) => {
                    return <div>{data.active_to ? DateUtils.format(data.active_to, DateFormat.minuteDateTime) : ""}</div>;
                },
            },
        ];

        if (!this.props.onlyActive) {
            columns.push({
                name: "is_visible",
                textAlign: "center",
                header: I18n.formatMessage({ id: "pages.productListList.grid.column.is_active" }),
                defaultWidth: Constants.columnWidth.statusIcon,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: [
                        { id: "true", label: I18n.formatMessage({ id: "common.form.isActive.true" }).toLowerCase() },
                        { id: "false", label: I18n.formatMessage({ id: "common.form.isActive.false" }).toLowerCase() },
                    ],
                },
                render: ({ data }: { data: ProductListListItem }) => <ListItemActive isActive={data.is_visible} fromDate={data.active_from} />,
            });
        }

        return columns;
    };

    private filterValues = (): FilterValue[] => {
        const filterValues = [
            { name: "id", operator: "eq", type: "number" },
            { name: "name", operator: "contains", type: "string" },
            { name: "mode", operator: "eq", type: "select" },
            { name: "is_visible", operator: "eq", type: "select" },
            { name: "active_from", operator: "inrange", type: "date" },
            { name: "active_to", operator: "inrange", type: "date" },
        ];
        if (!this.props.isSingleSelect) {
            filterValues.push({ name: "selected", operator: "eq", type: "select" });
        }

        return filterValues;
    };

    private urlQueryParser = new DataGridUrlQueryParser({
        filters: {
            id: QueryParserType.integer,
            name: QueryParserType.string,
            mode: QueryParserType.enum,
            is_visible: QueryParserType.boolean,
            active_from: QueryParserType.dateRange,
            active_to: QueryParserType.dateRange,
        },
        sortField: ProductListSortField,
    });

    private sortFieldToSortOrder = {
        [ProductListSortField.id]: "id",
        [ProductListSortField.name]: "name",
        [ProductListSortField.mode]: "mode",
        [ProductListSortField.is_visible]: "is_visible",
        [ProductListSortField.active_from]: "active_from",
        [ProductListSortField.active_to]: "active_to",
    };

    private getTypeByTab = () => {
        switch (this.state.currentTab) {
            case 0:
                return ProductListType.offer;
            case 1:
                return ProductListType.promotion;
            default:
                return undefined;
        }
    };

    private data: listProductLists_listProductLists_data[] = [];

    private dataSource = async (props: DataGridParams<ProductListSort, ProductListFilters>): Promise<DataSource<ProductListListItem>> => {
        try {
            const filters: ProductListFilters = {
                ...props.filters,
                type: this.getTypeByTab(),
                is_visible: this.props.onlyActive ?? props.filters?.is_visible,
            };
            const result = await Api.listProductLists({
                first: props.limit,
                page: props.page,
                filters,
                sortBy: props.sortBy,
            });
            this.data = result.data;
            return { data: result.data, count: result.paginatorInfo.total };
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }
        return { data: [], count: 0 };
    };

    private getRowLink = (rowProps: TypeRowProps): string => {
        return Path.productListEdit(rowProps.data.id);
    };

    private renderDataGrid = () => {
        return (
            <div>
                <Box mt="30px" />

                <DataGrid
                    className={this.props.classes.dataGrid}
                    urlQueryParser={this.urlQueryParser}
                    rowHeight={50}
                    style={{ minHeight: this.props.minWidth ?? 800 }}
                    dataSource={this.dataSource}
                    filterValues={this.filterValues()}
                    columns={this.getColumns()}
                    rowLink={this.getRowLink}
                    onRowClick={
                        !this.props.disableRowClick && (this.props.isSuperadmin || this.props.permissions.includes(PermissionType.product_list_update)) ? undefined : () => {}
                    }
                    activeCell={null}
                    sortFieldToSortOrder={this.sortFieldToSortOrder}
                />
            </div>
        );
    };

    render() {
        return (
            <div>
                <TabsComponent
                    tabs={[
                        { id: ProductListType.offer, label: I18n.formatMessage({ id: `enums.productListType.${ProductListType.offer}` }), content: this.renderDataGrid() },
                        {
                            id: ProductListType.promotion,
                            label: I18n.formatMessage({ id: `enums.productListType.${ProductListType.promotion}` }),
                            content: this.renderDataGrid(),
                        },
                    ]}
                    ariaLabel="Tabs"
                    currentTab={this.state.currentTab}
                    changeTab={(_, newTab) => this.setState({ currentTab: newTab })}
                />
            </div>
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { permissions: AuthSelectors.getPermissions(state.auth), isSuperadmin: AuthSelectors.isSuperadmin(state.auth) };
};

export default withSnackbar(connect(mapStateToProps)(withStyles(dataGridStyles, { withTheme: true })(ProductListListDataGrid)));
