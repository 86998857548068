import { gql } from "@apollo/client";

export const pageListItemFragment = gql`
    fragment PageListItem on Page {
        id
        name
        url
        active_from
        active_to
        updated_at
        is_visible
    }
`;
