import { gql } from "@apollo/client";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";

export const listBannersQuery = gql`
    query listBanners($filters: BannerFilters, $sortBy: BannerSort, $first: Int!, $page: Int) {
        listBanners(first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            data {
                ... on BannerNormal {
                    id
                    name
                    target_url
                    type
                    active_from
                    active_to
                    is_active
                }
                ... on BannerProductList {
                    id
                    name
                    target_url
                    type
                    active_from
                    active_to
                    is_active
                }
                ... on BannerProduct {
                    id
                    name
                    type
                    active_from
                    active_to
                    is_active
                }
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${paginatorInfoFragment}
`;
