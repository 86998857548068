import { gql } from "@apollo/client";

export const textContentForMenuFragment = gql`
    fragment TextContentForMenu on TextContent {
        id
        title
        url
        category {
            url
        }
    }
`;
