import { gql } from "@apollo/client";

export const productFilterFragment = gql`
    fragment ProductFilter on ProductFilter {
        type
        operator
        product_brand_id
        product_category_id
        product_param_id
        clpe_promotion_id
        price_type
        clpe_promotion_type
        created_at
        rating
        price_type
        product_type
    }
`;
