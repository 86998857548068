import { Typography, Box } from "@bigfish/admin-ui/core";
import { CenteredCard, BrandLogo } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Form } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import React from "react";
import { I18n } from "Src/i18n/I18n";
import { ForgotPasswordForm, ForgotPasswordFormValues } from "./ForgotPasswordForm";
import { RouteComponentProps, StaticContext, withRouter } from "react-router";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { Path } from "Utils/Path";
import { MinimalLayout } from "Components/MinimalLayout";

type LocationState = {
    email?: string;
};

type Props = WithSnackbarProps & RouteComponentProps<{}, StaticContext, LocationState>;

class ForgotPasswordPage extends React.Component<Props> {
    private onSubmit = async (values: ForgotPasswordFormValues, formikHelpers: FormikHelpers<ForgotPasswordFormValues>): Promise<void> => {
        try {
            await Api.adminPasswordReset(values.email);
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.forgotPassword.reset.succeed" }), { variant: "success" });
            this.props.history.push(Path.login);
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.forgotPassword.reset.failed" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
        }
    };

    public render() {
        const initialValues: ForgotPasswordFormValues = {
            email: this.props.location?.state?.email || "",
        };

        return (
            <MinimalLayout>
                <CenteredCard>
                    <BrandLogo />
                    <Box my="30px">
                        <Typography variant="h4" align="center" color="textPrimary">
                            {I18n.formatMessage({ id: "pages.forgotPassword.title" })}
                        </Typography>
                    </Box>
                    <Typography variant="body2" paragraph align={"center"} color="textSecondary">
                        {I18n.formatMessage({ id: "pages.forgotPassword.description" })}
                    </Typography>
                    <Formik initialValues={initialValues} onSubmit={this.onSubmit} validateOnBlur={false}>
                        {props => <ForgotPasswordForm formProps={props} />}
                    </Formik>
                </CenteredCard>
            </MinimalLayout>
        );
    }
}

export default withSnackbar(withRouter(ForgotPasswordPage));
