import { gql } from "@apollo/client";
import { menuFragment } from "../fragments/menuFragment";

export const updateMenuMutation = gql`
    mutation updateMenu($id: Int!, $input: MenuInput!, $csrf: String) {
        updateMenu(id: $id, input: $input, csrf: $csrf) {
            ...Menu
        }
    }
    ${menuFragment}
`;
