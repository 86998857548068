import { createAction, ActionsUnion } from "../actions/actionHelpers";
import { Admin } from "Api/graphql/auth/types";

export enum AuthActionTypes {
    UPDATE_ADMIN = "@auth/update_admin",
    UPDATE_AUTH_TOKEN = "@auth/update_auth_token",
    UPDATE_MEDIA_LIBRARY_TOKEN = "@auth/update_media_library_token",
    LOGOUT = "@auth/logout",
}

export const AuthActions = {
    updateAdmin: (admin: Admin) => {
        return createAction(AuthActionTypes.UPDATE_ADMIN, { admin });
    },
    updateToken: (authToken: string) => createAction(AuthActionTypes.UPDATE_AUTH_TOKEN, { authToken }),
    updateMediaLibraryToken: (mediaLibraryToken: string | null) => createAction(AuthActionTypes.UPDATE_MEDIA_LIBRARY_TOKEN, { mediaLibraryToken }),
    logout: () => {
        return createAction(AuthActionTypes.LOGOUT);
    },
};

export type AuthActions = ActionsUnion<typeof AuthActions>;
