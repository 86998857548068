import React from "react";
import { FullscreenLoader } from "@bigfish/admin-ui/components";
import { PageLayout } from "./PageLayout";
import { useSelector } from "react-redux";
import { ApplicationState } from "Redux/rootReducer";
import { AuthSelectors } from "Redux/selectors/authSelectors";
import { AdminLayout } from "@bigfish/admin-ui/components";

type Props = {
    breadcrumb?: React.ReactElement;
};

export const Loading = (props: Props) => {
    const isLoggedIn = useSelector((state: ApplicationState) => AuthSelectors.isLoggedIn(state.auth));
    if (isLoggedIn) {
        return (
            <PageLayout breadcrumb={props.breadcrumb}>
                <FullscreenLoader visible={true} />
            </PageLayout>
        );
    }

    return (
        <AdminLayout>
            <FullscreenLoader visible={true} />
        </AdminLayout>
    );
};
