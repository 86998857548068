import { gql } from "@apollo/client";
import { productBrandSelectItemFragment } from "../fragments/productBrandSelectItemFragment";

export const listProductBrandSelectItemsQuery = gql`
    query listProductBrandSelectItems($first: Int!) {
        listProductBrands(first: $first) {
            data {
                ...ProductBrandSelectItem
            }
        }
    }
    ${productBrandSelectItemFragment}
`;
