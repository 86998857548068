import { gql } from "@apollo/client";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";
import { personListItemFragment } from "../fragments/personListItemFragment";

export const personsQuery = gql`
    query persons($filters: PersonFilters, $sortBy: PersonSort, $first: Int!, $page: Int) {
        persons(first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            data {
                ...PersonListItem
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${personListItemFragment}
    ${paginatorInfoFragment}
`;
