import { gql } from "@apollo/client";
import { todoListItemFragment } from "../fragments/todoListItemFragment";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";

export const listTodosQuery = gql`
    query listTodos($filters: TodoFilters, $sortBy: TodoSort, $first: Int!, $page: Int) {
        listTodos(first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            data {
                ...TodoListItem
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${todoListItemFragment}
    ${paginatorInfoFragment}
`;
