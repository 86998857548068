import { gql } from "@apollo/client";
import { galleryFragment } from "../fragments/galleryFragment";

export const updateGalleryMutation = gql`
    mutation updateGallery($id: ID!, $input: UpdateGalleryInput!, $csrf: String) {
        updateGallery(id: $id, input: $input, csrf: $csrf) {
            ...Gallery
        }
    }
    ${galleryFragment}
`;
