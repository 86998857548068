import { Typography } from "@bigfish/admin-ui/core";
import { ActiveBreadcrumbItem, PageCard } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { I18n } from "Src/i18n/I18n";
import { AdminForm, AdminFormValues } from "./AdminForm";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { PageLayout } from "Components/PageLayout";
import { Link } from "Components/Link";
import { Breadcrumbs } from "Components/Breadcrumbs";
import Prompt from "Components/Prompt";
import { RoleListItem } from "Api/graphql/admin/types";

type Props = RouteComponentProps & WithSnackbarProps;

type State = {
    isCreateSucceed: boolean;
    selectableRoles: RoleListItem[];
};

class AdminCreatePage extends React.Component<Props, State> {
    public readonly state: State = {
        isCreateSucceed: false,
        selectableRoles: [],
    };

    public async componentDidMount(): Promise<void> {
        try {
            const { data: selectableRoles } = await Api.listRoles({ first: 9999 });
            this.setState({ selectableRoles });
        } catch (error) {
            this.props.enqueueSnackbar(error.message, { variant: "error" });
            this.props.history.push(Path.adminList);
        }
    }

    private onSubmit = async (values: AdminFormValues, formikHelpers: FormikHelpers<AdminFormValues>, isRedirectHandled?: boolean): Promise<boolean> => {
        try {
            await Api.createAdmin({
                email: values.email,
                name: values.name,
                phone: `${values.phone}`,
                is_active: values.is_active,
                roles: values.roles,
                store_id: values.store_id,
            });
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.admin.create.succeed" }), { variant: "success" });
            if (!isRedirectHandled) {
                this.setState({ isCreateSucceed: true });
            }
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.admin.create.failed" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
            return false;
        }
    };

    public render() {
        if (this.state.isCreateSucceed) {
            return <Redirect to={Path.adminList} />;
        }

        const initialValues: AdminFormValues = {
            name: "",
            email: "",
            is_active: false,
            store_id: null,
            roles: [],
        };

        return (
            <PageLayout
                breadcrumb={
                    <Breadcrumbs>
                        <Link to={Path.adminList} color="inherit">
                            {I18n.formatMessage({ id: "pages.adminList.breadcrumb" })}
                        </Link>
                        <ActiveBreadcrumbItem aria-current="page">{I18n.formatMessage({ id: "pages.admin.create.breadcrumb" })}</ActiveBreadcrumbItem>
                    </Breadcrumbs>
                }
            >
                <Typography variant="h1">{I18n.formatMessage({ id: "pages.admin.create.title" })}</Typography>
                <PageCard.Container>
                    <Formik initialValues={initialValues} onSubmit={this.onSubmit} validateOnBlur={false}>
                        {props => (
                            <>
                                <AdminForm formType={FormType.create} selectableRoles={this.state.selectableRoles} formProps={props} />
                                <Prompt when={props.dirty} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props, true)} />
                            </>
                        )}
                    </Formik>
                </PageCard.Container>
            </PageLayout>
        );
    }
}

export default withRouter(withSnackbar(AdminCreatePage));
