import React from "react";
import { DefaultModal } from "@bigfish/admin-ui/components";
import { Button, withStyles, WithStyles } from "@bigfish/admin-ui/core";
import styled from "styled-components";
import { GiftCard, GiftCardLog } from "Api/graphql/admin/types";
import { dataGridStyles } from "@bigfish/admin-ui/styles";
import UnControlledDataGrid from "Components/DataGrid/UCDataGrid";
import { I18n } from "I18n/I18n";
import { DataSource } from "Components/DataGrid/DataGridUtils";
import { CustomTypeColumn } from "Utils/DataGridUtils";
import { DateFormat, DateUtils } from "Utils/DateUtils";

type ComponentProps = {
    onClose: () => void;
    itemToLog: GiftCard | null;
};

type Props = ComponentProps & WithStyles<typeof dataGridStyles>;

const GiftCardLogModal = ({ onClose, itemToLog, classes }: Props) => {
    const logs = itemToLog?.log_sent_to ?? [];

    const getDataSource = (logs: GiftCardLog[]) => async (): Promise<DataSource<GiftCardLog>> => ({ data: logs, count: logs.length });

    const getColumns = (): CustomTypeColumn[] => {
        return [
            {
                name: "date",
                header: I18n.formatMessage({ id: "pages.giftCardList.logs.grid.column.date" }),
                defaultWidth: 175,
                render: ({ data }: { data: GiftCardLog }) => {
                    return <div>{data.date ? DateUtils.format(data.date, DateFormat.minuteDateTime) : ""}</div>;
                },
            },
            {
                name: "email",
                header: I18n.formatMessage({ id: "pages.giftCardList.logs.grid.column.email" }),
                defaultFlex: 1,
                minWidth: 250,
            },
        ];
    };

    return (
        <GiftCardLogModalWrapper>
            <DefaultModal
                title={I18n.formatMessage({ id: "pages.giftCardOrder.logModalTitle" })}
                rightButtonsComponent={
                    <Button variant="contained" color="primary" onClick={onClose}>
                        {I18n.formatMessage({ id: "common.ok" })}
                    </Button>
                }
                open={!!itemToLog}
                onClose={onClose}
            >
                <UnControlledDataGrid<GiftCardLog>
                    className={classes.dataGrid}
                    rowHeight={50}
                    style={{ minHeight: 150 + logs.length * 50 }}
                    dataSource={getDataSource(logs)}
                    columns={getColumns()}
                    activeCell={null}
                    onRowClick={() => {}}
                    rowStyle={{ cursor: "auto" }}
                    pagination={false}
                />
            </DefaultModal>
        </GiftCardLogModalWrapper>
    );
};

const GiftCardLogModalWrapper = styled.div`
    .MuiCard-root {
        overflow: hidden !important;
    }
`;

export default withStyles(dataGridStyles, { withTheme: true })(GiftCardLogModal);
