import { gql } from "@apollo/client";
import { textContentFragment } from "../fragments/textContentFragment";

export const getAdminContentQuery = gql`
    query getAdminContent($id: ID!) {
        adminContents(first: 1, filters: { id: $id }) {
            data {
                ... on TextContent {
                    ...TextContent
                }
            }
        }
    }
    ${textContentFragment}
`;
