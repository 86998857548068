import { ActiveBreadcrumbItem, PageCard, TitleBar } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { I18n } from "Src/i18n/I18n";
import { DepartmentForm, DepartmentFormValues } from "./DepartmentForm";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { PageLayout } from "Components/PageLayout";
import { Link } from "Components/Link";
import { Breadcrumbs } from "Components/Breadcrumbs";
import Prompt from "Components/Prompt";
import { FunctionalButton } from "Components/FunctionalButton";
import { DeleteForever } from "@bigfish/admin-ui/icons";

type Props = RouteComponentProps & WithSnackbarProps;

type State = {
    isCreateSucceed: boolean;
};

class DepartmentCreatePage extends React.Component<Props, State> {
    public readonly state: State = {
        isCreateSucceed: false,
    };

    private onSubmit = async (values: DepartmentFormValues, formikHelpers: FormikHelpers<DepartmentFormValues>, isRedirectHandled?: boolean): Promise<boolean> => {
        try {
            await Api.createDepartment({
                name: values.name,
                is_grouped: values.is_grouped,
                is_filter_enabled: values.is_grouped ? values.is_filter_enabled : undefined,
                is_map_enabled: values.is_grouped ? values.is_map_enabled : undefined,
            });
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.department.create.succeed" }), { variant: "success" });
            if (!isRedirectHandled) {
                this.setState({ isCreateSucceed: true });
            }
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.department.create.failed" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
            return false;
        }
    };

    public render() {
        if (this.state.isCreateSucceed) {
            return <Redirect to={Path.departmentList} />;
        }

        const initialValues: DepartmentFormValues = {
            name: "",
            is_grouped: false,
            is_filter_enabled: false,
            is_map_enabled: false,
            divisions: [],
            benefits: [],
        };

        return (
            <PageLayout
                breadcrumb={
                    <Breadcrumbs>
                        <Link to={Path.departmentList} color="inherit">
                            {I18n.formatMessage({ id: "pages.departmentList.breadcrumb" })}
                        </Link>
                        <ActiveBreadcrumbItem aria-current="page">{I18n.formatMessage({ id: "pages.department.create.breadcrumb" })}</ActiveBreadcrumbItem>
                    </Breadcrumbs>
                }
            >
                <TitleBar title={I18n.formatMessage({ id: "pages.department.create.title" })} rightButtonsComponent={<FunctionalButton disabled icon={<DeleteForever />} />} />
                <PageCard.Container>
                    <Formik initialValues={initialValues} onSubmit={this.onSubmit} validateOnBlur={true}>
                        {props => (
                            <>
                                <DepartmentForm formType={FormType.create} formProps={props} />
                                <Prompt when={props.dirty} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props, true)} />
                            </>
                        )}
                    </Formik>
                </PageCard.Container>
            </PageLayout>
        );
    }
}

export default withRouter(withSnackbar(DepartmentCreatePage));
