import { gql } from "@apollo/client";

export const productSimpleFragment = gql`
    fragment ProductSimple on ProductSimple {
        id
        lfdnr
        name
        is_active
        is_visible
        main_categories {
            id
            name
        }
        categories {
            id
            name
        }
        created_at
        updated_at
        active_from
    }
`;
