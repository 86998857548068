import React, { useCallback, useState } from "react";
import { Form, FormType } from "Components/Form";
import { FastField, Field, FieldProps, FormikProps } from "formik";
import { I18n } from "Src/i18n/I18n";
import CategorySelect from "./CategorySelect";
import DocumentModal, { Document } from "./DocumentModal";
import MediaLibraryImageInput from "Components/Inputs/MediaLibraryImageInput";
import { Helpers } from "Utils/Helpers";
import { useHistory } from "react-router-dom";
import { Path } from "Utils/Path";
import { I18nHelpers } from "I18n/I18nHelpers";
import { Validator } from "Utils/Validator";
import { TextContent } from "Api/graphql/admin/types";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import RichTextEditor from "Components/Inputs/RichTextEditor/RichTextEditor";
import { ClearRounded } from "@bigfish/admin-ui/icons";
import { FullscreenLoader, PageCard, SavePanel } from "@bigfish/admin-ui/components";
import { makeStyles, TextField, Box, Typography, FormControl, Button, Icon, ListItem, ListItemText, IconButton, FormHelperText, Grid, List } from "@bigfish/admin-ui/core";

export enum ContentSaveType {
    schedule = "schedule",
    removeSchedule = "removeSchedule",
    update = "update",
    draft = "draft",
    publish = "publish",
    unPublish = "unPublish",
}

export type ContentFormValues = {
    id?: string;
    title: string;
    lead: string | null;
    leadImage: string | null;
    content: string | null;
    author: string | null;
    documents: Document[];
    metaImage: string | null;
    metaTitle: string | null;
    metaKeywords: string | null;
    metaDescription: string | null;
    isActive: boolean;
    activeFrom: string | null;
    activeTo: string | null;
    url: string;
    categoryId: string;
    saveType?: ContentSaveType;
};

type Props = {
    formType: FormType;
    formProps: FormikProps<ContentFormValues>;
    content?: TextContent;
};

const useStyles = makeStyles({
    container: {
        padding: 30,
    },
    section: {
        marginTop: 30,
    },
    dateField: {
        marginRight: 30,
    },
    editorField: {
        marginTop: 13,
    },
    list: {
        width: "470px",
        padding: 0,
        borderRadius: 5,
        border: "1px solid",
        borderColor: "#E4E8F7",
        marginBottom: 7,

        "& li": {
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: 4,
            "&> div > span": {
                textOverflow: "ellipsis",
                overflow: "hidden",
            },
        },

        "& button": {
            marginLeft: 4,
        },

        "& svg": {
            fill: "#7895C1",
        },
    },
});

export const ContentForm = (props: Props) => {
    const classes = useStyles();
    const history = useHistory();
    const onCancelClick = useCallback(() => {
        history.push(Path.contentList);
    }, [history]);

    const [documentsModal, setDocumentsModal] = useState(false);
    const isDelayed = props.formProps.values.activeFrom ? DateUtils.isAfter(DateUtils.parseISO(props.formProps.values.activeFrom), new Date()) : false;
    const wasDelayed = props.content?.active_from ? DateUtils.isAfter(DateUtils.parseISO(props.content?.active_from), new Date()) : false;

    return (
        <>
            <Form formProps={props.formProps}>
                <PageCard.Heading title={I18n.formatMessage({ id: "pages.content.form.content" })} />
                <Field name="title" validate={I18nHelpers.formatValidator(Validator.contentTitle)}>
                    {({ field, meta }: FieldProps) => (
                        <TextField
                            type="text"
                            label={I18n.formatMessage({ id: "pages.content.form.title" })}
                            fullWidth
                            variant="outlined"
                            {...field}
                            error={meta.touched && !!meta.error}
                            required
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const title = e.currentTarget.value;

                                props.formProps.setValues({
                                    ...props.formProps.values,
                                    title,
                                    url: props.formType === FormType.create ? Helpers.updateSlug(field.value, title, props.formProps.values.url) : props.formProps.values.url,
                                });
                            }}
                            helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                        />
                    )}
                </Field>

                <Box mt="30px">
                    <Field name="url" validate={I18nHelpers.formatValidator(Validator.contentUrl)}>
                        {({ field, meta }: FieldProps) => (
                            <TextField
                                type="text"
                                label={I18n.formatMessage({ id: "pages.content.form.url" })}
                                fullWidth
                                variant="outlined"
                                {...field}
                                helperText={props.formType === FormType.create ? Form.getHelperText(meta, I18n.formatMessage({ id: "pages.content.form.urlHelperText" })) : ""}
                                required
                                disabled={props.formType !== FormType.create}
                                error={props.formType === FormType.create ? meta.touched && !!meta.error : undefined}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                    props.formProps.setFieldValue("url", Helpers.toSlug(event.currentTarget.value, false));
                                }}
                            />
                        )}
                    </Field>
                </Box>

                <Box mt="30px" mb="13px">
                    <Typography variant="body1" component="h3" color="textSecondary">
                        {I18n.formatMessage({ id: "pages.content.form.lead" })}
                    </Typography>
                </Box>

                <Box mt="15px">
                    <FastField name="lead">
                        {({ field, meta }: FieldProps) => (
                            <FormControl fullWidth error={!!meta.error}>
                                <RichTextEditor
                                    name="lead"
                                    config="lead"
                                    value={field.value}
                                    onChange={(lead: string) => {
                                        props.formProps.setFieldValue("lead", lead, false);
                                    }}
                                />
                            </FormControl>
                        )}
                    </FastField>
                </Box>

                <Box mt="30px">
                    <Typography variant="body1" component="h3" color="textSecondary">
                        {I18n.formatMessage({ id: "pages.content.form.image" })}
                    </Typography>
                </Box>
                <Box mt="15px">
                    <FastField name="leadImage">
                        {({ field }: FieldProps) => (
                            <MediaLibraryImageInput
                                id="article-image"
                                name="leadImage"
                                value={field.value}
                                onChange={(value: string | null) => {
                                    props.formProps.setFieldValue("leadImage", value);
                                }}
                            />
                        )}
                    </FastField>
                </Box>

                <Box mt="30px">
                    <Typography variant="body1" component="h3" color="textSecondary">
                        {I18n.formatMessage({ id: "pages.content.form.content" })}
                    </Typography>
                </Box>
                <Box mt="15px">
                    <FastField name="content">
                        {({ field }: FieldProps) => (
                            <RichTextEditor
                                value={field.value}
                                name="content"
                                config="default"
                                onChange={(content: string) => {
                                    props.formProps.setFieldValue("content", content);
                                }}
                            />
                        )}
                    </FastField>
                </Box>

                <Box mt="30px">
                    <FastField name="categoryId">
                        {({ field, meta }: FieldProps) => (
                            <CategorySelect
                                label={I18n.formatMessage({ id: "pages.content.form.category" })}
                                fullWidth
                                variant="outlined"
                                helperText={I18n.formatMessage({ id: "common.optional" })}
                                {...field}
                                error={!!meta.error}
                            />
                        )}
                    </FastField>
                </Box>

                <Box mt="30px">
                    <FastField name="author">
                        {({ field, meta }: FieldProps) => (
                            <TextField
                                type="text"
                                label={I18n.formatMessage({ id: "pages.content.form.author" })}
                                fullWidth
                                variant="outlined"
                                helperText={I18n.formatMessage({ id: "common.optional" })}
                                {...field}
                                error={!!meta.error}
                            />
                        )}
                    </FastField>
                </Box>

                <Box mt="30px">
                    <Typography variant="body1" component="h3" color="textSecondary">
                        {I18n.formatMessage({ id: "pages.content.form.documents" })}
                    </Typography>
                </Box>

                <FastField name="documents">
                    {({ field }: FieldProps) => (
                        <>
                            <Box my="10px">
                                <Button variant="outlined" color="primary" size="large" startIcon={<Icon className="fa fa-plus-circle" />} onClick={() => setDocumentsModal(true)}>
                                    {I18n.formatMessage({ id: "common.add" })}
                                </Button>
                            </Box>

                            {field.value.length > 0 && (
                                <div>
                                    <List className={classes.list}>
                                        {field.value.map((f: Document, index: number) => {
                                            const onRemoveDoc = (): void => {
                                                const newValue = [...field.value];
                                                newValue.splice(index, 1);
                                                props.formProps.setFieldValue("documents", newValue);
                                            };
                                            return (
                                                <ListItem key={f.id} divider={index !== field.value.length - 1}>
                                                    <ListItemText primary={f.file_name} />
                                                    <IconButton color="primary" onClick={onRemoveDoc} title={I18n.formatMessage({ id: "common.delete" })}>
                                                        <ClearRounded />
                                                    </IconButton>
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                </div>
                            )}
                            <FormHelperText>{I18n.formatMessage({ id: "common.optional" })}</FormHelperText>
                        </>
                    )}
                </FastField>

                <Box mt="30px">
                    <PageCard.Heading title={I18n.formatMessage({ id: "pages.content.form.publish.heading" })} />
                </Box>

                <Field name="activeFrom">
                    {({ field, meta }: FieldProps) => (
                        <TextField
                            className={classes.dateField}
                            id="publication-date"
                            label={I18n.formatMessage({ id: "pages.content.form.activeFrom" })}
                            type="datetime-local"
                            variant="outlined"
                            helperText={meta.error}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                max: props.formProps.values.activeTo || undefined,
                            }}
                            {...field}
                            error={!!meta.error}
                        />
                    )}
                </Field>

                <Field name="activeTo">
                    {({ field, meta }: FieldProps) => (
                        <TextField
                            id="expiration-date"
                            label={I18n.formatMessage({ id: "pages.content.form.activeUntil" })}
                            type="datetime-local"
                            variant="outlined"
                            helperText={meta.error}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                min: props.formProps.values.activeFrom || undefined,
                            }}
                            {...field}
                            error={!!meta.error}
                        />
                    )}
                </Field>

                {props.content && (
                    <>
                        <Box mt="30px">
                            <Typography color="textSecondary" gutterBottom>
                                {I18n.formatMessage({ id: "pages.content.form.id" })}
                            </Typography>
                            <Typography>{props.content.id}</Typography>
                        </Box>

                        <Box mt="30px">
                            <Typography color="textSecondary" gutterBottom>
                                {I18n.formatMessage({ id: "pages.content.form.isPublished" })}
                            </Typography>
                            <Typography>{I18n.formatMessage({ id: props.content.is_active ? "common.yes" : "common.no" })}</Typography>
                        </Box>

                        {props.content.last_published_at && (
                            <Box mt="30px">
                                <Typography color="textSecondary" gutterBottom>
                                    {I18n.formatMessage({ id: "pages.content.form.lastPublished" })}
                                </Typography>
                                <Typography>{DateUtils.format(DateUtils.parseISO(props.content.last_published_at), DateFormat.yyyymmddhhmm)}</Typography>
                            </Box>
                        )}

                        <Box mt="30px">
                            <Typography color="textSecondary" gutterBottom>
                                {I18n.formatMessage({ id: "pages.content.form.updatedAt" })}
                            </Typography>
                            <Typography>{DateUtils.format(DateUtils.parseISO(props.content.updated_at), DateFormat.yyyymmddhhmm)}</Typography>
                        </Box>
                    </>
                )}

                <Box mt="30px">
                    <PageCard.Heading title={I18n.formatMessage({ id: "pages.content.form.ogTags" })} />
                </Box>
                <Box mt="30px">
                    <FastField name="metaTitle">
                        {({ field, meta }: FieldProps) => (
                            <TextField
                                type="text"
                                label={I18n.formatMessage({ id: "pages.content.form.ogTitle" })}
                                fullWidth
                                variant="outlined"
                                helperText={I18n.formatMessage({ id: "common.optional" })}
                                {...field}
                                error={!!meta.error}
                            />
                        )}
                    </FastField>
                </Box>

                <Box mt="30px">
                    <FastField name="metaDescription">
                        {({ field, meta }: FieldProps) => (
                            <TextField
                                type="text"
                                label={I18n.formatMessage({ id: "pages.content.form.ogDescription" })}
                                fullWidth
                                variant="outlined"
                                helperText={I18n.formatMessage({ id: "common.optional" })}
                                {...field}
                                error={!!meta.error}
                            />
                        )}
                    </FastField>
                </Box>

                <Box mt="30px">
                    <FastField name="metaKeywords">
                        {({ field, meta }: FieldProps) => (
                            <TextField
                                type="text"
                                label={I18n.formatMessage({ id: "pages.content.form.ogKeywords" })}
                                fullWidth
                                variant="outlined"
                                helperText={I18n.formatMessage({ id: "common.optional" })}
                                {...field}
                                error={!!meta.error}
                            />
                        )}
                    </FastField>
                </Box>

                <Box mt="30px">
                    <Typography variant="body1" component="h3" color="textSecondary">
                        {I18n.formatMessage({ id: "pages.content.form.ogImage" })}
                    </Typography>
                </Box>
                <Box mt="15px" mb="30px">
                    <FastField name="metaImage">
                        {({ field }: FieldProps) => (
                            <MediaLibraryImageInput
                                id="article-image"
                                name="metaImage"
                                value={field.value}
                                onChange={(value: string | null) => {
                                    props.formProps.setFieldValue("metaImage", value);
                                }}
                            />
                        )}
                    </FastField>
                </Box>

                <SavePanel>
                    <Grid container justify="space-between">
                        <Grid item>
                            <Button variant="outlined" color="primary" onClick={onCancelClick}>
                                {I18n.formatMessage({ id: "common.cancel" })}
                            </Button>
                        </Grid>
                        <Grid item>
                            {props.content?.is_active ? (
                                <Grid container justify="space-between">
                                    <Grid item>
                                        <Button
                                            startIcon={<Icon className="fas fa-save" />}
                                            variant="outlined"
                                            color="primary"
                                            disabled={!props.formProps.isValid}
                                            style={{ marginRight: 20 }}
                                            onClick={() => {
                                                if (!wasDelayed && isDelayed) {
                                                    props.formProps.setFieldValue("saveType", ContentSaveType.draft);
                                                } else {
                                                    props.formProps.setFieldValue("saveType", isDelayed ? ContentSaveType.removeSchedule : ContentSaveType.unPublish);
                                                }
                                                props.formProps.submitForm();
                                            }}
                                        >
                                            {!wasDelayed && isDelayed
                                                ? I18n.formatMessage({ id: `pages.content.form.button.${ContentSaveType.draft}` })
                                                : I18n.formatMessage({ id: `pages.content.form.button.${isDelayed ? ContentSaveType.removeSchedule : ContentSaveType.unPublish}` })}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            startIcon={<Icon className="fa fa-check-circle" />}
                                            variant="contained"
                                            color="secondary"
                                            disabled={!props.formProps.dirty || !props.formProps.isValid}
                                            onClick={() => {
                                                props.formProps.setFieldValue("saveType", isDelayed && !wasDelayed ? ContentSaveType.schedule : ContentSaveType.update);
                                                props.formProps.submitForm();
                                            }}
                                        >
                                            {I18n.formatMessage({
                                                id: `pages.content.form.button.${isDelayed && !wasDelayed ? ContentSaveType.schedule : ContentSaveType.update}`,
                                            })}
                                        </Button>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid container justify="space-between">
                                    <Grid item>
                                        <Button
                                            startIcon={<Icon className="fas fa-save" />}
                                            variant="outlined"
                                            color="primary"
                                            disabled={!props.formProps.dirty || !props.formProps.isValid}
                                            style={{ marginRight: 20 }}
                                            onClick={() => {
                                                props.formProps.setFieldValue("saveType", ContentSaveType.draft);
                                                props.formProps.submitForm();
                                            }}
                                        >
                                            {I18n.formatMessage({ id: "pages.content.form.button.draft" })}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            startIcon={<Icon className="fa fa-check-circle" />}
                                            variant="contained"
                                            color="secondary"
                                            disabled={!props.formProps.isValid || (props.formType === FormType.create && !props.formProps.dirty)}
                                            onClick={() => {
                                                props.formProps.setFieldValue("saveType", isDelayed ? ContentSaveType.schedule : ContentSaveType.publish);
                                                props.formProps.submitForm();
                                            }}
                                        >
                                            {I18n.formatMessage({ id: `pages.content.form.button.${isDelayed ? ContentSaveType.schedule : ContentSaveType.publish}` })}
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </SavePanel>
                <FullscreenLoader visible={props.formProps.isSubmitting} />
            </Form>
            <DocumentModal
                isOpened={documentsModal}
                documents={props.formProps.values.documents}
                onDocumentsChange={(documents: Document[]) => {
                    props.formProps.setFieldValue("documents", documents);
                }}
                onClose={() => setDocumentsModal(false)}
            />
        </>
    );
};
