import React from "react";
import { I18n } from "I18n/I18n";
import { DefaultModal } from "@bigfish/admin-ui/components";
import { Box, Button, Paper, RootRef, Table, TableCell, TableContainer, TableRow, Typography } from "@bigfish/admin-ui/core";
import { ProductCategoryChild } from "Api/graphql/admin/types";
import { DragDropContext, Draggable, DraggingStyle, Droppable, DropResult, NotDraggingStyle } from "react-beautiful-dnd";
import { Api } from "Api/Api";
import { useSnackbar } from "notistack";
import { ApiError } from "Api/ApiError";
import { ProductCategoryToReorder } from "./ProductCategoryListPage";
import { isEqual } from "lodash";

type Props = {
    onClose: (withRefresh?: boolean) => void;
    productCategory: ProductCategoryToReorder;
};

export const ReorderCategoriesModal = (props: Props) => {
    const { enqueueSnackbar } = useSnackbar();
    const [children, setChildren] = React.useState<ProductCategoryChild[] | null>(props.productCategory.children);

    if (!children || children.length === 0) return null;

    const onSubmit = async () => {
        try {
            await Api.orderProductCategory(
                children.map((child: ProductCategoryChild, index: number) => {
                    return { id: child.id, position: index + 1 };
                })
            );
            enqueueSnackbar(I18n.formatMessage({ id: "pages.productCategoryList.reorderCategoriesModal.success" }), { variant: "success" });
            props.onClose(true);
        } catch (error) {
            if (error instanceof ApiError) {
                enqueueSnackbar(error.message, { variant: "error" });
            }
        }
    };

    const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined) => ({
        ...draggableStyle,
        ...(isDragging && {
            background: "rgb(235,235,235)",
        }),
    });

    const reorder = (list: ProductCategoryChild[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) return;
        const newChildren = reorder(children, result.source.index, result.destination.index);
        setChildren(newChildren);
    };

    return (
        <DefaultModal
            title={props.productCategory.modalTitle}
            leftButtonsComponent={
                <Button variant="outlined" color="primary" onClick={() => props.onClose()}>
                    {I18n.formatMessage({ id: "common.cancel" })}
                </Button>
            }
            rightButtonsComponent={
                <Button variant="contained" color="primary" onClick={onSubmit} disabled={isEqual(children, props.productCategory.children)}>
                    {I18n.formatMessage({ id: "common.save" })}
                </Button>
            }
            open={true}
            onClose={() => props.onClose()}
        >
            <Typography variant="body2" color="textSecondary">
                {I18n.formatMessage({ id: "pages.productCategoryList.reorderCategoriesModal.description" })}
            </Typography>

            <Box mt="30px" />

            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable-reorder-categories-modal">
                    {provided => (
                        <RootRef rootRef={provided.innerRef}>
                            <TableContainer component={Paper} style={{ maxHeight: "60vh" }}>
                                <Table className="product-category-table">
                                    <tbody>
                                        {children.map((childCategory: ProductCategoryChild, index: number) => (
                                            <Draggable key={`draggable-key-${childCategory.id}`} draggableId={`draggable-id-${childCategory.id}`} index={index}>
                                                {(provided, snapshot) => (
                                                    <TableRow
                                                        key={childCategory.id}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                    >
                                                        <TableCell>{childCategory.name}</TableCell>
                                                    </TableRow>
                                                )}
                                            </Draggable>
                                        ))}
                                    </tbody>
                                </Table>
                            </TableContainer>
                        </RootRef>
                    )}
                </Droppable>
            </DragDropContext>
        </DefaultModal>
    );
};
