import { gql } from "@apollo/client";
import { paymentMethodFragment } from "../fragments/paymentMethodFragment";

export const getPaymentMethodByIdQuery = gql`
    query getPaymentMethodById($first: Int!, $filters: PaymentMethodFilters) {
        listPaymentMethods(first: $first, filters: $filters) {
            data {
                ...PaymentMethod
            }
        }
    }
    ${paymentMethodFragment}
`;
