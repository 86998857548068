import { gql } from "@apollo/client";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";
import { roleListItemFragment } from "../fragments/roleListItemFragment";

export const listRolesQuery = gql`
    query listRoles($first: Int!, $sortBy: RoleSort) {
        listRoles(first: $first, sortBy: $sortBy) {
            data {
                ...RoleListItem
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${roleListItemFragment}
    ${paginatorInfoFragment}
`;
