import { gql } from "@apollo/client";

export const mailTemplateFragment = gql`
    fragment MailTemplate on MailTemplate {
        id
        subject
        content
        description
        is_active
    }
`;
