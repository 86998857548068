import { gql } from "@apollo/client";

export const categoryFragment = gql`
    fragment Category on Category {
        id
        lead
        title
        url
        meta_title
        meta_description
        meta_keywords
        created_at
        updated_at
    }
`;
