import { gql } from "@apollo/client";

export const ratingListItemFragment = gql`
    fragment RatingListItem on OrderRating {
        id
        order_increment_id
        rating_courier
        note_courier
        rating_purchase
        note_purchase
        rating_recommend
        created_at
        updated_at
    }
`;
