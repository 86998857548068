import { gql } from "@apollo/client";
import { badgeFragment } from "../fragments/badgeFragment";

export const listBadgeByIdQuery = gql`
    query listBadgeById($filters: BadgeFilters, $first: Int!) {
        listBadges(first: $first, filters: $filters) {
            data {
                ...Badge
            }
        }
    }
    ${badgeFragment}
`;
