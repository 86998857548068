import React from "react";

import { Formik, FormikHelpers } from "formik";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";

import { ActiveBreadcrumbItem, TitleBar } from "@bigfish/admin-ui/components";
import { Box } from "@bigfish/admin-ui/core";

import { CalendarDay } from "Api/graphql/admin/types";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Breadcrumbs } from "Components/Breadcrumbs";
import { FunctionalButton } from "Components/FunctionalButton";
import { Form, FormType } from "Components/Form";
import { FunctionConfirmModal } from "Components/FunctionConfirmModal";
import { Link } from "Components/Link";
import { Loading } from "Components/Loading";
import { PageLayout } from "Components/PageLayout";
import Prompt from "Components/Prompt";
import { I18n } from "I18n/I18n";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import { Path } from "Utils/Path";

import { CalendarDayForm, CalendarDayFormValues } from "./CalendarDayForm";
import { DeleteForever } from "@bigfish/admin-ui/icons";

type RouteParams = {
    date?: string;
};

type Props = WithSnackbarProps & RouteComponentProps<RouteParams>;

type State = {
    isLoading: boolean;
    calendarDay: CalendarDay | null;
    isDeleteDialogVisible: boolean;
};

class CalendarDayEditPage extends React.Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        calendarDay: null,
        isDeleteDialogVisible: false,
    };

    public async componentDidMount(): Promise<void> {
        if (!this.props.match.params.date) {
            return;
        }

        try {
            const calendarDay = await Api.getCalendarDay(`${this.props.match.params.date} 00:00:00`);
            this.setState({ calendarDay, isLoading: false });
        } catch (error) {
            this.props.enqueueSnackbar(error.message, { variant: "error" });
            this.props.history.push(Path.calendarDaysList);
        }
    }

    private onSubmit = async (values: CalendarDayFormValues, formikHelpers: FormikHelpers<CalendarDayFormValues>): Promise<boolean> => {
        try {
            const calendarDay = await Api.setCalendarDay({
                date: DateUtils.format(values.date, DateFormat.default),
                input: {
                    is_delivery: !values.is_delivery,
                    is_workday: values.is_workday,
                },
            });
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.calendarDay.onEditSubmit.succeed" }), { variant: "success" });
            if (this.props.match.params.date !== calendarDay.date) {
                this.props.history.replace(Path.calendarDayEdit(calendarDay.date));
            }
            this.setState({ calendarDay });
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.calendarDay.onEditSubmit.error" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
        }
        return false;
    };

    private getBreadcrumb = () => {
        return (
            <Breadcrumbs>
                <Link to={Path.calendarDaysList} color="inherit">
                    {I18n.formatMessage({ id: "pages.calendarDay.editBreadcrumb" })}
                </Link>
                <ActiveBreadcrumbItem aria-current="page">{this.state.calendarDay?.date || ""}</ActiveBreadcrumbItem>
            </Breadcrumbs>
        );
    };

    private onDeleteButtonClick = (): void => {
        this.setState({ isDeleteDialogVisible: true });
    };

    private onDeleteDialogClose = (): void => {
        this.setState({ isDeleteDialogVisible: false });
    };

    private onDeleteClick = async (): Promise<void> => {
        try {
            await Api.resetCalendarDay(this.state.calendarDay?.date);
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.calendarDay.edit.deleteSucceed" }), { variant: "success" });
            this.setState({ calendarDay: null });
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.calendarDay.edit.deleteError" }), { variant: "error" });
        }
    };

    public render() {
        const { isLoading, calendarDay } = this.state;

        if (isLoading) {
            return <Loading breadcrumb={this.getBreadcrumb()} />;
        }

        if (!calendarDay) {
            return <Redirect to={Path.calendarDaysList} />;
        }

        const initialValues: CalendarDayFormValues = {
            date: DateUtils.format(DateUtils.parseISO(calendarDay.date), DateFormat.input),
            is_delivery: !calendarDay.is_delivery,
            is_workday: calendarDay.is_workday,
            is_date_changed: false,
        };

        return (
            <PageLayout breadcrumb={this.getBreadcrumb()}>
                <TitleBar title={calendarDay.date} rightButtonsComponent={<FunctionalButton onClick={this.onDeleteButtonClick} icon={<DeleteForever />} />} />
                <Formik initialValues={initialValues} onSubmit={this.onSubmit} enableReinitialize={true}>
                    {props => (
                        <>
                            <CalendarDayForm formType={FormType.edit} formProps={props} calendarDay={calendarDay} />
                            <Prompt when={props.dirty && !props.values.is_date_changed} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props)} />
                        </>
                    )}
                </Formik>
                <Box mt="40px" />
                <FunctionConfirmModal
                    title={I18n.formatMessage({ id: "pages.calendarDay.edit.deleteModal.title" })}
                    description={I18n.formatMessage({ id: "pages.calendarDay.edit.deleteModal.description" })}
                    isVisible={this.state.isDeleteDialogVisible}
                    onClose={this.onDeleteDialogClose}
                    onFunctionClick={this.onDeleteClick}
                    leftButtonLabel={I18n.formatMessage({ id: "common.cancel" })}
                    rightButtonLabel={I18n.formatMessage({ id: "common.delete" })}
                />
            </PageLayout>
        );
    }
}

export default withSnackbar(withRouter(CalendarDayEditPage));
