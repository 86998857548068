import { gql } from "@apollo/client";

export const productVariantChildFragment = gql`
    fragment ProductVariantChild on Product {
        id
        lfdnr
        ean
        name
        main_categories {
            id
            name
        }
        categories {
            id
            name
        }
        is_visible
        active_from
        slug
    }
`;
