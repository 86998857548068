import React from "react";
import { PageLayout } from "Components/PageLayout";
import ProductCatalogDataGrid from "./PageListDataGrid";
import { TitleBar, PageCard } from "@bigfish/admin-ui/components";
import { I18n } from "I18n/I18n";
import { Button, Icon } from "@bigfish/admin-ui/core";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";

type Props = RouteComponentProps;
class PageListPage extends React.Component<Props> {
    public render() {
        return (
            <PageLayout>
                <TitleBar
                    title={I18n.formatMessage({ id: "pages.pageList.title" })}
                    rightButtonsComponent={
                        <Button
                            startIcon={<Icon className="fa fa-plus-circle" />}
                            variant="contained"
                            size="medium"
                            color="secondary"
                            onClick={() => this.props.history.push(Path.pageCreate)}
                        >
                            {I18n.formatMessage({ id: "pages.pageList.addButton" })}
                        </Button>
                    }
                />
                <PageCard.Container>
                    <ProductCatalogDataGrid />
                </PageCard.Container>
            </PageLayout>
        );
    }
}

export default withRouter(PageListPage);
