import React from "react";
import { CheckboxList, DefaultModal } from "@bigfish/admin-ui/components";
import { Box, Button, createStyles, Typography, WithStyles, withStyles } from "@bigfish/admin-ui/core";
import { Api } from "Api/Api";
import { DocumentModalListItem, PermissionType } from "Api/graphql/admin/types";
import { CheckboxListItem } from "@bigfish/admin-ui/components/CheckboxList";
import DocumentUploader from "Components/Inputs/DocumentUploader";
import { I18n } from "I18n/I18n";
import { connect, DispatchProp } from "react-redux";
import { AuthSelectors } from "Redux/selectors/authSelectors";
import { ApplicationState } from "Redux/rootReducer";

export type Document = { id: string; file_name: string };

const styles = () =>
    createStyles({
        documentsContainer: {
            display: "grid",
            gridTemplateColumns: "1fr 134px",
            gridGap: 30,
            alignItems: "self-start",
        },
    });

type ReduxProps = {
    permissions: PermissionType[];
};

type ComponentProps = {
    isOpened: boolean;
    onClose: () => void;

    documents: Document[];
    onDocumentsChange: (documents: Document[]) => void;
};

type Props = ReduxProps & DispatchProp & ComponentProps & WithStyles<typeof styles>;

type State = {
    documents: DocumentModalListItem[];
    acceptedDocuments: Document[];
};

class DocumentModal extends React.Component<Props, State> {
    public readonly state: State = {
        documents: [],
        acceptedDocuments: [],
    };

    public componentDidMount(): void {
        this.reload();
    }

    public componentDidUpdate(prevProps: Props): void {
        if (prevProps.isOpened && !this.props.isOpened) {
            this.setState({ acceptedDocuments: [] });
        }
        if (prevProps.documents !== this.props.documents || (this.props.isOpened && !prevProps.isOpened)) {
            this.setState({ acceptedDocuments: [...this.props.documents] });
        }
    }

    private reload = async (): Promise<void> => {
        try {
            const documents = await Api.getDocumentsForModal();
            this.setState({ documents });
        } catch (error) {
            this.setState({});
        }
    };

    private onUploadSucceed = (newDocument: DocumentModalListItem) => {
        this.setState({ acceptedDocuments: [...this.state.acceptedDocuments, newDocument] });
        this.reload();
    };

    private onChange = (value: Document, checked: boolean): void => {
        if (checked) {
            this.setState({ acceptedDocuments: [...this.state.acceptedDocuments, value] });
        } else {
            this.setState({ acceptedDocuments: [...this.state.acceptedDocuments, value].filter(document => document.id !== value.id) });
        }
    };

    private onAccept = () => {
        this.props.onDocumentsChange(this.state.acceptedDocuments);
        this.props.onClose();
    };

    render() {
        const isDocChecked = (document: Document): boolean => {
            return this.state.acceptedDocuments.some(d => d.id === document.id);
        };
        const items = this.state.documents.map(
            (document: DocumentModalListItem): CheckboxListItem<Document> => {
                return { label: document.file_name, value: document, checked: isDocChecked(document) };
            }
        );

        return (
            <DefaultModal
                title={I18n.formatMessage({ id: "pages.content.form.documentModal.title" })}
                rightButtonsComponent={
                    <Button variant="contained" color="primary" onClick={this.onAccept}>
                        {I18n.formatMessage({ id: "pages.content.form.documentModal.add" })}
                    </Button>
                }
                leftButtonsComponent={
                    <Button variant="outlined" color="primary" onClick={this.props.onClose}>
                        {I18n.formatMessage({ id: "common.cancel" })}
                    </Button>
                }
                open={this.props.isOpened}
                onClose={this.props.onClose}
            >
                <Typography variant="body2" color="textSecondary">
                    {I18n.formatMessage({ id: "pages.content.form.documentModal.description" })}
                </Typography>
                <Box className={this.props.classes.documentsContainer}>
                    <CheckboxList items={items} onChange={this.onChange} />
                    {[PermissionType.content_read, PermissionType.content_update].every(p => this.props.permissions.includes(p)) && (
                        <DocumentUploader
                            color="primary"
                            variant="outlined"
                            label={I18n.formatMessage({ id: "pages.content.form.documentModal.upload" })}
                            onUploadSucceed={this.onUploadSucceed}
                        />
                    )}
                </Box>
            </DefaultModal>
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { permissions: AuthSelectors.getPermissions(state.auth) };
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(DocumentModal));
