import React, { useCallback } from "react";
import { Form, FormType } from "Components/Form";
import { Button, Grid, Icon, Typography, Box, TextField } from "@bigfish/admin-ui/core";
import { Field, FieldProps, FormikProps } from "formik";
import { I18n } from "Src/i18n/I18n";
import { FullscreenLoader, PageCard, SavePanel } from "@bigfish/admin-ui/components";
import { Path } from "Utils/Path";
import { useHistory } from "react-router-dom";
import RichTextEditor from "Components/Inputs/RichTextEditor/RichTextEditor";
import { I18nHelpers } from "I18n/I18nHelpers";
import { Validator } from "Utils/Validator";

export type CategoryFormValues = {
    id: string;
    lead: string;
    title: string;
    meta_title: string | null;
    meta_description: string | null;
    meta_keywords: string | null;
    url: string;
};

type Props = {
    formType: FormType;
    formProps: FormikProps<CategoryFormValues>;
};

export const CategoryForm = (props: Props) => {
    const history = useHistory();
    const onCancelClick = useCallback(() => {
        history.push(Path.categoryList);
    }, [history]);

    return (
        <Form formProps={props.formProps}>
            <PageCard.Heading title={I18n.formatMessage({ id: "pages.category.form.data" })} />
            {props.formType === FormType.edit && (
                <Field name="id">
                    {({ field }: FieldProps) => (
                        <>
                            <Typography color="textSecondary" gutterBottom>
                                {I18n.formatMessage({ id: "pages.category.form.id.label" })}
                            </Typography>
                            <Typography>{field.value}</Typography>
                        </>
                    )}
                </Field>
            )}
            <Box mt="30px">
                <Field name="title" validate={I18nHelpers.formatValidator(Validator.categoryTitle)}>
                    {({ field, meta }: FieldProps) => {
                        return (
                            <TextField
                                type="text"
                                label={I18n.formatMessage({ id: "pages.category.form.title.label" })}
                                fullWidth
                                variant="outlined"
                                {...field}
                                error={meta.touched && !!meta.error}
                                required
                                helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                            />
                        );
                    }}
                </Field>
            </Box>

            <Box mt="30px">
                <Typography variant="body1" component="h3" color="textSecondary">
                    {I18n.formatMessage({ id: "pages.category.form.lead.label" })}
                </Typography>
            </Box>
            <Box mt="15px">
                <Field name="lead">
                    {({ field }: FieldProps) => (
                        <RichTextEditor
                            name="lead"
                            config="lead"
                            value={field.value}
                            onChange={(lead: string) => {
                                props.formProps.setFieldValue("lead", lead);
                            }}
                        />
                    )}
                </Field>
            </Box>

            <Box mt="30px">
                <Field name="url" validate={I18nHelpers.formatValidator(Validator.required)}>
                    {({ field, meta }: FieldProps) => {
                        if (props.formType === FormType.create) {
                            return (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "pages.category.form.url.label" })}
                                    fullWidth
                                    variant="outlined"
                                    {...field}
                                    error={meta.touched && !!meta.error}
                                    required
                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                                />
                            );
                        }
                        return (
                            <>
                                <Typography color="textSecondary" gutterBottom>
                                    {I18n.formatMessage({ id: "pages.category.form.url.label" })}
                                </Typography>
                                <Typography>{field.value}</Typography>
                            </>
                        );
                    }}
                </Field>
            </Box>

            <Box mt="30px">
                <Field name="meta_title">
                    {({ field, meta }: FieldProps) => {
                        if (props.formType === FormType.create) {
                            return (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "pages.category.form.metaTitle.label" })}
                                    fullWidth
                                    variant="outlined"
                                    {...field}
                                    error={meta.touched && !!meta.error}
                                    required
                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.optional" }))}
                                />
                            );
                        }
                        return (
                            <>
                                <Typography color="textSecondary" gutterBottom>
                                    {I18n.formatMessage({ id: "pages.category.form.metaTitle.label" })}
                                </Typography>
                                <Typography>{field.value}</Typography>
                            </>
                        );
                    }}
                </Field>
            </Box>

            <Box mt="30px">
                <Field name="meta_description">
                    {({ field, meta }: FieldProps) => {
                        if (props.formType === FormType.create) {
                            return (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "pages.category.form.metaDescription.label" })}
                                    fullWidth
                                    variant="outlined"
                                    {...field}
                                    error={meta.touched && !!meta.error}
                                    required
                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.optional" }))}
                                />
                            );
                        }
                        return (
                            <>
                                <Typography color="textSecondary" gutterBottom>
                                    {I18n.formatMessage({ id: "pages.category.form.metaDescription.label" })}
                                </Typography>
                                <Typography>{field.value}</Typography>
                            </>
                        );
                    }}
                </Field>
            </Box>

            <Box my="30px">
                <Field name="meta_keywords">
                    {({ field, meta }: FieldProps) => {
                        if (props.formType === FormType.create) {
                            return (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "pages.category.form.metaKeywords.label" })}
                                    fullWidth
                                    variant="outlined"
                                    {...field}
                                    error={meta.touched && !!meta.error}
                                    required
                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.optional" }))}
                                />
                            );
                        }
                        return (
                            <>
                                <Typography color="textSecondary" gutterBottom>
                                    {I18n.formatMessage({ id: "pages.category.form.metaKeywords.label" })}
                                </Typography>
                                <Typography>{field.value}</Typography>
                            </>
                        );
                    }}
                </Field>
            </Box>
            <SavePanel>
                <Grid container justify="space-between">
                    <Button variant="outlined" color="primary" onClick={onCancelClick}>
                        {I18n.formatMessage({ id: "common.cancel" })}
                    </Button>
                    <Button type="submit" startIcon={<Icon className="fas fa-save" />} variant="contained" color="secondary" disabled={!props.formProps.dirty}>
                        {I18n.formatMessage({ id: "common.save" })}
                    </Button>
                </Grid>
            </SavePanel>
            <FullscreenLoader visible={props.formProps.isSubmitting} />
        </Form>
    );
};
