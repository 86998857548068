import { Box, Typography } from "@bigfish/admin-ui/core";
import { ActiveBreadcrumbItem } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { BadgeForm, BadgeFormValues, badgeValidator } from "./BadgeForm";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { PageLayout } from "Components/PageLayout";
import { Link } from "Components/Link";
import { Breadcrumbs } from "Components/Breadcrumbs";
import Prompt from "Components/Prompt";
import { I18n } from "I18n/I18n";
import { OrderDirection, ProductParamSelectItem, ProductParamSortField } from "Api/graphql/admin/types";

type Props = RouteComponentProps & WithSnackbarProps;

type State = {
    productParams: ProductParamSelectItem[];
    isCreateSucceed: boolean;
};

class BadgeCreatePage extends React.Component<Props, State> {
    public readonly state: State = {
        productParams: [],
        isCreateSucceed: false,
    };

    public async componentDidMount(): Promise<void> {
        try {
            const { data: productParams } = await Api.listProductParamSelectItems({
                first: 9999,
                sortBy: { field: ProductParamSortField.name, direction: OrderDirection.ASC },
            });
            this.setState({ productParams: [...productParams].sort((a, b) => (a.name ?? "").localeCompare(b.name ?? "")) });
        } catch (error) {
            this.props.enqueueSnackbar(error.message, { variant: "error" });
            this.props.history.push(Path.badgeList);
        }
    }

    private onSubmit = async (values: BadgeFormValues, formikHelpers: FormikHelpers<BadgeFormValues>, isRedirectHandled?: boolean): Promise<boolean> => {
        try {
            if (!values.type) return false;
            const badgeId = await Api.createBadge({ ...values, type: values.type });
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.badge.onCreateSubmit.succeed" }), { variant: "success" });
            if (!isRedirectHandled) {
                this.setState({ isCreateSucceed: true }, () => this.props.history.push(Path.badgeEdit(`${badgeId}`)));
            }
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.badge.onCreateSubmit.error" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
            return false;
        }
    };

    public render() {
        if (this.state.isCreateSucceed) {
            return <Redirect to={Path.badgeList} />;
        }

        const initialValues: BadgeFormValues = {
            name: "",
            type: null,
            image: "",
            image_title: "",
            info: "",
            product_param_id: null,
            is_active: false,
            active_from: null,
            active_to: null,
        };

        return (
            <PageLayout
                breadcrumb={
                    <Breadcrumbs>
                        <Link to={Path.badgeList} color="inherit">
                            {I18n.formatMessage({ id: "pages.badge.createBreadcrumb" })}
                        </Link>
                        <ActiveBreadcrumbItem aria-current="page">{I18n.formatMessage({ id: "pages.badge.new" })}</ActiveBreadcrumbItem>
                    </Breadcrumbs>
                }
            >
                <Typography variant="h1">{I18n.formatMessage({ id: "pages.badge.new" })}</Typography>
                <Formik initialValues={initialValues} onSubmit={this.onSubmit} validate={badgeValidator(FormType.create)} validateOnBlur={false}>
                    {props => (
                        <>
                            <BadgeForm formType={FormType.create} formProps={props} productParams={this.state.productParams} />
                            <Prompt when={props.dirty && !this.state.isCreateSucceed} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props, true)} />
                        </>
                    )}
                </Formik>
                <Box mt="40px" />
            </PageLayout>
        );
    }
}

export default withRouter(withSnackbar(BadgeCreatePage));
