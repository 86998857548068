import { gql } from "@apollo/client";

export const websiteNotificationListItemFragment = gql`
    fragment WebsiteNotificationListItem on WebsiteNotification {
        id
        name
        active_from
        active_to
    }
`;
