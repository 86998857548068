import React from "react";
import { TableCell, Tooltip, Icon } from "@bigfish/admin-ui/core";
import { I18n } from "I18n/I18n";
import { NewButton, NormalMenuItem, SerializedMenuItem } from "./MenuForm";
import "./styles.css";

type Props = {
    menuItem: SerializedMenuItem;
    onEditClick: (id: string) => void;
    onDeleteClick: (id: string) => void;
    onRestoreClick: (id: string) => void;
    onNewClick?: () => void;
};

export const MenuItemRow = ({ menuItem, onEditClick, onDeleteClick, onRestoreClick, onNewClick }: Props) => {
    const renderNewButton = () => {
        const newButton: NewButton = menuItem as NewButton;

        return (
            <>
                <TableCell>
                    <div className="menu-item-row-new-button-wrapper" style={{ paddingLeft: `${menuItem.level * 32}px` }}>
                        <Icon className="fas fa-plus-circle draggable-icon menu-item-row-new-button-icon" />
                        <span className="menu-item-row-title" onClick={() => (onNewClick ? onNewClick() : {})}>
                            {newButton.parentId && newButton.parentTitleForNewButton ? (
                                <>
                                    <span>{I18n.formatMessage({ id: "pages.menu.form.menuTable.newRowWithTitle1" })}</span>
                                    <strong>{newButton.parentTitleForNewButton}</strong>
                                    <span>{I18n.formatMessage({ id: "pages.menu.form.menuTable.newRowWithTitle2" })}</span>
                                </>
                            ) : (
                                I18n.formatMessage({ id: "pages.menu.form.menuTable.newRow" })
                            )}
                        </span>
                    </div>
                </TableCell>
                <TableCell />
            </>
        );
    };

    const renderNormalMenuItem = () => {
        const normalMenuItem: NormalMenuItem = menuItem as NormalMenuItem;

        const id = (normalMenuItem as NormalMenuItem).id;

        return (
            <>
                <TableCell>
                    <div style={{ paddingLeft: `${menuItem.level * 32}px`, color: !normalMenuItem.delete ? "#2C3441" : "#A9AFDB" }}>
                        <Icon className="fas fa-ellipsis-v draggable-icon draggable-ellipsis-icon" />
                        <Icon className="fas fa-ellipsis-v draggable-icon draggable-ellipsis-icon" />
                        <span className="menu-item-row-title">{normalMenuItem.title}</span>
                    </div>
                </TableCell>
                <TableCell className="menu-item-row-status-icons">
                    <div className={`${normalMenuItem.delete ? "icon-padding-left" : ""}`}>
                        {!normalMenuItem.delete ? (
                            <>
                                <Tooltip className="icon-wrapper" onClick={() => (id ? onEditClick(id) : {})} title={I18n.formatMessage({ id: "common.edit" })} arrow>
                                    <Icon className="fa fa-pencil-alt draggable-icon" />
                                </Tooltip>
                                <Tooltip className="icon-wrapper" onClick={() => (id ? onDeleteClick(id) : {})} title={I18n.formatMessage({ id: "common.delete" })} arrow>
                                    <Icon className="fas fa-trash draggable-icon" />
                                </Tooltip>
                            </>
                        ) : (
                            <Tooltip
                                className="icon-wrapper"
                                onClick={() => (id ? onRestoreClick(id) : {})}
                                title={I18n.formatMessage({ id: "pages.menu.form.menuTable.undelete" })}
                                arrow
                            >
                                <Icon className="fas fa-sync menu-item-sync-icon" />
                            </Tooltip>
                        )}
                    </div>
                </TableCell>
            </>
        );
    };

    return "isNewButton" in menuItem ? renderNewButton() : renderNormalMenuItem();
};
