import React from "react";
import { Api } from "Api/Api";
import { TypeRowProps } from "@inovua/reactdatagrid-community/types";
import DataGrid from "Components/DataGrid/DataGrid";
import {
    OrderSubscriptionFilters,
    OrderSubscriptionSort,
    SubscriptionListItem,
    OrderSubscriptionSortField,
    listSubscriptions_listOrderSubscriptions_data,
    PermissionType,
} from "Api/graphql/admin/types";
import { ApiError } from "Api/ApiError";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { withStyles, WithStyles } from "@bigfish/admin-ui/core";
import { Path } from "Utils/Path";
import { dataGridStyles } from "@bigfish/admin-ui/styles";
import { Constants } from "Utils/Constants";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import { I18n } from "I18n/I18n";
import { connect } from "react-redux";
import { AuthSelectors } from "Redux/selectors/authSelectors";
import { ApplicationState } from "Redux/rootReducer";
import { DataGridParams } from "Components/DataGrid/UrlQueryParser";
import { DataSource, FilterValue } from "Components/DataGrid/DataGridUtils";
import { DataGridUrlQueryParser, QueryParserType } from "Components/DataGrid/DataGridUrlQueryParser";
import { CustomTypeColumn } from "Utils/DataGridUtils";

type ReduxProps = {
    permissions: PermissionType[];
    isSuperadmin: boolean;
};

type Props = ReduxProps & WithSnackbarProps & WithStyles<typeof dataGridStyles>;

class SubscriptionListDataGrid extends React.Component<Props> {
    private getColumns = (): CustomTypeColumn[] => {
        return [
            { name: "id", header: I18n.formatMessage({ id: "pages.subscriptionList.grid.column.id" }), defaultWidth: 100, filterDelay: Constants.filterDelayMS },
            { name: "user_id", header: I18n.formatMessage({ id: "pages.subscriptionList.grid.column.user_id" }), defaultWidth: 100, filterDelay: Constants.filterDelayMS },
            {
                name: "user_fullname",
                header: I18n.formatMessage({ id: "pages.subscriptionList.grid.column.user_fullname" }),
                defaultFlex: 1,
                minWidth: 236,
                filterDelay: Constants.filterDelayMS,
            },
            {
                name: "user_email",
                header: I18n.formatMessage({ id: "pages.subscriptionList.grid.column.user_email" }),
                defaultFlex: 1,
                minWidth: 336,
                filterDelay: Constants.filterDelayMS,
            },
            {
                name: "payment_provider",
                header: I18n.formatMessage({ id: "pages.subscriptionList.grid.column.payment_provider" }),
                defaultFlex: 1,
                minWidth: 180,
                filterDelay: Constants.filterDelayMS,
            },
            {
                name: "created_at",
                filterEditor: DateFilter,
                header: I18n.formatMessage({ id: "pages.subscriptionList.grid.column.created_at" }),
                defaultWidth: Constants.columnWidth.dateTime,
            },
            {
                name: "updated_at",
                filterEditor: DateFilter,
                header: I18n.formatMessage({ id: "pages.subscriptionList.grid.column.updated_at" }),
                defaultWidth: Constants.columnWidth.dateTime,
            },
            {
                name: "deleted_at",
                filterEditor: DateFilter,
                header: I18n.formatMessage({ id: "pages.subscriptionList.grid.column.deleted_at" }),
                defaultWidth: Constants.columnWidth.dateTime,
            },
            {
                name: "shipping_method_name",
                header: I18n.formatMessage({ id: "pages.subscriptionList.grid.column.shipping_method_name" }),
                defaultFlex: 1,
                minWidth: 236,
                filterDelay: Constants.filterDelayMS,
            },
        ];
    };

    private filterValues = (): FilterValue[] => {
        const filterValues = [
            { name: "id", operator: "eq", type: "number" },
            { name: "user_id", operator: "eq", type: "number" },
            { name: "user_fullname", operator: "contains", type: "string" },
            { name: "user_email", operator: "contains", type: "string" },
            { name: "payment_provider", operator: "contains", type: "string" },
            { name: "created_at", operator: "inrange", type: "date" },
            { name: "updated_at", operator: "inrange", type: "date" },
            { name: "deleted_at", operator: "inrange", type: "date" },
            { name: "shipping_method_name", operator: "contains", type: "string" },
        ];
        return filterValues;
    };

    private urlQueryParser = new DataGridUrlQueryParser({
        filters: {
            id: QueryParserType.integer,
            user_id: QueryParserType.integer,
            user_fullname: QueryParserType.string,
            user_email: QueryParserType.string,
            payment_provider: QueryParserType.string,
            created_at: QueryParserType.dateRange,
            updated_at: QueryParserType.dateRange,
            deleted_at: QueryParserType.dateRange,
            shipping_method_name: QueryParserType.string,
        },
        sortField: OrderSubscriptionSortField,
    });

    private sortFieldToSortOrder = {
        [OrderSubscriptionSortField.id]: "id",
        [OrderSubscriptionSortField.payment_provider]: "payment_provider",
        [OrderSubscriptionSortField.created_at]: "created_at",
        [OrderSubscriptionSortField.updated_at]: "updated_at",
        [OrderSubscriptionSortField.deleted_at]: "deleted_at",
        [OrderSubscriptionSortField.shipping_method_name]: "shipping_method_name",
    };

    private data: listSubscriptions_listOrderSubscriptions_data[] = [];

    private dataSource = async (props: DataGridParams<OrderSubscriptionSort, OrderSubscriptionFilters>): Promise<DataSource<SubscriptionListItem>> => {
        try {
            const result = await Api.listSubscriptions({
                first: props.limit,
                page: props.page,
                filters: props.filters,
                sortBy: props.sortBy,
            });
            this.data = result.data;
            return { data: result.data, count: result.paginatorInfo.total };
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }
        return { data: [], count: 0 };
    };

    render() {
        return (
            <DataGrid
                className={this.props.classes.dataGrid}
                urlQueryParser={this.urlQueryParser}
                rowHeight={50}
                style={{ minHeight: 800 }}
                dataSource={this.dataSource}
                filterValues={this.filterValues()}
                columns={this.getColumns()}
                rowLink={(rowProps: TypeRowProps): string => {
                    return Path.subscriptionEdit(rowProps.data.id);
                }}
                activeCell={null}
                sortFieldToSortOrder={this.sortFieldToSortOrder}
                onRowClick={this.props.isSuperadmin || this.props.permissions.includes(PermissionType.order_update) ? undefined : () => {}}
            />
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { permissions: AuthSelectors.getPermissions(state.auth), isSuperadmin: AuthSelectors.isSuperadmin(state.auth) };
};

export default withSnackbar(connect(mapStateToProps)(withStyles(dataGridStyles, { withTheme: true })(SubscriptionListDataGrid)));
