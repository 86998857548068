import React from "react";
import { Api } from "Api/Api";
import { TypeRowProps } from "@inovua/reactdatagrid-community/types";
import DataGrid from "Components/DataGrid/DataGrid";
import { MaintenanceFilters, MaintenanceListItem, MaintenanceScope, PermissionType } from "Api/graphql/admin/types";
import { ApiError } from "Api/ApiError";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { withStyles, WithStyles } from "@bigfish/admin-ui/core";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import { Path } from "Utils/Path";
import { dataGridStyles } from "@bigfish/admin-ui/styles";
import { Constants } from "Utils/Constants";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import { I18n } from "I18n/I18n";
import { connect } from "react-redux";
import { AuthSelectors } from "Redux/selectors/authSelectors";
import { ApplicationState } from "Redux/rootReducer";
import { DataGridParams, Sortable } from "Components/DataGrid/UrlQueryParser";
import { DataSource, FilterValue } from "Components/DataGrid/DataGridUtils";
import { DataGridUrlQueryParser, QueryParserType } from "Components/DataGrid/DataGridUrlQueryParser";
import { ListItemActive } from "Components/ListItemActive";
import { ObjectUtils } from "Utils/ObjectUtils";
import { CustomTypeColumn } from "Utils/DataGridUtils";

type ReduxProps = {
    permissions: PermissionType[];
    isSuperadmin: boolean;
};

type Props = ReduxProps & WithSnackbarProps & WithStyles<typeof dataGridStyles>;

class MaintenanceListDataGrid extends React.Component<Props> {
    private getColumns = (): CustomTypeColumn[] => {
        return [
            { name: "id", header: I18n.formatMessage({ id: "pages.maintenanceList.grid.column.id" }), defaultWidth: 100, sortable: false, filterDelay: Constants.filterDelayMS },
            {
                name: "active_from",
                header: I18n.formatMessage({ id: "pages.maintenanceList.grid.column.active_from" }),
                defaultWidth: Constants.columnWidth.dateTime,
                render: ({ data }: { data: MaintenanceListItem }) => {
                    return <div>{data.active_from ? DateUtils.format(data.active_from, DateFormat.minuteDateTime) : ""}</div>;
                },
                sortable: false,
            },
            {
                name: "active_to",
                header: I18n.formatMessage({ id: "pages.maintenanceList.grid.column.active_to" }),
                defaultWidth: Constants.columnWidth.dateTime,
                render: ({ data }: { data: MaintenanceListItem }) => {
                    return <div>{data.active_to ? DateUtils.format(data.active_to, DateFormat.minuteDateTime) : ""}</div>;
                },
                sortable: false,
            },
            {
                name: "scopes",
                header: I18n.formatMessage({ id: "pages.maintenanceList.grid.column.scopes" }),
                defaultWidth: 292,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: ObjectUtils.enumAsArray<MaintenanceScope>(MaintenanceScope).map((scope: MaintenanceScope) => {
                        return { id: scope, label: scope };
                    }),
                },
                sortable: false,
                render: ({ value }: { value: MaintenanceScope[] }) => {
                    return value.join(", ");
                },
            },
            {
                name: "notification",
                header: I18n.formatMessage({ id: "pages.maintenanceList.grid.column.notification" }),
                defaultFlex: 1,
                minWidth: 336,
                sortable: false,
                filterDelay: Constants.filterDelayMS,
            },
            {
                name: "maintenance",
                header: I18n.formatMessage({ id: "pages.maintenanceList.grid.column.maintenance" }),
                defaultWidth: Constants.columnWidth.statusIcon,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: [
                        { id: "true", label: I18n.formatMessage({ id: "common.yes" }).toLowerCase() },
                        { id: "false", label: I18n.formatMessage({ id: "common.no" }).toLowerCase() },
                    ],
                },
                render: ({ data }: { data: MaintenanceListItem }) => <ListItemActive isActive={data.maintenance} fromDate={data.active_from} />,
                sortable: false,
            },
            {
                name: "is_active",
                header: I18n.formatMessage({ id: "pages.maintenanceList.grid.column.is_active" }),
                defaultWidth: Constants.columnWidth.statusIcon,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: [
                        { id: "true", label: I18n.formatMessage({ id: "common.yes" }).toLowerCase() },
                        { id: "false", label: I18n.formatMessage({ id: "common.no" }).toLowerCase() },
                    ],
                },
                render: ({ data }: { data: MaintenanceListItem }) => <ListItemActive isActive={data.is_active} fromDate={data.active_from} />,
                sortable: false,
            },
        ];
    };

    private filterValues: FilterValue[] = [
        { name: "id", operator: "eq", type: "number" },
        { name: "is_active", operator: "eq", type: "select" },
        { name: "scopes", operator: "inlist", type: "select" },
        { name: "notification", operator: "contains", type: "string" },
        { name: "maintenance", operator: "eq", type: "select" },
    ];

    private urlQueryParser = new DataGridUrlQueryParser({
        filters: {
            id: QueryParserType.integer,
            is_active: QueryParserType.boolean,
            scopes: QueryParserType.enum,
            notification: QueryParserType.string,
            maintenance: QueryParserType.boolean,
        },
    });

    private dataSource = async (props: DataGridParams<Sortable, MaintenanceFilters>): Promise<DataSource<MaintenanceListItem>> => {
        try {
            const result = await Api.listMaintenances({
                first: props.limit,
                page: props.page,
                filters: {
                    ...props.filters,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    scopes: props.filters?.scopes ? props.filters.scopes.split(",") : undefined,
                },
            });
            return { data: result.data, count: result.paginatorInfo.total };
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }
        return { data: [], count: 0 };
    };

    private getRowLink = (rowProps: TypeRowProps): string => {
        return Path.maintenanceEdit(rowProps.data.id);
    };

    render() {
        return (
            <DataGrid
                className={this.props.classes.dataGrid}
                urlQueryParser={this.urlQueryParser}
                rowHeight={50}
                style={{ minHeight: 800 }}
                dataSource={this.dataSource}
                filterValues={this.filterValues}
                columns={this.getColumns()}
                rowLink={this.getRowLink}
                activeCell={null}
                onRowClick={this.props.isSuperadmin || this.props.permissions.includes(PermissionType.maintenance_update) ? undefined : () => {}}
            />
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { permissions: AuthSelectors.getPermissions(state.auth), isSuperadmin: AuthSelectors.isSuperadmin(state.auth) };
};

export default withSnackbar(connect(mapStateToProps)(withStyles(dataGridStyles, { withTheme: true })(MaintenanceListDataGrid)));
