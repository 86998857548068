import { ActiveBreadcrumbItem, TitleBar } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { ProductCategoryForm, ProductCategoryFormValues, productCategoryValidator } from "./ProductCategoryForm";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { Loading } from "Components/Loading";
import { PageLayout } from "Components/PageLayout";
import { Link } from "Components/Link";
import { Breadcrumbs } from "Components/Breadcrumbs";
import { DispatchProp } from "react-redux";
import Prompt from "Components/Prompt";
import { ProductCategory } from "Api/graphql/admin/types";
import { Box } from "@bigfish/admin-ui/core";
import { I18n } from "I18n/I18n";
import { DateUtils, DateFormat } from "Utils/DateUtils";
import { ApiUtils } from "Utils/ApiUtils";

type RouteParams = {
    id?: string;
};

type Props = DispatchProp & WithSnackbarProps & RouteComponentProps<RouteParams>;

type State = {
    isLoading: boolean;
    productCategory: ProductCategory | null;
};

class ProductCategoryEditPage extends React.Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        productCategory: null,
    };

    public async componentDidMount(): Promise<void> {
        if (!this.props.match.params.id) {
            return;
        }

        try {
            const productCategory = await Api.getProductCategoryById(Number.parseInt(this.props.match.params.id, 10));
            this.setState({ productCategory, isLoading: false });
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
            this.props.history.push(Path.productCategoryList);
        }
    }

    private isParent = !this.state.productCategory?.parent?.id;

    private onSubmit = async (values: ProductCategoryFormValues, formikHelpers: FormikHelpers<ProductCategoryFormValues>): Promise<boolean> => {
        try {
            const productCategory = await Api.updateProductCategory(this.state.productCategory!.id, {
                slug: values.slug,
                name: values.name,
                description: values.description,
                icon: values.icon,
                icon_title: values.icon_title,
                meta_description: values.meta_description,
                og_image: values.og_image,
                og_title: values.og_title,
                og_description: values.og_description,
                banners: values.banners.map(banner => {
                    return {
                        is_active: !!banner.is_active,
                        active_from: banner.active_from ? DateUtils.format(banner.active_from, DateFormat.apiDateTime) : null,
                        active_to: banner.active_to ? DateUtils.format(banner.active_to, DateFormat.apiDateTime) : null,
                        image: banner.image,
                        image_title: banner.image_title,
                        link: banner.link,
                        link_new_tab: banner.link_new_tab,
                    };
                }),
                is_featured: values.is_featured,
                include_in_menu: values.include_in_menu,
                feed_facebook: values.feed_facebook,
                feed_google: values.feed_google,
                feed_google_merchant: values.feed_google_merchant,
                feed_dsa: values.feed_dsa,
                is_active: values.is_active,
                product_sort_by: this.isParent ? (values.product_sort_by ? ApiUtils.getProductSortInputFromFriendly(values.product_sort_by) : null) : undefined,
            });
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.productCategory.onSubmit.success" }), { variant: "success" });

            if (!productCategory) {
                throw new Error(I18n.formatMessage({ id: "pages.productCategory.onSubmit.error" }));
            }

            this.setState({ productCategory });
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.productCategory.onSubmit.error" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
        }
        return false;
    };

    private getBreadcrumb = () => {
        return (
            <Breadcrumbs>
                <Link to={Path.productCategoryList} color="inherit">
                    {I18n.formatMessage({ id: "pages.productCategory.breadcrumb.title" })}
                </Link>
                <ActiveBreadcrumbItem aria-current="page">{this.state.productCategory?.name || ""}</ActiveBreadcrumbItem>
            </Breadcrumbs>
        );
    };

    public render() {
        const { isLoading, productCategory } = this.state;

        if (isLoading) {
            return <Loading breadcrumb={this.getBreadcrumb()} />;
        }

        if (!productCategory) {
            return <Redirect to={Path.productCategoryList} />;
        }

        const initialValues: ProductCategoryFormValues = {
            slug: productCategory.slug,
            name: productCategory.name,
            description: productCategory.description,
            icon: productCategory.icon,
            icon_title: productCategory.icon_title,
            og_image: productCategory.og_image,
            og_title: productCategory.og_title,
            og_description: productCategory.og_description,
            meta_description: productCategory.meta_description,
            banners: productCategory.banners.map(banner => {
                return {
                    is_active: !!banner.is_active,
                    active_from: banner.active_from ? DateUtils.format(DateUtils.parseISO(banner.active_from), DateFormat.input) : "",
                    active_to: banner.active_to ? DateUtils.format(DateUtils.parseISO(banner.active_to), DateFormat.input) : "",
                    image: banner.image,
                    image_title: banner.image_title,
                    link: banner.link,
                    link_new_tab: banner.link_new_tab,
                };
            }),
            is_featured: productCategory.is_featured,
            include_in_menu: productCategory.include_in_menu,
            feed_facebook: productCategory.feed_facebook,
            feed_google: productCategory.feed_google,
            feed_google_merchant: productCategory.feed_google_merchant,
            feed_dsa: productCategory.feed_dsa,
            is_active: productCategory.is_active,
            product_sort_by: this.isParent ? ApiUtils.getProductSortByFromBackend(productCategory.product_sort_by) : undefined,
        };

        return (
            <PageLayout breadcrumb={this.getBreadcrumb()}>
                <TitleBar title={productCategory.name} />
                <Formik initialValues={initialValues} onSubmit={this.onSubmit} enableReinitialize={true} validate={productCategoryValidator}>
                    {props => (
                        <>
                            <ProductCategoryForm formType={FormType.edit} formProps={props} productCategory={productCategory} />
                            <Prompt when={props.dirty} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props)} />
                        </>
                    )}
                </Formik>
                <Box mt="40px" />
            </PageLayout>
        );
    }
}

export default withSnackbar(withRouter(ProductCategoryEditPage));
