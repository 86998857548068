import { gql } from "@apollo/client";

export const campaignListItemFragment = gql`
    fragment CampaignListItem on Campaign {
        id
        name
        active_from
        active_to
        is_active
    }
`;
