import { gql } from "@apollo/client";

export const userFragment = gql`
    fragment User on User {
        id
        lastname
        firstname
        email
        phone_number
        rossmann_plus_profile {
            card_number
            is_virtual_card
        }
        created_at
        banktransfer_enabled
        cod_disabled
        newsletter
        is_active
        country_code
        area_code
        phone_number
        order_count
        order_total_sum
        last_login
    }
`;
