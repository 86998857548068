import { gql } from "@apollo/client";
import { ratingListItemFragment } from "../fragments/ratingListItemFragment";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";

export const listRatingsQuery = gql`
    query listRatings($filters: OrderRatingFilters, $sortBy: OrderRatingSort, $first: Int!, $page: Int) {
        listOrderRatings(first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            aggregations {
                avg_rating_purchase
                avg_rating_courier
                avg_rating_recommend
            }
            data {
                ...RatingListItem
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${ratingListItemFragment}
    ${paginatorInfoFragment}
`;
