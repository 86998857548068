import React from "react";

import { withSnackbar, WithSnackbarProps } from "notistack";

import { Typography, withStyles, WithStyles } from "@bigfish/admin-ui/core";
import { dataGridStyles } from "@bigfish/admin-ui/styles";
import { TypeColumn, TypeRowProps } from "@inovua/reactdatagrid-community/types";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";

import { CalendarDay, CalendarDayFilters, CalendarDaySort, CalendarDaySortField } from "Api/graphql/admin/types";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import DataGrid from "Components/DataGrid/DataGrid";
import { DataGridUrlQueryParser, QueryParserType } from "Components/DataGrid/DataGridUrlQueryParser";
import { DataSource, FilterValue } from "Components/DataGrid/DataGridUtils";
import { DataGridParams } from "Components/DataGrid/UrlQueryParser";
import { IsActiveIcon } from "Components/IsActive";
import { I18n } from "I18n/I18n";
import { Constants } from "Utils/Constants";
import { Path } from "Utils/Path";

type Props = WithSnackbarProps & WithStyles<typeof dataGridStyles>;

class CalendarDaysListDataGrid extends React.Component<Props> {
    private getColumns = (): TypeColumn[] => {
        return [
            {
                name: "date",
                filterEditor: DateFilter,
                header: I18n.formatMessage({ id: "pages.calendarDaysList.grid.column.date" }),
                defaultWidth: Constants.columnWidth.dateTime,
            },
            {
                name: "is_workday",
                header: I18n.formatMessage({ id: "pages.calendarDaysList.grid.column.type" }),
                defaultFlex: 1,
                minWidth: 300,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: [
                        { id: "true", label: I18n.formatMessage({ id: "pages.calendarDay.form.workday" }) },
                        { id: "false", label: I18n.formatMessage({ id: "pages.calendarDay.form.holiday" }) },
                    ],
                },
                render: ({ data }: { data: CalendarDay }) => (
                    <Typography color="textPrimary" variant="inherit">
                        {I18n.formatMessage({ id: `pages.calendarDay.form.${data.is_workday ? "workday" : "holiday"}` })}
                    </Typography>
                ),
            },
            {
                name: "is_delivery",
                textAlign: "center",
                header: I18n.formatMessage({ id: "pages.calendarDaysList.grid.column.isDelivery" }),
                defaultWidth: 200,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: [
                        { id: "false", label: I18n.formatMessage({ id: "common.yes" }) },
                        { id: "true", label: I18n.formatMessage({ id: "common.no" }) },
                    ],
                },
                render: ({ data }: { data: CalendarDay }) => <IsActiveIcon value={!data.is_delivery} />,
            },
        ];
    };

    private dataSource = async (props: DataGridParams<CalendarDaySort, CalendarDayFilters>): Promise<DataSource<CalendarDay>> => {
        try {
            const result = await Api.listCalendarDays({
                first: props.limit,
                page: props.page,
                filters: props.filters,
                sortBy: props.sortBy,
            });
            return { data: result.data, count: result.paginatorInfo.total };
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }
        return { data: [], count: 0 };
    };

    private urlQueryParser = new DataGridUrlQueryParser({
        filters: {
            date: QueryParserType.dateRange,
            is_workday: QueryParserType.boolean,
            is_delivery: QueryParserType.boolean,
        },
        sortField: CalendarDaySortField,
    });

    private sortFieldToSortOrder = {
        [CalendarDaySortField.date]: "date",
        [CalendarDaySortField.is_workday]: "is_workday",
        [CalendarDaySortField.is_delivery]: "is_delivery",
    };

    private filterValues: FilterValue[] = [
        { name: "date", operator: "inrange", type: "date" },
        { name: "is_workday", operator: "eq", type: "select" },
        { name: "is_delivery", operator: "eq", type: "select" },
    ];

    private getRowLink = (rowProps: TypeRowProps): string => {
        return Path.calendarDayEdit(rowProps.data.date);
    };

    render() {
        return (
            <DataGrid
                className={this.props.classes.dataGrid}
                urlQueryParser={this.urlQueryParser}
                rowHeight={50}
                style={{ minHeight: 800 }}
                dataSource={this.dataSource}
                filterValues={this.filterValues}
                columns={this.getColumns()}
                activeCell={null}
                rowLink={this.getRowLink}
                sortFieldToSortOrder={this.sortFieldToSortOrder}
            />
        );
    }
}

export default withSnackbar(withStyles(dataGridStyles, { withTheme: true })(CalendarDaysListDataGrid));
