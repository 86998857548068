import { gql } from "@apollo/client";

export const productParamValueFragment = gql`
    fragment ProductParamValue on ProductParamValue {
        product_param_id
        is_multi
        # is_brandbank # TODO: https://jira.bigfish.hu/browse/ROS-458
        values
    }
`;
