import { ProductFilterInput, ProductFilterType } from "Api/graphql/admin/types";
import { DateFormat, DateUtils } from "./DateUtils";

export const ProductListUtils = {
    /**
     * A filter should only have a value that corresponds with its type, e. g. if type is "product_category" we only need "product_category_id"
     */
    cleanFilter(filter: ProductFilterInput) {
        switch (filter.type) {
            case ProductFilterType.created:
                return {
                    type: filter.type,
                    operator: filter.operator,
                    created_at: filter.created_at ? DateUtils.format(DateUtils.parseISO(filter.created_at), DateFormat.apiDateTime) : undefined,
                };
            case ProductFilterType.rating:
                return { type: filter.type, operator: filter.operator, rating: filter.rating };
            case ProductFilterType.product_param:
                return { type: filter.type, operator: filter.operator, product_param_id: filter.product_param_id };
            case ProductFilterType.promotion:
                return { type: filter.type, operator: filter.operator, clpe_promotion_id: filter.clpe_promotion_id };
            case ProductFilterType.product_category:
                return { type: filter.type, operator: filter.operator, product_category_id: filter.product_category_id };
            case ProductFilterType.brand:
                return { type: filter.type, operator: filter.operator, product_brand_id: filter.product_brand_id };
            case ProductFilterType.price_type:
                return { type: filter.type, operator: filter.operator, price_type: filter.price_type };
            case ProductFilterType.clpe_promotion_type:
                return { type: filter.type, operator: filter.operator, clpe_promotion_type: filter.clpe_promotion_type };
            case ProductFilterType.new:
                return { type: filter.type };
            case ProductFilterType.product_type:
                return { type: filter.type, operator: filter.operator, product_type: filter.product_type };
            default:
                return { ...filter };
        }
    },
};
