import React from "react";
import { I18n } from "I18n/I18n";
import UnControlledDataGrid from "Components/DataGrid/UCDataGrid";
import { CustomTypeColumn } from "Utils/DataGridUtils";
import { DataSource } from "Components/DataGrid/DataGridUtils";
import { SubscriptionOrder } from "Api/graphql/admin/types";
import { TypeRowProps } from "@inovua/reactdatagrid-community/types";
import { Path } from "Utils/Path";

type Props = {
    orders: SubscriptionOrder[];
};

export const SubscriptionOrders = (props: Props) => {
    const getColumns = (): CustomTypeColumn[] => {
        return [
            { name: "increment_id", header: I18n.formatMessage({ id: "pages.subscription.form.orders.column.increment_id" }), defaultWidth: 100 },
            {
                name: "status",
                header: I18n.formatMessage({ id: "pages.subscription.form.orders.column.status" }),
                defaultFlex: 1,
                minWidth: 200,
                render: ({ data }: { data: SubscriptionOrder }) => {
                    return <div>{data.status.title}</div>;
                },
            },
            { name: "delivey_day_expected", header: I18n.formatMessage({ id: "pages.subscription.form.orders.column.delivey_day_expected" }), defaultWidth: 225 },
            { name: "delivey_time_window", header: I18n.formatMessage({ id: "pages.subscription.form.orders.column.delivey_time_window" }), defaultWidth: 125 },
            { name: "created_at", header: I18n.formatMessage({ id: "pages.subscription.form.orders.column.created_at" }), defaultWidth: 225 },
        ];
    };

    return (
        <UnControlledDataGrid<SubscriptionOrder>
            rowHeight={50}
            style={{ minHeight: 42 + props.orders.length * 50 }}
            dataSource={async (): Promise<DataSource<SubscriptionOrder>> => {
                return { data: props.orders, count: props.orders.length };
            }}
            columns={getColumns()}
            activeCell={null}
            pagination={false}
            rowLink={(rowProps: TypeRowProps): string => {
                return Path.orderEdit(rowProps.data.increment_id);
            }}
        />
    );
};
