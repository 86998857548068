import { gql } from "@apollo/client";
import { calendarDayFragment } from "../fragments/calendarDayFragment";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";

export const listCalendarDaysQuery = gql`
    query listCalendarDays($filters: CalendarDayFilters, $sortBy: CalendarDaySort, $first: Int!, $page: Int) {
        listCalendarDays(first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            data {
                ...CalendarDay
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${calendarDayFragment}
    ${paginatorInfoFragment}
`;
