import { Box, Typography } from "@bigfish/admin-ui/core";
import { ActiveBreadcrumbItem } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { MenuForm, MenuFormValues, MenuItemInputWithDelete, menuValidator } from "./MenuForm";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { PageLayout } from "Components/PageLayout";
import { Link } from "Components/Link";
import { Breadcrumbs } from "Components/Breadcrumbs";
import Prompt from "Components/Prompt";
import { I18n } from "I18n/I18n";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import { MenuItemInput, MenuItemTypeEnum } from "Api/graphql/admin/types";

type Props = RouteComponentProps & WithSnackbarProps;

type State = {
    isCreateSucceed: boolean;
};

class MenuCreatePage extends React.Component<Props, State> {
    public readonly state: State = {
        isCreateSucceed: false,
    };

    private onSubmit = async (values: MenuFormValues, formikHelpers: FormikHelpers<MenuFormValues>, isRedirectHandled?: boolean): Promise<boolean> => {
        const getItems = (items: MenuItemInputWithDelete[]): MenuItemInput[] => {
            return items
                .filter(i => !i.delete)
                .map(i => {
                    return {
                        type: i.type,
                        model_id: i.model_id,
                        title: i.title,
                        url: i.type === MenuItemTypeEnum.url ? i.url : null,
                        popup: i.popup,
                        is_active: i.is_active,
                        page_id_overlay: i.page_id_overlay,
                        items: i.items ? getItems(i.items) : undefined,
                    };
                });
        };

        try {
            const menuId = await Api.createMenu({
                ...values,
                items: getItems(values.items),
                active_from: values.active_from ? DateUtils.format(values.active_from, DateFormat.apiDateTime) : null,
                active_to: values.active_to ? DateUtils.format(values.active_to, DateFormat.apiDateTime) : null,
            });
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.menu.onCreateSubmit.succeed" }), { variant: "success" });
            if (!isRedirectHandled) {
                this.setState({ isCreateSucceed: true }, () => this.props.history.push(Path.menuEdit(`${menuId}`)));
            }
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.menu.onCreateSubmit.error" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
            return false;
        }
    };

    public render() {
        if (this.state.isCreateSucceed) {
            return <Redirect to={Path.menuList} />;
        }

        const initialValues: MenuFormValues = {
            slug: "",
            name: "",
            items: [],
            is_active: true,
            active_from: null,
            active_to: null,
        };

        return (
            <PageLayout
                breadcrumb={
                    <Breadcrumbs>
                        <Link to={Path.menuList} color="inherit">
                            {I18n.formatMessage({ id: "pages.menu.createBreadcrumb" })}
                        </Link>
                        <ActiveBreadcrumbItem aria-current="page">{I18n.formatMessage({ id: "pages.menu.new" })}</ActiveBreadcrumbItem>
                    </Breadcrumbs>
                }
            >
                <Typography variant="h1">{I18n.formatMessage({ id: "pages.menu.new" })}</Typography>
                <Formik initialValues={initialValues} onSubmit={this.onSubmit} validate={menuValidator()} validateOnBlur={false}>
                    {props => (
                        <>
                            <MenuForm formType={FormType.create} formProps={props} />
                            <Prompt when={props.dirty && !this.state.isCreateSucceed} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props, true)} />
                        </>
                    )}
                </Formik>
                <Box mt="40px" />
            </PageLayout>
        );
    }
}

export default withRouter(withSnackbar(MenuCreatePage));
