import { gql } from "@apollo/client";
import { paymentStatusFragment } from "../fragments/paymentStatusFragment";

export const listPaymentStatusSelectItemsQuery = gql`
    query listPaymentStatusSelectItems {
        paymentStatuses {
            ...PaymentStatus
        }
    }
    ${paymentStatusFragment}
`;
