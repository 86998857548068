import { DefaultModal } from "@bigfish/admin-ui/components";
import { Button } from "@bigfish/admin-ui/core";
import { Api } from "Api/Api";
import { DocumentListItem } from "Api/graphql/admin/types";
import { I18n } from "I18n/I18n";
import { withSnackbar, WithSnackbarProps } from "notistack";
import React from "react";

type ComponentProps = {
    document: DocumentListItem | null;
    onClose: (reload?: boolean) => void;
};

type Props = ComponentProps & WithSnackbarProps;

type State = {
    isLoading: boolean;
    shouldForce: boolean;
};

class DeleteDocumentModal extends React.Component<Props, State> {
    public readonly state: State = { isLoading: false, shouldForce: false };

    private onCloseClick = (): void => {
        this.setState({ shouldForce: false });
        this.props.onClose(false);
    };

    private onDeleteClick = () => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const result = await Api.removeDocument(this.props.document!.id, this.state.shouldForce);
                    if (result) {
                        this.props.enqueueSnackbar(I18n.formatMessage({ id: "components.deleteDocumentModal.deleteSucceed" }), { variant: "success" });
                        this.setState({ isLoading: false, shouldForce: false });
                        this.props.onClose(true);
                    } else {
                        this.setState({ isLoading: false, shouldForce: true });
                    }
                } catch (error) {
                    this.props.enqueueSnackbar(error.message, { variant: "error" });
                }
            }
        );
    };

    public render() {
        return (
            <DefaultModal
                title={I18n.formatMessage({ id: "components.deleteDocumentModal.title" })}
                open={!!this.props.document}
                leftButtonsComponent={
                    <Button variant="outlined" color="primary" onClick={this.onCloseClick} disabled={this.state.isLoading}>
                        {I18n.formatMessage({ id: "common.cancel" })}
                    </Button>
                }
                rightButtonsComponent={
                    <Button
                        variant={this.state.shouldForce ? "outlined" : "contained"}
                        color="error"
                        onClick={this.onDeleteClick}
                        disabled={this.state.isLoading}
                        style={{ marginRight: 20 }}
                    >
                        {I18n.formatMessage({ id: "components.deleteDocumentModal.delete" })}
                    </Button>
                }
                onClose={this.onCloseClick}
            >
                <div
                    dangerouslySetInnerHTML={{
                        __html: I18n.formatMessage(
                            {
                                id: this.state.shouldForce ? "components.deleteDocumentModal.descriptionForce" : "components.deleteDocumentModal.description",
                            },
                            { file_name: this.props.document?.file_name }
                        ),
                    }}
                />
            </DefaultModal>
        );
    }
}

export default withSnackbar(DeleteDocumentModal);
