import { Box } from "@bigfish/admin-ui/core";
import { ActiveBreadcrumbItem } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { PageForm, PageFormValues, pageValidator } from "./PageForm";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { PageLayout } from "Components/PageLayout";
import { Link } from "Components/Link";
import { Breadcrumbs } from "Components/Breadcrumbs";
import Prompt from "Components/Prompt";
import { I18n } from "I18n/I18n";
import { ApiUtils } from "Utils/ApiUtils";
import { Site } from "Api/graphql/admin/types";

type Props = RouteComponentProps & WithSnackbarProps;

type State = {
    isCreateSucceed: boolean;
    selectSites: Site[];
};

class PageCreatePage extends React.Component<Props, State> {
    public readonly state: State = {
        isCreateSucceed: false,
        selectSites: [],
    };

    public async componentDidMount(): Promise<void> {
        try {
            const selectSites = await Api.listSites();
            this.setState({ selectSites });
        } catch (error) {
            this.props.enqueueSnackbar(error.message, { variant: "error" });
            this.props.history.push(Path.pageList);
        }
    }

    private onSubmit = async (values: PageFormValues, formikHelpers: FormikHelpers<PageFormValues>, isRedirectHandled?: boolean): Promise<boolean> => {
        try {
            const pageId = await Api.createPage(ApiUtils.getPageInput(values, FormType.create));
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.page.onCreateSubmit.succeed" }), { variant: "success" });
            if (!isRedirectHandled) {
                this.setState({ isCreateSucceed: true }, () => this.props.history.push(Path.pageEdit(`${pageId}`)));
            }
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.page.onCreateSubmit.error" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
            return false;
        }
    };

    public render() {
        if (this.state.isCreateSucceed) {
            return <Redirect to={Path.pageList} />;
        }

        const initialValues: PageFormValues = {
            name: "",
            title: "",
            url: "",
            is_active: false,
            active_from: null,
            active_to: null,
            meta_title: "",
            meta_description: "",
            og_title: "",
            og_description: "",
            og_image: "",
            blocks: [],
            is_header: true,
            is_footer: true,
            site_id: null,
        };

        return (
            <PageLayout
                breadcrumb={
                    <Breadcrumbs>
                        <Link to={Path.pageList} color="inherit">
                            {I18n.formatMessage({ id: "pages.page.createBreadcrumb" })}
                        </Link>
                        <ActiveBreadcrumbItem aria-current="page">{I18n.formatMessage({ id: "pages.page.new" })}</ActiveBreadcrumbItem>
                    </Breadcrumbs>
                }
            >
                <Formik initialValues={initialValues} onSubmit={this.onSubmit} validate={pageValidator(FormType.create)} validateOnBlur={false} enableReinitialize={true}>
                    {props => (
                        <>
                            <PageForm formType={FormType.create} formProps={props} selectSites={this.state.selectSites} />
                            <Prompt when={props.dirty && !this.state.isCreateSucceed} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props, true)} />
                        </>
                    )}
                </Formik>
                <Box mt="40px" />
            </PageLayout>
        );
    }
}

export default withRouter(withSnackbar(PageCreatePage));
