import { gql } from "@apollo/client";
import { productCategoryForMenuFragment } from "../fragments/productCategoryForMenuFragment";

export const listProductCategoriesForMenuQuery = gql`
    query listProductCategoriesForMenu {
        productCategoryTree {
            ...ProductCategoryForMenu
        }
    }
    ${productCategoryForMenuFragment}
`;
