import { ActiveBreadcrumbItem } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { BankTransferStatus, Order, OrderItem, OrderItemInput, OrderItemType, PaymentStatusEnum, ShipmentStatus, PermissionType } from "Api/graphql/admin/types";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { OrderForm, OrderFormValues } from "./OrderForm";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { Loading } from "Components/Loading";
import { PageLayout } from "Components/PageLayout";
import { Link } from "Components/Link";
import { Breadcrumbs } from "Components/Breadcrumbs";
import Prompt from "Components/Prompt";
import { Box } from "@bigfish/admin-ui/core";
import { I18n } from "I18n/I18n";
import { ApplicationState } from "Redux/rootReducer";
import { connect } from "react-redux";
import { AuthSelectors } from "Redux/selectors/authSelectors";
import { DeleteOrderConfirmModal } from "./DeleteOrderConfirmModal";
import { CloseOrderModal } from "Components/CloseOrderModal";
import { CorrectOrderItemsDialog, CorrectionItem } from "Components/CorrectOrderItemsDialog/CorrectOrderItemsDialog";
import { ApiUtils } from "Utils/ApiUtils";
import { FunctionConfirmModal } from "Components/FunctionConfirmModal";

type RouteParams = {
    incrementId?: string;
};

type ReduxProps = {
    permissions: PermissionType[];
    isSuperadmin: boolean;
};

type Props = WithSnackbarProps & RouteComponentProps<RouteParams> & ReduxProps;

type State = {
    isLoading: boolean;
    isCorrectionLoading: boolean;
    order: Order | null;
    isCloseOrderDialogVisible: boolean;
    isDeleteDialogVisible: boolean;
    isCorrectionDialogVisible: boolean;
    isStornoDialogVisible: boolean;
    isRefundDialogVisible: boolean;
    isRetryDialogVisible: boolean;
    shipmentStatuses: ShipmentStatus[];
    refreshIndex: number;
};

class OrderPage extends React.Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        isCorrectionLoading: false,
        order: null,
        isCloseOrderDialogVisible: false,
        isDeleteDialogVisible: false,
        isCorrectionDialogVisible: false,
        isStornoDialogVisible: false,
        isRefundDialogVisible: false,
        isRetryDialogVisible: false,
        shipmentStatuses: [],
        refreshIndex: 0,
    };

    public async componentDidMount(): Promise<void> {
        this.fetchOrderAndShipmentStatuses(Number.parseInt(this.props.match.params.incrementId!, 10))();
    }

    private fetchOrderAndShipmentStatuses = (id: number) => () => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const order = await Api.getOrderById(id);
                    const shipmentStatuses = await Api.listShipmentStatuses();
                    this.setState({ order, shipmentStatuses, isLoading: false });
                } catch (error) {
                    this.setState({ isLoading: false });
                    if (error instanceof ApiError) {
                        this.props.enqueueSnackbar(error.message, { variant: "error" });
                    }
                    this.props.history.push(Path.orderList);
                }
            }
        );
    };

    private onSubmit = async (values: OrderFormValues, formikHelpers: FormikHelpers<OrderFormValues>): Promise<boolean> => {
        if (!this.canEdit() || !this.state.order!.increment_id) return false;

        try {
            const order = await Api.updateOrder(this.state.order!.increment_id, {
                bank_transfer_status: values.bank_transfer_status,
                bank_transfer_paid: values.bank_transfer_paid,
                admin_note: values.admin_note,
            });
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.order.edit.onSubmit.success" }), { variant: "success" });
            this.setState({ order, refreshIndex: this.state.refreshIndex + 1 });
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.order.edit.onSubmit.error" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
        }
        return false;
    };

    private onCloseOrderClick = async (delivered: boolean): Promise<void> => {
        try {
            await Api.closeOrder(Number.parseInt(this.props.match.params.incrementId!, 10), delivered);
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.order.closeOrder.success" }), { variant: "success" });
            this.setState({ isCloseOrderDialogVisible: false }, this.fetchOrderAndShipmentStatuses(Number.parseInt(this.props.match.params.incrementId!, 10)));
        } catch (error) {
            if (error instanceof ApiError) {
                const message = error.hasFieldError() ? error.fieldErrors[0].message : error.message;
                this.props.enqueueSnackbar(message || I18n.formatMessage({ id: "pages.order.closeOrder.error" }), { variant: "error" });
            } else {
                this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.order.closeOrder.error" }), { variant: "error" });
            }
        }
    };

    private onDeleteClick = async (): Promise<void> => {
        if (!this.state.order?.can_be_deleted) return;

        try {
            await Api.deleteOrder(Number.parseInt(this.props.match.params.incrementId!, 10));
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.order.delete.success" }), { variant: "success" });
            this.setState({ isDeleteDialogVisible: false }, this.fetchOrderAndShipmentStatuses(Number.parseInt(this.props.match.params.incrementId!, 10)));
        } catch (error) {
            if (error instanceof ApiError) {
                const message = error.hasFieldError() ? error.fieldErrors[0].message : error.message;
                this.props.enqueueSnackbar(message || I18n.formatMessage({ id: "pages.order.delete.error" }), { variant: "error" });
            } else {
                this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.order.delete.error" }), { variant: "error" });
            }
        }
    };

    private onCorrectItems = async (items: CorrectionItem[]): Promise<void> => {
        if (!this.state.order) return;

        this.setState({ isCorrectionLoading: true }, async () => {
            try {
                const order = await Api.correctInvoice(Number.parseInt(this.props.match.params.incrementId!, 10), ApiUtils.getOrderItemInputs(this.state.order!.items, items));
                this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.order.correct.success" }), { variant: "success" });
                this.setState({ isCorrectionDialogVisible: false, order });
            } catch (error) {
                if (error instanceof ApiError) {
                    const message = error.hasFieldError() ? error.fieldErrors[0].message : error.message;
                    this.props.enqueueSnackbar(message || I18n.formatMessage({ id: "pages.order.correct.error" }), { variant: "error" });
                } else {
                    this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.order.correct.error" }), { variant: "error" });
                }
            } finally {
                this.setState({ isCorrectionLoading: false });
            }
        });
    };

    private onStornoClick = async (): Promise<void> => {
        if (!this.state.order?.can_storno) return;

        try {
            await Api.stornoInvoice(Number.parseInt(this.props.match.params.incrementId!, 10));
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.order.storno.success" }), { variant: "success" });
            this.setState({ isStornoDialogVisible: false }, this.fetchOrderAndShipmentStatuses(Number.parseInt(this.props.match.params.incrementId!, 10)));
        } catch (error) {
            if (error instanceof ApiError) {
                const message = error.hasFieldError() ? error.fieldErrors[0].message : error.message;
                this.props.enqueueSnackbar(message || I18n.formatMessage({ id: "pages.order.storno.error" }), { variant: "error" });
            } else {
                this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.order.storno.error" }), { variant: "error" });
            }
        }
    };

    private onRefundClick = async (): Promise<void> => {
        try {
            await Api.refundPayment(Number.parseInt(this.props.match.params.incrementId!, 10));
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.order.refund.success" }), { variant: "success" });
            this.setState({ isRefundDialogVisible: false }, this.fetchOrderAndShipmentStatuses(Number.parseInt(this.props.match.params.incrementId!, 10)));
        } catch (error) {
            if (error instanceof ApiError) {
                const message = error.hasFieldError() ? error.fieldErrors[0].message : error.message;
                this.props.enqueueSnackbar(message || I18n.formatMessage({ id: "pages.order.refund.error" }), { variant: "error" });
            } else {
                this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.order.refund.error" }), { variant: "error" });
            }
        }
    };

    private onRetryClick = async (): Promise<void> => {
        try {
            const order = await Api.resendShipment(Number.parseInt(this.props.match.params.incrementId!, 10));
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.order.retry.success" }), { variant: "success" });
            this.setState({ isRetryDialogVisible: false, order });
        } catch (error) {
            if (error instanceof ApiError) {
                const message = error.hasFieldError() ? error.fieldErrors[0].message : error.message;
                this.props.enqueueSnackbar(message || I18n.formatMessage({ id: "pages.order.retry.error" }), { variant: "error" });
            } else {
                this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.order.retry.error" }), { variant: "error" });
            }
        }
    };

    private getBreadcrumb = () => {
        return (
            <Breadcrumbs>
                <Link to={Path.orderList} color="inherit">
                    {I18n.formatMessage({ id: "pages.order.edit.breadcrumb" })}
                </Link>
                {this.state.order?.increment_id && <ActiveBreadcrumbItem aria-current="page">{this.state.order.increment_id}</ActiveBreadcrumbItem>}
            </Breadcrumbs>
        );
    };

    private onModifyOrderSubmit = async (orderItems: OrderItem[]): Promise<boolean> => {
        const shippingItem = orderItems.find(oi => oi.type === OrderItemType.shipping);
        if (!shippingItem || !this.state.order!.increment_id) return false;

        const productItems = orderItems.map(oi => {
            return { id: oi.id, quantity: oi.quantity, unit_price: oi.unit_price };
        });

        try {
            const order = await Api.modifyOrder(this.state.order!.increment_id, {
                shipping_cost: shippingItem.unit_price,
                items: productItems as OrderItemInput[],
            });
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.order.edit.onSubmit.success" }), { variant: "success" });
            if (!order.increment_id) {
                throw new Error();
            }
            this.fetchOrderAndShipmentStatuses(order.increment_id)();
            this.setState({ order });
            return true;
        } catch (error) {
            if (error instanceof ApiError) {
                const message = error.hasFieldError() ? error.fieldErrors[0].message : error.message;
                this.props.enqueueSnackbar(message || I18n.formatMessage({ id: "pages.order.edit.onSubmit.error" }), { variant: "error" });
            } else {
                this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.order.edit.onSubmit.error" }), { variant: "error" });
            }
        }
        return false;
    };

    private canEdit = () => {
        return this.props.isSuperadmin || this.props.permissions.includes(PermissionType.order_update);
    };

    public render() {
        const { isLoading, isCorrectionLoading, order, shipmentStatuses } = this.state;

        if (isLoading) {
            return <Loading breadcrumb={this.getBreadcrumb()} />;
        }

        if (!order) {
            return <Redirect to={Path.orderList} />;
        }

        const getBankTransferStatus = () => {
            switch (order.payment?.status.id) {
                case PaymentStatusEnum.paid:
                    return BankTransferStatus.paid;
                case PaymentStatusEnum.partially_paid:
                    return BankTransferStatus.partially_paid;
                default:
                    return null;
            }
        };

        const initialValues: OrderFormValues = {
            bank_transfer_status: getBankTransferStatus(),
            bank_transfer_paid: order.payment?.amount_paid ?? null,
            admin_note: "",
        };

        return (
            <PageLayout breadcrumb={this.getBreadcrumb()}>
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values, formikHelpers) => {
                        this.onSubmit(values, formikHelpers);
                        formikHelpers.resetForm();
                    }}
                    validateOnBlur={false}
                    enableReinitialize={true}
                >
                    {props => (
                        <>
                            <OrderForm
                                refreshIndex={this.state.refreshIndex}
                                formType={FormType.edit}
                                formProps={props}
                                order={order!}
                                canEdit={this.canEdit()}
                                onCloseOrderClick={() => this.setState({ isCloseOrderDialogVisible: true })}
                                onDeleteClick={() => this.setState({ isDeleteDialogVisible: true })}
                                onCorrectionClick={() => this.setState({ isCorrectionDialogVisible: true })}
                                onStornoClick={() => this.setState({ isStornoDialogVisible: true })}
                                onRefundClick={() => this.setState({ isRefundDialogVisible: true })}
                                onRetryClick={() => this.setState({ isRetryDialogVisible: true })}
                                onModifyOrderSubmit={this.onModifyOrderSubmit}
                                shipmentStatuses={shipmentStatuses}
                            />
                            <Prompt when={props.dirty} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props)} />
                        </>
                    )}
                </Formik>
                <Box mt="40px" />
                <CloseOrderModal
                    title={I18n.formatMessage({ id: "pages.order.edit.closeOrderModal.title" })}
                    isVisible={this.state.isCloseOrderDialogVisible}
                    onClose={() => this.setState({ isCloseOrderDialogVisible: false })}
                    onCloseOrderClick={this.onCloseOrderClick}
                />
                <DeleteOrderConfirmModal
                    isVisible={this.state.isDeleteDialogVisible}
                    onClose={() => this.setState({ isDeleteDialogVisible: false })}
                    onDeleteClick={this.onDeleteClick}
                />
                {this.state.isCorrectionDialogVisible ? (
                    <CorrectOrderItemsDialog
                        onSave={(items: CorrectionItem[]) => this.onCorrectItems(items)}
                        onClose={() => this.setState({ isCorrectionDialogVisible: false })}
                        order={order}
                        isLoading={isCorrectionLoading}
                    />
                ) : (
                    <></>
                )}
                <FunctionConfirmModal
                    title={I18n.formatMessage({ id: "pages.order.edit.stornoModal.title" })}
                    description={I18n.formatMessage({ id: "pages.order.edit.stornoModal.description" })}
                    isVisible={this.state.isStornoDialogVisible}
                    onClose={() => this.setState({ isStornoDialogVisible: false })}
                    onFunctionClick={this.onStornoClick}
                    leftButtonLabel={I18n.formatMessage({ id: "common.cancel" })}
                    rightButtonLabel={I18n.formatMessage({ id: "common.yes" })}
                />
                <FunctionConfirmModal
                    title={I18n.formatMessage({ id: "pages.order.edit.refundModal.title" })}
                    description={I18n.formatMessage({ id: "pages.order.edit.refundModal.description" })}
                    isVisible={this.state.isRefundDialogVisible}
                    onClose={() => this.setState({ isRefundDialogVisible: false })}
                    onFunctionClick={this.onRefundClick}
                    leftButtonLabel={I18n.formatMessage({ id: "common.cancel" })}
                    rightButtonLabel={I18n.formatMessage({ id: "common.yes" })}
                />
                <FunctionConfirmModal
                    title={I18n.formatMessage({ id: "pages.order.edit.retryModal.title" })}
                    description={I18n.formatMessage({ id: "pages.order.edit.retryModal.description" })}
                    isVisible={this.state.isRetryDialogVisible}
                    onClose={() => this.setState({ isRetryDialogVisible: false })}
                    onFunctionClick={this.onRetryClick}
                    leftButtonLabel={I18n.formatMessage({ id: "common.cancel" })}
                    rightButtonLabel={I18n.formatMessage({ id: "common.yes" })}
                />
            </PageLayout>
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { permissions: AuthSelectors.getPermissions(state.auth), isSuperadmin: AuthSelectors.isSuperadmin(state.auth) };
};

export default withSnackbar(connect(mapStateToProps)(withRouter(OrderPage)));
