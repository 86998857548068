import slugify from "slugify";

export class Helpers {
    private static SLUG_OPTIONS = { lower: true };

    public static toSlug(value: string, isUnderscore?: boolean): string {
        return slugify(
            value
                .replace(/[^a-zA-Z0-9áÁéÉíÍóÓöÖőŐüÜűŰúÚ -]/g, "") // replace all non alphanumeric/dash characters
                .replace(/\./g, "") // replace all dots with empty string (for some reason the previous replace won't cover it)
                .replace(/-{2,}/g, "") // replace multiple dashes with a single dash
                .trim()
                .replace(/[\s-]+/g, isUnderscore ? "_" : "-")
                .toLowerCase(),
            Helpers.SLUG_OPTIONS
        );
    }

    public static updateSlug(oldName: string, newName: string, slug: string): string {
        const oldSlug = Helpers.toSlug(oldName, false);

        if (!slug || oldSlug === slug) {
            return Helpers.toSlug(newName, false);
        }

        return slug;
    }
}
