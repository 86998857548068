import { gql } from "@apollo/client";
import { adminFragment } from "../fragments/adminFragment";

export const updateAdminMutation = gql`
    mutation updateAdmin($id: Int!, $input: AdminInput!, $csrf: String) {
        updateAdmin(id: $id, input: $input, csrf: $csrf) {
            ...Admin
        }
    }
    ${adminFragment}
`;
