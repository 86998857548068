import { gql } from "@apollo/client";
import { productCatalogItemFragment } from "../fragments/productCatalogItemFragment";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";

export const listProductsQuery = gql`
    query listProducts($filters_base: [ProductFilterInput!], $filters: ProductFilters, $sortBy: ProductSort, $first: Int!, $page: Int) {
        listProducts(filters_base: $filters_base, first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            data {
                ...ProductCatalogItem
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${productCatalogItemFragment}
    ${paginatorInfoFragment}
`;
