import { gql } from "@apollo/client";
import { shippingMethodSelectItemFragment } from "../fragments/shippingMethodSelectItemFragment";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";

export const listShippingMethodSelectItemsQuery = gql`
    query listShippingMethodSelectItems($filters: ShippingMethodFilters, $sortBy: ShippingMethodSort, $first: Int!, $page: Int) {
        listShippingMethods(first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            data {
                ...ShippingMethodSelectItem
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${shippingMethodSelectItemFragment}
    ${paginatorInfoFragment}
`;
