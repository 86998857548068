import { Box, Typography } from "@bigfish/admin-ui/core";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { ModalBadgeForm, BadgeFormValues, badgeValidator } from "./ModalBadgeForm";
import Prompt from "Components/Prompt";
import { I18n } from "I18n/I18n";

type ComponentProps = {
    onModalClose: () => void;
};

type Props = ComponentProps & WithSnackbarProps;

type State = {
    isCreateSucceed: boolean;
};

class ModalBadgeCreatePage extends React.Component<Props, State> {
    public readonly state: State = {
        isCreateSucceed: false,
    };

    private onSubmit = async (values: BadgeFormValues, formikHelpers: FormikHelpers<BadgeFormValues>, isRedirectHandled?: boolean): Promise<boolean> => {
        try {
            if (!values.type) return false;
            await Api.createBadge({ ...values, type: values.type });
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.badge.onCreateSubmit.succeed" }), { variant: "success" });
            if (!isRedirectHandled) {
                this.setState({ isCreateSucceed: true }, this.props.onModalClose);
            }
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.badge.onCreateSubmit.error" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
            return false;
        }
    };

    public render() {
        const initialValues: BadgeFormValues = {
            name: "",
            type: null,
            image: "",
            image_title: "",
            info: "",
            product_param_id: null,
            is_active: false,
            active_from: null,
            active_to: null,
        };

        return (
            <div className="modal-grey-background">
                <Typography variant="h1">{I18n.formatMessage({ id: "pages.badge.new" })}</Typography>
                <Formik initialValues={initialValues} onSubmit={this.onSubmit} validate={badgeValidator(FormType.create)} validateOnBlur={false}>
                    {props => (
                        <>
                            <ModalBadgeForm formType={FormType.create} formProps={props} onCancelClick={this.props.onModalClose} />
                            <Prompt when={props.dirty && !this.state.isCreateSucceed} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props, true)} />
                        </>
                    )}
                </Formik>
                <Box mt="40px" />
            </div>
        );
    }
}

export default withSnackbar(ModalBadgeCreatePage);
