import React from "react";
import { DefaultModal } from "@bigfish/admin-ui/components";
import {
    debounce,
    Typography,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Icon,
    Button,
    TextField,
    InputAdornment,
    IconButton,
    Box,
    createStyles,
    WithStyles,
    withStyles,
    CircularProgress,
} from "@bigfish/admin-ui/core";
import { Api } from "Api/Api";
import { GalleryListItem } from "Api/graphql/admin/types";
import { I18n } from "I18n/I18n";
import { withSnackbar, WithSnackbarProps } from "notistack";

type ComponentProps = {
    isVisible: boolean;

    selectedGallery: GalleryListItem | null;
    onGallerySelected: (gallery: GalleryListItem) => void;

    onGalleryInsertClick: () => void;
    onGalleryAddClick: () => void;
    onGalleryEditClick: (gallery: GalleryListItem) => void;
    onClose: () => void;
};

type Props = ComponentProps & WithSnackbarProps & WithStyles<typeof styles>;

type State = {
    filter: string;
    galleries: GalleryListItem[];
    isLoading: boolean;
};

class CKGalleryListModal extends React.Component<Props, State> {
    public readonly state: State = {
        filter: "",
        galleries: [],
        isLoading: true,
    };

    public componentDidMount(): void {
        this.refreshData();
    }

    public componentDidUpdate(prevProps: Props): void {
        if (!this.props.isVisible && prevProps.isVisible) {
            this.setState({ filter: "" });
        } else if (this.props.isVisible && !prevProps.isVisible) {
            this.refreshData();
        }
    }

    private refreshData = (): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const result = await Api.galleries({ first: 999, filters: { title: this.state.filter } });
                    this.setState({ galleries: result.data, isLoading: false });
                } catch (error) {
                    this.props.enqueueSnackbar(error.message, { variant: "error" });
                    this.setState({ isLoading: false, galleries: [] });
                }
            }
        );
    };

    private refreshDataDebounced = debounce(this.refreshData, 250);

    private onClearFilter = (): void => {
        this.setState({ filter: "" }, this.refreshData);
    };

    public render() {
        return (
            <DefaultModal
                title={I18n.formatMessage({ id: "components.inputs.richTextEditor.galleryList.title" })}
                rightButtonsComponent={
                    <Button variant="contained" color="primary" disabled={!this.props.selectedGallery} onClick={this.props.onGalleryInsertClick}>
                        {I18n.formatMessage({ id: "components.inputs.richTextEditor.galleryList.insert" })}
                    </Button>
                }
                leftButtonsComponent={
                    <Button variant="outlined" color="primary" onClick={this.props.onClose}>
                        {I18n.formatMessage({ id: "common.cancel" })}
                    </Button>
                }
                open={this.props.isVisible}
                onClose={this.props.onClose}
            >
                <Box display="flex" flexDirection="row">
                    <Box flex="1">
                        <TextField
                            type="text"
                            label={I18n.formatMessage({ id: "components.inputs.richTextEditor.galleryList.search" })}
                            variant="outlined"
                            fullWidth
                            InputProps={{
                                endAdornment: this.state.filter ? (
                                    <InputAdornment position="end">
                                        <IconButton color="primary" onClick={this.onClearFilter}>
                                            <Icon className="fa fa-times" />
                                        </IconButton>
                                    </InputAdornment>
                                ) : (
                                    <InputAdornment position="end">
                                        <IconButton color="primary">
                                            <Icon className="fa fa-search" color="primary" />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            value={this.state.filter}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                this.setState({ filter: event.currentTarget.value }, this.refreshDataDebounced);
                            }}
                        />
                    </Box>
                    <Box ml="20px" alignSelf="center">
                        <Button variant="outlined" color="primary" startIcon={<Icon className="fas fa-plus-circle" />} onClick={this.props.onGalleryAddClick}>
                            {I18n.formatMessage({ id: "components.inputs.richTextEditor.galleryList.add" })}
                        </Button>
                    </Box>
                </Box>

                {this.state.galleries.length === 0 ? (
                    <Box display="flex" alignItems="center" justifyContent="center" height="33vh">
                        {this.state.isLoading ? (
                            <CircularProgress color="secondary" />
                        ) : (
                            <Typography>{I18n.formatMessage({ id: "components.inputs.richTextEditor.galleryList.emptyList" })}</Typography>
                        )}
                    </Box>
                ) : (
                    <Box mt="20px">
                        <TableContainer style={{ height: "33vh", overflow: "scroll", borderRadius: 4, border: "1px solid #E4E8F7" }}>
                            {this.state.isLoading && (
                                // TODO: sitebuild fix
                                <div style={{ position: "absolute", top: "50%", left: "50%", right: "50%", bottom: "50%" }}>
                                    <CircularProgress color="secondary" />
                                </div>
                            )}
                            <Table aria-label="sticky table">
                                <TableBody>
                                    {this.state.galleries.map(gallery => {
                                        return (
                                            <TableRow
                                                hover
                                                className={this.props.classes.row}
                                                role="button"
                                                tabIndex={-1}
                                                key={gallery.id}
                                                selected={this.props.selectedGallery?.id === gallery.id}
                                                onClick={() => {
                                                    this.props.onGallerySelected(gallery);
                                                }}
                                            >
                                                <TableCell>
                                                    <Icon className="far fa-images" />
                                                </TableCell>
                                                <TableCell>{gallery.title}</TableCell>
                                                <TableCell>{gallery.image_count}</TableCell>
                                                <TableCell>{gallery.updated_at}</TableCell>
                                                <TableCell
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        this.props.onGalleryEditClick(gallery);
                                                    }}
                                                >
                                                    <Icon className="fa fa-pencil-alt" style={{ color: "#7895C1" }} title={I18n.formatMessage({ id: "common.edit" })} />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                )}
            </DefaultModal>
        );
    }
}

const styles = () =>
    createStyles({
        row: {
            cursor: "pointer",
            "&> td >.fa-pencil-alt": {
                visibility: "hidden",
            },
            "&:hover > td >.fa-pencil-alt": {
                visibility: "visible",
            },
        },
    });

export default withStyles(styles)(withSnackbar(CKGalleryListModal));
