import { gql } from "@apollo/client";
import { galleryListItemFragment } from "../fragments/galleryListItemFragment";

export const createGalleryMutation = gql`
    mutation createGallery($input: CreateGalleryInput!, $csrf: String) {
        createGallery(input: $input, csrf: $csrf) {
            ...GalleryListItem
        }
    }
    ${galleryListItemFragment}
`;
