import { gql } from "@apollo/client";

export const productPriceFragment = gql`
    fragment ProductPrice on ProductPrice {
        id
        price
        price_unit
        active_from
        active_to
    }
`;
