import React from "react";
import { Button, Icon, Menu, MenuItem } from "@bigfish/admin-ui/core";
import { PageLayout } from "Components/PageLayout";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import BannerListDataGrid from "./BannerListDataGrid";
import { TitleBar, PageCard } from "@bigfish/admin-ui/components";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { I18n } from "I18n/I18n";
import { connect } from "react-redux";
import { ApplicationState } from "Redux/rootReducer";
import { AuthSelectors } from "Redux/selectors/authSelectors";
import { PermissionType, BannerType } from "Api/graphql/admin/types";

type ReduxProps = {
    permissions: PermissionType[];
    isSuperadmin: boolean;
};

type State = {
    anchorElement: (EventTarget & HTMLButtonElement) | null;
};

type Props = ReduxProps & RouteComponentProps & WithSnackbarProps;

class BannerListPage extends React.Component<Props, State> {
    public readonly state: State = {
        anchorElement: null,
    };

    private onAddClick = (type: BannerType): void => {
        this.onAddMenuClose();
        this.props.history.push(Path.bannerCreate(type));
    };

    private onAddMenuClose = () => {
        this.setState({ anchorElement: null });
    };

    public render() {
        return (
            <PageLayout>
                <TitleBar
                    title={I18n.formatMessage({ id: "pages.bannerList.title" })}
                    rightButtonsComponent={
                        (this.props.isSuperadmin || this.props.permissions.includes(PermissionType.banner_update)) && (
                            <div>
                                <Button
                                    aria-controls="add-menu"
                                    aria-haspopup="true"
                                    startIcon={<Icon className="fa fa-plus-circle" />}
                                    variant="contained"
                                    size="medium"
                                    color="secondary"
                                    onClick={event => this.setState({ anchorElement: event.currentTarget })}
                                >
                                    {I18n.formatMessage({ id: "pages.bannerList.addButton" })}
                                </Button>
                                <Menu id="simple-menu" anchorEl={this.state.anchorElement} keepMounted open={!!this.state.anchorElement} onClose={this.onAddMenuClose}>
                                    <MenuItem onClick={() => this.onAddClick(BannerType.normal)}>{I18n.formatMessage({ id: "enums.bannerType.normal" })}</MenuItem>
                                    <MenuItem onClick={() => this.onAddClick(BannerType.product_list)}>{I18n.formatMessage({ id: "enums.bannerType.product_list" })}</MenuItem>
                                    <MenuItem onClick={() => this.onAddClick(BannerType.product)}>{I18n.formatMessage({ id: "enums.bannerType.product" })}</MenuItem>
                                </Menu>
                            </div>
                        )
                    }
                />
                <PageCard.Container>
                    <BannerListDataGrid />
                </PageCard.Container>
            </PageLayout>
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { permissions: AuthSelectors.getPermissions(state.auth), isSuperadmin: AuthSelectors.isSuperadmin(state.auth) };
};

export default withSnackbar(connect(mapStateToProps)(withRouter(BannerListPage)));
