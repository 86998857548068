import { gql } from "@apollo/client";
import { personFragment } from "../fragments/personFragment";

export const getPersonQuery = gql`
    query getPerson($id: ID!) {
        person(id: $id) {
            ...Person
        }
    }
    ${personFragment}
`;
