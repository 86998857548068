import React, { useCallback } from "react";
import { ProductUpdateInput, Product, ProductParamStructure } from "Api/graphql/admin/types";
import { Box, Button, Grid, Icon } from "@bigfish/admin-ui/core";
import { I18n } from "Src/i18n/I18n";
import { FullscreenLoader, PageCard, SavePanel, TabsComponent } from "@bigfish/admin-ui/components";
import { Path } from "Utils/Path";
import { useHistory } from "react-router-dom";
import { Form } from "Components/Form";
import { FormikErrors, FormikProps } from "formik";
import { GeneralTab } from "./GeneralTab";
import { DataTab } from "./DataTab";
import { StockTab } from "./StockTab";
import LogListDataGrid from "Pages/LogList/LogListDataGrid";
import { I18nHelpers } from "I18n/I18nHelpers";
import { Validator } from "Utils/Validator";
import { ProductImageInputWithGenericUrl } from "Utils/ApiUtils";

export type ProductFormValues = Omit<ProductUpdateInput, "images"> & {
    images: ProductImageInputWithGenericUrl[];
    health_fund?: boolean;
    og_image?: string;
    og_title?: string;
    og_description?: string;
    meta_description?: string;
    description?: string;
    use_evo_description?: boolean;
    size_class: number;
};

type Props = {
    product: Product;
    productParamStructures: ProductParamStructure[];
    formProps: FormikProps<ProductFormValues>;
};

export const productValidator = () => (values: ProductFormValues): FormikErrors<ProductFormValues> => {
    const errors: { [key in keyof ProductFormValues]?: any } = {};

    if (values.slug) {
        errors.slug = I18nHelpers.formatValidator(Validator.slug)(values.slug);
    }

    return Form.cleanupFormikErrors(errors);
};

export const ProductForm = (props: Props) => {
    const history = useHistory();
    const onCancelClick = useCallback(() => {
        history.push(Path.productCatalog);
    }, [history]);

    const [formTab, setFormTab] = React.useState(0);

    const changeTab = (_event: React.ChangeEvent<{}>, newValue: number) => {
        setFormTab(newValue);
    };

    return (
        <PageCard.Container>
            <Form formProps={props.formProps}>
                <TabsComponent
                    ariaLabel="product-form-tabs"
                    tabs={[
                        {
                            label: I18n.formatMessage({ id: "pages.product.form.tabs.general" }),
                            id: "product-form-general-tab",
                            content: <GeneralTab product={props.product} formProps={props.formProps} />,
                        },
                        {
                            label: I18n.formatMessage({ id: "pages.product.form.tabs.data" }),
                            id: "product-form-data-tab",
                            content: <DataTab product={props.product} productParamStructures={props.productParamStructures} formProps={props.formProps} />,
                        },
                        {
                            label: I18n.formatMessage({ id: "pages.product.form.tabs.stock" }),
                            id: "product-form-stock-tab",
                            content: <StockTab product={props.product} />,
                        },
                        {
                            label: I18n.formatMessage({ id: "pages.product.form.tabs.log" }),
                            id: "product-form-log-tab",
                            content: (
                                <Box mt="30px">
                                    <LogListDataGrid parentId={props.product.id} />
                                </Box>
                            ),
                        },
                    ]}
                    currentTab={formTab}
                    changeTab={changeTab}
                />

                <SavePanel>
                    <Grid container justify="space-between">
                        <Button variant="outlined" color="primary" onClick={onCancelClick}>
                            {I18n.formatMessage({ id: "common.cancel" })}
                        </Button>
                        <Button
                            type="submit"
                            startIcon={<Icon className="fas fa-save" />}
                            variant="contained"
                            color="secondary"
                            disabled={!props.formProps.dirty || !props.formProps.isValid}
                        >
                            {I18n.formatMessage({ id: "common.save" })}
                        </Button>
                    </Grid>
                </SavePanel>
                <FullscreenLoader visible={props.formProps.isSubmitting} />
            </Form>
        </PageCard.Container>
    );
};
