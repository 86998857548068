import React, { useCallback } from "react";
import { Product, ProductGratisInput } from "Api/graphql/admin/types";
import { Box, Button, Grid, Icon } from "@bigfish/admin-ui/core";
import { I18n } from "Src/i18n/I18n";
import { FullscreenLoader, PageCard, SavePanel, TabsComponent } from "@bigfish/admin-ui/components";
import { Path } from "Utils/Path";
import { useHistory } from "react-router-dom";
import { Form } from "Components/Form";
import { FormikProps } from "formik";
import { GeneralTab } from "./GeneralTab";
import LogListDataGrid from "Pages/LogList/LogListDataGrid";
import { StockTab } from "Pages/Product/StockTab";

type Props = {
    product: Product;
    formProps: FormikProps<ProductGratisInput>;
};

export const ProductGratisForm = (props: Props) => {
    const history = useHistory();
    const onCancelClick = useCallback(() => {
        history.push(Path.productCatalog);
    }, [history]);

    const [formTab, setFormTab] = React.useState(0);

    const changeTab = (_event: React.ChangeEvent<{}>, newValue: number) => {
        setFormTab(newValue);
    };

    return (
        <PageCard.Container>
            <Form formProps={props.formProps}>
                <TabsComponent
                    ariaLabel="product-form-tabs"
                    tabs={[
                        {
                            label: I18n.formatMessage({ id: "pages.product.form.tabs.general" }),
                            id: "product-form-general-tab",
                            content: <GeneralTab product={props.product} formProps={props.formProps} />,
                        },
                        {
                            label: I18n.formatMessage({ id: "pages.product.form.tabs.stock" }),
                            id: "product-form-stock-tab",
                            content: <StockTab product={props.product} />,
                        },
                        {
                            label: I18n.formatMessage({ id: "pages.product.form.tabs.log" }),
                            id: "product-form-log-tab",
                            content: (
                                <Box mt="30px">
                                    <LogListDataGrid parentId={props.product.id} />
                                </Box>
                            ),
                        },
                    ]}
                    currentTab={formTab}
                    changeTab={changeTab}
                />

                <SavePanel>
                    <Grid container justify="space-between">
                        <Button variant="outlined" color="primary" onClick={onCancelClick}>
                            {I18n.formatMessage({ id: "common.cancel" })}
                        </Button>
                        <Button
                            type="submit"
                            startIcon={<Icon className="fas fa-save" />}
                            variant="contained"
                            color="secondary"
                            disabled={!props.formProps.dirty || !props.formProps.isValid}
                        >
                            {I18n.formatMessage({ id: "common.save" })}
                        </Button>
                    </Grid>
                </SavePanel>
                <FullscreenLoader visible={props.formProps.isSubmitting} />
            </Form>
        </PageCard.Container>
    );
};
