import { gql } from "@apollo/client";
import { paymentMethodSelectItemFragment } from "../fragments/paymentMethodSelectItemFragment";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";

export const listPaymentMethodSelectItemsQuery = gql`
    query listPaymentMethodSelectItems($filters: PaymentMethodFilters, $sortBy: PaymentMethodSort, $first: Int!, $page: Int) {
        listPaymentMethods(first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            data {
                ...PaymentMethodSelectItem
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${paymentMethodSelectItemFragment}
    ${paginatorInfoFragment}
`;
