import React from "react";
import { TypeSingleFilterValue, TypeSingleSortInfo } from "@inovua/reactdatagrid-community/types";
import { DataSource, DataSourceProps } from "Components/DataGrid/DataGridUtils";
import { Icon, IconButton } from "@bigfish/admin-ui/core";
import UnControlledDataGrid from "Components/DataGrid/UCDataGrid";
import { I18n } from "I18n/I18n";
import { BenefitListItem } from "Api/graphql/admin/types";
import { CustomTypeColumn } from "Utils/DataGridUtils";
import { Constants } from "Utils/Constants";

type Props = {
    benefits: BenefitListItem[];
    onRemoveClick(benefitId: string): void;
};

type State = {
    filterValue: TypeSingleFilterValue[];
    sortInfo: TypeSingleSortInfo[];
};

class BenefitTable extends React.Component<Props> {
    public readonly state: State = {
        filterValue: [{ name: "title", operator: "contains", type: "string", value: "" }],
        sortInfo: [],
    };

    private getColumns = (): CustomTypeColumn[] => {
        return [
            {
                name: "id",
                header: I18n.formatMessage({ id: "pages.division.form.benefits.grid.column.id" }),
                filterDelay: Constants.filterDelayMS,
            },
            {
                name: "title",
                header: I18n.formatMessage({ id: "pages.division.form.benefits.grid.column.title" }),
                flex: 1,
                filterDelay: Constants.filterDelayMS,
            },
            {
                name: "functions",
                width: 100,
                header: I18n.formatMessage({ id: "pages.division.form.benefits.grid.column.functions" }),
                textAlign: "center",
                sortable: false,
                render: ({ data }: { data: BenefitListItem }) => {
                    return (
                        <IconButton color="primary" onClick={() => this.props.onRemoveClick(data.id)}>
                            <Icon className="fas fa-times" />
                        </IconButton>
                    );
                },
            },
        ];
    };

    private getDataSource = (benefits: BenefitListItem[]) => async (params: DataSourceProps<BenefitListItem>): Promise<DataSource<BenefitListItem>> => {
        const titleFilter = params.filterValue![0].value.toLowerCase();
        const data = benefits.filter(product => {
            return (product.title ?? "").toLowerCase().includes(titleFilter);
        });

        return { data, count: data.length };
    };

    public render() {
        return (
            <UnControlledDataGrid<BenefitListItem>
                rowHeight={50}
                style={{ minHeight: 400 }}
                dataSource={this.getDataSource(this.props.benefits)}
                columns={this.getColumns()}
                filterValue={this.state.filterValue}
                onFilterValueChange={filterValue => {
                    this.setState({ filterValue });
                }}
                activeCell={null}
                filterable
            />
        );
    }
}

export default BenefitTable;
