import React from "react";
import { PageLayout } from "Components/PageLayout";
import { RouteComponentProps } from "react-router-dom";
import GiftCardOrderListDataGrid from "./GiftCardOrderListDataGrid";
import { TitleBar, PageCard } from "@bigfish/admin-ui/components";
import { I18n } from "I18n/I18n";
import { connect } from "react-redux";
import { Button, Icon, Menu, MenuItem } from "@bigfish/admin-ui/core";
import { PermissionType } from "Api/graphql/admin/types";
import { ApplicationState } from "Redux/rootReducer";
import { AuthSelectors } from "Redux/selectors/authSelectors";
import { Path } from "Utils/Path";

type ReduxProps = {
    permissions: PermissionType[];
    isSuperadmin: boolean;
};

type Props = RouteComponentProps & ReduxProps;

type State = {
    anchorElement: (EventTarget & HTMLButtonElement) | null;
};

enum GiftCardOrderType {
    prize = "prize",
    company = "company",
}

class GiftCardOrderListPage extends React.Component<Props, State> {
    public readonly state: State = {
        anchorElement: null,
    };

    private onAddMenuClose = () => {
        this.setState({ anchorElement: null });
    };

    private onAddClick = (giftCardOrderType: GiftCardOrderType): void => {
        this.onAddMenuClose();
        this.props.history.push(giftCardOrderType === GiftCardOrderType.prize ? Path.giftCardOrderPrizeCreate : Path.giftCardOrderCompanyCreate);
    };

    public render() {
        return (
            <PageLayout>
                <TitleBar
                    title={I18n.formatMessage({ id: "pages.giftCardOrderList.title" })}
                    rightButtonsComponent={
                        (this.props.isSuperadmin || this.props.permissions.includes(PermissionType.gift_card_order_create)) && (
                            <div>
                                <Button
                                    startIcon={<Icon className="fa fa-plus-circle" />}
                                    variant="contained"
                                    size="medium"
                                    color="secondary"
                                    onClick={event => this.setState({ anchorElement: event.currentTarget })}
                                >
                                    {I18n.formatMessage({ id: "pages.giftCardOrderList.addButton" })}
                                </Button>
                                <Menu anchorEl={this.state.anchorElement} keepMounted open={!!this.state.anchorElement} onClose={this.onAddMenuClose}>
                                    <MenuItem onClick={() => this.onAddClick(GiftCardOrderType.prize)}>
                                        {I18n.formatMessage({ id: "pages.giftCardOrderList.addButton.prize" })}
                                    </MenuItem>
                                    <MenuItem onClick={() => this.onAddClick(GiftCardOrderType.company)}>
                                        {I18n.formatMessage({ id: "pages.giftCardOrderList.addButton.company" })}
                                    </MenuItem>
                                </Menu>
                            </div>
                        )
                    }
                />
                <PageCard.Container>
                    <GiftCardOrderListDataGrid />
                </PageCard.Container>
            </PageLayout>
        );
    }
}
const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { permissions: AuthSelectors.getPermissions(state.auth), isSuperadmin: AuthSelectors.isSuperadmin(state.auth) };
};

export default connect(mapStateToProps)(GiftCardOrderListPage);
