import { gql } from "@apollo/client";

export const deleteContentMutation = gql`
    mutation deleteContent($id: ID!, $csrf: String) {
        deleteContent(id: $id, csrf: $csrf) {
            ... on TextContent {
                id
            }
        }
    }
`;
