import { gql } from "@apollo/client";
import { productBrandForMenuFragment } from "../fragments/productBrandForMenuFragment";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";

export const listProductBrandsForMenuQuery = gql`
    query listProductBrandsForMenu($filters: ProductBrandFilters, $sortBy: ProductBrandSort, $first: Int!, $page: Int) {
        listProductBrands(first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            data {
                ...ProductBrandForMenu
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${productBrandForMenuFragment}
    ${paginatorInfoFragment}
`;
