import {
    DateRangeFilter,
    FulfillmentInput,
    OrderDirection,
    // TODO: Prod backend not ready yet: https://bigfishhu.atlassian.net/browse/ROS-3917
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // OrderItemInputV2,
    InvoiceCorrectionItemInput,
    Order_items,
    PackageType,
    PageBlockBannerLayout,
    PageBlockBanner_banners_BannerNormal,
    PageBlockBanner_banners_BannerProduct,
    PageBlockBanner_banners_BannerProductList,
    PageInput,
    Page_blocks_PageBlockProductList_product_sort_by,
    ProductImage,
    ProductImageSource,
    ProductSortFieldCategory,
    ProductSortFrontendInput,
    ShippingMethodType,
} from "Api/graphql/admin/types";
import { IntervalFilterValue } from "Components/DataGrid/DataGrid";
import { FormType } from "Components/Form";
import { ExtendedPageBlockBannerLayout, PageFormValues, ProductListSortFriendlyValues } from "Pages/Page/PageForm";
import { DateFormat, DateUtils } from "./DateUtils";
import { CorrectionItem } from "Components/CorrectOrderItemsDialog/CorrectOrderItemsDialog";
import { FulfillmentFormValues } from "Pages/Fulfillment/FulfillmentForm";
import { Constants } from "./Constants";

export type BannerMinimal = PageBlockBanner_banners_BannerNormal | PageBlockBanner_banners_BannerProduct | PageBlockBanner_banners_BannerProductList;

export type ProductImageInputWithGenericUrl = {
    is_new: boolean;
    id?: number;
    generic_url: string;
    source: ProductImageSource;
    is_active: boolean;
    delete: boolean;
};

export const ApiUtils = {
    getDateRangeFromInterval(interval: IntervalFilterValue | null | undefined): DateRangeFilter | undefined {
        if (!interval) return undefined;
        return {
            from: interval.start ? DateUtils.format(interval.start, DateFormat.apiDateTime) : undefined,
            to: interval.end ? DateUtils.format(interval.end, DateFormat.apiDateTime) : undefined,
        };
    },

    getRangeFromInterval(interval: IntervalFilterValue | null | undefined): IntervalFilterValue | undefined {
        if (!interval) return undefined;
        return {
            start: interval.start ? `${interval.start}` : undefined,
            end: interval.end ? `${interval.end}` : undefined,
        };
    },

    getProductSortInputFromFriendly: (sortByFriendly: ProductListSortFriendlyValues): ProductSortFrontendInput | null => {
        switch (sortByFriendly) {
            case ProductListSortFriendlyValues.ratingAsc:
                return { field: ProductSortFieldCategory.rating, direction: OrderDirection.ASC };
            case ProductListSortFriendlyValues.ratingDesc:
                return { field: ProductSortFieldCategory.rating, direction: OrderDirection.DESC };
            case ProductListSortFriendlyValues.priceUnitAsc:
                return { field: ProductSortFieldCategory.price_unit, direction: OrderDirection.ASC };
            case ProductListSortFriendlyValues.priceUnitDesc:
                return { field: ProductSortFieldCategory.price_unit, direction: OrderDirection.DESC };
            case ProductListSortFriendlyValues.priceAsc:
                return { field: ProductSortFieldCategory.price, direction: OrderDirection.ASC };
            case ProductListSortFriendlyValues.priceDesc:
                return { field: ProductSortFieldCategory.price, direction: OrderDirection.DESC };
            case ProductListSortFriendlyValues.orderedAsc:
                return { field: ProductSortFieldCategory.ordered, direction: OrderDirection.ASC };
            case ProductListSortFriendlyValues.orderedDesc:
                return { field: ProductSortFieldCategory.ordered, direction: OrderDirection.DESC };
            case ProductListSortFriendlyValues.nameAsc:
                return { field: ProductSortFieldCategory.name, direction: OrderDirection.ASC };
            case ProductListSortFriendlyValues.nameDesc:
                return { field: ProductSortFieldCategory.name, direction: OrderDirection.DESC };
            case ProductListSortFriendlyValues.none:
            default:
                return null;
        }
    },

    getProductSortByFromBackend: (sortBy: Page_blocks_PageBlockProductList_product_sort_by | null): ProductListSortFriendlyValues | null => {
        if (!sortBy) return null;

        const { field, direction } = sortBy;

        if (field === ProductSortFieldCategory.rating && direction === OrderDirection.ASC) {
            return ProductListSortFriendlyValues.ratingAsc;
        } else if (field === ProductSortFieldCategory.rating && direction === OrderDirection.DESC) {
            return ProductListSortFriendlyValues.ratingDesc;
        } else if (field === ProductSortFieldCategory.price_unit && direction === OrderDirection.ASC) {
            return ProductListSortFriendlyValues.priceUnitAsc;
        } else if (field === ProductSortFieldCategory.price_unit && direction === OrderDirection.DESC) {
            return ProductListSortFriendlyValues.priceUnitDesc;
        } else if (field === ProductSortFieldCategory.price && direction === OrderDirection.ASC) {
            return ProductListSortFriendlyValues.priceAsc;
        } else if (field === ProductSortFieldCategory.price && direction === OrderDirection.DESC) {
            return ProductListSortFriendlyValues.priceDesc;
        } else if (field === ProductSortFieldCategory.ordered && direction === OrderDirection.ASC) {
            return ProductListSortFriendlyValues.orderedAsc;
        } else if (field === ProductSortFieldCategory.ordered && direction === OrderDirection.DESC) {
            return ProductListSortFriendlyValues.orderedDesc;
        } else if (field === ProductSortFieldCategory.name && direction === OrderDirection.ASC) {
            return ProductListSortFriendlyValues.nameAsc;
        } else if (field === ProductSortFieldCategory.name && direction === OrderDirection.DESC) {
            return ProductListSortFriendlyValues.nameDesc;
        } else {
            return null;
        }
    },

    getPageInput(values: PageFormValues, formType: FormType): PageInput {
        return {
            name: values.name,
            title: values.title,
            url: values.url,
            is_active: values.is_active,
            active_from: values.active_from ? DateUtils.format(values.active_from, DateFormat.apiDateTime) : null,
            active_to: values.active_to ? DateUtils.format(values.active_to, DateFormat.apiDateTime) : null,
            meta_title: values.meta_title,
            meta_description: values.meta_description,
            og_title: values.og_title,
            og_description: values.og_description,
            og_image: values.og_image,
            blocks: values.blocks
                .filter(b => (formType === FormType.create ? !b.delete : true))
                .map(b => {
                    return {
                        id: b.id,
                        delete: b.delete,
                        title: b.title,
                        type: b.type,
                        banner_ids: b.banners?.map(banner => banner.id) ?? [],
                        product_list_id: b.product_list?.id ?? undefined,
                        product_limit: b.product_limit || null,
                        is_scrollable: b.is_scrollable,
                        product_sort_by: b.product_sort_by ? ApiUtils.getProductSortInputFromFriendly(b.product_sort_by) : null,
                        is_filterable: b.is_filterable,
                        content: b.content,
                        layout: ExtendedPageBlockBannerLayout.featured === b.layout || ExtendedPageBlockBannerLayout.single === b.layout ? PageBlockBannerLayout.full : b.layout,
                    };
                }),
            is_header: values.is_header,
            is_footer: values.is_footer,
            site_id: values.site_id!,
        };
    },

    convertProductImagesIntoInput(images: ProductImage[]): ProductImageInputWithGenericUrl[] {
        return images
            .slice()
            .sort((a, b) => (a.position > b.position ? 1 : -1))
            .map(i => {
                return {
                    id: i.id,
                    is_new: false,
                    source: i.source,
                    generic_url: i.url_list ?? "",
                    delete: false,
                    is_active: i.is_active,
                };
            });
    },

    // TODO: Prod backend not ready yet: https://bigfishhu.atlassian.net/browse/ROS-3917
    // getOrderItemInputs(orderItems: Order_items[], correctionItems: CorrectionItem[]): OrderItemInputV2[] {
    getOrderItemInputs(orderItems: Order_items[], correctionItems: CorrectionItem[]): InvoiceCorrectionItemInput[] {
        return orderItems.map(oi => {
            return {
                id: oi.id,
                enabled: correctionItems.map(ci => ci.id).includes(oi.id),
                quantity: correctionItems.find(ci => ci.id === oi.id)?.quantity ?? oi.quantity,
            };
        });
    },

    getFulfillmentFormValues(values: FulfillmentFormValues): FulfillmentInput {
        return {
            ...values,
            active_from: values.active_from ? DateUtils.format(values.active_from, DateFormat.apiDateTime) : null,
            active_to: values.active_to ? DateUtils.format(values.active_to, DateFormat.apiDateTime) : null,
            package_type_max: `${values.package_type_max}` === Constants.nullFormValue ? null : (values.package_type_max as PackageType | null),
            quantity_max: values.quantity_max === null || values.quantity_max === undefined || values.quantity_max < 0 ? null : values.quantity_max,

            notification_threshold: values.notification_threshold < 0 ? 0 : values.notification_threshold,
            limit: values.limit < 0 ? 0 : values.limit,
            timewindow_allowed: values.shipping_methods.includes(ShippingMethodType.mpl_delivery) ? values.timewindow_allowed : false,
            weight: `${values.weight}` === "-" ? 0 : Number.parseInt(`${values.weight}`),
        };
    },
};
