import React from "react";
import { Form } from "Components/Form";
import { FormControl, FormControlLabel, Grid, TextField, Typography, Box, Checkbox } from "@bigfish/admin-ui/core";
import { Field, FieldProps, FormikProps } from "formik";
import { I18nHelpers } from "I18n/I18nHelpers";
import { Validator } from "Utils/Validator";
import { PageCard } from "@bigfish/admin-ui/components";
import { I18n } from "I18n/I18n";
import { CustomFormHelperText } from "Components/CustomFormHelperText";
import { Product } from "Api/graphql/admin/types";
import { ProductFormValues } from "./ProductForm";
import ProductImagesSortList from "./ProductImagesSortList";
import { SortEnd, arrayMove } from "react-sortable-hoc";
import "./styles.css";
import MediaLibraryImageInput from "Components/Inputs/MediaLibraryImageInput";
import { Constants } from "Utils/Constants";
import RichTextEditor from "Components/Inputs/RichTextEditor/RichTextEditor";
import { NumberUtils } from "Utils/NumberUtils";
import { ProductImageInputWithGenericUrl } from "Utils/ApiUtils";
import SelectInput from "Components/SelectInput";

export const PRODUCT_PARAM_SIZE_CLASS_NOT_DEFINED = 0;

type Props = {
    product: Product;
    formProps: FormikProps<ProductFormValues>;
};

export const GeneralTab = (props: Props) => {
    const [isCartQtyMaxEnabled, setIsCartQtyMaxEnabled] = React.useState<boolean>(
        props.formProps.values.cart_qty_max !== null && props.formProps.values.cart_qty_max !== undefined
    );

    return (
        <div>
            <Box mt="30px" />
            <PageCard.Heading title={I18n.formatMessage({ id: "common.form.basicData" })} />
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Typography color="textSecondary" gutterBottom>
                        {I18n.formatMessage({ id: "pages.product.form.tabs.general.name" })}
                    </Typography>
                    <Typography variant="body2">{props.product.name}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography color="textSecondary" gutterBottom>
                        {I18n.formatMessage({ id: "pages.product.form.tabs.general.id" })}
                    </Typography>
                    <Typography variant="body2">{props.product.id}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography color="textSecondary" gutterBottom>
                        {I18n.formatMessage({ id: "pages.product.form.tabs.general.lfdnr" })}
                    </Typography>
                    <Typography variant="body2">{props.product.lfdnr}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography color="textSecondary" gutterBottom>
                        {I18n.formatMessage({ id: "pages.product.form.tabs.general.ean" })}
                    </Typography>
                    <Typography variant="body2">{props.product.ean.join(", ")}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography color="textSecondary" gutterBottom>
                        {I18n.formatMessage({ id: "pages.product.form.tabs.general.categories" })}
                    </Typography>
                    <Typography variant="body2">
                        {props.product.categories.length > 0
                            ? props.product.categories.map(c => {
                                  return (
                                      <li className="product-form-category-list-item" key={c.id}>
                                          {c.category_path.map(c => c.name).join(" / ")}
                                      </li>
                                  );
                              })
                            : "-"}
                    </Typography>
                </Grid>
            </Grid>

            <Box mt="30px" />

            <Field name="slug" validate={I18nHelpers.formatValidator(Validator.required)}>
                {({ field, meta }: FieldProps) => (
                    <TextField
                        type="text"
                        label={I18n.formatMessage({ id: "pages.product.form.slugLabel" })}
                        fullWidth
                        variant="outlined"
                        required
                        helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                        error={meta.touched && !!meta.error}
                        {...field}
                    />
                )}
            </Field>

            <Box mt="30px" />

            <Typography color="textSecondary" gutterBottom>
                {I18n.formatMessage({ id: "pages.product.form.brandBankDescriptionLabel" })}
            </Typography>
            <Typography variant="body2">{props.product.brand_bank?.param_values.find(pv => pv.product_param_id === "description")?.values[0] ?? "-"}</Typography>

            <Box mt="30px" />

            <Typography color="textSecondary" gutterBottom>
                {I18n.formatMessage({ id: "pages.product.form.descriptionLabel" })}
            </Typography>
            <Field name="description">
                {({ field }: FieldProps) => (
                    <RichTextEditor
                        config="productDescription"
                        {...field}
                        onChange={(content: string) => {
                            props.formProps.setFieldValue("description", content);
                        }}
                    />
                )}
            </Field>

            <Box mt="30px" />

            <PageCard.Heading title={I18n.formatMessage({ id: "pages.product.form.imagesHeading" })} />
            <Field name="images">
                {({ field }: FieldProps) => (
                    <ProductImagesSortList
                        value={field.value}
                        axis="xy"
                        distance={10}
                        onSortEnd={({ oldIndex, newIndex }: SortEnd) => {
                            const newImages = arrayMove(field.value, oldIndex, newIndex);
                            props.formProps.setFieldValue("images", newImages);
                        }}
                        onChange={(images: Array<ProductImageInputWithGenericUrl | null>) => props.formProps.setFieldValue("images", images)}
                    />
                )}
            </Field>

            <Box mt="30px" />

            <Grid item xs={6}>
                <Field name="image_position_360">
                    {({ field, meta }: FieldProps) => (
                        <TextField
                            type="number"
                            label={I18n.formatMessage({ id: "pages.product.form.imagePosition360Label" })}
                            fullWidth
                            variant="outlined"
                            disabled={!props.product.image_path_360}
                            helperText={Form.getHelperText(meta, "")}
                            error={meta.touched && !!meta.error}
                            {...field}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const value = e.currentTarget.value;
                                if (!NumberUtils.isStringNumber(value)) return;
                                field.onChange(e);
                            }}
                        />
                    )}
                </Field>
            </Grid>

            <Box mt="30px" />

            <PageCard.Heading title={I18n.formatMessage({ id: "pages.product.form.subscriptionSettings" })} />
            <Field name="size_class">
                {({ field, meta }: FieldProps) => (
                    <SelectInput
                        label={I18n.formatMessage({ id: "pages.product.form.sizeClass" })}
                        fullWidth
                        variant="outlined"
                        options={Array.from({ length: 9 }).map((_, index) => {
                            if (index === 0) {
                                return { id: PRODUCT_PARAM_SIZE_CLASS_NOT_DEFINED, title: I18n.formatMessage({ id: "common.absent" }) };
                            }
                            return { id: index, title: `${index}` };
                        })}
                        {...field}
                        error={meta.touched && !!meta.error}
                    />
                )}
            </Field>

            <Box mt="30px" />

            <PageCard.Heading title={I18n.formatMessage({ id: "common.form.settings" })} />

            <Typography variant="body1" component="h3" color="textSecondary">
                {I18n.formatMessage({ id: "pages.product.form.statusLabel" })}
            </Typography>

            <Field name="is_active">
                {({ field, meta }: FieldProps) =>
                    field.value !== undefined && (
                        <Box mt="15px" ml="10px">
                            <FormControl error={meta.touched && !!meta.error}>
                                <FormControlLabel control={<Checkbox {...field} checked={field.value} />} label={I18n.formatMessage({ id: "pages.product.form.isActiveLabel" })} />
                            </FormControl>
                            <CustomFormHelperText meta={meta} />
                        </Box>
                    )
                }
            </Field>

            <Field name="health_fund">
                {({ field, meta }: FieldProps) =>
                    field.value !== undefined && (
                        <Box mt="15px" ml="10px">
                            <FormControl error={meta.touched && !!meta.error}>
                                <FormControlLabel
                                    control={<Checkbox {...field} checked={field.value} />}
                                    label={I18n.formatMessage({ id: "pages.product.form.healthFundLabel" })}
                                />
                            </FormControl>
                            <CustomFormHelperText meta={meta} />
                        </Box>
                    )
                }
            </Field>

            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Box mt="15px" ml="10px">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={() => {
                                        props.formProps.setFieldValue("cart_qty_max", null);
                                        props.formProps.setFieldTouched("cart_qty_max", true);
                                        setIsCartQtyMaxEnabled(!isCartQtyMaxEnabled);
                                    }}
                                    checked={isCartQtyMaxEnabled}
                                />
                            }
                            label={I18n.formatMessage({ id: "pages.product.form.orderQtyMaxCheckboxLabel" })}
                        />
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Field name="cart_qty_max">
                        {({ field, meta }: FieldProps) => (
                            <TextField
                                type="number"
                                label={I18n.formatMessage({ id: "pages.product.form.orderQtyMaxLabel" })}
                                fullWidth
                                variant="outlined"
                                required
                                disabled={!isCartQtyMaxEnabled}
                                helperText={Form.getHelperText(meta, "")}
                                error={meta.touched && !!meta.error}
                                {...field}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const value = e.currentTarget.value;
                                    if (!NumberUtils.isStringNumber(value)) return;
                                    field.onChange(e);
                                }}
                            />
                        )}
                    </Field>
                </Grid>
            </Grid>

            <Box mt="30px" />
            <PageCard.Heading title={I18n.formatMessage({ id: "common.form.metaData" })} />

            <div>
                <Typography variant="body1" component="h3" color="textSecondary">
                    {I18n.formatMessage({ id: "pages.product.form.ogImageLabel" })}
                </Typography>
                <Box className="image-section-root">
                    <Box className="image-section-image-field">
                        <div className="og_image_url_wrapper">
                            <Field name="og_image">
                                {({ field }: FieldProps) => (
                                    <MediaLibraryImageInput
                                        id="product-category-og_image_url"
                                        name="og_image"
                                        value={field.value}
                                        onChange={(value: string | null) => {
                                            props.formProps.setFieldValue("og_image", value);
                                            props.formProps.setFieldTouched("og_image", true);
                                        }}
                                    />
                                )}
                            </Field>
                        </div>
                    </Box>
                    <Box className="image-section-title-tag-field">
                        <Field name="og_title" validate={I18nHelpers.formatValidator(Validator.ogTitleMaxLength)}>
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "pages.product.form.ogTitleLabel" })}
                                    fullWidth
                                    variant="outlined"
                                    error={meta.touched && !!meta.error}
                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.maxCharacter" }, { max: Constants.ogTitleMaxLength }))}
                                    inputProps={{
                                        maxLength: Constants.ogTitleMaxLength,
                                    }}
                                    {...field}
                                />
                            )}
                        </Field>
                    </Box>
                    <Box className="image-section-og-description-field">
                        <Field name="og_description" validate={I18nHelpers.formatValidator(Validator.ogDescriptionMaxLength)}>
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "pages.product.form.ogDescriptionLabel" })}
                                    fullWidth
                                    variant="outlined"
                                    error={meta.touched && !!meta.error}
                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.maxCharacter" }, { max: Constants.ogDescriptionMaxLength }))}
                                    inputProps={{
                                        maxLength: Constants.ogDescriptionMaxLength,
                                    }}
                                    {...field}
                                />
                            )}
                        </Field>
                    </Box>
                    <Box className="image-section-meta-description-field">
                        <Field name="meta_description" validate={I18nHelpers.formatValidator(Validator.metaDescriptionMaxLength)}>
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "pages.product.form.metaDescriptionLabel" })}
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    error={meta.touched && !!meta.error}
                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.maxCharacter" }, { max: Constants.metaDescriptionMaxLength }))}
                                    inputProps={{
                                        maxLength: Constants.metaDescriptionMaxLength,
                                    }}
                                    {...field}
                                />
                            )}
                        </Field>
                    </Box>
                </Box>
            </div>

            <Box mt="30px" />
            <PageCard.Heading title={I18n.formatMessage({ id: "common.form.evoProductDescription" })} />

            <Field name="use_evo_description">
                {({ field, meta }: FieldProps) => (
                    <Box mt="15px" ml="10px">
                        <FormControl error={meta.touched && !!meta.error}>
                            <FormControlLabel
                                control={<Checkbox {...field} checked={field.value} />}
                                label={I18n.formatMessage({ id: "pages.product.form.useEvoProductDescriptionLabel" })}
                            />
                        </FormControl>
                        <CustomFormHelperText meta={meta} />
                    </Box>
                )}
            </Field>

            <Box mt="30px" />

            <Field name="brandbank_exclude">
                {({ field, meta }: FieldProps) => (
                    <Box mt="15px" ml="10px">
                        <FormControl error={meta.touched && !!meta.error}>
                            <FormControlLabel
                                control={<Checkbox {...field} checked={field.value} />}
                                label={I18n.formatMessage({ id: "pages.product.form.brandbankExcludeLabel" })}
                            />
                        </FormControl>
                        <CustomFormHelperText meta={meta} />
                    </Box>
                )}
            </Field>
        </div>
    );
};
