import React from "react";
import { Api } from "Api/Api";
import { TypeColumn } from "@inovua/reactdatagrid-community/types";
import { ProductFilters, ProductSort, ProductVariantChild, ProductSortField, ProductCategorySelectItem } from "Api/graphql/admin/types";
import { ApiError } from "Api/ApiError";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { Checkbox, withStyles, WithStyles } from "@bigfish/admin-ui/core";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import { dataGridStyles } from "@bigfish/admin-ui/styles";
import { I18n } from "I18n/I18n";
import { ProductCategoryUtils } from "Utils/ProductCategoryUtils";
import { DataSource, FilterValue } from "Components/DataGrid/DataGridUtils";
import { DataGridParams } from "Components/DataGrid/UrlQueryParser";
import { ListItemActive } from "Components/ListItemActive";
import UnparsedDataGrid from "Components/DataGrid/UnparsedDataGrid";
import { Constants } from "Utils/Constants";
import { CustomTypeColumn } from "Utils/DataGridUtils";

type ComponentProps = {
    idsFilter?: number[];
    productBrandId?: number;
    selectedProductIds?: number[];
    minWidth?: number;
    onAddProducts?(products: ProductVariantChild[]): void;
    onRemoveProducts?(products: ProductVariantChild[]): void;
};

type Props = ComponentProps & WithSnackbarProps & WithStyles<typeof dataGridStyles>;

type State = {
    categorySelectItems: ProductCategorySelectItem[];
    dataGridKey: number;
};

class ProductVariantChildDataGrid extends React.Component<Props, State> {
    public readonly state: State = {
        categorySelectItems: [],
        dataGridKey: 0,
    };

    public componentDidMount = async (): Promise<void> => {
        try {
            const categorySelectItems = await Api.listProductCategorySelectItems();
            this.setState({ categorySelectItems });
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }
    };

    public componentDidUpdate(prevProps: Props) {
        if (prevProps.idsFilter !== this.props.idsFilter) {
            this.setState({ dataGridKey: this.state.dataGridKey + 1 });
        }
    }

    private getColumns = (): CustomTypeColumn[] => {
        return [
            ...(this.props.selectedProductIds
                ? [
                      {
                          name: "selected",
                          header: "",
                          sortable: false,
                          defaultWidth: 60,
                          textAlign: "center",
                          filterEditor: () => {
                              const isAllSelected = this.data.length > 0 && this.data.every(data => this.props.selectedProductIds?.includes(data.id));
                              const indeterminate = !isAllSelected && this.data.some(data => this.props.selectedProductIds?.includes(data.id));
                              return (
                                  <Checkbox
                                      checked={isAllSelected}
                                      indeterminate={indeterminate}
                                      onChange={() => (isAllSelected ? this.props.onRemoveProducts?.(this.data) : this.props.onAddProducts?.(this.data))}
                                  />
                              );
                          },
                          filterable: true,
                          render: ({ data }: { data: ProductVariantChild }) => {
                              const isAdded = this.props.selectedProductIds?.includes(data.id);
                              return (
                                  <div>
                                      <Checkbox checked={isAdded} onChange={() => (isAdded ? this.props.onRemoveProducts?.([data]) : this.props.onAddProducts?.([data]))} />
                                  </div>
                              );
                          },
                      } as TypeColumn,
                  ]
                : []),
            { name: "id", header: I18n.formatMessage({ id: "pages.productCatalog.grid.column.id" }), defaultWidth: 120 },
            { name: "lfdnr", header: I18n.formatMessage({ id: "pages.productCatalog.grid.column.lfdnr" }), defaultWidth: 160 },
            {
                name: "ean",
                header: I18n.formatMessage({ id: "pages.productCatalog.grid.column.ean" }),
                defaultFlex: 1,
                minWidth: 160,
                render: ({ data }: { data: ProductVariantChild }) => {
                    return data?.ean ? data.ean.join(", ") : "";
                },
                filterDelay: Constants.filterDelayMS,
            },
            { name: "name", header: I18n.formatMessage({ id: "pages.productCatalog.grid.column.name" }), defaultFlex: 1, minWidth: 420 },
            {
                name: "product_category_id_main",
                header: I18n.formatMessage({ id: "pages.productCatalog.grid.column.main_categories" }),
                defaultWidth: 292,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: ProductCategoryUtils.getMainCategories(this.state.categorySelectItems).map(c => {
                        return { id: c.id, label: c.name };
                    }),
                },
                sortable: false,
                render: ({ data }: { data: ProductVariantChild }) => data.main_categories.map(c => c.name).join(", "),
            },
            {
                name: "product_category_id",
                header: I18n.formatMessage({ id: "pages.productCatalog.grid.column.categories" }),
                defaultWidth: 400,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: ProductCategoryUtils.getCategories(this.state.categorySelectItems).map(c => {
                        return { id: c.id, label: c.name };
                    }),
                },
                sortable: false,
                render: ({ data }: { data: ProductVariantChild }) => data.categories.map(c => c.name).join(", "),
            },
            {
                name: "is_visible",
                textAlign: "center",
                header: I18n.formatMessage({ id: "pages.productCatalog.grid.column.is_visible" }),
                defaultWidth: 120,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: [
                        { id: "true", label: I18n.formatMessage({ id: "common.form.isActive.true" }).toLowerCase() },
                        { id: "false", label: I18n.formatMessage({ id: "common.form.isActive.false" }).toLowerCase() },
                    ],
                },
                render: ({ data }: { data: ProductVariantChild }) => <ListItemActive isActive={data.is_visible} fromDate={data.active_from} />,
            },
        ];
    };

    private filterValues: FilterValue[] = [
        { name: "id", operator: "eq", type: "number" },
        { name: "lfdnr", operator: "eq", type: "number" },
        { name: "ean", operator: "contains", type: "string" },
        { name: "name", operator: "contains", type: "string" },
        { name: "product_category_id_main", operator: "inlist", type: "select" },
        { name: "product_category_id", operator: "inlist", type: "select" },
        { name: "is_visible", operator: "eq", type: "select" },
        { name: "is_description", operator: "eq", type: "select" },
        { name: "is_image", operator: "eq", type: "select" },
        { name: "price", operator: "inrange", type: "number" },
        { name: "stock_qty", operator: "inrange", type: "number" },
    ];

    private sortFieldToSortOrder = {
        [ProductSortField.id]: "id",
        [ProductSortField.lfdnr]: "lfdnr",
        [ProductSortField.name]: "name",
        [ProductSortField.ean]: "ean",
        [ProductSortField.price]: "price",
        [ProductSortField.is_visible]: "is_visible",
        [ProductSortField.is_image]: "is_image",
        [ProductSortField.is_description]: "is_description",
        [ProductSortField.stock_qty]: "stock_qty",
    };

    private data: ProductVariantChild[] = [];

    private dataSource = async (props: DataGridParams<ProductSort, ProductFilters>): Promise<DataSource<ProductVariantChild>> => {
        try {
            const result = await Api.listProducts({
                first: props.limit,
                page: props.page,
                filters: {
                    ...props.filters,
                    id: this.props.idsFilter ?? props.filters?.id,
                    product_brand_id: this.props.productBrandId ?? undefined,
                    product_category_id_main: props.filters?.product_category_id_main
                        ? `${props.filters?.product_category_id_main}`.split(",").map(id => Number.parseInt(`${id}`, 10))
                        : undefined,
                    product_category_id: props.filters?.product_category_id
                        ? `${props.filters?.product_category_id}`.split(",").map(id => Number.parseInt(`${id}`, 10))
                        : undefined,
                    price: props.filters?.price
                        ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          { min: Number.parseInt(props.filters.price.start, 10), max: Number.parseInt(props.filters.price.end, 10) }
                        : undefined,
                    stock_qty: props.filters?.stock_qty
                        ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          { min: Number.parseInt(props.filters.stock_qty.start, 10), max: Number.parseInt(props.filters.stock_qty.end, 10) }
                        : undefined,
                },
                sortBy: props.sortBy,
            });

            const resultData = result.data.map(d => {
                return {
                    __typename: "Product",
                    id: d.id,
                    lfdnr: d.lfdnr,
                    ean: d.ean,
                    name: d.name,
                    main_categories: d.main_categories,
                    categories: d.categories,
                    is_visible: d.is_visible,
                    active_from: d.active_from,
                    slug: d.slug,
                };
            });

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.data = resultData;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return { data: resultData, count: result.paginatorInfo.total };
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }
        return { data: [], count: 0 };
    };

    render() {
        return (
            <UnparsedDataGrid
                key={this.state.dataGridKey}
                className={this.props.classes.dataGrid}
                rowHeight={50}
                style={{ minHeight: this.props.minWidth ?? 800 }}
                dataSource={this.dataSource}
                filterValues={this.filterValues}
                columns={this.getColumns()}
                rowLink={undefined}
                activeCell={null}
                sortFieldToSortOrder={this.sortFieldToSortOrder}
            />
        );
    }
}

export default withSnackbar(withStyles(dataGridStyles, { withTheme: true })(ProductVariantChildDataGrid));
