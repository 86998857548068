import React, { ChangeEvent, useCallback, useState } from "react";
import { DefaultModal } from "@bigfish/admin-ui/components";
import { Box, Button, FormHelperText, TextField, Typography } from "@bigfish/admin-ui/core";
import styled from "styled-components";
import { I18n } from "I18n/I18n";
import { LocalGiftCard } from "./GiftCardOrderPrizeForm";
import { NumberUtils } from "Utils/NumberUtils";
import { useSnackbar } from "notistack";
import { Validator } from "Utils/Validator";
import { Constants } from "Utils/Constants";

type Props = {
    isVisible: boolean;
    title: React.ReactNode;
    editedItem: LocalGiftCard | "new";
    onClose: () => void;
    onSave: (value: string, mail_to: string) => void | Promise<void>;
};

export const GiftCardPrizeModal = (props: Props) => {
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setLoading] = useState(false);

    const [value, setValue] = useState<string>(props.editedItem !== "new" && props.editedItem.value ? `${props.editedItem.value}` : "");
    const [valueError, setValueError] = useState<string | null>(null);

    const [mailTo, setMailTo] = useState<string>(props.editedItem !== "new" && props.editedItem.mail_to ? props.editedItem.mail_to : "");
    const [mailToError, setMailToError] = useState<JSX.Element | string | null>(null);

    const fileReader = new FileReader();

    const onFunctionClick = useCallback(async (): Promise<void> => {
        setLoading(true);
        await props.onSave(value, mailTo);
        setLoading(false);
    }, [props, value, mailTo]);

    const onClose = useCallback((): void => {
        if (isLoading) {
            return;
        }
        props.onClose();
    }, [isLoading, props]);

    const mailToSeparator = mailTo.split(/\r?\n|\r|\n/g).length > 1 ? /\r?\n|\r|\n/g : ",";

    const getMailToSeparatedValues = (raw: string): string[] => {
        let formatted = raw.trim();
        if (formatted[formatted.length - 1] === ",") {
            formatted = formatted.slice(0, formatted.length - 1);
        }

        return formatted.split(mailToSeparator).map(f => f.trim());
    };

    const validateMailTo = (currentValue: string) => {
        const separatedValues = getMailToSeparatedValues(currentValue);

        ////////////////////////
        // Count
        ////////////////////////
        if (separatedValues.length > Constants.giftCardMaxEmails) {
            setMailToError(I18n.formatMessage({ id: "pages.giftCardOrderPrize.modalGiftCardEmailsError.count" }));
            return;
        }

        ////////////////////////
        // Delimiter
        ////////////////////////
        if (currentValue.length >= 3) {
            const valueSplit = currentValue.split(" ").filter(Boolean);

            if (valueSplit.length > 1) {
                const valueAllButLast = valueSplit.slice(0, valueSplit.length - 1);

                if (valueAllButLast.find(v => v.slice(-1) !== ",")) {
                    setMailToError(I18n.formatMessage({ id: "pages.giftCardOrderPrize.modalGiftCardEmailsError.delimiter" }));
                    return;
                }
            }
        }

        ////////////////////////
        // Format
        ////////////////////////
        const wrongFormatEmails = separatedValues.filter(v => !Validator.emailRE.test(v));
        if (wrongFormatEmails.length > 0) {
            setMailToError(
                <>
                    <span>{I18n.formatMessage({ id: `pages.giftCardOrderPrize.modalGiftCardEmailsError.format${wrongFormatEmails.length === 1 ? "Singular" : "Plural"}` })}</span>
                    <br />
                    {wrongFormatEmails.map(e => (
                        <>
                            <strong>{e}</strong>
                            <br />
                        </>
                    ))}
                </>
            );
            return;
        }

        ////////////////////////
        // Duplicate
        ////////////////////////
        const duplicateEmails = separatedValues
            .map((email: string) => {
                if (separatedValues.filter(checkForDuplicate => checkForDuplicate === email).length > 1) {
                    return email;
                }
                return null;
            })
            .filter(e => e !== null);

        if (duplicateEmails.length > 0) {
            setMailToError(
                <>
                    <span>
                        {I18n.formatMessage({
                            id: `pages.giftCardOrderPrize.modalGiftCardEmailsError.duplicate${duplicateEmails.length === 1 ? "Singular" : "Plural"}`,
                        })}
                    </span>
                    <br />
                    {duplicateEmails.map(e => (
                        <>
                            <strong>{e}</strong>
                            <br />
                        </>
                    ))}
                </>
            );
            return;
        }

        setMailToError(null);
    };

    const onCsvChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target?.files) return;
        const csvFile = e.target.files[0];
        if (!csvFile || csvFile.type !== "text/csv") return;

        if (csvFile) {
            fileReader.onload = function (event) {
                if (!event.target) return;
                const text = event.target.result;
                if (!text) return;

                let emailArray = text?.toString().replace(/\r/g, "").replace(/\n/g, ", ").trim();

                // Remove trailing comma
                if (emailArray[emailArray.length - 1] === ",") emailArray = emailArray.slice(0, emailArray.length - 1);

                validateMailTo(emailArray);
                setMailTo(emailArray);
            };

            fileReader.readAsText(csvFile);
        }
    };

    return (
        <GiftCardModalWrapper>
            <DefaultModal
                title={props.title}
                rightButtonsComponent={
                    <Button variant="contained" color="primary" onClick={onFunctionClick} disabled={isLoading || !value || !!valueError || !mailTo || !!mailToError}>
                        {I18n.formatMessage({ id: "common.save" })}
                    </Button>
                }
                leftButtonsComponent={
                    <Button variant="outlined" color="primary" onClick={onClose} disabled={isLoading}>
                        {I18n.formatMessage({ id: "common.cancel" })}
                    </Button>
                }
                open={props.isVisible}
                onClose={onClose}
            >
                <GiftCardModalInnerWrapper>
                    <TextField
                        type="number"
                        label={I18n.formatMessage({ id: "pages.giftCardOrderPrize.form.giftCard.valueLabel" })}
                        fullWidth
                        variant="outlined"
                        required
                        helperText={valueError || I18n.formatMessage({ id: "common.required" })}
                        error={!!valueError}
                        value={value}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (NumberUtils.isStringNumber(e.currentTarget.value)) {
                                const leadingZerosTrimmed = e.currentTarget.value.replace(/^[0]+/g, "");
                                const converted = Number.parseInt(leadingZerosTrimmed, 10);

                                setValue(converted ? `${converted}` : "");
                            }
                        }}
                        onBlur={(e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                            if (!e.currentTarget.value) {
                                setValueError(I18n.formatMessage({ id: "common.required" }));
                                return;
                            } else {
                                setValueError(null);
                            }
                            const rounded = NumberUtils.getRoundedValue(e.currentTarget.value);
                            if (!rounded) return;

                            // Notify user about the value being rounded
                            if (e.currentTarget.value !== `${rounded}`) {
                                enqueueSnackbar(
                                    I18n.formatMessage(
                                        { id: "pages.giftCardOrderPrize.modal.roundedNotification" },
                                        { notRounded: I18n.formatCurrency(Number.parseInt(e.currentTarget.value, 10)), rounded: I18n.formatCurrency(rounded) }
                                    ),
                                    { variant: "warning" }
                                );
                            }

                            setValue(`${NumberUtils.getRoundedValue(e.currentTarget.value)}`);
                        }}
                    />

                    <Box mt="30px" />

                    <TextField
                        type="text"
                        label={I18n.formatMessage({ id: "pages.giftCardOrderPrize.form.giftCard.mailToLabel" })}
                        fullWidth
                        multiline
                        variant="outlined"
                        error={!!mailToError}
                        value={mailTo}
                        onChange={e => {
                            validateMailTo(e.currentTarget.value);
                            setMailTo(e.currentTarget.value);
                        }}
                    />
                    <Box mt="5px" />
                    <Typography variant="body2">
                        <label htmlFor="input-file-csv">
                            <CsvUploadLabel>{I18n.formatMessage({ id: "pages.giftCardOrderPrize.form.giftCard.csvLabel" })}</CsvUploadLabel>
                        </label>
                        <CsvInput type="file" id="input-file-csv" name="file-csv" accept=".csv" onChange={onCsvChange} />
                    </Typography>
                    <FormHelperText className="helper-text-nowrap" error={!!mailToError}>
                        {mailToError || I18n.formatMessage({ id: "common.required" })}
                    </FormHelperText>
                </GiftCardModalInnerWrapper>
            </DefaultModal>
        </GiftCardModalWrapper>
    );
};

const CsvInput = styled.input`
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

const CsvUploadLabel = styled.span`
    color: ${Constants.color.basicLink};

    &:hover {
        cursor: pointer;
    }
`;

const GiftCardModalInnerWrapper = styled.div`
    padding-top: 20px;
    max-height: 500px;
    overflow-y: scroll;
`;

const GiftCardModalWrapper = styled.div`
    .MuiCard-root {
        overflow: hidden !important;
    }
`;
