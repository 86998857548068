import React from "react";
import { Button, Icon } from "@bigfish/admin-ui/core";
import { PageLayout } from "Components/PageLayout";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import FulfillmentListDataGrid from "./FulfillmentListDataGrid";
import { TitleBar, PageCard } from "@bigfish/admin-ui/components";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { I18n } from "I18n/I18n";
import { connect } from "react-redux";
import { ApplicationState } from "Redux/rootReducer";
import { AuthSelectors } from "Redux/selectors/authSelectors";
import { ShippingMethodTabItem, PermissionType } from "Api/graphql/admin/types";
import { Loading } from "Components/Loading";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";

type ReduxProps = {
    permissions: PermissionType[];
    isSuperadmin: boolean;
};

type Props = ReduxProps & RouteComponentProps & WithSnackbarProps;

type State = {
    shippingMethods: ShippingMethodTabItem[];
    currentTab: number;
    isLoading: boolean;
};

class FulfillmentListPage extends React.Component<Props, State> {
    public readonly state: State = {
        shippingMethods: [],
        currentTab: 0,
        isLoading: true,
    };

    public async componentDidMount(): Promise<void> {
        this.setState({ isLoading: true }, async () => {
            try {
                const { data: shippingMethods } = await Api.listShippingMethods({ first: 100 });
                this.setState({ shippingMethods });
            } catch (error) {
                if (error instanceof ApiError) {
                    this.props.enqueueSnackbar(error.message, { variant: "error" });
                }
            }
        });
        this.setState({ isLoading: false });
    }

    private onAddClick = (): void => {
        this.props.history.push(Path.fulfillmentCreate);
    };

    public render() {
        const { isLoading, shippingMethods } = this.state;

        if (isLoading) {
            return <Loading />;
        }

        return (
            <PageLayout>
                <TitleBar
                    title={I18n.formatMessage({ id: "pages.fulfillmentList.title" })}
                    rightButtonsComponent={
                        (this.props.isSuperadmin || this.props.permissions.includes(PermissionType.shipping_method_update)) && (
                            <Button startIcon={<Icon className="fa fa-plus-circle" />} variant="contained" size="medium" color="secondary" onClick={this.onAddClick}>
                                {I18n.formatMessage({ id: "pages.fulfillmentList.addButton" })}
                            </Button>
                        )
                    }
                />
                <PageCard.Container>
                    <FulfillmentListDataGrid shippingMethods={shippingMethods} />
                </PageCard.Container>
            </PageLayout>
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { permissions: AuthSelectors.getPermissions(state.auth), isSuperadmin: AuthSelectors.isSuperadmin(state.auth) };
};

export default withSnackbar(connect(mapStateToProps)(withRouter(FulfillmentListPage)));
