import { Box, Typography } from "@bigfish/admin-ui/core";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { ModalBannerForm, BannerFormValues, bannerValidator } from "./ModalBannerForm";
import Prompt from "Components/Prompt";
import { I18n } from "I18n/I18n";
import { BannerAlign, BannerType } from "Api/graphql/admin/types";
import { DateFormat, DateUtils } from "Utils/DateUtils";

type ComponentProps = {
    type: BannerType;
    onModalClose: () => void;
};

type Props = ComponentProps & WithSnackbarProps;

type State = {
    isCreateSucceed: boolean;
};

class ModalBannerCreatePage extends React.Component<Props, State> {
    public readonly state: State = {
        isCreateSucceed: false,
    };

    private onSubmit = async (values: BannerFormValues, formikHelpers: FormikHelpers<BannerFormValues>, isRedirectHandled?: boolean): Promise<boolean> => {
        try {
            await Api.createBanner({
                ...values,
                urls: values.urls.filter(u => !!u),
                active_from: values.active_from ? DateUtils.format(values.active_from, DateFormat.apiDateTime) : null,
                active_to: values.active_to ? DateUtils.format(values.active_to, DateFormat.apiDateTime) : null,
            });
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.banner.onCreateSubmit.succeed" }), { variant: "success" });
            if (!isRedirectHandled) {
                this.setState({ isCreateSucceed: true }, this.props.onModalClose);
            }
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.banner.onCreateSubmit.error" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
            return false;
        }
    };

    public render() {
        const { type } = this.props;

        const initialValues: BannerFormValues = {
            name: "",
            title: "",
            slider_title: "",
            type: type as BannerType,
            image: "",
            image_align: BannerAlign.center,
            image_title: "",
            image_mobile: "",
            image_mobile_title: "",
            button_title: "",
            target_url: "",
            target_popup: true,
            urls: [""],
            is_active: true,
            active_from: null,
            active_to: null,
            product_id: null,
            position: null,
        };

        return (
            <div className="modal-grey-background">
                <Typography variant="h1">{I18n.formatMessage({ id: "pages.banner.new" })}</Typography>
                <Formik initialValues={initialValues} onSubmit={this.onSubmit} validate={bannerValidator(FormType.create)} validateOnBlur={false}>
                    {props => (
                        <>
                            <ModalBannerForm formType={FormType.create} formProps={props} onClose={this.props.onModalClose} />
                            <Prompt when={props.dirty && !this.state.isCreateSucceed} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props, true)} />
                        </>
                    )}
                </Formik>
                <Box mt="40px" />
            </div>
        );
    }
}

export default withSnackbar(ModalBannerCreatePage);
