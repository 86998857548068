import { ActiveBreadcrumbItem, TitleBar } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { TodoForm, TodoFormValues } from "./TodoForm";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { Loading } from "Components/Loading";
import { PageLayout } from "Components/PageLayout";
import { Link } from "Components/Link";
import { Breadcrumbs } from "Components/Breadcrumbs";
import Prompt from "Components/Prompt";
import { Todo } from "Api/graphql/admin/types";
import { I18n } from "I18n/I18n";
import { Box } from "@bigfish/admin-ui/core";

type RouteParams = {
    id?: string;
};

type Props = WithSnackbarProps & RouteComponentProps<RouteParams>;

type State = {
    isLoading: boolean;
    todo: Todo | null;
};

class TodoEditPage extends React.Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        todo: null,
    };

    public async componentDidMount(): Promise<void> {
        if (!this.props.match.params.id) {
            return;
        }

        try {
            const todo = await Api.getTodoById(Number.parseInt(this.props.match.params.id, 10));
            this.setState({ todo, isLoading: false });
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
            this.props.history.push(Path.todoList);
        }
    }

    private onSubmit = async (values: TodoFormValues, formikHelpers: FormikHelpers<TodoFormValues>): Promise<boolean> => {
        try {
            const todo = await Api.updateTodo(Number.parseInt(this.state.todo!.id, 10), values);
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.todo.onEditSubmit.succeed" }), { variant: "success" });
            this.setState({ todo });
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.todo.onEditSubmit.error" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
        }
        return false;
    };

    private getBreadcrumb = () => {
        return (
            <Breadcrumbs>
                <Link to={Path.todoList} color="inherit">
                    {I18n.formatMessage({ id: "pages.todo.editBreadcrumb" })}
                </Link>
                <ActiveBreadcrumbItem aria-current="page">{this.state.todo?.id || ""}</ActiveBreadcrumbItem>
            </Breadcrumbs>
        );
    };

    public render() {
        const { isLoading, todo } = this.state;

        if (isLoading) {
            return <Loading breadcrumb={this.getBreadcrumb()} />;
        }

        if (!todo) {
            return <Redirect to={Path.todoList} />;
        }

        const initialValues: TodoFormValues = {
            status: todo.status,
            assigned_to: todo.assigned_to?.id,
            subject: todo.subject,
            message: todo.message,
        };

        return (
            <PageLayout breadcrumb={this.getBreadcrumb()}>
                <TitleBar title={todo.subject} />
                <Formik initialValues={initialValues} onSubmit={this.onSubmit} enableReinitialize={true}>
                    {props => (
                        <>
                            <TodoForm formType={FormType.edit} formProps={props} todo={todo} />
                            <Prompt when={props.dirty} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props)} />
                        </>
                    )}
                </Formik>
                <Box mt="40px" />
            </PageLayout>
        );
    }
}

export default withSnackbar(withRouter(TodoEditPage));
