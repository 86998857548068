import React, { useMemo } from "react";

import { Field, FieldProps, FormikProps } from "formik";

import { PageCard } from "@bigfish/admin-ui/components";
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, Icon, IconButton, InputAdornment, Radio, TextField, Typography } from "@bigfish/admin-ui/core";
import { Close, EventBusy } from "@bigfish/admin-ui/icons";

import { Banner, BannerAlign, BannerType } from "Api/graphql/admin/types";
import { CustomFormHelperText } from "Components/CustomFormHelperText";
import { Form, FormType } from "Components/Form";
import MediaLibraryImageInput from "Components/Inputs/MediaLibraryImageInput";
import { I18nHelpers } from "I18n/I18nHelpers";
import { I18n } from "Src/i18n/I18n";
import { Constants } from "Utils/Constants";
import { Validator } from "Utils/Validator";

import { BannerFormValues } from "./BannerForm";

type Props = {
    formType: FormType;
    formProps: FormikProps<BannerFormValues>;
    banner?: Banner;
};

export const GeneralTab = (props: Props) => {
    const isProductBanner = props.formProps.values.type === BannerType.product;

    const renderUrls = useMemo(() => {
        return (
            (props.formProps.values.type === BannerType.product_list || props.formProps.values.type === BannerType.product) && (
                <div>
                    {props.formProps.values.urls.map((url: string, index: number) => {
                        return (
                            <Grid key={`url-${index}`} container spacing={3}>
                                <Grid item xs={11}>
                                    <Field name={`urls[${index}]`}>
                                        {({ field, meta }: FieldProps) => (
                                            <TextField
                                                type="text"
                                                label={I18n.formatMessage({ id: "pages.banner.form.urlLabel" })}
                                                fullWidth
                                                variant="outlined"
                                                required
                                                helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                                                error={meta.touched && !!meta.error}
                                                {...field}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton
                                        onClick={() => {
                                            const newUrls = [...props.formProps.values.urls];
                                            newUrls.splice(index, 1);
                                            props.formProps.setFieldValue("urls", newUrls.filter(Boolean));
                                            props.formProps.setFieldTouched("urls", true);
                                        }}
                                    >
                                        <Close />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        );
                    })}
                    <Box mt="15px" />
                    <FormHelperText>{I18n.formatMessage({ id: "pages.banner.form.urlHelperText" })}</FormHelperText>
                    <Box mt="30px" />
                    <Button
                        startIcon={<Icon className="fa fa-plus-circle" />}
                        variant="outlined"
                        size="medium"
                        color="secondary"
                        onClick={() => {
                            props.formProps.setFieldValue("urls", [...props.formProps.values.urls, ""]);
                        }}
                    >
                        {I18n.formatMessage({ id: "common.add" })}
                    </Button>
                </div>
            )
        );
    }, [props.formProps.values.type, props.formProps.values.urls]);

    const renderAlignments = useMemo(() => {
        return (
            props.formProps.values.type === BannerType.normal && (
                <div>
                    <Box mt="60px" />
                    <Typography color="textSecondary" gutterBottom>
                        {I18n.formatMessage({ id: "pages.banner.form.imageAlignLabel" })}
                    </Typography>
                    <Box ml="15px">
                        <FormControlLabel
                            checked={props.formProps.values.image_align === BannerAlign.center}
                            onChange={() => {
                                props.formProps.setFieldValue("image_align", BannerAlign.center);
                            }}
                            control={<Radio />}
                            label={I18n.formatMessage({ id: "enums.bannerAlign.center" })}
                        />
                        <FormControlLabel
                            checked={props.formProps.values.image_align === BannerAlign.right}
                            onChange={() => {
                                props.formProps.setFieldValue("image_align", BannerAlign.right);
                            }}
                            control={<Radio />}
                            label={I18n.formatMessage({ id: "enums.bannerAlign.right" })}
                        />
                        <FormControlLabel
                            checked={props.formProps.values.image_align === BannerAlign.left}
                            onChange={() => {
                                props.formProps.setFieldValue("image_align", BannerAlign.left);
                            }}
                            control={<Radio />}
                            label={I18n.formatMessage({ id: "enums.bannerAlign.left" })}
                        />
                    </Box>
                </div>
            )
        );
    }, [props.formProps.values.type, props.formProps.values.image_align]);

    return (
        <div>
            {props.formType === FormType.edit && <Box mt="30px" />}
            <PageCard.Heading title={I18n.formatMessage({ id: "common.form.basicData" })} />
            <Box mt="30px" />
            {props.formType === FormType.edit && (
                <div>
                    <Typography color="textSecondary" gutterBottom>
                        {I18n.formatMessage({ id: "pages.banner.form.idLabel" })}
                    </Typography>
                    <Typography variant="subtitle1">{props.banner?.id}</Typography>
                </div>
            )}

            <Box mt="30px" />

            <Field name="name" validate={I18nHelpers.formatValidator(Validator.required)}>
                {({ field, meta }: FieldProps) => (
                    <TextField
                        type="text"
                        label={I18n.formatMessage({ id: "pages.banner.form.nameLabel" })}
                        fullWidth
                        variant="outlined"
                        required
                        helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                        error={meta.touched && !!meta.error}
                        {...field}
                    />
                )}
            </Field>

            <Box mt="30px" />

            {renderUrls}

            <Box mt="30px" />

            {!isProductBanner && (
                <>
                    <PageCard.Heading title={I18n.formatMessage({ id: "common.form.content" })} />

                    <Field name="title">
                        {({ field, meta }: FieldProps) => (
                            <TextField
                                type="text"
                                label={I18n.formatMessage({ id: "pages.banner.form.titleLabel" })}
                                fullWidth
                                variant="outlined"
                                required
                                helperText={Form.getHelperText(meta, "")}
                                error={meta.touched && !!meta.error}
                                {...field}
                            />
                        )}
                    </Field>

                    <Box mt="30px" />

                    <Field name="slider_title">
                        {({ field, meta }: FieldProps) => (
                            <TextField
                                type="text"
                                label={I18n.formatMessage({ id: "pages.banner.form.sliderTitleLabel" })}
                                fullWidth
                                variant="outlined"
                                required
                                helperText={Form.getHelperText(meta, "")}
                                error={meta.touched && !!meta.error}
                                {...field}
                            />
                        )}
                    </Field>

                    <Box mt="30px" />

                    <Field name="button_title">
                        {({ field, meta }: FieldProps) => (
                            <TextField
                                type="text"
                                label={I18n.formatMessage({ id: "pages.banner.form.buttonTitleLabel" })}
                                fullWidth
                                variant="outlined"
                                required
                                helperText={Form.getHelperText(meta, "")}
                                error={meta.touched && !!meta.error}
                                {...field}
                            />
                        )}
                    </Field>

                    <Box mt="30px" />

                    <Typography variant="body1" component="h3" color="textSecondary">
                        {I18n.formatMessage({ id: "pages.banner.form.imageLabel" })}
                    </Typography>
                    <Box className="image-section-root">
                        <Box className="image-section-image-field">
                            <div className="og_image_url_wrapper">
                                <Field name="image" validate={I18nHelpers.formatValidator(Validator.required)}>
                                    {({ field, meta }: FieldProps) => (
                                        <div>
                                            <MediaLibraryImageInput
                                                id="banner-image-url"
                                                {...field}
                                                name="image"
                                                value={field.value}
                                                onChange={(value: string | null) => {
                                                    props.formProps.setFieldValue("image", value);
                                                    props.formProps.setFieldTouched("image", true);
                                                }}
                                            />
                                            <CustomFormHelperText
                                                meta={meta}
                                                defaultHelperText={<div style={{ whiteSpace: "nowrap" }}>{I18n.formatMessage({ id: "pages.banner.form.imageHelperText" })}</div>}
                                            />
                                        </div>
                                    )}
                                </Field>
                            </div>
                        </Box>
                        <Box className="image-section-alt-field">
                            <Field name="image_title" validate={I18nHelpers.formatValidator(Validator.altTitleMaxLength)}>
                                {({ field, meta }: FieldProps) => (
                                    <TextField
                                        type="text"
                                        label={I18n.formatMessage({ id: "common.form.altTitleTag" })}
                                        fullWidth
                                        variant="outlined"
                                        inputProps={{
                                            maxLength: Constants.altTitleMaxLength,
                                        }}
                                        {...field}
                                        helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.maxCharacter" }, { max: Constants.altTitleMaxLength }))}
                                        error={meta.touched && !!meta.error}
                                    />
                                )}
                            </Field>
                        </Box>
                    </Box>

                    {renderAlignments}

                    <Box mt="60px" />

                    <Typography variant="body1" component="h3" color="textSecondary">
                        {I18n.formatMessage({ id: "pages.banner.form.imageMobileLabel" })}
                    </Typography>
                    <Box className="image-section-root">
                        <Box className="image-section-image-field">
                            <div className="og_image_url_wrapper">
                                <Field name="image_mobile" validate={I18nHelpers.formatValidator(Validator.required)}>
                                    {({ field, meta }: FieldProps) => (
                                        <div>
                                            <MediaLibraryImageInput
                                                id="banner-image-mobile-url"
                                                {...field}
                                                name="image_mobile"
                                                value={field.value}
                                                onChange={(value: string | null) => {
                                                    props.formProps.setFieldValue("image_mobile", value);
                                                    props.formProps.setFieldTouched("image_mobile", true);
                                                }}
                                            />
                                            <CustomFormHelperText
                                                meta={meta}
                                                defaultHelperText={
                                                    <div style={{ whiteSpace: "nowrap" }}>{I18n.formatMessage({ id: "pages.banner.form.imageMobileHelperText" })}</div>
                                                }
                                            />
                                        </div>
                                    )}
                                </Field>
                            </div>
                        </Box>
                        <Box className="image-section-alt-field">
                            <Field name="image_mobile_title" validate={I18nHelpers.formatValidator(Validator.altTitleMaxLength)}>
                                {({ field, meta }: FieldProps) => (
                                    <TextField
                                        type="text"
                                        label={I18n.formatMessage({ id: "common.form.altTitleTag" })}
                                        fullWidth
                                        variant="outlined"
                                        inputProps={{
                                            maxLength: Constants.altTitleMaxLength,
                                        }}
                                        {...field}
                                        helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.maxCharacter" }, { max: Constants.altTitleMaxLength }))}
                                        error={meta.touched && !!meta.error}
                                    />
                                )}
                            </Field>
                        </Box>
                    </Box>

                    <Box mt="60px" />

                    <Grid container spacing={3}>
                        <Grid item xs={10}>
                            <Field
                                name="target_url"
                                validate={props.formProps.values.type === BannerType.product_list ? I18nHelpers.formatValidator(Validator.required) : undefined}
                            >
                                {({ field, meta }: FieldProps) => (
                                    <TextField
                                        type="text"
                                        label={I18n.formatMessage({ id: "pages.banner.form.targetUrlLabel" })}
                                        fullWidth
                                        variant="outlined"
                                        required
                                        helperText={Form.getHelperText(
                                            meta,
                                            props.formProps.values.type === BannerType.product_list
                                                ? `${I18n.formatMessage({ id: "common.required" })} ${I18n.formatMessage({ id: "pages.banner.form.targetUrlHelperText" })}`
                                                : ""
                                        )}
                                        error={meta.touched && !!meta.error}
                                        {...field}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={2}>
                            <Field name="target_popup">
                                {({ field, meta }: FieldProps) =>
                                    field.value !== undefined && (
                                        <Box mt="15px" ml="10px">
                                            <FormControl error={meta.touched && !!meta.error}>
                                                <FormControlLabel
                                                    control={<Checkbox {...field} checked={field.value} />}
                                                    label={I18n.formatMessage({ id: "pages.banner.form.targetPopupLabel" })}
                                                />
                                            </FormControl>
                                            <CustomFormHelperText meta={meta} />
                                        </Box>
                                    )
                                }
                            </Field>
                        </Grid>
                    </Grid>

                    <Box mt="30px" />
                </>
            )}

            <PageCard.Heading title={I18n.formatMessage({ id: "common.form.settings" })} />
            <Typography variant="body1" component="h3" color="textSecondary">
                {I18n.formatMessage({ id: "pages.banner.form.statusLabel" })}
            </Typography>
            <Field name="is_active">
                {({ field, meta }: FieldProps) =>
                    field.value !== undefined && (
                        <Box mt="15px" ml="10px">
                            <FormControl error={meta.touched && !!meta.error}>
                                <FormControlLabel control={<Checkbox {...field} checked={field.value} />} label={I18n.formatMessage({ id: "pages.banner.form.isActiveLabel" })} />
                            </FormControl>
                            <CustomFormHelperText meta={meta} />
                        </Box>
                    )
                }
            </Field>

            <Box mt="30px" />

            {isProductBanner && (
                <div>
                    <Grid container>
                        <Grid item>
                            <Box mr="30px">
                                <Field name="product_id" validate={isProductBanner ? I18nHelpers.formatValidator(Validator.required) : undefined}>
                                    {({ field, meta }: FieldProps) => (
                                        <TextField
                                            type="number"
                                            label={I18n.formatMessage({ id: "pages.banner.form.productIdLabel" })}
                                            variant="outlined"
                                            required
                                            helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                                            error={meta.touched && !!meta.error}
                                            {...field}
                                        />
                                    )}
                                </Field>
                            </Box>
                        </Grid>

                        <Box mt="30px" />

                        <Grid item>
                            <Field name="position" validate={isProductBanner ? I18nHelpers.formatValidator(Validator.required) : undefined}>
                                {({ field, meta }: FieldProps) => (
                                    <TextField
                                        type="number"
                                        label={I18n.formatMessage({ id: "pages.banner.form.positionLabel" })}
                                        variant="outlined"
                                        required
                                        helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                                        error={meta.touched && !!meta.error}
                                        {...field}
                                    />
                                )}
                            </Field>
                        </Grid>
                    </Grid>
                    <Box mt="30px" />
                </div>
            )}

            <Grid container>
                <Grid item>
                    <Box mr="30px">
                        <Field name="active_from">
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    id="active_from"
                                    label={I18n.formatMessage({ id: "pages.banner.form.activeFromLabel" })}
                                    type="datetime-local"
                                    className={`datetime-local ${props.formProps.values.active_from ? "datetime-local-filled" : ""}`}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        endAdornment: props.formProps.values.active_from ? (
                                            <InputAdornment
                                                position="end"
                                                onClick={() => {
                                                    props.formProps.setFieldValue("active_from", "");
                                                    props.formProps.setFieldTouched("active_from", true);
                                                }}
                                            >
                                                <EventBusy />
                                            </InputAdornment>
                                        ) : null,
                                    }}
                                    {...field}
                                    value={field.value}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        props.formProps.setTouched({ ...props.formProps.touched, active_from: true });
                                        field.onChange(e);
                                    }}
                                    error={meta.touched && !!meta.error}
                                    helperText={Form.getHelperText(meta, "")}
                                />
                            )}
                        </Field>
                    </Box>
                </Grid>
                <Grid item>
                    <Field name="active_to">
                        {({ field, meta }: FieldProps) => (
                            <TextField
                                id="active_to"
                                label={I18n.formatMessage({ id: "pages.banner.form.activeToLabel" })}
                                className={`datetime-local ${props.formProps.values.active_to ? "datetime-local-filled" : ""}`}
                                type="datetime-local"
                                placeholder=""
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    endAdornment: props.formProps.values.active_to ? (
                                        <InputAdornment
                                            position="end"
                                            onClick={() => {
                                                props.formProps.setFieldValue("active_to", "");
                                                props.formProps.setFieldTouched("active_to", true);
                                            }}
                                        >
                                            <EventBusy />
                                        </InputAdornment>
                                    ) : null,
                                }}
                                {...field}
                                value={field.value}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    props.formProps.setTouched({ ...props.formProps.touched, active_to: true });
                                    field.onChange(e);
                                }}
                                error={meta.touched && !!meta.error}
                                helperText={Form.getHelperText(meta, "")}
                            />
                        )}
                    </Field>
                </Grid>
            </Grid>
        </div>
    );
};
