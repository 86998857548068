import { gql } from "@apollo/client";
import { bannerNormalFragment } from "../fragments/bannerNormalFragment";
import { bannerProductFragment } from "../fragments/bannerProductFragment";
import { bannerProductListFragment } from "../fragments/bannerProductListFragment";

export const getBannerByIdQuery = gql`
    query getBannerById($filters: BannerFilters, $first: Int!) {
        listBanners(first: $first, filters: $filters) {
            data {
                ... on BannerNormal {
                    ...BannerNormal
                }
                ... on BannerProductList {
                    ...BannerProductList
                }
                ... on BannerProduct {
                    ...BannerProduct
                }
            }
        }
    }
    ${bannerNormalFragment}
    ${bannerProductListFragment}
    ${bannerProductFragment}
`;
