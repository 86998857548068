import React from "react";

import { withSnackbar, WithSnackbarProps } from "notistack";
import { RouteComponentProps } from "react-router";

import { PageCard, TabsComponent, TitleBar } from "@bigfish/admin-ui/components";
import { Box } from "@bigfish/admin-ui/core";

import { PaymentMethodTabItem } from "Api/graphql/admin/types";
import { Api } from "Api/Api";
import { Loading } from "Components/Loading";
import { PageLayout } from "Components/PageLayout";
import { I18n } from "I18n/I18n";
import { Path } from "Utils/Path";

import PaymentMethodEditPage from "./PaymentMethodEditPage";

type RouteParams = {
    id?: string;
};

type Props = WithSnackbarProps & RouteComponentProps<RouteParams>;

type State = {
    paymentMethods: PaymentMethodTabItem[];
    currentTab: number;
    isLoading: boolean;
};

class PaymentMethodListPage extends React.Component<Props, State> {
    public readonly state: State = {
        paymentMethods: [],
        currentTab: 0,
        isLoading: true,
    };

    public async componentDidMount(): Promise<void> {
        this.setState({ isLoading: true }, async () => {
            try {
                const { data: paymentMethods } = await Api.listPaymentMethods({ first: 9999 });
                this.setState({ paymentMethods }, () => {
                    if (paymentMethods.length > 0) {
                        const currentTab = this.state.paymentMethods.findIndex(pm => pm.id === this.props.match.params.id);
                        this.setState({ currentTab: currentTab !== -1 ? currentTab : 0 }, () => {
                            this.props.history.push(Path.paymentMethodEdit(this.props.match.params.id ?? paymentMethods[0].id));
                        });
                    }
                });
            } catch (error) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        });
        this.setState({ isLoading: false });
    }

    public render() {
        const changeTab = (_event: React.ChangeEvent<{}>, newTab: number) => {
            this.setState({ currentTab: newTab }, () => {
                this.props.history.push(Path.paymentMethodEdit(this.state.paymentMethods[newTab].id));
            });
        };

        const { isLoading, paymentMethods, currentTab } = this.state;
        const { id } = this.props.match.params;

        if (isLoading) {
            return <Loading />;
        }

        return (
            <PageLayout>
                <TitleBar title={I18n.formatMessage({ id: "pages.paymentMethodList.title" })} />
                <PageCard.Container>
                    {paymentMethods.length > 0 && (
                        <TabsComponent
                            ariaLabel="payment-method-list-page-tabs"
                            tabs={paymentMethods.map(pm => {
                                return {
                                    label: pm.name,
                                    id: pm.id,
                                    content: id ? <PaymentMethodEditPage key={id} id={id} /> : null,
                                };
                            })}
                            currentTab={currentTab}
                            changeTab={changeTab}
                        />
                    )}
                </PageCard.Container>
                <Box mt="40px" />
            </PageLayout>
        );
    }
}

export default withSnackbar(PaymentMethodListPage);
