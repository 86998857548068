import { Button, Icon } from "@bigfish/admin-ui/core";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { DocumentModalListItem } from "Api/graphql/admin/types";
import { I18n } from "I18n/I18n";
import { withSnackbar, WithSnackbarProps } from "notistack";
import React from "react";

type ComponentProps = {
    label: string;
    color: "primary" | "secondary";
    variant: "contained" | "outlined";
    onUploadSucceed: (document: DocumentModalListItem) => void;
};

type Props = ComponentProps & WithSnackbarProps;

class DocumentUploader extends React.Component<Props> {
    private fileInputRef = React.createRef<HTMLInputElement>();

    private onAddClick = (): void => {
        this.fileInputRef.current?.click();
    };

    private readonly onFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;

        if (!files || files.length === 0) {
            return;
        }

        const file = files[0];
        try {
            const document = await Api.uploadDocument(file);
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.documentList.saveSucceed" }), { variant: "success" });
            this.props.onUploadSucceed(document);
        } catch (error) {
            if (error instanceof ApiError) {
                const message = error.hasFieldError() ? error.fieldErrors[0].message : error.message;
                this.props.enqueueSnackbar(message, { variant: "error" });
            }
        }

        // Remove last value to be able to retry upload
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.fileInputRef.current.value = null;
    };

    public render() {
        return (
            <>
                <Button startIcon={<Icon className="fa fa-cloud-upload-alt" />} variant={this.props.variant} size="medium" color={this.props.color} onClick={this.onAddClick}>
                    {this.props.label}
                </Button>
                <input type="file" hidden ref={this.fileInputRef} onChange={this.onFileUpload} accept="application/pdf" />
            </>
        );
    }
}

export default withSnackbar(DocumentUploader);
