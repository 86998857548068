import "../Product/styles.css";

import React from "react";

import { Field, FieldProps, FormikProps } from "formik";
import { arrayMove, SortEnd } from "react-sortable-hoc";

import { PageCard } from "@bigfish/admin-ui/components";
import { Box, Checkbox, FormControl, FormControlLabel, Grid, InputAdornment, TextField, Typography } from "@bigfish/admin-ui/core";
import { EventBusy } from "@bigfish/admin-ui/icons";

import { Product, ProductBrandSelectItem } from "Api/graphql/admin/types";
import { Api } from "Api/Api";
import { CustomFormHelperText } from "Components/CustomFormHelperText";
import FetchedSelect from "Components/FetchedSelect/FetchedSelect";
import { Form, FormType } from "Components/Form";
import MediaLibraryImageInput from "Components/Inputs/MediaLibraryImageInput";
import RichTextEditor from "Components/Inputs/RichTextEditor/RichTextEditor";
import { I18n } from "I18n/I18n";
import { I18nHelpers } from "I18n/I18nHelpers";
import { Constants } from "Utils/Constants";
import { Validator } from "Utils/Validator";

import ProductImagesSortList from "../Product/ProductImagesSortList";
import { BundleProductsDataGrid } from "./BundleProductsDataGrid";
import { ProductBundleFormValues } from "./ProductBundleForm";
import { useSnackbar } from "notistack";
import { ProductImageInputWithGenericUrl } from "Utils/ApiUtils";

type Props = {
    formType: FormType;
    product?: Product;
    formProps: FormikProps<ProductBundleFormValues>;
};

export const GeneralTab = (props: Props) => {
    const { enqueueSnackbar } = useSnackbar();

    return (
        <div>
            <Box mt="30px" />
            <PageCard.Heading title={I18n.formatMessage({ id: "common.form.basicData" })} />

            {props.formType === FormType.edit && (
                <>
                    <Typography color="textSecondary" gutterBottom>
                        {I18n.formatMessage({ id: "pages.product.form.tabs.general.id" })}
                    </Typography>
                    <Typography variant="body2">{props.product?.id}</Typography>
                </>
            )}

            <Field name="name" validate={I18nHelpers.formatValidator(Validator.required)}>
                {({ field, meta }: FieldProps) => (
                    <Box mt="30px">
                        <TextField
                            type="text"
                            label={I18n.formatMessage({ id: "pages.productBundle.form.nameLabel" })}
                            fullWidth
                            variant="outlined"
                            required
                            helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                            error={meta.touched && !!meta.error}
                            {...field}
                        />
                    </Box>
                )}
            </Field>

            <Field name="bundle_promotion_id" validate={I18nHelpers.formatValidator(Validator.required)}>
                {({ field, meta }: FieldProps) => (
                    <Box mt="30px">
                        <TextField
                            type="number"
                            label={I18n.formatMessage({ id: "pages.productBundle.form.promotionLabel" })}
                            fullWidth
                            variant="outlined"
                            required
                            helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                            error={meta.touched && !!meta.error}
                            {...field}
                        />
                    </Box>
                )}
            </Field>

            <Field name="slug" validate={I18nHelpers.formatValidator(Validator.required)}>
                {({ field, meta }: FieldProps) => (
                    <Box mt="30px">
                        <TextField
                            type="text"
                            label={I18n.formatMessage({ id: "pages.productBundle.form.slugLabel" })}
                            fullWidth
                            variant="outlined"
                            required
                            helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                            error={meta.touched && !!meta.error}
                            {...field}
                        />
                    </Box>
                )}
            </Field>

            <Field name="product_brand_id">
                {({ field, meta }: FieldProps) => (
                    <Box mt="30px">
                        <FetchedSelect
                            key="value-product-brand-id"
                            label={I18n.formatMessage({ id: "pages.productBundle.form.brandLabel" })}
                            fullWidth
                            variant="outlined"
                            getTitle={(productBrand: ProductBrandSelectItem) => productBrand.name ?? ""}
                            fetch={async (): Promise<ProductBrandSelectItem[]> => {
                                try {
                                    const productBrands = await Api.listProductBrandSelectItems({ first: 9999 });
                                    return [...productBrands.data].sort((a, b) => (!!a.name && !!b.name && a.name > b.name ? 1 : -1));
                                } catch (error) {
                                    enqueueSnackbar(error.message, { variant: "error" });
                                    return [];
                                }
                            }}
                            helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.optional" }))}
                            {...field}
                            error={meta.touched && !!meta.error}
                            deletable={!!props.formProps.values.product_brand_id}
                            onDelete={() => {
                                props.formProps.setFieldValue("product_brand_id", null);
                                props.formProps.setFieldTouched("product_brand_id", true);
                            }}
                        />
                    </Box>
                )}
            </Field>

            <Box mt="30px" />

            <Typography color="textSecondary" gutterBottom>
                {I18n.formatMessage({ id: "pages.productBundle.form.descriptionLabel" })}
            </Typography>
            <Field name="description">
                {({ field }: FieldProps) => (
                    <RichTextEditor
                        config="productDescription"
                        {...field}
                        onChange={(content: string) => {
                            props.formProps.setFieldValue("description", content);
                        }}
                    />
                )}
            </Field>

            <Box mt="30px" />

            <PageCard.Heading title={I18n.formatMessage({ id: "pages.productBundle.form.imagesHeading" })} />

            <Field name="images">
                {({ field }: FieldProps) => (
                    <ProductImagesSortList
                        value={field.value}
                        axis="xy"
                        distance={10}
                        onSortEnd={({ oldIndex, newIndex }: SortEnd) => {
                            const newImages = arrayMove(field.value, oldIndex, newIndex);
                            props.formProps.setFieldValue("images", newImages);
                        }}
                        onChange={(images: Array<ProductImageInputWithGenericUrl | null>) => props.formProps.setFieldValue("images", images)}
                    />
                )}
            </Field>

            <Box mt="30px" />

            {props.formType === FormType.edit && <PageCard.Heading title={I18n.formatMessage({ id: "pages.productBundle.form.productsHeading" })} />}

            {props.product && <BundleProductsDataGrid products={props.product.children!} />}

            <Box mt="30px" />

            <PageCard.Heading title={I18n.formatMessage({ id: "common.form.settings" })} />

            <Typography variant="body1" component="h3" color="textSecondary">
                {I18n.formatMessage({ id: "pages.productBundle.form.statusLabel" })}
            </Typography>

            <Field name="is_active">
                {({ field, meta }: FieldProps) =>
                    field.value !== undefined && (
                        <Box mt="15px" ml="10px">
                            <FormControl error={meta.touched && !!meta.error}>
                                <FormControlLabel
                                    control={<Checkbox {...field} checked={field.value} />}
                                    label={I18n.formatMessage({ id: "pages.productBundle.form.isActiveLabel" })}
                                />
                            </FormControl>
                            <CustomFormHelperText meta={meta} />
                        </Box>
                    )
                }
            </Field>

            <Box mt="30px" />

            <Grid container>
                <Grid item>
                    <Box mr="30px">
                        <Field name="active_from">
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    id="active_from"
                                    label={I18n.formatMessage({ id: "pages.productList.form.activeFromLabel" })}
                                    type="datetime-local"
                                    className={`datetime-local ${props.formProps.values.active_from ? "datetime-local-filled" : ""}`}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        endAdornment: props.formProps.values.active_from ? (
                                            <InputAdornment
                                                position="end"
                                                onClick={() => {
                                                    props.formProps.setFieldValue("active_from", "");
                                                    props.formProps.setFieldTouched("active_from", true);
                                                }}
                                            >
                                                <EventBusy />
                                            </InputAdornment>
                                        ) : null,
                                    }}
                                    {...field}
                                    value={field.value}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        props.formProps.setTouched({ ...props.formProps.touched, active_from: true });
                                        field.onChange(e);
                                    }}
                                    error={meta.touched && !!meta.error}
                                    helperText={Form.getHelperText(meta, "")}
                                />
                            )}
                        </Field>
                    </Box>
                </Grid>
                <Grid item>
                    <Field name="active_to">
                        {({ field, meta }: FieldProps) => (
                            <TextField
                                id="active_to"
                                label={I18n.formatMessage({ id: "pages.productList.form.activeToLabel" })}
                                className={`datetime-local ${props.formProps.values.active_to ? "datetime-local-filled" : ""}`}
                                type="datetime-local"
                                placeholder=""
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    endAdornment: props.formProps.values.active_to ? (
                                        <InputAdornment
                                            position="end"
                                            onClick={() => {
                                                props.formProps.setFieldValue("active_to", "");
                                                props.formProps.setFieldTouched("active_to", true);
                                            }}
                                        >
                                            <EventBusy />
                                        </InputAdornment>
                                    ) : null,
                                }}
                                {...field}
                                value={field.value}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    props.formProps.setTouched({ ...props.formProps.touched, active_to: true });
                                    field.onChange(e);
                                }}
                                error={meta.touched && !!meta.error}
                                helperText={Form.getHelperText(meta, "")}
                            />
                        )}
                    </Field>
                </Grid>
            </Grid>

            <Box mt="30px" />
            <PageCard.Heading title={I18n.formatMessage({ id: "common.form.metaData" })} />

            <div>
                <Typography variant="body1" component="h3" color="textSecondary">
                    {I18n.formatMessage({ id: "pages.productBundle.form.ogImageLabel" })}
                </Typography>
                <Box className="image-section-root">
                    <Box className="image-section-image-field">
                        <div className="og_image_url_wrapper">
                            <Field name="og_image">
                                {({ field }: FieldProps) => (
                                    <MediaLibraryImageInput
                                        id="product-category-og_image_url"
                                        name="og_image"
                                        value={field.value}
                                        onChange={(value: string | null) => {
                                            props.formProps.setFieldValue("og_image", value);
                                            props.formProps.setFieldTouched("og_image", true);
                                        }}
                                    />
                                )}
                            </Field>
                        </div>
                    </Box>
                    <Box className="image-section-title-tag-field">
                        <Field name="og_title" validate={I18nHelpers.formatValidator(Validator.ogTitleMaxLength)}>
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "pages.productBundle.form.ogTitleLabel" })}
                                    fullWidth
                                    variant="outlined"
                                    error={meta.touched && !!meta.error}
                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.maxCharacter" }, { max: Constants.ogTitleMaxLength }))}
                                    inputProps={{
                                        maxLength: Constants.ogTitleMaxLength,
                                    }}
                                    {...field}
                                />
                            )}
                        </Field>
                    </Box>
                    <Box className="image-section-og-description-field">
                        <Field name="og_description" validate={I18nHelpers.formatValidator(Validator.ogDescriptionMaxLength)}>
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "pages.productBundle.form.ogDescriptionLabel" })}
                                    fullWidth
                                    variant="outlined"
                                    error={meta.touched && !!meta.error}
                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.maxCharacter" }, { max: Constants.ogDescriptionMaxLength }))}
                                    inputProps={{
                                        maxLength: Constants.ogDescriptionMaxLength,
                                    }}
                                    {...field}
                                />
                            )}
                        </Field>
                    </Box>
                    <Box className="image-section-meta-description-field">
                        <Field name="meta_description" validate={I18nHelpers.formatValidator(Validator.metaDescriptionMaxLength)}>
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "pages.productBundle.form.metaDescriptionLabel" })}
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    error={meta.touched && !!meta.error}
                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.maxCharacter" }, { max: Constants.metaDescriptionMaxLength }))}
                                    inputProps={{
                                        maxLength: Constants.metaDescriptionMaxLength,
                                    }}
                                    {...field}
                                />
                            )}
                        </Field>
                    </Box>
                </Box>
            </div>
        </div>
    );
};
