import React from "react";
import { PageCard, TitleBar } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { Admin } from "Api/graphql/admin/types";
import { Form } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import { connect, DispatchProp } from "react-redux";
import { AuthActions } from "Redux/actions/authActions";
import { ApplicationState } from "Redux/rootReducer";
import { I18n } from "Src/i18n/I18n";
import { MyProfileForm, MyProfileFormValues } from "./MyProfileForm";
import { PageLayout } from "Components/PageLayout";
import Prompt from "Components/Prompt";
import ChangePasswordModal from "./ChangePasswordModal";
import { Admin_roles } from "Api/graphql/auth/types";

type ReduxProps = {
    admin: Admin;
    roles: Admin_roles[];
};

type Props = ReduxProps & DispatchProp & WithSnackbarProps;

type State = {
    isChangePasswordModalVisible: boolean;
};

class MyProfilePage extends React.Component<Props> {
    public readonly state: State = {
        isChangePasswordModalVisible: false,
    };

    private onSubmit = async (values: MyProfileFormValues, formikHelpers: FormikHelpers<MyProfileFormValues>): Promise<boolean> => {
        try {
            const result = await Api.updateAdminMe({ name: values.name });
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.myProfile.saveSucceed" }), { variant: "success" });
            this.props.dispatch(AuthActions.updateAdmin(result));
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.myProfile.saveFailed" }), { variant: "error" });
            Form.submitFailed(formikHelpers, error);
        }
        return false;
    };

    private shouldBlock = (): boolean => {
        if (this.state.isChangePasswordModalVisible) {
            this.setState({ isChangePasswordModalVisible: false });
            return true;
        }
        return false;
    };

    private onChangePasswordButtonClick = (): void => {
        this.setState({ isChangePasswordModalVisible: true });
    };

    private onCloseModal = (): void => {
        this.setState({ isChangePasswordModalVisible: false });
    };

    public render() {
        const initialValues: MyProfileFormValues = {
            name: this.props.admin.name,
        };

        return (
            <PageLayout>
                <TitleBar title={this.props.admin.name} />
                <PageCard.Container>
                    <Formik initialValues={initialValues} onSubmit={this.onSubmit} validateOnBlur={false} enableReinitialize={true}>
                        {props => (
                            <>
                                <MyProfileForm admin={this.props.admin} formProps={props} onChangePasswordButtonClick={this.onChangePasswordButtonClick} />
                                <Prompt
                                    when={props.dirty || this.state.isChangePasswordModalVisible}
                                    hasSaveButton={props.isValid}
                                    shouldBlock={this.shouldBlock}
                                    onSave={props.submitForm}
                                />
                            </>
                        )}
                    </Formik>
                    <ChangePasswordModal isVisible={this.state.isChangePasswordModalVisible} onClose={this.onCloseModal} />
                </PageCard.Container>
            </PageLayout>
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { admin: state.auth.admin!, roles: state.auth.admin!.roles };
};

export default withSnackbar(connect(mapStateToProps)(MyProfilePage));
