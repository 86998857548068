import React from "react";
import { Api } from "Api/Api";
import { TypeRowProps } from "@inovua/reactdatagrid-community/types";
import DataGrid from "Components/DataGrid/DataGrid";
import { DataGridUrlQueryParser, QueryParserType } from "Components/DataGrid/DataGridUrlQueryParser";
import { ApiError } from "Api/ApiError";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { Icon, withStyles, WithStyles } from "@bigfish/admin-ui/core";
import { Path } from "Utils/Path";
import { dataGridStyles } from "@bigfish/admin-ui/styles";
import { TextContentListItem, ContentField, ContentOrder, ContentFilters, PermissionType } from "Api/graphql/admin/types";
import { I18n } from "I18n/I18n";
import { DateUtils } from "Utils/DateUtils";
import { connect } from "react-redux";
import { ApplicationState } from "Redux/rootReducer";
import { AuthSelectors } from "Redux/selectors/authSelectors";
import { DataGridParams } from "Components/DataGrid/UrlQueryParser";
import { DataSource, FilterValue } from "Components/DataGrid/DataGridUtils";
import { CustomTypeColumn } from "Utils/DataGridUtils";
import { Constants } from "Utils/Constants";

type ReduxProps = {
    permissions: PermissionType[];
    isSuperadmin: boolean;
};

type Props = ReduxProps & WithSnackbarProps & WithStyles<typeof dataGridStyles>;

class ContentListDataGrid extends React.Component<Props> {
    private getColumns = (): CustomTypeColumn[] => {
        return [
            { name: "id", header: I18n.formatMessage({ id: "pages.contentList.grid.column.id" }), width: 132, filterDelay: Constants.filterDelayMS },
            { name: "title", header: I18n.formatMessage({ id: "pages.contentList.grid.column.title" }), defaultFlex: 1, minWidth: 400, filterDelay: Constants.filterDelayMS },
            {
                name: "category",
                header: I18n.formatMessage({ id: "pages.contentList.grid.column.category" }),
                render: ({ data }: { data: TextContentListItem }) => {
                    return <div title={data.category?.title}>{data.category?.title}</div>;
                },
                width: 210,
                sortable: false,
            },
            { name: "updated_at", header: I18n.formatMessage({ id: "pages.contentList.grid.column.updatedAt" }), width: 210 },
            {
                textAlign: "center",
                width: 160,
                render: ({ value, data }: { value: boolean; data: TextContentListItem }) => {
                    if (!value) {
                        const isDelayed = data.active_from ? DateUtils.isAfter(DateUtils.parseISO(data.active_from), new Date()) : false;
                        if (isDelayed) {
                            return <Icon className="fa fa-calendar-check" color="secondary" title={I18n.formatMessage({ id: "pages.contentList.grid.column.status.scheduled" })} />;
                        }
                        return (
                            <Icon
                                className="fa fa-times-circle"
                                color="primary"
                                style={{ color: "#BDC2E6" }}
                                title={I18n.formatMessage({ id: "pages.contentList.grid.column.status.draft" })}
                            />
                        );
                    }
                    return <Icon className="fa fa-check-circle" color="secondary" title={I18n.formatMessage({ id: "pages.contentList.grid.column.status.published" })} />;
                },
                sortable: false,
                name: "is_visible",
                header: I18n.formatMessage({ id: "pages.contentList.grid.column.status" }),
            },
        ];
    };

    private filterValue: FilterValue[] = [
        { name: "id", operator: "eq", type: "number" },
        { name: "title", operator: "contains", type: "string" },
    ];

    private urlQueryParser = new DataGridUrlQueryParser({
        filters: {
            id: QueryParserType.string,
            title: QueryParserType.string,
            is_active: QueryParserType.boolean,
        },
        sortField: ContentField,
    });

    private sortFieldToSortOrder = {
        [ContentField.id]: "id",
        [ContentField.title]: "title",
        [ContentField.updated_at]: "updated_at",
    };

    private dataSource = async (props: DataGridParams<ContentOrder, ContentFilters>): Promise<DataSource<TextContentListItem>> => {
        try {
            const result = await Api.adminContents({ first: props.limit, page: props.page, filters: props.filters, sortBy: props.sortBy });
            return { data: result.data as TextContentListItem[], count: result.paginatorInfo.total };
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }
        return { data: [], count: 0 };
    };

    private getRowLink = (rowProps: TypeRowProps): string => {
        return Path.contentEdit(rowProps.data.id);
    };

    render() {
        return (
            <DataGrid
                urlQueryParser={this.urlQueryParser}
                rowHeight={50}
                style={{ minHeight: 800 }}
                dataSource={this.dataSource}
                filterValues={this.filterValue}
                columns={this.getColumns()}
                sortFieldToSortOrder={this.sortFieldToSortOrder}
                activeCell={null}
                rowLink={this.getRowLink}
                onRowClick={this.props.isSuperadmin || this.props.permissions.includes(PermissionType.content_update) ? undefined : () => {}}
            />
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { permissions: AuthSelectors.getPermissions(state.auth), isSuperadmin: AuthSelectors.isSuperadmin(state.auth) };
};

export default withSnackbar(connect(mapStateToProps)(withStyles(dataGridStyles, { withTheme: true })(ContentListDataGrid)));
