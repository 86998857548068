import { gql } from "@apollo/client";

export const paymentMethodFragment = gql`
    fragment PaymentMethod on PaymentMethod {
        id
        name
        description
        cost
        is_active
        cart_value_max
    }
`;
