import React from "react";
import { Box, Button, Divider, FormControl, FormHelperText, Grid, Typography } from "@bigfish/admin-ui/core";
import { PasswordField } from "@bigfish/admin-ui/components";
import { Form } from "Components/Form";
import { Field, FieldProps, FormikErrors, FormikProps } from "formik";
import { Validator } from "Utils/Validator";
import { I18n } from "Src/i18n/I18n";
import { I18nHelpers } from "I18n/I18nHelpers";

export type ChangePasswordFormValues = {
    password: string;
    passwordNew: string;
    passwordNewConfirmation: string;
};

export const changePasswordValidator = (values: ChangePasswordFormValues): FormikErrors<ChangePasswordFormValues> => {
    const errors: FormikErrors<ChangePasswordFormValues> = {};
    errors.password = I18nHelpers.formatValidator(Validator.required)(values.password);
    errors.passwordNew = I18nHelpers.formatValidator(Validator.newPassword)(values.passwordNew);
    errors.passwordNewConfirmation = I18nHelpers.formatValidator(Validator.samePassword(values.passwordNew))(values.passwordNewConfirmation);
    return Form.cleanupFormikErrors(errors);
};

type Props = {
    formProps: FormikProps<ChangePasswordFormValues>;
    onCancelClick: () => void;
};

export const ChangePasswordForm = (props: Props) => {
    const password = props.formProps.getFieldMeta("password");
    const passwordNewMeta = props.formProps.getFieldMeta("passwordNew");
    const passwordNewConfirmationMeta = props.formProps.getFieldMeta("passwordNewConfirmation");
    return (
        <Form formProps={props.formProps}>
            <Box p="20px 0">
                <Typography variant="body2" color="textSecondary">
                    {I18n.formatMessage({ id: "pages.myProfile.changePasswordModal.description" })}
                </Typography>

                <Box width={440} mx="auto">
                    <Box mt="30px">
                        <Field name="password">
                            {({ field, meta }: FieldProps) => (
                                <FormControl fullWidth error={meta.touched && !!meta.error}>
                                    <PasswordField
                                        type="password"
                                        label={I18n.formatMessage({ id: "pages.myProfile.changePasswordModal.form.password.label" })}
                                        fullWidth
                                        variant="outlined"
                                        error={meta.touched && !!meta.error}
                                        required
                                        {...field}
                                    />
                                </FormControl>
                            )}
                        </Field>
                    </Box>
                    <Box mt="30px">
                        <Field name="passwordNew">
                            {({ field, meta }: FieldProps) => (
                                <FormControl fullWidth error={meta.touched && !!meta.error}>
                                    <PasswordField
                                        type="password"
                                        label={I18n.formatMessage({ id: "pages.myProfile.changePasswordModal.form.passwordNew.label" })}
                                        fullWidth
                                        variant="outlined"
                                        error={meta.touched && !!meta.error}
                                        required
                                        {...field}
                                    />
                                </FormControl>
                            )}
                        </Field>
                    </Box>
                    <Box mt="30px">
                        <Field name="passwordNewConfirmation">
                            {({ field, meta }: FieldProps) => (
                                <FormControl fullWidth error={meta.touched && !!meta.error}>
                                    <PasswordField
                                        type="password"
                                        label={I18n.formatMessage({ id: "pages.myProfile.changePasswordModal.form.passwordNewConfirmation.label" })}
                                        fullWidth
                                        variant="outlined"
                                        error={meta.touched && !!meta.error}
                                        required
                                        {...field}
                                    />
                                </FormControl>
                            )}
                        </Field>
                    </Box>

                    <Box mt="30px">
                        <FormControl
                            fullWidth
                            error={
                                (password.touched && !!password.error) ||
                                (passwordNewMeta.touched && !!passwordNewMeta.error) ||
                                (passwordNewConfirmationMeta.touched && !!passwordNewConfirmationMeta.error)
                            }
                        >
                            <FormHelperText component="span" style={{ textAlign: "center" }}>
                                {password.touched && password.error && <FormHelperText component="span">{password.error} </FormHelperText>}
                                {passwordNewConfirmationMeta.touched && passwordNewConfirmationMeta.error && (
                                    <FormHelperText component="span">{passwordNewConfirmationMeta.error} </FormHelperText>
                                )}
                                {I18n.formatMessage({ id: "pages.myProfile.changePasswordModal.info" })}
                            </FormHelperText>
                        </FormControl>
                    </Box>
                </Box>
            </Box>

            <Divider />
            <Box mt="30px">
                <Grid container justify="space-between" alignItems="center">
                    <Box>
                        <Button variant="outlined" size="large" color="primary" fullWidth disabled={props.formProps.isSubmitting} onClick={props.onCancelClick}>
                            {I18n.formatMessage({ id: "common.cancel" })}
                        </Button>
                    </Box>
                    <Box>
                        <Button type="submit" variant="contained" size="large" color="secondary" fullWidth disabled={props.formProps.isSubmitting || !props.formProps.dirty}>
                            {I18n.formatMessage({ id: "pages.myProfile.changePasswordModal.submit" })}
                        </Button>
                    </Box>
                </Grid>
            </Box>
        </Form>
    );
};
