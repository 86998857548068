import React, { useCallback } from "react";
import { Form, FormType } from "Components/Form";
import { FormikProps } from "formik";
import { I18n } from "Src/i18n/I18n";
import { FullscreenLoader, PageCard, SavePanel, TabsComponent, TitleBar } from "@bigfish/admin-ui/components";
import { MediaLibraryHelper } from "Utils/MediaLibraryHelper";
import { useHistory } from "react-router";
import { Path } from "Utils/Path";
import { GalleryFormContentTab } from "./GalleryFormContentTab";
import { GalleryFormOrderTab } from "./GalleryFormOrderTab";
import { Button, Icon, Grid } from "@bigfish/admin-ui/core";
import { FunctionalButton } from "Components/FunctionalButton";
import { DeleteForever } from "@bigfish/admin-ui/icons";

export type GalleryImage = {
    url: string;
    caption: string | null;
    credit: string | null;
};

export type GalleryFormValues = {
    title: string;
    handle: string;
    images: GalleryImage[];
};

type Props = {
    title: string;
    isModal?: boolean;
    formType: FormType;
    formProps: FormikProps<GalleryFormValues>;
    onDeleteClick?: () => void;
};

export const GalleryForm = (props: Props) => {
    const history = useHistory();

    const onCancelClick = useCallback(() => {
        history.push(Path.galleryList);
    }, [history]);

    const [tab, setTab] = React.useState(0);
    const changeTab = (_event: React.ChangeEvent<{}>, newValue: number) => {
        setTab(newValue);
    };

    const onSelection = useCallback(
        (urls: string[]) => {
            const images = [...props.formProps.values.images, ...urls.map((url: string): GalleryImage => ({ url, caption: "", credit: "" }))];
            props.formProps.setFieldValue("images", images);
        },
        [props.formProps]
    );

    return (
        <>
            <TitleBar
                title={props.isModal ? "" : props.title}
                rightButtonsComponent={
                    <>
                        <FunctionalButton disabled={!props.onDeleteClick} onClick={props.onDeleteClick} icon={<DeleteForever />} />
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<Icon className="item fa fa-plus-circle" />}
                            onClick={() => MediaLibraryHelper.openLibrary({ onSelection })}
                        >
                            {I18n.formatMessage({ id: "pages.gallery.form.addFromMediaLibrary" })}
                        </Button>
                    </>
                }
            />
            <PageCard.Container>
                <Form formProps={props.formProps}>
                    <TabsComponent
                        ariaLabel="gallery-editor"
                        tabs={[
                            {
                                label: I18n.formatMessage({ id: "pages.gallery.form.tab.content" }),
                                id: "gallery-content",
                                content: <GalleryFormContentTab formProps={props.formProps} formType={props.formType} />,
                            },
                            {
                                label: I18n.formatMessage({ id: "pages.gallery.form.tab.sort" }),
                                id: "gallery-order",
                                content: <GalleryFormOrderTab formProps={props.formProps} />,
                            },
                        ]}
                        currentTab={tab}
                        changeTab={changeTab}
                    />

                    <SavePanel>
                        <Grid container justify="space-between">
                            <Button variant="outlined" color="primary" onClick={onCancelClick}>
                                {I18n.formatMessage({ id: "common.cancel" })}
                            </Button>
                            <Button
                                type="submit"
                                startIcon={<Icon className="fas fa-save" />}
                                variant="contained"
                                color="secondary"
                                disabled={!props.formProps.dirty || !props.formProps.isValid}
                            >
                                {I18n.formatMessage({ id: "common.save" })}
                            </Button>
                        </Grid>
                    </SavePanel>
                    <FullscreenLoader visible={props.formProps.isSubmitting} />
                </Form>
            </PageCard.Container>
        </>
    );
};
