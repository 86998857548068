import { gql } from "@apollo/client";
import { pageListItemFragment } from "../fragments/pageListItemFragment";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";

export const listPagesQuery = gql`
    query listPages($filters: PageFilters, $sortBy: PageSort, $first: Int!, $page: Int) {
        listPages(first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            data {
                ...PageListItem
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${pageListItemFragment}
    ${paginatorInfoFragment}
`;
