import React from "react";
import { Order } from "Api/graphql/admin/types";
import { Box } from "@bigfish/admin-ui/core";
import { TransactionPayments } from "./TransactionPayments";
import { GiftCardPayments } from "./GiftCardPayments";
import { InvoicePayments } from "./InvoicePayments";

type Props = {
    order: Order | undefined;
};

export const OrderFormPaymentsTab = (props: Props) => {
    if (!props.order) return null;

    return (
        <div>
            {(props.order.payment?.transactions ?? []).length > 0 && (
                <div>
                    <Box mt="30px" />
                    <TransactionPayments paymentTransactions={props.order.payment?.transactions ?? []} />
                </div>
            )}

            {props.order.gift_cards.length > 0 && (
                <div>
                    <Box mt="30px" />
                    <GiftCardPayments giftCardPayments={props.order.gift_cards} />
                </div>
            )}

            {props.order.invoices.length > 0 && (
                <div>
                    <Box mt="30px" />
                    <InvoicePayments invoicePayments={props.order.invoices} />
                </div>
            )}
        </div>
    );
};
