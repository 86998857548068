import React, { useCallback, useState } from "react";
import uniq from "lodash/uniq";
import { I18n } from "I18n/I18n";
import { Box, Button, Typography } from "@bigfish/admin-ui/core";
import { ProductCatalogItem } from "Api/graphql/admin/types";
import { createModal } from "Components/createModal";
import ProductCatalogDataGrid from "Pages/ProductCatalog/ProductCatalogDataGrid";

const AddProductModal = createModal({ modalWidth: 1280 });
interface Props {
    description?: string;
    initialSelectedProductIds: number[];
    onClose: () => void;
    onAddClick: (selectedProductIds: number[]) => void;
}

export const AddProductDialog = ({ description, initialSelectedProductIds, onClose, onAddClick }: Props) => {
    const [selectedProductIds, setSelectedProductIds] = useState<number[]>(initialSelectedProductIds);

    const handleAddProducts = useCallback(
        (productsToAdd: ProductCatalogItem[]) => {
            setSelectedProductIds(uniq([...selectedProductIds, ...productsToAdd.map(p => p.id)]));
        },
        [selectedProductIds]
    );

    const handleRemoveProducts = useCallback(
        (productsToRemove: ProductCatalogItem[]) => {
            setSelectedProductIds(selectedProductIds.filter(productId => !productsToRemove.some(productToRemove => productToRemove.id === productId)));
        },
        [selectedProductIds]
    );

    return (
        <AddProductModal
            title={I18n.formatMessage({ id: "components.addProductDialog.title" })}
            leftButtonsComponent={<Typography variant="subtitle2">{I18n.formatMessage({ id: "common.selectedItems" }, { selectedItems: selectedProductIds.length })}</Typography>}
            rightButtonsComponent={
                <>
                    <Button variant="outlined" color="primary" onClick={onClose} style={{ marginRight: "20px" }}>
                        {I18n.formatMessage({ id: "common.cancel" })}
                    </Button>
                    <Button variant="contained" color="primary" onClick={() => onAddClick(selectedProductIds)}>
                        {I18n.formatMessage({ id: "common.add" })}
                    </Button>
                </>
            }
            open
            onClose={onClose}
        >
            {description && (
                <Box mb="30px">
                    <Typography variant="body2" color="textSecondary">
                        {description}
                    </Typography>
                </Box>
            )}
            <ProductCatalogDataGrid
                key="add-product-dialog-data-grid"
                selectedProductIds={selectedProductIds}
                onAddProducts={handleAddProducts}
                onRemoveProducts={handleRemoveProducts}
                minWidth={500}
                isReadOnly
                isUnparsed
            />
        </AddProductModal>
    );
};
