import { gql } from "@apollo/client";
import { productParamSelectItemFragment } from "../fragments/productParamSelectItemFragment";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";

export const listProductParamSelectItemsQuery = gql`
    query listProductParamSelectItems($filters: ProductParamFilters, $sortBy: ProductParamSort, $first: Int!, $page: Int) {
        productParamList(first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            data {
                ...ProductParamSelectItem
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${productParamSelectItemFragment}
    ${paginatorInfoFragment}
`;
