import React from "react";

import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { PageCard, TitleBar } from "@bigfish/admin-ui/components";
import { Typography } from "@bigfish/admin-ui/core";

import { PermissionType } from "Api/graphql/admin/types";
import LogListDataGrid from "Pages/LogList/LogListDataGrid";
import { PageLayout } from "Components/PageLayout";
import { I18n } from "I18n/I18n";
import { ApplicationState } from "Redux/rootReducer";
import { AuthSelectors } from "Redux/selectors/authSelectors";

type ReduxProps = {
    permissions: PermissionType[];
    isSuperadmin: boolean;
};

type Props = ReduxProps & RouteComponentProps;

class LogListPage extends React.Component<Props> {
    public render() {
        return (
            <PageLayout>
                <TitleBar title={I18n.formatMessage({ id: "pages.logList.title" })} />
                <Typography style={{ marginTop: 15 }}>{I18n.formatMessage({ id: "pages.logList.subtitle" })}</Typography>
                <PageCard.Container>
                    <LogListDataGrid />
                </PageCard.Container>
            </PageLayout>
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { permissions: AuthSelectors.getPermissions(state.auth), isSuperadmin: AuthSelectors.isSuperadmin(state.auth) };
};

export default withRouter(connect(mapStateToProps)(LogListPage));
