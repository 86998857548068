import React from "react";
import { Box, Icon } from "@bigfish/admin-ui/core";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect, DispatchProp } from "react-redux";

import { Path } from "Utils/Path";
import { Admin } from "Api/graphql/admin/types";
import { ApplicationState } from "Redux/rootReducer";
import { I18n } from "I18n/I18n";
import { Link } from "./Link";

type ReduxProps = {
    admin: Admin;
};

type Props = RouteComponentProps & ReduxProps & DispatchProp;

type State = {};

class UserMenu extends React.Component<Props, State> {
    public render() {
        return (
            <>
                <Link to={Path.myProfile}>
                    <Icon className="fa fa-user-circle" style={{ fontSize: 14 }} />
                    {this.props.admin.name}
                </Link>{" "}
                <Box component="span" className="separator" />
                <Link to={Path.logout}>{I18n.formatMessage({ id: "common.logout" })}</Link>
            </>
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { admin: state.auth.admin! };
};

export default withRouter(connect(mapStateToProps)(UserMenu));
