import { gql } from "@apollo/client";
import { userOrderItemFragment } from "../fragments/userOrderItemFragment";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";

export const listUserOrdersQuery = gql`
    query listUserOrders($filters: OrderFilters, $sortBy: OrderSort, $first: Int!, $page: Int) {
        listOrders(first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            data {
                ...UserOrderItem
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${userOrderItemFragment}
    ${paginatorInfoFragment}
`;
