import { gql } from "@apollo/client";

export const listOrdersExportQuery = gql`
    query listOrdersExport($filters: OrderFilters, $sortBy: OrderSort) {
        listOrdersExport(filters: $filters, sortBy: $sortBy) {
            url
            send_in_email
        }
    }
`;
