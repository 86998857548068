import { gql } from "@apollo/client";

export const attachDocumentMutation = gql`
    mutation attachDocument($contentId: ID!, $documentId: ID!, $csrf: String) {
        attachDocument(contentId: $contentId, documentId: $documentId, csrf: $csrf) {
            ... on TextContent {
                id
            }
        }
    }
`;
