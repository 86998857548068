import { gql } from "@apollo/client";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";
import { productParamSimpleFragment } from "../fragments/productParamSimpleFragment";

export const productParamListQuery = gql`
    query productParamList($filters: ProductParamFilters, $sortBy: ProductParamSort, $first: Int!, $page: Int) {
        productParamList(first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            data {
                ...ProductParamSimple
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${productParamSimpleFragment}
    ${paginatorInfoFragment}
`;
