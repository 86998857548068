import React from "react";
import { GiftCardPayment } from "Api/graphql/admin/types";
import { I18n } from "I18n/I18n";
import UnControlledDataGrid from "Components/DataGrid/UCDataGrid";
import { CustomTypeColumn } from "Utils/DataGridUtils";
import { DataSource } from "Components/DataGrid/DataGridUtils";
import { PageCard } from "@bigfish/admin-ui/components";
import { DateUtils, DateFormat } from "Utils/DateUtils";

type Props = {
    giftCardPayments: GiftCardPayment[];
};

export const GiftCardPayments = (props: Props) => {
    const getColumns = (): CustomTypeColumn[] => {
        return [
            { name: "barcode", header: I18n.formatMessage({ id: "pages.order.form.payments.giftCardPayments.column.barcode" }), defaultFlex: 1, minWidth: 200 },
            {
                name: "amount",
                header: I18n.formatMessage({ id: "pages.order.form.payments.giftCardPayments.column.amount" }),
                defaultWidth: 200,
                render: ({ value }: { value: number }) => {
                    return <div>{I18n.formatCurrency(value)}</div>;
                },
            },
            {
                name: "voided_amount",
                header: I18n.formatMessage({ id: "pages.order.form.payments.giftCardPayments.column.voided_amount" }),
                defaultWidth: 200,
                render: ({ value }: { value: number }) => {
                    return <div>{I18n.formatCurrency(value)}</div>;
                },
            },
            {
                name: "paid_at",
                header: I18n.formatMessage({ id: "pages.order.form.payments.giftCardPayments.column.paid_at" }),
                minWidth: 357,
                render: ({ value }: { value: any }) => {
                    return <div>{value ? DateUtils.format(value, DateFormat.minuteDateTime) : ""}</div>;
                },
            },
            {
                name: "voided_at",
                header: I18n.formatMessage({ id: "pages.order.form.payments.giftCardPayments.column.voided_at" }),
                minWidth: 357,
                render: ({ value }: { value: any }) => {
                    return <div>{value ? DateUtils.format(value, DateFormat.minuteDateTime) : ""}</div>;
                },
            },
        ];
    };

    return (
        <div>
            <PageCard.Heading title={I18n.formatMessage({ id: "pages.order.form.payments.giftCardPayments.title" })} />
            <UnControlledDataGrid<GiftCardPayment>
                rowHeight={50}
                style={{ minHeight: 42 + props.giftCardPayments.length * 50 }}
                dataSource={async (): Promise<DataSource<GiftCardPayment>> => {
                    return { data: props.giftCardPayments, count: props.giftCardPayments.length };
                }}
                columns={getColumns()}
                activeCell={null}
                pagination={false}
            />
        </div>
    );
};
