import { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { Path } from "Utils/Path";
import { AuthUtils } from "Redux/utils/authUtils";

type Props = RouteComponentProps;

class LogoutPage extends Component<Props> {
    public async componentDidMount(): Promise<void> {
        AuthUtils.logout();
        this.props.history.replace({ pathname: Path.login, state: { from: undefined } });
    }

    public render() {
        return null;
    }
}

export default withRouter(LogoutPage);
