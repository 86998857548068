import React from "react";
import { PageLayout } from "Components/PageLayout";
import { RouteComponentProps } from "react-router-dom";
import UserListDataGrid from "./UserListDataGrid";
import { TitleBar, PageCard } from "@bigfish/admin-ui/components";
import { WithSnackbarProps } from "notistack";
import { I18n } from "I18n/I18n";

type Props = RouteComponentProps & WithSnackbarProps;

class UserListPage extends React.Component<Props> {
    public render() {
        return (
            <PageLayout>
                <TitleBar title={I18n.formatMessage({ id: "pages.userList.title" })} />
                <PageCard.Container>
                    <UserListDataGrid />
                </PageCard.Container>
            </PageLayout>
        );
    }
}

export default UserListPage;
