import { Box } from "@bigfish/admin-ui/core";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { RoleForm, RoleFormValues } from "./RoleForm";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { PageLayout } from "Components/PageLayout";
import Prompt from "Components/Prompt";
import { TitleBar } from "@bigfish/admin-ui/components";
import { I18n } from "I18n/I18n";
import { Permission } from "Api/graphql/admin/types";

type Props = RouteComponentProps & WithSnackbarProps;

type State = {
    isCreateSucceed: boolean;
    permissions: Permission[];
};

class RoleCreatePage extends React.Component<Props, State> {
    public readonly state: State = {
        isCreateSucceed: false,
        permissions: [],
    };

    public async componentDidMount(): Promise<void> {
        try {
            const { data: permissions } = await Api.listPermissions({ first: 9999 });
            this.setState({ permissions });
        } catch (error) {
            this.props.enqueueSnackbar(error.message, { variant: "error" });
            this.props.history.push(Path.roleList);
        }
    }

    private onSubmit = async (values: RoleFormValues, formikHelpers: FormikHelpers<RoleFormValues>, isRedirectHandled?: boolean): Promise<boolean> => {
        try {
            await Api.createRole(values.name ?? "", {
                title: values.title,
                permissions: values.permissions,
            });
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.role.create.onSubmit.success" }), { variant: "success" });
            if (!isRedirectHandled) {
                this.setState({ isCreateSucceed: true });
            }
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.role.create.onSubmit.error" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
            return false;
        }
    };

    public render() {
        if (this.state.isCreateSucceed) {
            return <Redirect to={Path.roleList} />;
        }

        const initialValues: RoleFormValues = {
            name: "",
            title: "",
            permissions: [],
        };

        return (
            <PageLayout>
                <TitleBar title={I18n.formatMessage({ id: "pages.role.createTitle" })} />
                <Formik initialValues={initialValues} onSubmit={this.onSubmit} validateOnBlur={false}>
                    {props => (
                        <>
                            <RoleForm formType={FormType.create} formProps={props} permissions={this.state.permissions} />
                            <Prompt when={props.dirty} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props, true)} />
                        </>
                    )}
                </Formik>
                <Box mt="40px" />
            </PageLayout>
        );
    }
}

export default withSnackbar(withRouter(RoleCreatePage));
