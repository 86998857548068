import { Typography } from "@bigfish/admin-ui/core";
import { ActiveBreadcrumbItem, FullscreenLoader, PageCard } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Category } from "Api/graphql/admin/types";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { I18n } from "Src/i18n/I18n";
import { CategoryForm, CategoryFormValues } from "./CategoryForm";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { Loading } from "Components/Loading";
import { PageLayout } from "Components/PageLayout";
import { Link } from "Components/Link";
import { Breadcrumbs } from "Components/Breadcrumbs";
import Prompt from "Components/Prompt";

type RouteParams = {
    id?: string;
};

type Props = WithSnackbarProps & RouteComponentProps<RouteParams>;

type State = {
    isLoading: boolean;
    category: Category | null;
};

class CategoryEditPage extends React.Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        category: null,
    };

    public async componentDidMount(): Promise<void> {
        try {
            const category = await Api.getCategory(this.props.match.params.id!);
            this.setState({ category, isLoading: false });
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
            this.props.history.push(Path.categoryList);
        }
    }

    private onSubmit = async (values: CategoryFormValues, formikHelpers: FormikHelpers<CategoryFormValues>): Promise<boolean> => {
        try {
            const category = await Api.updateCategory(values.id, {
                title: values.title,
                lead: values.lead ?? null,
            });
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.category.edit.succeed" }), { variant: "success" });
            this.setState({ category });
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.category.edit.failed" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
        }
        return false;
    };

    private getBreadcrumb = () => {
        return (
            <Breadcrumbs>
                <Link to={Path.categoryList} color="inherit">
                    {I18n.formatMessage({ id: "pages.categoryList.breadcrumb" })}
                </Link>
                <ActiveBreadcrumbItem aria-current="page">{I18n.formatMessage({ id: "pages.category.edit.breadcrumb" })}</ActiveBreadcrumbItem>
            </Breadcrumbs>
        );
    };

    public render() {
        if (this.state.isLoading) {
            return <Loading breadcrumb={this.getBreadcrumb()} />;
        }
        if (!this.state.category) {
            return <Redirect to={Path.categoryList} />;
        }

        const initialValues: CategoryFormValues = {
            id: this.state.category.id,
            lead: this.state.category.lead || "",
            title: this.state.category.title,
            meta_title: this.state.category.meta_title,
            meta_description: this.state.category.meta_description,
            meta_keywords: this.state.category.meta_keywords,
            url: this.state.category.url,
        };

        return (
            <PageLayout breadcrumb={this.getBreadcrumb()}>
                <Typography variant="h1">{this.state.category.title}</Typography>
                <PageCard.Container>
                    <Formik initialValues={initialValues} onSubmit={this.onSubmit} validateOnBlur={false} enableReinitialize={true}>
                        {props => (
                            <>
                                <CategoryForm formType={FormType.edit} formProps={props} />
                                <Prompt when={props.dirty} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props)} />
                                <FullscreenLoader visible={props.isSubmitting} />
                            </>
                        )}
                    </Formik>
                </PageCard.Container>
            </PageLayout>
        );
    }
}

export default withSnackbar(withRouter(CategoryEditPage));
