import { gql } from "@apollo/client";
import { badgeFragment } from "./badgeFragment";

export const productParamFragment = gql`
    fragment ProductParam on ProductParam {
        id
        name
        evo_name
        type
        is_active
        is_filter
        is_multi
        type_widget
        created_at
        updated_at
        options {
            id
            label
            value
        }
        badge {
            ...Badge
        }
        updated_by {
            id
            name
        }
    }
    ${badgeFragment}
`;
