import { gql } from "@apollo/client";

export const subscriptionListItemFragment = gql`
    fragment SubscriptionListItem on OrderSubscriptionSimple {
        id
        user_id
        user_fullname
        user_email
        payment_provider
        created_at
        updated_at
        shipping_method_name
        deleted_at
    }
`;
