import React from "react";
import { batchActions } from "redux-batched-actions";
import { connect, DispatchProp } from "react-redux";
import { RouteComponentProps, withRouter, StaticContext, Redirect } from "react-router";
import { Formik, FormikHelpers } from "formik";

import { Api } from "Api/Api";
import { AuthActions } from "Redux/actions/authActions";
import { Path } from "Utils/Path";
import { AuthSelectors } from "Redux/selectors/authSelectors";
import { ApplicationState } from "Redux/rootReducer";
import { LoginForm, LoginFormValues } from "./LoginForm";
import { Form } from "Components/Form";
import { ApiError } from "Api/ApiError";
import { Typography } from "@bigfish/admin-ui/core";
import { CenteredCard, BrandLogo } from "@bigfish/admin-ui/components";
import { Box } from "@bigfish/admin-ui/core";
import { I18n } from "Src/i18n/I18n";
import { MinimalLayout } from "Components/MinimalLayout";

interface LocationState {
    from?: {
        pathname: string;
    };
}

type ReduxProps = {
    isLoggedIn: boolean;
    isStoreOnlyAdmin: boolean;
};

type Props = ReduxProps & DispatchProp & RouteComponentProps<{}, StaticContext, LocationState>;

class LoginPage extends React.Component<Props> {
    private onSubmit = async (values: LoginFormValues, formikHelpers: FormikHelpers<LoginFormValues>): Promise<void> => {
        try {
            const result = await Api.adminLogin({ login: values.login, password: values.password });
            this.props.dispatch(
                batchActions([AuthActions.updateAdmin(result.admin), AuthActions.updateToken(result.token), AuthActions.updateMediaLibraryToken(result.media_library_token)])
            );
        } catch (error) {
            if (error instanceof ApiError) {
                if (error.hasFieldError()) {
                    Form.submitFailed(formikHelpers, error);
                } else {
                    formikHelpers.setFieldError("login", error.message);
                }
            }
        }
    };

    public render() {
        if (this.props.isLoggedIn) {
            const to = this.props.isStoreOnlyAdmin ? Path.storeOrderList : this.props.location.state?.from?.pathname || Path.dashboard;
            return <Redirect to={to} />;
        }

        const initialValues: LoginFormValues = {
            login: process.env.REACT_APP_DEBUG_USER_EMAIL || "",
            password: process.env.REACT_APP_DEBUG_USER_PASSWORD || "",
        };

        return (
            <MinimalLayout>
                <CenteredCard>
                    <BrandLogo />
                    <Box my="30px">
                        <Typography variant="h4" component="h1" align="center" color="textPrimary">
                            {I18n.formatMessage({ id: "pages.login.title" })}
                        </Typography>
                    </Box>
                    <Box my="30px">
                        <Typography variant="body2" paragraph align="center" color="textSecondary">
                            {I18n.formatMessage({ id: "pages.login.description" })}
                        </Typography>
                    </Box>
                    <Formik initialValues={initialValues} onSubmit={this.onSubmit} validateOnBlur={false}>
                        {props => <LoginForm formProps={props} />}
                    </Formik>
                </CenteredCard>
            </MinimalLayout>
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { isLoggedIn: AuthSelectors.isLoggedIn(state.auth), isStoreOnlyAdmin: AuthSelectors.isStoreOnlyAdmin(state.auth) };
};

export default connect(mapStateToProps)(withRouter(LoginPage));
