import { gql } from "@apollo/client";

export const bannerProductListFragment = gql`
    fragment BannerProductList on BannerProductList {
        id
        name
        urls
        title
        slider_title
        type
        image_align
        button_title
        image
        image_title
        image_mobile
        image_mobile_title
        target_url
        target_popup
        is_active
        active_from
        active_to
        created_at
        created_by {
            id
            name
        }
        updated_at
        updated_by {
            id
            name
        }
    }
`;
