import { gql } from "@apollo/client";
import { productListFragment } from "../fragments/productListFragment";

export const updateProductListMutation = gql`
    mutation updateProductList($id: Int!, $input: ProductListInput!, $csrf: String) {
        updateProductList(id: $id, input: $input, csrf: $csrf) {
            ...ProductList
        }
    }
    ${productListFragment}
`;
