import { gql } from "@apollo/client";
import { orderFragment } from "../fragments/orderFragment";

export const resendShipmentMutation = gql`
    mutation resendShipment($increment_id: Int!, $csrf: String) {
        resendShipment(increment_id: $increment_id, csrf: $csrf) {
            ...Order
        }
    }
    ${orderFragment}
`;
