import React from "react";
import { Button, Icon } from "@bigfish/admin-ui/core";
import { PageLayout } from "Components/PageLayout";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import RoleListDataGrid from "./RoleListDataGrid";
import { TitleBar, PageCard } from "@bigfish/admin-ui/components";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { I18n } from "I18n/I18n";
import { connect } from "react-redux";
import { ApplicationState } from "Redux/rootReducer";
import { AuthSelectors } from "Redux/selectors/authSelectors";
import { PermissionType } from "Api/graphql/admin/types";

type ReduxProps = {
    permissions: PermissionType[];
    isSuperadmin: boolean;
};

type Props = ReduxProps & RouteComponentProps & WithSnackbarProps;

class RoleListPage extends React.Component<Props> {
    private onAddClick = (): void => {
        this.props.history.push(Path.roleCreate);
    };

    public render() {
        return (
            <PageLayout>
                <TitleBar
                    title={I18n.formatMessage({ id: "pages.roleList.title" })}
                    rightButtonsComponent={
                        (this.props.isSuperadmin || this.props.permissions.includes(PermissionType.role_update)) && (
                            <Button startIcon={<Icon className="fa fa-plus-circle" />} variant="contained" size="medium" color="secondary" onClick={this.onAddClick}>
                                {I18n.formatMessage({ id: "pages.roleList.addButton" })}
                            </Button>
                        )
                    }
                />
                <PageCard.Container>
                    <RoleListDataGrid />
                </PageCard.Container>
            </PageLayout>
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { permissions: AuthSelectors.getPermissions(state.auth), isSuperadmin: AuthSelectors.isSuperadmin(state.auth) };
};

export default withSnackbar(connect(mapStateToProps)(withRouter(RoleListPage)));
