import { gql } from "@apollo/client";
import { maintenanceFragment } from "../fragments/maintenanceFragment";

export const updateMaintenanceMutation = gql`
    mutation updateMaintenance($id: ID!, $input: MaintenanceInput!, $csrf: String) {
        updateMaintenance(id: $id, input: $input, csrf: $csrf) {
            ...Maintenance
        }
    }
    ${maintenanceFragment}
`;
