import { gql } from "@apollo/client";
import { siteFragment } from "./siteFragment";

export const redirectionListItemFragment = gql`
    fragment RedirectionListItem on Redirection {
        id
        from_site {
            ...Site
        }
        from
        to_site {
            ...Site
        }
        to
        created_at
        updated_at
        is_active
    }
    ${siteFragment}
`;
