import { gql } from "@apollo/client";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";
import { shippingMethodTabItemFragment } from "../fragments/shippingMethodTabItemFragment";

export const listShippingMethodsQuery = gql`
    query listShippingMethods($first: Int!) {
        listShippingMethods(first: $first) {
            data {
                ...ShippingMethodTabItem
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${shippingMethodTabItemFragment}
    ${paginatorInfoFragment}
`;
