import { gql } from "@apollo/client";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";
import { menuListItemFragment } from "../fragments/menuListItemFragment";

export const listMenusQuery = gql`
    query listMenus($filters: MenuFilters, $sortBy: MenuSort, $first: Int!, $page: Int) {
        listMenus(first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            data {
                ...MenuListItem
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${menuListItemFragment}
    ${paginatorInfoFragment}
`;
