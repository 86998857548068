import React from "react";
import { ActiveBreadcrumbItem } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Gallery } from "Api/graphql/admin/types";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import { I18n } from "Src/i18n/I18n";
import { GalleryForm, GalleryFormValues } from "./GalleryForm";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { Loading } from "Components/Loading";
import { PageLayout } from "Components/PageLayout";
import { Link } from "Components/Link";
import { Breadcrumbs } from "Components/Breadcrumbs";
import Prompt from "Components/Prompt";
import { FunctionConfirmModal } from "Components/FunctionConfirmModal";

type RouteParams = {
    id?: string;
};

type Props = WithSnackbarProps & RouteComponentProps<RouteParams>;

type State = {
    isLoading: boolean;
    gallery: Gallery | null;
    isDeleteDialogVisible: boolean;
};

class GalleryEditPage extends React.Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        gallery: null,
        isDeleteDialogVisible: false,
    };

    public async componentDidMount(): Promise<void> {
        try {
            const gallery = await Api.getGallery(this.props.match.params.id!);
            this.setState({ gallery, isLoading: false });
        } catch (error) {
            this.props.enqueueSnackbar(error.message, { variant: "error" });
            this.props.history.push(Path.galleryList);
        }
    }

    private onSubmit = async (values: GalleryFormValues, formikHelpers: FormikHelpers<GalleryFormValues>): Promise<boolean> => {
        try {
            const gallery = await Api.updateGallery(this.state.gallery!.id, {
                title: values.title,
                images: values.images.map(image => ({ url: image.url, caption: image.caption, credit: image.credit })),
            });

            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.gallery.edit.succeed" }), { variant: "success" });
            this.setState({ gallery });
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.gallery.edit.failed" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
        }
        return false;
    };

    private onDeleteClick = async (): Promise<void> => {
        try {
            await Api.deleteGallery(this.state.gallery!.id);
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.gallery.edit.deleteSucceed" }), { variant: "success" });
            this.setState({ gallery: null });
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.gallery.edit.deleteFailed" }), { variant: "error" });
        }
    };

    private shouldBlock = (): boolean => {
        if (this.state.isDeleteDialogVisible) {
            this.setState({ isDeleteDialogVisible: false });
            return true;
        }
        return false;
    };

    private onDeleteButtonClick = (): void => {
        this.setState({ isDeleteDialogVisible: true });
    };

    private onDeleteDialogClose = (): void => {
        this.setState({ isDeleteDialogVisible: false });
    };

    private getBreadcrumb = () => {
        return (
            <Breadcrumbs>
                <Link to={Path.galleryList} color="inherit">
                    {I18n.formatMessage({ id: "pages.galleryList.breadcrumb" })}
                </Link>
                <ActiveBreadcrumbItem aria-current="page">{I18n.formatMessage({ id: "pages.gallery.edit.breadcrumb" })}</ActiveBreadcrumbItem>
            </Breadcrumbs>
        );
    };

    public render() {
        if (this.state.isLoading) {
            return <Loading breadcrumb={this.getBreadcrumb()} />;
        }
        if (!this.state.gallery) {
            return <Redirect to={Path.galleryList} />;
        }

        const initialValues: GalleryFormValues = {
            title: this.state.gallery.title,
            handle: this.state.gallery.handle || "",
            images: this.state.gallery.images,
        };

        return (
            <PageLayout breadcrumb={this.getBreadcrumb()}>
                <Formik initialValues={initialValues} onSubmit={this.onSubmit} validateOnBlur={false} enableReinitialize={true}>
                    {props => (
                        <>
                            <GalleryForm title={this.state.gallery!.title} formType={FormType.edit} formProps={props} onDeleteClick={this.onDeleteButtonClick} />
                            <FunctionConfirmModal
                                title={I18n.formatMessage({ id: "pages.gallery.edit.deleteModal.title" })}
                                description={I18n.formatMessage({ id: "pages.gallery.edit.deleteModal.description" })}
                                isVisible={this.state.isDeleteDialogVisible}
                                onClose={this.onDeleteDialogClose}
                                onFunctionClick={this.onDeleteClick}
                                rightButtonLabel={I18n.formatMessage({ id: "common.delete" })}
                                leftButtonLabel={I18n.formatMessage({ id: "common.cancel" })}
                            />
                            <Prompt
                                when={props.dirty || this.state.isDeleteDialogVisible}
                                hasSaveButton={props.isValid}
                                shouldBlock={this.shouldBlock}
                                onSave={() => this.onSubmit(props.values, props)}
                            />
                        </>
                    )}
                </Formik>
            </PageLayout>
        );
    }
}

export default withSnackbar(withRouter(GalleryEditPage));
