import { gql } from "@apollo/client";
import { menuItemFragment } from "../fragments/menuItemFragment";

export const menuFragment = gql`
    fragment Menu on Menu {
        id
        slug
        name
        created_at
        updated_at
        is_active
        active_from
        active_to
        items {
            ...MenuItem
        }
        created_by {
            id
            name
        }
        updated_by {
            id
            name
        }
    }
    ${menuItemFragment}
`;
