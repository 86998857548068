import { gql } from "@apollo/client";
import { orderFragment } from "../fragments/orderFragment";

export const getOrderByIdQuery = gql`
    query getOrderById($increment_id: Int!) {
        order(increment_id: $increment_id) {
            ...Order
        }
    }
    ${orderFragment}
`;
