import React from "react";

import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { PageCard, TitleBar } from "@bigfish/admin-ui/components";
import { Button, Icon } from "@bigfish/admin-ui/core";

import { PermissionType } from "Api/graphql/admin/types";
import { PageLayout } from "Components/PageLayout";
import { I18n } from "I18n/I18n";
import { ApplicationState } from "Redux/rootReducer";
import { AuthSelectors } from "Redux/selectors/authSelectors";
import { Path } from "Utils/Path";

import ProductBundlesListDataGrid from "./ProductBundlesListDataGrid";

type ReduxProps = {
    permissions: PermissionType[];
    isSuperadmin: boolean;
};

type Props = RouteComponentProps & ReduxProps;

class ProductBundlesListPage extends React.Component<Props> {
    private onAddClick = (): void => {
        this.props.history.push(Path.productBundleCreate);
    };

    public render() {
        return (
            <PageLayout>
                <TitleBar
                    title={I18n.formatMessage({ id: "pages.productBundles.title" })}
                    rightButtonsComponent={
                        (this.props.isSuperadmin || this.props.permissions.includes(PermissionType.product_list_create)) && (
                            <Button startIcon={<Icon className="fa fa-plus-circle" />} variant="contained" size="medium" color="secondary" onClick={this.onAddClick}>
                                {I18n.formatMessage({ id: "pages.productBundles.addButton" })}
                            </Button>
                        )
                    }
                />
                <PageCard.Container>
                    <ProductBundlesListDataGrid />
                </PageCard.Container>
            </PageLayout>
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { permissions: AuthSelectors.getPermissions(state.auth), isSuperadmin: AuthSelectors.isSuperadmin(state.auth) };
};

export default withRouter(connect(mapStateToProps)(ProductBundlesListPage));
