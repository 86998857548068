import React from "react";
import { Form } from "Components/Form";
import { Button, FormControl, FormHelperText, Grid, Icon, TextField, Typography, Box, InputLabel, Select, OutlinedInput, Chip } from "@bigfish/admin-ui/core";
import { Field, FieldProps, FormikProps } from "formik";
import { Validator } from "Utils/Validator";
import { I18n } from "Src/i18n/I18n";
import { I18nHelpers } from "I18n/I18nHelpers";
import { FullscreenLoader, PageCard, SavePanel } from "@bigfish/admin-ui/components";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import { Admin } from "Api/graphql/auth/types";

export type MyProfileFormValues = {
    name: string;
};

type Props = {
    admin: Admin | null;
    formProps: FormikProps<MyProfileFormValues>;
    onChangePasswordButtonClick: () => void;
};

export const MyProfileForm = (props: Props) => {
    return (
        <Form formProps={props.formProps}>
            <PageCard.Heading title={I18n.formatMessage({ id: "pages.myProfile.form.title" })} />
            <Field name="name" validate={I18nHelpers.formatValidator(Validator.profileName)}>
                {({ field, meta }: FieldProps) => (
                    <FormControl fullWidth error={!!meta.error}>
                        <TextField
                            type="text"
                            label={I18n.formatMessage({ id: "pages.myProfile.form.name.label" })}
                            fullWidth
                            variant="outlined"
                            required
                            error={!!meta.error}
                            {...field}
                        />
                        <FormHelperText>
                            {meta.error} {I18n.formatMessage({ id: "common.required" })}
                        </FormHelperText>
                    </FormControl>
                )}
            </Field>
            <Box mt="30px">
                <FormControl>
                    {props.admin?.email && (
                        <div>
                            <Typography color="textSecondary" gutterBottom>
                                {I18n.formatMessage({ id: "pages.myProfile.form.email.label" })}
                            </Typography>
                            <Typography>{props.admin.email}</Typography>
                        </div>
                    )}
                    {props.admin?.phone && (
                        <div>
                            <Box my="30px" />
                            <Typography color="textSecondary" gutterBottom>
                                {I18n.formatMessage({ id: "pages.myProfile.form.phone.label" })}
                            </Typography>
                            <Typography>{props.admin.phone}</Typography>
                        </div>
                    )}
                </FormControl>
            </Box>

            {props.admin?.last_login && (
                <div>
                    <Box mt="30px" />

                    <Typography color="textSecondary" gutterBottom>
                        {I18n.formatMessage({ id: "pages.admin.form.lastLoginLabel" })}
                    </Typography>
                    <Typography variant="subtitle1">{props.admin.last_login ? DateUtils.format(props.admin.last_login, DateFormat.minuteDateTime) : ""}</Typography>
                </div>
            )}

            <Box mt="30px" />

            {props.admin?.id && (
                <div>
                    <Box mt="30px" />

                    <Typography color="textSecondary" gutterBottom>
                        {I18n.formatMessage({ id: "pages.admin.form.idLabel" })}
                    </Typography>
                    <Typography variant="subtitle1">{props.admin.id}</Typography>
                </div>
            )}

            <Box my="30px">
                <Button
                    startIcon={<Icon className="fas fa-redo-alt" style={{ fontSize: 14 }} />}
                    variant="outlined"
                    color="primary"
                    disabled={props.formProps.isSubmitting}
                    onClick={props.onChangePasswordButtonClick}
                >
                    {I18n.formatMessage({ id: "pages.myProfile.form.changePassword" })}
                </Button>
            </Box>

            <Box mt="30px">
                <PageCard.Heading title={I18n.formatMessage({ id: "pages.admin.form.roles" })} />
            </Box>

            {props.admin?.roles && (
                <FormControl variant="outlined" fullWidth>
                    <InputLabel>{I18n.formatMessage({ id: "pages.admin.form.roles" })}</InputLabel>
                    <Select
                        labelId="multiselect-input"
                        id="multiselect-input"
                        multiple
                        disabled
                        input={<OutlinedInput id="multiselect-input" label={I18n.formatMessage({ id: "pages.admin.form.roles" })} />}
                        value={props.admin.roles.map(v => v.title)}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        renderValue={(selected: string[]) => {
                            return (
                                <Box>
                                    {selected.map((value: string) => (
                                        <Chip className="multiselect-chip" key={value} label={value} clickable={false} />
                                    ))}
                                </Box>
                            );
                        }}
                    />
                </FormControl>
            )}

            {props.formProps.dirty && (
                <SavePanel>
                    <Grid container justify="space-between">
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                props.formProps.resetForm();
                            }}
                            disabled={props.formProps.isSubmitting}
                        >
                            {I18n.formatMessage({ id: "common.cancel" })}
                        </Button>
                        <Button type="submit" startIcon={<Icon className="fa fa-save" />} variant="contained" color="secondary" disabled={props.formProps.isSubmitting}>
                            {I18n.formatMessage({ id: "common.save" })}
                        </Button>
                    </Grid>
                </SavePanel>
            )}
            <FullscreenLoader visible={props.formProps.isSubmitting} />
        </Form>
    );
};
