import React from "react";
import { AdminLayout, SimpleAppBar } from "@bigfish/admin-ui/components";
import { brand } from "Utils/Brand";
import { version } from "Utils/Version";
import { Path } from "Utils/Path";

type Props = {
    children: JSX.Element | JSX.Element[];
};

export const MinimalLayout = (props: Props) => {
    return (
        <AdminLayout topComponent={<SimpleAppBar linkProps={{ href: Path.dashboard }} />} brandSettings={brand} version={`v${version}`}>
            {props.children}
        </AdminLayout>
    );
};
