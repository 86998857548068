import { gql } from "@apollo/client";

// TODO: TEMP: https://bigfishhu.atlassian.net/browse/ROS-4521
// export const updateShipmentStatusMutation = gql`
//     mutation updateShipmentStatus($order_ids: [Int!]!, $status: ShipmentStatusStoreEnum!, $csrf: String) {
//         updateShipmentStatus(order_ids: $order_ids, status: $status, csrf: $csrf) {
//             id
//         }
//     }
// `;
export const updateShipmentStatusMutation = gql`
    mutation updateShipmentStatus($order_ids: [Int!]!, $status: ShipmentStatusStoreEnum!, $csrf: String) {
        updateShipmentStatus(order_ids: $order_ids, status: $status, csrf: $csrf) {
            id
        }
    }
`;
