import { gql } from "@apollo/client";

export const productListListItemFragment = gql`
    fragment ProductListListItem on ProductList {
        id
        name
        mode
        active_from
        active_to
        is_visible
        type
    }
`;
