import React, { useCallback, useState } from "react";
import { Form, FormType } from "Components/Form";
import { Button, Grid, Icon, TextField, Box, Typography, Checkbox, FormControl, FormControlLabel, FormHelperText } from "@bigfish/admin-ui/core";
import { Field, FieldProps, FormikProps } from "formik";
import { Validator } from "Utils/Validator";
import { I18n } from "Src/i18n/I18n";
import { I18nHelpers } from "I18n/I18nHelpers";
import { PageCard, SavePanel } from "@bigfish/admin-ui/components";
import { Path } from "Utils/Path";
import { useHistory } from "react-router-dom";
import { BenefitListItem, DivisionListItem } from "Api/graphql/admin/types";
import DivisionTable from "./DivisionTable";
import AddBenefitModal from "Components/BenefitTable/AddBenefitModal";
import BenefitTable from "Components/BenefitTable/BenefitTable";

export type DepartmentFormValues = {
    name: string;
    is_grouped: boolean;
    is_filter_enabled: boolean;
    is_map_enabled: boolean;
    divisions: DivisionListItem[];
    benefits: BenefitListItem[];
};

type Props = {
    formType: FormType;
    formProps: FormikProps<DepartmentFormValues>;
};

export const DepartmentForm = (props: Props) => {
    const history = useHistory();
    const onCancelClick = useCallback(() => {
        history.push(Path.departmentList);
    }, [history]);
    const [isAddModalVisible, setAddModalVisible] = useState<boolean>(false);

    return (
        <Form formProps={props.formProps}>
            <Field name="name" validate={I18nHelpers.formatValidator(Validator.departmentName)}>
                {({ field, meta }: FieldProps) => (
                    <TextField
                        type="text"
                        label={I18n.formatMessage({ id: "pages.department.form.title.label" })}
                        fullWidth
                        variant="outlined"
                        required
                        helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                        error={meta.touched && !!meta.error}
                        {...field}
                    />
                )}
            </Field>

            <Field name="is_grouped">
                {({ field, meta }: FieldProps) => (
                    <Box mt="30px">
                        <FormControl error={meta.touched && !!meta.error}>
                            <Typography color="textSecondary" gutterBottom>
                                {I18n.formatMessage({ id: "pages.department.form.is_grouped" })}
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        {...field}
                                        checked={field.value}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                                            props.formProps.setFieldValue(field.name, checked);
                                            if (!checked) {
                                                props.formProps.setFieldValue("is_filter_enabled", false);
                                                props.formProps.setFieldValue("is_map_enabled", false);
                                            }
                                        }}
                                    />
                                }
                                label={I18n.formatMessage({ id: "common.yes" })}
                            />
                        </FormControl>
                    </Box>
                )}
            </Field>

            <Field name="is_filter_enabled">
                {({ field, meta }: FieldProps) => (
                    <Box mt="30px">
                        <FormControl error={meta.touched && !!meta.error}>
                            <Typography color="textSecondary" gutterBottom>
                                {I18n.formatMessage({ id: "pages.department.form.is_filter_enabled" })}
                            </Typography>
                            <FormControlLabel
                                control={<Checkbox {...field} checked={field.value} />}
                                label={I18n.formatMessage({ id: "common.yes" })}
                                disabled={!props.formProps.values.is_grouped}
                            />
                        </FormControl>
                    </Box>
                )}
            </Field>

            <Field name="is_map_enabled">
                {({ field, meta }: FieldProps) => (
                    <Box mt="30px">
                        <FormControl error={meta.touched && !!meta.error}>
                            <Typography color="textSecondary" gutterBottom>
                                {I18n.formatMessage({ id: "pages.department.form.is_map_enabled" })}
                            </Typography>
                            <FormControlLabel
                                control={<Checkbox {...field} checked={field.value} />}
                                label={I18n.formatMessage({ id: "common.yes" })}
                                disabled={!props.formProps.values.is_grouped}
                            />
                        </FormControl>
                    </Box>
                )}
            </Field>

            {props.formType === FormType.edit && (
                <Field name="divisions">
                    {({ field, meta }: FieldProps) => (
                        <Box my="30px">
                            <FormControl fullWidth error={meta.touched && !!meta.error}>
                                <Typography color="textSecondary" gutterBottom>
                                    {I18n.formatMessage({ id: "pages.department.form.divisions.label" })}
                                </Typography>
                                <DivisionTable divisions={field.value} />
                            </FormControl>
                        </Box>
                    )}
                </Field>
            )}

            <PageCard.Heading title={I18n.formatMessage({ id: "pages.department.form.benefits.label" })} />

            <Box mt="30px">
                <Button variant="outlined" color="primary" startIcon={<Icon className="fa fa-plus-circle" />} size="medium" onClick={() => setAddModalVisible(true)}>
                    {I18n.formatMessage({ id: "common.add" })}
                </Button>
                <Field name="benefits">
                    {({ field }: FieldProps) => (
                        <AddBenefitModal
                            onClose={() => setAddModalVisible(false)}
                            products={field.value}
                            onProductsSelected={(selectedProducts: BenefitListItem[]): void => {
                                props.formProps.setFieldValue("benefits", selectedProducts);
                                setAddModalVisible(false);
                            }}
                            open={isAddModalVisible}
                        />
                    )}
                </Field>
            </Box>

            <Box my="30px">
                <Field name="benefits">
                    {({ field, meta }: FieldProps) => (
                        <>
                            <BenefitTable
                                benefits={field.value}
                                onRemoveClick={(id: string) => {
                                    props.formProps.setFieldValue(
                                        field.name,
                                        props.formProps.values.benefits.filter((p: BenefitListItem) => p.id !== id)
                                    );
                                }}
                            />
                            <input style={{ visibility: "hidden" }} name={field.name} />
                            <FormHelperText error={meta.touched && !!meta.error}>{meta.touched && meta.error}</FormHelperText>
                        </>
                    )}
                </Field>
            </Box>

            <SavePanel>
                <Grid container justify="space-between">
                    <Button variant="outlined" color="primary" onClick={onCancelClick}>
                        {I18n.formatMessage({ id: "common.cancel" })}
                    </Button>
                    <Button
                        type="submit"
                        startIcon={<Icon className="fas fa-save" />}
                        variant="contained"
                        color="secondary"
                        disabled={!props.formProps.dirty || props.formProps.isSubmitting}
                    >
                        {I18n.formatMessage({ id: "common.save" })}
                    </Button>
                </Grid>
            </SavePanel>
        </Form>
    );
};
