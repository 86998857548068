import { gql } from "@apollo/client";

export const productParamSimpleFragment = gql`
    fragment ProductParamSimple on ProductParamSimple {
        id
        name
        is_filter
        created_at
        updated_at
    }
`;
