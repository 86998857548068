import React, { useCallback, useState } from "react";
import { DefaultModal } from "@bigfish/admin-ui/components";
import { Button, Typography } from "@bigfish/admin-ui/core";
import styled from "styled-components";

type Props = {
    isVisible: boolean;
    title: React.ReactNode;
    description: React.ReactNode;
    onClose: () => void;
    onFunctionClick: () => void | Promise<void>;
    rightButtonLabel?: string;
    leftButtonLabel?: string;
    isRightButtonDisabled?: boolean;
};

export const FunctionConfirmModal = (props: Props) => {
    const [isLoading, setLoading] = useState(false);

    const onFunctionClick = useCallback(async (): Promise<void> => {
        if (!props.rightButtonLabel) return;

        setLoading(true);
        await props.onFunctionClick();
        setLoading(false);
    }, [props]);

    const onClose = useCallback((): void => {
        if (isLoading) {
            return;
        }
        props.onClose();
    }, [isLoading, props]);

    return (
        <FunctionConfirmModalWrapper>
            <DefaultModal
                title={props.title}
                rightButtonsComponent={
                    props.rightButtonLabel ? (
                        <Button variant="contained" color="primary" onClick={onFunctionClick} disabled={!!props.isRightButtonDisabled || isLoading}>
                            {props.rightButtonLabel}
                        </Button>
                    ) : null
                }
                leftButtonsComponent={
                    props.leftButtonLabel ? (
                        <Button variant="outlined" color="primary" onClick={onClose} disabled={isLoading}>
                            {props.leftButtonLabel}
                        </Button>
                    ) : null
                }
                open={props.isVisible}
                onClose={onClose}
            >
                <Typography variant="body2" color="textSecondary">
                    {props.description}
                </Typography>
            </DefaultModal>
        </FunctionConfirmModalWrapper>
    );
};

const FunctionConfirmModalWrapper = styled.div`
    .MuiCard-root {
        overflow: hidden !important;
    }
`;
