import { gql } from "@apollo/client";
import { productVariantChildFragment } from "./productVariantChildFragment";

export const productVariantFragment = gql`
    fragment ProductVariant on Product {
        id
        variant_product_param_id
        children {
            ...ProductVariantChild
        }
        name
        slug
        is_active
        active_from
        active_to
        created_at
        created_by {
            id
            name
        }
        updated_at
        updated_by {
            id
            name
        }
    }
    ${productVariantChildFragment}
`;
