import React from "react";

import { withSnackbar, WithSnackbarProps } from "notistack";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { PageCard, TitleBar } from "@bigfish/admin-ui/components";

import { PageLayout } from "Components/PageLayout";
import { I18n } from "I18n/I18n";

import ProductParamListDataGrid from "./ProductParamListDataGrid";

type Props = RouteComponentProps & WithSnackbarProps;

class ProductParamListPage extends React.Component<Props> {
    public render() {
        return (
            <PageLayout>
                <TitleBar title={I18n.formatMessage({ id: "pages.productParamList.title" })} />
                <PageCard.Container>
                    <ProductParamListDataGrid />
                </PageCard.Container>
            </PageLayout>
        );
    }
}

export default withSnackbar(withRouter(ProductParamListPage));
