import { gql } from "@apollo/client";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";
import { divisionListItemFragment } from "../fragments/divisionListItemFragment";

export const divisionsQuery = gql`
    query divisions($filters: DivisionFilters, $sortBy: DivisionSort, $first: Int!, $page: Int) {
        divisions(first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            data {
                ...DivisionListItem
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${divisionListItemFragment}
    ${paginatorInfoFragment}
`;
