import { gql } from "@apollo/client";
import { productCategorySelectItemFieldsFragment } from "../fragments/productCategorySelectItemFieldsFragment";
import { childProductCategorySelectItemFieldsFragment } from "../fragments/childProductCategorySelectItemFieldsFragment";

export const productCategorySelectItemFragment = gql`
    fragment ProductCategorySelectItem on ProductCategorySimple {
        ...ProductCategorySelectItemFields
        ...ChildProductCategorySelectItemFields
    }
    ${productCategorySelectItemFieldsFragment}
    ${childProductCategorySelectItemFieldsFragment}
`;
