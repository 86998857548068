import React, { useCallback, useMemo, useState } from "react";
import uniqWith from "lodash/uniqWith";
import { I18n } from "I18n/I18n";
import { Box, Button, Typography } from "@bigfish/admin-ui/core";
import { ProductVariantChild } from "Api/graphql/admin/types";
import { createModal } from "Components/createModal";
import ProductVariantChildDataGrid from "./ProductVariantChildDataGrid";

const AddProductModal = createModal({ modalWidth: 1280 });
interface Props {
    description?: string;
    initialSelectedProducts: ProductVariantChild[];
    onClose: () => void;
    onAddClick: (selectedProducts: ProductVariantChild[]) => void;
}

export const AddProductVariantChildDialog = ({ description, initialSelectedProducts, onClose, onAddClick }: Props) => {
    const [selectedProducts, setSelectedProducts] = useState(initialSelectedProducts);
    const selectedProductIds = useMemo(() => selectedProducts.map(p => p.id), [selectedProducts]);

    const handleAddProducts = useCallback(
        (productsToAdd: ProductVariantChild[]) => {
            setSelectedProducts(uniqWith([...selectedProducts, ...productsToAdd], (p1, p2) => p1.id === p2.id));
        },
        [selectedProducts]
    );

    const handleRemoveProducts = useCallback(
        (productsToRemove: ProductVariantChild[]) => {
            setSelectedProducts(selectedProducts.filter(product => !productsToRemove.some(productToRemove => productToRemove.id === product.id)));
        },
        [selectedProducts]
    );

    return (
        <AddProductModal
            title={I18n.formatMessage({ id: "components.addProductVariantChildDialog.title" })}
            leftButtonsComponent={<Typography variant="subtitle2">{I18n.formatMessage({ id: "common.selectedItems" }, { selectedItems: selectedProductIds.length })}</Typography>}
            rightButtonsComponent={
                <>
                    <Button variant="outlined" color="primary" onClick={onClose} style={{ marginRight: "20px" }}>
                        {I18n.formatMessage({ id: "common.cancel" })}
                    </Button>
                    <Button variant="contained" color="primary" onClick={() => onAddClick(selectedProducts)}>
                        {I18n.formatMessage({ id: "common.add" })}
                    </Button>
                </>
            }
            open
            onClose={onClose}
        >
            {description && (
                <Box mb="30px">
                    <Typography variant="body2" color="textSecondary">
                        {description}
                    </Typography>
                </Box>
            )}
            <ProductVariantChildDataGrid
                key="add-product-dialog-data-grid"
                selectedProductIds={selectedProductIds}
                onAddProducts={handleAddProducts}
                onRemoveProducts={handleRemoveProducts}
                minWidth={500}
            />
        </AddProductModal>
    );
};
