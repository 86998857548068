import React, { useCallback } from "react";

import { Field, FieldProps, FormikProps } from "formik";
import { useHistory } from "react-router-dom";

import { FullscreenLoader, PageCard, SavePanel } from "@bigfish/admin-ui/components";
import { Box, Button, Grid, Icon, InputAdornment, TextField, Typography } from "@bigfish/admin-ui/core";
import { ArrowBackRounded, Clear } from "@bigfish/admin-ui/icons";

import { ProductParam, ProductParamUpdateInput } from "Api/graphql/admin/types";
import { Form, FormType } from "Components/Form";
import { I18n } from "Src/i18n/I18n";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import { Path } from "Utils/Path";

export type ProductParamFormValues = ProductParamUpdateInput & { options: { id: number; newLabel: string }[] };

type Props = {
    formType: FormType;
    formProps: FormikProps<ProductParamFormValues>;
    productParam: ProductParam;
};

export const ProductParamForm = (props: Props) => {
    const history = useHistory();

    const onCancelClick = useCallback(() => {
        history.push(Path.productParamList);
    }, [history]);

    return (
        <Form formProps={props.formProps}>
            <PageCard.Container>
                <PageCard.Heading title={I18n.formatMessage({ id: "common.form.basicData" })} />

                <Grid container alignItems="center">
                    <Grid item xs={6}>
                        <Typography color="textSecondary" gutterBottom>
                            {I18n.formatMessage({ id: "pages.productParam.form.nameLabelEvo" })}
                        </Typography>
                        <Typography variant="body2">{props.productParam.evo_name}</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <ArrowBackRounded />
                    </Grid>
                    <Grid item xs={5}>
                        <Field name="name">
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "pages.productParam.form.nameLabel" })}
                                    fullWidth
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end" style={{ cursor: "pointer" }} onClick={() => props.formProps.setFieldValue("name", "")}>
                                                <Clear />
                                            </InputAdornment>
                                        ),
                                    }}
                                    error={meta.touched && !!meta.error}
                                    {...field}
                                />
                            )}
                        </Field>
                    </Grid>
                </Grid>

                {/* TODO: temporarily disabled field */}
                {/* <Box mt="30px" />

                <Field name="is_filter">
                    {({ field, meta }: FieldProps) =>
                        field.value !== undefined && (
                            <Box mt="15px" ml="10px">
                                <FormControl error={meta.touched && !!meta.error}>
                                    <FormControlLabel
                                        control={<Checkbox {...field} checked={field.value} />}
                                        label={I18n.formatMessage({ id: "pages.productParam.form.isFilterLabel" })}
                                    />
                                </FormControl>
                                <CustomFormHelperText meta={meta} />
                            </Box>
                        )
                    }
                </Field> */}
            </PageCard.Container>

            {props.productParam.options.length > 0 && (
                <PageCard.Container>
                    <PageCard.Heading title={I18n.formatMessage({ id: "pages.productParam.form.optionsTitle" })} />
                    {props.productParam.options.map((option, i) => {
                        return (
                            <Box mb="30px" key={option.id}>
                                <Grid container alignItems="center">
                                    <Grid item xs={6}>
                                        <Typography color="textSecondary" gutterBottom>
                                            {I18n.formatMessage({ id: "pages.productParam.form.erpLabel" }, { number: i + 1 })}
                                        </Typography>
                                        <Typography variant="body2">{option.label}</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <ArrowBackRounded />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Field name={`options[${i}].newLabel`}>
                                            {({ field, meta }: FieldProps) => (
                                                <TextField
                                                    type="text"
                                                    label={I18n.formatMessage({ id: "pages.productParam.form.optionLabel" }, { number: i + 1 })}
                                                    fullWidth
                                                    variant="outlined"
                                                    required
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment
                                                                position="end"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => props.formProps.setFieldValue(`options[${i}].newLabel`, "")}
                                                            >
                                                                <Clear />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    error={meta.touched && !!meta.error}
                                                    {...field}
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                </Grid>
                            </Box>
                        );
                    })}
                </PageCard.Container>
            )}

            <PageCard.Container>
                <PageCard.Heading title={I18n.formatMessage({ id: "common.statistics.title" })} />
                <Box mt="30px" mb="30px">
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Typography color="textSecondary" gutterBottom>
                                {I18n.formatMessage({ id: "common.statistics.updated_at" })}
                            </Typography>
                            <Typography variant="body2">{DateUtils.format(props.productParam?.updated_at, DateFormat.minuteDateTime)}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography color="textSecondary" gutterBottom>
                                {I18n.formatMessage({ id: "common.statistics.updated_by" })}
                            </Typography>
                            <Typography variant="body2">{props.productParam.updated_by?.name ?? ""}</Typography>
                        </Grid>
                    </Grid>
                </Box>
            </PageCard.Container>

            <SavePanel>
                <Grid container justify="space-between">
                    <Button variant="outlined" color="primary" onClick={onCancelClick}>
                        {I18n.formatMessage({ id: "common.cancel" })}
                    </Button>
                    <Button
                        type="submit"
                        startIcon={<Icon className="fas fa-save" />}
                        variant="contained"
                        color="secondary"
                        disabled={!props.formProps.dirty || !props.formProps.isValid}
                    >
                        {I18n.formatMessage({ id: `common.${props.formType === FormType.create ? "create" : "save"}` })}
                    </Button>
                </Grid>
            </SavePanel>
            <FullscreenLoader visible={props.formProps.isSubmitting} />
        </Form>
    );
};
