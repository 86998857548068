import React from "react";

import { Formik, FormikHelpers } from "formik";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { DispatchProp } from "react-redux";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";

import { ActiveBreadcrumbItem, TitleBar } from "@bigfish/admin-ui/components";
import { Box } from "@bigfish/admin-ui/core";

import { Product, ProductParamStructure } from "Api/graphql/admin/types";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Breadcrumbs } from "Components/Breadcrumbs";
import { Form, FormType } from "Components/Form";
import { Link } from "Components/Link";
import { Loading } from "Components/Loading";
import { PageLayout } from "Components/PageLayout";
import Prompt from "Components/Prompt";
import { I18n } from "I18n/I18n";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import { Path } from "Utils/Path";

import { bundleValidator, ProductBundleForm, ProductBundleFormValues } from "./ProductBundleForm";
import { ApiUtils } from "Utils/ApiUtils";

type RouteParams = {
    id?: string;
};

type Props = DispatchProp & WithSnackbarProps & RouteComponentProps<RouteParams>;

type State = {
    isLoading: boolean;
    product: Product | null;
    productParamStructures: ProductParamStructure[];
};

export enum ProductParamManualValue {
    description = "description",
    og_image = "og_image",
    og_title = "og_title",
    og_description = "og_description",
    meta_description = "meta_description",
}

class ProductBundleEditPage extends React.Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        product: null,
        productParamStructures: [],
    };

    public async componentDidMount(): Promise<void> {
        try {
            const id = Number.parseInt(this.props.match.params.id!, 10);
            const product = await Api.getProductById(id);
            const productParamStructures = await Api.listProductParamStructures();
            this.setState({
                product,
                productParamStructures,
                isLoading: false,
            });
        } catch (error) {
            this.props.enqueueSnackbar(error.message, { variant: "error" });
            this.props.history.push(Path.productBundles);
        }
    }

    private onSubmit = async (values: ProductBundleFormValues, formikHelpers: FormikHelpers<ProductBundleFormValues>): Promise<boolean> => {
        const getParams = () => {
            const returnedParams = [...values.params];

            // description
            const foundDescriptionParam = returnedParams.find(p => p.product_param_id === ProductParamManualValue.description);
            foundDescriptionParam
                ? (foundDescriptionParam.values = [values.description ?? ""])
                : returnedParams.push({ product_param_id: ProductParamManualValue.description, values: [values.description ?? ""] });

            // og_image
            const foundOgImageParam = returnedParams.find(p => p.product_param_id === ProductParamManualValue.og_image);
            foundOgImageParam
                ? (foundOgImageParam.values = [values.og_image ?? ""])
                : returnedParams.push({ product_param_id: ProductParamManualValue.og_image, values: [values.og_image ?? ""] });

            // og_title
            const foundOgTitleParam = returnedParams.find(p => p.product_param_id === ProductParamManualValue.og_title);
            foundOgTitleParam
                ? (foundOgTitleParam.values = [values.og_title ?? ""])
                : returnedParams.push({ product_param_id: ProductParamManualValue.og_title, values: [values.og_title ?? ""] });

            // og_description
            const foundOgDescriptionParam = returnedParams.find(p => p.product_param_id === ProductParamManualValue.og_description);
            foundOgDescriptionParam
                ? (foundOgDescriptionParam.values = [values.og_description ?? ""])
                : returnedParams.push({ product_param_id: ProductParamManualValue.og_description, values: [values.og_description ?? ""] });

            // meta_description
            const foundMetaDescriptionParam = returnedParams.find(p => p.product_param_id === ProductParamManualValue.meta_description);
            foundMetaDescriptionParam
                ? (foundMetaDescriptionParam.values = [values.meta_description ?? ""])
                : returnedParams.push({ product_param_id: ProductParamManualValue.meta_description, values: [values.meta_description ?? ""] });

            return returnedParams;
        };

        try {
            const product = await Api.updateProductBundle(this.state.product!.id, {
                name: values.name,
                slug: values.slug,
                bundle_promotion_id: values.bundle_promotion_id,
                product_brand_id: values.product_brand_id ? values.product_brand_id : null,
                is_active: values.is_active,
                active_from: values.active_from ? DateUtils.format(values.active_from, DateFormat.apiDateTime) : null,
                active_to: values.active_to ? DateUtils.format(values.active_to, DateFormat.apiDateTime) : null,
                images: values.images.map(i => {
                    return {
                        id: i.id,
                        is_new: i.is_new,
                        source: i.source,
                        filename_medialib: i.generic_url,
                        delete: i.delete,
                        is_active: i.is_active,
                    };
                }),
                params: getParams(),
            });
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.productBundle.onSubmit.success" }), { variant: "success" });
            this.setState({ product });
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.productBundle.onSubmit.error" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
        }
        return false;
    };

    private getBreadcrumb = () => {
        return (
            <Breadcrumbs>
                <Link to={Path.productCatalog} color="inherit">
                    {I18n.formatMessage({ id: "pages.productBundle.breadcrumb.title" })}
                </Link>
                {this.state.product?.name && <ActiveBreadcrumbItem aria-current="page">{this.state.product.name}</ActiveBreadcrumbItem>}
            </Breadcrumbs>
        );
    };

    public render() {
        const { isLoading, product } = this.state;

        if (isLoading) {
            return <Loading breadcrumb={this.getBreadcrumb()} />;
        }

        if (!product) {
            return <Redirect to={Path.productBundles} />;
        }

        const initialValues: ProductBundleFormValues = {
            name: product.name ?? "",
            slug: product.slug ?? "",
            bundle_promotion_id: product.bundle_promotion_id ?? 0,
            product_brand_id: product.brand?.id ?? null,
            is_active: product.is_active,
            active_from: product.active_from ? DateUtils.format(product.active_from, DateFormat.input) : null,
            active_to: product.active_to ? DateUtils.format(product.active_to, DateFormat.input) : null,
            images: ApiUtils.convertProductImagesIntoInput(product.images),
            params: product.param_values.map(p => {
                return {
                    product_param_id: p.product_param_id,
                    values: p.values,
                };
            }),
            og_image: product.param_values.find(p => p.product_param_id === "og_image")?.values[0] ?? undefined,
            og_title: product.param_values.find(p => p.product_param_id === "og_title")?.values[0] ?? undefined,
            og_description: product.param_values.find(p => p.product_param_id === "og_description")?.values[0] ?? undefined,
            meta_description: product.param_values.find(p => p.product_param_id === "meta_description")?.values[0] ?? undefined,
            description: product.param_values.find(p => p.product_param_id === "description")?.values[0] ?? undefined,
        };

        return (
            <PageLayout breadcrumb={this.getBreadcrumb()}>
                <TitleBar title={product.name} />
                <Formik initialValues={initialValues} onSubmit={this.onSubmit} validate={bundleValidator()} validateOnBlur={false} enableReinitialize={true}>
                    {props => (
                        <>
                            <ProductBundleForm formType={FormType.edit} product={product} formProps={props} />
                            <Prompt when={props.dirty} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props)} />
                        </>
                    )}
                </Formik>
                <Box mt="40px" />
            </PageLayout>
        );
    }
}

export default withSnackbar(withRouter(ProductBundleEditPage));
