import { ActiveBreadcrumbItem, TitleBar } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { Product, ProductGratisInput } from "Api/graphql/admin/types";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { Loading } from "Components/Loading";
import { PageLayout } from "Components/PageLayout";
import { Link } from "Components/Link";
import { Breadcrumbs } from "Components/Breadcrumbs";
import { DispatchProp } from "react-redux";
import Prompt from "Components/Prompt";
import { Box } from "@bigfish/admin-ui/core";
import { ProductGratisForm } from "./ProductGratisForm";
import { I18n } from "I18n/I18n";
import { ApiError } from "Api/ApiError";
import { Form } from "Components/Form";
import { DateFormat, DateUtils } from "Utils/DateUtils";

type RouteParams = {
    id?: string;
};

type Props = DispatchProp & WithSnackbarProps & RouteComponentProps<RouteParams>;

type State = {
    isLoading: boolean;
    product: Product | null;
};

class ProductGratisEditPage extends React.Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        product: null,
    };

    public async componentDidMount(): Promise<void> {
        try {
            const id = Number.parseInt(this.props.match.params.id!, 10);
            const product = await Api.getProductById(id);
            this.setState({ product, isLoading: false });
        } catch (error) {
            this.props.enqueueSnackbar(error.message, { variant: "error" });
            this.props.history.push(Path.productGratisList);
        }
    }

    private onSubmit = async (values: ProductGratisInput, formikHelpers: FormikHelpers<ProductGratisInput>): Promise<boolean> => {
        if (!this.state.product) {
            return false;
        }
        try {
            const product = await Api.updateProductGratis(this.state.product.id, {
                is_force_active: values.is_force_active,
                active_from: values.active_from ? DateUtils.format(values.active_from, DateFormat.apiDateTime) : values.active_from,
                active_to: values.active_to ? DateUtils.format(values.active_to, DateFormat.apiDateTime) : values.active_to,
            });
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.productGratis.edit.succeed" }), { variant: "success" });
            this.setState({ product });
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.productGratis.edit.failed" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
        }
        return false;
    };

    private getBreadcrumb = () => {
        return (
            <Breadcrumbs>
                <Link to={Path.productGratisList} color="inherit">
                    {I18n.formatMessage({ id: "pages.productGratis.breadcrumb.title" })}
                </Link>
                {this.state.product?.name && <ActiveBreadcrumbItem aria-current="page">{this.state.product.name}</ActiveBreadcrumbItem>}
            </Breadcrumbs>
        );
    };

    public render() {
        const { isLoading, product } = this.state;

        if (isLoading) {
            return <Loading breadcrumb={this.getBreadcrumb()} />;
        }

        if (!product) {
            return <Redirect to={Path.productGratisList} />;
        }

        const initialValues: ProductGratisInput = {
            is_force_active: product.is_force_active,
            active_from: product.active_from,
            active_to: product.active_to,
        };

        return (
            <PageLayout breadcrumb={this.getBreadcrumb()}>
                <TitleBar title={product.name} />
                <Formik initialValues={initialValues} onSubmit={this.onSubmit} validateOnBlur={false} enableReinitialize={true}>
                    {props => (
                        <>
                            <ProductGratisForm product={product} formProps={props} />
                            <Prompt when={props.dirty} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props)} />
                        </>
                    )}
                </Formik>
                <Box mt="40px" />
            </PageLayout>
        );
    }
}

export default withSnackbar(withRouter(ProductGratisEditPage));
