import React from "react";
import { I18n } from "I18n/I18n";
import { Box, Button, Grid } from "@bigfish/admin-ui/core";
import { Api } from "Api/Api";
import { withSnackbar, WithSnackbarProps } from "notistack";
import "./styles.css";
import { FunctionConfirmModal } from "Components/FunctionConfirmModal";

enum CacheType {
    full = "full",
    clearProductCache = "clearProductCache",
    clearProductCategoryCache = "clearProductCategoryCache",
    clearProductPriceCache = "clearProductPriceCache",
}

type State = {
    cacheTypeUnderConfirmation: CacheType | null;
};

class CacheClearing extends React.Component<WithSnackbarProps, State> {
    public readonly state: State = {
        cacheTypeUnderConfirmation: null,
    };

    private clearCache = async (): Promise<void> => {
        try {
            await Api.clearCache();
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.cacheManagement.cacheClearing.full.success" }), { variant: "success" });
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.cacheManagement.cacheClearing.full.error" }), { variant: "error" });
        }
    };

    private clearProductCache = async (): Promise<void> => {
        try {
            await Api.clearProductCache();
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.cacheManagement.cacheClearing.clearProductCache.success" }), { variant: "success" });
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.cacheManagement.cacheClearing.clearProductCache.error" }), { variant: "error" });
        }
    };

    private clearProductCategoryCache = async (): Promise<void> => {
        try {
            await Api.clearProductCategoryCache();
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.cacheManagement.cacheClearing.clearProductCategoryCache.success" }), { variant: "success" });
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.cacheManagement.cacheClearing.clearProductCategoryCache.error" }), { variant: "error" });
        }
    };

    private clearProductPriceCache = async (): Promise<void> => {
        try {
            await Api.clearProductPriceCache();
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.cacheManagement.cacheClearing.clearProductPriceCache.success" }), { variant: "success" });
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.cacheManagement.cacheClearing.clearProductPriceCache.error" }), { variant: "error" });
        }
    };

    private clearCacheByType = (): void => {
        const { cacheTypeUnderConfirmation } = this.state;

        if (cacheTypeUnderConfirmation === null) return;

        switch (cacheTypeUnderConfirmation) {
            case CacheType.full:
                this.clearCache();
                break;
            case CacheType.clearProductCache:
                this.clearProductCache();
                break;
            case CacheType.clearProductCategoryCache:
                this.clearProductCategoryCache();
                break;
            case CacheType.clearProductPriceCache:
                this.clearProductPriceCache();
                break;
            default:
                break;
        }

        this.setState({ cacheTypeUnderConfirmation: null });
    };

    public render() {
        const { cacheTypeUnderConfirmation } = this.state;

        return (
            <div>
                <Box mt="30px" />
                <Grid container>
                    <Grid item className="cache-clearing-grid-item">
                        <Button variant="contained" color="secondary" onClick={() => this.setState({ cacheTypeUnderConfirmation: CacheType.full })}>
                            {I18n.formatMessage({ id: "pages.cacheManagement.cacheClearing.full" })}
                        </Button>
                    </Grid>
                    <Grid item className="cache-clearing-grid-item">
                        <Button variant="contained" color="secondary" onClick={() => this.setState({ cacheTypeUnderConfirmation: CacheType.clearProductCache })}>
                            {I18n.formatMessage({ id: "pages.cacheManagement.cacheClearing.clearProductCache" })}
                        </Button>
                    </Grid>
                    <Grid item className="cache-clearing-grid-item">
                        <Button variant="contained" color="secondary" onClick={() => this.setState({ cacheTypeUnderConfirmation: CacheType.clearProductCategoryCache })}>
                            {I18n.formatMessage({ id: "pages.cacheManagement.cacheClearing.clearProductCategoryCache" })}
                        </Button>
                    </Grid>
                    <Grid item className="cache-clearing-grid-item">
                        <Button variant="contained" color="secondary" onClick={() => this.setState({ cacheTypeUnderConfirmation: CacheType.clearProductPriceCache })}>
                            {I18n.formatMessage({ id: "pages.cacheManagement.cacheClearing.clearProductPriceCache" })}
                        </Button>
                    </Grid>
                </Grid>
                <FunctionConfirmModal
                    title={cacheTypeUnderConfirmation ? I18n.formatMessage({ id: `pages.cacheManagement.cacheClearing.confirmModal.title.${cacheTypeUnderConfirmation}` }) : ""}
                    description={I18n.formatMessage({ id: "pages.cacheManagement.cacheClearing.confirmModal.description" })}
                    isVisible={this.state.cacheTypeUnderConfirmation !== null}
                    onClose={() => {
                        this.setState({ cacheTypeUnderConfirmation: null });
                    }}
                    onFunctionClick={this.clearCacheByType}
                    leftButtonLabel={I18n.formatMessage({ id: "common.cancel" })}
                    rightButtonLabel={I18n.formatMessage({ id: "common.yes" })}
                />
            </div>
        );
    }
}

export default withSnackbar(CacheClearing);
