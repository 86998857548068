import React from "react";
import { Grid, Icon, IconButton } from "@bigfish/admin-ui/core";
import { PageLayout } from "Components/PageLayout";
import { PageCard, TitleBar } from "@bigfish/admin-ui/components";
import { I18n } from "I18n/I18n";
import { Api } from "Api/Api";
import { TypeComputedProps, TypeColumn } from "@inovua/reactdatagrid-community/types";
import { ApiError } from "Api/ApiError";
import { DocumentField, DocumentOrder, DocumentListItem } from "Api/graphql/admin/types";
import DataGrid from "Components/DataGrid/DataGrid";
import { WithSnackbarProps, withSnackbar } from "notistack";
import DeleteDocumentModal from "./DeleteDocumentModal";
import DocumentUploader from "Components/Inputs/DocumentUploader";
import { Clear } from "@bigfish/admin-ui/icons";
import { DataGridUrlQueryParser } from "Components/DataGrid/DataGridUrlQueryParser";
import { DataGridParams } from "Components/DataGrid/UrlQueryParser";
import { DataSource, FilterValue } from "Components/DataGrid/DataGridUtils";

type Props = WithSnackbarProps;

type State = {
    documentToDelete: DocumentListItem | null;
};

class DocumentListPage extends React.Component<Props, State> {
    private tableRef: React.MutableRefObject<TypeComputedProps | null> = React.createRef();

    public readonly state: State = {
        documentToDelete: null,
    };

    private filterValue: FilterValue[] = [];

    private urlQueryParser = new DataGridUrlQueryParser({
        filters: {},
        sortField: DocumentField,
    });

    private sortFieldToSortOrder = {
        [DocumentField.id]: "id",
    };

    private dataSource = async (props: DataGridParams<DocumentOrder, {}>): Promise<DataSource<DocumentListItem>> => {
        try {
            const result = await Api.documents({ first: props.limit, page: props.page, sortBy: props.sortBy });
            return { data: result.data, count: result.paginatorInfo.total };
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }
        return { data: [], count: 0 };
    };

    private onCloseDeleteModal = (reload?: boolean): void => {
        if (reload) {
            this.tableRef.current?.reload();
        }
        this.setState({ documentToDelete: null });
    };

    private getColumns = (): TypeColumn[] => {
        return [
            { name: "id", header: I18n.formatMessage({ id: "pages.documentList.grid.column.id" }), width: 132, sortable: true },
            {
                name: "file_name",
                header: I18n.formatMessage({ id: "pages.documentList.grid.column.file_name" }),
                defaultFlex: 1,
                sortable: false,
            },
            { name: "url", header: I18n.formatMessage({ id: "pages.documentList.grid.column.url" }), defaultFlex: 2, sortable: false },
            {
                name: "actions",
                header: "",
                sortable: false,
                width: 115,
                render: ({ data }: { data: DocumentListItem }) => {
                    const copyToClipBoard = () => {
                        this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.documentList.copyToClipboardSucceed" }), { variant: "success" });
                        navigator.clipboard.writeText(data.url);
                    };
                    const showModal = () => this.setState({ documentToDelete: data });
                    return (
                        <Grid container direction="row" alignItems="center" alignContent="center">
                            <Grid item>
                                <IconButton color="primary" onClick={copyToClipBoard} title={I18n.formatMessage({ id: "pages.documentList.copyToClipboard" })}>
                                    <Icon className="far fa-copy" color="primary" style={{ color: "#7895C1" }} />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <IconButton color="primary" onClick={showModal} title={I18n.formatMessage({ id: "common.delete" })}>
                                    <Clear color="primary" style={{ color: "#7895C1" }} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    );
                },
            },
        ];
    };

    public render() {
        return (
            <PageLayout>
                <TitleBar
                    title={I18n.formatMessage({ id: "pages.documentList.title" })}
                    rightButtonsComponent={
                        <DocumentUploader
                            label={I18n.formatMessage({ id: "pages.documentList.add" })}
                            color="secondary"
                            variant="contained"
                            onUploadSucceed={() => {
                                this.tableRef?.current?.reload();
                            }}
                        />
                    }
                />
                <PageCard.Container>
                    <DataGrid
                        urlQueryParser={this.urlQueryParser}
                        rowHeight={50}
                        showCellBorders="horizontal"
                        style={{ minHeight: 800 }}
                        dataSource={this.dataSource}
                        filterValues={this.filterValue}
                        columns={this.getColumns()}
                        sortFieldToSortOrder={this.sortFieldToSortOrder}
                        onReady={(computedPropsRef: React.MutableRefObject<TypeComputedProps | null>) => {
                            this.tableRef = computedPropsRef;
                        }}
                        enableSelection={false}
                    />
                </PageCard.Container>
                <DeleteDocumentModal document={this.state.documentToDelete} onClose={this.onCloseDeleteModal} />
            </PageLayout>
        );
    }
}

export default withSnackbar(DocumentListPage);
