import React from "react";
import { ColoredAvatar } from "@bigfish/admin-ui/components";
import { StringUtils } from "Utils/StringUtils";
import "./styles.css";

type Props = {
    identifier: number;
    value: string;
};

export const NameWithAvatar = (props: Props) => {
    return (
        <div className="name-with-avatar-wrapper">
            <div>
                <ColoredAvatar identifier={props.identifier} sizes="20 20">
                    {StringUtils.getNameInitials(props.value)}
                </ColoredAvatar>
            </div>
            <div className="name-with-avatar-name-cell">{props.value}</div>
        </div>
    );
};
