import { gql } from "@apollo/client";
import { bannerNormalFragment } from "./bannerNormalFragment";
import { bannerProductFragment } from "./bannerProductFragment";
import { bannerProductListFragment } from "./bannerProductListFragment";

export const bannerFragment = gql`
    fragment Banner on Banner {
        ... on BannerNormal {
            ...BannerNormal
        }
        ... on BannerProductList {
            ...BannerProductList
        }
        ... on BannerProduct {
            ...BannerProduct
        }
    }
    ${bannerNormalFragment}
    ${bannerProductListFragment}
    ${bannerProductFragment}
`;
