import React from "react";
import { InvoiceTypeEnum, OrderInvoice } from "Api/graphql/admin/types";
import { I18n } from "I18n/I18n";
import UnControlledDataGrid from "Components/DataGrid/UCDataGrid";
import { CustomTypeColumn } from "Utils/DataGridUtils";
import { DataSource } from "Components/DataGrid/DataGridUtils";
import { PageCard } from "@bigfish/admin-ui/components";
import { DateUtils, DateFormat } from "Utils/DateUtils";

type Props = {
    invoicePayments: OrderInvoice[];
};

export const InvoicePayments = (props: Props) => {
    const getColumns = (): CustomTypeColumn[] => {
        return [
            {
                name: "type",
                header: I18n.formatMessage({ id: "pages.order.form.payments.invoicePayments.column.type" }),
                defaultWidth: 200,
                render: ({ value }: { value: InvoiceTypeEnum }) => {
                    return <div>{I18n.formatMessage({ id: `enums.invoiceTypeEnum.${value}` })}</div>;
                },
            },
            {
                name: "invoice_number",
                header: I18n.formatMessage({ id: "pages.order.form.payments.invoicePayments.column.invoice_number" }),
                defaultFlex: 1,
                minWidth: 200,
            },
            {
                name: "created_at",
                header: I18n.formatMessage({ id: "pages.order.form.payments.invoicePayments.column.created_at" }),
                minWidth: 357,
                render: ({ value }: { value: any }) => {
                    return <div>{value ? DateUtils.format(value, DateFormat.minuteDateTime) : ""}</div>;
                },
            },
            {
                name: "invoiced_at",
                header: I18n.formatMessage({ id: "pages.order.form.payments.invoicePayments.column.invoiced_at" }),
                minWidth: 357,
                render: ({ value }: { value: any }) => {
                    return <div>{value ? DateUtils.format(value, DateFormat.minuteDateTime) : ""}</div>;
                },
            },
        ];
    };

    return (
        <div>
            <PageCard.Heading title={I18n.formatMessage({ id: "pages.order.form.payments.invoicePayments.title" })} />
            <UnControlledDataGrid<OrderInvoice>
                rowHeight={50}
                style={{ minHeight: 42 + props.invoicePayments.length * 50 }}
                dataSource={async (): Promise<DataSource<OrderInvoice>> => {
                    return { data: props.invoicePayments, count: props.invoicePayments.length };
                }}
                columns={getColumns()}
                activeCell={null}
                pagination={false}
            />
        </div>
    );
};
