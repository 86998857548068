import { gql } from "@apollo/client";

export const fulfillmentFragment = gql`
    fragment Fulfillment on Fulfillment {
        id
        active_from
        active_to
        limit
        count
        shipping_methods
        timewindow_allowed
        vip_levels
        created_at
        updated_at
        created_by {
            id
            name
        }
        updated_by {
            id
            name
        }
        is_active
        notification_threshold
        quantity_max
        package_type_max
        product_id_force
        product_id_exclude
        type
        weight
    }
`;
