import { gql } from "@apollo/client";
import { giftCardDesignFragment } from "../fragments/giftCardDesignFragment";

export const getGiftCardDesignsQuery = gql`
    query getGiftCardDesigns {
        giftCardDesign {
            ...GiftCardDesign
        }
    }
    ${giftCardDesignFragment}
`;
