import { gql } from "@apollo/client";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";
import { maintenanceListItemFragment } from "../fragments/maintenanceListItemFragment";

export const listMaintenancesQuery = gql`
    query listMaintenances($filters: MaintenanceFilters, $first: Int!, $page: Int) {
        maintenances(first: $first, filters: $filters, page: $page) {
            data {
                ...MaintenanceListItem
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${maintenanceListItemFragment}
    ${paginatorInfoFragment}
`;
