import { gql } from "@apollo/client";

export const todoFragment = gql`
    fragment Todo on Todo {
        id
        status
        subject
        message
        role {
            id
            name
            title
        }
        created_at
        updated_at
        assigned_to {
            id
            name
        }
        created_by {
            name
        }
        updated_by {
            name
        }
    }
`;
