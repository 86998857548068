import { gql } from "@apollo/client";

export const productCategoryChildFragment = gql`
    fragment ProductCategoryChild on ProductCategory {
        id
        name
        is_active
        is_featured
        position
        has_child
    }
`;
