import { gql } from "@apollo/client";

export const paymentTransactionFragment = gql`
    fragment PaymentTransaction on PaymentTransaction {
        id
        pmgw_transaction_id
        provider_transaction_id
        anum
        payment_method {
            name
        }
        status_payment
        amount
        amount_refund
        success
    }
`;
