import React from "react";
import { Box, Grid, Icon, TextField, Tooltip, withStyles, WithStyles } from "@bigfish/admin-ui/core";
import { I18n } from "I18n/I18n";
import { dataGridStyles } from "@bigfish/admin-ui/styles";
import UnControlledDataGrid from "Components/DataGrid/UCDataGrid";
import { CustomTypeColumn } from "Utils/DataGridUtils";
import { DataSource, DataSourceProps } from "Components/DataGrid/DataGridUtils";
import { GiftCard } from "Api/graphql/admin/types";
import { TypeSingleFilterValue } from "@inovua/reactdatagrid-community/types";
import { FunctionConfirmModal } from "Components/FunctionConfirmModal";
import { Validator } from "Utils/Validator";
import { Api } from "Api/Api";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { connect } from "react-redux";
import { ApplicationState } from "Redux/rootReducer";
import { PermissionType } from "Api/graphql/auth/types";
import { AuthSelectors } from "Redux/selectors/authSelectors";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import { ApiError } from "Api/ApiError";
import GiftCardLogModal from "./GiftCardLogModal";

type ReduxProps = {
    permissions: PermissionType[];
    isSuperadmin: boolean;
};

type ComponentProps = {
    orderId: number;
    giftCards: GiftCard[];
};

type Props = WithStyles<typeof dataGridStyles> & WithSnackbarProps & ComponentProps & ReduxProps;

type State = {
    giftCards: GiftCard[];
    isResendLoading: boolean;
    itemToResend: GiftCard | null;
    itemToLog: GiftCard | null;
    resendEmail: string | null;
    resendValidationError: string | null;
    filterValue: TypeSingleFilterValue[];
};

class GiftCardListDataGrid extends React.Component<Props, State> {
    public readonly state: State = {
        giftCards: this.props.giftCards,
        isResendLoading: false,
        itemToResend: null,
        itemToLog: null,
        resendEmail: null,
        resendValidationError: null,
        filterValue: [{ name: "mail_to", operator: "contains", type: "string", value: "" }],
    };

    private refreshGiftCards = async (): Promise<void> => {
        try {
            const giftCards = await Api.giftCardOrderGiftCards(this.props.orderId);
            this.setState({ giftCards });
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }
    };

    private getDataSource = (giftCards: GiftCard[]) => async (params: DataSourceProps<GiftCard>): Promise<DataSource<GiftCard>> => {
        const mailToFilter = params.filterValue![0].value.toLowerCase();
        const data = giftCards.filter(gc => {
            return (gc.mail_to ?? "").toLowerCase().includes(mailToFilter);
        });

        return { data, count: data.length };
    };

    private getColumns = (): CustomTypeColumn[] => {
        const hasPermission = this.props.isSuperadmin || this.props.permissions.includes(PermissionType.gift_card_resend);

        return [
            {
                name: "value",
                header: I18n.formatMessage({ id: "pages.giftCardList.grid.column.value" }),
                defaultWidth: 110,
                sortable: false,

                render: ({ value }: { value: number }) => {
                    return <div>{I18n.formatCurrency(value)}</div>;
                },
            },
            {
                name: "barcode",
                header: I18n.formatMessage({ id: "pages.giftCardList.grid.column.barcode" }),
                defaultWidth: 150,
                sortable: false,
            },
            {
                name: "expire_at",
                header: I18n.formatMessage({ id: "pages.giftCardList.grid.column.expire_at" }),
                defaultWidth: 120,
                sortable: false,

                render: ({ data }: { data: GiftCard }) => {
                    return <div>{data.expire_at ? DateUtils.format(data.expire_at, DateFormat.default) : ""}</div>;
                },
            },
            {
                name: "mail_to",
                header: I18n.formatMessage({ id: "pages.giftCardList.grid.column.mail_to" }),
                defaultFlex: 1,
                minWidth: 200,
                sortable: false,
            },
            {
                name: "sent_at",
                header: I18n.formatMessage({ id: "pages.giftCardList.grid.column.sent_at" }),
                defaultWidth: 170,
                sortable: false,
                render: ({ data }: { data: GiftCard }) => {
                    return <div>{data.sent_at ? DateUtils.format(data.sent_at, DateFormat.apiDateTime) : ""}</div>;
                },
            },
            {
                name: "_functions",
                header: "",
                minWidth: 100,
                render: ({ data }: { data: GiftCard }) => {
                    return (
                        <Grid container spacing={3}>
                            <Grid item>
                                {data.barcode ? (
                                    <Tooltip
                                        className="icon-wrapper"
                                        onClick={() => (data && hasPermission ? this.setState({ itemToResend: data, resendEmail: data.mail_to }) : {})}
                                        title={I18n.formatMessage({ id: "pages.giftCardList.grid.column.resend" })}
                                        arrow
                                    >
                                        <Icon className="fas fa-paper-plane" />
                                    </Tooltip>
                                ) : (
                                    <div style={{ opacity: "0.3" }}>
                                        <Icon className="fas fa-paper-plane" />
                                    </div>
                                )}
                            </Grid>
                            <Grid item>
                                <Tooltip
                                    className="icon-wrapper"
                                    onClick={() => (data && hasPermission ? this.setState({ itemToLog: data }) : {})}
                                    title={I18n.formatMessage({ id: "pages.giftCardList.grid.column.log" })}
                                    arrow
                                >
                                    <Icon className="fas fa-book" />
                                </Tooltip>
                            </Grid>
                        </Grid>
                    );
                },
            },
        ];
    };

    private onResendSubmit = (): void => {
        const resend = async (): Promise<void> => {
            const { itemToResend, resendEmail } = this.state;

            if (!itemToResend?.id || !resendEmail) {
                this.setState({ isResendLoading: false });
                return;
            }

            try {
                await Api.resendGiftCard(itemToResend.id, resendEmail);

                await this.refreshGiftCards();

                this.setState({
                    isResendLoading: false,
                    itemToResend: null,
                    resendEmail: null,
                    resendValidationError: null,
                });

                this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.giftCardList.resendSucceed" }), { variant: "success" });
            } catch (error) {
                this.setState({ isResendLoading: false });
                this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.giftCardList.resendError" }), { variant: "error" });
            }
        };

        this.setState({ isResendLoading: true }, resend);
    };

    private validateResendEmails = (onSuccessCallback?: () => void) => {
        const { resendEmail } = this.state;

        if (!resendEmail) {
            this.setState({ resendValidationError: I18n.formatMessage({ id: "pages.giftCardList.errors.cantBeEmpty" }) });
            return;
        }

        if (!resendEmail.match(Validator.emailRE)) {
            this.setState({ resendValidationError: I18n.formatMessage({ id: "pages.giftCardList.errors.format" }) });
            return;
        }

        this.setState({ resendValidationError: null }, onSuccessCallback);
    };

    private renderItemToResendModal = () => {
        return (
            <TextField
                type="text"
                label={I18n.formatMessage({ id: "pages.giftCardList.itemToResendTitle" })}
                fullWidth
                multiline
                variant="outlined"
                helperText={this.state.resendValidationError || ""}
                value={this.state.resendEmail ?? ""}
                onChange={e => {
                    this.setState({ resendEmail: e.currentTarget.value }, this.validateResendEmails);
                }}
                rowsMax={15}
                error={!!this.state.resendValidationError}
            />
        );
    };

    render() {
        const { isResendLoading, itemToResend, resendValidationError, resendEmail } = this.state;

        return (
            <div>
                <Box mt="30px" />

                <UnControlledDataGrid<GiftCard>
                    className={this.props.classes.dataGrid}
                    rowHeight={50}
                    style={{ minHeight: 522 }}
                    dataSource={this.getDataSource(this.state.giftCards)}
                    columns={this.getColumns()}
                    activeCell={null}
                    onRowClick={() => {}}
                    filterValue={this.state.filterValue}
                    onFilterValueChange={filterValue => {
                        if (filterValue) this.setState({ filterValue });
                    }}
                    rowStyle={{ cursor: "auto" }}
                />
                <FunctionConfirmModal
                    title={I18n.formatMessage({ id: "pages.giftCardOrder.resendModalTitle" })}
                    description={itemToResend ? this.renderItemToResendModal() : null}
                    isVisible={!!itemToResend}
                    onClose={() => this.setState({ itemToResend: null })}
                    onFunctionClick={() => this.validateResendEmails(this.onResendSubmit)}
                    rightButtonLabel={I18n.formatMessage({ id: "pages.giftCardOrder.resend" })}
                    isRightButtonDisabled={!resendEmail || !!resendValidationError || isResendLoading}
                />
                {!!this.state.itemToLog && <GiftCardLogModal onClose={() => this.setState({ itemToLog: null })} itemToLog={this.state.itemToLog} />}
            </div>
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { permissions: AuthSelectors.getPermissions(state.auth), isSuperadmin: AuthSelectors.isSuperadmin(state.auth) };
};

export default connect(mapStateToProps)(withSnackbar(withStyles(dataGridStyles, { withTheme: true })(GiftCardListDataGrid)));
