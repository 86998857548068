import React from "react";
import { connect, DispatchProp } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { batchActions } from "redux-batched-actions";
import { AuthActions } from "Redux/actions/authActions";
import { Path } from "Utils/Path";
import { Api } from "Api/Api";
import { Formik, FormikHelpers } from "formik";
import { EmailActivationForm, emailActivationFormValidator, EmailActivationFormValues } from "./EmailActivationForm";
import { Form } from "Components/Form";
import { ApiError } from "Api/ApiError";
import { Typography, Box } from "@bigfish/admin-ui/core";
import { CenteredCard, BrandLogo } from "@bigfish/admin-ui/components";
import { I18n } from "Src/i18n/I18n";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { MinimalLayout } from "Components/MinimalLayout";

type RouteParams = {
    email?: string;
    token?: string;
};

type Props = DispatchProp & RouteComponentProps<RouteParams> & WithSnackbarProps;

class EmailActivationPage extends React.Component<Props> {
    private onSubmit = async (values: EmailActivationFormValues, formikHelpers: FormikHelpers<EmailActivationFormValues>): Promise<void> => {
        try {
            const result = await Api.resetAdminPassword({ email: this.props.match.params.email!, token: this.props.match.params.token!, password: values.password });
            this.props.dispatch(batchActions([AuthActions.updateAdmin(result.admin), AuthActions.updateToken(result.token)]));
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.emailActivation.succeed" }), { variant: "success" });
        } catch (error) {
            this.props.enqueueSnackbar(error.message, { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
        }
    };

    public render() {
        if (!this.props.match.params.email || !this.props.match.params.token) {
            return <Redirect to={Path.login} />;
        }

        const initialValues: EmailActivationFormValues = {
            password: "",
            passwordConfirmation: "",
        };

        return (
            <MinimalLayout>
                <CenteredCard>
                    <BrandLogo />
                    <Box my="30px">
                        <Typography variant="h4" align="center" color="textPrimary">
                            {I18n.formatMessage({ id: "pages.emailActivation.title" })}
                        </Typography>
                    </Box>
                    <Box my="30px">
                        <Typography variant="body2" paragraph align={"center"} color="textSecondary">
                            {I18n.formatMessage({ id: "pages.emailActivation.description" })}
                        </Typography>
                    </Box>
                    <Formik initialValues={initialValues} onSubmit={this.onSubmit} validate={emailActivationFormValidator} validateOnBlur={false}>
                        {props => <EmailActivationForm formProps={props} />}
                    </Formik>
                </CenteredCard>
            </MinimalLayout>
        );
    }
}

export default connect()(withSnackbar(EmailActivationPage));
