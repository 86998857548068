import { gql } from "@apollo/client";
import { paymentMethodFragment } from "../fragments/paymentMethodFragment";

export const updatePaymentMethodMutation = gql`
    mutation updatePaymentMethod($id: String!, $input: PaymentMethodInput!, $csrf: String) {
        updatePaymentMethod(id: $id, input: $input, csrf: $csrf) {
            ...PaymentMethod
        }
    }
    ${paymentMethodFragment}
`;
