import React from "react";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { withStyles, WithStyles } from "@bigfish/admin-ui/core";
import { dataGridStyles } from "@bigfish/admin-ui/styles";
import { TypeColumn, TypeRowProps } from "@inovua/reactdatagrid-community/types";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import { OrderDirection, RoleSortField, TodoFilters, TodoListItem, TodoSort, TodoSortField, TodoStatus } from "Api/graphql/admin/types";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import DataGrid from "Components/DataGrid/DataGrid";
import { DataGridUrlQueryParser, QueryParserType } from "Components/DataGrid/DataGridUrlQueryParser";
import { DataSource, FilterValue } from "Components/DataGrid/DataGridUtils";
import { DataGridParams } from "Components/DataGrid/UrlQueryParser";
import { I18n } from "I18n/I18n";
import { Path } from "Utils/Path";
import { Constants } from "Utils/Constants";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import { RoleListItem } from "Api/graphql/admin/types";

type Props = WithSnackbarProps & WithStyles<typeof dataGridStyles>;

type State = {
    rolesForSelect: RoleListItem[];
};

class TodoListDataGrid extends React.Component<Props, State> {
    public readonly state: State = {
        rolesForSelect: [],
    };

    public async componentDidMount(): Promise<void> {
        try {
            const { data } = await Api.listRoles({ first: 9999, sortBy: { field: RoleSortField.title, direction: OrderDirection.ASC } });
            this.setState({ rolesForSelect: data });
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }
    }

    private getColumns = (): TypeColumn[] => {
        return [
            { name: "id", header: I18n.formatMessage({ id: "pages.todoList.grid.column.id" }), defaultWidth: 200 },
            { name: "subject", header: I18n.formatMessage({ id: "pages.todoList.grid.column.subject" }), defaultFlex: 1, minWidth: 300 },
            { name: "message", header: I18n.formatMessage({ id: "pages.todoList.grid.column.message" }), defaultFlex: 1, minWidth: 300 },
            {
                name: "role_name",
                header: I18n.formatMessage({ id: "pages.todoList.grid.column.role_name" }),
                defaultFlex: 1,
                minWidth: 300,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: this.state.rolesForSelect.map(r => {
                        return { id: r.name, label: r.title };
                    }),
                },
                render: ({ value }: { value: string }) => {
                    return <div>{this.state.rolesForSelect.find(r => r.name === value)?.title ?? value}</div>;
                },
            },
            { name: "assigned_to_name", header: I18n.formatMessage({ id: "pages.todoList.grid.column.assigned_to_name" }), defaultFlex: 1, minWidth: 300 },
            {
                name: "created_at",
                width: 300,
                header: I18n.formatMessage({ id: "pages.todoList.grid.column.created_at" }),
                filterEditor: DateFilter,
                dateFormat: "YYYY-MM-DD",
            },
            {
                name: "updated_at",
                width: 300,
                header: I18n.formatMessage({ id: "pages.todoList.grid.column.updated_at" }),
                filterEditor: DateFilter,
                dateFormat: "YYYY-MM-DD",
            },
            {
                name: "status",
                header: I18n.formatMessage({ id: "pages.todoList.grid.column.status" }),
                defaultFlex: 1,
                minWidth: 300,
                render: ({ value }: { value: TodoStatus }) => {
                    return <div>{Constants.getTodoStatus(value)}</div>;
                },
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: [
                        { id: TodoStatus.done, label: I18n.formatMessage({ id: "enums.todoStatus.done" }) },
                        { id: TodoStatus.todo, label: I18n.formatMessage({ id: "enums.todoStatus.todo" }) },
                    ],
                },
            },
        ];
    };

    private dataSource = async (props: DataGridParams<TodoSort, TodoFilters>): Promise<DataSource<TodoListItem>> => {
        try {
            const result = await Api.listTodos({
                first: props.limit,
                page: props.page,
                filters: props.filters,
                sortBy: props.sortBy,
            });
            return { data: result.data, count: result.paginatorInfo.total };
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }
        return { data: [], count: 0 };
    };

    private urlQueryParser = new DataGridUrlQueryParser({
        filters: {
            id: QueryParserType.integer,
            status: QueryParserType.string,
            role_name: QueryParserType.string,
            subject: QueryParserType.string,
            message: QueryParserType.string,
            created_at: QueryParserType.dateRange,
            updated_at: QueryParserType.dateRange,
            assigned_to_name: QueryParserType.string,
        },
        sortField: TodoSortField,
    });

    private sortFieldToSortOrder = {
        [TodoSortField.id]: "id",
        [TodoSortField.status]: "status",
        [TodoSortField.subject]: "subject",
        [TodoSortField.role_name]: "role_name",
        [TodoSortField.assigned_to_name]: "assigned_to_name",
        [TodoSortField.created_at]: "created_at",
        [TodoSortField.updated_at]: "updated_at",
    };

    private filterValues: FilterValue[] = [
        { name: "id", operator: "eq", type: "number" },
        { name: "status", operator: "eq", type: "select" },
        { name: "role_name", operator: "eq", type: "select" },
        { name: "subject", operator: "contains", type: "string" },
        { name: "message", operator: "contains", type: "string" },
        { name: "created_at", operator: "inrange", type: "date" },
        { name: "updated_at", operator: "inrange", type: "date" },
        { name: "assigned_to_name", operator: "contains", type: "string" },
    ];

    private getRowLink = (rowProps: TypeRowProps): string => {
        return Path.todoEdit(rowProps.data.id);
    };

    render() {
        return (
            <DataGrid
                className={this.props.classes.dataGrid}
                urlQueryParser={this.urlQueryParser}
                rowHeight={50}
                style={{ minHeight: 800 }}
                dataSource={this.dataSource}
                filterValues={this.filterValues}
                columns={this.getColumns()}
                activeCell={null}
                rowLink={this.getRowLink}
                sortFieldToSortOrder={this.sortFieldToSortOrder}
            />
        );
    }
}

export default withSnackbar(withStyles(dataGridStyles, { withTheme: true })(TodoListDataGrid));
