import * as React from "react";
import { IconButton } from "@bigfish/admin-ui/core";
import { Close as IconClose } from "@bigfish/admin-ui/icons";
import { useSnackbar, SnackbarKey } from "notistack";

const SnackbarCloseButton = ({ snackbarKey }: { snackbarKey: SnackbarKey }) => {
    const { closeSnackbar } = useSnackbar();

    return (
        <IconButton size="small" color="inherit" onClick={() => closeSnackbar(snackbarKey)}>
            <IconClose />
        </IconButton>
    );
};

export default SnackbarCloseButton;
