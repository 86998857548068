import { gql } from "@apollo/client";
import { benefitListItemFragment } from "./benefitListItemFragment";
import { departmentListItemFragment } from "./departmentListItemFragment";
import { personListItemFragment } from "./personListItemFragment";

export const divisionFragment = gql`
    fragment Division on Division {
        id
        name
        description
        quote
        leaders {
            ...PersonListItem
        }
        department {
            ...DepartmentListItem
        }
        benefits {
            ...BenefitListItem
        }
        created_at
        updated_at
        is_virtual
    }
    ${personListItemFragment}
    ${departmentListItemFragment}
    ${benefitListItemFragment}
`;
