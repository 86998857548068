import { gql } from "@apollo/client";
import { divisionFragment } from "../fragments/divisionFragment";

export const getDivisionQuery = gql`
    query getDivision($id: ID!) {
        division(id: $id) {
            ...Division
        }
    }
    ${divisionFragment}
`;
