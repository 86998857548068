import { gql } from "@apollo/client";
import { productParamStructureFragment } from "../fragments/productParamStructureFragment";

export const listProductParamStructuresQuery = gql`
    query listProductParamStructures($first: Int!) {
        productParamList(first: $first) {
            data {
                ...ProductParamStructure
            }
        }
    }
    ${productParamStructureFragment}
`;
