import React from "react";
import { Button, Icon } from "@bigfish/admin-ui/core";
import { PageLayout } from "Components/PageLayout";
import ContentListDataGrid from "./ContentListDataGrid";
import { Path } from "Utils/Path";
import { RouteComponentProps, withRouter } from "react-router";
import { TitleBar, PageCard } from "@bigfish/admin-ui/components";
import { I18n } from "I18n/I18n";
import { connect } from "react-redux";
import { ApplicationState } from "Redux/rootReducer";
import { AuthSelectors } from "Redux/selectors/authSelectors";
import { PermissionType } from "Api/graphql/admin/types";

type ReduxProps = {
    permissions: PermissionType[];
    isSuperadmin: boolean;
};

type Props = ReduxProps & RouteComponentProps;

class ContentListPage extends React.Component<Props> {
    private onAddClick = (): void => {
        this.props.history.push(Path.contentCreate);
    };

    public render() {
        return (
            <PageLayout>
                <TitleBar
                    title={I18n.formatMessage({ id: "pages.contentList.title" })}
                    rightButtonsComponent={
                        (this.props.isSuperadmin || this.props.permissions.includes(PermissionType.content_update)) && (
                            <Button startIcon={<Icon className="fa fa-plus-circle" />} variant="contained" size="medium" color="secondary" onClick={this.onAddClick}>
                                {I18n.formatMessage({ id: "pages.contentList.add" })}
                            </Button>
                        )
                    }
                />
                <PageCard.Container>
                    <ContentListDataGrid />
                </PageCard.Container>
            </PageLayout>
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { permissions: AuthSelectors.getPermissions(state.auth), isSuperadmin: AuthSelectors.isSuperadmin(state.auth) };
};

export default withRouter(connect(mapStateToProps)(ContentListPage));
