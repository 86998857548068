import React from "react";
import { MenuDrawer } from "@bigfish/admin-ui/components";
import { matchPath, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { I18n } from "I18n/I18n";
import { connect, DispatchProp } from "react-redux";
import { ApplicationState } from "Redux/rootReducer";
import { PermissionType } from "Api/graphql/admin/types";
import { AuthSelectors } from "Redux/selectors/authSelectors";
import { MenuItem } from "@bigfish/admin-ui/components/MenuDrawer";

type ReduxProps = {
    permissions: PermissionType[];
    isSuperadmin: boolean;
    isStoreAdmin: boolean;
};

type Props = ReduxProps & DispatchProp & RouteComponentProps;

class Drawer extends React.Component<Props> {
    private navigateTo = (path: string) => (): void => {
        this.props.history.push(path);
    };

    private isPathMatches = (pathOrPaths: string[] | string): boolean => {
        const paths = typeof pathOrPaths === "string" ? [pathOrPaths] : pathOrPaths;
        return paths.some(path => matchPath(this.props.location.pathname, { path, exact: true }) !== null);
    };

    private getItems = (): MenuItem[] => {
        let drawerItems = [
            {
                icon: "fa fa-home",
                text: I18n.formatMessage({ id: "components.drawer.dashboard" }),
                onClick: this.navigateTo(Path.dashboard),
                isActive: this.props.location.pathname === Path.dashboard,
            },
            {
                icon: "fas fa-cube",
                text: I18n.formatMessage({ id: "components.drawer.products" }),
                onClick: () => {},
                children: [
                    {
                        text: I18n.formatMessage({ id: "components.drawer.products.products" }),
                        onClick: this.navigateTo(Path.productCatalog),
                        isActive: this.isPathMatches([Path.productCatalog, Path.productEdit(":id")]),
                        requiredPermissions: [PermissionType.product_read],
                    },
                    {
                        text: I18n.formatMessage({ id: "components.drawer.products.productBrands" }),
                        onClick: this.navigateTo(Path.productBrandList),
                        isActive: this.isPathMatches([Path.productBrandList, Path.productBrandEdit(":id")]),
                        requiredPermissions: [PermissionType.product_brand_read],
                    },
                    {
                        text: I18n.formatMessage({ id: "components.drawer.products.productCategories" }),
                        onClick: this.navigateTo(Path.productCategoryList),
                        isActive: this.isPathMatches([Path.productCategoryList, Path.productCategoryEdit(":id")]),
                        requiredPermissions: [PermissionType.product_read],
                    },
                    {
                        text: I18n.formatMessage({ id: "components.drawer.products.productLists" }),
                        onClick: this.navigateTo(Path.productListList),
                        isActive: this.isPathMatches([Path.productListList, Path.productListEdit(":id")]),
                        requiredPermissions: [PermissionType.product_list_read],
                    },
                    {
                        text: I18n.formatMessage({ id: "components.drawer.products.productParams" }),
                        onClick: this.navigateTo(Path.productParamList),
                        isActive: this.isPathMatches([Path.productParamList, Path.productParamEdit(":id")]),
                        requiredPermissions: [PermissionType.product_param_read],
                    },
                    {
                        text: I18n.formatMessage({ id: "components.drawer.products.productBundles" }),
                        onClick: this.navigateTo(Path.productBundles),
                        isActive: this.isPathMatches([Path.productBundles, Path.productBundleCreate, Path.productBundleEdit(":id")]),
                        requiredPermissions: [PermissionType.product_read],
                    },
                    {
                        text: I18n.formatMessage({ id: "components.drawer.products.productVariants" }),
                        onClick: this.navigateTo(Path.productVariantList),
                        isActive: this.isPathMatches([Path.productVariantList, Path.productVariantEdit(":id")]),
                        requiredPermissions: [PermissionType.product_variant_create, PermissionType.product_variant_update],
                    },
                    {
                        text: I18n.formatMessage({ id: "components.drawer.products.productGratis" }),
                        onClick: this.navigateTo(Path.productGratisList),
                        isActive: this.isPathMatches([Path.productGratisList, Path.productGratisEdit(":id")]),
                        requiredPermissions: [PermissionType.product_list_read],
                    },
                ],
            },
            {
                icon: "fas fa-pen-square",
                text: I18n.formatMessage({ id: "components.drawer.contents" }),
                onClick: () => {},
                children: [
                    {
                        text: I18n.formatMessage({ id: "components.drawer.contents.pages" }),
                        onClick: this.navigateTo(Path.pageList),
                        isActive: this.isPathMatches([Path.pageList, Path.pageCreate, Path.pageEdit(":id")]),
                        requiredPermissions: [PermissionType.content_read],
                    },
                    {
                        text: I18n.formatMessage({ id: "components.drawer.contents.banners" }),
                        onClick: this.navigateTo(Path.bannerList),
                        isActive: this.isPathMatches([Path.bannerList, Path.bannerCreate(":type"), Path.bannerEdit(":id")]),
                        requiredPermissions: [PermissionType.banner_read],
                    },
                    {
                        text: I18n.formatMessage({ id: "components.drawer.contents.badges" }),
                        onClick: this.navigateTo(Path.badgeList),
                        isActive: this.isPathMatches([Path.badgeList, Path.badgeCreate, Path.badgeEdit(":id")]),
                        requiredPermissions: [PermissionType.badge_read],
                    },
                    {
                        text: I18n.formatMessage({ id: "components.drawer.contents.menus" }),
                        onClick: this.navigateTo(Path.menuList),
                        isActive: this.isPathMatches([Path.menuList, Path.menuCreate, Path.menuEdit(":id")]),
                        requiredPermissions: [PermissionType.menu_read],
                    },
                    {
                        text: I18n.formatMessage({ id: "components.drawer.contents.websiteNotifications" }),
                        onClick: this.navigateTo(Path.websiteNotificationList),
                        isActive: this.isPathMatches([Path.websiteNotificationList, Path.websiteNotificationCreate, Path.websiteNotificationEdit(":id")]),
                        requiredPermissions: [PermissionType.website_notification_read],
                    },
                    {
                        icon: "far fa-file-alt",
                        text: I18n.formatMessage({ id: "components.drawer.articleList" }),
                        onClick: this.navigateTo(Path.contentList),
                        isActive: this.props.location.pathname.includes(Path.contentList),
                        requiredPermissions: [PermissionType.content_read],
                    },
                    {
                        icon: "far fa-images",
                        text: I18n.formatMessage({ id: "components.drawer.galleryList" }),
                        onClick: this.navigateTo(Path.galleryList),
                        isActive: this.props.location.pathname.includes(Path.galleryList),
                        requiredPermissions: [PermissionType.content_read],
                    },
                    {
                        icon: "far fa-file",
                        text: I18n.formatMessage({ id: "components.drawer.documentList" }),
                        onClick: this.navigateTo(Path.documentList),
                        isActive: this.props.location.pathname.includes(Path.documentList),
                        requiredPermissions: [PermissionType.content_read],
                    },
                    {
                        icon: "fa fa-sitemap",
                        text: I18n.formatMessage({ id: "components.drawer.categoryList" }),
                        onClick: this.navigateTo(Path.categoryList),
                        isActive: this.isPathMatches([Path.categoryList]),
                        requiredPermissions: [PermissionType.content_read],
                    },
                    {
                        icon: "fa fa-forward",
                        text: I18n.formatMessage({ id: "components.drawer.campaignList" }),
                        onClick: this.navigateTo(Path.campaignList),
                        isActive: this.isPathMatches([Path.campaignList, Path.campaignCreate, Path.campaignEdit(":id")]),
                        requiredPermissions: [PermissionType.content_read],
                    },
                ],
            },
            {
                icon: "fas fa-shopping-cart",
                text: I18n.formatMessage({ id: "components.drawer.orderList" }),
                onClick: this.navigateTo(Path.orderList),
                isActive: this.isPathMatches([Path.orderList, Path.orderEdit(":id")]),
                requiredPermissions: [PermissionType.order_read],
            },
            {
                icon: "fas fa-baby",
                text: I18n.formatMessage({ id: "components.drawer.subscriptionList" }),
                onClick: this.navigateTo(Path.subscriptionList),
                isActive: this.isPathMatches([Path.subscriptionList, Path.subscriptionEdit(":id")]),
                requiredPermissions: [PermissionType.order_read],
            },
            {
                id: "store-orders",
                icon: "fas fa-store",
                text: I18n.formatMessage({ id: "components.drawer.storeOrderList" }),
                onClick: this.navigateTo(Path.storeOrderList),
                isActive: this.isPathMatches([Path.storeOrderList]),
                requiredPermissions: [PermissionType.order_read],
            },
            {
                icon: "far fa-address-card",
                text: I18n.formatMessage({ id: "components.drawer.users" }),
                onClick: () => {},
                children: [
                    {
                        text: I18n.formatMessage({ id: "components.drawer.userList" }),
                        onClick: this.navigateTo(Path.userList),
                        isActive: this.props.location.pathname.includes(Path.userList),
                        requiredPermissions: [PermissionType.user_read],
                    },
                    {
                        text: I18n.formatMessage({ id: "components.drawer.adminList" }),
                        onClick: this.navigateTo(Path.adminList),
                        isActive: this.props.location.pathname.includes(Path.adminList),
                        requiredPermissions: [PermissionType.admin_read],
                    },
                    {
                        text: I18n.formatMessage({ id: "components.drawer.roleList" }),
                        onClick: this.navigateTo(Path.roleList),
                        isActive: this.props.location.pathname.includes(Path.roleList),
                        requiredPermissions: [PermissionType.role_read],
                    },
                ],
            },
            {
                icon: "fa fa-briefcase",
                text: I18n.formatMessage({ id: "components.drawer.jobs" }),
                onClick: () => {},
                children: [
                    {
                        text: I18n.formatMessage({ id: "components.drawer.jobs.persons" }),
                        onClick: this.navigateTo(Path.personList),
                        isActive: this.props.location.pathname.includes(Path.personList),
                        requiredPermissions: [PermissionType.manage_career_persons],
                    },
                    {
                        text: I18n.formatMessage({ id: "components.drawer.jobs.benefit" }),
                        onClick: this.navigateTo(Path.benefitList),
                        isActive: this.props.location.pathname.includes(Path.benefitList),
                        requiredPermissions: [PermissionType.manage_career_benefits],
                    },
                    {
                        text: I18n.formatMessage({ id: "components.drawer.jobs.departments" }),
                        onClick: this.navigateTo(Path.departmentList),
                        isActive: this.props.location.pathname.includes(Path.departmentList),
                        requiredPermissions: [PermissionType.manage_career_departments],
                    },
                    {
                        text: I18n.formatMessage({ id: "components.drawer.jobs.divisions" }),
                        onClick: this.navigateTo(Path.divisionList),
                        isActive: this.props.location.pathname.includes(Path.divisionList),
                        requiredPermissions: [PermissionType.manage_career_divisions],
                    },
                    {
                        text: I18n.formatMessage({ id: "components.drawer.jobs.successStories" }),
                        onClick: this.navigateTo(Path.successStoryList),
                        isActive: this.props.location.pathname.includes(Path.successStoryList),
                        requiredPermissions: [PermissionType.manage_career_success_stories],
                    },
                ],
            },
            {
                icon: "fas fa-cog",
                text: I18n.formatMessage({ id: "components.drawer.settings" }),
                onClick: () => {},
                children: [
                    {
                        text: I18n.formatMessage({ id: "components.drawer.settings.mailTemplates" }),
                        onClick: this.navigateTo(Path.mailTemplateList),
                        isActive: this.isPathMatches([Path.mailTemplateList, Path.mailTemplateEdit(":id")]),
                        requiredPermissions: [PermissionType.mail_template_read],
                    },
                    {
                        text: I18n.formatMessage({ id: "components.drawer.shipping.methods" }),
                        onClick: this.navigateTo(Path.shippingMethodList),
                        isActive: this.isPathMatches([Path.shippingMethodList, Path.shippingMethodEdit(":id")]),
                        requiredPermissions: [PermissionType.shipping_method_read, PermissionType.shipping_method_update],
                    },
                    {
                        text: I18n.formatMessage({ id: "components.drawer.settings.fulfillments" }),
                        onClick: this.navigateTo(Path.fulfillmentList),
                        isActive: this.isPathMatches([Path.fulfillmentList, Path.fulfillmentEdit(":id")]),
                        requiredPermissions: [PermissionType.shipping_method_read, PermissionType.shipping_method_update],
                    },
                    {
                        text: I18n.formatMessage({ id: "components.drawer.payment.methods" }),
                        onClick: this.navigateTo(Path.paymentMethodList),
                        isActive: this.isPathMatches([Path.paymentMethodList, Path.paymentMethodEdit(":id")]),
                        requiredPermissions: [PermissionType.payment_method_read, PermissionType.payment_method_update],
                    },
                    {
                        text: I18n.formatMessage({ id: "components.drawer.redirectionList" }),
                        onClick: this.navigateTo(Path.redirectionList),
                        isActive: this.props.location.pathname.includes(Path.redirectionList),
                        requiredPermissions: [PermissionType.manage_redirections],
                    },
                    {
                        text: I18n.formatMessage({ id: "components.drawer.calendarDaysList" }),
                        onClick: this.navigateTo(Path.calendarDaysList),
                        isActive: this.isPathMatches([Path.calendarDaysList, Path.calendarDayEdit(":id")]),
                        requiredPermissions: [PermissionType.manage_calendar],
                    },
                    {
                        text: I18n.formatMessage({ id: "components.drawer.maintenanceList" }),
                        onClick: this.navigateTo(Path.maintenanceList),
                        isActive: this.isPathMatches([Path.maintenanceList, Path.maintenanceCreate, Path.maintenanceEdit(":id")]),
                        requiredPermissions: [PermissionType.maintenance_read],
                    },
                    {
                        text: I18n.formatMessage({ id: "components.drawer.systemSettingsList" }),
                        onClick: this.navigateTo(Path.systemSettingsList),
                        isActive: this.props.location.pathname.includes(Path.systemSettingsList),
                        requiredPermissions: [PermissionType.setting_read],
                    },
                    {
                        text: I18n.formatMessage({ id: "components.drawer.cacheManagement" }),
                        onClick: this.navigateTo(Path.cacheManagement),
                        isActive: this.props.location.pathname.includes(Path.cacheManagement),
                        requiredPermissions: [PermissionType.manage_cache],
                    },
                ],
            },
            {
                id: "admin-logs",
                icon: "fas fa-history",
                text: I18n.formatMessage({ id: "components.drawer.adminLogs" }),
                onClick: this.navigateTo(Path.logList),
                isActive: this.isPathMatches([Path.logList]),
                requiredPermissions: [PermissionType.log_read],
            },
            {
                id: "todos",
                icon: "far fa-check-circle",
                text: I18n.formatMessage({ id: "components.drawer.todoList" }),
                onClick: this.navigateTo(Path.todoList),
                isActive: this.isPathMatches([Path.todoList, Path.todoEdit(":id")]),
                requiredPermissions: [PermissionType.admin_todo_read],
            },
            {
                id: "ratings",
                icon: "fas fa-star",
                text: I18n.formatMessage({ id: "components.drawer.ratingList" }),
                onClick: this.navigateTo(Path.ratingList),
                isActive: this.isPathMatches([Path.ratingList]),
                requiredPermissions: [PermissionType.order_rating_read],
            },
            {
                id: "gift-card-orders",
                icon: "fas fa-gift",
                text: I18n.formatMessage({ id: "components.drawer.giftCardOrderList" }),
                onClick: this.navigateTo(Path.giftCardOrderList),
                isActive: this.isPathMatches([Path.giftCardOrderList, Path.giftCardOrderPrizeCreate, Path.giftCardOrderEdit(":id"), Path.giftCardOrderCompanyCreate]),
            },
        ];

        if (!this.props.isStoreAdmin) {
            drawerItems = drawerItems.filter(i => i.id !== "store-orders");
        }

        const hasMenuItemPermission = ({ requiredPermissions }: { requiredPermissions?: PermissionType[] }) => {
            return (
                !requiredPermissions || this.props.isSuperadmin || requiredPermissions.every((permission: PermissionType): boolean => this.props.permissions.includes(permission))
            );
        };

        return drawerItems.filter(menuItem => {
            if (menuItem.children) {
                menuItem.children = menuItem.children.filter(child => hasMenuItemPermission(child));
                if (menuItem.children.length === 0) {
                    return false;
                }
            }
            return hasMenuItemPermission(menuItem);
        });
    };

    render() {
        return <MenuDrawer items={this.getItems()} />;
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { permissions: AuthSelectors.getPermissions(state.auth), isSuperadmin: AuthSelectors.isSuperadmin(state.auth), isStoreAdmin: AuthSelectors.isStoreAdmin(state.auth) };
};

export default withRouter(connect(mapStateToProps)(Drawer));
