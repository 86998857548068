import { gql } from "@apollo/client";

export const listProductFilterParamsQuery = gql`
    query listProductFilterParams {
        listProductFilterParams {
            id
            name
        }
    }
`;
