import { gql } from "@apollo/client";
import { shipmentStatusFragment } from "../fragments/shipmentStatusFragment";

export const listShipmentStatuses = gql`
    query listShipmentStatuses {
        shipmentStatuses {
            ...ShipmentStatus
        }
    }
    ${shipmentStatusFragment}
`;
