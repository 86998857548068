import { gql } from "@apollo/client";
import { giftCardFragment } from "../fragments/giftCardFragment";

export const giftCardOrderGiftCardsQuery = gql`
    query giftCardOrderGiftCards($id: Int!) {
        giftCardOrder(id: $id) {
            gift_cards {
                ...GiftCard
            }
        }
    }
    ${giftCardFragment}
`;
