import { gql } from "@apollo/client";

export const productGratisListFragment = gql`
    fragment ProductGratisListItem on ProductSimple {
        id
        lfdnr
        ean
        name
        main_categories {
            id
            name
        }
        is_active
        is_visible
        active_from
        active_to
        is_image
        stock_qty
    }
`;
