import { gql } from "@apollo/client";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";
import { paymentMethodTabItemFragment } from "../fragments/paymentMethodTabItemFragment";

export const listPaymentMethodsQuery = gql`
    query listPaymentMethods($first: Int!) {
        listPaymentMethods(first: $first) {
            data {
                ...PaymentMethodTabItem
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${paymentMethodTabItemFragment}
    ${paginatorInfoFragment}
`;
