import { gql } from "@apollo/client";
import { benefitFragment } from "../fragments/benefitFragment";

export const getBenefitQuery = gql`
    query getBenefit($id: ID!) {
        benefit(id: $id) {
            ...Benefit
        }
    }
    ${benefitFragment}
`;
