import { gql } from "@apollo/client";
import { campaignListItemFragment } from "../fragments/campaignListItemFragment";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";

export const listCampaignsQuery = gql`
    query listCampaigns($filters: CampaignFilters, $sortBy: CampaignSort, $first: Int!, $page: Int) {
        listCampaigns(first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            data {
                ...CampaignListItem
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${campaignListItemFragment}
    ${paginatorInfoFragment}
`;
