import React from "react";
import { makeStyles, Card, Typography, Divider, Modal, Grid, IconButton, Icon, Box } from "@bigfish/admin-ui/core";

interface ModalConfig {
    isFullWidth?: boolean;
    modalWidth?: number;
    modalHeight?: number;
    backgroundColor?: string;
}

export interface ModalProps {
    title: string | React.ReactNode;
    open: boolean;
    onClose(): void;
    leftButtonsComponent?: React.ReactNode;
    rightButtonsComponent?: React.ReactNode;
}

export const createModal = ({ isFullWidth, modalWidth, modalHeight, backgroundColor }: ModalConfig) => {
    const useStyles = makeStyles({
        modal: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        card: {
            width: isFullWidth ? undefined : modalWidth ?? 640,
            height: modalHeight ?? undefined,
            margin: "0 auto",
            borderRadius: 20,
            padding: "30px 20px",
            outline: "none",
            overflowY: "scroll",
            backgroundColor: backgroundColor ?? "#FFFFFF",
        },
        cardHeader: {
            paddingBottom: 15,
        },
        cardFooter: {
            paddingTop: 30,
        },
    });

    return (props: React.PropsWithChildren<ModalProps>) => {
        const classes = useStyles();

        return (
            <Modal open={props.open} onClose={props.onClose} className={classes.modal}>
                <Card raised className={classes.card}>
                    <Grid container justify="space-between" alignItems="center" className={classes.cardHeader}>
                        <Typography variant="h3">{props.title}</Typography>
                        <IconButton color="primary" onClick={props.onClose}>
                            <Icon className="fas fa-times" />
                        </IconButton>
                    </Grid>
                    <Divider />
                    <Box p="20px 0">{props.children}</Box>
                    <Divider />
                    <Grid container justify="space-between" alignItems="center" className={classes.cardFooter}>
                        <Box>{props.leftButtonsComponent}</Box>
                        <Box>{props.rightButtonsComponent}</Box>
                    </Grid>
                </Card>
            </Modal>
        );
    };
};
