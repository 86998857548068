import { gql } from "@apollo/client";
import { pageOverlayFragment } from "./pageOverlayFragment";

export const menuItemFieldsFragment = gql`
    fragment MenuItemFields on MenuItem {
        type {
            id
            name
        }
        model_id
        title
        url
        popup
        is_active
        page_overlay {
            ...PageOverlay
        }
    }
    ${pageOverlayFragment}
`;
