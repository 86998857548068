import React from "react";
import { I18n } from "I18n/I18n";
import { DefaultModal } from "@bigfish/admin-ui/components";
import { Button, Typography } from "@bigfish/admin-ui/core";

type Props = {
    isVisible: boolean;
    onClose: () => void;
    onDeleteClick: () => void;
};

export const DeleteRoleConfirmModal = (props: Props) => {
    return (
        <DefaultModal
            title={I18n.formatMessage({ id: "pages.role.deleteModal.title" })}
            rightButtonsComponent={
                <Button variant="contained" color="primary" onClick={props.onDeleteClick}>
                    {I18n.formatMessage({ id: "common.delete" })}
                </Button>
            }
            leftButtonsComponent={
                <Button variant="outlined" color="primary" onClick={props.onClose}>
                    {I18n.formatMessage({ id: "common.cancel" })}
                </Button>
            }
            open={props.isVisible}
            onClose={props.onClose}
        >
            <Typography variant="body2" color="textSecondary">
                {I18n.formatMessage({ id: "pages.role.deleteModal.description" })}
            </Typography>
        </DefaultModal>
    );
};
