import React, { useCallback, useMemo, useState } from "react";
import { I18n } from "I18n/I18n";
import { Button, Icon, Menu, MenuItem, Typography } from "@bigfish/admin-ui/core";
import { BannerListItem, BannerType } from "Api/graphql/admin/types";
import { createModal } from "Components/createModal";
import uniqWith from "lodash/uniqWith";
import BannerListDataGrid from "Pages/BannerList/BannerListDataGrid";
import { Constants } from "Utils/Constants";
import ModalBannerCreatePage from "Pages/ModalBanner/ModalBannerCreatePage";
import "./styles.css";
import { ObjectUtils } from "Utils/ObjectUtils";
import { BannerMinimal } from "Utils/ApiUtils";

const BannerAddDialogModal = createModal({ modalWidth: 1280 });
const CreateBannerDialog = createModal(Constants.getModalPageStyles());

type Props = {
    isSingleSelect?: boolean;
    initialSelectedBanners: Array<BannerMinimal | BannerListItem>;
    onClose: () => void;
    onAddClick: (selectedBanners: Array<BannerMinimal | BannerListItem>) => void;
    onlyActive?: boolean;
};

export const BannerAddDialog = ({ isSingleSelect, initialSelectedBanners, onClose, onAddClick, onlyActive }: Props) => {
    const [selectedBanners, setSelectedBanners] = useState(initialSelectedBanners);
    const selectedBannerIds = useMemo(() => selectedBanners.map(container => container.id), [selectedBanners]);
    const [newBannerType, setNewBannerType] = useState<BannerType | null>(null);
    const [anchorElement, setAnchorElement] = useState<(EventTarget & HTMLButtonElement) | null>(null);
    const [listKey, setListKey] = useState<number>(0);

    const handleAddBanners = (containersToAdd: BannerListItem[]) => {
        isSingleSelect ? setSelectedBanners(containersToAdd) : setSelectedBanners(uniqWith([...selectedBanners, ...containersToAdd], (p1, p2) => p1.id === p2.id));
    };

    const handleRemoveBanners = useCallback(
        (containersToRemove: BannerListItem[]) => {
            setSelectedBanners(selectedBanners.filter(container => !containersToRemove.some(containerToRemove => containerToRemove.id === container.id)));
        },
        [selectedBanners]
    );

    const onNewClick = (bannerType: BannerType): void => {
        setNewBannerType(bannerType);
        setAnchorElement(null);
    };

    const onAddMenuClose = () => {
        setAnchorElement(null);
    };

    return (
        <BannerAddDialogModal
            title={I18n.formatMessage({ id: "components.bannerAddDialog.title" })}
            leftButtonsComponent={
                <div className="product-list-add-dialog-left-buttons-component">
                    <div>
                        <Button
                            aria-controls="add-menu"
                            aria-haspopup="true"
                            startIcon={<Icon className="fa fa-plus-circle" />}
                            variant="contained"
                            size="medium"
                            color="secondary"
                            onClick={event => {
                                setAnchorElement(event.currentTarget);
                            }}
                        >
                            {I18n.formatMessage({ id: "components.bannerAddDialog.createBanner" })}
                        </Button>
                        <Menu id="product-list-list-page-menu" anchorEl={anchorElement} keepMounted open={!!anchorElement} onClose={onAddMenuClose}>
                            <div>
                                {ObjectUtils.enumAsArray<BannerType>(BannerType).map((type: BannerType) => (
                                    <MenuItem key={type} onClick={() => onNewClick(type)}>
                                        {I18n.formatMessage({ id: `enums.bannerType.${type}` })}
                                    </MenuItem>
                                ))}
                            </div>
                        </Menu>
                    </div>
                    {!isSingleSelect && (
                        <Typography variant="subtitle2">{I18n.formatMessage({ id: "common.selectedItems" }, { selectedItems: selectedBannerIds.length })}</Typography>
                    )}
                </div>
            }
            rightButtonsComponent={
                <>
                    <Button variant="outlined" color="primary" onClick={onClose} style={{ marginRight: "20px" }}>
                        {I18n.formatMessage({ id: "common.cancel" })}
                    </Button>
                    <Button variant="contained" color="primary" onClick={() => onAddClick(selectedBanners)}>
                        {I18n.formatMessage({ id: "common.add" })}
                    </Button>
                </>
            }
            open
            onClose={onClose}
        >
            <BannerListDataGrid
                key={listKey}
                selectedBannerIds={selectedBannerIds}
                onAddBanners={handleAddBanners}
                onRemoveBanners={handleRemoveBanners}
                disableRowClick
                isSingleSelect={isSingleSelect}
                minWidth={500}
                onlyActive={onlyActive}
            />

            {newBannerType && (
                <CreateBannerDialog title={null} open onClose={() => setNewBannerType(null)}>
                    <ModalBannerCreatePage
                        type={newBannerType}
                        onModalClose={() => {
                            setNewBannerType(null);
                            setListKey(listKey + 1);
                        }}
                    />
                </CreateBannerDialog>
            )}
        </BannerAddDialogModal>
    );
};
