import React from "react";
import { PageLayout } from "Components/PageLayout";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { TitleBar, PageCard } from "@bigfish/admin-ui/components";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { Api } from "Api/Api";
import { ProductCategoryChild, PermissionType } from "Api/graphql/admin/types";
import { ApiError } from "Api/ApiError";
import { Loading } from "Components/Loading";
import { Button, Icon, Table } from "@bigfish/admin-ui/core";
import { ProductCategoryRow } from "./ProductCategoryRow";
import { I18n } from "I18n/I18n";
import "./styles.css";
import { AuthSelectors } from "Redux/selectors/authSelectors";
import { connect } from "react-redux";
import { ApplicationState } from "Redux/rootReducer";
import { ReorderCategoriesModal } from "./ReorderCategoriesModal";

type ReduxProps = {
    permissions: PermissionType[];
    isSuperadmin: boolean;
};

type Props = ReduxProps & RouteComponentProps & WithSnackbarProps;

export type ProductCategoryToReorder = {
    modalTitle: string;
    children: ProductCategoryChild[] | null;
};

type State = {
    isLoading: boolean;
    mainCategories: ProductCategoryChild[];
    productCategoryToReorder: ProductCategoryToReorder | null;
};

class ProductCategoryListPage extends React.Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        mainCategories: [],
        productCategoryToReorder: null,
    };

    public async componentDidMount(): Promise<void> {
        await this.fetchMainCategories();
    }

    public fetchMainCategories = async (): Promise<void> => {
        this.setState({ isLoading: true }, async () => {
            try {
                const mainCategories = await Api.getProductCategoryChildren(null);
                this.setState({ mainCategories });
            } catch (error) {
                if (error instanceof ApiError) {
                    this.props.enqueueSnackbar(error.message, { variant: "error" });
                }
            }
            this.setState({ isLoading: false });
        });
    };

    private toggleProductCategoryToReorder = (productCategoryToReorder: ProductCategoryToReorder | null): void => {
        this.setState({ productCategoryToReorder });
    };

    private onReorderCategoriesModalClose = (withRefresh?: boolean): void => {
        this.toggleProductCategoryToReorder(null);

        if (withRefresh === true) {
            this.fetchMainCategories();
        }
    };

    public render() {
        const { isLoading, productCategoryToReorder, mainCategories } = this.state;

        if (isLoading) {
            return <Loading />;
        }

        const areCategoriesEditable = this.props.isSuperadmin || this.props.permissions.includes(PermissionType.product_update);

        return (
            <PageLayout>
                <TitleBar
                    title={I18n.formatMessage({ id: "pages.productCategoryList.title" })}
                    rightButtonsComponent={
                        <Button
                            startIcon={<Icon className="fas fa-bars" />}
                            variant="contained"
                            size="medium"
                            color="secondary"
                            onClick={() =>
                                this.toggleProductCategoryToReorder({
                                    modalTitle: I18n.formatMessage({ id: "pages.productCategoryList.reorderCategoriesModal.titleAll" }),
                                    children: mainCategories,
                                })
                            }
                        >
                            {I18n.formatMessage({ id: "pages.productCategoryList.reorderCategoriesModal.titleAll" })}
                        </Button>
                    }
                />
                <PageCard.Container>
                    <Table className="product-category-table">
                        <tbody>
                            {mainCategories.map((pc: ProductCategoryChild) => (
                                <ProductCategoryRow
                                    key={pc.id}
                                    areCategoriesEditable={areCategoriesEditable}
                                    productCategory={pc}
                                    level={1}
                                    onReorderClick={this.toggleProductCategoryToReorder}
                                />
                            ))}
                        </tbody>
                    </Table>
                </PageCard.Container>
                {productCategoryToReorder ? <ReorderCategoriesModal productCategory={productCategoryToReorder} onClose={this.onReorderCategoriesModalClose} /> : <></>}
            </PageLayout>
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { permissions: AuthSelectors.getPermissions(state.auth), isSuperadmin: AuthSelectors.isSuperadmin(state.auth) };
};

export default withSnackbar(connect(mapStateToProps)(withRouter(ProductCategoryListPage)));
