import { gql } from "@apollo/client";

export const departmentListItemFragment = gql`
    fragment DepartmentListItem on Department {
        id
        name
        order
        created_at
        updated_at
    }
`;
