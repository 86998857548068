import { Api } from "Api/Api";
import { AuthActions } from "Redux/actions/authActions";
import { store } from "Redux/store";

export class AuthUtils {
    public static async logout(skipApiCall?: boolean): Promise<void> {
        if (!skipApiCall) {
            try {
                await Api.adminLogout();
                // eslint-disable-next-line no-empty
            } catch (error) {}
        }
        store.dispatch(AuthActions.logout());
    }
}
