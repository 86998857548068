import React from "react";
import { UserAddressSmall } from "Api/graphql/admin/types";

export class OrderUtils {
    // For order detail pages
    public static renderAddress(address: UserAddressSmall): JSX.Element {
        return (
            <div>
                {(address.lastname || address.firstname) && <div>{[address.lastname || "", address.firstname || ""].join(" ")}</div>}
                <div>
                    {address.zip_code || address.city || address.address_line1 || address.address_line2
                        ? [address.zip_code, address.city, address.address_line1, address.address_line2].filter(address => address !== null && address !== "").join(" ")
                        : "-"}
                </div>
                {address.company_name && <div>{address.company_name}</div>}
                {address.company_number && <div>{address.company_number}</div>}
            </div>
        );
    }
}
