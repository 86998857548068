import React from "react";

import { withSnackbar, WithSnackbarProps } from "notistack";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { PageCard, TitleBar } from "@bigfish/admin-ui/components";
import { Button, Icon } from "@bigfish/admin-ui/core";

import { PermissionType } from "Api/graphql/admin/types";
import { PageLayout } from "Components/PageLayout";
import { I18n } from "I18n/I18n";
import { ApplicationState } from "Redux/rootReducer";
import { AuthSelectors } from "Redux/selectors/authSelectors";
import { Path } from "Utils/Path";

import CalendarDaysListDataGrid from "./CalendarDaysListDataGrid";

type ReduxProps = {
    permissions: PermissionType[];
    isSuperadmin: boolean;
};

type Props = ReduxProps & RouteComponentProps & WithSnackbarProps;

class CalendarDaysListPage extends React.Component<Props> {
    public render() {
        return (
            <PageLayout>
                <TitleBar
                    title={I18n.formatMessage({ id: "pages.calendarDaysList.title" })}
                    rightButtonsComponent={
                        (this.props.isSuperadmin || this.props.permissions.includes(PermissionType.manage_calendar)) && (
                            <div>
                                <Button
                                    aria-controls="add-menu"
                                    aria-haspopup="true"
                                    startIcon={<Icon className="fa fa-plus-circle" />}
                                    variant="contained"
                                    size="medium"
                                    color="secondary"
                                    onClick={() => this.props.history.push(Path.calendarDayCreate)}
                                >
                                    {I18n.formatMessage({ id: "pages.calendarDaysList.addButton" })}
                                </Button>
                            </div>
                        )
                    }
                />
                <PageCard.Container>
                    <CalendarDaysListDataGrid />
                </PageCard.Container>
            </PageLayout>
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { permissions: AuthSelectors.getPermissions(state.auth), isSuperadmin: AuthSelectors.isSuperadmin(state.auth) };
};

export default withSnackbar(connect(mapStateToProps)(withRouter(CalendarDaysListPage)));
