import { ActiveBreadcrumbItem, TitleBar } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { MailTemplateForm, MailTemplateFormValues } from "./MailTemplateForm";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { Loading } from "Components/Loading";
import { PageLayout } from "Components/PageLayout";
import { Link } from "Components/Link";
import { Breadcrumbs } from "Components/Breadcrumbs";
import Prompt from "Components/Prompt";
import { MailTemplate } from "Api/graphql/admin/types";
import { Box } from "@bigfish/admin-ui/core";
import { I18n } from "I18n/I18n";
import { StringUtils } from "Utils/StringUtils";
import TestMail from "./TestMail";

type RouteParams = {
    id?: string;
};

type Props = WithSnackbarProps & RouteComponentProps<RouteParams>;

type State = {
    isLoading: boolean;
    mailTemplate: MailTemplate | null;
};

class MailTemplateEditPage extends React.Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        mailTemplate: null,
    };

    public async componentDidMount(): Promise<void> {
        if (!this.props.match.params.id) {
            return;
        }

        try {
            const mailTemplate = await Api.getMailTemplateById(Number.parseInt(this.props.match.params.id, 10));
            this.setState({ mailTemplate, isLoading: false });
        } catch (error) {
            this.props.enqueueSnackbar(error.message, { variant: "error" });
            this.props.history.push(Path.mailTemplateList);
        }
    }

    private onSubmit = async (values: MailTemplateFormValues, formikHelpers: FormikHelpers<MailTemplateFormValues>): Promise<boolean> => {
        try {
            const mailTemplate = await Api.updateMailTemplate(this.state.mailTemplate!.id, values);
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.mailTemplate.onEditSubmit.succeed" }), { variant: "success" });
            this.setState({ mailTemplate });
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.mailTemplate.onEditSubmit.error" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
        }
        return false;
    };

    private getBreadcrumb = () => {
        return (
            <Breadcrumbs>
                <Link to={Path.mailTemplateList} color="inherit">
                    {I18n.formatMessage({ id: "pages.mailTemplate.editBreadcrumb" })}
                </Link>
                <ActiveBreadcrumbItem aria-current="page">
                    {this.state.mailTemplate?.description ? StringUtils.getTruncatedText(this.state.mailTemplate.description) : ""}
                </ActiveBreadcrumbItem>
            </Breadcrumbs>
        );
    };

    public render() {
        const { isLoading, mailTemplate } = this.state;

        if (isLoading) {
            return <Loading breadcrumb={this.getBreadcrumb()} />;
        }

        if (!mailTemplate) {
            return <Redirect to={Path.mailTemplateList} />;
        }

        const initialValues: MailTemplateFormValues = {
            subject: mailTemplate.subject,
            content: mailTemplate.content,
            description: mailTemplate.description ?? "",
            is_active: mailTemplate.is_active,
        };

        return (
            <PageLayout breadcrumb={this.getBreadcrumb()}>
                {mailTemplate.description ? <TitleBar title={StringUtils.getTruncatedText(mailTemplate.description, 50)} /> : <></>}
                <Box mt="20px" />
                <TestMail mailTemplate={mailTemplate} />
                <Formik initialValues={initialValues} onSubmit={this.onSubmit} enableReinitialize={true}>
                    {props => (
                        <>
                            <MailTemplateForm formType={FormType.edit} formProps={props} mailTemplate={mailTemplate} />
                            <Prompt when={props.dirty} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props)} />
                        </>
                    )}
                </Formik>
                <Box mt="40px" />
            </PageLayout>
        );
    }
}

export default withSnackbar(withRouter(MailTemplateEditPage));
