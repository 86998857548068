import React from "react";
import UnControlledDataGrid from "Components/DataGrid/UCDataGrid";
import { I18n } from "I18n/I18n";
import { BenefitListItem } from "Api/graphql/admin/types";
import { CustomTypeColumn } from "Utils/DataGridUtils";
import { Constants } from "Utils/Constants";

type Props = {
    divisions: BenefitListItem[];
};

class DivisionTable extends React.Component<Props> {
    private getColumns = (): CustomTypeColumn[] => {
        return [
            {
                name: "id",
                header: I18n.formatMessage({ id: "pages.department.form.divisions.grid.column.id" }),
                filterDelay: Constants.filterDelayMS,
            },
            {
                name: "name",
                header: I18n.formatMessage({ id: "pages.department.form.divisions.grid.column.name" }),
                flex: 1,
                filterDelay: Constants.filterDelayMS,
            },
        ];
    };

    public render() {
        return (
            <UnControlledDataGrid<BenefitListItem>
                rowHeight={50}
                style={{ minHeight: 400 }}
                dataSource={this.props.divisions}
                columns={this.getColumns()}
                onFilterValueChange={filterValue => {
                    this.setState({ filterValue });
                }}
                activeCell={null}
                filterable
            />
        );
    }
}

export default DivisionTable;
