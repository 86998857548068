import { gql } from "@apollo/client";
import { adminFragment } from "../fragments/adminFragment";

export const resetAdminPasswordMutation = gql`
    mutation resetAdminPassword($input: SetAdminPasswordInput!) {
        resetPassword(input: $input) {
            token
            admin {
                ...Admin
            }
        }
    }
    ${adminFragment}
`;
