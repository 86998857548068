import React from "react";
import { I18n } from "I18n/I18n";
import { Box, Button, Grid, Typography } from "@bigfish/admin-ui/core";
import { Api } from "Api/Api";
import { withSnackbar, WithSnackbarProps } from "notistack";
import "./styles.css";
import { FunctionConfirmModal } from "Components/FunctionConfirmModal";
import ProductCatalogDataGrid from "Pages/ProductCatalog/ProductCatalogDataGrid";
import { ProductCatalogItem } from "Api/graphql/admin/types";
import uniqWith from "lodash/uniqWith";
import { PageCard } from "@bigfish/admin-ui/components";

const QUEUE_REFRESH_INTERVAL = 5000;

enum ReindexType {
    allProducts = "allProducts",
    selectedProducts = "selectedProducts",
}

type State = {
    remaining: number;
    isButtonDisabled: boolean;
    isStatusLoading: boolean;
    isSelectedReindexLoading: boolean;
    reindexTypeUnderConfirmation: ReindexType | null;
    selectedProducts: Array<{ id: number; lfdnr: number | null }>;
};

class Reindexing extends React.Component<WithSnackbarProps, State> {
    queueIntervalHandle: number | null = null;

    public readonly state: State = {
        remaining: 0,
        isButtonDisabled: true,
        isStatusLoading: true,
        isSelectedReindexLoading: false,
        reindexTypeUnderConfirmation: null,
        selectedProducts: [],
    };

    public componentDidMount(): void {
        this.indexStatus();
        this.queueIntervalHandle = window.setInterval(this.indexStatus, QUEUE_REFRESH_INTERVAL);
    }

    public componentWillUnmount(): void {
        if (this.queueIntervalHandle !== null) {
            clearInterval(this.queueIntervalHandle);
            this.queueIntervalHandle = null;
        }
    }

    private indexProducts = async (): Promise<void> => {
        this.setState({ isSelectedReindexLoading: true, reindexTypeUnderConfirmation: null, selectedProducts: [] }, async () => {
            try {
                await Api.indexProduct(this.state.selectedProducts.map(sp => sp.lfdnr).filter(sp => sp !== null) as number[]);
                this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.cacheManagement.cacheClearing.selected.success" }), { variant: "success" });
            } catch (error) {
                this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.cacheManagement.cacheClearing.selected.error" }), { variant: "error" });
            }
            this.setState({ isSelectedReindexLoading: false });
        });
    };

    private queueProductIndex = (): void => {
        this.setState({ isButtonDisabled: true }, async () => {
            try {
                await Api.queueProductIndex();
                this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.cacheManagement.cacheClearing.queueProductIndex.success" }), { variant: "success" });
            } catch (error) {
                this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.cacheManagement.cacheClearing.queueProductIndex.error" }), { variant: "error" });
            }
            this.setState({ reindexTypeUnderConfirmation: null });
        });
    };

    private indexStatus = (): void => {
        this.setState({ isStatusLoading: true }, async () => {
            try {
                const remaining = await Api.indexStatus();
                this.setState({
                    remaining,
                    isButtonDisabled: remaining > 0,
                });
            } catch (error) {
                this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.cacheManagement.cacheClearing.indexStatus.error" }), { variant: "error" });
            }
            this.setState({ isStatusLoading: false });
        });
    };

    private handleAddProducts = (productsToAdd: ProductCatalogItem[]) => {
        this.setState({
            selectedProducts: uniqWith(
                [
                    ...this.state.selectedProducts,
                    ...productsToAdd.map(pta => {
                        return { id: pta.id, lfdnr: pta.lfdnr };
                    }),
                ],
                (p1, p2) => p1.id === p2.id
            ),
        });
    };

    private handleRemoveProducts = (productsToRemove: ProductCatalogItem[]) => {
        this.setState({ selectedProducts: this.state.selectedProducts.filter(sp => !productsToRemove.some(productToRemove => productToRemove.id === sp.id)) });
    };

    public render() {
        const { remaining, isButtonDisabled, isStatusLoading, isSelectedReindexLoading, selectedProducts, reindexTypeUnderConfirmation } = this.state;

        return (
            <div>
                <Box mt="30px" mb="30px">
                    <PageCard.Heading title={I18n.formatMessage({ id: "pages.cacheManagement.reindexing.allProducts" })} />
                </Box>
                <Grid container>
                    <Grid item xs={5}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => this.setState({ reindexTypeUnderConfirmation: ReindexType.allProducts })}
                            disabled={isButtonDisabled}
                        >
                            {I18n.formatMessage({ id: "pages.cacheManagement.reindexing.queueProductIndex" })}
                        </Button>
                    </Grid>
                    <Grid item xs={7}>
                        <Typography color="textSecondary" gutterBottom>
                            {I18n.formatMessage(
                                { id: "pages.cacheManagement.reindexing.indexStatus.remaining" },
                                { queueRefreshInterval: Math.ceil(QUEUE_REFRESH_INTERVAL / 1000) }
                            )}
                        </Typography>
                        <Typography color={isStatusLoading ? "textSecondary" : "textPrimary"} variant="subtitle1">
                            {isStatusLoading ? I18n.formatMessage({ id: "common.loading" }) : remaining >= 5000 ? `${remaining}+` : remaining}
                        </Typography>
                    </Grid>
                </Grid>

                <Box mt="30px" mb="30px">
                    <PageCard.Heading title={I18n.formatMessage({ id: "pages.cacheManagement.reindexing.selectedProducts" })} />
                </Box>

                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => this.setState({ reindexTypeUnderConfirmation: ReindexType.selectedProducts })}
                    disabled={isSelectedReindexLoading || this.state.selectedProducts.map(sp => sp.lfdnr).length === 0}
                >
                    {isSelectedReindexLoading ? I18n.formatMessage({ id: "common.loading" }) : I18n.formatMessage({ id: "pages.cacheManagement.reindexing.indexProduct" })}
                </Button>

                <Box mt="30px" />

                <ProductCatalogDataGrid
                    key="add-product-dialog-data-grid"
                    selectedProductIds={selectedProducts.map(p => p.id)}
                    onAddProducts={this.handleAddProducts}
                    onRemoveProducts={this.handleRemoveProducts}
                    minWidth={500}
                    isReadOnly
                    isUnparsed
                />
                <FunctionConfirmModal
                    title={I18n.formatMessage({ id: "pages.cacheManagement.reindexing.confirmModal.title" })}
                    description={I18n.formatMessage({ id: "pages.cacheManagement.reindexing.confirmModal.description" })}
                    isVisible={reindexTypeUnderConfirmation !== null}
                    onClose={() => {
                        this.setState({ reindexTypeUnderConfirmation: null });
                    }}
                    onFunctionClick={
                        reindexTypeUnderConfirmation === ReindexType.allProducts
                            ? this.queueProductIndex
                            : reindexTypeUnderConfirmation === ReindexType.selectedProducts
                            ? this.indexProducts
                            : () => {}
                    }
                    leftButtonLabel={I18n.formatMessage({ id: "common.cancel" })}
                    rightButtonLabel={I18n.formatMessage({ id: "common.yes" })}
                />
            </div>
        );
    }
}

export default withSnackbar(Reindexing);
