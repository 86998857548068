import { gql } from "@apollo/client";
import { productFragment } from "../fragments/productFragment";

export const getProductByIdQuery = gql`
    query getProductById($id: Int!) {
        product(id: $id) {
            ...Product
        }
    }
    ${productFragment}
`;
