import { gql } from "@apollo/client";
import { permissionFragment } from "../fragments/permissionFragment";

export const listPermissionsQuery = gql`
    query listPermissions($first: Int!) {
        listPermissions(first: $first) {
            data {
                ...Permission
            }
        }
    }
    ${permissionFragment}
`;
