import { gql } from "@apollo/client";
import { productParamFragment } from "./../fragments/productParamFragment";

export const productParamQuery = gql`
    query productParam($id: String!) {
        productParam(id: $id) {
            ...ProductParam
        }
    }
    ${productParamFragment}
`;
