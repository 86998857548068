import { gql } from "@apollo/client";
import { storeSelectItemFragment } from "../fragments/storeSelectItemFragment";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";

export const listStoresForSelectQuery = gql`
    query listStoresForSelect($first: Int!) {
        listStores(first: $first) {
            data {
                ...StoreSelectItem
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${storeSelectItemFragment}
    ${paginatorInfoFragment}
`;
