import { gql } from "@apollo/client";
import { documentModalListItemFragment } from "../fragments/documentModalListItemFragment";

export const getDocumentsForModalQuery = gql`
    query getDocumentsForModal {
        documents(first: 999) {
            data {
                ...DocumentModalListItem
            }
        }
    }
    ${documentModalListItemFragment}
`;
