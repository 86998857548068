/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import CKGalleryModal from "./CKGalleryInsertModal/CKGalleryInsertModal";
import { GalleryImage } from "Api/graphql/admin/types";
import { MediaLibraryHelper } from "Utils/MediaLibraryHelper";
import { I18n } from "I18n/I18n";
import { Box, createStyles, WithStyles, withStyles } from "@bigfish/admin-ui/core";

type CKEditorGalleryImage = {
    url: string;
    dataAssetURL: string;
    caption: string;
    credit: string;
};

export type CKConfig = "default" | "lead" | "websiteNotification" | "basic" | "pageHtmlBlock" | "shippingMethodDescription" | "productDescription";

type ComponentProps = {
    value: string;
    name: string;
    config: CKConfig;
    onChange: (value: string) => void;
};

type Props = ComponentProps & WithStyles<typeof styles>;

type State = {
    galleryModal: {
        isVisible: boolean;
        callback?: (images: CKEditorGalleryImage[]) => void;
    };
};

class RichTextEditor extends React.Component<Props, State> {
    public readonly state: State = {
        galleryModal: {
            isVisible: false,
        },
    };

    public componentDidMount() {
        this.setCallbacks(this.props.name);
    }

    public componentDidUpdate(prevProps: Props) {
        if (this.props.config === "default" && prevProps.config !== "default") {
            this.setCallbacks(this.props.name);
        } else if (this.props.config !== "default" && prevProps.config === "default") {
            this.cleanCallbacks(prevProps.name);
        } else {
            if (this.props.name !== prevProps.name) {
                this.cleanCallbacks(prevProps.name);
                this.setCallbacks(this.props.name);
            }
        }
    }

    public componentWillUnmount() {
        this.cleanCallbacks(this.props.name);
    }

    private setCallbacks = (name: string): void => {
        // @ts-ignore
        window[`${name}GalleryCallback`] = this.galleryCallback;
        // @ts-ignore
        window[`${name}ImageCallback`] = this.imageCallback;
        // @ts-ignore
        window[`${name}CDNImageCallback`] = this.cdnImageCallback;
    };

    private cleanCallbacks = (name: string): void => {
        // @ts-ignore
        window[`${name}GalleryCallback`] = undefined;
        // @ts-ignore
        window[`${name}ImageCallback`] = undefined;
        // @ts-ignore
        window[`${name}CDNImageCallback`] = undefined;
    };

    private galleryCallback = (addedImages: any, callback: (images: CKEditorGalleryImage[]) => void) => {
        this.setState({ galleryModal: { isVisible: true, callback } });
    };

    private cdnImageCallback = (callBack: (url: string) => void) => {
        MediaLibraryHelper.openLibrary({
            maximumSelectableAsset: 1,
            onSelection: (assets: string[]) => {
                if (assets.length > 0) {
                    callBack(MediaLibraryHelper.getImageUrl(assets[0]));
                }
            },
        });
    };

    private imageCallback = (callBack: (image: { fileName: string; dataAssetURL: string }) => void) => {
        MediaLibraryHelper.openLibrary({
            maximumSelectableAsset: 1,
            onSelection: (assets: string[]) => {
                if (assets.length > 0) {
                    callBack({
                        fileName: assets[0],
                        dataAssetURL: MediaLibraryHelper.getImageUrl(assets[0]),
                    });
                }
            },
        });
    };

    private onGalleryModalClose = () => {
        this.setState({ galleryModal: { isVisible: false } });
    };

    private onGallerySelected = (galleryImages: GalleryImage[]) => {
        if (this.state.galleryModal.callback) {
            const images: CKEditorGalleryImage[] = galleryImages.map((image: GalleryImage) => {
                return {
                    url: MediaLibraryHelper.getImageUrl(image.url),
                    dataAssetURL: MediaLibraryHelper.getImageUrl(image.url),
                    caption: image.caption || "",
                    credit: image.credit || "",
                };
            });
            this.state.galleryModal.callback(images);
        }
        this.setState({ galleryModal: { isVisible: false } });
    };

    private getConfig = () => {
        const defaultToolbarItems = ["fontSize"];
        const defaultFontSizeProperties = {
            fontSize: {
                options: [9, 11, 13, "default", 17, 19, 21],
            },
        };

        switch (this.props.config) {
            case "lead":
                return {
                    isIconEnabled: true,
                    ...defaultFontSizeProperties,
                    toolbar: {
                        items: [...defaultToolbarItems, "bulletedList"],
                    },
                };
            case "websiteNotification":
                return {
                    isIconEnabled: true,
                    link: {
                        decorators: {
                            openInNewTab: {
                                mode: "manual",
                                label: "Open in a new tab",
                                attributes: {
                                    target: "_blank",
                                    rel: "noopener noreferrer",
                                },
                            },
                        },
                    },
                    ...defaultFontSizeProperties,
                    toolbar: {
                        items: [...defaultToolbarItems, "bold", "underline", "italic", "|", "indent", "outdent", "link", "|", "undo", "redo"],
                    },
                };
            case "basic":
                return {
                    isIconEnabled: true,
                    link: {
                        decorators: {
                            openInNewTab: {
                                mode: "manual",
                                label: "Open in a new tab",
                                attributes: {
                                    target: "_blank",
                                    rel: "noopener noreferrer",
                                },
                            },
                        },
                    },
                    ...defaultFontSizeProperties,
                    toolbar: {
                        items: [
                            ...defaultToolbarItems,
                            "heading",
                            "|",
                            "bold",
                            "underline",
                            "italic",
                            "bulletedList",
                            "numberedList",
                            "|",
                            "indent",
                            "outdent",
                            "mediaEmbed",
                            "blockQuote",
                            "link",
                            "insertTable",
                            "htmlEmbed",
                            "|",
                            "undo",
                            "redo",
                        ],
                    },
                    table: {
                        contentToolbar: ["tableColumn", "tableRow"],
                    },
                    mediaEmbed: {
                        previewsInData: true,
                    },
                    heading: {
                        options: [
                            { model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
                            { model: "heading1", view: "h1", title: "Heading 1", class: "ck-heading_heading1" },
                            { model: "heading2", view: "h2", title: "Heading 2", class: "ck-heading_heading2" },
                            { model: "heading3", view: "h3", title: "Heading 3", class: "ck-heading_heading3" },
                        ],
                    },
                };
            case "productDescription":
                return {
                    isIconEnabled: true,
                    link: {
                        decorators: {
                            openInNewTab: {
                                mode: "manual",
                                label: "Open in a new tab",
                                attributes: {
                                    target: "_blank",
                                    rel: "noopener noreferrer",
                                },
                            },
                        },
                    },
                    ...defaultFontSizeProperties,
                    toolbar: {
                        items: [
                            ...defaultToolbarItems,
                            "heading",
                            "|",
                            "bold",
                            "underline",
                            "italic",
                            "bulletedList",
                            "numberedList",
                            "|",
                            "indent",
                            "outdent",
                            "mediaEmbed",
                            "blockQuote",
                            "link",
                            "insertTable",
                            "htmlEmbed",
                            "cdnImage",
                            "|",
                            "undo",
                            "redo",
                        ],
                    },
                    cdnImage: {
                        toolbarLabel: I18n.formatMessage({ id: "components.inputs.richTextEditor.config.cdnImage.toolbarLabel" }),
                        callbackName: `${this.props.name}CDNImageCallback`,
                    },
                    table: {
                        contentToolbar: ["tableColumn", "tableRow"],
                    },
                    mediaEmbed: {
                        previewsInData: true,
                    },
                    heading: {
                        options: [
                            { model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
                            { model: "heading1", view: "h1", title: "Heading 1", class: "ck-heading_heading1" },
                            { model: "heading2", view: "h2", title: "Heading 2", class: "ck-heading_heading2" },
                            { model: "heading3", view: "h3", title: "Heading 3", class: "ck-heading_heading3" },
                        ],
                    },
                };
            case "pageHtmlBlock":
                return {
                    gallery: {
                        toolbarLabel: I18n.formatMessage({ id: "components.inputs.richTextEditor.config.gallery.toolbarLabel" }),
                        editButtonLabel: I18n.formatMessage({ id: "components.inputs.richTextEditor.config.gallery.editButtonLabel" }),
                        callbackName: `${this.props.name}GalleryCallback`,
                    },
                    infoImage: {
                        toolbarLabel: I18n.formatMessage({ id: "components.inputs.richTextEditor.config.infoImage.toolbarLabel" }),
                        descriptionLabel: I18n.formatMessage({ id: "components.inputs.richTextEditor.config.infoImage.descriptionLabel" }),
                        sourceLabel: I18n.formatMessage({ id: "components.inputs.richTextEditor.config.infoImage.sourceLabel" }),
                        callbackName: `${this.props.name}ImageCallback`,
                    },
                    ...defaultFontSizeProperties,
                    toolbar: {
                        items: [
                            ...defaultToolbarItems,
                            "heading",
                            "|",
                            "bold",
                            "italic",
                            "link",
                            "bulletedList",
                            "numberedList",
                            "|",
                            "indent",
                            "outdent",
                            "|",
                            "blockQuote",
                            "mediaEmbed",
                            "undo",
                            "redo",
                            "htmlEmbed",
                            "insertTable",
                            "infoImage",
                        ],
                    },
                    table: {
                        contentToolbar: ["tableColumn", "tableRow"],
                    },
                    heading: {
                        options: [
                            { model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
                            { model: "heading1", view: "h1", title: "Heading 1", class: "ck-heading_heading1" },
                            { model: "heading2", view: "h2", title: "Heading 2", class: "ck-heading_heading2" },
                            { model: "heading3", view: "h3", title: "Heading 3", class: "ck-heading_heading3" },
                        ],
                    },
                    mediaEmbed: {
                        previewsInData: true,
                    },
                    htmlEmbed: {
                        sanitizeHtml(inputHtml: string) {
                            return { html: inputHtml };
                        },
                    },
                    image: {
                        toolbar: ["imageTextAlternative", "imageStyle:full", "imageStyle:side"],
                    },
                };
            case "shippingMethodDescription":
                return {
                    infoImage: {
                        toolbarLabel: I18n.formatMessage({ id: "components.inputs.richTextEditor.config.infoImage.toolbarLabel" }),
                        descriptionLabel: I18n.formatMessage({ id: "components.inputs.richTextEditor.config.infoImage.descriptionLabel" }),
                        sourceLabel: I18n.formatMessage({ id: "components.inputs.richTextEditor.config.infoImage.sourceLabel" }),
                        callbackName: `${this.props.name}ImageCallback`,
                    },
                    ...defaultFontSizeProperties,
                    toolbar: {
                        items: [
                            ...defaultToolbarItems,
                            "heading",
                            "|",
                            "bold",
                            "italic",
                            "link",
                            "bulletedList",
                            "numberedList",
                            "|",
                            "indent",
                            "outdent",
                            "|",
                            "blockQuote",
                            "mediaEmbed",
                            "undo",
                            "redo",
                            "insertTable",
                            "infoImage",
                        ],
                    },
                    table: {
                        contentToolbar: ["tableColumn", "tableRow"],
                    },
                    heading: {
                        options: [
                            { model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
                            { model: "heading1", view: "h1", title: "Heading 1", class: "ck-heading_heading1" },
                            { model: "heading2", view: "h2", title: "Heading 2", class: "ck-heading_heading2" },
                            { model: "heading3", view: "h3", title: "Heading 3", class: "ck-heading_heading3" },
                        ],
                    },
                    mediaEmbed: {
                        previewsInData: true,
                    },
                    htmlEmbed: {
                        sanitizeHtml(inputHtml: string) {
                            return { html: inputHtml };
                        },
                    },
                    image: {
                        toolbar: ["imageTextAlternative", "imageStyle:full", "imageStyle:side"],
                    },
                };
            default:
                return {
                    gallery: {
                        toolbarLabel: I18n.formatMessage({ id: "components.inputs.richTextEditor.config.gallery.toolbarLabel" }),
                        editButtonLabel: I18n.formatMessage({ id: "components.inputs.richTextEditor.config.gallery.editButtonLabel" }),
                        callbackName: `${this.props.name}GalleryCallback`,
                    },
                    infoImage: {
                        toolbarLabel: I18n.formatMessage({ id: "components.inputs.richTextEditor.config.infoImage.toolbarLabel" }),
                        descriptionLabel: I18n.formatMessage({ id: "components.inputs.richTextEditor.config.infoImage.descriptionLabel" }),
                        sourceLabel: I18n.formatMessage({ id: "components.inputs.richTextEditor.config.infoImage.sourceLabel" }),
                        callbackName: `${this.props.name}ImageCallback`,
                    },
                    ...defaultFontSizeProperties,
                    toolbar: {
                        items: [
                            ...defaultToolbarItems,
                            "heading",
                            "|",
                            "bold",
                            "italic",
                            "link",
                            "bulletedList",
                            "numberedList",
                            "|",
                            "indent",
                            "outdent",
                            "|",
                            "blockQuote",
                            "mediaEmbed",
                            "undo",
                            "redo",
                            "htmlEmbed",
                            "insertTable",
                            "gallery",
                            "infoImage",
                        ],
                    },
                    table: {
                        contentToolbar: ["tableColumn", "tableRow"],
                    },
                    heading: {
                        options: [
                            { model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
                            { model: "heading1", view: "h1", title: "Heading 1", class: "ck-heading_heading1" },
                            { model: "heading2", view: "h2", title: "Heading 2", class: "ck-heading_heading2" },
                            { model: "heading3", view: "h3", title: "Heading 3", class: "ck-heading_heading3" },
                        ],
                    },
                    mediaEmbed: {
                        previewsInData: true,
                    },
                    htmlEmbed: {
                        sanitizeHtml(inputHtml: string) {
                            return { html: inputHtml };
                        },
                    },
                    image: {
                        toolbar: ["imageTextAlternative", "imageStyle:full", "imageStyle:side"],
                    },
                };
        }
    };

    public render() {
        return (
            <Box className={this.props.classes.ckeditorWrapper}>
                <CKEditor
                    editor={window.ClassicEditor}
                    config={this.getConfig()}
                    data={this.props.value}
                    onChange={(_event: any, editor: any) => {
                        this.props.onChange(editor.getData());
                    }}
                />
                <CKGalleryModal isVisible={this.state.galleryModal.isVisible} onClose={this.onGalleryModalClose} onGallerySelected={this.onGallerySelected} />
            </Box>
        );
    }
}

// TODO: sitebuild fix
const styles = () =>
    createStyles({
        ckeditorWrapper: {
            "& .ck-content": {
                overflowWrap: "anywhere",
            },
            "& .ck-editor__editable_inline": {
                minHeight: "100px",
            },
        },
    });

export default withStyles(styles)(RichTextEditor);
