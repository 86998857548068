import React from "react";
import { FormControl, FormHelperText, Button, Box } from "@bigfish/admin-ui/core";
import { PasswordField } from "@bigfish/admin-ui/components";
import { Form } from "Components/Form";
import { Field, FieldProps, FormikErrors, FormikProps } from "formik";
import { Validator } from "Utils/Validator";
import { I18n } from "Src/i18n/I18n";
import { I18nHelpers } from "I18n/I18nHelpers";

export type ResetPasswordFormValues = {
    password: string;
    passwordConfirmation: string;
};

export const resetPasswordFormValidator = (values: ResetPasswordFormValues): FormikErrors<ResetPasswordFormValues> => {
    const errors: FormikErrors<ResetPasswordFormValues> = {};
    errors.password = I18nHelpers.formatValidator(Validator.newPassword)(values.password);
    errors.passwordConfirmation = I18nHelpers.formatValidator(Validator.samePassword(values.password))(values.passwordConfirmation);
    return Form.cleanupFormikErrors(errors);
};

type Props = {
    formProps: FormikProps<ResetPasswordFormValues>;
};

export const ResetPasswordForm = (props: Props) => {
    const passwordMeta = props.formProps.getFieldMeta("password");
    const passwordConfirmationMeta = props.formProps.getFieldMeta("passwordConfirmation");
    return (
        <Form formProps={props.formProps}>
            <Field name="password" validate={I18nHelpers.formatValidator(Validator.newPassword)}>
                {({ field, meta }: FieldProps) => (
                    <Box my="30px">
                        <PasswordField
                            type="password"
                            label={I18n.formatMessage({ id: "pages.resetPassword.form.password.label" })}
                            fullWidth
                            variant="outlined"
                            error={meta.touched && !!meta.error}
                            required
                            {...field}
                        />
                    </Box>
                )}
            </Field>
            <Field name="passwordConfirmation" validate={I18nHelpers.formatValidator(Validator.samePassword(props.formProps.values.password))}>
                {({ field, meta }: FieldProps) => (
                    <Box my="30px">
                        <PasswordField
                            type="password"
                            label={I18n.formatMessage({ id: "pages.resetPassword.form.passwordConfirmation.label" })}
                            fullWidth
                            variant="outlined"
                            error={meta.touched && !!meta.error}
                            required
                            {...field}
                        />
                    </Box>
                )}
            </Field>

            <Box my="30px">
                <FormControl fullWidth error={(passwordMeta.touched && !!passwordMeta.error) || (passwordConfirmationMeta.touched && !!passwordConfirmationMeta.error)}>
                    <FormHelperText component="span" style={{ textAlign: "center" }}>
                        {passwordConfirmationMeta.touched && passwordConfirmationMeta.error && <FormHelperText component="span">{passwordConfirmationMeta.error} </FormHelperText>}
                        {I18n.formatMessage({ id: "pages.resetPassword.form.info" })}
                    </FormHelperText>
                </FormControl>
            </Box>

            <FormControl fullWidth>
                <Button type="submit" variant="contained" size="large" color="secondary" fullWidth disabled={!props.formProps.dirty || props.formProps.isSubmitting}>
                    {I18n.formatMessage({ id: "pages.resetPassword.form.submit" })}
                </Button>
            </FormControl>
        </Form>
    );
};
