import React from "react";
import { WelcomeIllustration } from "./WelcomeIllustration";
import { Grid, Typography, withStyles, WithStyles } from "@bigfish/admin-ui/core";
import { PageLayout } from "Components/PageLayout";
import { Admin } from "Api/graphql/admin/types";
import { connect, DispatchProp } from "react-redux";
import { dashboardStyles } from "./DashboardStyles";
import { ApplicationState } from "Redux/rootReducer";
import { I18n } from "I18n/I18n";

type ReduxProps = {
    admin: Admin;
};

type Props = ReduxProps & DispatchProp & WithStyles<typeof dashboardStyles>;

class DashboardPage extends React.Component<Props> {
    render() {
        return (
            <PageLayout>
                <Grid container justify="center" alignItems="center" className={this.props.classes.container}>
                    <Grid item className={this.props.classes.content}>
                        <WelcomeIllustration />
                        <Typography variant="h1" className={this.props.classes.title}>
                            {I18n.formatMessage({ id: "pages.dashboard.welcome" }, { name: this.props.admin.name })}
                        </Typography>
                    </Grid>
                </Grid>
            </PageLayout>
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { admin: state.auth.admin! };
};

export default connect(mapStateToProps)(withStyles(dashboardStyles, { withTheme: true })(DashboardPage));
