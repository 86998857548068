import { gql } from "@apollo/client";
import { productParamValueFragment } from "../fragments/productParamValueFragment";
import { productPriceFragment } from "../fragments/productPriceFragment";
import { productStockFragment } from "../fragments/productStockFragment";
import { productImageFragment } from "../fragments/productImageFragment";

const productFieldsFragment = gql`
    fragment ProductFields on Product {
        id
        name
        lfdnr
        ean
        cart_qty_max
        categories {
            id
            category_path {
                id
                name
            }
        }
        slug
        unit_base
        images {
            ...ProductImage
        }
        is_active
        is_force_active
        active_from
        active_to
        param_values {
            ...ProductParamValue
        }
        brand_bank {
            id
            param_values {
                ...ProductParamValue
            }
        }
        badges_featured {
            name
        }
        badges_product_params {
            name
        }
        badges_notifications {
            name
        }
        prices {
            ...ProductPrice
        }
        stock {
            ...ProductStock
        }
        brand {
            id
            name
        }
        created_at
        updated_at
        created_by {
            id
            name
        }
        updated_by {
            id
            name
        }
        bundle_promotion_id
        image_path_360
        image_position_360
        brandbank_exclude
    }
    ${productImageFragment}
    ${productPriceFragment}
    ${productStockFragment}
    ${productParamValueFragment}
`;

export const productFragment = gql`
    fragment Product on Product {
        ...ProductFields
        children {
            ...ProductFields
        }
    }
    ${productFieldsFragment}
`;
