import React from "react";

import { Formik, FormikHelpers } from "formik";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";

import { ActiveBreadcrumbItem, TitleBar } from "@bigfish/admin-ui/components";
import { Box } from "@bigfish/admin-ui/core";

import { ProductParam } from "Api/graphql/admin/types";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Breadcrumbs } from "Components/Breadcrumbs";
import { Form, FormType } from "Components/Form";
import { Link } from "Components/Link";
import { Loading } from "Components/Loading";
import { PageLayout } from "Components/PageLayout";
import Prompt from "Components/Prompt";
import { I18n } from "I18n/I18n";
import { Path } from "Utils/Path";

import { ProductParamForm, ProductParamFormValues } from "./ProductParamForm";

type RouteParams = {
    id?: string;
};

type Props = WithSnackbarProps & RouteComponentProps<RouteParams>;

type State = {
    isLoading: boolean;
    productParam: ProductParam | null;
};

class ProductParamEditPage extends React.Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        productParam: null,
    };

    public async componentDidMount(): Promise<void> {
        if (!this.props.match.params.id) {
            return;
        }

        try {
            const productParam = await Api.productParam(this.props.match.params.id);
            this.setState({ productParam, isLoading: false });
        } catch (error) {
            this.props.enqueueSnackbar(error.message, { variant: "error" });
            this.props.history.push(Path.productParamList);
        }
    }

    private onSubmit = async (values: ProductParamFormValues, formikHelpers: FormikHelpers<ProductParamFormValues>): Promise<boolean> => {
        const input = {
            name: values.name,
            // TODO: temporarily disabled field
            // is_filter: values.is_filter,
            options: this.state.productParam?.options.map((option, i) => ({
                id: option.id,
                label: values.options?.[i].newLabel.length > 0 ? values.options[i].newLabel : option.label,
            })),
        };

        try {
            const productParam = await Api.updateProductParam(this.state.productParam!.id, input);
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.productParam.onEditSubmit.succeed" }), { variant: "success" });
            this.setState({ productParam });
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.productParam.onEditSubmit.error" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
        }
        return false;
    };

    private getBreadcrumb = () => {
        return (
            <Breadcrumbs>
                <Link to={Path.productParamList} color="inherit">
                    {I18n.formatMessage({ id: "pages.productParam.editBreadcrumb" })}
                </Link>
                <ActiveBreadcrumbItem aria-current="page">{this.state.productParam?.name ?? this.state.productParam?.evo_name}</ActiveBreadcrumbItem>
            </Breadcrumbs>
        );
    };

    public render() {
        const { isLoading, productParam } = this.state;

        if (isLoading) {
            return <Loading breadcrumb={this.getBreadcrumb()} />;
        }

        if (!productParam) {
            return <Redirect to={Path.productParamList} />;
        }

        const initialValues: ProductParamFormValues = {
            name: productParam.name,
            // TODO: temporarily disabled field
            // is_filter: productParam.is_filter,
            options: productParam.options.map(option => ({ id: option.id, newLabel: "" })),
        };

        return (
            <PageLayout breadcrumb={this.getBreadcrumb()}>
                <TitleBar title={productParam.name} />
                <Formik initialValues={initialValues} onSubmit={this.onSubmit} enableReinitialize={true}>
                    {props => (
                        <>
                            <ProductParamForm formType={FormType.edit} formProps={props} productParam={productParam} />
                            <Prompt when={props.dirty} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props)} />
                        </>
                    )}
                </Formik>
                <Box mt="40px" />
            </PageLayout>
        );
    }
}

export default withSnackbar(withRouter(ProductParamEditPage));
