import React, { useCallback } from "react";
import { Form, FormType } from "Components/Form";
import { Button, Grid, Icon, TextField, Typography, Box } from "@bigfish/admin-ui/core";
import { Field, FieldProps, FormikProps } from "formik";
import { AdminSelectItem, listAdminsForSelect_listAdmins_data, Todo, TodoInput, TodoStatus } from "Api/graphql/admin/types";
import { I18n } from "Src/i18n/I18n";
import { FullscreenLoader, PageCard, SavePanel } from "@bigfish/admin-ui/components";
import { Path } from "Utils/Path";
import { useHistory } from "react-router-dom";
import { I18nHelpers } from "I18n/I18nHelpers";
import { Validator } from "Utils/Validator";
import { ObjectUtils } from "Utils/ObjectUtils";
import SelectInput from "Components/SelectInput";
import FetchedSelect from "Components/FetchedSelect/FetchedSelect";
import { Api } from "Api/Api";
import { useSnackbar } from "notistack";
import { ApiError } from "Api/ApiError";
import { StatisticsPaper } from "Components/StatisticsPaper";

export type TodoFormValues = TodoInput;

type Props = {
    formType: FormType;
    formProps: FormikProps<TodoFormValues>;
    todo?: Todo;
};

export const TodoForm = (props: Props) => {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const onCancelClick = useCallback(() => {
        history.push(Path.todoList);
    }, [history]);

    return (
        <Form formProps={props.formProps}>
            <PageCard.Container>
                <PageCard.Heading title={I18n.formatMessage({ id: "common.form.content" })} />

                <Box mt="30px" />

                {props.formType === FormType.edit && (
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Typography color="textSecondary" gutterBottom>
                                {I18n.formatMessage({ id: "pages.todo.form.idLabel" })}
                            </Typography>
                            <Typography variant="subtitle1">{props.todo?.id}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography color="textSecondary" gutterBottom>
                                {I18n.formatMessage({ id: "pages.todo.form.roleLabel" })}
                            </Typography>
                            <Typography variant="subtitle1">{props.todo?.role.title}</Typography>
                        </Grid>
                    </Grid>
                )}

                <Box mt="30px" />

                <Field name="status" validate={I18nHelpers.formatValidator(Validator.required)}>
                    {({ field, meta }: FieldProps) => (
                        <SelectInput
                            label={I18n.formatMessage({ id: "pages.todo.form.statusLabel" })}
                            fullWidth
                            variant="outlined"
                            options={[
                                ...ObjectUtils.enumAsArray<TodoStatus>(TodoStatus).map((type: TodoStatus) => {
                                    return { id: type, title: I18n.formatMessage({ id: `enums.todoStatus.${type}` }) };
                                }),
                            ]}
                            helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                            {...field}
                            error={meta.touched && !!meta.error}
                        />
                    )}
                </Field>

                <Box mt="30px" />

                <Field name="assigned_to">
                    {({ field, meta }: FieldProps) => (
                        <FetchedSelect
                            label={I18n.formatMessage({ id: "pages.todo.form.assignedToLabel" })}
                            fullWidth
                            variant="outlined"
                            getTitle={(assignedTo: AdminSelectItem) => assignedTo.name}
                            fetch={async (): Promise<AdminSelectItem[]> => {
                                try {
                                    let total = 0;
                                    let adminSelectItems: listAdminsForSelect_listAdmins_data[] = [];

                                    let page = 1;
                                    const result = await Api.listAdminsForSelect({ first: 9999 });
                                    total = result.paginatorInfo.total;
                                    adminSelectItems = [...adminSelectItems, ...result.data];

                                    while (adminSelectItems.length < total) {
                                        page = page + 1;
                                        const nextResult = await Api.listAdminsForSelect({ first: 9999, page });
                                        total = nextResult.paginatorInfo.total;
                                        adminSelectItems = [...adminSelectItems, ...nextResult.data];
                                    }

                                    return adminSelectItems;
                                } catch (error) {
                                    if (error instanceof ApiError) {
                                        enqueueSnackbar(error.message, { variant: "error" });
                                    }
                                    return [];
                                }
                            }}
                            helperText={Form.getHelperText(meta, "")}
                            {...field}
                            error={meta.touched && !!meta.error}
                        />
                    )}
                </Field>

                <Box mt="30px" />

                <Field name="subject" validate={I18nHelpers.formatValidator(Validator.required)}>
                    {({ field, meta }: FieldProps) => (
                        <TextField
                            type="text"
                            label={I18n.formatMessage({ id: "pages.todo.form.subjectLabel" })}
                            fullWidth
                            variant="outlined"
                            helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                            error={meta.touched && !!meta.error}
                            {...field}
                        />
                    )}
                </Field>

                <Box mt="30px" />

                <Field name="message" validate={I18nHelpers.formatValidator(Validator.required)}>
                    {({ field, meta }: FieldProps) => (
                        <TextField
                            type="text"
                            label={I18n.formatMessage({ id: "pages.todo.form.messageLabel" })}
                            fullWidth
                            variant="outlined"
                            helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                            error={meta.touched && !!meta.error}
                            {...field}
                        />
                    )}
                </Field>
            </PageCard.Container>

            {props.todo && (
                <StatisticsPaper
                    createdAt={props.todo.created_at}
                    createdBy={props.todo.created_by?.name}
                    updatedAt={props.todo.updated_at}
                    updatedBy={props.todo.updated_by?.name}
                />
            )}

            <SavePanel>
                <Grid container justify="space-between">
                    <Button variant="outlined" color="primary" onClick={onCancelClick}>
                        {I18n.formatMessage({ id: "common.cancel" })}
                    </Button>
                    <Button
                        type="submit"
                        startIcon={<Icon className="fas fa-save" />}
                        variant="contained"
                        color="secondary"
                        disabled={!props.formProps.dirty || !props.formProps.isValid}
                    >
                        {I18n.formatMessage({ id: "common.save" })}
                    </Button>
                </Grid>
            </SavePanel>
            <FullscreenLoader visible={props.formProps.isSubmitting} />
        </Form>
    );
};
