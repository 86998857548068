import React, { useCallback, useState } from "react";
import { Form, FormType } from "Components/Form";
import {
    Button,
    FormControl,
    Grid,
    Icon,
    TextField,
    Box,
    Chip,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    FormControlLabel,
    Checkbox,
    InputAdornment,
} from "@bigfish/admin-ui/core";
import { Field, FieldProps, FormikErrors, FormikProps } from "formik";
import { Fulfillment, FulfillmentInput, FulfillmentTypeSelectable, PackageType, RossmannPlusVipLevel, ShippingMethodTabItem, ShippingMethodType } from "Api/graphql/admin/types";
import { I18n } from "Src/i18n/I18n";
import { FullscreenLoader, PageCard, SavePanel } from "@bigfish/admin-ui/components";
import { Path } from "Utils/Path";
import { useHistory } from "react-router-dom";
import { I18nHelpers } from "I18n/I18nHelpers";
import { Validator } from "Utils/Validator";
import { NumberUtils } from "Utils/NumberUtils";
import { ObjectUtils } from "Utils/ObjectUtils";
import { CustomFormHelperText } from "Components/CustomFormHelperText";
import { Constants } from "Utils/Constants";
import { EventBusy } from "@bigfish/admin-ui/icons";
import SelectInput from "Components/SelectInput";
import ProductCatalogDataGrid from "Pages/ProductCatalog/ProductCatalogDataGrid";
import { AddProductDialog } from "Components/AddProductDialog";
import { StringUtils } from "Utils/StringUtils";

export type FulfillmentFormValues = Omit<FulfillmentInput, "package_type_max"> & { package_type_max: PackageType | null | "null" };

type Props = {
    formType: FormType;
    formProps: FormikProps<FulfillmentFormValues>;
    fulfillment?: Fulfillment;
    shippingMethods: ShippingMethodTabItem[];
};

export const fulfillmentValidator = (values: FulfillmentFormValues): FormikErrors<FulfillmentFormValues> => {
    const errors: { [key in keyof FulfillmentFormValues]?: any } = {};

    if (values.active_from && values.active_to) {
        errors.active_to = Validator.endDateCantBeBeforeStart(values.active_from, values.active_to);
        errors.active_from = errors.active_from || Validator.startDateCantBeAfterEnd(values.active_from, values.active_to);
    }

    return Form.cleanupFormikErrors(errors);
};

export const FulfillmentForm = (props: Props) => {
    const history = useHistory();
    const [isProductForceDialogVisible, setIsProductForceDialogVisible] = useState<boolean>(false);
    const [isProductExcludeDialogVisible, setIsProductExcludeDialogVisible] = useState<boolean>(false);
    const onCancelClick = useCallback(() => {
        history.push(Path.fulfillmentList);
    }, [history]);

    const handleShippingMethodsChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
        const { value } = event.target;
        props.formProps.setFieldValue("shipping_methods", typeof value === "string" ? value.split(",") : value);
        props.formProps.setFieldTouched("shipping_methods", true);
    };

    const handleVipLevelsChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
        const { value } = event.target;
        props.formProps.setFieldValue("vip_levels", typeof value === "string" ? value.split(",") : value);
        props.formProps.setFieldTouched("vip_levels", true);
    };

    const getTypeTitle = (type: PackageType) => {
        return type === PackageType.CRATE ? I18n.formatMessage({ id: "enums.packageType.crate" }) : type;
    };

    return (
        <>
            <Form formProps={props.formProps}>
                <Grid container>
                    <Grid item md={4}>
                        <Field name="type" validate={I18nHelpers.formatValidator(Validator.required)}>
                            {({ field, meta }: FieldProps) => (
                                <SelectInput
                                    label={I18n.formatMessage({ id: "pages.fulfillment.form.type" })}
                                    fullWidth
                                    variant="outlined"
                                    options={ObjectUtils.enumAsArray<FulfillmentTypeSelectable>(FulfillmentTypeSelectable)
                                        .map((type: FulfillmentTypeSelectable) => ({
                                            id: type,
                                            title: Constants.getFulfillmentTypeLabel(type),
                                        }))
                                        .sort((a, b) => a.title.localeCompare(b.title))}
                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                                    {...field}
                                    error={meta.touched && !!meta.error}
                                />
                            )}
                        </Field>
                    </Grid>
                </Grid>

                <Box mt="30px" />

                <Grid container>
                    <Grid item md={4}>
                        <Field name="weight" validate={I18nHelpers.formatValidator(Validator.required)}>
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "pages.fulfillment.form.weight" })}
                                    fullWidth
                                    variant="outlined"
                                    required
                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                                    error={meta.touched && !!meta.error}
                                    {...field}
                                    value={props.formProps.values.weight}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        if (e.currentTarget.value === "") {
                                            props.formProps.setFieldValue("weight", null);
                                            return;
                                        }

                                        const converted = e.currentTarget.value
                                            .replace(/--/g, "-")
                                            .replace(/\+/g, "")
                                            .replace(/[a-zA-Z]/g, "");

                                        if (`${converted}` !== `${props.formProps.values.weight}`) {
                                            props.formProps.setFieldValue("weight", converted);
                                        }
                                    }}
                                />
                            )}
                        </Field>
                    </Grid>
                </Grid>

                <Box mt="30px" />

                <Field name="is_active">
                    {({ field, meta }: FieldProps) =>
                        field.value !== undefined && (
                            <Box mt="15px" ml="10px">
                                <FormControl error={meta.touched && !!meta.error}>
                                    <FormControlLabel
                                        control={<Checkbox {...field} checked={field.value} />}
                                        label={I18n.formatMessage({ id: "pages.fulfillment.form.is_active" })}
                                    />
                                </FormControl>
                                <CustomFormHelperText meta={meta} />
                            </Box>
                        )
                    }
                </Field>

                <Box mt="30px" />

                <Grid container style={{ display: "flex" }} alignItems="center">
                    <Grid item>
                        <Box mr="30px">
                            <Field name="active_from" validate={I18nHelpers.formatValidator(Validator.required)}>
                                {({ field, meta }: FieldProps) => (
                                    <TextField
                                        id="active_from"
                                        label={I18n.formatMessage({ id: "pages.fulfillment.form.active_from" })}
                                        type="datetime-local"
                                        className={`datetime-local ${props.formProps.values.active_from ? "datetime-local-filled" : ""}`}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            endAdornment: props.formProps.values.active_from ? (
                                                <InputAdornment
                                                    position="end"
                                                    onClick={() => {
                                                        props.formProps.setFieldValue("active_from", "");
                                                        props.formProps.setFieldTouched("active_from", true);
                                                    }}
                                                >
                                                    <EventBusy />
                                                </InputAdornment>
                                            ) : null,
                                        }}
                                        {...field}
                                        value={field.value}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            props.formProps.setTouched({ ...props.formProps.touched, active_from: true });
                                            field.onChange(e);
                                        }}
                                        error={meta.touched && !!meta.error}
                                        helperText={Form.getHelperText(meta, "")}
                                    />
                                )}
                            </Field>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Field name="active_to" validate={I18nHelpers.formatValidator(Validator.required)}>
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    id="active_to"
                                    label={I18n.formatMessage({ id: "pages.fulfillment.form.active_to" })}
                                    className={`datetime-local ${props.formProps.values.active_to ? "datetime-local-filled" : ""}`}
                                    type="datetime-local"
                                    placeholder=""
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        endAdornment: props.formProps.values.active_to ? (
                                            <InputAdornment
                                                position="end"
                                                onClick={() => {
                                                    props.formProps.setFieldValue("active_to", "");
                                                    props.formProps.setFieldTouched("active_to", true);
                                                }}
                                            >
                                                <EventBusy />
                                            </InputAdornment>
                                        ) : null,
                                    }}
                                    {...field}
                                    value={field.value}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        props.formProps.setTouched({ ...props.formProps.touched, active_to: true });
                                        field.onChange(e);
                                    }}
                                    error={meta.touched && !!meta.error}
                                    helperText={Form.getHelperText(meta, "")}
                                />
                            )}
                        </Field>
                    </Grid>
                </Grid>

                <Box mt="30px" />

                <Grid container spacing={3}>
                    <Grid item md={4}>
                        <Field name="limit">
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "pages.fulfillment.form.limit" })}
                                    fullWidth
                                    variant="outlined"
                                    helperText={Form.getHelperText(meta, "")}
                                    error={meta.touched && !!meta.error}
                                    {...field}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        const value = e.currentTarget.value;
                                        if (!NumberUtils.isStringNumber(value)) return;

                                        if (e.currentTarget.value === "") {
                                            props.formProps.setFieldValue("limit", null);
                                            return;
                                        }

                                        const converted = StringUtils.cleanNumericValue(e.currentTarget.value);

                                        props.formProps.setFieldValue("limit", isNaN(converted) ? 0 : converted);
                                        props.formProps.setFieldTouched("limit", true);
                                    }}
                                />
                            )}
                        </Field>
                    </Grid>
                    <Grid item md={4}>
                        <Field name="package_type_max">
                            {({ field, meta }: FieldProps) => (
                                <SelectInput
                                    label={I18n.formatMessage({ id: "pages.fulfillment.form.package_type_max" })}
                                    fullWidth
                                    variant="outlined"
                                    options={[
                                        { id: Constants.nullFormValue, title: I18n.formatMessage({ id: "common.nullFormValue" }) },
                                        ...ObjectUtils.enumAsArray<PackageType>(PackageType)
                                            .map((type: PackageType) => ({
                                                id: type,
                                                title: getTypeTitle(type),
                                            }))
                                            .sort((a, b) => a.title.localeCompare(b.title)),
                                    ]}
                                    helperText={Form.getHelperText(meta, "")}
                                    {...field}
                                    error={meta.touched && !!meta.error}
                                />
                            )}
                        </Field>
                    </Grid>
                    <Grid item md={4}>
                        <Field name="quantity_max">
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "pages.fulfillment.form.quantity_max" })}
                                    fullWidth
                                    variant="outlined"
                                    required
                                    helperText={Form.getHelperText(meta, "")}
                                    error={meta.touched && !!meta.error}
                                    {...field}
                                    value={props.formProps.values.quantity_max}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        if (e.currentTarget.value === "") {
                                            props.formProps.setFieldValue("quantity_max", null);
                                            return;
                                        }

                                        const converted = StringUtils.cleanNumericValue(e.currentTarget.value);

                                        props.formProps.setFieldValue("quantity_max", isNaN(converted) ? 0 : converted);
                                        props.formProps.setFieldTouched("quantity_max", true);
                                    }}
                                />
                            )}
                        </Field>
                    </Grid>
                </Grid>

                <Box mt="30px" />

                <Field name="notification_threshold">
                    {({ field, meta }: FieldProps) => (
                        <TextField
                            type="text"
                            label={I18n.formatMessage({ id: "pages.fulfillment.form.notification_threshold.label" })}
                            fullWidth
                            variant="outlined"
                            helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "pages.fulfillment.form.notification_threshold.hint" }))}
                            error={meta.touched && !!meta.error}
                            {...field}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                if (e.currentTarget.value === "") {
                                    props.formProps.setFieldValue("notification_threshold", null);
                                    return;
                                }

                                const converted = StringUtils.cleanNumericValue(e.currentTarget.value);

                                props.formProps.setFieldValue("notification_threshold", isNaN(converted) ? 0 : converted);
                                props.formProps.setFieldTouched("notification_threshold", true);
                            }}
                        />
                    )}
                </Field>

                <Box mt="30px" />

                <Field name="shipping_methods" validate={I18nHelpers.formatValidator(Validator.required)}>
                    {({ field, meta }: FieldProps) => (
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>{I18n.formatMessage({ id: "pages.fulfillment.form.shipping_methods" })}</InputLabel>
                            <Select
                                labelId="multiselect-input"
                                id="multiselect-input"
                                multiple
                                input={<OutlinedInput id="multiselect-input" label={I18n.formatMessage({ id: "pages.fulfillment.form.shipping_methods" })} />}
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                renderValue={(selected: string[]) => {
                                    return (
                                        <Box>
                                            {selected.map((value: string) => (
                                                <Chip
                                                    className="multiselect-chip"
                                                    key={value}
                                                    label={props.shippingMethods.find(sm => sm.id === value)?.name ?? null}
                                                    deleteIcon={
                                                        <div
                                                            className="multiselect-delete-icon"
                                                            onMouseDown={event => {
                                                                event.stopPropagation();
                                                                props.formProps.setFieldValue(
                                                                    "shipping_methods",
                                                                    props.formProps.values.shipping_methods.filter(sm => sm !== value)
                                                                );
                                                            }}
                                                        >
                                                            <i className="fas fa-times-circle"></i>
                                                        </div>
                                                    }
                                                    onDelete={() => {}}
                                                    clickable={false}
                                                />
                                            ))}
                                        </Box>
                                    );
                                }}
                                error={meta.touched && !!meta.error}
                                {...field}
                                onChange={handleShippingMethodsChange}
                            >
                                {props.shippingMethods.map(sm => (
                                    <MenuItem key={sm.id} value={sm.id ?? ""}>
                                        {sm.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </Field>

                <Box mt="30px" />

                <Field name="vip_levels" validate={I18nHelpers.formatValidator(Validator.required)}>
                    {({ field, meta }: FieldProps) => (
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>{I18n.formatMessage({ id: "pages.fulfillment.form.vip_levels" })}</InputLabel>
                            <Select
                                labelId="multiselect-input"
                                id="multiselect-input"
                                multiple
                                input={<OutlinedInput id="multiselect-input" label={I18n.formatMessage({ id: "pages.fulfillment.form.vip_levels" })} />}
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                renderValue={(selected: string[]) => {
                                    return (
                                        <Box>
                                            {selected.map((value: string) => (
                                                <Chip
                                                    className="multiselect-chip"
                                                    key={value}
                                                    label={Constants.getRossmannPlusVipLevelTitle(value)}
                                                    deleteIcon={
                                                        <div
                                                            className="multiselect-delete-icon"
                                                            onMouseDown={event => {
                                                                event.stopPropagation();
                                                                props.formProps.setFieldValue(
                                                                    "vip_levels",
                                                                    props.formProps.values.vip_levels.filter(vl => vl !== value)
                                                                );
                                                            }}
                                                        >
                                                            <i className="fas fa-times-circle"></i>
                                                        </div>
                                                    }
                                                    onDelete={() => {}}
                                                    clickable={false}
                                                />
                                            ))}
                                        </Box>
                                    );
                                }}
                                error={meta.touched && !!meta.error}
                                {...field}
                                onChange={handleVipLevelsChange}
                            >
                                {ObjectUtils.enumAsArray<RossmannPlusVipLevel>(RossmannPlusVipLevel).map(level => (
                                    <MenuItem key={level} value={level}>
                                        {Constants.getRossmannPlusVipLevelTitle(level)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </Field>

                <Box mt="30px" />

                <Field name="timewindow_allowed">
                    {({ field, meta }: FieldProps) =>
                        field.value !== undefined && (
                            <Box mt="15px" ml="10px">
                                <FormControl error={meta.touched && !!meta.error}>
                                    <FormControlLabel
                                        control={<Checkbox {...field} checked={field.value} />}
                                        label={I18n.formatMessage({ id: "pages.fulfillment.form.timewindow_allowed" })}
                                        disabled={!props.formProps.values.shipping_methods.includes(ShippingMethodType.mpl_delivery)}
                                    />
                                </FormControl>
                                <CustomFormHelperText meta={meta} defaultHelperText={I18n.formatMessage({ id: "pages.fulfillment.form.timewindowAllowedHint" })} />
                            </Box>
                        )
                    }
                </Field>

                <Box mt="30px" />

                <PageCard.Heading title={I18n.formatMessage({ id: "pages.fulfillment.form.product_id_force" })} />

                <Button startIcon={<Icon className="fa fa-plus-circle" />} variant="outlined" size="medium" color="secondary" onClick={() => setIsProductForceDialogVisible(true)}>
                    {I18n.formatMessage({ id: "pages.productList.form.addProductButton" })}
                </Button>

                <Box mb="30px" />

                <ProductCatalogDataGrid
                    key="product_id_force-data-grid"
                    idsFilter={props.formProps.values.product_id_force || []}
                    isEmbedded
                    minHeight={300 + (props.formProps.values.product_id_force?.length || 0) * 50}
                    onDeleteWithFunctionalButton={id => {
                        props.formProps.setFieldValue(
                            "product_id_force",
                            (props.formProps.values.product_id_force ?? []).filter(pif => pif !== id)
                        );
                        props.formProps.setFieldTouched("product_id_force", true);
                    }}
                    isReadOnly
                />

                <Box mt="30px" />

                <PageCard.Heading title={I18n.formatMessage({ id: "pages.fulfillment.form.product_id_exclude" })} />

                <Button
                    startIcon={<Icon className="fa fa-plus-circle" />}
                    variant="outlined"
                    size="medium"
                    color="secondary"
                    onClick={() => setIsProductExcludeDialogVisible(true)}
                >
                    {I18n.formatMessage({ id: "pages.productList.form.addProductButton" })}
                </Button>

                <Box mb="30px" />

                <ProductCatalogDataGrid
                    key="product_id_exclude-data-grid"
                    idsFilter={props.formProps.values.product_id_exclude || []}
                    isEmbedded
                    minHeight={300 + (props.formProps.values.product_id_exclude?.length || 0) * 50}
                    onDeleteWithFunctionalButton={id => {
                        props.formProps.setFieldValue(
                            "product_id_exclude",
                            (props.formProps.values.product_id_exclude ?? []).filter(pif => pif !== id)
                        );
                        props.formProps.setFieldTouched("product_id_exclude", true);
                    }}
                    isReadOnly
                />

                <Box mt="30px" />

                <SavePanel>
                    <Grid container justify="space-between">
                        <Button variant="outlined" color="primary" onClick={onCancelClick}>
                            {I18n.formatMessage({ id: "common.cancel" })}
                        </Button>
                        <Button
                            type="submit"
                            startIcon={<Icon className="fas fa-save" />}
                            variant="contained"
                            color="secondary"
                            disabled={!props.formProps.dirty || !props.formProps.isValid}
                        >
                            {I18n.formatMessage({ id: `common.${props.formType === FormType.create ? "create" : "save"}` })}
                        </Button>
                    </Grid>
                </SavePanel>
                <FullscreenLoader visible={props.formProps.isSubmitting} />
            </Form>
            {isProductForceDialogVisible && (
                <AddProductDialog
                    key="product_id_force-dialog"
                    description={I18n.formatMessage({ id: "pages.fulfillment.form.addProductDialog.description" })}
                    initialSelectedProductIds={props.formProps.values.product_id_force ?? []}
                    onAddClick={(newProducts: number[]) => {
                        props.formProps.setFieldValue("product_id_force", newProducts);
                        setIsProductForceDialogVisible(false);
                    }}
                    onClose={() => setIsProductForceDialogVisible(false)}
                />
            )}
            {isProductExcludeDialogVisible && (
                <AddProductDialog
                    key="product_id_exclude-dialog"
                    description={I18n.formatMessage({ id: "pages.fulfillment.form.addProductDialog.description" })}
                    initialSelectedProductIds={props.formProps.values.product_id_exclude ?? []}
                    onAddClick={(newProducts: number[]) => {
                        props.formProps.setFieldValue("product_id_exclude", newProducts);
                        setIsProductExcludeDialogVisible(false);
                    }}
                    onClose={() => setIsProductExcludeDialogVisible(false)}
                />
            )}
        </>
    );
};
