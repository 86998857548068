import React from "react";
import { PageLayout } from "Components/PageLayout";
import ProductCatalogDataGrid from "./ProductCatalogDataGrid";
import { TitleBar, PageCard } from "@bigfish/admin-ui/components";
import { I18n } from "I18n/I18n";

class ProductCatalogPage extends React.Component {
    public render() {
        return (
            <PageLayout>
                <TitleBar title={I18n.formatMessage({ id: "pages.productCatalog.title" })} />
                <PageCard.Container>
                    <ProductCatalogDataGrid />
                </PageCard.Container>
            </PageLayout>
        );
    }
}

export default ProductCatalogPage;
