import React from "react";
import { Field, FieldProps, FormikProps } from "formik";
import { TextField, Button, Icon, Typography, Box } from "@bigfish/admin-ui/core";

import { Form } from "Components/Form";
import { Path } from "Utils/Path";
import { Validator } from "Utils/Validator";
import { I18n } from "Src/i18n/I18n";
import { PasswordField } from "@bigfish/admin-ui/components";
import { I18nHelpers } from "I18n/I18nHelpers";
import { Link } from "Components/Link";

export type LoginFormValues = {
    login: string;
    password: string;
};

type Props = {
    formProps: FormikProps<LoginFormValues>;
};

export const LoginForm = (props: Props) => {
    return (
        <Form formProps={props.formProps}>
            <Field name="login">
                {({ field, meta }: FieldProps) => (
                    <Box my="30px">
                        <TextField
                            type="text"
                            label={I18n.formatMessage({ id: "pages.login.form.login.label" })}
                            fullWidth
                            variant="outlined"
                            required
                            error={meta.touched && !!meta.error}
                            helperText={meta.error}
                            {...field}
                        />
                    </Box>
                )}
            </Field>
            <Field name="password" validate={I18nHelpers.formatValidator(Validator.required)}>
                {({ field, meta }: FieldProps) => (
                    <Box my="30px">
                        <PasswordField
                            type="password"
                            label={I18n.formatMessage({ id: "pages.login.form.password.label" })}
                            fullWidth
                            variant="outlined"
                            required
                            error={meta.touched && !!meta.error}
                            helperText={meta.error}
                            {...field}
                        />
                    </Box>
                )}
            </Field>
            <Box my="30px">
                <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    fullWidth
                    type="submit"
                    startIcon={<Icon className="fa fa-walking" style={{ color: "white" }} />}
                    disabled={props.formProps.isSubmitting}
                >
                    {I18n.formatMessage({ id: "pages.login.form.submit" })}
                </Button>
            </Box>
            <Typography align="center">
                <Link variant="body1" color="secondary" to={{ pathname: Path.forgotPassword, state: { email: props.formProps.values.login } }}>
                    {I18n.formatMessage({ id: "pages.login.form.forgotPassword" })}
                </Link>
            </Typography>
        </Form>
    );
};
