import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@bigfish/admin-ui/core";
import React from "react";
import { withSnackbar, WithSnackbarProps } from "notistack";

export type SelectOption = {
    id: string | number | null;
    title: string;
};

type ComponentProps = {
    value: string | number;
    onChange: (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>, child: React.ReactNode) => void;
    label?: string;
    error?: boolean;
    required?: boolean;
    name?: string;
    fullWidth?: boolean;
    variant?: "filled" | "outlined" | "standard";
    helperText?: React.ReactNode;
    options: SelectOption[];
    disabled?: boolean;
};

type Props = ComponentProps & WithSnackbarProps;

class SelectInput extends React.Component<Props> {
    private renderMenuItem = (option: SelectOption) => {
        return (
            <MenuItem key={`${option.id}`} value={option.id ?? `${option.id}`}>
                {option.title}
            </MenuItem>
        );
    };

    public render() {
        return (
            <FormControl variant="outlined" fullWidth>
                <InputLabel>{this.props.label}</InputLabel>
                {/* TODO: fix warning when loading in progress */}
                <Select
                    value={this.props.value}
                    onChange={this.props.onChange}
                    label={this.props.label}
                    error={this.props.error}
                    required={this.props.required}
                    name={this.props.name}
                    fullWidth={this.props.fullWidth}
                    variant={this.props.variant}
                    disabled={this.props.disabled}
                >
                    {this.props.options.map(this.renderMenuItem)}
                </Select>
                <FormHelperText className={this.props.error ? "custom-error-helper" : "helper-text-nowrap"}>{this.props.helperText}</FormHelperText>
            </FormControl>
        );
    }
}

export default withSnackbar(SelectInput);
