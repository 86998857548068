import React, { useCallback, useMemo } from "react";
import { Form, FormType } from "Components/Form";
import { Button, Grid, Icon, TextField, Typography, Box, FormControl, FormControlLabel, Checkbox, InputAdornment, Radio, IconButton } from "@bigfish/admin-ui/core";
import { Field, FieldProps, FormikErrors, FormikProps } from "formik";
import {
    ProductListInput,
    ProductList,
    ProductListType,
    ProductListMode,
    BadgeListItem,
    ProductFilterType,
    ProductFilterInput,
    listProductFilterParams_listProductFilterParams,
    ProductFilterOperator,
    PriceType,
    listProductBrandSelectItems_listProductBrands_data,
    ProductType,
} from "Api/graphql/admin/types";
import { Validator } from "Utils/Validator";
import { I18n } from "Src/i18n/I18n";
import { I18nHelpers } from "I18n/I18nHelpers";
import { FullscreenLoader, PageCard, SavePanel } from "@bigfish/admin-ui/components";
import { Path } from "Utils/Path";
import { useHistory } from "react-router-dom";
import { Close, EventBusy } from "@bigfish/admin-ui/icons";
import { CustomFormHelperText } from "Components/CustomFormHelperText";
import MediaLibraryImageInput from "Components/Inputs/MediaLibraryImageInput";
import { AddProductDialog } from "Components/AddProductDialog";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import { Constants } from "Utils/Constants";
import { AddBadgeDialog } from "Components/AddBadgeDialog/AddBadgeDialog";
import ProductListBadgeListDataGrid from "./ProductListBadgeListDataGrid";
import { createModal } from "Components/createModal";
import ModalBadgeEditPage from "Pages/ModalBadge/ModalBadgeEditPage";
import SelectInput from "Components/SelectInput";
import { ObjectUtils } from "Utils/ObjectUtils";
import FetchedSelect, { SelectOption } from "Components/FetchedSelect/FetchedSelect";
import { Api } from "Api/Api";
import { useSnackbar } from "notistack";
import { ProductCategoryUtils } from "Utils/ProductCategoryUtils";
import ProductCatalogDataGrid from "Pages/ProductCatalog/ProductCatalogDataGrid";
import { ProductListUtils } from "Utils/ProductListUtils";
import { Autocomplete } from "@bigfish/admin-ui/lab";
import { ReorderModal } from "../../components/ReorderModal/ReorderModal";
import { Helpers } from "Utils/Helpers";
import { ApiError } from "Api/ApiError";

export type DisplayedContainer = {
    id: number;
    title: string;
};

export type ProductListFormValues = ProductListInput & { badge: BadgeListItem | null };

type ComponentProps = {
    formType: FormType;
    formProps: FormikProps<ProductListFormValues>;
    productList?: ProductList;
    productListType: ProductListType;
    productFilterParams: listProductFilterParams_listProductFilterParams[];
    productBrands: listProductBrandSelectItems_listProductBrands_data[];
};

type Props = ComponentProps;

const ModalBadgeEditPageDialog = createModal(Constants.getModalPageStyles());

export const productListValidator = (values: ProductListFormValues): FormikErrors<ProductListFormValues> => {
    const errors: { [key in keyof ProductListFormValues]?: any } = {};

    if (values.active_from && values.active_to) {
        errors.active_to = Validator.endDateCantBeBeforeStart(values.active_from, values.active_to);
        errors.active_from = errors.active_from || Validator.startDateCantBeAfterEnd(values.active_from, values.active_to);
    }

    if (values.type === ProductListType.promotion) {
        errors.image = I18nHelpers.formatValidator(Validator.required)(values.image);
        errors.image_mobile = I18nHelpers.formatValidator(Validator.required)(values.image_mobile);
    }

    if (values.mode === ProductListMode.manual) {
        errors.products = I18nHelpers.formatValidator(Validator.arrayCantBeEmpty)(values.products);
    }

    if (values.mode === ProductListMode.auto) {
        errors.filters = I18nHelpers.formatValidator(Validator.arrayCantBeEmpty)(values.filters);
    }

    errors.slug = I18nHelpers.formatValidator(Validator.slug)(values.slug);

    return Form.cleanupFormikErrors(errors);
};

export const ProductListForm = (props: Props) => {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const onCancelClick = useCallback(() => {
        history.push(Path.productListList);
    }, [history]);

    const [isProductAddDialogVisible, setIsProductAddDialogVisible] = React.useState<boolean>(false);
    const [isBadgeAddDialogVisible, setIsBadgeAddDialogVisible] = React.useState<boolean>(false);
    const [editedModalBadgeId, setEditedModalBadgeId] = React.useState<number | null>(null);
    const [isReorderModalOpen, setIsReorderModalOpen] = React.useState<boolean>(false);

    const handleAddProductsClick = useCallback(
        (newProducts: number[]) => {
            props.formProps.setFieldValue("products", newProducts);
            setIsProductAddDialogVisible(false);
        },
        [props.formProps]
    );

    const handleAddBadgesClick = (newBadges: BadgeListItem[]) => {
        props.formProps.setFieldValue("badge", newBadges[0]);
        setIsBadgeAddDialogVisible(false);
    };

    const onModalBadgeUpdate = (modalBadge: BadgeListItem | null) => {
        props.formProps.setFieldValue("badge", modalBadge);
    };

    const getOperatorFilterFunction = (type: ProductFilterType) => {
        switch (type) {
            case ProductFilterType.created:
                return (operator: ProductFilterOperator) => [ProductFilterOperator.lte, ProductFilterOperator.gte].includes(operator);
            case ProductFilterType.rating:
                return (operator: ProductFilterOperator) => operator === ProductFilterOperator.gte;
            case ProductFilterType.product_param:
            case ProductFilterType.promotion:
            case ProductFilterType.product_category:
            case ProductFilterType.brand:
            case ProductFilterType.price_type:
            default:
                return (operator: ProductFilterOperator) => operator === ProductFilterOperator.eq;
        }
    };

    const getFilterValueGridByType = (type: ProductFilterType, index: number) => {
        switch (type) {
            case ProductFilterType.product_param:
                return (
                    <Field key={type} name={`filters.${index}.product_param_id`}>
                        {({ field, meta }: FieldProps) => (
                            <div>
                                <Autocomplete
                                    key="value-product_param_id"
                                    options={props.productFilterParams}
                                    getOptionLabel={option => option.name ?? ""}
                                    defaultValue={props.productFilterParams.find(pfp => pfp.id === field.value)}
                                    filterSelectedOptions
                                    renderInput={params => <TextField {...params} label={I18n.formatMessage({ id: `pages.productList.form.filters.valueLabel.${type}` })} />}
                                    onChange={(
                                        _event: React.ChangeEvent<{}>,
                                        value: string | listProductFilterParams_listProductFilterParams | (string | listProductFilterParams_listProductFilterParams)[] | null
                                    ) => {
                                        if (value && typeof value === "object" && "id" in value) {
                                            props.formProps.setFieldValue(`filters.${index}.product_param_id`, value.id);
                                            props.formProps.setFieldTouched(`filters.${index}.product_param_id`, true);
                                        }
                                    }}
                                    noOptionsText={I18n.formatMessage({ id: "common.noOptions" })}
                                />
                                <CustomFormHelperText meta={meta} />
                            </div>
                        )}
                    </Field>
                );
            case ProductFilterType.product_category:
                return (
                    <Field key={type} name={`filters.${index}.product_category_id`}>
                        {({ field, meta }: FieldProps) => (
                            <FetchedSelect
                                key="value-product-category-id"
                                label={I18n.formatMessage({ id: `pages.productList.form.filters.valueLabel.${type}` })}
                                fullWidth
                                variant="outlined"
                                getTitle={(category: SelectOption) => category.title}
                                fetch={async (): Promise<SelectOption[]> => {
                                    try {
                                        const categorySelectItems = await Api.listProductCategorySelectItems();
                                        const mainCategories = ProductCategoryUtils.getMainCategories(categorySelectItems).map(c => {
                                            return { id: c.id, title: c.name };
                                        });
                                        const categories = ProductCategoryUtils.getCategories(categorySelectItems).map(c => {
                                            return { id: c.id, title: c.name };
                                        });
                                        return [...mainCategories, ...categories].sort((a, b) => (a.title > b.title ? 1 : -1));
                                    } catch (error) {
                                        enqueueSnackbar(error.message, { variant: "error" });
                                        return [];
                                    }
                                }}
                                helperText={Form.getHelperText(meta, "")}
                                {...field}
                                error={meta.touched && !!meta.error}
                            />
                        )}
                    </Field>
                );
            case ProductFilterType.brand:
                return (
                    <Field key={type} name={`filters.${index}.product_brand_id`}>
                        {({ field, meta }: FieldProps) => (
                            <div>
                                <Autocomplete
                                    key="value-product-brand-id"
                                    options={props.productBrands}
                                    getOptionLabel={option => option.name ?? ""}
                                    defaultValue={props.productBrands.find(pb => pb.id === field.value)}
                                    filterSelectedOptions
                                    renderInput={params => <TextField {...params} label={I18n.formatMessage({ id: `pages.productList.form.filters.valueLabel.${type}` })} />}
                                    onChange={(
                                        _event: React.ChangeEvent<{}>,
                                        value: string | listProductBrandSelectItems_listProductBrands_data | (string | listProductBrandSelectItems_listProductBrands_data)[] | null
                                    ) => {
                                        if (value && typeof value === "object" && "id" in value) {
                                            props.formProps.setFieldValue(`filters.${index}.product_brand_id`, value.id);
                                            props.formProps.setFieldTouched(`filters.${index}.product_brand_id`, true);
                                        }
                                    }}
                                    noOptionsText={I18n.formatMessage({ id: "common.noOptions" })}
                                />
                                <CustomFormHelperText meta={meta} />
                            </div>
                        )}
                    </Field>
                );
            case ProductFilterType.created:
                return (
                    <Field key={type} name={`filters.${index}.created_at`}>
                        {({ field, meta }: FieldProps) => (
                            <TextField
                                key={`filters.${index}.created_at`}
                                id={`filters.${index}.created_at`}
                                label={I18n.formatMessage({ id: `pages.productList.form.filters.valueLabel.${type}` })}
                                type="datetime-local"
                                className={`datetime-local ${props.formProps.values.filters[index].created_at ? "datetime-local-filled" : ""}`}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    endAdornment: props.formProps.values.filters[index].created_at ? (
                                        <InputAdornment
                                            position="end"
                                            onClick={() => {
                                                props.formProps.setFieldValue(`filters.${index}.created_at`, "");
                                                props.formProps.setFieldTouched(`filters.${index}.created_at`, true);
                                            }}
                                        >
                                            <EventBusy />
                                        </InputAdornment>
                                    ) : null,
                                }}
                                {...field}
                                value={field.value}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    props.formProps.setTouched({ ...props.formProps.touched, [`filters.${index}.created_at`]: true });
                                    field.onChange(e);
                                }}
                                error={meta.touched && !!meta.error}
                                helperText={Form.getHelperText(meta, "")}
                            />
                        )}
                    </Field>
                );
            case ProductFilterType.rating:
                return (
                    <Field key={type} name={`filters.${index}.rating`}>
                        {({ field, meta }: FieldProps) => (
                            <SelectInput
                                label={I18n.formatMessage({ id: `pages.productList.form.filters.valueLabel.${type}` })}
                                fullWidth
                                variant="outlined"
                                options={[1, 2, 3, 4, 5].map((rating: number) => {
                                    return { id: rating, title: `${rating}` };
                                })}
                                helperText={Form.getHelperText(meta, "")}
                                {...field}
                                error={meta.touched && !!meta.error}
                            />
                        )}
                    </Field>
                );
            case ProductFilterType.price_type:
                return (
                    <Field key={type} name={`filters.${index}.price_type`}>
                        {({ field, meta }: FieldProps) => (
                            <SelectInput
                                label={I18n.formatMessage({ id: `pages.productList.form.filters.valueLabel.${type}` })}
                                fullWidth
                                variant="outlined"
                                options={[
                                    ...ObjectUtils.enumAsArray<PriceType>(PriceType).map((type: PriceType) => {
                                        return { id: type, title: I18n.formatMessage({ id: `enums.priceType.${type}` }) };
                                    }),
                                ]}
                                helperText={Form.getHelperText(meta, "")}
                                {...field}
                                error={meta.touched && !!meta.error}
                            />
                        )}
                    </Field>
                );
            case ProductFilterType.clpe_promotion_type:
                return (
                    <Field key={type} name={`filters.${index}.clpe_promotion_type`}>
                        {({ field, meta }: FieldProps) => (
                            <FetchedSelect
                                label={I18n.formatMessage({ id: `pages.productList.form.filters.valueLabel.${type}` })}
                                fullWidth
                                variant="outlined"
                                helperText={Form.getHelperText(meta, "")}
                                getTitle={(clpe: SelectOption) => clpe.title ?? ""}
                                fetch={async (): Promise<SelectOption[]> => {
                                    try {
                                        const clpePromotionTypes = await Api.clpePromotionTypeDescriptions();
                                        return clpePromotionTypes.map(type => ({ id: type.name, title: type.description ?? type.name }));
                                    } catch (error) {
                                        if (error instanceof ApiError) {
                                            enqueueSnackbar(error.message, { variant: "error" });
                                        }
                                        return [];
                                    }
                                }}
                                {...field}
                                value={field.value ?? ""}
                                error={meta.touched && !!meta.error}
                            />
                        )}
                    </Field>
                );
            case ProductFilterType.product_type:
                return (
                    <Field key={type} name={`filters.${index}.product_type`}>
                        {({ field, meta }: FieldProps) => (
                            <SelectInput
                                label={I18n.formatMessage({ id: `pages.productList.form.filters.valueLabel.${type}` })}
                                fullWidth
                                variant="outlined"
                                options={[
                                    ...ObjectUtils.enumAsArray<ProductType>(ProductType).map((type: ProductType) => {
                                        return { id: type, title: I18n.formatMessage({ id: `enums.productType.${type}` }) };
                                    }),
                                ]}
                                helperText={Form.getHelperText(meta, "")}
                                {...field}
                                error={meta.touched && !!meta.error}
                            />
                        )}
                    </Field>
                );
            default:
                return "";
        }
    };

    const renderProductCatalogDataGrid = useMemo(() => {
        const { filters, mode, products } = props.formProps.values;

        const cleanFilters = filters.map(f => ProductListUtils.cleanFilter(f));
        const filtersBase = cleanFilters.filter(cf => !Object.values(cf).some(v => v === null || v === undefined));

        return (
            <ProductCatalogDataGrid
                key="product-list-displayed-products"
                filtersBase={mode === ProductListMode.auto ? filtersBase : undefined}
                idsFilter={mode === ProductListMode.manual ? products : undefined}
                isEmbedded
            />
        );
    }, [props.formProps.values.filters, props.formProps.values.mode, props.formProps.values.products]);

    const renderBadgeDataGrid = useMemo(() => {
        return props.formProps.values.badge ? (
            <ProductListBadgeListDataGrid
                badges={[props.formProps.values.badge]}
                onRemoveClick={() => props.formProps.setFieldValue("badge", null)}
                onRowClick={(id: number) => setEditedModalBadgeId(id)}
            />
        ) : null;
    }, [props.formProps.values.badge]);

    const renderFilters = useMemo(() => {
        return props.formProps.values.mode === ProductListMode.auto ? (
            <Box mt="30px">
                <Typography variant="subtitle1">{I18n.formatMessage({ id: "pages.productList.form.filtersText" })}</Typography>
                <Box mt="30px" />
                {props.formProps.values.filters.map((filter: ProductFilterInput, index: number) => {
                    return (
                        <Grid key={`filter-input-${index}`} container spacing={3}>
                            <Grid item xs={4}>
                                <Field name={`filters.${[index]}.type`}>
                                    {({ field, meta }: FieldProps) => (
                                        <SelectInput
                                            label={I18n.formatMessage({ id: "pages.productList.form.filters.typeLabel" })}
                                            fullWidth
                                            variant="outlined"
                                            options={[
                                                { id: "brand", title: I18n.formatMessage({ id: "enums.productFilterType.brand" }) },
                                                { id: "clpe_promotion_type", title: I18n.formatMessage({ id: "enums.productFilterType.clpe_promotion_type" }) },
                                                { id: "created", title: I18n.formatMessage({ id: "enums.productFilterType.created" }) },
                                                { id: "price_type", title: I18n.formatMessage({ id: "enums.productFilterType.price_type" }) },
                                                { id: "product_category", title: I18n.formatMessage({ id: "enums.productFilterType.product_category" }) },
                                                { id: "product_param", title: I18n.formatMessage({ id: "enums.productFilterType.product_param" }) },
                                                { id: "rating", title: I18n.formatMessage({ id: "enums.productFilterType.rating" }) },
                                                { id: "promotion", title: I18n.formatMessage({ id: "enums.productFilterType.promotion" }) },
                                                { id: "new", title: I18n.formatMessage({ id: "enums.productFilterType.new" }) },
                                                { id: "product_type", title: I18n.formatMessage({ id: "enums.productFilterType.product_type" }) },
                                            ]}
                                            helperText={Form.getHelperText(meta, "")}
                                            {...field}
                                            error={meta.touched && !!meta.error}
                                        />
                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={3}>
                                <Field name={`filters.${[index]}.operator`}>
                                    {({ field, meta }: FieldProps) => (
                                        <SelectInput
                                            label={I18n.formatMessage({ id: "pages.productList.form.filters.operatorLabel" })}
                                            fullWidth
                                            variant="outlined"
                                            options={[
                                                ...ObjectUtils.enumAsArray<ProductFilterOperator>(ProductFilterOperator)
                                                    .filter(getOperatorFilterFunction(filter.type))
                                                    .map((operator: ProductFilterOperator) => {
                                                        return { id: operator, title: I18n.formatMessage({ id: `enums.productFilterOperator.${operator}` }) };
                                                    }),
                                            ]}
                                            helperText={Form.getHelperText(meta, "")}
                                            {...field}
                                            error={meta.touched && !!meta.error}
                                        />
                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={4}>
                                {getFilterValueGridByType(filter.type, index)}
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton
                                    className="table-delete-icon"
                                    onClick={() => {
                                        const newFilters = [...props.formProps.values.filters];
                                        newFilters.splice(index, 1);
                                        props.formProps.setFieldValue("filters", newFilters);
                                    }}
                                >
                                    <Close />
                                </IconButton>
                            </Grid>
                        </Grid>
                    );
                })}
                <Box mt="30px" />
                <Button
                    startIcon={<Icon className="fa fa-plus-circle" />}
                    variant="outlined"
                    size="medium"
                    color="secondary"
                    onClick={() => {
                        props.formProps.setFieldValue("filters", [
                            ...props.formProps.values.filters,
                            { type: ProductFilterType.product_param, operator: ProductFilterOperator.eq },
                        ]);
                    }}
                >
                    {I18n.formatMessage({ id: "pages.productList.form.filters.addFilterButton" })}
                </Button>
            </Box>
        ) : null;
    }, [props.formProps.values.mode, props.formProps.values.filters]);

    const renderPromotionFields = useMemo(() => {
        return props.formProps.values.type === ProductListType.promotion ? (
            <div>
                <PageCard.Heading title={I18n.formatMessage({ id: "common.form.content" })} />

                <Field name="title" validate={I18nHelpers.formatValidator(Validator.required)}>
                    {({ field, meta }: FieldProps) => (
                        <TextField
                            type="text"
                            label={I18n.formatMessage({ id: "pages.productList.form.titleLabel" })}
                            fullWidth
                            variant="outlined"
                            required
                            helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                            error={meta.touched && !!meta.error}
                            {...field}
                        />
                    )}
                </Field>

                <Box mt="30px" />

                <Field name="subtitle">
                    {({ field, meta }: FieldProps) => (
                        <TextField
                            type="text"
                            label={I18n.formatMessage({ id: "pages.productList.form.subtitleLabel" })}
                            fullWidth
                            variant="outlined"
                            required
                            helperText={Form.getHelperText(meta, "")}
                            error={meta.touched && !!meta.error}
                            {...field}
                        />
                    )}
                </Field>

                <Box mt="30px" />

                <Typography color="textSecondary" gutterBottom>
                    {I18n.formatMessage({ id: "pages.productList.form.imageLabel" })}
                </Typography>
                <Box mt="15px" className="image-section-root">
                    <Box className="image-section-image-field">
                        <div className="og_image_url_wrapper">
                            <Field name="image" validate={I18nHelpers.formatValidator(Validator.required)}>
                                {({ field, meta }: FieldProps) => (
                                    <div>
                                        <MediaLibraryImageInput
                                            id="image"
                                            name="image"
                                            value={field.value}
                                            onChange={(value: string | null) => {
                                                props.formProps.setFieldValue("image", value);
                                                props.formProps.setFieldTouched("image", true);
                                            }}
                                        />
                                        <CustomFormHelperText
                                            meta={meta}
                                            defaultHelperText={<div style={{ whiteSpace: "nowrap" }}>{I18n.formatMessage({ id: "pages.productList.form.image.helperText" })}</div>}
                                        />
                                    </div>
                                )}
                            </Field>
                        </div>
                    </Box>
                    <Box className="image-section-alt-field">
                        <Field name="image_title" validate={I18nHelpers.formatValidator(Validator.altTitleMaxLength)}>
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "common.form.altTitleTag" })}
                                    fullWidth
                                    variant="outlined"
                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.maxCharacter" }, { max: Constants.altTitleMaxLength }))}
                                    error={meta.touched && !!meta.error}
                                    inputProps={{
                                        maxLength: Constants.altTitleMaxLength,
                                    }}
                                    {...field}
                                />
                            )}
                        </Field>
                    </Box>
                </Box>

                <Box mt="60px" />

                <Typography color="textSecondary" gutterBottom>
                    {I18n.formatMessage({ id: "pages.productList.form.imageMobileLabel" })}
                </Typography>
                <Box mt="15px" className="image-section-root">
                    <Box className="image-section-image-field">
                        <div className="og_image_url_wrapper">
                            <Field name="image_mobile" validate={I18nHelpers.formatValidator(Validator.required)}>
                                {({ field, meta }: FieldProps) => (
                                    <div>
                                        <MediaLibraryImageInput
                                            id="image_mobile"
                                            name="image_mobile"
                                            value={field.value}
                                            onChange={(value: string | null) => {
                                                props.formProps.setFieldValue("image_mobile", value);
                                                props.formProps.setFieldTouched("image_mobile", true);
                                            }}
                                        />
                                        <CustomFormHelperText
                                            meta={meta}
                                            defaultHelperText={
                                                <div style={{ whiteSpace: "nowrap" }}>{I18n.formatMessage({ id: "pages.productList.form.imageMobile.helperText" })}</div>
                                            }
                                        />
                                    </div>
                                )}
                            </Field>
                        </div>
                    </Box>
                    <Box className="image-section-alt-field">
                        <Field name="image_mobile_title" validate={I18nHelpers.formatValidator(Validator.altTitleMaxLength)}>
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "common.form.altTitleTag" })}
                                    fullWidth
                                    variant="outlined"
                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.maxCharacter" }, { max: Constants.altTitleMaxLength }))}
                                    error={meta.touched && !!meta.error}
                                    inputProps={{
                                        maxLength: Constants.altTitleMaxLength,
                                    }}
                                    {...field}
                                />
                            )}
                        </Field>
                    </Box>
                </Box>
            </div>
        ) : null;
    }, [props.formProps.values.type]);

    return (
        <div>
            <Form formProps={props.formProps}>
                <PageCard.Container>
                    <PageCard.Heading title={I18n.formatMessage({ id: "common.form.basicData" })} />

                    <Box mt="30px" />
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Typography color="textSecondary" gutterBottom>
                                {I18n.formatMessage({ id: "pages.productList.form.typeLabel" })}
                            </Typography>
                            <Typography variant="subtitle1">{I18n.formatMessage({ id: `enums.productListType.${props.formProps.values.type}` })}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            {props.formType === FormType.edit && (
                                <div>
                                    <Typography color="textSecondary" gutterBottom>
                                        {I18n.formatMessage({ id: "pages.productList.form.idLabel" })}
                                    </Typography>
                                    <Typography variant="subtitle1">{props.productList?.id ?? ""}</Typography>
                                </div>
                            )}
                        </Grid>
                    </Grid>

                    <Box mt="30px" />

                    <Field name="name" validate={I18nHelpers.formatValidator(Validator.required)}>
                        {({ field, meta }: FieldProps) => (
                            <TextField
                                type="text"
                                label={I18n.formatMessage({ id: "pages.productList.form.nameLabel" })}
                                fullWidth
                                variant="outlined"
                                required
                                helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                                error={meta.touched && !!meta.error}
                                {...field}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const slug = Helpers.updateSlug(field.value, e.currentTarget.value, "");
                                    props.formProps.setFieldValue("slug", slug);
                                    props.formProps.setFieldTouched("slug", true);
                                    field.onChange(e);
                                }}
                            />
                        )}
                    </Field>

                    <Box mt="30px" />

                    <Field name="title" validate={I18nHelpers.formatValidator(Validator.required)}>
                        {({ field, meta }: FieldProps) => (
                            <TextField
                                type="text"
                                label={I18n.formatMessage({ id: "pages.productList.form.titleLabel" })}
                                fullWidth
                                variant="outlined"
                                required
                                helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                                error={meta.touched && !!meta.error}
                                {...field}
                            />
                        )}
                    </Field>

                    <Box mt="30px" />

                    <Field name="slug" validate={I18nHelpers.formatValidator(Validator.required)}>
                        {({ field, meta }: FieldProps) => (
                            <TextField
                                type="text"
                                label={I18n.formatMessage({ id: "pages.productList.form.slugLabel" })}
                                fullWidth
                                variant="outlined"
                                required
                                helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                                error={meta.touched && !!meta.error}
                                {...field}
                                disabled={props.formType === FormType.edit}
                            />
                        )}
                    </Field>

                    <Box mt="30px" />

                    <PageCard.Heading title={I18n.formatMessage({ id: "common.form.settings" })} />

                    <Typography variant="body1" component="h3" color="textSecondary">
                        {I18n.formatMessage({ id: "pages.productList.form.statusLabel" })}
                    </Typography>

                    <Field name="is_active">
                        {({ field, meta }: FieldProps) =>
                            field.value !== undefined && (
                                <Box mt="15px" ml="10px">
                                    <FormControl error={meta.touched && !!meta.error}>
                                        <FormControlLabel
                                            control={<Checkbox {...field} checked={field.value} />}
                                            label={I18n.formatMessage({ id: "pages.productList.form.isActiveLabel" })}
                                        />
                                    </FormControl>
                                    <CustomFormHelperText meta={meta} />
                                </Box>
                            )
                        }
                    </Field>

                    <Box mt="30px" />

                    <Grid container>
                        <Grid item>
                            <Box mr="30px">
                                <Field name="active_from">
                                    {({ field, meta }: FieldProps) => (
                                        <TextField
                                            id="active_from"
                                            label={I18n.formatMessage({ id: "pages.productList.form.activeFromLabel" })}
                                            type="datetime-local"
                                            className={`datetime-local ${props.formProps.values.active_from ? "datetime-local-filled" : ""}`}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                endAdornment: props.formProps.values.active_from ? (
                                                    <InputAdornment
                                                        position="end"
                                                        onClick={() => {
                                                            props.formProps.setFieldValue("active_from", "");
                                                            props.formProps.setFieldTouched("active_from", true);
                                                        }}
                                                    >
                                                        <EventBusy />
                                                    </InputAdornment>
                                                ) : null,
                                            }}
                                            {...field}
                                            value={field.value}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                props.formProps.setTouched({ ...props.formProps.touched, active_from: true });
                                                field.onChange(e);
                                            }}
                                            error={meta.touched && !!meta.error}
                                            helperText={Form.getHelperText(meta, "")}
                                        />
                                    )}
                                </Field>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Field name="active_to">
                                {({ field, meta }: FieldProps) => (
                                    <TextField
                                        id="active_to"
                                        label={I18n.formatMessage({ id: "pages.productList.form.activeToLabel" })}
                                        className={`datetime-local ${props.formProps.values.active_to ? "datetime-local-filled" : ""}`}
                                        type="datetime-local"
                                        placeholder=""
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            endAdornment: props.formProps.values.active_to ? (
                                                <InputAdornment
                                                    position="end"
                                                    onClick={() => {
                                                        props.formProps.setFieldValue("active_to", "");
                                                        props.formProps.setFieldTouched("active_to", true);
                                                    }}
                                                >
                                                    <EventBusy />
                                                </InputAdornment>
                                            ) : null,
                                        }}
                                        {...field}
                                        value={field.value}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            props.formProps.setTouched({ ...props.formProps.touched, active_to: true });
                                            field.onChange(e);
                                        }}
                                        error={meta.touched && !!meta.error}
                                        helperText={Form.getHelperText(meta, "")}
                                    />
                                )}
                            </Field>
                        </Grid>
                    </Grid>

                    <Box mt="30px" />

                    {renderPromotionFields}

                    <Box mt={`${props.formProps.values.type === ProductListType.promotion ? "60px" : "30px"}`} />

                    <PageCard.Heading title={I18n.formatMessage({ id: "pages.productList.form.addProductsHeading" })} />

                    <Field name="group_by_category">
                        {({ field, meta }: FieldProps) =>
                            field.value !== undefined && (
                                <Box mt="15px" ml="10px">
                                    <FormControl error={meta.touched && !!meta.error}>
                                        <FormControlLabel
                                            control={<Checkbox {...field} checked={field.value} />}
                                            label={I18n.formatMessage({ id: "pages.productList.form.groupByCategoryLabel" })}
                                        />
                                    </FormControl>
                                    <CustomFormHelperText meta={meta} />
                                </Box>
                            )
                        }
                    </Field>

                    <Box mt="30px" />

                    <Box ml="10px">
                        <Grid container spacing={3}>
                            <Grid item xs={2}>
                                <FormControlLabel
                                    checked={props.formProps.values.mode === ProductListMode.manual}
                                    onChange={() => {
                                        props.formProps.setFieldValue("mode", ProductListMode.manual);
                                    }}
                                    control={<Radio />}
                                    label={I18n.formatMessage({ id: "pages.productList.form.modeLabel.manual" })}
                                />
                            </Grid>
                            <Grid item xs={10}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => setIsReorderModalOpen(true)}
                                    disabled={props.formProps.values.mode !== ProductListMode.manual || props.formProps.values.products.length === 0}
                                >
                                    {I18n.formatMessage({ id: "pages.productList.form.reorderButtonLabel" })}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box mt="24px" mb="24px">
                        <Typography variant="subtitle1">{I18n.formatMessage({ id: "pages.productList.form.addProductHint" })}</Typography>
                    </Box>

                    <Button
                        startIcon={<Icon className="fa fa-plus-circle" />}
                        variant="outlined"
                        size="medium"
                        color="secondary"
                        onClick={() => setIsProductAddDialogVisible(true)}
                        disabled={props.formProps.values.mode === ProductListMode.auto}
                    >
                        {I18n.formatMessage({ id: "pages.productList.form.addProductButton" })}
                    </Button>

                    <Box mt="30px" />

                    <Box ml="10px">
                        <FormControlLabel
                            checked={props.formProps.values.mode === ProductListMode.auto}
                            onChange={() => {
                                props.formProps.setFieldValue("mode", ProductListMode.auto);
                            }}
                            control={<Radio />}
                            label={I18n.formatMessage({ id: "pages.productList.form.modeLabel.auto" })}
                        />
                    </Box>

                    {renderFilters}

                    <Box mt="30px" mb="30px" />

                    {renderProductCatalogDataGrid}

                    <Box mt="30px" />

                    <PageCard.Heading title={I18n.formatMessage({ id: "pages.productList.form.badgeHeading" })} />

                    <Box mt="30px" />

                    <Button
                        startIcon={<Icon className="fa fa-plus-circle" />}
                        variant="outlined"
                        size="medium"
                        color="secondary"
                        onClick={() => setIsBadgeAddDialogVisible(true)}
                        disabled={!!props.formProps.values.badge}
                    >
                        {I18n.formatMessage({ id: "pages.productList.form.addBadgeButton" })}
                    </Button>

                    <Box mt="30px" />

                    {renderBadgeDataGrid}

                    <Box mt="30px" />

                    <PageCard.Heading title={I18n.formatMessage({ id: "common.form.metaData" })} />
                    <Typography variant="body1" component="h3" color="textSecondary">
                        {I18n.formatMessage({ id: "pages.content.form.ogImage" })}
                    </Typography>
                    <Box className="image-section-root">
                        <Box className="image-section-image-field">
                            <div className="og_image_url_wrapper">
                                <Field key={"og_image"} name={"og_image"}>
                                    {({ field, meta }: FieldProps) => (
                                        <div>
                                            <MediaLibraryImageInput
                                                id="product-category-og_image_url"
                                                name="og_image"
                                                value={field.value}
                                                onChange={(value: string | null) => {
                                                    props.formProps.setFieldValue("og_image", value);
                                                    props.formProps.setFieldTouched("og_image", true);
                                                }}
                                            />
                                            <CustomFormHelperText meta={meta} />
                                        </div>
                                    )}
                                </Field>
                            </div>
                        </Box>
                        <Box className="image-section-title-tag-field">
                            <Field key={I18n.formatMessage({ id: "common.form.ogTitle" })} name="og_title" validate={I18nHelpers.formatValidator(Validator.ogTitleMaxLength)}>
                                {({ field, meta }: FieldProps) => (
                                    <TextField
                                        type="text"
                                        label={I18n.formatMessage({ id: "common.form.ogTitle" })}
                                        fullWidth
                                        variant="outlined"
                                        error={meta.touched && !!meta.error}
                                        helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.maxCharacter" }, { max: Constants.ogTitleMaxLength }))}
                                        inputProps={{
                                            maxLength: Constants.ogTitleMaxLength,
                                        }}
                                        {...field}
                                    />
                                )}
                            </Field>
                        </Box>
                        <Box className="image-section-og-description-field">
                            <Field
                                key={I18n.formatMessage({ id: "common.form.ogDescription" })}
                                name="og_description"
                                validate={I18nHelpers.formatValidator(Validator.ogDescriptionMaxLength)}
                            >
                                {({ field, meta }: FieldProps) => (
                                    <TextField
                                        type="text"
                                        label={I18n.formatMessage({ id: "common.form.ogDescription" })}
                                        fullWidth
                                        variant="outlined"
                                        helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.maxCharacter" }, { max: Constants.ogDescriptionMaxLength }))}
                                        error={meta.touched && !!meta.error}
                                        inputProps={{
                                            maxLength: Constants.ogDescriptionMaxLength,
                                        }}
                                        {...field}
                                    />
                                )}
                            </Field>
                        </Box>
                        <Box className="image-section-meta-description-field">
                            <Field
                                key={I18n.formatMessage({ id: "common.form.metaDescription" })}
                                name="meta_description"
                                validate={I18nHelpers.formatValidator(Validator.metaDescriptionMaxLength)}
                            >
                                {({ field, meta }: FieldProps) => (
                                    <TextField
                                        type="text"
                                        label={I18n.formatMessage({ id: "common.form.metaDescription" })}
                                        fullWidth
                                        multiline
                                        variant="outlined"
                                        helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.maxCharacter" }, { max: Constants.metaDescriptionMaxLength }))}
                                        error={meta.touched && !!meta.error}
                                        inputProps={{
                                            maxLength: Constants.metaDescriptionMaxLength,
                                        }}
                                        {...field}
                                    />
                                )}
                            </Field>
                        </Box>
                    </Box>
                </PageCard.Container>

                {props.formType === FormType.edit && (
                    <Box mt="30px">
                        <PageCard.Container>
                            <PageCard.Heading title={I18n.formatMessage({ id: "common.statistics.title" })} />
                            <Box mt="30px" mb="30px">
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <Typography color="textSecondary" gutterBottom>
                                            {I18n.formatMessage({ id: "common.statistics.created_at" })}
                                        </Typography>
                                        <Typography variant="body2">{DateUtils.format(props.productList?.created_at, DateFormat.minuteDateTime)}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography color="textSecondary" gutterBottom>
                                            {I18n.formatMessage({ id: "common.statistics.created_by" })}
                                        </Typography>
                                        <Typography variant="body2">{props.productList?.created_by?.name ?? ""}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <Typography color="textSecondary" gutterBottom>
                                            {I18n.formatMessage({ id: "common.statistics.updated_at" })}
                                        </Typography>
                                        <Typography variant="body2">{DateUtils.format(props.productList?.updated_at, DateFormat.minuteDateTime)}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography color="textSecondary" gutterBottom>
                                            {I18n.formatMessage({ id: "common.statistics.updated_by" })}
                                        </Typography>
                                        <Typography variant="body2">{props.productList?.updated_by?.name ?? ""}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </PageCard.Container>
                    </Box>
                )}

                <SavePanel>
                    <Grid container justify="space-between">
                        <Button variant="outlined" color="primary" onClick={onCancelClick}>
                            {I18n.formatMessage({ id: "common.cancel" })}
                        </Button>
                        <Button
                            type="submit"
                            startIcon={<Icon className="fas fa-save" />}
                            variant="contained"
                            color="secondary"
                            disabled={!props.formProps.dirty || !props.formProps.isValid}
                        >
                            {I18n.formatMessage({ id: `common.${props.formType === FormType.create ? "create" : "save"}` })}
                        </Button>
                    </Grid>
                </SavePanel>
                <FullscreenLoader visible={props.formProps.isSubmitting} />
                {isProductAddDialogVisible && (
                    <AddProductDialog
                        description={I18n.formatMessage({ id: "pages.productList.form.addProductDialog.description" })}
                        initialSelectedProductIds={props.formProps.values.products}
                        onAddClick={handleAddProductsClick}
                        onClose={() => {
                            setIsProductAddDialogVisible(false);
                            history.replace({ pathname: history.location.pathname });
                        }}
                    />
                )}
                {isBadgeAddDialogVisible && (
                    <AddBadgeDialog
                        initialSelectedBadges={props.formProps.values.badge ? [props.formProps.values.badge] : []}
                        onAddClick={handleAddBadgesClick}
                        onClose={() => setIsBadgeAddDialogVisible(false)}
                        isSingleSelect
                    />
                )}
            </Form>
            {editedModalBadgeId && (
                <ModalBadgeEditPageDialog title={null} open onClose={() => setEditedModalBadgeId(null)}>
                    <ModalBadgeEditPage id={editedModalBadgeId} onModalClose={() => setEditedModalBadgeId(null)} onUpdate={onModalBadgeUpdate} />
                </ModalBadgeEditPageDialog>
            )}
            {isReorderModalOpen && (
                <ReorderModal
                    productIds={props.formProps.values.products}
                    onConfirmClick={(products: number[]) => {
                        props.formProps.setFieldValue("products", products);
                        props.formProps.setFieldTouched("products", true);
                        setIsReorderModalOpen(false);
                    }}
                    onClose={() => setIsReorderModalOpen(false)}
                />
            )}
        </div>
    );
};
