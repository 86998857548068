/* eslint-disable @typescript-eslint/no-explicit-any */
import { ValidatorMessage } from "Utils/Validator";
import { I18n } from "./I18n";

export class I18nHelpers {
    public static formatEnum<EnumValue>(enumName: string, value: EnumValue): string {
        return I18n.formatMessage({ id: `enums.${enumName}.${value}` });
    }

    public static formatValidator(validatorFunction: (...params: any[]) => ValidatorMessage | undefined) {
        return (...params: any[]): string | undefined => {
            const result = validatorFunction(...params);
            if (result) {
                return I18nHelpers.formatEnum("validatorMessage", result);
            }
            return undefined;
        };
    }
}
