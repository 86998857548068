import React from "react";
import { Route, Redirect } from "react-router-dom";
import { RouteProps, RouteComponentProps } from "react-router";
import { connect, DispatchProp } from "react-redux";
import { Path } from "Utils/Path";
import { AuthSelectors } from "Redux/selectors/authSelectors";
import { ApplicationState } from "Redux/rootReducer";
import { PermissionType } from "Api/graphql/auth/types";

type ReduxProps = {
    isLoggedIn: boolean;
    permissions: PermissionType[];
    isSuperadmin: boolean;
};

type ComponentProps = {
    requiredPermissions?: PermissionType[];
} & RouteProps;

type Props = ComponentProps & ReduxProps & DispatchProp;

class PrivateRoute extends React.Component<Props> {
    public render() {
        const { component: Component, ...rest } = this.props;
        return (
            <Route
                {...rest}
                render={(props: RouteComponentProps): React.ReactNode | null => {
                    if (!this.props.isLoggedIn) {
                        return <Redirect to={{ pathname: Path.login, state: { from: { pathname: props.location } } }} />;
                    } else if (!this.props.isSuperadmin && this.props.requiredPermissions?.some((permission: PermissionType) => !this.props.permissions.includes(permission))) {
                        return <Redirect to={Path.dashboard} />;
                    } else {
                        if (Component) {
                            return <Component {...props} />;
                        } else if (this.props.render) {
                            return this.props.render(props);
                        }
                        return null;
                    }
                }}
            />
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return {
        isLoggedIn: AuthSelectors.isLoggedIn(state.auth),
        permissions: AuthSelectors.getPermissions(state.auth),
        isSuperadmin: AuthSelectors.isSuperadmin(state.auth),
    };
};

export default connect(mapStateToProps)(PrivateRoute);
