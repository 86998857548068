import { gql } from "@apollo/client";

export const fulfillmentListItemFragment = gql`
    fragment FulfillmentListItem on Fulfillment {
        id
        active_from
        active_to
        date_reset_next
        limit
        count
        shipping_methods
        timewindow_allowed
        vip_levels
        created_at
        updated_at
        created_by {
            id
            name
        }
        updated_by {
            id
            name
        }
        is_active
        weight
    }
`;
