import { gql } from "@apollo/client";

export const productBrandListItemFragment = gql`
    fragment ProductBrandListItem on ProductBrand {
        id
        name
        slug
        is_logo
        is_public
        is_rossmann
        created_at
        updated_at
    }
`;
