import { Api } from "Api/Api";
import { GalleryListItem, GalleryImage } from "Api/graphql/admin/types";
import React from "react";
import CKGalleryModal from "./CKGalleryModal";
import CKGalleryListModal from "./CKGalleryListModal";
import { withSnackbar, WithSnackbarProps } from "notistack";

export enum ModalContent {
    list = "list",
    create = "create",
    edit = "edit",
}

type ComponentProps = {
    isVisible: boolean;
    onClose: () => void;
    onGallerySelected: (galleryImages: GalleryImage[]) => void;
};

type Props = ComponentProps & WithSnackbarProps;

type State = {
    selectedGallery: GalleryListItem | null;
    modalContent: ModalContent;
};

class CKGalleryInsertModal extends React.Component<Props, State> {
    public readonly state: State = {
        selectedGallery: null,
        modalContent: ModalContent.list,
    };

    public componentDidUpdate(prevProps: Props) {
        if (prevProps.isVisible && !this.props.isVisible) {
            this.setState({ selectedGallery: null, modalContent: ModalContent.list });
        }
    }

    private onAddGalleryClick = (): void => {
        this.setState({ modalContent: ModalContent.create });
    };

    private onGallerySelected = (selectedGallery: GalleryListItem): void => {
        this.setState({ selectedGallery });
    };

    private onGalleryEditClick = (selectedGallery: GalleryListItem): void => {
        this.setState({ selectedGallery, modalContent: ModalContent.edit });
    };

    private onGalleryInsertClick = async (): Promise<void> => {
        try {
            const images = await Api.getGalleryImages(this.state.selectedGallery!.id);
            this.props.onGallerySelected(images);
        } catch (error) {
            this.props.enqueueSnackbar(error.message, { variant: "error" });
        }
    };

    public render() {
        if (this.state.modalContent === ModalContent.list) {
            return (
                <CKGalleryListModal
                    isVisible={this.props.isVisible}
                    onClose={this.props.onClose}
                    selectedGallery={this.state.selectedGallery}
                    onGalleryInsertClick={this.onGalleryInsertClick}
                    onGallerySelected={this.onGallerySelected}
                    onGalleryAddClick={this.onAddGalleryClick}
                    onGalleryEditClick={this.onGalleryEditClick}
                />
            );
        }

        return (
            <CKGalleryModal
                onClose={() => {
                    this.setState({ selectedGallery: null, modalContent: ModalContent.list });
                }}
                onSucceed={(gallery: GalleryListItem) => {
                    this.setState({ selectedGallery: gallery, modalContent: ModalContent.list });
                }}
                type={this.state.modalContent}
                galleryId={this.state.selectedGallery?.id}
            />
        );
    }
}

export default withSnackbar(CKGalleryInsertModal);
