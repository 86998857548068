import { gql } from "@apollo/client";

export const websiteNotificationFragment = gql`
    fragment WebsiteNotification on WebsiteNotification {
        id
        name
        title
        message
        is_active
        active_from
        active_to
        created_at
        created_by {
            name
        }
        updated_at
        updated_by {
            name
        }
        target
    }
`;
