import React from "react";

import { withSnackbar, WithSnackbarProps } from "notistack";
import styled from "styled-components";

import { ColoredAvatar, DefaultModal } from "@bigfish/admin-ui/components";
import { Grid, Typography } from "@bigfish/admin-ui/core";
import { TypeColumn, TypeRowProps } from "@inovua/reactdatagrid-community/types";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";

import { LogActionType, LogFilters, LogSort, LogSortField, ModelType, ModelUpdateLog } from "Api/graphql/admin/types";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import DataGrid from "Components/DataGrid/DataGrid";
import { DataGridUrlQueryParser, QueryParserType } from "Components/DataGrid/DataGridUrlQueryParser";
import { DataSource, FilterValue } from "Components/DataGrid/DataGridUtils";
import { DataGridParams } from "Components/DataGrid/UrlQueryParser";
import { I18n } from "I18n/I18n";
import { Constants } from "Utils/Constants";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import { StringUtils } from "Utils/StringUtils";
import { ObjectUtils } from "Utils/ObjectUtils";

type LogListProps = {
    incrementId?: number | null;
    parentId?: number;
};

type Props = WithSnackbarProps & LogListProps;

type State = {
    changed: string;
    isChangesDialogVisible: boolean;
};

class LogListDataGrid extends React.Component<Props, State> {
    public readonly state: State = {
        changed: "",
        isChangesDialogVisible: false,
    };

    private getColumns = (): TypeColumn[] => {
        return [
            { name: "model_id", header: I18n.formatMessage({ id: "pages.logList.grid.column.modelId" }), sortable: false },
            {
                name: "action",
                header: I18n.formatMessage({ id: "pages.logList.grid.column.action" }),
                sortable: false,
                defaultWidth: 200,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: [
                        ...ObjectUtils.enumAsArray<LogActionType>(LogActionType).map((type: LogActionType) => {
                            return { id: type, label: I18n.formatMessage({ id: `enums.logActionType.${type}` }) };
                        }),
                    ],
                },
            },

            {
                name: "parent_type",
                header: I18n.formatMessage({ id: "pages.logList.grid.column.parentType" }),
                sortable: false,
                defaultWidth: 200,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: [
                        ...ObjectUtils.enumAsArray<ModelType>(ModelType).map((type: ModelType) => {
                            return { id: type, label: I18n.formatMessage({ id: `enums.modelType.${type}` }) };
                        }),
                    ],
                },
            },
            {
                name: "ean",
                header: I18n.formatMessage({ id: "pages.logList.grid.column.ean" }),
            },
            {
                name: "increment_id",
                header: I18n.formatMessage({ id: "pages.logList.grid.column.incrementId" }),
            },
            {
                name: "object_type",
                header: I18n.formatMessage({ id: "pages.logList.grid.column.objectType" }),
                sortable: false,
                defaultWidth: 200,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: [
                        ...ObjectUtils.enumAsArray<ModelType>(ModelType).map((type: ModelType) => {
                            return { id: type, label: I18n.formatMessage({ id: `enums.modelType.${type}` }) };
                        }),
                    ],
                },
            },
            {
                name: "timestamp",
                filterEditor: DateFilter,
                header: I18n.formatMessage({ id: "pages.logList.grid.column.timeStamp" }),
                defaultWidth: Constants.columnWidth.dateTime,
                render: ({ data }: { data: ModelUpdateLog }) => {
                    return <div>{data.timestamp ? DateUtils.format(data.timestamp, DateFormat.minuteDateTime) : ""}</div>;
                },
            },
            {
                name: "admin_id",
                header: I18n.formatMessage({ id: "pages.logList.grid.column.adminName" }),
                render: ({ data }: { data: ModelUpdateLog }) => {
                    return (
                        <Grid container direction="row" alignItems="center" alignContent="center" spacing={3}>
                            {data.admin_name && (
                                <>
                                    <Grid item>
                                        <ColoredAvatar identifier={data.admin_id ?? 0} sizes="20 20">
                                            {StringUtils.getNameInitials(data.admin_name)}
                                        </ColoredAvatar>
                                    </Grid>
                                    <Grid item>{data.admin_name}</Grid>
                                </>
                            )}
                        </Grid>
                    );
                },
                defaultFlex: 1,
                minWidth: 300,
                sortable: false,
            },
        ];
    };

    private filterValue: FilterValue[] = [
        { name: "model_id", operator: "eq", type: "number" },
        { name: "action", operator: "eq", type: "select" },
        { name: "parent_type", operator: "eq", type: "select" },
        { name: "ean", operator: "contains", type: "string" },
        { name: "increment_id", operator: "eq", type: "number" },
        { name: "object_type", operator: "eq", type: "select" },
        { name: "admin_id", operator: "eq", type: "number" },
        { name: "timestamp", operator: "inrange", type: "date" },
    ];

    private urlQueryParser = new DataGridUrlQueryParser({
        filters: {
            model_id: QueryParserType.integer,
            action: QueryParserType.enum,
            parent_type: QueryParserType.enum,
            ean: QueryParserType.string,
            increment_id: QueryParserType.integer,
            object_type: QueryParserType.enum,
            admin_id: QueryParserType.integer,
            timestamp: QueryParserType.dateRange,
        },
        sortField: LogSortField,
    });

    private sortFieldToSortOrder = {
        [LogSortField.timestamp]: "timestamp",
    };

    private dataSource = async (props: DataGridParams<LogSort, LogFilters>): Promise<DataSource<ModelUpdateLog>> => {
        try {
            const result = await Api.listLogs({
                first: props.limit,
                page: props.page,
                filters: { ...props.filters, increment_id: this.props.incrementId ?? props.filters?.increment_id, parent_id: this.props.parentId },
                sortBy: props.sortBy,
            });
            return { data: result.data, count: result.paginatorInfo.total };
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }
        return { data: [], count: 0 };
    };

    private toggleChangesDialogVisibility = () => {
        this.setState({ isChangesDialogVisible: !this.state.isChangesDialogVisible });
    };

    private onRowClick = (rowProps: TypeRowProps) => {
        this.toggleChangesDialogVisibility();
        this.setState({ changed: rowProps.data.changed });
    };

    private formatChanges = () => {
        if (this.state.changed.length !== 0) {
            const changes = Object.entries(JSON.parse(JSON.parse(this.state.changed)));
            return (
                <Typography>
                    {changes
                        .filter(change => change[1] !== null)
                        .map((change, i) => (
                            <div key={i}>
                                <Grid container alignItems="flex-start">
                                    <Grid item xs={4}>
                                        <Typography color="textSecondary" gutterBottom>
                                            {`${change[0]}`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        {typeof change[1] === "object" ? (
                                            <>
                                                {Object.entries(change[1] as object).map((item, i) => (
                                                    <Typography key={i} gutterBottom>
                                                        {`${item[0]}: ${item[1]}`}
                                                    </Typography>
                                                ))}
                                            </>
                                        ) : (
                                            `${change[1]}`
                                        )}
                                    </Grid>
                                </Grid>
                                <br />
                            </div>
                        ))}
                </Typography>
            );
        }
        return;
    };

    render() {
        return (
            <>
                <DataGrid
                    urlQueryParser={this.urlQueryParser}
                    rowHeight={50}
                    onRowClick={this.onRowClick}
                    style={{ minHeight: 800 }}
                    dataSource={this.dataSource}
                    filterValues={this.filterValue}
                    columns={this.getColumns()}
                    sortFieldToSortOrder={this.sortFieldToSortOrder}
                    activeCell={null}
                />
                <DefaultModal title={I18n.formatMessage({ id: "pages.logList.modalTitle" })} open={this.state.isChangesDialogVisible} onClose={this.toggleChangesDialogVisibility}>
                    <ChangesContainer>{this.formatChanges()}</ChangesContainer>
                </DefaultModal>
            </>
        );
    }
}

export default withSnackbar(LogListDataGrid);

const ChangesContainer = styled.div`
    width: 100%;
    height: 100%;
    max-height: 400px;
    overflow: auto;
`;
