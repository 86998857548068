import { gql } from "@apollo/client";

export const giftCardPaymentFragment = gql`
    fragment GiftCardPayment on GiftCardPayment {
        barcode
        amount
        voided_amount
        paid_at
        voided_at
    }
`;
