import { gql } from "@apollo/client";

export const userOrderItemFragment = gql`
    fragment UserOrderItem on OrderSimple {
        id
        created_at
        grand_total
        shipping_method_name
        payment_method_name
        payment_status_title
    }
`;
