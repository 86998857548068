import React from "react";
import { Formik, FormikHelpers } from "formik";
import { ChangePasswordForm, ChangePasswordFormValues, changePasswordValidator } from "./ChangePasswordForm";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Form } from "Components/Form";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { I18n } from "I18n/I18n";
import { FormModal } from "Components/FormModal";

type ComponentProps = {
    disabled?: boolean;
    isVisible: boolean;
    onClose: () => void;
};

type Props = ComponentProps & WithSnackbarProps;

class ChangePasswordModal extends React.Component<Props> {
    private onSubmit = async (values: ChangePasswordFormValues, formikHelpers: FormikHelpers<ChangePasswordFormValues>): Promise<void> => {
        try {
            await Api.updateAdminMe({ password: values.password, password_new: values.passwordNew, password_new_confirmation: values.passwordNewConfirmation });
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.myProfile.changePasswordModal.saveSucceed" }), { variant: "success" });
            this.props.onClose();
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.myProfile.changePasswordModal.saveFailed" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
        }
    };

    public render() {
        const initialValues: ChangePasswordFormValues = {
            password: "",
            passwordNew: "",
            passwordNewConfirmation: "",
        };

        return (
            <FormModal title={I18n.formatMessage({ id: "pages.myProfile.changePasswordModal.title" })} onClose={this.props.onClose} open={this.props.isVisible}>
                <Formik initialValues={initialValues} onSubmit={this.onSubmit} validate={changePasswordValidator} validateOnBlur={false}>
                    {props => <ChangePasswordForm formProps={props} onCancelClick={this.props.onClose} />}
                </Formik>
            </FormModal>
        );
    }
}

export default withSnackbar(ChangePasswordModal);
