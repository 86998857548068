import { gql } from "@apollo/client";

export const productCategoryBannerFragment = gql`
    fragment ProductCategoryBanner on ProductCategoryBanner {
        is_active
        active_from
        active_to
        image
        image_title
        link
        link_new_tab
    }
`;
