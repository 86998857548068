import React, { useCallback } from "react";
import { Form, FormType } from "Components/Form";
import { Button, Grid, Icon, TextField, Box, Typography } from "@bigfish/admin-ui/core";
import { FastField, Field, FieldProps, FormikProps } from "formik";
import { Validator } from "Utils/Validator";
import { I18n } from "Src/i18n/I18n";
import { I18nHelpers } from "I18n/I18nHelpers";
import { SavePanel } from "@bigfish/admin-ui/components";
import { Path } from "Utils/Path";
import { useHistory } from "react-router-dom";
import MediaLibraryImageInput from "Components/Inputs/MediaLibraryImageInput";

export type BenefitFormValues = {
    title: string;
    image: string;
};

type Props = {
    formType: FormType;
    formProps: FormikProps<BenefitFormValues>;
};

export const BenefitForm = (props: Props) => {
    const history = useHistory();
    const onCancelClick = useCallback(() => {
        history.push(Path.benefitList);
    }, [history]);

    return (
        <Form formProps={props.formProps}>
            <Field name="title" validate={I18nHelpers.formatValidator(Validator.benefitName)}>
                {({ field, meta }: FieldProps) => (
                    <TextField
                        type="text"
                        label={I18n.formatMessage({ id: "pages.benefit.form.title.label" })}
                        fullWidth
                        variant="outlined"
                        required
                        helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                        error={meta.touched && !!meta.error}
                        {...field}
                    />
                )}
            </Field>
            <Box mt="30px">
                <Typography variant="body1" component="h3" color="textSecondary">
                    {I18n.formatMessage({ id: "pages.benefit.form.image.label" })}
                </Typography>
            </Box>
            <Box mt="15px">
                <FastField name="image" validate={I18nHelpers.formatValidator(Validator.required)}>
                    {({ field, meta }: FieldProps) => (
                        <MediaLibraryImageInput
                            id="article-image"
                            name="image"
                            value={field.value}
                            onChange={(value: string | null) => {
                                props.formProps.setFieldValue("image", value);
                            }}
                            error={meta.touched && !!meta.error}
                            helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                        />
                    )}
                </FastField>
            </Box>
            <Box my="30px" />

            <SavePanel>
                <Grid container justify="space-between">
                    <Button variant="outlined" color="primary" onClick={onCancelClick}>
                        {I18n.formatMessage({ id: "common.cancel" })}
                    </Button>
                    <Button
                        type="submit"
                        startIcon={<Icon className="fas fa-save" />}
                        variant="contained"
                        color="secondary"
                        disabled={!props.formProps.dirty || props.formProps.isSubmitting}
                    >
                        {I18n.formatMessage({ id: "common.save" })}
                    </Button>
                </Grid>
            </SavePanel>
        </Form>
    );
};
