import { gql } from "@apollo/client";
import { productCategoryForMenuFieldsFragment } from "./productCategoryForMenuFieldsFragment";

export const childProductCategoryForMenuFieldsFragment = gql`
    fragment ChildProductCategoryForMenuFields on ProductCategorySimple {
        children {
            ...ProductCategoryForMenuFields
            children {
                ...ProductCategoryForMenuFields
                children {
                    ...ProductCategoryForMenuFields
                    children {
                        ...ProductCategoryForMenuFields
                        children {
                            ...ProductCategoryForMenuFields
                        }
                    }
                }
            }
        }
    }
    ${productCategoryForMenuFieldsFragment}
`;
