import React, { useCallback } from "react";
import { Form, FormType } from "Components/Form";
import { Button, Grid, Icon, TextField, Box, FormControl, Typography, makeStyles, Checkbox, FormControlLabel, InputLabel, MenuItem, Select } from "@bigfish/admin-ui/core";
import { Field, FieldProps, FormikErrors, FormikProps } from "formik";
import { Validator } from "Utils/Validator";
import { I18n } from "Src/i18n/I18n";
import { I18nHelpers } from "I18n/I18nHelpers";
import { SavePanel } from "@bigfish/admin-ui/components";
import { Path } from "Utils/Path";
import { useHistory } from "react-router-dom";
import FetchedSelect from "Components/FetchedSelect/FetchedSelect";
import { Api } from "Api/Api";
import { useSnackbar } from "notistack";
import { Site } from "Api/graphql/admin/types";
import { RedirectionType } from "Pages/RedirectionList/RedirectionListPage";
import { ApiError } from "Api/ApiError";

export type RedirectionFormValues = {
    from_site_id: string | null;
    from: string;
    to_site_id: string | null;
    to: string;
    is_regex: boolean;
    forward: boolean | null;
    is_permanent: "true" | "false";
    active_from: string;
    active_to: string;
};

type Props = {
    formType: FormType;
    formProps: FormikProps<RedirectionFormValues>;
    redirectionType: RedirectionType;
};

export const redirectionValidator = (redirectionType: RedirectionType) => (values: RedirectionFormValues): FormikErrors<RedirectionFormValues> => {
    const errors: { [key in keyof RedirectionFormValues]?: any } = {};

    if (redirectionType === RedirectionType.internal.toLowerCase()) {
        errors.from_site_id = I18nHelpers.formatValidator(Validator.required)(values.from_site_id);
        errors.to = I18nHelpers.formatValidator(Validator.redirectionToMustBeRelative)(values.to, false);
    }

    if (redirectionType === RedirectionType.external.toLowerCase()) {
        errors.to = I18nHelpers.formatValidator(Validator.redirectionToMustBeAbsolute)(values.to);
    }

    if (redirectionType === RedirectionType.siteToSite.toLowerCase()) {
        errors.to_site_id = I18nHelpers.formatValidator(Validator.required)(values.to_site_id);
        errors.from = I18nHelpers.formatValidator(Validator.redirectionToMustBeRelative)(values.from);
        errors.to = I18nHelpers.formatValidator(Validator.redirectionToMustBeRelative)(values.to);
    }

    return Form.cleanupFormikErrors(errors);
};

export const RedirectionForm = (props: Props) => {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const onCancelClick = useCallback(() => {
        history.push(Path.redirectionList);
    }, [history]);
    const classes = useStyles();

    return (
        <Form formProps={props.formProps}>
            <Field name="from_site_id">
                {({ field, meta }: FieldProps) => (
                    <FetchedSelect
                        label={I18n.formatMessage({ id: "pages.redirection.form.fromSiteIdLabel" })}
                        fullWidth
                        variant="outlined"
                        getTitle={(site: Site) => site.name ?? ""}
                        fetch={async (): Promise<Site[]> => {
                            try {
                                const sites = await Api.listSites();
                                return sites.filter(fs => fs.name !== "app");
                            } catch (error) {
                                if (error instanceof ApiError) {
                                    enqueueSnackbar(error.message, { variant: "error" });
                                }
                                return [];
                            }
                        }}
                        helperText={Form.getHelperText(meta, props.redirectionType === RedirectionType.internal ? I18n.formatMessage({ id: "common.required" }) : "")}
                        {...field}
                        error={meta.touched && !!meta.error}
                    />
                )}
            </Field>

            <Box mt="30px" />

            <Field name="from">
                {({ field, meta }: FieldProps) => (
                    <Box mb="30px">
                        <TextField
                            type="text"
                            label={I18n.formatMessage({ id: "pages.redirection.form.from.label" })}
                            fullWidth
                            variant="outlined"
                            helperText={Form.getHelperText(meta, "")}
                            error={meta.touched && !!meta.error}
                            {...field}
                        />
                    </Box>
                )}
            </Field>

            <Box mt="30px" />

            <Field name="is_regex">
                {({ field, meta }: FieldProps) => (
                    <Box mb="30px">
                        <FormControl fullWidth error={meta.touched && !!meta.error}>
                            <Typography color="textSecondary" gutterBottom>
                                {I18n.formatMessage({ id: "pages.redirection.form.is_regex.label" })}
                            </Typography>
                            <FormControlLabel control={<Checkbox {...field} checked={field.value} />} label={I18n.formatMessage({ id: "pages.redirection.form.is_regex" })} />
                        </FormControl>
                    </Box>
                )}
            </Field>

            {props.redirectionType !== RedirectionType.internal.toLowerCase() && (
                <Field name="forward">
                    {({ field, meta }: FieldProps) => (
                        <Box mb="30px">
                            <FormControl fullWidth error={meta.touched && !!meta.error}>
                                <Typography color="textSecondary" gutterBottom>
                                    {I18n.formatMessage({ id: "pages.redirection.form.forward.label" })}
                                </Typography>
                                <FormControlLabel control={<Checkbox {...field} checked={field.value} />} label={I18n.formatMessage({ id: "pages.redirection.form.forward" })} />
                            </FormControl>
                        </Box>
                    )}
                </Field>
            )}

            <Box mt="30px" />

            {props.redirectionType === RedirectionType.siteToSite.toLowerCase() && (
                <Field name="to_site_id">
                    {({ field, meta }: FieldProps) => (
                        <FetchedSelect
                            label={I18n.formatMessage({ id: "pages.redirection.form.toSiteIdLabel" })}
                            fullWidth
                            variant="outlined"
                            getTitle={(site: Site) => site.name ?? ""}
                            fetch={async (): Promise<Site[]> => {
                                try {
                                    const sites = await Api.listSites();
                                    return sites.filter(fs => fs.name !== "app");
                                } catch (error) {
                                    if (error instanceof ApiError) {
                                        enqueueSnackbar(error.message, { variant: "error" });
                                    }
                                    return [];
                                }
                            }}
                            helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                            {...field}
                            error={meta.touched && !!meta.error}
                        />
                    )}
                </Field>
            )}

            <Box mt="30px" />

            <Field name="to">
                {({ field, meta }: FieldProps) => (
                    <Box mb="30px">
                        <TextField
                            type="text"
                            label={I18n.formatMessage({ id: "pages.redirection.form.to.label" })}
                            fullWidth
                            variant="outlined"
                            required={props.redirectionType === RedirectionType.external}
                            helperText={Form.getHelperText(meta, props.redirectionType === RedirectionType.external ? I18n.formatMessage({ id: "common.required" }) : "")}
                            error={meta.touched && !!meta.error}
                            {...field}
                        />
                    </Box>
                )}
            </Field>

            <Field name="is_permanent">
                {({ field }: FieldProps) => (
                    <Box mb="30px">
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="category-select">{I18n.formatMessage({ id: "pages.redirection.form.is_permanent.label" })}</InputLabel>
                            <Select labelId="category-select" {...field} label={I18n.formatMessage({ id: "pages.redirection.form.is_permanent.label" })}>
                                <MenuItem value="true">{I18n.formatMessage({ id: "pages.redirection.form.is_permanent.true" })}</MenuItem>
                                <MenuItem value="false">{I18n.formatMessage({ id: "pages.redirection.form.is_permanent.false" })}</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                )}
            </Field>

            <Field name="active_from">
                {({ field, meta }: FieldProps) => (
                    <TextField
                        className={classes.dateField}
                        id="publication-date"
                        label={I18n.formatMessage({ id: "pages.redirection.form.active_from.label" })}
                        type="datetime-local"
                        variant="outlined"
                        helperText={meta.error}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            max: props.formProps.values.active_to || undefined,
                        }}
                        {...field}
                        error={!!meta.error}
                    />
                )}
            </Field>

            <Field name="active_to">
                {({ field, meta }: FieldProps) => (
                    <TextField
                        id="expiration-date"
                        label={I18n.formatMessage({ id: "pages.redirection.form.active_to.label" })}
                        type="datetime-local"
                        variant="outlined"
                        helperText={meta.error}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            min: props.formProps.values.active_from || undefined,
                        }}
                        {...field}
                        error={!!meta.error}
                    />
                )}
            </Field>

            <SavePanel>
                <Grid container justify="space-between">
                    <Button variant="outlined" color="primary" onClick={onCancelClick}>
                        {I18n.formatMessage({ id: "common.cancel" })}
                    </Button>
                    <Button
                        type="submit"
                        startIcon={<Icon className="fas fa-save" />}
                        variant="contained"
                        color="secondary"
                        disabled={!props.formProps.dirty || !props.formProps.isValid || props.formProps.isSubmitting}
                    >
                        {I18n.formatMessage({ id: "common.save" })}
                    </Button>
                </Grid>
            </SavePanel>
        </Form>
    );
};

const useStyles = makeStyles({
    dateField: {
        marginRight: 30,
    },
});
