import { gql } from "@apollo/client";

export const modelUpdateLogFragment = gql`
    fragment ModelUpdateLog on ModelUpdateLog {
        object_type
        action
        model_id
        ean
        increment_id
        parent_type
        changed
        admin_id
        admin_name
        timestamp
    }
`;
