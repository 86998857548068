import { gql } from "@apollo/client";
import { websiteNotificationFragment } from "../fragments/websiteNotificationFragment";

export const updateWebsiteNotificationMutation = gql`
    mutation updateWebsiteNotification($id: Int!, $input: WebsiteNotificationInput!, $csrf: String) {
        updateWebsiteNotification(id: $id, input: $input, csrf: $csrf) {
            ...WebsiteNotification
        }
    }
    ${websiteNotificationFragment}
`;
