import { gql } from "@apollo/client";
import { pageBlockProductListFragment } from "../fragments/pageBlockProductListFragment";
import { pageBlockHtmlFragment } from "../fragments/pageBlockHtmlFragment";
import { pageBlockBannerFragment } from "./pageBlockBannerFragment";
import { siteFragment } from "./siteFragment";
import { pageBlockNewsletterFragment } from "./pageBlockNewsletterFragment";
import { pageBlockBrandListFragment } from "./pageBlockBrandListFragment";
import { pageBlockRecommendedProductsFragment } from "./pageBlockRecommendedProductsFragment";
import { pageBlockHomeboxFragment } from "./pageBlockHomeboxFragment";

export const pageFragment = gql`
    fragment Page on Page {
        id
        name
        title
        url
        is_active
        is_visible
        active_from
        active_to
        meta_title
        meta_description
        og_title
        og_description
        og_image
        background_image
        created_at
        updated_at
        site {
            ...Site
        }
        created_by {
            name
        }
        updated_by {
            name
        }
        blocks {
            ... on PageBlockBanner {
                ...PageBlockBanner
            }
            ... on PageBlockProductList {
                ...PageBlockProductList
            }
            ... on PageBlockHtml {
                ...PageBlockHtml
            }
            ... on PageBlockNewsletter {
                ...PageBlockNewsletter
            }
            ... on PageBlockBrandList {
                ...PageBlockBrandList
            }
            ... on PageBlockRecommendedProducts {
                ...PageBlockRecommendedProducts
            }
            ... on PageBlockHomebox {
                ...PageBlockHomebox
            }
        }
        is_header
        is_footer
    }
    ${pageBlockBannerFragment}
    ${pageBlockProductListFragment}
    ${pageBlockHtmlFragment}
    ${pageBlockNewsletterFragment}
    ${pageBlockBrandListFragment}
    ${pageBlockRecommendedProductsFragment}
    ${pageBlockHomeboxFragment}
    ${siteFragment}
`;
