import { gql } from "@apollo/client";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";
import { benefitListItemFragment } from "../fragments/benefitListItemFragment";

export const benefitsQuery = gql`
    query benefits($filters: BenefitFilters, $sortBy: BenefitSort, $first: Int!, $page: Int) {
        benefits(first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            data {
                ...BenefitListItem
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${benefitListItemFragment}
    ${paginatorInfoFragment}
`;
