import { gql } from "@apollo/client";
import { productBrandFragment } from "../fragments/productBrandFragment";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";

export const getProductBrandByIdQuery = gql`
    query getProductBrandById($filters: ProductBrandFilters, $sortBy: ProductBrandSort, $first: Int!, $page: Int) {
        listProductBrands(first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            data {
                ...ProductBrand
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${productBrandFragment}
    ${paginatorInfoFragment}
`;
