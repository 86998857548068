import React from "react";
import { Box } from "@bigfish/admin-ui/core";
import { Product } from "Api/graphql/admin/types";
import ProductStocksDataGrid from "./ProductStocksDataGrid";

type Props = {
    product: Product;
};

export const StockTab = (props: Props) => {
    return (
        <div>
            <Box mt="30px" />
            {props.product.stock && <ProductStocksDataGrid stocks={[props.product.stock]} />}
        </div>
    );
};
