import { gql } from "@apollo/client";
import { productListListItemFragment } from "../fragments/productListListItemFragment";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";

export const listProductListsQuery = gql`
    query listProductLists($filters: ProductListFilters, $sortBy: ProductListSort, $first: Int!, $page: Int) {
        listProductLists(first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            data {
                ...ProductListListItem
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${productListListItemFragment}
    ${paginatorInfoFragment}
`;
