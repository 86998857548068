import { gql } from "@apollo/client";
import { campaignFragment } from "../fragments/campaignFragment";

export const updateCampaignMutation = gql`
    mutation updateCampaign($id: Int!, $input: CampaignInput!, $csrf: String) {
        updateCampaign(id: $id, input: $input, csrf: $csrf) {
            ...Campaign
        }
    }
    ${campaignFragment}
`;
