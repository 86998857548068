import { ActiveBreadcrumbItem, PageCard, TitleBar } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { SuccessStory } from "Api/graphql/admin/types";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { I18n } from "Src/i18n/I18n";
import { SuccessStoryForm, SuccessStoryFormValues } from "./SuccessStoryForm";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { Loading } from "Components/Loading";
import { PageLayout } from "Components/PageLayout";
import { Link } from "Components/Link";
import { Breadcrumbs } from "Components/Breadcrumbs";
import Prompt from "Components/Prompt";
import { FunctionConfirmModal } from "Components/FunctionConfirmModal";
import { FunctionalButton } from "Components/FunctionalButton";
import { DeleteForever } from "@bigfish/admin-ui/icons";

type RouteParams = {
    id?: string;
};

type Props = WithSnackbarProps & RouteComponentProps<RouteParams>;

type State = {
    isLoading: boolean;
    successStory: SuccessStory | null;
    isDeleteDialogVisible: boolean;
};

class SuccessStoryEditPage extends React.Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        successStory: null,
        isDeleteDialogVisible: false,
    };

    public async componentDidMount(): Promise<void> {
        try {
            const admin = await Api.getSuccessStory(this.props.match.params.id!);
            this.setState({ successStory: admin, isLoading: false });
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
            this.props.history.push(Path.successStoryList);
        }
    }

    private toggleDeleteDialogVisibility = () => {
        this.setState({ isDeleteDialogVisible: !this.state.isDeleteDialogVisible });
    };

    private deleteSuccessStory = async (): Promise<void> => {
        try {
            await Api.deleteSuccessStory(this.state.successStory!.id);
            this.props.history.push(Path.successStoryList);
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }
    };

    private onSubmit = async (values: SuccessStoryFormValues, formikHelpers: FormikHelpers<SuccessStoryFormValues>): Promise<boolean> => {
        try {
            const successStory = await Api.updateSuccessStory(this.state.successStory!.id, {
                content: values.content,
                division_id: values.division!.id,
                person_id: values.person!.id,
            });
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.successStory.edit.succeed" }), { variant: "success" });
            this.setState({ successStory });
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.successStory.edit.failed" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
        }
        return false;
    };

    private getBreadcrumb = () => {
        return (
            <Breadcrumbs>
                <Link to={Path.successStoryList} color="inherit">
                    {I18n.formatMessage({ id: "pages.successStoryList.breadcrumb" })}
                </Link>
                <ActiveBreadcrumbItem aria-current="page">{I18n.formatMessage({ id: "pages.successStory.edit.breadcrumb" })}</ActiveBreadcrumbItem>
            </Breadcrumbs>
        );
    };

    public render() {
        if (this.state.isLoading) {
            return <Loading breadcrumb={this.getBreadcrumb()} />;
        }
        if (!this.state.successStory) {
            return <Redirect to={Path.successStoryList} />;
        }

        const initialValues: SuccessStoryFormValues = {
            content: this.state.successStory.content,
            division: this.state.successStory.division,
            person: this.state.successStory.person,
        };

        return (
            <PageLayout breadcrumb={this.getBreadcrumb()}>
                <TitleBar title={this.state.successStory.id} rightButtonsComponent={<FunctionalButton onClick={this.toggleDeleteDialogVisibility} icon={<DeleteForever />} />} />
                <PageCard.Container>
                    <Formik initialValues={initialValues} onSubmit={this.onSubmit} validateOnBlur={false} enableReinitialize={true}>
                        {props => (
                            <>
                                <SuccessStoryForm formType={FormType.edit} formProps={props} />
                                <Prompt when={props.dirty} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props)} />
                            </>
                        )}
                    </Formik>
                </PageCard.Container>

                <FunctionConfirmModal
                    title={I18n.formatMessage({ id: "pages.successStory.edit.deleteModal.title" })}
                    description={I18n.formatMessage({ id: "pages.successStory.edit.deleteModal.description" })}
                    isVisible={this.state.isDeleteDialogVisible}
                    onClose={this.toggleDeleteDialogVisibility}
                    onFunctionClick={this.deleteSuccessStory}
                    rightButtonLabel={I18n.formatMessage({ id: "common.delete" })}
                    leftButtonLabel={I18n.formatMessage({ id: "common.cancel" })}
                />
            </PageLayout>
        );
    }
}

export default withSnackbar(withRouter(SuccessStoryEditPage));
