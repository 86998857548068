import React from "react";
import { Button, Icon, Menu, MenuItem } from "@bigfish/admin-ui/core";
import { PageLayout } from "Components/PageLayout";
import { RouteComponentProps, withRouter } from "react-router-dom";
import ProductListListDataGrid from "./ProductListListDataGrid";
import { TitleBar, PageCard } from "@bigfish/admin-ui/components";
import { I18n } from "I18n/I18n";
import { Path } from "Utils/Path";
import { ProductListType, PermissionType } from "Api/graphql/admin/types";
import { ApplicationState } from "Redux/rootReducer";
import { AuthSelectors } from "Redux/selectors/authSelectors";
import { connect } from "react-redux";

type ReduxProps = {
    permissions: PermissionType[];
    isSuperadmin: boolean;
};

type Props = RouteComponentProps & ReduxProps;

type State = {
    anchorElement: (EventTarget & HTMLButtonElement) | null;
};

class ProductListListPage extends React.Component<Props, State> {
    public readonly state: State = {
        anchorElement: null,
    };

    private onAddClick = (productListType: ProductListType): void => {
        this.onAddMenuClose();
        this.props.history.push(Path.productListCreate(productListType));
    };

    private onAddMenuClose = () => {
        this.setState({ anchorElement: null });
    };

    public render() {
        return (
            <PageLayout>
                <TitleBar
                    title={I18n.formatMessage({ id: "pages.productListList.title" })}
                    rightButtonsComponent={
                        (this.props.isSuperadmin || this.props.permissions.includes(PermissionType.product_list_create)) && (
                            <div>
                                <Button
                                    aria-controls="add-menu"
                                    aria-haspopup="true"
                                    startIcon={<Icon className="fa fa-plus-circle" />}
                                    variant="contained"
                                    size="medium"
                                    color="secondary"
                                    onClick={event => this.setState({ anchorElement: event.currentTarget })}
                                >
                                    {I18n.formatMessage({ id: "pages.productListList.addButton" })}
                                </Button>
                                <Menu
                                    id="product-list-list-page-menu"
                                    anchorEl={this.state.anchorElement}
                                    keepMounted
                                    open={!!this.state.anchorElement}
                                    onClose={this.onAddMenuClose}
                                >
                                    <MenuItem onClick={() => this.onAddClick(ProductListType.offer)}>{I18n.formatMessage({ id: "pages.productListList.add.offer" })}</MenuItem>
                                    <MenuItem onClick={() => this.onAddClick(ProductListType.promotion)}>
                                        {I18n.formatMessage({ id: "pages.productListList.add.promotion" })}
                                    </MenuItem>
                                </Menu>
                            </div>
                        )
                    }
                />
                <PageCard.Container>
                    <ProductListListDataGrid />
                </PageCard.Container>
            </PageLayout>
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { permissions: AuthSelectors.getPermissions(state.auth), isSuperadmin: AuthSelectors.isSuperadmin(state.auth) };
};

export default withRouter(connect(mapStateToProps)(ProductListListPage));
