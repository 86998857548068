import { gql } from "@apollo/client";
import { productCategorySelectItemFragment } from "../fragments/productCategorySelectItemFragment";

export const listProductCategorySelectItemsQuery = gql`
    query listProductCategorySelectItems {
        productCategoryTree {
            ...ProductCategorySelectItem
        }
    }
    ${productCategorySelectItemFragment}
`;
