import { Admin } from "Api/graphql/admin/types";
import { ReduxAction } from "Redux/actions/reduxAction";
import { AuthActionTypes } from "../actions/authActions";

export type AuthReducerType = {
    authToken: string | null;
    mediaLibraryToken: string | null;
    admin: Admin | null;
};

const initialState: AuthReducerType = {
    authToken: null,
    mediaLibraryToken: null,
    admin: null,
};

export function authReducer(state: AuthReducerType = initialState, action: ReduxAction): AuthReducerType {
    switch (action.type) {
        case AuthActionTypes.UPDATE_AUTH_TOKEN:
            return { ...state, authToken: action.payload.authToken };
        case AuthActionTypes.UPDATE_MEDIA_LIBRARY_TOKEN:
            return { ...state, mediaLibraryToken: action.payload.mediaLibraryToken };
        case AuthActionTypes.UPDATE_ADMIN:
            return { ...state, admin: action.payload.admin };
        case AuthActionTypes.LOGOUT:
            return { ...initialState };
        default:
            return state;
    }
}
