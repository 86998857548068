import React, { useCallback, useEffect } from "react";
import { Form, FormType } from "Components/Form";
import {
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    Icon,
    TextField,
    Typography,
    Box,
    Chip,
    InputLabel,
    Select,
    OutlinedInput,
    MenuItem,
    FormHelperText,
} from "@bigfish/admin-ui/core";
import { Field, FieldInputProps, FieldProps, FormikProps } from "formik";
import { Validator } from "Utils/Validator";
import { Checkbox } from "@bigfish/admin-ui/core";
import { I18n } from "Src/i18n/I18n";
import { I18nHelpers } from "I18n/I18nHelpers";
import { FullscreenLoader, PageCard, SavePanel } from "@bigfish/admin-ui/components";
import { Path } from "Utils/Path";
import { useHistory } from "react-router-dom";
import { AdminInput, RoleListItem, StoreSelectItem } from "Api/graphql/admin/types";
import { NumberUtils } from "Utils/NumberUtils";
import { Admin } from "Api/graphql/auth/types";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import FetchedSelect from "Components/FetchedSelect/FetchedSelect";
import { Api } from "Api/Api";
import { withSnackbar, WithSnackbarProps } from "notistack";

export type AdminFormValues = AdminInput;

type Props = {
    isMe?: boolean;
    admin?: Admin | null;
    formType: FormType;
    formProps: FormikProps<AdminFormValues>;
    selectableRoles: RoleListItem[];
} & WithSnackbarProps;

export const AdminForm = withSnackbar((props: Props) => {
    const history = useHistory();
    const onCancelClick = useCallback(() => {
        history.push(Path.adminList);
    }, [history]);

    const onPhoneChange = (field: FieldInputProps<any>) => async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!NumberUtils.isStringNumber(e.currentTarget.value)) {
            return;
        } else {
            field.onChange(e);
        }
    };

    useEffect(() => {
        if (isStoreIdDisabled() && props.formProps.values.store_id !== null) {
            props.formProps.setFieldValue("store_id", null);
            props.formProps.setFieldTouched("store_id", true);
        }
    }, [props.formProps.values.roles]);

    const handleRolesChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
        const { value } = event.target;
        props.formProps.setFieldValue("roles", typeof value === "string" ? value.split(",") : value);
        props.formProps.setFieldTouched("roles", true);
    };

    const isStoreIdDisabled = () => {
        return !props.formProps.values.roles.includes("store");
    };

    return (
        <Form formProps={props.formProps}>
            <PageCard.Heading title={I18n.formatMessage({ id: "pages.admin.form.accountData" })} />
            <Field name="name" validate={I18nHelpers.formatValidator(Validator.profileName)}>
                {({ field, meta }: FieldProps) => (
                    <TextField
                        type="text"
                        label={I18n.formatMessage({ id: "pages.admin.form.name.label" })}
                        fullWidth
                        variant="outlined"
                        required
                        helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                        error={meta.touched && !!meta.error}
                        {...field}
                    />
                )}
            </Field>
            <Box mt="30px">
                <Field name="email" validate={I18nHelpers.formatValidator(Validator.email)}>
                    {({ field, meta }: FieldProps) => (
                        <TextField
                            type="text"
                            label={I18n.formatMessage({ id: "pages.admin.form.email.label" })}
                            fullWidth
                            variant="outlined"
                            required
                            helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                            error={meta.touched && !!meta.error}
                            {...field}
                        />
                    )}
                </Field>
            </Box>
            <Box mt="30px">
                <Field name="phone">
                    {({ field, meta }: FieldProps) => (
                        <TextField
                            type="text"
                            label={I18n.formatMessage({ id: "pages.admin.form.phone.label" })}
                            fullWidth
                            variant="outlined"
                            required
                            helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.optionalField" }))}
                            error={meta.touched && !!meta.error}
                            {...field}
                            onChange={onPhoneChange(field)}
                        />
                    )}
                </Field>
            </Box>

            <Field name="is_active">
                {({ field, meta }: FieldProps) =>
                    field.value !== undefined && (
                        <Box mt="30px">
                            <FormControl fullWidth error={meta.touched && !!meta.error}>
                                <Typography color="textSecondary" gutterBottom>
                                    {I18n.formatMessage({ id: "pages.admin.form.status" })}
                                </Typography>
                                <FormControlLabel
                                    style={{ paddingLeft: "15px" }}
                                    control={<Checkbox {...field} checked={field.value} />}
                                    label={I18n.formatMessage({ id: "pages.admin.form.active" })}
                                />
                            </FormControl>
                        </Box>
                    )
                }
            </Field>

            {props.admin?.last_login && (
                <div>
                    <Box mt="30px" />

                    <Typography color="textSecondary" gutterBottom>
                        {I18n.formatMessage({ id: "pages.admin.form.lastLoginLabel" })}
                    </Typography>
                    <Typography variant="subtitle1">{props.admin.last_login ? DateUtils.format(props.admin.last_login, DateFormat.minuteDateTime) : ""}</Typography>
                </div>
            )}

            <Box mt="30px" />

            {props.admin?.id && (
                <div>
                    <Box mt="30px" />

                    <Typography color="textSecondary" gutterBottom>
                        {I18n.formatMessage({ id: "pages.admin.form.idLabel" })}
                    </Typography>
                    <Typography variant="subtitle1">{props.admin.id}</Typography>
                </div>
            )}

            <Box mt="30px">
                <PageCard.Heading title={I18n.formatMessage({ id: "pages.admin.form.roles" })} />
            </Box>

            <Field name="roles" validate={I18nHelpers.formatValidator(Validator.permissionLength)}>
                {({ field, meta }: FieldProps) => (
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel>{I18n.formatMessage({ id: "pages.admin.form.roles" })}</InputLabel>
                        <Select
                            labelId="multiselect-input"
                            id="multiselect-input"
                            multiple
                            input={<OutlinedInput id="multiselect-input" label={I18n.formatMessage({ id: "pages.admin.form.roles" })} />}
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            renderValue={(selected: string[]) => {
                                return (
                                    <Box>
                                        {selected.map((value: string) => (
                                            <Chip
                                                className="multiselect-chip"
                                                key={value}
                                                label={props.selectableRoles.find(role => role.name === value)?.title ?? ""}
                                                deleteIcon={
                                                    <div
                                                        className="multiselect-delete-icon"
                                                        onMouseDown={event => {
                                                            event.stopPropagation();
                                                            props.formProps.setFieldValue(
                                                                "roles",
                                                                props.formProps.values.roles.filter(r => r !== value)
                                                            );
                                                        }}
                                                    >
                                                        <i className="fas fa-times-circle"></i>
                                                    </div>
                                                }
                                                onDelete={() => {}}
                                                clickable={false}
                                            />
                                        ))}
                                    </Box>
                                );
                            }}
                            error={meta.touched && !!meta.error}
                            {...field}
                            onChange={handleRolesChange}
                        >
                            {props.selectableRoles.map(role => (
                                <MenuItem key={role.id} value={role.name}>
                                    {role.title}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText className={meta.touched && !!meta.error ? "custom-error-helper" : "helper-text-nowrap"}>
                            {Form.getHelperText(meta, I18n.formatMessage({ id: "pages.admin.form.rolesHint" }))}
                        </FormHelperText>
                    </FormControl>
                )}
            </Field>

            <Box mt="30px" />

            <Field name="store_id">
                {({ field, meta }: FieldProps) => (
                    <FetchedSelect
                        label={I18n.formatMessage({ id: "pages.admin.form.storeIdLabel" })}
                        fullWidth
                        variant="outlined"
                        getTitle={(store: StoreSelectItem) => `${store.id} - ${store.address}`}
                        fetch={async (): Promise<StoreSelectItem[]> => {
                            try {
                                const stores = await Api.listStoresForSelect({ first: 9999 });
                                return stores
                                    .map(s => {
                                        return { ...s, address: s.address ?? "" };
                                    })
                                    .sort((a, b) => (b.id > a.id ? -1 : 1))
                                    .reverse();
                            } catch (error) {
                                props.enqueueSnackbar(error.message, { variant: "error" });
                                return [];
                            }
                        }}
                        helperText={Form.getHelperText(meta, "")}
                        {...field}
                        error={meta.touched && !!meta.error}
                        disabled={isStoreIdDisabled()}
                        deletable={!!props.formProps.values.store_id}
                        onDelete={() => {
                            props.formProps.setFieldValue("store_id", null);
                            props.formProps.setFieldTouched("store_id", true);
                        }}
                    />
                )}
            </Field>

            <SavePanel>
                <Grid container justify="space-between">
                    <Button variant="outlined" color="primary" onClick={onCancelClick}>
                        {I18n.formatMessage({ id: "common.cancel" })}
                    </Button>
                    {props.formType === FormType.create ? (
                        <Button type="submit" variant="contained" color="secondary" startIcon={<Icon className="fas fa-paper-plane" />} disabled={!props.formProps.dirty}>
                            {I18n.formatMessage({ id: "pages.admin.form.invite" })}
                        </Button>
                    ) : (
                        <Button
                            type="submit"
                            startIcon={<Icon className="fas fa-save" />}
                            variant="contained"
                            color="secondary"
                            disabled={!props.formProps.dirty || !props.formProps.isValid}
                        >
                            {I18n.formatMessage({ id: "common.save" })}
                        </Button>
                    )}
                </Grid>
            </SavePanel>
            <FullscreenLoader visible={props.formProps.isSubmitting} />
        </Form>
    );
});
