import { gql } from "@apollo/client";
import { userListItemFragment } from "../fragments/userListItemFragment";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";

export const listUsersQuery = gql`
    query listUsers($filters: UserFilters, $sortBy: UserOrder, $first: Int!, $page: Int) {
        listUsers(first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            data {
                ...UserListItem
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${userListItemFragment}
    ${paginatorInfoFragment}
`;
