import { gql } from "@apollo/client";
import { personFragment } from "../fragments/personFragment";

export const updatePersonMutation = gql`
    mutation updatePerson($id: ID!, $input: PersonInput!, $csrf: String) {
        updatePerson(id: $id, input: $input, csrf: $csrf) {
            ...Person
        }
    }
    ${personFragment}
`;
