import React from "react";
import { Icon } from "@bigfish/admin-ui/core";
import { I18n } from "I18n/I18n";

type Props = {
    value: boolean | null;
};

export const IsActiveIcon = ({ value: isActive }: Props) => {
    return isActive ? (
        <Icon className="fa fa-check-circle" color="secondary" title={I18n.formatMessage({ id: "common.form.isActive.true" })} />
    ) : (
        <Icon className="fa fa-times-circle" color="disabled" title={I18n.formatMessage({ id: "common.form.isActive.false" })} />
    );
};
