import React from "react";

import { TypeColumn } from "@inovua/reactdatagrid-community/types";
import { Product_children, ProductSortField } from "Api/graphql/admin/types";
import DataGrid from "Components/DataGrid/DataGrid";
import { DataGridUrlQueryParser, QueryParserType } from "Components/DataGrid/DataGridUrlQueryParser";
import { DataSource } from "Components/DataGrid/DataGridUtils";
import { I18n } from "I18n/I18n";

interface BundleProductsDataGrid {
    products: Product_children[];
}

export const BundleProductsDataGrid: React.FC<BundleProductsDataGrid> = ({ products }) => {
    const getColumns = (): TypeColumn[] => {
        return [
            { name: "id", header: I18n.formatMessage({ id: "pages.productCatalog.grid.column.id" }), defaultWidth: 120, filterable: false, sortable: false },
            {
                name: "ean",
                header: I18n.formatMessage({ id: "pages.productCatalog.grid.column.ean" }),
                defaultFlex: 1,
                minWidth: 160,
                render: ({ data }: { data: Product_children }) => data.ean.join(", "),
                filterable: false,
                sortable: false,
            },
            { name: "lfdnr", header: I18n.formatMessage({ id: "pages.productCatalog.grid.column.lfdnr" }), defaultWidth: 160, filterable: false, sortable: false },
            { name: "name", header: I18n.formatMessage({ id: "pages.productCatalog.grid.column.name" }), defaultFlex: 1, minWidth: 420, filterable: false, sortable: false },
        ];
    };

    const urlQueryParser = new DataGridUrlQueryParser({
        filters: {
            id: QueryParserType.integer,
            ean: QueryParserType.string,
            lfdnr: QueryParserType.integer,
            name: QueryParserType.string,
        },
        sortField: ProductSortField,
    });

    const dataSource = async (): Promise<DataSource<Product_children>> => {
        return { data: products, count: products.length };
    };

    return <DataGrid urlQueryParser={urlQueryParser} rowHeight={50} style={{ minHeight: 400 }} dataSource={dataSource} columns={getColumns()} activeCell={null} paginationHidden />;
};
