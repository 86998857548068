import React from "react";
import { Api } from "Api/Api";
import { TypeColumn, TypeRowProps } from "@inovua/reactdatagrid-community/types";
import DataGrid from "Components/DataGrid/DataGrid";
import { ProductFilters, ProductSort, ProductVariantListItem, ProductSortField, ProductCategorySelectItem } from "Api/graphql/admin/types";
import { DataGridUrlQueryParser, QueryParserType } from "Components/DataGrid/DataGridUrlQueryParser";
import { ApiError } from "Api/ApiError";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { withStyles, WithStyles } from "@bigfish/admin-ui/core";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import { Path } from "Utils/Path";
import { dataGridStyles } from "@bigfish/admin-ui/styles";
import { I18n } from "I18n/I18n";
import { ProductCategoryUtils } from "Utils/ProductCategoryUtils";
import { DataSource, FilterValue } from "Components/DataGrid/DataGridUtils";
import { DataGridParams } from "Components/DataGrid/UrlQueryParser";
import { ListItemActive } from "Components/ListItemActive";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import { Constants } from "Utils/Constants";
import { DateFormat, DateUtils } from "Utils/DateUtils";

type ComponentProps = {
    productBrandId?: number;
};

type Props = ComponentProps & WithSnackbarProps & WithStyles<typeof dataGridStyles>;

type State = {
    categorySelectItems: ProductCategorySelectItem[];
};

class ProductVariantListDataGrid extends React.Component<Props, State> {
    public readonly state: State = {
        categorySelectItems: [],
    };

    public componentDidMount = async (): Promise<void> => {
        try {
            const categorySelectItems = await Api.listProductCategorySelectItems();
            this.setState({ categorySelectItems });
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }
    };

    private getColumns = (): TypeColumn[] => {
        return [
            { name: "id", header: I18n.formatMessage({ id: "pages.productVariantList.grid.column.id" }), defaultWidth: 120 },
            { name: "lfdnr", header: I18n.formatMessage({ id: "pages.productVariantList.grid.column.lfdnr" }), defaultWidth: 160 },
            { name: "name", header: I18n.formatMessage({ id: "pages.productVariantList.grid.column.name" }), defaultFlex: 1, minWidth: 420 },
            {
                name: "product_category_id_main",
                header: I18n.formatMessage({ id: "pages.productVariantList.grid.column.main_categories" }),
                defaultWidth: 292,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: ProductCategoryUtils.getMainCategories(this.state.categorySelectItems).map(c => {
                        return { id: c.id, label: c.name };
                    }),
                },
                sortable: false,
                render: ({ data }: { data: ProductVariantListItem }) => data.main_categories.map(c => c.name).join(", "),
            },
            {
                name: "product_category_id",
                header: I18n.formatMessage({ id: "pages.productVariantList.grid.column.categories" }),
                defaultWidth: 400,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: ProductCategoryUtils.getCategories(this.state.categorySelectItems).map(c => {
                        return { id: c.id, label: c.name };
                    }),
                },
                sortable: false,
                render: ({ data }: { data: ProductVariantListItem }) => data.categories.map(c => c.name).join(", "),
            },
            {
                name: "is_visible",
                textAlign: "center",
                header: I18n.formatMessage({ id: "pages.productCatalog.grid.column.is_visible" }),
                defaultWidth: 120,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: [
                        { id: "true", label: I18n.formatMessage({ id: "common.form.isActive.true" }).toLowerCase() },
                        { id: "false", label: I18n.formatMessage({ id: "common.form.isActive.false" }).toLowerCase() },
                    ],
                },
                render: ({ data }: { data: ProductVariantListItem }) => <ListItemActive isActive={data.is_visible} fromDate={data.active_from} />,
            },
            {
                name: "created_at",
                filterEditor: DateFilter,
                header: I18n.formatMessage({ id: "pages.productVariantList.grid.column.created_at" }),
                defaultWidth: Constants.columnWidth.dateTime,
                render: ({ data }: { data: ProductVariantListItem }) => {
                    return <div>{data.created_at ? DateUtils.format(data.created_at, DateFormat.minuteDateTime) : ""}</div>;
                },
            },
        ];
    };

    private filterValues: FilterValue[] = [
        { name: "id", operator: "eq", type: "number" },
        { name: "lfdnr", operator: "eq", type: "number" },
        { name: "name", operator: "contains", type: "string" },
        { name: "product_category_id_main", operator: "inlist", type: "select" },
        { name: "product_category_id", operator: "inlist", type: "select" },
        { name: "is_visible", operator: "eq", type: "select" },
        { name: "created_at", operator: "inrange", type: "date" },
    ];

    private urlQueryParser = new DataGridUrlQueryParser({
        filters: {
            id: QueryParserType.integer,
            lfdnr: QueryParserType.integer,
            name: QueryParserType.string,
            product_category_id_main: QueryParserType.enum,
            product_category_id: QueryParserType.enum,
            is_visible: QueryParserType.boolean,
            created_at: QueryParserType.dateRange,
        },
        sortField: ProductSortField,
    });

    private sortFieldToSortOrder = {
        [ProductSortField.id]: "id",
        [ProductSortField.lfdnr]: "lfdnr",
        [ProductSortField.name]: "name",
        [ProductSortField.is_visible]: "is_visible",
    };

    private dataSource = async (props: DataGridParams<ProductSort, ProductFilters>): Promise<DataSource<ProductVariantListItem>> => {
        try {
            const result = await Api.listProductVariants({
                first: props.limit,
                page: props.page,
                filters: {
                    ...props.filters,
                    product_category_id_main: props.filters?.product_category_id_main
                        ? `${props.filters?.product_category_id_main}`.split(",").map(id => Number.parseInt(`${id}`, 10))
                        : undefined,
                    product_category_id: props.filters?.product_category_id
                        ? `${props.filters?.product_category_id}`.split(",").map(id => Number.parseInt(`${id}`, 10))
                        : undefined,
                },
                sortBy: props.sortBy,
            });

            return { data: result.data, count: result.paginatorInfo.total };
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }
        return { data: [], count: 0 };
    };

    private getRowLink = (rowProps: TypeRowProps): string => {
        return Path.productVariantEdit(rowProps.data.id);
    };

    render() {
        return (
            <DataGrid
                className={this.props.classes.dataGrid}
                urlQueryParser={this.urlQueryParser}
                rowHeight={50}
                style={{ minHeight: 800 }}
                dataSource={this.dataSource}
                filterValues={this.filterValues}
                columns={this.getColumns()}
                rowLink={this.getRowLink}
                activeCell={null}
                sortFieldToSortOrder={this.sortFieldToSortOrder}
            />
        );
    }
}

export default withSnackbar(withStyles(dataGridStyles, { withTheme: true })(ProductVariantListDataGrid));
