import { Box, Button, Checkbox, Divider, Grid, Typography } from "@bigfish/admin-ui/core";
import { TypeFilterValue } from "@inovua/reactdatagrid-community/types";
import { Api } from "Api/Api";
import { BenefitFilters, BenefitListItem, BenefitSort, BenefitSortField } from "Api/graphql/admin/types";
import { DataGridUtils, DataSourceProps } from "Components/DataGrid/DataGridUtils";
import UnControlledDataGrid from "Components/DataGrid/UCDataGrid";
import { FormModal } from "Components/FormModal";
import { I18n } from "I18n/I18n";
import { withSnackbar, WithSnackbarProps } from "notistack";
import React from "react";
import { Constants } from "Utils/Constants";
import { CustomTypeColumn } from "Utils/DataGridUtils";

type ComponentProps = {
    open: boolean;
    products: BenefitListItem[];
    onClose: () => void;
    onProductsSelected: (products: BenefitListItem[]) => void;
};

type Props = ComponentProps & WithSnackbarProps;

type State = {
    selectedProducts: BenefitListItem[];
};

class AddProductModal extends React.Component<Props, State> {
    public readonly state: State = {
        selectedProducts: [...this.props.products],
    };

    public componentDidUpdate(prevProps: Props): void {
        if (!this.props.open && prevProps.open) {
            this.setState({ selectedProducts: [] });
        }
        if (this.props.products !== prevProps.products) {
            this.setState({ selectedProducts: [...this.props.products] });
        }
    }

    private onAddClick = (): void => {
        this.props.onProductsSelected(this.state.selectedProducts);
    };

    private onCheckboxCheck = (data: BenefitListItem) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
        if (checked) {
            this.setState({ selectedProducts: [...this.state.selectedProducts, data] });
        } else {
            this.setState({ selectedProducts: this.state.selectedProducts.filter(sp => sp.id !== data.id) });
        }
    };

    private getColumns = (): CustomTypeColumn[] => {
        return [
            {
                name: "isSelected",
                sortable: false,
                width: 80,
                render: ({ data }: { data: BenefitListItem }) => {
                    const checked = this.state.selectedProducts.some(p => p.id === data.id);
                    return <Checkbox checked={checked} onChange={this.onCheckboxCheck(data)} />;
                },
                header: ({ data }: { data: BenefitListItem[] }) => {
                    const checked = data.length > 0 && data.every(product => this.state.selectedProducts.some(p => product.id === p.id));
                    const indeterminate = !checked && data.some(product => this.state.selectedProducts.some(p => product.id === p.id));
                    return (
                        <Checkbox
                            checked={checked}
                            indeterminate={indeterminate}
                            onChange={() => {
                                if (checked || indeterminate) {
                                    this.setState({ selectedProducts: this.state.selectedProducts.filter(sp => !data.some(d => d.id === sp.id)) });
                                } else {
                                    this.setState({ selectedProducts: [...this.state.selectedProducts, ...data] });
                                }
                            }}
                        />
                    );
                },
            },
            {
                name: "id",
                sortable: true,
                header: I18n.formatMessage({ id: "pages.division.form.benefits.grid.column.id" }),
                filterDelay: Constants.filterDelayMS,
            },
            {
                name: "title",
                sortable: true,
                header: I18n.formatMessage({ id: "pages.division.form.benefits.grid.column.title" }),
                defaultFlex: 1,
                filterDelay: Constants.filterDelayMS,
            },
        ];
    };

    private getDataSource = async (params: DataSourceProps<BenefitListItem>): Promise<{ data: BenefitListItem[]; count: number }> => {
        try {
            const first = params.limit;
            const page = 1 + params.skip / first;
            const sortBy: BenefitSort | undefined = params.sortInfo
                ? {
                      field: params.sortInfo.id.toUpperCase() as BenefitSortField,
                      direction: DataGridUtils.getOrderDirection(params.sortInfo.dir)!,
                  }
                : undefined;

            let filters: BenefitFilters | undefined = undefined;
            if (params.filterValue && params.filterValue.length > 0) {
                filters = { title: params.filterValue[0].value || undefined };
            }
            const result = await Api.benefits({ first, page, sortBy, filters });
            return { data: result.data, count: result.paginatorInfo.total };
        } catch (error) {
            this.props.enqueueSnackbar(error.message, { variant: "error" });
            return { data: [], count: 0 };
        }
    };

    private defaultFilterValue: TypeFilterValue = [{ name: "title", operator: "contains", type: "string", value: "" }];

    public render() {
        return (
            <FormModal width="60%" title={I18n.formatMessage({ id: "pages.division.form.benefit.add.title" })} open={this.props.open} onClose={this.props.onClose}>
                <Box my="20px">
                    <Typography variant="body2" color="textSecondary">
                        {I18n.formatMessage({ id: "pages.division.form.benefit.add.description" })}
                    </Typography>
                </Box>
                <UnControlledDataGrid
                    dataSource={this.getDataSource}
                    columns={this.getColumns()}
                    style={{ maxHeight: 800, minHeight: 400 }}
                    pagination="remote"
                    remoteFilter={true}
                    defaultFilterValue={this.defaultFilterValue}
                />
                <Divider style={{ marginTop: 15, marginBottom: 15 }} />
                <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                        <Box>
                            <Typography variant="button">
                                {I18n.formatMessage({ id: "pages.division.form.benefit.add.selected" }, { count: this.state.selectedProducts.length })}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Grid container justify="space-around" direction="row">
                            <Box mr="20px">
                                <Button variant="outlined" color="primary" onClick={this.props.onClose}>
                                    {I18n.formatMessage({ id: "common.cancel" })}
                                </Button>
                            </Box>
                            <Box>
                                <Button variant="contained" color="secondary" onClick={this.onAddClick}>
                                    {I18n.formatMessage({ id: "common.add" })}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </FormModal>
        );
    }
}

export default withSnackbar(AddProductModal);
