import { gql } from "@apollo/client";
import { productListMinimalFragment } from "./productListMinimalFragment";

export const pageBlockProductListFragment = gql`
    fragment PageBlockProductList on PageBlockProductList {
        id
        type
        title
        product_list {
            ...ProductListMinimal
        }
        is_scrollable
        is_filterable
        product_limit
        product_sort_by {
            field
            direction
        }
    }
    ${productListMinimalFragment}
`;
