import { gql } from "@apollo/client";
import { badgeListItemFragment } from "../fragments/badgeListItemFragment";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";

export const listBadgesQuery = gql`
    query listBadges($filters: BadgeFilters, $sortBy: BadgeSort, $first: Int!, $page: Int) {
        listBadges(first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            data {
                ...BadgeListItem
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${badgeListItemFragment}
    ${paginatorInfoFragment}
`;
