import React, { useCallback, useEffect, useState } from "react";
import { Form, FormType } from "Components/Form";
import { Button, Grid, Icon, TextField, Typography, Box, FormControl, FormControlLabel, Checkbox, InputAdornment } from "@bigfish/admin-ui/core";
import { Field, FieldProps, Formik, FormikErrors, FormikProps } from "formik";
import { Menu, MenuInput, MenuItemInput, MenuItemTypeEnum } from "Api/graphql/admin/types";
import { I18n } from "Src/i18n/I18n";
import { FullscreenLoader, PageCard, SavePanel } from "@bigfish/admin-ui/components";
import { Path } from "Utils/Path";
import { useHistory } from "react-router-dom";
import { I18nHelpers } from "I18n/I18nHelpers";
import { Validator } from "Utils/Validator";
import { StatisticsPaper } from "Components/StatisticsPaper";
import { Paper, RootRef, Table, TableBody, TableContainer, TableRow } from "@bigfish/admin-ui/core";
import { DragDropContext, Draggable, Droppable, DraggingStyle, NotDraggingStyle, DropResult } from "react-beautiful-dnd";
import { MenuItemRow } from "./MenuItemRow";
import { MenuItemFormModal, MenuItemInputLocal } from "./MenuItemFormModal";
import { CustomFormHelperText } from "Components/CustomFormHelperText";
import { EventBusy } from "@bigfish/admin-ui/icons";

export type NewButton = {
    level: number;
    parentId?: string | null;
    parentTitleForNewButton?: string | null;
};

export type MenuItemInputWithDelete = MenuItemInput & { delete?: boolean; id?: string };

export type NormalMenuItem = MenuItemInputWithDelete & { parentId?: string | null; level: number; serializedItems: NormalMenuItem[] };

export type SerializedMenuItem = NormalMenuItem | NewButton;

export type MenuFormValues = MenuInput;

type Props = {
    formType: FormType;
    formProps: FormikProps<MenuFormValues>;
    menu?: Menu;
};

export const menuValidator = () => (values: MenuFormValues): FormikErrors<MenuFormValues> => {
    const errors: { [key in keyof MenuFormValues]?: any } = {};

    if (values.active_from && values.active_to) {
        errors.active_to = Validator.endDateCantBeBeforeStart(values.active_from, values.active_to);
        errors.active_from = errors.active_from || Validator.startDateCantBeAfterEnd(values.active_from, values.active_to);
    }

    return Form.cleanupFormikErrors(errors);
};

export const MenuForm = (props: Props) => {
    const history = useHistory();
    const onCancelClick = useCallback(() => {
        history.push(Path.menuList);
    }, [history]);

    const [serializedMenuItems, setSerializedMenuItems] = useState<SerializedMenuItem[]>([]);
    const [reorderIndex, setReorderIndex] = useState<number>(0);
    const [editedMenuItem, setEditedMenuItem] = useState<NormalMenuItem | null>(null);
    const [newMenuItem, setNewMenuItem] = useState<NewButton | null>(null);

    useEffect(() => {
        // Adding parentId and "new" button
        const makeSerializedMenuItems: SerializedMenuItem[] = [];

        const expandItem = (item: MenuItemInputWithDelete, level: number, parentId?: string | null, parentTitleForNewButton?: string | null): void => {
            makeSerializedMenuItems.push({ ...item, parentId, level, serializedItems: [] });

            const children = item.items;
            if (children) {
                const childItems: (
                    | MenuItemInputWithDelete
                    | {
                          isNewButton: boolean;
                          parentId: string | null | undefined;
                          parentTitleForNewButton: string | null | undefined;
                      }
                )[] = [...children];

                if (level < 4) {
                    childItems.push({ isNewButton: true, parentId: item.id, parentTitleForNewButton });
                }

                childItems.forEach(i => {
                    return "isNewButton" in i ? makeSerializedMenuItems.push({ ...i, level: level + 1 }) : expandItem(i, level + 1, item.id, i.title);
                });
            }
        };

        [...props.formProps.values.items, { isNewButton: true, parentId: undefined }].forEach(i => {
            return "isNewButton" in i ? makeSerializedMenuItems.push({ ...i, level: 0 }) : expandItem(i, 0, undefined, i.title);
        });

        setSerializedMenuItems(makeSerializedMenuItems);
    }, [props.formProps.values.items]);

    const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined, menuItem: SerializedMenuItem) => ({
        ...draggableStyle,
        backgroundColor: !isNewButton(menuItem) && (menuItem as NormalMenuItem).delete ? "#EBEFF9" : "#fff",
        ...(isDragging && {
            background: "rgb(235,235,235)",
        }),
    });

    /**
     * Convert the serialized menu items to MenuItemInputWithDelete[]
     */
    const syncSerializedMenuItems = (newSerializedMenuItems: SerializedMenuItem[]): void => {
        const filteredSerials = [...newSerializedMenuItems].filter(smi => !isNewButton(smi)) as NormalMenuItem[];
        filteredSerials.forEach(fs => (fs.serializedItems = []));

        [4, 3, 2, 1, 0].forEach(level => {
            if (level === 0) return;

            const currentLevels = filteredSerials.filter(smi => smi.level === level);
            const parentLevels = filteredSerials.filter(smi => smi.level === level - 1);

            if (currentLevels.length === 0 || parentLevels.length === 0) return;

            currentLevels.forEach(cl => {
                const parentIndex = filteredSerials.findIndex(pl => pl.id === cl.parentId);

                if (parentIndex !== -1) {
                    // Put child into parent and convert into MenuItemInputWithDelete
                    filteredSerials[parentIndex].serializedItems = [...filteredSerials[parentIndex].serializedItems, cl];
                }
            });
        });

        const topLevels = filteredSerials.filter(fs => fs.level === 0);

        const menuItemInputs = topLevels.map(tl => {
            const getItems = (items: NormalMenuItem[]): MenuItemInputWithDelete[] => {
                return items.map(i => {
                    return {
                        delete: i.delete,
                        id: i.id,
                        type: i.type,
                        model_id: i.model_id,
                        title: i.title,
                        url: i.url,
                        popup: i.popup,
                        is_active: i.is_active,
                        page_id_overlay: tl.page_id_overlay,
                        items: i.serializedItems ? getItems(i.serializedItems) : undefined,
                    };
                });
            };

            return {
                delete: tl.delete,
                id: tl.id,
                type: tl.type,
                model_id: tl.model_id,
                title: tl.title,
                url: tl.url,
                popup: tl.popup,
                is_active: tl.is_active,
                page_id_overlay: tl.page_id_overlay,
                items: tl.serializedItems ? getItems(tl.serializedItems) : undefined,
            };
        });

        props.formProps.setFieldValue("items", menuItemInputs);
        setReorderIndex(reorderIndex + 1);
    };

    const reorder = (list: SerializedMenuItem[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) return;

        let newSerializedMenuItems = [...serializedMenuItems];

        const from = newSerializedMenuItems[result.source.index];
        const to = newSerializedMenuItems[result.destination.index];

        from.parentId = to.parentId;

        newSerializedMenuItems = reorder(serializedMenuItems, result.source.index, result.destination.index);

        syncSerializedMenuItems(newSerializedMenuItems);
    };

    const getMenuItemId = (menuItem: SerializedMenuItem, index: number) => {
        return isNewButton(menuItem)
            ? `new-button-${(menuItem as NewButton).parentId}-${(menuItem as NewButton).parentTitleForNewButton}-${index}`
            : `normal-menu-item-${(menuItem as NormalMenuItem).id}-${index}`;
    };

    const onDeleteToggle = (menuItem: SerializedMenuItem, value: boolean) => {
        const indexToDelete = serializedMenuItems.findIndex(smi => (smi as NormalMenuItem).id === (menuItem as NormalMenuItem).id);
        if (indexToDelete !== -1) {
            const newSerializedMenuItems = [...serializedMenuItems];
            newSerializedMenuItems[indexToDelete] = { ...serializedMenuItems[indexToDelete], delete: value };
            syncSerializedMenuItems(newSerializedMenuItems);
        }
    };

    const onMenuItemUpdate = (menuItemUpdatedValues: MenuItemInputLocal) => {
        const indexToUpdate = serializedMenuItems.findIndex(smi => (smi as NormalMenuItem).id === (editedMenuItem as NormalMenuItem).id);
        if (indexToUpdate !== -1) {
            const newSerializedMenuItems = [...serializedMenuItems];
            newSerializedMenuItems[indexToUpdate] = { ...serializedMenuItems[indexToUpdate], ...menuItemUpdatedValues };
            syncSerializedMenuItems(newSerializedMenuItems);
        }
    };

    const onMenuItemCreate = (menuItemUpdatedValues: MenuItemInputLocal) => {
        const indexToInsertAt = newMenuItem!.parentId
            ? serializedMenuItems.findIndex(smi => isNewButton(smi) && smi.parentId === newMenuItem!.parentId)
            : serializedMenuItems.length;

        if (indexToInsertAt !== -1) {
            const newSerializedMenuItems = [...serializedMenuItems];
            const level = newMenuItem!.level > 0 ? newMenuItem!.level : 0;
            const type = menuItemUpdatedValues.type;
            const model_id = menuItemUpdatedValues.model_id;
            newSerializedMenuItems.splice(indexToInsertAt - 1, 0, {
                id: `${menuItemUpdatedValues.title}-${level}-${type}-${model_id}`,
                type,
                model_id,
                title: menuItemUpdatedValues.title,
                url: menuItemUpdatedValues.url,
                popup: menuItemUpdatedValues.popup,
                is_active: menuItemUpdatedValues.is_active,
                page_id_overlay: menuItemUpdatedValues.page_id_overlay,
                parentId: newMenuItem!.parentId,
                level: level,
            });
            syncSerializedMenuItems(newSerializedMenuItems);
        }
    };

    const isNewButton = (menuItem: SerializedMenuItem) => {
        return "isNewButton" in menuItem;
    };

    const renderMenuItemFormModal = (formType: FormType): JSX.Element | null => {
        if (editedMenuItem && formType === FormType.edit) {
            const initialValues: MenuItemInputLocal = {
                delete: false,
                id: editedMenuItem.id || "",
                type: editedMenuItem.type || MenuItemTypeEnum.url,
                model_id: editedMenuItem.model_id,
                title: editedMenuItem.title || "",
                url: editedMenuItem.url || "",
                popup: editedMenuItem.popup || false,
                is_active: editedMenuItem.is_active || false,
                page_id_overlay: editedMenuItem.page_id_overlay,
            };

            return (
                <Formik
                    initialValues={initialValues}
                    onSubmit={values => {
                        onMenuItemUpdate(values);
                        setEditedMenuItem(null);
                    }}
                    enableReinitialize={true}
                >
                    {props => <MenuItemFormModal menuItem={editedMenuItem} formType={FormType.edit} onClose={() => setEditedMenuItem(null)} formProps={props} />}
                </Formik>
            );
        } else if (newMenuItem && formType === FormType.create) {
            const initialValues: MenuItemInputLocal = {
                delete: false,
                id: "",
                type: MenuItemTypeEnum.url,
                model_id: null,
                title: "",
                url: "",
                popup: false,
                is_active: true,
                page_id_overlay: null,
            };

            return (
                <Formik
                    initialValues={initialValues}
                    onSubmit={values => {
                        onMenuItemCreate(values);
                        setNewMenuItem(null);
                    }}
                    enableReinitialize={true}
                >
                    {props => <MenuItemFormModal menuItem={newMenuItem} formType={FormType.create} onClose={() => setNewMenuItem(null)} formProps={props} />}
                </Formik>
            );
        }
        return null;
    };

    return (
        <Form formProps={props.formProps}>
            <PageCard.Container>
                <PageCard.Heading title={I18n.formatMessage({ id: "common.form.basicData" })} />

                <Box mt="30px" />

                {props.formType === FormType.edit && (
                    <div>
                        <Typography color="textSecondary" gutterBottom>
                            {I18n.formatMessage({ id: "pages.menu.form.idLabel" })}
                        </Typography>
                        <Typography variant="subtitle1">{props.menu?.id}</Typography>
                    </div>
                )}

                <Box mt="30px" />

                <Field name="name" validate={I18nHelpers.formatValidator(Validator.required)}>
                    {({ field, meta }: FieldProps) => (
                        <TextField
                            type="text"
                            label={I18n.formatMessage({ id: "pages.menu.form.nameLabel" })}
                            fullWidth
                            variant="outlined"
                            required
                            helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                            error={meta.touched && !!meta.error}
                            {...field}
                        />
                    )}
                </Field>

                <Box mt="30px" />

                <Field name="slug" validate={I18nHelpers.formatValidator(Validator.required)}>
                    {({ field, meta }: FieldProps) => (
                        <TextField
                            type="text"
                            label={I18n.formatMessage({ id: "pages.menu.form.slugLabel" })}
                            fullWidth
                            variant="outlined"
                            required
                            helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                            error={meta.touched && !!meta.error}
                            {...field}
                        />
                    )}
                </Field>

                <Box mt="30px" />

                <TableContainer component={Paper} key={reorderIndex}>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {provided => (
                                <RootRef rootRef={provided.innerRef}>
                                    <Table>
                                        <TableBody>
                                            {serializedMenuItems.map((menuItem, index) => (
                                                <Draggable
                                                    key={`draggable-${getMenuItemId(menuItem, index)}`}
                                                    draggableId={`menu-item-${index}`}
                                                    index={index}
                                                    isDragDisabled={isNewButton(menuItem)}
                                                >
                                                    {(provided, snapshot) => (
                                                        <TableRow
                                                            className="reorder-cell-row"
                                                            key={getMenuItemId(menuItem, index)}
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, menuItem)}
                                                        >
                                                            <MenuItemRow
                                                                menuItem={menuItem}
                                                                onDeleteClick={() => onDeleteToggle(menuItem, true)}
                                                                onEditClick={() => (!isNewButton(menuItem) ? setEditedMenuItem(menuItem as NormalMenuItem) : {})}
                                                                onRestoreClick={() => onDeleteToggle(menuItem, false)}
                                                                onNewClick={() => setNewMenuItem(menuItem)}
                                                            />
                                                        </TableRow>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </TableBody>
                                    </Table>
                                </RootRef>
                            )}
                        </Droppable>
                    </DragDropContext>
                </TableContainer>

                <Box mt="60px" />

                <PageCard.Heading title={I18n.formatMessage({ id: "common.form.settings" })} />

                <Field name="is_active">
                    {({ field, meta }: FieldProps) =>
                        field.value !== undefined && (
                            <Box mt="15px" ml="10px">
                                <FormControl error={meta.touched && !!meta.error}>
                                    <FormControlLabel control={<Checkbox {...field} checked={field.value} />} label={I18n.formatMessage({ id: "pages.menu.form.isActiveLabel" })} />
                                </FormControl>
                                <CustomFormHelperText meta={meta} />
                            </Box>
                        )
                    }
                </Field>

                <Box mt="30px" />

                <Grid container>
                    <Grid item>
                        <Box mr="30px">
                            <Field name="active_from">
                                {({ field, meta }: FieldProps) => (
                                    <TextField
                                        id="active_from"
                                        label={I18n.formatMessage({ id: "pages.menu.form.activeFromLabel" })}
                                        type="datetime-local"
                                        className={`datetime-local ${props.formProps.values.active_from ? "datetime-local-filled" : ""}`}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            endAdornment: props.formProps.values.active_from ? (
                                                <InputAdornment
                                                    position="end"
                                                    onClick={() => {
                                                        props.formProps.setFieldValue("active_from", "");
                                                        props.formProps.setFieldTouched("active_from", true);
                                                    }}
                                                >
                                                    <EventBusy />
                                                </InputAdornment>
                                            ) : null,
                                        }}
                                        {...field}
                                        value={field.value}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            props.formProps.setTouched({ ...props.formProps.touched, active_from: true });
                                            field.onChange(e);
                                        }}
                                        error={meta.touched && !!meta.error}
                                        helperText={Form.getHelperText(meta, "")}
                                    />
                                )}
                            </Field>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Field name="active_to">
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    id="active_to"
                                    label={I18n.formatMessage({ id: "pages.menu.form.activeToLabel" })}
                                    className={`datetime-local ${props.formProps.values.active_to ? "datetime-local-filled" : ""}`}
                                    type="datetime-local"
                                    placeholder=""
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        endAdornment: props.formProps.values.active_to ? (
                                            <InputAdornment
                                                position="end"
                                                onClick={() => {
                                                    props.formProps.setFieldValue("active_to", "");
                                                    props.formProps.setFieldTouched("active_to", true);
                                                }}
                                            >
                                                <EventBusy />
                                            </InputAdornment>
                                        ) : null,
                                    }}
                                    {...field}
                                    value={field.value}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        props.formProps.setTouched({ ...props.formProps.touched, active_to: true });
                                        field.onChange(e);
                                    }}
                                    error={meta.touched && !!meta.error}
                                    helperText={Form.getHelperText(meta, "")}
                                />
                            )}
                        </Field>
                    </Grid>
                </Grid>
            </PageCard.Container>

            {props.formType === FormType.edit && props.menu && (
                <StatisticsPaper
                    createdAt={props.menu.created_at}
                    createdBy={props.menu.created_by?.name}
                    updatedAt={props.menu.updated_at}
                    updatedBy={props.menu.updated_by?.name}
                />
            )}

            {editedMenuItem && renderMenuItemFormModal(FormType.edit)}
            {newMenuItem && renderMenuItemFormModal(FormType.create)}

            <SavePanel>
                <Grid container justify="space-between">
                    <Button variant="outlined" color="primary" onClick={onCancelClick}>
                        {I18n.formatMessage({ id: "common.cancel" })}
                    </Button>
                    <Button
                        type="submit"
                        startIcon={<Icon className="fas fa-save" />}
                        variant="contained"
                        color="secondary"
                        disabled={!props.formProps.dirty || !props.formProps.isValid}
                    >
                        {I18n.formatMessage({ id: `common.${props.formType === FormType.create ? "create" : "save"}` })}
                    </Button>
                </Grid>
            </SavePanel>
            <FullscreenLoader visible={props.formProps.isSubmitting} />
        </Form>
    );
};
