import React from "react";
import { PageLayout } from "Components/PageLayout";
import { Redirect, RouteComponentProps } from "react-router-dom";
import StoreOrderListDataGrid from "./StoreOrderListDataGrid";
import { TitleBar, PageCard, TabsComponent } from "@bigfish/admin-ui/components";
import { I18n } from "I18n/I18n";
import { connect } from "react-redux";
import { ApplicationState } from "Redux/rootReducer";
import { Path } from "Utils/Path";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { AuthReducerType } from "Redux/reducers/authReducer";
import { AuthSelectors } from "Redux/selectors/authSelectors";

type ReduxProps = {
    auth: AuthReducerType;
};

type Props = RouteComponentProps & ReduxProps & WithSnackbarProps;

type State = {
    tabIndex: number;
};

class StoreOrderListPage extends React.Component<Props, State> {
    public readonly state: State = {
        tabIndex: 0,
    };

    public componentDidMount = () => {
        if (!AuthSelectors.isSuperadmin(this.props.auth) && !this.isStore()) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.storeOrderList.notStoreAdminError" }), { variant: "error", autoHideDuration: 10000 });
        }
    };

    private isStore = () => {
        const { auth } = this.props;
        return auth.admin?.roles.find(r => r.name === "store") && !!auth.admin?.store;
    };

    public render() {
        if (!AuthSelectors.isSuperadmin(this.props.auth) && !this.isStore()) {
            return <Redirect to={Path.dashboard} />;
        }

        return (
            <PageLayout>
                <TitleBar title={I18n.formatMessage({ id: `pages.storeOrderList.title.${this.state.tabIndex === 0 ? "open" : "closed"}` })} />
                <PageCard.Container>
                    <TabsComponent
                        ariaLabel="store-order-tabs"
                        tabs={[
                            {
                                label: I18n.formatMessage({ id: "pages.storeOrderList.tabsTitles.open" }),
                                id: "store-order-open-orders-tab",
                                content: <StoreOrderListDataGrid key="store-order-open-orders" />,
                            },
                            {
                                label: I18n.formatMessage({ id: "pages.storeOrderList.tabsTitles.closed" }),
                                id: "store-order-closed-orders-tab",
                                content: <StoreOrderListDataGrid key="store-order-closed-orders" onlyClosed />,
                            },
                        ]}
                        currentTab={this.state.tabIndex}
                        changeTab={(_event: React.ChangeEvent<{}>, newValue: number) => {
                            this.props.history.push(Path.storeOrderList);
                            this.setState({ tabIndex: newValue });
                        }}
                    />
                </PageCard.Container>
            </PageLayout>
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { auth: state.auth };
};

export default withSnackbar(connect(mapStateToProps)(StoreOrderListPage));
