import { gql } from "@apollo/client";

export const divisionListItemFragment = gql`
    fragment DivisionListItem on Division {
        id
        name
        department {
            id
            name
        }
        created_at
        updated_at
    }
`;
