import { gql } from "@apollo/client";

export const orderListItemFragment = gql`
    fragment OrderListItem on OrderSimple {
        id
        increment_id
        created_at
        time_window_date
        delivery_day_expected
        user_fullname
        user_email
        grand_total
        shipping_method_name
        payment_method_name
        payment_status_title
        time_window_interval
        time_window_interval_length
        pickup_point {
            id
        }
        shipment_status {
            title
        }
        status {
            title
        }
        user_agent_type
        fulfillment
        card_number
        order_subscription_id
    }
`;
