import { gql } from "@apollo/client";
import { benefitListItemFragment } from "./benefitListItemFragment";
import { divisionListItemFragment } from "./divisionListItemFragment";

export const departmentFragment = gql`
    fragment Department on Department {
        id
        name
        is_grouped
        is_filter_enabled
        is_map_enabled
        order
        divisions {
            ...DivisionListItem
        }
        benefits {
            ...BenefitListItem
        }
        created_at
        updated_at
    }
    ${divisionListItemFragment}
    ${benefitListItemFragment}
`;
