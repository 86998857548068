import { gql } from "@apollo/client";

export const badgeFragment = gql`
    fragment Badge on Badge {
        id
        type
        name
        info
        image
        image_title
        product_param {
            id
            name
        }
        is_active
        created_at
        created_by {
            id
            name
        }
        updated_at
        updated_by {
            id
            name
        }
        active_from
        active_to
        is_visible
    }
`;
