import React from "react";
import { ActiveBreadcrumbItem, TitleBar } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { ProductBrand } from "Api/graphql/admin/types";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import { ProductBrandForm, ProductBrandFormValues, productBrandValidator } from "./ProductBrandForm";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { Loading } from "Components/Loading";
import { PageLayout } from "Components/PageLayout";
import { Link } from "Components/Link";
import { Breadcrumbs } from "Components/Breadcrumbs";
import Prompt from "Components/Prompt";
import { Box } from "@bigfish/admin-ui/core";
import { I18n } from "I18n/I18n";

type RouteParams = {
    id?: string;
};

type Props = WithSnackbarProps & RouteComponentProps<RouteParams>;

type State = {
    isLoading: boolean;
    isDeleteDialogVisible: boolean;
    productBrand: ProductBrand | null;
};

class ProductBrandEditPage extends React.Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        isDeleteDialogVisible: false,
        productBrand: null,
    };

    public async componentDidMount(): Promise<void> {
        try {
            const productBrand = await Api.getProductBrandById(Number.parseInt(this.props.match.params.id!, 10));
            this.setState({ productBrand, isLoading: false });
        } catch (error) {
            this.props.enqueueSnackbar(error.message, { variant: "error" });
            this.props.history.push(Path.productBrandList);
        }
    }

    private onSubmit = async (values: ProductBrandFormValues, formikHelpers: FormikHelpers<ProductBrandFormValues>): Promise<boolean> => {
        try {
            const productBrand = await Api.updateProductBrand(this.state.productBrand!.id, {
                name: values.name,
                slug: values.slug,
                image: values.image,
                logo: values.logo,
                logo_title: values.logo_title,
                meta_title: values.meta_title,
                meta_description: values.meta_description,
                og_image: values.og_image,
                og_title: values.og_title,
                og_description: values.og_description,
                is_rossmann: values.is_rossmann,
                is_public: values.is_public,
            });
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.productBrand.onSubmit.success" }), { variant: "success" });
            this.setState({ productBrand });
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.productBrand.onSubmit.error" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
        }
        return false;
    };

    private getBreadcrumb = () => {
        return (
            <Breadcrumbs>
                <Link to={Path.productBrandList} color="inherit">
                    {I18n.formatMessage({ id: "pages.productBrand.breadcrumb.title" })}
                </Link>
                {this.state.productBrand && <ActiveBreadcrumbItem aria-current="page">{this.state.productBrand.name}</ActiveBreadcrumbItem>}
            </Breadcrumbs>
        );
    };

    public render() {
        const { isLoading, productBrand } = this.state;

        if (isLoading) {
            return <Loading breadcrumb={this.getBreadcrumb()} />;
        }

        if (!productBrand) {
            return <Redirect to={Path.productBrandList} />;
        }

        const initialValues: ProductBrandFormValues = {
            name: productBrand.name ?? "",
            slug: productBrand.slug ?? "",
            logo: productBrand.logo,
            logo_title: productBrand.logo_title,
            meta_title: productBrand.meta_title,
            meta_description: productBrand.meta_description,
            og_image: productBrand.og_image,
            og_title: productBrand.og_title,
            og_description: productBrand.og_description,
            is_rossmann: productBrand.is_rossmann,
            is_public: productBrand.is_public,
        };

        return (
            <PageLayout breadcrumb={this.getBreadcrumb()}>
                <TitleBar title={productBrand.name} />
                <Formik initialValues={initialValues} onSubmit={this.onSubmit} validate={productBrandValidator()} validateOnBlur={false} enableReinitialize={true}>
                    {props => (
                        <>
                            <ProductBrandForm formType={FormType.edit} formProps={props} productBrand={productBrand!} />
                            <Prompt when={props.dirty} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props)} />
                        </>
                    )}
                </Formik>
                <Box mt="40px" />
            </PageLayout>
        );
    }
}

export default withSnackbar(withRouter(ProductBrandEditPage));
