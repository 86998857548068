// import CKEditorInspector from "@ckeditor/ckeditor5-inspector";
import ProductListController from "./ProductListController";
import ProductListUI from "./ProductListUI";
import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import "./styles.css";

export default class ProductList extends Plugin {
    static get requires() {
        return [ ProductListController, ProductListUI ];
    }

    init() {
        // eslint-disable-next-line no-undef
        if (process.env.NODE_ENV === "production") {
            // CKEditorInspector.attach( { "editor": this.editor } );
        }
    }
}