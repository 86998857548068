import { gql } from "@apollo/client";
import { productBrandFragment } from "../fragments/productBrandFragment";

export const updateProductBrandMutation = gql`
    mutation updateProductBrand($id: Int!, $input: ProductBrandInput!, $csrf: String) {
        updateProductBrand(id: $id, input: $input, csrf: $csrf) {
            ...ProductBrand
        }
    }
    ${productBrandFragment}
`;
