import { gql } from "@apollo/client";
import { roleFragment } from "../fragments/roleFragment";

export const updateRoleMutation = gql`
    mutation updateRole($id: Int!, $input: RoleInput!, $csrf: String) {
        updateRole(id: $id, input: $input, csrf: $csrf) {
            ...Role
        }
    }
    ${roleFragment}
`;
