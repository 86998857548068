import React from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import { TypeColumn, TypeRowProps } from "@inovua/reactdatagrid-community/types";
import { DataGridParams, Filterable, Sortable } from "./UrlQueryParser";
import { DataGridUtils, DataSource, DataSourceProps, FilterValue } from "./DataGridUtils";
import { I18n } from "I18n/I18n";
import { dataGridStyles } from "@bigfish/admin-ui/styles";
import { withStyles, WithStyles } from "@bigfish/admin-ui/core";
import { TypeRowReorder } from "@inovua/reactdatagrid-community/types/TypeDataGridProps";
import "@inovua/reactdatagrid-community/index.css";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { DataGridProps } from "./DataGrid";

type ComponentProps<DataType extends Object, SortOrder extends Sortable, Filter extends Filterable> = {
    columns: TypeColumn[];
    filterValues?: FilterValue[] | null;
    dataSource: (props: DataGridParams<SortOrder, Filter>) => Promise<DataSource<DataType>>;
    sortFieldToSortOrder: { [key: string]: string };
    rowLink?: (rowProps: TypeRowProps) => string;
    enableSelection?: boolean;
    rowReorderColumn?: boolean;
    onRowReorder?: TypeRowReorder | undefined;
};

type Props<DataType extends Object, SortOrder extends Sortable, Filter extends Filterable> = DataGridProps &
    ComponentProps<DataType, SortOrder, Filter> &
    RouteComponentProps &
    WithStyles<typeof dataGridStyles>;

class UnparsedDataGrid<DataType, SortOrder extends Sortable, Filter extends Filterable> extends React.Component<Props<DataType, SortOrder, Filter>> {
    private dataSource = (values: DataSourceProps<DataType>): Promise<DataSource<DataType>> => {
        const page = 1 + Math.round(values.skip / values.limit); // page value must be at least 1
        const filterValues = { limit: values.limit, page };

        const filters: { [key: string]: string | number | boolean | undefined } = {};
        if (values.filterValue) {
            values.filterValue.forEach(f => {
                switch (f.type) {
                    case "number":
                        filters[f.name] = f.value ? Number.parseInt(f.value, 10) : undefined;
                        break;
                    case "select":
                        filters[f.name] = f.value ? f.value === "true" : undefined;
                        break;
                    default:
                        filters[f.name] = f.value;
                        break;
                }
            });
        }

        let sortBy = undefined;

        if (values.sortInfo) {
            const sortInfo = Array.isArray(values.sortInfo) ? values.sortInfo[0] : values.sortInfo;
            sortBy = { direction: DataGridUtils.getOrderDirection(sortInfo.dir), field: sortInfo.name };
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return this.props.dataSource({ ...filterValues, filters, sortBy });
    };

    private onRowClick = (rowProps: TypeRowProps, event: MouseEvent) => {
        if (this.props.rowLink) {
            const path = this.props.rowLink(rowProps);
            if (event.ctrlKey || event.metaKey) {
                window.open(path, "_blank");
            } else {
                this.props.history.push(path);
            }
        }
    };

    public render() {
        return (
            <ReactDataGrid
                className={this.props.classes.dataGrid}
                showColumnMenuTool={false}
                idProperty="id"
                pagination="remote"
                loadingText={I18n.formatMessage({ id: "common.loading" })}
                emptyText={I18n.formatMessage({ id: "components.dataGrid.emptyText" })}
                i18n={I18n.dataGrid}
                remoteSort={true}
                defaultLimit={DataGridUtils.DEFAULT_LIMIT}
                defaultSkip={0}
                renderColumnFilterContextMenu={() => null}
                resizable={false}
                enableSelection={this.props.enableSelection || false}
                defaultSelected={false}
                showHeader={true}
                showEmptyRows={false}
                showHoverRows={true}
                showZebraRows={false}
                columnUserSelect={true}
                showCellBorders="horizontal"
                remoteFilter={true}
                {...this.props}
                columns={this.props.columns.map(column => {
                    return { ...column, resizable: true };
                })}
                onRowClick={this.props.onRowClick ?? this.onRowClick}
                defaultFilterValue={(this.props.filterValues ?? []).map(fv => {
                    return { ...fv, value: undefined };
                })}
                dataSource={this.dataSource}
                activateRowOnFocus={false}
                rowStyle={this.props.rowLink && { cursor: "pointer" }}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                pageSizes={DataGridUtils.DEFAULT_PAGE_SIZES}
                rowHeight={this.props.rowHeight ?? 50}
                style={this.props.style ?? { minHeight: 800 }}
                rowReorderColumn={this.props.rowReorderColumn}
                onRowReorder={this.props.onRowReorder}
            />
        );
    }
}

export default withRouter(withStyles(dataGridStyles)(UnparsedDataGrid));
