import { Box } from "@bigfish/admin-ui/core";
import { PageCard, TitleBar } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { FulfillmentForm, FulfillmentFormValues, fulfillmentValidator } from "./FulfillmentForm";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { PageLayout } from "Components/PageLayout";
import Prompt from "Components/Prompt";
import { I18n } from "I18n/I18n";
import { FulfillmentTypeSelectable, ShippingMethodTabItem } from "Api/graphql/admin/types";
import { Constants } from "Utils/Constants";
import { Loading } from "Components/Loading";
import { ApiUtils } from "Utils/ApiUtils";

type Props = RouteComponentProps & WithSnackbarProps;

type State = {
    shippingMethods: ShippingMethodTabItem[];
    isCreateSucceed: boolean;
    currentTab: number;
    isLoading: boolean;
};

class FulfillmentCreatePage extends React.Component<Props, State> {
    public readonly state: State = {
        shippingMethods: [],
        isCreateSucceed: false,
        currentTab: 0,
        isLoading: true,
    };

    public async componentDidMount(): Promise<void> {
        this.setState({ isLoading: true }, async () => {
            try {
                const { data: shippingMethods } = await Api.listShippingMethods({ first: 100 });
                this.setState({ shippingMethods });
            } catch (error) {
                if (error instanceof ApiError) {
                    this.props.enqueueSnackbar(error.message, { variant: "error" });
                }
            }
        });
        this.setState({ isLoading: false });
    }

    private onSubmit = async (values: FulfillmentFormValues, formikHelpers: FormikHelpers<FulfillmentFormValues>, isRedirectHandled?: boolean): Promise<boolean> => {
        try {
            const fulfillmentId = await Api.createFulfillment(ApiUtils.getFulfillmentFormValues(values));
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.fulfillment.onCreateSubmit.succeed" }), { variant: "success" });
            if (!isRedirectHandled) {
                this.setState({ isCreateSucceed: true }, () => this.props.history.push(Path.fulfillmentEdit(`${fulfillmentId}`)));
            }
            return true;
        } catch (error) {
            let errorMessage = null;

            if (error instanceof ApiError) {
                errorMessage = error?.message;
                Form.submitFailed(formikHelpers, error);
            }

            this.props.enqueueSnackbar(errorMessage || I18n.formatMessage({ id: "pages.fulfillment.onCreateSubmit.error" }), { variant: "error" });
            return false;
        }
    };

    public render() {
        const { isLoading, shippingMethods } = this.state;

        if (this.state.isCreateSucceed) {
            return <Redirect to={Path.fulfillmentList} />;
        }

        const initialValues: FulfillmentFormValues = {
            type: FulfillmentTypeSelectable.DHL,
            weight: 0,
            active_from: null,
            active_to: null,
            limit: 0,
            shipping_methods: [],
            timewindow_allowed: false,
            vip_levels: [],
            is_active: true,
            product_id_force: [],
            product_id_exclude: [],
            package_type_max: Constants.nullFormValue,
            notification_threshold: 0,
            quantity_max: null,
        };

        if (isLoading) {
            return <Loading />;
        }

        return (
            <PageLayout>
                <TitleBar title={I18n.formatMessage({ id: "pages.fulfillment.new" })} />
                <PageCard.Container>
                    <PageCard.Heading title={I18n.formatMessage({ id: "common.form.basicData" })} />
                    <Formik initialValues={initialValues} onSubmit={this.onSubmit} validateOnBlur={true} validate={fulfillmentValidator}>
                        {props => (
                            <>
                                <FulfillmentForm formType={FormType.create} formProps={props} shippingMethods={shippingMethods} />
                                <Prompt when={props.dirty && !this.state.isCreateSucceed} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props, true)} />
                            </>
                        )}
                    </Formik>
                    <Box mt="40px" />
                </PageCard.Container>
            </PageLayout>
        );
    }
}

export default withRouter(withSnackbar(FulfillmentCreatePage));
