import React from "react";
import FormikErrorFocus from "formik-error-focus";
import { ApiError, FieldError } from "Api/ApiError";
import { FieldMetaProps, FormikErrors, FormikHelpers, FormikProps } from "formik";
import { ObjectUtils } from "Utils/ObjectUtils";

type Props<Values> = {
    formProps: FormikProps<Values>;
    children: React.ReactNode;
};

export enum FormType {
    create = "create",
    edit = "edit",
}
export class Form<Values> extends React.Component<Props<Values>> {
    public static submitFailed<T>(formikHelpers: FormikHelpers<T>, apiError: ApiError): void {
        if (apiError.hasFieldError()) {
            apiError.fieldErrors.forEach((fieldError: FieldError) => {
                formikHelpers.setFieldError(fieldError.name, fieldError.message);
            });
        }
    }

    public static getHelperText<T>(meta: FieldMetaProps<T>, helperText: React.ReactNode = null, errorOnlyIfTouched = true): React.ReactNode {
        return (meta.touched || !errorOnlyIfTouched) && !!meta.error ? `${meta.error}` : helperText;
    }

    public static cleanupFormikErrors<T, V extends { [Key in keyof T]: string | undefined }>(errors: FormikErrors<V>): FormikErrors<V> {
        const result: FormikErrors<V> = {};
        return ObjectUtils.keys(errors)
            .filter(key => errors[key] !== undefined)
            .reduce((res, key) => {
                res[key] = errors[key];
                return res;
            }, result);
    }

    private onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        this.props.formProps.submitForm();
    };

    public render() {
        return (
            <form onSubmit={this.onSubmit} noValidate>
                {this.props.children}
                <FormikErrorFocus offset={-200} align={"top"} focusDelay={200} ease={"linear"} duration={250} />
            </form>
        );
    }
}
