import { gql } from "@apollo/client";

export const productBrandFragment = gql`
    fragment ProductBrand on ProductBrand {
        id
        name
        evo_name
        slug
        logo
        logo_title
        meta_title
        meta_description
        og_image
        og_title
        og_description
        is_logo
        is_public
        is_rossmann
        created_at
        updated_at
        updated_by {
            id
            name
        }
    }
`;
