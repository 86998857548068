import { gql } from "@apollo/client";
import { productFragment } from "./productFragment";

export const bannerProductFragment = gql`
    fragment BannerProduct on BannerProduct {
        id
        name
        type
        urls
        product {
            ...Product
        }
        position
        is_active
        active_from
        active_to
        created_at
        created_by {
            id
            name
        }
        updated_at
        updated_by {
            id
            name
        }
    }
    ${productFragment}
`;
