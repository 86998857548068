import React from "react";
import "./styles.css";

type Props = {
    fontAwesomeIcon: string; // fontawesome icon. e.g. "fas fa-phone-alt"
    value: string;
};

export const ValueWithIcon = (props: Props) => {
    return props.value ? (
        <div className="value-with-icon-wrapper">
            <i className={`value-with-icon-icon ${props.fontAwesomeIcon}`} />
            {props.value}
        </div>
    ) : null;
};
