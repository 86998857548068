import hu from "date-fns/locale/hu";
import { isEqual, isAfter, isBefore, parse, parseISO, format as dateFnsFormat } from "date-fns";

export enum DateFormat {
    default = "yyyy-MM-dd",
    yyyymmddhhmm = "yyyy. MM. dd. HH:mm",
    yyyymmdd = "yyyy. MM. dd.",
    apiDateTime = "yyyy-MM-dd HH:mm:ss",
    input = "yyyy-MM-dd'T'HH:mm",
    minuteDateTime = "yyyy-MM-dd HH:mm",
}

export class DateUtils {
    /**
     * Parse string to Date
     * @param dateString string
     * @param dateFormat DateFormat
     */
    public static parse(dateString: string, dateFormat: DateFormat | string = DateFormat.default): Date {
        return parse(dateString, dateFormat, new Date());
    }

    private static formatDate(date: Date, format: DateFormat): string {
        try {
            const formattedDate = dateFnsFormat(date, format, { locale: hu });
            return formattedDate;
        } catch (error) {
            return `${date}`;
        }
    }

    public static format(date: string, format: DateFormat): string;
    public static format(date: Date, format: DateFormat): string;

    /**
     * Format Date to string
     * @param date Date | string
     * @param format DateFormat
     */
    public static format(date: Date | string, format: DateFormat): string {
        if (date instanceof Date) {
            return DateUtils.formatDate(date, format);
        }
        return DateUtils.formatDate(DateUtils.parseISO(date), format);
    }

    public static parseISO(date: string) {
        return parseISO(date);
    }

    public static isAfter(date1: Date, date2: Date) {
        return isAfter(date1, date2);
    }

    public static isBetween = (date: Date, from: Date, to: Date, inclusivity = "()") => {
        if (!["()", "[]", "(]", "[)"].includes(inclusivity)) {
            throw new Error("Inclusivity parameter must be one of (), [], (], [)");
        }

        const isBeforeEqual = inclusivity[0] === "[",
            isAfterEqual = inclusivity[1] === "]";

        return (isBeforeEqual ? isEqual(from, date) || isBefore(from, date) : isBefore(from, date)) && (isAfterEqual ? isEqual(to, date) || isAfter(to, date) : isAfter(to, date));
    };
}
