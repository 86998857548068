import React, { useMemo } from "react";
import { Order, OrderItem, OrderItemType } from "Api/graphql/admin/types";
import { Box, Grid, Typography } from "@bigfish/admin-ui/core";
import { I18n } from "I18n/I18n";
import OrderItemListDataGrid from "./OrderItemListDataGrid";

type Props = {
    order: Order | undefined;
    onModifyOrderSubmit: (orderItems: OrderItem[]) => Promise<boolean>;
};

export const OrderFormProductsTab = (props: Props) => {
    const renderField = (i18nLabelId: string, value: JSX.Element | string | null) => {
        return (
            <div style={{ wordWrap: "break-word", whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
                <Typography color="textSecondary" gutterBottom>
                    {I18n.formatMessage({ id: `pages.order.form.label.${i18nLabelId}` })}
                </Typography>
                <Typography variant="subtitle1">{value}</Typography>
            </div>
        );
    };

    const getDiscounts = () => {
        if (!props.order?.items || props.order?.items.length === 0) return "-";

        const discounts = props.order.items
            .map(i =>
                i.discounts.map(d => {
                    if (i.quantity === 1) {
                        return `${d.promotion_name} (${I18n.formatCurrency(d.amount)}) (${i.product.name})`;
                    }
                    return `${d.promotion_name} (${i.quantity}*${I18n.formatCurrency(d.amount)}) (${i.product.name})`;
                })
            )
            .flat();

        return discounts.length > 0 ? (
            <ul>
                {discounts.map(d => {
                    return <li key={d}>{d}</li>;
                })}
            </ul>
        ) : (
            "-"
        );
    };

    const renderOrderItemsListDataGrid = useMemo(() => {
        return props.order?.items ? (
            <OrderItemListDataGrid
                orderItems={props.order.items.map(i => {
                    return {
                        ...i,
                        product_name: i.product.name,
                        product_lfdnr: i.product.lfdnr,
                        ean: i.product.ean.join(", "),
                    };
                })}
                onModifyOrderSubmit={props.onModifyOrderSubmit}
                isOrderEditable={props.order.can_be_modified}
            />
        ) : null;
    }, [props.order?.items]);

    if (!props.order) return null;

    return (
        <div>
            <Box mt="30px" />

            <Grid container spacing={3}>
                <Grid item xs={3}>
                    {renderField("orderedItems", `${props.order.items.length}`)}
                </Grid>
                <Grid item xs={3}>
                    {renderField(
                        "orderedProducts",
                        `${props.order.items
                            .filter(i => i.type === OrderItemType.product)
                            .map(i => i.quantity)
                            .reduce((total, num) => {
                                return total + num;
                            })}`
                    )}
                </Grid>
                <Grid item xs={3}>
                    {renderField("netTotal", I18n.formatCurrency(props.order.grand_total_netto))}
                </Grid>
                {props.order.grand_total !== undefined && props.order.grand_total !== null && props.order.tax_total !== undefined && props.order.tax_total !== null && (
                    <Grid item xs={3}>
                        {renderField("grossTotal", I18n.formatCurrency(props.order.grand_total))}
                    </Grid>
                )}
            </Grid>

            <Box mt="30px" />

            <Grid container>
                <Grid item xs={9}>
                    <Typography color="textSecondary" gutterBottom>
                        {I18n.formatMessage({ id: "pages.order.form.discountsLabel" })}
                    </Typography>
                    <Typography variant="subtitle1">{getDiscounts()}</Typography>
                </Grid>
            </Grid>

            <Box mt="30px" />

            {renderOrderItemsListDataGrid}
        </div>
    );
};
