import { gql } from "@apollo/client";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";
import { textContentListItemFragment } from "../fragments/textContentListItemFragment";

export const adminContentsQuery = gql`
    query adminContents($filters: ContentFilters, $sortBy: ContentOrder, $first: Int!, $page: Int) {
        adminContents(first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            data {
                ... on TextContent {
                    ...TextContentListItem
                }
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${textContentListItemFragment}
    ${paginatorInfoFragment}
`;
