import { gql } from "@apollo/client";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";
import { subscriptionListItemFragment } from "../fragments/subscriptionListItemFragment";

export const listSubscriptionsQuery = gql`
    query listSubscriptions($filters: OrderSubscriptionFilters, $sortBy: OrderSubscriptionSort, $first: Int!, $page: Int) {
        listOrderSubscriptions(first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            data {
                ...SubscriptionListItem
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${subscriptionListItemFragment}
    ${paginatorInfoFragment}
`;
