import React from "react";

import { withSnackbar, WithSnackbarProps } from "notistack";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { PageCard, TitleBar } from "@bigfish/admin-ui/components";
import { Box } from "@bigfish/admin-ui/core";

import { PermissionType } from "Api/graphql/admin/types";
import { PageLayout } from "Components/PageLayout";
import { I18n } from "I18n/I18n";
import TestMail from "Pages/MailTemplate/TestMail";

import MailTemplateListDataGrid from "./MailTemplateListDataGrid";

type ReduxProps = {
    permissions: PermissionType[];
    isSuperadmin: boolean;
};

type Props = ReduxProps & RouteComponentProps & WithSnackbarProps;

class MailTemplateListPage extends React.Component<Props> {
    public render() {
        return (
            <PageLayout>
                <TitleBar title={I18n.formatMessage({ id: "pages.mailTemplateList.title" })} />
                <Box mt="20px" />
                <TestMail allEmail />
                <PageCard.Container>
                    <MailTemplateListDataGrid />
                </PageCard.Container>
            </PageLayout>
        );
    }
}

export default withSnackbar(withRouter(MailTemplateListPage));
