import { gql } from "@apollo/client";

export const storeOrderListItemFragment = gql`
    fragment StoreOrderListItem on OrderSimple {
        id
        increment_id
        created_at
        billing_fullname
        shipping_fullname
        delivery_day_expected
        packages {
            id
        }
        is_in_store
        store_admin_is_took_over
        delivered_at
        shipment_status {
            title
        }
    }
`;
