import { gql } from "@apollo/client";
import { mailTemplateFragment } from "../fragments/mailTemplateFragment";

export const getMailTemplateByIdQuery = gql`
    query getMailTemplateById($filters: MailTemplateFilters, $sortBy: MailTemplateOrder, $first: Int!, $page: Int) {
        listMailTemplates(first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            data {
                ...MailTemplate
            }
        }
    }
    ${mailTemplateFragment}
`;
