import { gql } from "@apollo/client";

export const userAddressSmallFragment = gql`
    fragment UserAddressSmall on UserAddress {
        firstname
        lastname
        zip_code
        city
        address_line1
        address_line2
        company_name
        company_number
    }
`;
