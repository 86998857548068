import { gql } from "@apollo/client";

export const adminNoteFragment = gql`
    fragment AdminNote on AdminNote {
        note
        created_by {
            id
            name
        }
        created_at
    }
`;
