import { Box, Typography } from "@bigfish/admin-ui/core";
import { ActiveBreadcrumbItem } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { BannerForm, BannerFormValues, bannerValidator } from "./BannerForm";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { PageLayout } from "Components/PageLayout";
import { Link } from "Components/Link";
import { Breadcrumbs } from "Components/Breadcrumbs";
import Prompt from "Components/Prompt";
import { I18n } from "I18n/I18n";
import { BannerAlign, BannerType } from "Api/graphql/admin/types";
import { ObjectUtils } from "Utils/ObjectUtils";
import { DateFormat, DateUtils } from "Utils/DateUtils";

type RouteParams = {
    type?: string;
};

type Props = RouteComponentProps<RouteParams> & WithSnackbarProps;

type State = {
    isCreateSucceed: boolean;
};

class BannerCreatePage extends React.Component<Props, State> {
    public readonly state: State = {
        isCreateSucceed: false,
    };

    private onSubmit = async (values: BannerFormValues, formikHelpers: FormikHelpers<BannerFormValues>, isRedirectHandled?: boolean): Promise<boolean> => {
        try {
            const bannerId = await Api.createBanner({
                ...values,
                urls: values.urls.filter(u => !!u),
                active_from: values.active_from ? DateUtils.format(values.active_from, DateFormat.apiDateTime) : null,
                active_to: values.active_to ? DateUtils.format(values.active_to, DateFormat.apiDateTime) : null,
            });
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.banner.onCreateSubmit.succeed" }), { variant: "success" });
            if (!isRedirectHandled) {
                this.setState({ isCreateSucceed: true }, () => this.props.history.push(Path.bannerEdit(`${bannerId}`)));
            }
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.banner.onCreateSubmit.error" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
            return false;
        }
    };

    public render() {
        const { type } = this.props.match.params;

        const isTypeParamInvalid = (): boolean => {
            return (
                !type ||
                !ObjectUtils.enumAsArray<BannerType>(BannerType)
                    .map(type => `${type}`)
                    .includes(type)
            );
        };

        if (this.state.isCreateSucceed || isTypeParamInvalid()) {
            return <Redirect to={Path.bannerList} />;
        }

        const initialValues: BannerFormValues = {
            name: "",
            title: "",
            slider_title: "",
            type: type as BannerType,
            image: "",
            image_align: BannerAlign.center,
            image_title: "",
            image_mobile: "",
            image_mobile_title: "",
            button_title: "",
            target_url: "",
            target_popup: true,
            urls: [""],
            is_active: true,
            active_from: null,
            active_to: null,
            product_id: null,
            position: null,
        };

        return (
            <PageLayout
                breadcrumb={
                    <Breadcrumbs>
                        <Link to={Path.bannerList} color="inherit">
                            {I18n.formatMessage({ id: "pages.banner.createBreadcrumb" })}
                        </Link>
                        <ActiveBreadcrumbItem aria-current="page">{I18n.formatMessage({ id: "pages.banner.new" })}</ActiveBreadcrumbItem>
                    </Breadcrumbs>
                }
            >
                <Typography variant="h1">{I18n.formatMessage({ id: "pages.banner.new" })}</Typography>
                <Formik initialValues={initialValues} onSubmit={this.onSubmit} validate={bannerValidator(FormType.create)} validateOnBlur={false}>
                    {props => (
                        <>
                            <BannerForm formType={FormType.create} formProps={props} />
                            <Prompt when={props.dirty && !this.state.isCreateSucceed} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props, true)} />
                        </>
                    )}
                </Formik>
                <Box mt="40px" />
            </PageLayout>
        );
    }
}

export default withRouter(withSnackbar(BannerCreatePage));
