import React, { useCallback } from "react";

import { FormikErrors, FormikProps } from "formik";
import { useHistory } from "react-router-dom";

import { FullscreenLoader, PageCard, SavePanel, TabsComponent } from "@bigfish/admin-ui/components";
import { Button, Grid, Icon } from "@bigfish/admin-ui/core";

import { Product, ProductBundleInput } from "Api/graphql/admin/types";
import { Form, FormType } from "Components/Form";
import { StatisticsPaper } from "Components/StatisticsPaper";
import { I18n } from "Src/i18n/I18n";
import { Path } from "Utils/Path";
import { Validator } from "Utils/Validator";

import { GeneralTab } from "./GeneralTab";
import { ProductImageInputWithGenericUrl } from "Utils/ApiUtils";

export type ProductBundleFormValues = Omit<ProductBundleInput, "images"> & {
    images: ProductImageInputWithGenericUrl[];
    og_image?: string;
    og_title?: string;
    og_description?: string;
    meta_description?: string;
    description?: string;
};

type Props = {
    formType: FormType;
    product?: Product;
    formProps: FormikProps<ProductBundleFormValues>;
};

export const bundleValidator = () => (values: ProductBundleFormValues): FormikErrors<ProductBundleFormValues> => {
    const errors: { [key in keyof ProductBundleFormValues]?: any } = {};

    if (values.active_from && values.active_to) {
        errors.active_to = Validator.endDateCantBeBeforeStart(values.active_from, values.active_to);
        errors.active_from = errors.active_from || Validator.startDateCantBeAfterEnd(values.active_from, values.active_to);
    }

    return Form.cleanupFormikErrors(errors);
};

export const ProductBundleForm = (props: Props) => {
    const history = useHistory();
    const onCancelClick = useCallback(() => {
        history.push(Path.productBundles);
    }, [history]);

    const [formTab, setFormTab] = React.useState(0);

    const changeTab = (_event: React.ChangeEvent<{}>, newValue: number) => {
        setFormTab(newValue);
    };

    return (
        <Form formProps={props.formProps}>
            <PageCard.Container>
                <TabsComponent
                    ariaLabel="product-form-tabs"
                    tabs={[
                        {
                            label: I18n.formatMessage({ id: "pages.product.form.tabs.general" }),
                            id: "product-form-general-tab",
                            content: <GeneralTab product={props.product} formProps={props.formProps} formType={props.formType} />,
                        },
                        // TODO: https://jira.bigfish.hu/browse/ROS-1583 (Napló)
                        // {
                        //     label: I18n.formatMessage({ id: "pages.product.form.tabs.log" }),
                        //     id: "product-form-log-tab",
                        //     // content: <LogTab />,
                        //     content: <p>log</p>,
                        // },
                    ]}
                    currentTab={formTab}
                    changeTab={changeTab}
                />
            </PageCard.Container>

            {props.formType === FormType.edit && props.product && (
                <StatisticsPaper
                    createdAt={props.product.created_at}
                    createdBy={props.product.created_by?.name}
                    updatedAt={props.product.updated_at}
                    updatedBy={props.product.updated_by?.name}
                />
            )}

            <SavePanel>
                <Grid container justify="space-between">
                    <Button variant="outlined" color="primary" onClick={onCancelClick}>
                        {I18n.formatMessage({ id: "common.cancel" })}
                    </Button>
                    <Button
                        type="submit"
                        startIcon={<Icon className="fas fa-save" />}
                        variant="contained"
                        color="secondary"
                        disabled={!props.formProps.dirty || !props.formProps.isValid}
                    >
                        {I18n.formatMessage({ id: `common.${props.formType === FormType.create ? "create" : "save"}` })}
                    </Button>
                </Grid>
            </SavePanel>
            <FullscreenLoader visible={props.formProps.isSubmitting} />
        </Form>
    );
};
