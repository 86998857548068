// import CKEditorInspector from "@ckeditor/ckeditor5-inspector";
import DocumentBoxController from "./DocumentBoxController";
import DocumentBoxUI from "./DocumentBoxUI";
import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import "./styles.css";

export default class DocumentBox extends Plugin {
    static get requires() {
        return [ DocumentBoxController, DocumentBoxUI ];
    }

    init() {
        // eslint-disable-next-line no-undef
        // if (process.env.NODE_ENV === "production") {
        //     CKEditorInspector.attach( { "editor": this.editor } );
        // }
    }
}