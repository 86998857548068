import React from "react";
import { Api } from "Api/Api";
import { TypeRowProps } from "@inovua/reactdatagrid-community/types";
import DataGrid from "Components/DataGrid/DataGrid";
import { BannerFilters, BannerSort, BannerListItem, BannerType, BannerSortField, listBanners_listBanners_data } from "Api/graphql/admin/types";
import { DataGridUrlQueryParser, QueryParserType } from "Components/DataGrid/DataGridUrlQueryParser";
import { ApiError } from "Api/ApiError";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { Checkbox, withStyles, WithStyles } from "@bigfish/admin-ui/core";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import { Path } from "Utils/Path";
import { dataGridStyles } from "@bigfish/admin-ui/styles";
import { Constants } from "Utils/Constants";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import { I18n } from "I18n/I18n";
import { IsActiveIcon } from "Components/IsActive";
import { connect } from "react-redux";
import { AuthSelectors } from "Redux/selectors/authSelectors";
import { ApplicationState } from "Redux/rootReducer";
import { PermissionType } from "Api/graphql/admin/types";
import { DataSource, FilterValue } from "Components/DataGrid/DataGridUtils";
import { DataGridParams } from "Components/DataGrid/UrlQueryParser";
import { ObjectUtils } from "Utils/ObjectUtils";
import { CustomTypeColumn } from "Utils/DataGridUtils";

type ComponentProps = {
    isSingleSelect?: boolean;
    selectedBannerIds?: number[];
    onAddBanners?(products: listBanners_listBanners_data[]): void;
    onRemoveBanners?(products: listBanners_listBanners_data[]): void;
    disableRowClick?: boolean;
    minWidth?: number;
    onlyActive?: boolean;
};

type ReduxProps = {
    permissions: PermissionType[];
    isSuperadmin: boolean;
};

type Props = ReduxProps & ComponentProps & WithSnackbarProps & WithStyles<typeof dataGridStyles>;

class BannerListDataGrid extends React.Component<Props> {
    private getColumns = (): CustomTypeColumn[] => {
        const columns = [
            ...(this.props.selectedBannerIds
                ? [
                      {
                          name: "selected",
                          header: "",
                          sortable: false,
                          defaultWidth: 60,
                          textAlign: "center",
                          filterEditor: () => {
                              const isAllSelected = this.data.length > 0 && this.data.every(data => this.props.selectedBannerIds?.includes(data.id));
                              const indeterminate = !isAllSelected && this.data.some(data => this.props.selectedBannerIds?.includes(data.id));
                              return (
                                  <Checkbox
                                      checked={isAllSelected}
                                      indeterminate={indeterminate}
                                      onChange={() => (isAllSelected ? this.props.onRemoveBanners?.(this.data) : this.props.onAddBanners?.(this.data))}
                                  />
                              );
                          },
                          filterable: true,
                          render: ({ data }: { data: BannerListItem }) => {
                              const isAdded = this.props.selectedBannerIds?.includes(data.id);
                              return (
                                  <div>
                                      <Checkbox checked={isAdded} onChange={() => (isAdded ? this.props.onRemoveBanners?.([data]) : this.props.onAddBanners?.([data]))} />
                                  </div>
                              );
                          },
                      } as CustomTypeColumn,
                  ]
                : []),
            { name: "id", header: I18n.formatMessage({ id: "pages.bannerList.grid.column.id" }), defaultWidth: 100, filterDelay: Constants.filterDelayMS },
            {
                name: "name",
                header: I18n.formatMessage({ id: "pages.bannerList.grid.column.name" }),
                defaultFlex: 1,
                minWidth: 260,
                filterDelay: Constants.filterDelayMS,
            },
            { name: "target_url", header: I18n.formatMessage({ id: "pages.bannerList.grid.column.target_url" }), defaultWidth: 271, filterDelay: Constants.filterDelayMS },
            {
                name: "type",
                defaultWidth: 140,
                header: I18n.formatMessage({ id: "pages.bannerList.grid.column.type" }),
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: [
                        ...ObjectUtils.enumAsArray<BannerType>(BannerType).map((type: BannerType) => {
                            return { id: type, label: I18n.formatMessage({ id: `enums.bannerType.${type}` }) };
                        }),
                    ],
                },
                render: ({ data }: { data: BannerListItem }) => {
                    return <div>{I18n.formatMessage({ id: `enums.bannerType.${data.type}` })}</div>;
                },
            },
            {
                name: "active_from",
                filterEditor: DateFilter,
                header: I18n.formatMessage({ id: "pages.bannerList.grid.column.active_from" }),
                defaultWidth: Constants.columnWidth.dateTime,
                render: ({ data }: { data: BannerListItem }) => {
                    return <div>{data.active_from ? DateUtils.format(data.active_from, DateFormat.minuteDateTime) : ""}</div>;
                },
            },
            {
                name: "active_to",
                filterEditor: DateFilter,
                header: I18n.formatMessage({ id: "pages.bannerList.grid.column.active_to" }),
                defaultWidth: Constants.columnWidth.dateTime,
                render: ({ data }: { data: BannerListItem }) => {
                    return <div>{data.active_to ? DateUtils.format(data.active_to, DateFormat.minuteDateTime) : ""}</div>;
                },
            },
        ];

        if (!this.props.onlyActive) {
            columns.push({
                name: "is_active",
                textAlign: "center",
                header: I18n.formatMessage({ id: "pages.bannerList.grid.column.is_active" }),
                defaultWidth: Constants.columnWidth.statusIcon,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: [
                        { id: "true", label: I18n.formatMessage({ id: "common.form.isActive.true" }).toLowerCase() },
                        { id: "false", label: I18n.formatMessage({ id: "common.form.isActive.false" }).toLowerCase() },
                    ],
                },
                render: ({ data }: { data: BannerListItem }) => <IsActiveIcon value={data.is_active} />,
            });
        }

        return columns;
    };

    private filterValues = (): FilterValue[] => {
        const filterValues = [
            { name: "id", operator: "eq", type: "number" },
            { name: "name", operator: "contains", type: "string" },
            { name: "target_url", operator: "contains", type: "string" },
            { name: "type", operator: "eq", type: "select" },
            { name: "active_from", operator: "inrange", type: "date" },
            { name: "active_to", operator: "inrange", type: "date" },
            { name: "is_active", operator: "eq", type: "select" },
        ];
        if (!this.props.isSingleSelect) {
            filterValues.push({ name: "selected", operator: "eq", type: "select" });
        }

        return filterValues;
    };

    private urlQueryParser = new DataGridUrlQueryParser({
        filters: {
            id: QueryParserType.integer,
            name: QueryParserType.string,
            target_url: QueryParserType.string,
            type: QueryParserType.enum,
            active_from: QueryParserType.dateRange,
            active_to: QueryParserType.dateRange,
            is_active: QueryParserType.boolean,
        },
        sortField: BannerSortField,
    });

    private sortFieldToSortOrder = {
        [BannerSortField.id]: "id",
        [BannerSortField.name]: "name",
        [BannerSortField.type]: "type",
        [BannerSortField.target_url]: "target_url",
        [BannerSortField.active_from]: "active_from",
        [BannerSortField.active_to]: "active_to",
        [BannerSortField.is_active]: "is_active",
    };

    private data: listBanners_listBanners_data[] = [];

    private dataSource = async (props: DataGridParams<BannerSort, BannerFilters>): Promise<DataSource<listBanners_listBanners_data>> => {
        try {
            const filters: BannerFilters = {
                ...props.filters,
                id: props.filters?.id,
                is_active: this.props.onlyActive ?? props.filters?.is_active,
            };
            const result = await Api.listBanners({
                first: props.limit,
                page: props.page,
                filters,
                sortBy: props.sortBy,
            });
            this.data = result.data;
            return { data: result.data, count: result.paginatorInfo.total };
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }
        return { data: [], count: 0 };
    };

    private getRowLink = (rowProps: TypeRowProps): string => {
        return Path.bannerEdit(rowProps.data.id);
    };

    render() {
        return (
            <DataGrid
                className={this.props.classes.dataGrid}
                urlQueryParser={this.urlQueryParser}
                rowHeight={50}
                style={{ minHeight: this.props.minWidth ?? 800 }}
                dataSource={this.dataSource}
                filterValues={this.filterValues()}
                columns={this.getColumns()}
                rowLink={this.props.disableRowClick ? undefined : this.getRowLink}
                activeCell={null}
                sortFieldToSortOrder={this.sortFieldToSortOrder}
                onRowClick={!this.props.disableRowClick && (this.props.isSuperadmin || this.props.permissions.includes(PermissionType.banner_update)) ? undefined : () => {}}
            />
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { permissions: AuthSelectors.getPermissions(state.auth), isSuperadmin: AuthSelectors.isSuperadmin(state.auth) };
};

export default withSnackbar(connect(mapStateToProps)(withStyles(dataGridStyles, { withTheme: true })(BannerListDataGrid)));
