import React, { useCallback } from "react";

import { Field, FieldProps, FormikProps } from "formik";
import { useHistory } from "react-router-dom";

import { FullscreenLoader, PageCard, SavePanel } from "@bigfish/admin-ui/components";
import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, Icon, Radio, TextField } from "@bigfish/admin-ui/core";

import { CalendarDay, CalendarDayInput } from "Api/graphql/admin/types";
import { CustomFormHelperText } from "Components/CustomFormHelperText";
import { Form, FormType } from "Components/Form";
import { I18n } from "Src/i18n/I18n";
import { Path } from "Utils/Path";
import { I18nHelpers } from "I18n/I18nHelpers";
import { Validator } from "Utils/Validator";

export type CalendarDayFormValues = CalendarDayInput & { date: string; is_date_changed?: boolean };

type Props = {
    formType: FormType;
    formProps: FormikProps<CalendarDayFormValues>;
    calendarDay?: CalendarDay;
};

export const CalendarDayForm = (props: Props) => {
    const history = useHistory();

    const onCancelClick = useCallback(() => {
        history.push(Path.calendarDaysList);
    }, [history]);

    return (
        <Form formProps={props.formProps}>
            <PageCard.Container>
                <Box mt="15px" />
                <Field name="date" validate={I18nHelpers.formatValidator(Validator.required)}>
                    {({ field, meta }: FieldProps) => (
                        <TextField
                            id="calendarday-date"
                            label={I18n.formatMessage({ id: "pages.calendarDay.form.dateLabel" })}
                            type="datetime-local"
                            variant="outlined"
                            helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            {...field}
                            value={field.value}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                field.onChange(e);
                                props.formProps.setFieldValue("is_date_changed", true);
                            }}
                            error={!!meta.error}
                        />
                    )}
                </Field>
                <Box mt="30px" />
                <FormControlLabel
                    checked={props.formProps.values.is_workday === true}
                    onChange={() => {
                        props.formProps.setFieldValue("is_workday", true);
                    }}
                    control={<Radio />}
                    label={I18n.formatMessage({ id: "pages.calendarDay.form.workday" })}
                />
                <FormControlLabel
                    checked={props.formProps.values.is_workday === false}
                    onChange={() => {
                        props.formProps.setFieldValue("is_workday", false);
                    }}
                    control={<Radio />}
                    label={I18n.formatMessage({ id: "pages.calendarDay.form.holiday" })}
                />
                <Box mt="30px" />
                <Field name="is_delivery">
                    {({ field, meta }: FieldProps) =>
                        field.value !== undefined && (
                            <Box mt="15px">
                                <FormControl error={meta.touched && !!meta.error}>
                                    <FormControlLabel
                                        control={<Checkbox {...field} checked={field.value} />}
                                        label={I18n.formatMessage({ id: "pages.calendarDay.form.isDeliveryLabel" })}
                                    />
                                </FormControl>
                                <CustomFormHelperText meta={meta} />
                            </Box>
                        )
                    }
                </Field>
            </PageCard.Container>

            <SavePanel>
                <Grid container justify="space-between">
                    <Button variant="outlined" color="primary" onClick={onCancelClick}>
                        {I18n.formatMessage({ id: "common.cancel" })}
                    </Button>
                    <Button
                        type="submit"
                        startIcon={<Icon className="fas fa-save" />}
                        variant="contained"
                        color="secondary"
                        disabled={!props.formProps.dirty || !props.formProps.isValid}
                    >
                        {I18n.formatMessage({ id: `common.${props.formType === FormType.create ? "create" : "save"}` })}
                    </Button>
                </Grid>
            </SavePanel>
            <FullscreenLoader visible={props.formProps.isSubmitting} />
        </Form>
    );
};
