import { gql } from "@apollo/client";
import { productListFragment } from "../fragments/productListFragment";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";

export const getProductListByIdQuery = gql`
    query getProductListById($filters: ProductListFilters, $sortBy: ProductListSort, $first: Int!, $page: Int) {
        listProductLists(first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            data {
                ...ProductList
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${productListFragment}
    ${paginatorInfoFragment}
`;
