import React from "react";
import { Form, FormType } from "Components/Form";
import {
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    Icon,
    TextField,
    Typography,
    Box,
    IconButton,
    makeStyles,
    InputLabel,
    Select,
    OutlinedInput,
    Chip,
    MenuItem,
    FormHelperText,
} from "@bigfish/admin-ui/core";
import { Field, FieldProps, FormikErrors, FormikProps } from "formik";
import {
    DeliveryInformationInput,
    RossmannPlusVipLevel,
    ShippingCostInput,
    ShippingMethod,
    ShippingMethodInput,
    ShippingMethodRoleInput,
    TimeWindowCostInput,
} from "Api/graphql/admin/types";
import { Checkbox } from "@bigfish/admin-ui/core";
import { I18n } from "Src/i18n/I18n";
import { FullscreenLoader, PageCard, SavePanel } from "@bigfish/admin-ui/components";
import { Constants, ShippingMethodId } from "Utils/Constants";
import { I18nHelpers } from "I18n/I18nHelpers";
import { Validator } from "Utils/Validator";
import { CustomFormHelperText } from "Components/CustomFormHelperText";
import RichTextEditor from "Components/Inputs/RichTextEditor/RichTextEditor";
import { NumberUtils } from "Utils/NumberUtils";
import { Close } from "@bigfish/admin-ui/icons";

export type ShippingMethodFormValues = Omit<ShippingMethodInput, "cost_time_windows"> & {
    id: string;
    price: number | null;
    cost_time_windows: TimeWindowCostInput[];
    is_appearance_active: boolean;
    roles: ShippingMethodRoleInput[];
};

type Props = {
    formType: FormType;
    formProps: FormikProps<ShippingMethodFormValues>;
    shippingMethod?: ShippingMethod;
};

export const shippingMethodValidator = (formType: FormType) => (values: ShippingMethodFormValues): FormikErrors<ShippingMethodFormValues> => {
    const errors: { [key in keyof ShippingMethodFormValues]?: any } = {};

    if (!errors.active_from && formType === FormType.create) {
        errors.active_from = I18nHelpers.formatValidator(Validator.startDatePast)(values.active_from);
    }

    if (values.active_from && values.active_to) {
        errors.active_to = Validator.endDateCantBeBeforeStart(values.active_from, values.active_to);
        errors.active_from = errors.active_from || Validator.startDateCantBeAfterEnd(values.active_from, values.active_to);
    }

    if (values.id === ShippingMethodId.mpl_delivery) {
        // Időablak nélkül
        if (values.cost?.length > 0 && values.cost.every(c => c.cart_limit !== 0)) {
            if (values.cost?.length > 0) {
                errors.cost = values.cost.map(() => {
                    return {
                        cart_limit: I18n.formatMessage({ id: "pages.shippingMethod.form.cartLimitMustHaveZero" }),
                    };
                });
            }
        }

        // Idősávok
        if (values.cost_time_windows?.length > 0) {
            errors.cost_time_windows = [];
            let isCostTimeWindowsValid = true;
            for (let i = 0; i < values.cost_time_windows.length; i++) {
                if (values.cost_time_windows[i].cost?.length > 0 && values.cost_time_windows[i].cost.every(c => c.cart_limit !== 0)) {
                    if (values.cost_time_windows[i].cost?.length > 0) {
                        isCostTimeWindowsValid = false;
                        errors.cost_time_windows[i] = {};
                        errors.cost_time_windows[i].cost = values.cost_time_windows[i].cost.map(c => {
                            return {
                                cart_limit: I18n.formatMessage({ id: "pages.shippingMethod.form.cartLimitMustHaveZero" }),
                            };
                        });
                    }
                }
            }
            if (isCostTimeWindowsValid) {
                errors.cost_time_windows = undefined;
            }
        }
    }

    return Form.cleanupFormikErrors(errors);
};

export const ShippingMethodForm = (props: Props) => {
    const classes = useStyles();

    const convertVipLevel = (level: RossmannPlusVipLevel) => {
        switch (level) {
            case RossmannPlusVipLevel.GOLD:
                return I18n.formatMessage({ id: "common.vipLevel.gold" });
            case RossmannPlusVipLevel.SILVER:
                return I18n.formatMessage({ id: "common.vipLevel.silver" });
            case RossmannPlusVipLevel.BRONZE:
                return I18n.formatMessage({ id: "common.vipLevel.bronze" });
            default:
                return I18n.formatMessage({ id: "common.vipLevel.basic" });
        }
    };

    const handleRolesChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>, roleIndex: number) => {
        const { value } = event.target;
        props.formProps.setFieldValue(`roles[${roleIndex}].vip_levels`, typeof value === "string" ? value.split(",") : value);
        props.formProps.setFieldTouched(`roles[${roleIndex}].vip_levels`, true);
    };

    const renderCostTerminalSection = () => {
        return (
            <div>
                <PageCard.Heading title={I18n.formatMessage({ id: "pages.shippingMethod.form.costs.terminal" })} />

                {(props.formProps.values.cost_terminal ?? []).map((cost: ShippingCostInput, costIndex: number) => {
                    return (
                        <Grid key={`cost-terminal-${costIndex}`} container spacing={3}>
                            <Grid item xs={5}>
                                <Field name={`cost_terminal[${costIndex}].cart_limit`} validate={I18nHelpers.formatValidator(Validator.mustNotBeNil)}>
                                    {({ field, meta }: FieldProps) => (
                                        <TextField
                                            type="number"
                                            label={I18n.formatMessage({ id: "pages.shippingMethod.form.mplTimeWindow.cartLimitLabel" })}
                                            fullWidth
                                            variant="outlined"
                                            required
                                            helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                                            error={meta.touched && !!meta.error}
                                            {...field}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                if (NumberUtils.isStringNumber(e.currentTarget.value)) {
                                                    field.onChange(e);
                                                }
                                            }}
                                        />
                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={5}>
                                <Field name={`cost_terminal[${costIndex}].cost`} validate={I18nHelpers.formatValidator(Validator.mustNotBeNil)}>
                                    {({ field, meta }: FieldProps) => (
                                        <TextField
                                            type="number"
                                            label={I18n.formatMessage({ id: "pages.shippingMethod.form.mplTimeWindow.costLabel" })}
                                            fullWidth
                                            variant="outlined"
                                            required
                                            helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                                            error={meta.touched && !!meta.error}
                                            {...field}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                if (NumberUtils.isStringNumber(e.currentTarget.value)) {
                                                    field.onChange(e);
                                                }
                                            }}
                                        />
                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={2}>
                                <IconButton
                                    onClick={() => {
                                        const costTerminal = [...(props.formProps.values.cost_terminal ?? [])];
                                        costTerminal.splice(costIndex, 1);
                                        props.formProps.setFieldValue("cost_terminal", costTerminal, false);
                                    }}
                                >
                                    <Close />
                                </IconButton>
                            </Grid>
                        </Grid>
                    );
                })}

                <Box mt="15px" />

                <Button
                    startIcon={<Icon className="fa fa-plus-circle" />}
                    variant="outlined"
                    size="medium"
                    color="secondary"
                    onClick={() => {
                        props.formProps.setFieldValue("cost_terminal", [...(props.formProps.values.cost_terminal ?? []), { cart_limit: 0, cost: 0 }]);
                    }}
                >
                    {I18n.formatMessage({ id: "pages.shippingMethod.form.mplTimeWindow.addWindowButton" })}
                </Button>
                <Box mt="30px" />
            </div>
        );
    };

    const renderTimeWindowSection = (shippingMethodId: ShippingMethodId) => {
        return (
            <div>
                <PageCard.Heading
                    title={I18n.formatMessage({ id: `pages.shippingMethod.form.${shippingMethodId === ShippingMethodId.mpl_delivery ? "noTimeWindow" : "costs"}` })}
                />

                {props.formProps.values.cost.map((cost: ShippingCostInput, costIndex: number) => {
                    return (
                        <Grid key={`cost-${costIndex}`} container spacing={3}>
                            <Grid item xs={5}>
                                <Field name={`cost[${costIndex}].cart_limit`} validate={I18nHelpers.formatValidator(Validator.mustNotBeNil)}>
                                    {({ field, meta }: FieldProps) => (
                                        <TextField
                                            type="number"
                                            label={I18n.formatMessage({ id: "pages.shippingMethod.form.mplTimeWindow.cartLimitLabel" })}
                                            fullWidth
                                            variant="outlined"
                                            required
                                            helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                                            error={meta.touched && !!meta.error}
                                            {...field}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                if (NumberUtils.isStringNumber(e.currentTarget.value)) {
                                                    field.onChange(e);
                                                }
                                            }}
                                        />
                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={5}>
                                <Field name={`cost[${costIndex}].cost`} validate={I18nHelpers.formatValidator(Validator.mustNotBeNil)}>
                                    {({ field, meta }: FieldProps) => (
                                        <TextField
                                            type="number"
                                            label={I18n.formatMessage({ id: "pages.shippingMethod.form.mplTimeWindow.costLabel" })}
                                            fullWidth
                                            variant="outlined"
                                            required
                                            helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                                            error={meta.touched && !!meta.error}
                                            {...field}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                if (NumberUtils.isStringNumber(e.currentTarget.value)) {
                                                    field.onChange(e);
                                                }
                                            }}
                                        />
                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={2}>
                                <IconButton
                                    onClick={() => {
                                        const cost = [...props.formProps.values.cost];
                                        cost.splice(costIndex, 1);
                                        props.formProps.setFieldValue("cost", cost, false);
                                    }}
                                >
                                    <Close />
                                </IconButton>
                            </Grid>
                        </Grid>
                    );
                })}

                <Box mt="15px" />

                <Button
                    startIcon={<Icon className="fa fa-plus-circle" />}
                    variant="outlined"
                    size="medium"
                    color="secondary"
                    onClick={() => {
                        props.formProps.setFieldValue("cost", [...props.formProps.values.cost, { cart_limit: 0, cost: 0 }]);
                        props.formProps.setFieldTouched("mplTimeWindows", true);
                    }}
                >
                    {I18n.formatMessage({ id: "pages.shippingMethod.form.mplTimeWindow.addWindowButton" })}
                </Button>
                <Box mt="30px" />

                {shippingMethodId === ShippingMethodId.mpl_delivery && (
                    <div>
                        <Field name="delivery_information" validate={I18nHelpers.formatValidator(Validator.required)}>
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "pages.shippingMethod.form.deliveryInformationLabel" })}
                                    fullWidth
                                    variant="outlined"
                                    required
                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "pages.shippingMethod.form.deliveryInformationHelperText" }))}
                                    error={meta.touched && !!meta.error}
                                    {...field}
                                />
                            )}
                        </Field>

                        <Box mt="30px" />

                        {renderDeliveryInformationsSection()}

                        <Box mt="30px" />

                        {props.formProps.values.cost_time_windows?.map((w: TimeWindowCostInput, windowIndex: number) => {
                            return (
                                <div key={`${windowIndex}-${w.interval_hours}`}>
                                    <div>
                                        <PageCard.Heading title={I18n.formatMessage({ id: "pages.shippingMethod.form.mplTimeWindow.intervalHour" }, { hour: w.interval_hours })} />

                                        <Box mt="30px" />

                                        {w.cost.map((cost: ShippingCostInput, costIndex: number) => {
                                            return (
                                                <Grid key={`mplTimeWindow-${costIndex}`} container spacing={3}>
                                                    <Grid item xs={5}>
                                                        <Field
                                                            name={`cost_time_windows[${windowIndex}].cost[${costIndex}].cart_limit`}
                                                            validate={I18nHelpers.formatValidator(Validator.mustNotBeNil)}
                                                        >
                                                            {({ field, meta }: FieldProps) => (
                                                                <TextField
                                                                    type="number"
                                                                    label={I18n.formatMessage({ id: "pages.shippingMethod.form.mplTimeWindow.cartLimitLabel" })}
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    required
                                                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                                                                    error={meta.touched && !!meta.error}
                                                                    {...field}
                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                        if (NumberUtils.isStringNumber(e.currentTarget.value)) {
                                                                            field.onChange(e);
                                                                        }
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <Field
                                                            name={`cost_time_windows[${windowIndex}].cost[${costIndex}].cost`}
                                                            validate={I18nHelpers.formatValidator(Validator.mustNotBeNil)}
                                                        >
                                                            {({ field, meta }: FieldProps) => (
                                                                <TextField
                                                                    type="number"
                                                                    label={I18n.formatMessage({ id: "pages.shippingMethod.form.mplTimeWindow.costLabel" })}
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    required
                                                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                                                                    error={meta.touched && !!meta.error}
                                                                    {...field}
                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                        if (NumberUtils.isStringNumber(e.currentTarget.value)) {
                                                                            field.onChange(e);
                                                                        }
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <IconButton
                                                            onClick={() => {
                                                                const cost = [...props.formProps.values.cost_time_windows[windowIndex].cost];
                                                                cost.splice(costIndex, 1);
                                                                const newWindows = [...props.formProps.values.cost_time_windows];
                                                                newWindows[windowIndex] = { ...newWindows[windowIndex], cost };
                                                                props.formProps.setFieldValue("cost_time_windows", newWindows, false);
                                                            }}
                                                        >
                                                            <Close />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            );
                                        })}
                                    </div>

                                    <Box mt="15px" />

                                    <Button
                                        startIcon={<Icon className="fa fa-plus-circle" />}
                                        variant="outlined"
                                        size="medium"
                                        color="secondary"
                                        onClick={() => {
                                            const newWindows = [...props.formProps.values.cost_time_windows];
                                            newWindows[windowIndex] = {
                                                ...newWindows[windowIndex],
                                                cost: [...newWindows[windowIndex].cost, { cart_limit: 0, cost: 0 }],
                                            };
                                            props.formProps.setFieldValue("cost_time_windows", newWindows);
                                            props.formProps.setFieldTouched("cost_time_windows", true);
                                        }}
                                    >
                                        {I18n.formatMessage({ id: "pages.shippingMethod.form.mplTimeWindow.addWindowButton" })}
                                    </Button>
                                    <Box mt="30px" />
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        );
    };

    const renderDeliveryInformationsSection = () => {
        return (
            <div>
                {(props.formProps.values.delivery_informations ?? []).map((di: DeliveryInformationInput, index: number) => {
                    return (
                        <Box mb="30px" key={`delivery-information-${index}`}>
                            <Grid container>
                                <Grid item>
                                    <Field name={`delivery_informations[${index}].active_from`}>
                                        {({ field, meta }: FieldProps) => (
                                            <TextField
                                                className={classes.dateField}
                                                id={`delivery_informations[${index}].active_from`}
                                                label={I18n.formatMessage({ id: "pages.shippingMethod.form.active_from.label" })}
                                                type="datetime-local"
                                                variant="outlined"
                                                helperText={meta.error}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    max: `props.formProps.values.delivery_informations[${index}].active_to` || undefined,
                                                }}
                                                {...field}
                                                value={field.value}
                                                error={!!meta.error}
                                            />
                                        )}
                                    </Field>
                                </Grid>

                                <Grid item>
                                    <Field name={`delivery_informations[${index}].active_to`}>
                                        {({ field, meta }: FieldProps) => (
                                            <TextField
                                                id={`delivery_informations[${index}].active_to`}
                                                label={I18n.formatMessage({ id: "pages.shippingMethod.form.active_to.label" })}
                                                type="datetime-local"
                                                variant="outlined"
                                                helperText={meta.error}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    max: `props.formProps.values.delivery_informations[${index}].active_from` || undefined,
                                                }}
                                                {...field}
                                                value={field.value}
                                                error={!!meta.error}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                            </Grid>

                            <Box mt="30px" />

                            <Grid container spacing={3}>
                                <Grid item xs={9}>
                                    <Field name={`delivery_informations[${index}].info`} validate={I18nHelpers.formatValidator(Validator.required)}>
                                        {({ field, meta }: FieldProps) => (
                                            <TextField
                                                type="text"
                                                label={I18n.formatMessage({ id: "pages.shippingMethod.form.deliveryInformationLabel" })}
                                                fullWidth
                                                variant="outlined"
                                                required
                                                helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                                                error={meta.touched && !!meta.error}
                                                {...field}
                                            />
                                        )}
                                    </Field>
                                </Grid>

                                <Grid item xs={1}>
                                    <IconButton
                                        onClick={() => {
                                            const delivery_informations = [...(props.formProps.values.delivery_informations ?? [])];
                                            delivery_informations.splice(index, 1);
                                            props.formProps.setFieldValue("delivery_informations", delivery_informations, false);
                                        }}
                                    >
                                        <Close />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Box>
                    );
                })}

                <Box mt="15px" />

                <Button
                    startIcon={<Icon className="fa fa-plus-circle" />}
                    variant="outlined"
                    size="medium"
                    color="secondary"
                    onClick={() => {
                        props.formProps.setFieldValue("delivery_informations", [
                            ...(props.formProps.values.delivery_informations ?? []),
                            { active_from: null, active_to: null, info: "" },
                        ]);
                        props.formProps.setFieldTouched("delivery_informations", true);
                    }}
                >
                    {I18n.formatMessage({ id: "pages.shippingMethod.form.deliveryInformations.addNew" })}
                </Button>

                <Box mt="15px" />
            </div>
        );
    };

    const renderAppearanceSection = () => {
        return (
            <div>
                {props.formProps.values.roles.map((role: ShippingMethodRoleInput, roleIndex: number) => {
                    return (
                        <Box mb="30px" key={`role-${roleIndex}`}>
                            <Grid container>
                                <Grid item>
                                    <Field name={`roles[${roleIndex}].active_from`}>
                                        {({ field, meta }: FieldProps) => (
                                            <TextField
                                                className={classes.dateField}
                                                id={`roles[${roleIndex}].active_from`}
                                                label={I18n.formatMessage({ id: "pages.shippingMethod.form.active_from.label" })}
                                                type="datetime-local"
                                                variant="outlined"
                                                helperText={meta.error}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    max: `props.formProps.values.roles[${roleIndex}].active_to` || undefined,
                                                }}
                                                {...field}
                                                value={field.value}
                                                error={!!meta.error}
                                            />
                                        )}
                                    </Field>
                                </Grid>

                                <Grid item>
                                    <Field name={`roles[${roleIndex}].active_to`}>
                                        {({ field, meta }: FieldProps) => (
                                            <TextField
                                                id={`roles[${roleIndex}].active_to`}
                                                label={I18n.formatMessage({ id: "pages.shippingMethod.form.active_to.label" })}
                                                type="datetime-local"
                                                variant="outlined"
                                                helperText={meta.error}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    max: `props.formProps.values.roles[${roleIndex}].active_from` || undefined,
                                                }}
                                                {...field}
                                                value={field.value}
                                                error={!!meta.error}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                            </Grid>

                            <Box mt="30px" />

                            <Grid container spacing={3}>
                                <Grid item xs={9}>
                                    <Field name={`roles[${roleIndex}].vip_levels`} validate={I18nHelpers.formatValidator(Validator.permissionLength)}>
                                        {({ field, meta }: FieldProps) => (
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel>{I18n.formatMessage({ id: "pages.shippingMethod.form.vipLevels" })}</InputLabel>
                                                <Select
                                                    labelId="multiselect-input"
                                                    id="multiselect-input"
                                                    multiple
                                                    input={<OutlinedInput id="multiselect-input" label={I18n.formatMessage({ id: "pages.shippingMethod.form.vipLevels" })} />}
                                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                    // @ts-ignore
                                                    renderValue={(selected: RossmannPlusVipLevel[]) => {
                                                        return (
                                                            <Box>
                                                                {selected.map((value: RossmannPlusVipLevel, i: number) => (
                                                                    <Chip
                                                                        className="multiselect-chip"
                                                                        key={value}
                                                                        label={convertVipLevel(value)}
                                                                        deleteIcon={
                                                                            <div
                                                                                className="multiselect-delete-icon"
                                                                                onMouseDown={event => {
                                                                                    event.stopPropagation();
                                                                                    props.formProps.setFieldValue(
                                                                                        `roles[${roleIndex}].vip_levels`,
                                                                                        props.formProps.values.roles[roleIndex].vip_levels?.filter(l => l !== value)
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <i className="fas fa-times-circle"></i>
                                                                            </div>
                                                                        }
                                                                        onDelete={() => {}}
                                                                        clickable={false}
                                                                    />
                                                                ))}
                                                            </Box>
                                                        );
                                                    }}
                                                    error={meta.touched && !!meta.error}
                                                    {...field}
                                                    onChange={e => handleRolesChange(e, roleIndex)}
                                                >
                                                    <MenuItem value={RossmannPlusVipLevel.BASIC}>{I18n.formatMessage({ id: "common.vipLevel.basic" })}</MenuItem>
                                                    <MenuItem value={RossmannPlusVipLevel.BRONZE}>{I18n.formatMessage({ id: "common.vipLevel.bronze" })}</MenuItem>
                                                    <MenuItem value={RossmannPlusVipLevel.SILVER}>{I18n.formatMessage({ id: "common.vipLevel.silver" })}</MenuItem>
                                                    <MenuItem value={RossmannPlusVipLevel.GOLD}>{I18n.formatMessage({ id: "common.vipLevel.gold" })}</MenuItem>
                                                </Select>
                                                <FormHelperText className={meta.touched && !!meta.error ? "custom-error-helper" : "helper-text-nowrap"}>
                                                    {Form.getHelperText(meta, I18n.formatMessage({ id: "pages.shippingMethod.form.vipLevelHint" }))}
                                                </FormHelperText>
                                            </FormControl>
                                        )}
                                    </Field>
                                </Grid>

                                <Grid item xs={1}>
                                    <IconButton
                                        onClick={() => {
                                            const roles = [...props.formProps.values.roles];
                                            roles.splice(roleIndex, 1);
                                            props.formProps.setFieldValue("roles", roles, false);
                                        }}
                                    >
                                        <Close />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Box>
                    );
                })}

                <Box mt="15px" />

                <Button
                    startIcon={<Icon className="fa fa-plus-circle" />}
                    variant="outlined"
                    size="medium"
                    color="secondary"
                    onClick={() => {
                        props.formProps.setFieldValue("roles", [...props.formProps.values.roles, { active_from: null, active_to: null, vip_levels: [] }]);
                        props.formProps.setFieldTouched("roles", true);
                    }}
                >
                    {I18n.formatMessage({ id: "pages.shippingMethod.form.vipLevels.addNew" })}
                </Button>

                <Box mt="15px" />
            </div>
        );
    };

    const renderUniqueFields = (): JSX.Element | null => {
        const descriptionField = (
            <>
                <Box mt="30px" mb="13px">
                    <Typography variant="body1" component="h3" color="textSecondary">
                        {I18n.formatMessage({ id: "pages.shippingMethod.form.descriptionLabel" })}
                    </Typography>
                </Box>

                <Field name="description">
                    {({ field }: FieldProps) => (
                        <div>
                            <RichTextEditor
                                config="shippingMethodDescription"
                                {...field}
                                value={props.formProps.values.description ?? ""}
                                onChange={(content: string) => {
                                    props.formProps.setFieldValue("description", content || null);
                                    props.formProps.setFieldTouched("description", true);
                                }}
                            />
                        </div>
                    )}
                </Field>
            </>
        );

        const weightMinField = (
            <Field name="weight_min">
                {({ field, meta }: FieldProps) => (
                    <TextField
                        type="number"
                        label={I18n.formatMessage({ id: "pages.shippingMethod.form.weightMinLabel" })}
                        fullWidth
                        variant="outlined"
                        required
                        helperText={Form.getHelperText(meta, "")}
                        error={meta.touched && !!meta.error}
                        {...field}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (NumberUtils.isStringNumber(e.currentTarget.value)) {
                                if (e.currentTarget.value === "") {
                                    props.formProps.setFieldValue("weight_min", null);
                                    props.formProps.setFieldTouched("weight_min", true);
                                } else {
                                    field.onChange(e);
                                }
                            }
                        }}
                    />
                )}
            </Field>
        );

        const weightMaxField = (
            <Field name="weight_max">
                {({ field, meta }: FieldProps) => (
                    <TextField
                        type="number"
                        label={I18n.formatMessage({ id: "pages.shippingMethod.form.weightMaxLabel" })}
                        fullWidth
                        variant="outlined"
                        required
                        helperText={Form.getHelperText(meta, "")}
                        error={meta.touched && !!meta.error}
                        {...field}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (NumberUtils.isStringNumber(e.currentTarget.value)) {
                                if (e.currentTarget.value === "") {
                                    props.formProps.setFieldValue("weight_max", null);
                                    props.formProps.setFieldTouched("weight_max", true);
                                } else {
                                    field.onChange(e);
                                }
                            }
                        }}
                    />
                )}
            </Field>
        );

        const orderMaxField = (
            <Field name="order_max">
                {({ field, meta }: FieldProps) => (
                    <TextField
                        type="number"
                        label={I18n.formatMessage({ id: "pages.shippingMethod.form.orderMaxLabel" })}
                        fullWidth
                        variant="outlined"
                        required
                        helperText={Form.getHelperText(meta, "")}
                        error={meta.touched && !!meta.error}
                        {...field}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (NumberUtils.isStringNumber(e.currentTarget.value)) {
                                if (e.currentTarget.value === "") {
                                    props.formProps.setFieldValue("order_max", null);
                                    props.formProps.setFieldTouched("order_max", true);
                                } else {
                                    field.onChange(e);
                                }
                            }
                        }}
                    />
                )}
            </Field>
        );

        const isActiveField = (
            <>
                <Typography variant="body1" component="h3" color="textSecondary">
                    {I18n.formatMessage({ id: "pages.shippingMethod.form.statusLabel" })}
                </Typography>

                <Field name="is_active">
                    {({ field, meta }: FieldProps) =>
                        field.value !== undefined && (
                            <Box mt="15px" ml="10px">
                                <FormControl error={meta.touched && !!meta.error}>
                                    <FormControlLabel
                                        control={<Checkbox {...field} checked={field.value} />}
                                        label={I18n.formatMessage({ id: "pages.shippingMethod.form.isActiveLabel" })}
                                    />
                                </FormControl>
                                <CustomFormHelperText meta={meta} />
                            </Box>
                        )
                    }
                </Field>
            </>
        );

        const deliveryInformationField = (
            <Field name="delivery_information" validate={I18nHelpers.formatValidator(Validator.required)}>
                {({ field, meta }: FieldProps) => (
                    <TextField
                        type="text"
                        label={I18n.formatMessage({ id: "pages.shippingMethod.form.deliveryInformationLabel" })}
                        fullWidth
                        variant="outlined"
                        required
                        helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "pages.shippingMethod.form.deliveryInformationHelperText" }))}
                        error={meta.touched && !!meta.error}
                        {...field}
                    />
                )}
            </Field>
        );

        switch (props.shippingMethod?.id) {
            case ShippingMethodId.gls_delivery:
                return (
                    <div>
                        {descriptionField}

                        <Box mt="30px" />

                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                {weightMinField}
                            </Grid>
                            <Grid item xs={6}>
                                {weightMaxField}
                            </Grid>
                        </Grid>

                        <Box mt="30px" />

                        {orderMaxField}

                        <Box mt="30px" />

                        {isActiveField}

                        <Box mt="30px" />

                        {deliveryInformationField}

                        <Box mt="30px" />

                        {renderTimeWindowSection(ShippingMethodId.gls_delivery)}
                    </div>
                );
            case ShippingMethodId.rossmann_delivery:
                return (
                    <div>
                        {descriptionField}
                        <Box mt="30px" />
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <Field name="cart_value_min">
                                    {({ field, meta }: FieldProps) => (
                                        <TextField
                                            type="number"
                                            label={I18n.formatMessage({ id: "pages.shippingMethod.form.cartValueMinLabel" })}
                                            fullWidth
                                            variant="outlined"
                                            required
                                            helperText={Form.getHelperText(meta, "")}
                                            error={meta.touched && !!meta.error}
                                            {...field}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                if (NumberUtils.isStringNumber(e.currentTarget.value)) {
                                                    if (e.currentTarget.value === "") {
                                                        props.formProps.setFieldValue("cart_value_min", null);
                                                        props.formProps.setFieldTouched("cart_value_min", true);
                                                    } else {
                                                        field.onChange(e);
                                                    }
                                                }
                                            }}
                                        />
                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={4}>
                                {weightMinField}
                            </Grid>
                            <Grid item xs={4}>
                                {weightMaxField}
                            </Grid>
                        </Grid>

                        <Box mt="30px" />

                        {isActiveField}

                        <Box mt="30px" />

                        <Typography variant="body1" component="h3" color="textSecondary">
                            {I18n.formatMessage({ id: "pages.shippingMethod.form.appearanceLabel" })}
                        </Typography>

                        <Field name="is_appearance_active">
                            {({ field, meta }: FieldProps) =>
                                field.value !== undefined && (
                                    <Box mt="15px" ml="10px">
                                        <FormControl error={meta.touched && !!meta.error}>
                                            <FormControlLabel
                                                control={<Checkbox {...field} checked={field.value} />}
                                                label={I18n.formatMessage({ id: "pages.shippingMethod.form.isAppearanceActiveLabel" })}
                                            />
                                        </FormControl>
                                        <CustomFormHelperText meta={meta} />
                                    </Box>
                                )
                            }
                        </Field>

                        <Box mt="30px" />

                        {props.formProps.values.is_appearance_active && renderAppearanceSection()}
                    </div>
                );
            case ShippingMethodId.sameday_easybox:
            case ShippingMethodId.foxpost:
                return (
                    <div>
                        {descriptionField}

                        <Box mt="30px" />

                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                {weightMinField}
                            </Grid>
                            <Grid item xs={6}>
                                {weightMaxField}
                            </Grid>
                        </Grid>

                        <Box mt="30px" />

                        {orderMaxField}

                        <Box mt="30px" />

                        {isActiveField}

                        <Box mt="30px" />

                        {renderTimeWindowSection(ShippingMethodId.mpl_pickup)}
                    </div>
                );
            case ShippingMethodId.gls_pickup:
                return (
                    <div>
                        <Box mt="30px" />

                        {deliveryInformationField}

                        <Box mt="30px" />

                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                {weightMinField}
                            </Grid>
                            <Grid item xs={6}>
                                {weightMaxField}
                            </Grid>
                        </Grid>

                        <Box mt="30px" />

                        {isActiveField}

                        <Box mt="30px" />

                        {renderTimeWindowSection(props.shippingMethod?.id)}
                        {renderCostTerminalSection()}
                    </div>
                );
            case ShippingMethodId.mpl_pickup:
                return (
                    <div>
                        {descriptionField}

                        <Box mt="30px" />

                        {deliveryInformationField}

                        <Box mt="30px" />

                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                {weightMinField}
                            </Grid>
                            <Grid item xs={6}>
                                {weightMaxField}
                            </Grid>
                        </Grid>

                        <Box mt="30px" />

                        {orderMaxField}

                        <Box mt="30px" />

                        {isActiveField}

                        <Box mt="30px" />

                        {renderTimeWindowSection(ShippingMethodId.mpl_pickup)}
                        {renderCostTerminalSection()}
                    </div>
                );
            case ShippingMethodId.mpl_delivery:
                return (
                    <div>
                        {descriptionField}

                        <Box mt="30px" />

                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                {weightMinField}
                            </Grid>
                            <Grid item xs={6}>
                                {weightMaxField}
                            </Grid>
                        </Grid>

                        <Box mt="30px" />

                        {orderMaxField}

                        <Box mt="30px" />

                        {isActiveField}

                        <Box mt="30px" />

                        {renderTimeWindowSection(ShippingMethodId.mpl_delivery)}
                    </div>
                );
            case ShippingMethodId.rossmann_shop:
                return (
                    <div>
                        {descriptionField}
                        <Box mt="30px" />
                        <Field name="price" validate={I18nHelpers.formatValidator(Validator.mustNotBeNil)}>
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    type="number"
                                    label={I18n.formatMessage({ id: "pages.shippingMethod.form.priceLabel" })}
                                    fullWidth
                                    variant="outlined"
                                    required
                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                                    error={meta.touched && !!meta.error}
                                    {...field}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        if (NumberUtils.isStringNumber(e.currentTarget.value)) {
                                            field.onChange(e);
                                        }
                                    }}
                                />
                            )}
                        </Field>

                        <Box mt="30px" />

                        <Field name="time_limit_next_day_departure" validate={I18nHelpers.formatValidator(Validator.required)}>
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "pages.shippingMethod.form.timeLimitNextDayDepartureLabel" })}
                                    fullWidth
                                    variant="outlined"
                                    required
                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "pages.shippingMethod.form.timeLimitNextDayDepartureHint" }))}
                                    error={meta.touched && !!meta.error}
                                    {...field}
                                />
                            )}
                        </Field>

                        <Box mt="30px" />

                        {isActiveField}
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <Form formProps={props.formProps}>
            <Field name="name" validate={I18nHelpers.formatValidator(Validator.required)}>
                {({ field, meta }: FieldProps) => (
                    <TextField
                        type="text"
                        label={I18n.formatMessage({ id: "pages.shippingMethod.form.nameLabel" })}
                        fullWidth
                        variant="outlined"
                        required
                        helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                        error={meta.touched && !!meta.error}
                        {...field}
                    />
                )}
            </Field>

            <Box mt="30px" />

            <Field name="lead" validate={I18nHelpers.formatValidator(Validator.shippingMethodLead)}>
                {({ field, meta }: FieldProps) => (
                    <TextField
                        type="text"
                        label={I18n.formatMessage({ id: "pages.shippingMethod.form.leadLabel" })}
                        multiline
                        fullWidth
                        variant="outlined"
                        required
                        error={meta.touched && !!meta.error}
                        helperText={Form.getHelperText(
                            meta,
                            I18n.formatMessage({ id: "common.required" }) + I18n.formatMessage({ id: "common.maxCharacter" }, { max: Constants.shippingMethodLeadMaxLength })
                        )}
                        inputProps={{
                            maxLength: Constants.shippingMethodLeadMaxLength,
                        }}
                        {...field}
                    />
                )}
            </Field>

            {renderUniqueFields()}

            <SavePanel>
                <Grid container justify="flex-end">
                    <Button
                        type="submit"
                        startIcon={<Icon className="fas fa-save" />}
                        variant="contained"
                        color="secondary"
                        disabled={!props.formProps.dirty || !props.formProps.isValid}
                    >
                        {I18n.formatMessage({ id: `common.${props.formType === FormType.create ? "create" : "save"}` })}
                    </Button>
                </Grid>
            </SavePanel>
            <FullscreenLoader visible={props.formProps.isSubmitting} />
        </Form>
    );
};

const useStyles = makeStyles({
    dateField: {
        marginRight: 30,
    },
});
