import { gql } from "@apollo/client";
import { productGratisListFragment } from "../fragments/productGratisListFragment";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";

export const listProductGratisQuery = gql`
    query listProductGratis($filters_base: [ProductFilterInput!], $filters: ProductFilters, $sortBy: ProductSort, $first: Int!, $page: Int) {
        listProductGratis(filters_base: $filters_base, first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            data {
                ...ProductGratisListItem
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${productGratisListFragment}
    ${paginatorInfoFragment}
`;
