import { gql } from "@apollo/client";
import { productCategoryChildFragment } from "../fragments/productCategoryChildFragment";

export const getProductCategoryChildrenQuery = gql`
    query getProductCategoryChildren($filters: ProductCategoryFilters, $sortBy: ProductCategorySort, $first: Int!) {
        listProductCategories(filters: $filters, sortBy: $sortBy, first: $first) {
            data {
                ...ProductCategoryChild
            }
        }
    }
    ${productCategoryChildFragment}
`;
