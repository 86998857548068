import { gql } from "@apollo/client";

export const campaignFragment = gql`
    fragment Campaign on Campaign {
        id
        name
        description
        target_url
        target_title
        image_foreground_desktop
        image_background_desktop
        image_foreground_mobile
        image_background_mobile
        active_from
        active_to
        is_active
        created_at
        updated_at
        created_by {
            id
            name
        }
        updated_by {
            id
            name
        }
    }
`;
