import { gql } from "@apollo/client";
import { orderFragment } from "../fragments/orderFragment";

export const updateOrderMutation = gql`
    mutation updateOrder($increment_id: Int!, $input: OrderInput!, $csrf: String) {
        updateOrder(increment_id: $increment_id, input: $input, csrf: $csrf) {
            ...Order
        }
    }
    ${orderFragment}
`;
