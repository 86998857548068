import React from "react";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { TitleBar } from "@bigfish/admin-ui/components";
import { PageLayout } from "Components/PageLayout";
import { I18n } from "I18n/I18n";

import RatingListDataGrid from "./RatingListDataGrid";

type Props = RouteComponentProps & WithSnackbarProps;

class RatingListPage extends React.Component<Props> {
    public render() {
        return (
            <PageLayout>
                <TitleBar title={I18n.formatMessage({ id: "pages.ratingList.title" })} />
                <RatingListDataGrid />
            </PageLayout>
        );
    }
}

export default withSnackbar(withRouter(RatingListPage));
