import { gql } from "@apollo/client";
import { redirectionFragment } from "../fragments/redirectionFragment";

export const getRedirectionQuery = gql`
    query getRedirection($id: Int) {
        redirection(id: $id) {
            ...Redirection
        }
    }
    ${redirectionFragment}
`;
