import { Box } from "@bigfish/admin-ui/core";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { ProductListForm, ProductListFormValues, productListValidator } from "./ProductListForm";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { PageLayout } from "Components/PageLayout";
import Prompt from "Components/Prompt";
import { TitleBar } from "@bigfish/admin-ui/components";
import { I18n } from "I18n/I18n";
import { ObjectUtils } from "Utils/ObjectUtils";
import { listProductBrandSelectItems_listProductBrands_data, listProductFilterParams_listProductFilterParams, ProductListMode, ProductListType } from "Api/graphql/admin/types";
import { DateFormat, DateUtils } from "Utils/DateUtils";

type RouteParams = {
    productListType?: string;
};

type Props = RouteComponentProps & WithSnackbarProps & RouteComponentProps<RouteParams>;

type State = {
    isCreateSucceed: boolean;
    productFilterParams: listProductFilterParams_listProductFilterParams[];
    productBrands: listProductBrandSelectItems_listProductBrands_data[];
};

class ProductListCreatePage extends React.Component<Props, State> {
    public readonly state: State = {
        isCreateSucceed: false,
        productFilterParams: [],
        productBrands: [],
    };

    public async componentDidMount(): Promise<void> {
        try {
            const productFilterParams = await Api.listProductFilterParams();
            const { data: productBrands } = await Api.listProductBrandSelectItems({ first: 9999 });
            this.setState({
                productFilterParams: [...productFilterParams].sort((a, b) => (a.name ?? "").localeCompare(b.name ?? "")),
                productBrands: [...productBrands].sort((a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : -1)),
            });
        } catch (error) {
            this.props.enqueueSnackbar(error.message, { variant: "error" });
            this.props.history.push(Path.productListList);
        }
    }

    private onSubmit = async (values: ProductListFormValues, formikHelpers: FormikHelpers<ProductListFormValues>, isRedirectHandled?: boolean): Promise<boolean> => {
        try {
            await Api.createProductList({
                type: values.type,
                mode: values.mode,
                name: values.name,
                title: values.title,
                subtitle: values.subtitle,
                slug: values.slug,
                is_active: values.is_active,
                active_from: values.active_from ? DateUtils.format(values.active_from, DateFormat.apiDateTime) : null,
                active_to: values.active_to ? DateUtils.format(values.active_to, DateFormat.apiDateTime) : null,
                description: values.description,
                image: values.image,
                image_title: values.image_title,
                image_mobile: values.image_mobile,
                image_mobile_title: values.image_mobile_title,
                og_title: values.og_title,
                og_description: values.og_description,
                og_image: values.og_image,
                group_by_category: values.group_by_category,
                filters: values.filters,
                products: values.products,
                badge_id: values.badge?.id ?? null,
            });
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.productList.create.onSubmit.success" }), { variant: "success" });
            if (!isRedirectHandled) {
                this.setState({ isCreateSucceed: true });
            }
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.productList.create.onSubmit.error" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
            return false;
        }
    };

    private isProductListTypeParamInvalid = (): boolean => {
        return (
            !this.props.match.params.productListType ||
            !ObjectUtils.enumAsArray<ProductListType>(ProductListType)
                .map(type => `${type}`)
                .includes(this.props.match.params.productListType.toLowerCase())
        );
    };

    public render() {
        if (this.state.isCreateSucceed || this.isProductListTypeParamInvalid()) {
            return <Redirect to={Path.productListList} />;
        }

        const initialValues: ProductListFormValues = {
            type: this.props.match.params.productListType as ProductListType,
            mode: ProductListMode.manual,
            name: "",
            title: "",
            subtitle: "",
            slug: "",
            is_active: false,
            active_from: null,
            active_to: null,
            description: "",
            image: "",
            image_mobile: "",
            og_image: "",
            products: [],
            filters: [],
            group_by_category: false,
            badge: null,
        };

        return (
            <PageLayout>
                <TitleBar title={I18n.formatMessage({ id: "pages.productList.add" })} />
                <Formik initialValues={initialValues} onSubmit={this.onSubmit} validate={productListValidator} validateOnBlur={false}>
                    {props => (
                        <>
                            <ProductListForm
                                formType={FormType.create}
                                productListType={this.props.match.params.productListType as ProductListType}
                                formProps={props}
                                productFilterParams={this.state.productFilterParams}
                                productBrands={this.state.productBrands}
                            />
                            <Prompt when={props.dirty} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props, true)} />
                        </>
                    )}
                </Formik>
                <Box mt="40px" />
            </PageLayout>
        );
    }
}

export default withSnackbar(withRouter(ProductListCreatePage));
