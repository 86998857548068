import { gql } from "@apollo/client";
import { productVariantFragment } from "../fragments/productVariantFragment";

export const getProductVariantByIdQuery = gql`
    query getProductVariantById($id: Int!) {
        product(id: $id) {
            ...ProductVariant
        }
    }
    ${productVariantFragment}
`;
