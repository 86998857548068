import React, { useState } from "react";
import { I18n } from "I18n/I18n";
import { Button, Typography } from "@bigfish/admin-ui/core";
import { createModal } from "Components/createModal";
import { Order } from "Api/graphql/admin/types";
import CorrectionDataGrid from "./CorrectionDataGrid";

const CorrectOrderItemsModal = createModal({ modalWidth: 1280 });

interface Props {
    order: Order;
    onClose: () => void;
    onSave: (selectedItemIds: CorrectionItem[]) => void;
    isLoading: boolean;
}

export type CorrectionItem = {
    id: number;
    quantity: number;
};

export const CorrectOrderItemsDialog = ({ order, onClose, onSave, isLoading }: Props) => {
    const [selectedItems, setSelectedItems] = useState<CorrectionItem[]>([]);

    const handleItemsChange = (items: CorrectionItem[]) => setSelectedItems(items);

    return (
        <CorrectOrderItemsModal
            title={I18n.formatMessage({ id: "components.correctOrderItemsDialog.title" })}
            leftButtonsComponent={<Typography variant="subtitle2">{I18n.formatMessage({ id: "common.selectedItems" }, { selectedItems: selectedItems.length })}</Typography>}
            rightButtonsComponent={
                <>
                    <Button variant="outlined" color="primary" onClick={onClose} style={{ marginRight: "20px" }} disabled={isLoading}>
                        {I18n.formatMessage({ id: "common.cancel" })}
                    </Button>
                    <Button variant="contained" color="primary" onClick={() => onSave(selectedItems)} disabled={isLoading}>
                        {I18n.formatMessage({ id: "common.save" })}
                    </Button>
                </>
            }
            open
            onClose={onClose}
        >
            <CorrectionDataGrid
                orderItems={order.items.map(i => {
                    return {
                        ...i,
                        product_name: i.product.name,
                        product_lfdnr: i.product.lfdnr,
                        ean: i.product.ean.join(", "),
                    };
                })}
                selectedOrderItems={selectedItems}
                onItemsChange={handleItemsChange}
            />
        </CorrectOrderItemsModal>
    );
};
