import React from "react";
import { connect, DispatchProp } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { batchActions } from "redux-batched-actions";
import { AuthActions } from "Redux/actions/authActions";
import { Path } from "Utils/Path";
import { Api } from "Api/Api";
import { Formik, FormikHelpers } from "formik";
import { ResetPasswordForm, resetPasswordFormValidator, ResetPasswordFormValues } from "./ResetPasswordForm";
import { Form } from "Components/Form";
import { ApiError } from "Api/ApiError";
import { Typography, Box } from "@bigfish/admin-ui/core";
import { CenteredCard, BrandLogo } from "@bigfish/admin-ui/components";
import { I18n } from "Src/i18n/I18n";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { MinimalLayout } from "Components/MinimalLayout";

type RouteParams = {
    data?: string;
};

type Props = WithSnackbarProps & DispatchProp & RouteComponentProps<RouteParams>;

class ResetPasswordPage extends React.Component<Props> {
    private email = "";
    private token = "";

    constructor(props: Props) {
        super(props);

        if (props.match.params.data) {
            const [email, token] = props.match.params.data.split(":");
            this.email = email;
            this.token = token;
        }
    }

    private onSubmit = async (values: ResetPasswordFormValues, formikHelpers: FormikHelpers<ResetPasswordFormValues>): Promise<void> => {
        try {
            const result = await Api.resetAdminPassword({ email: this.email, token: this.token, password: values.password });
            this.props.dispatch(batchActions([AuthActions.updateAdmin(result.admin), AuthActions.updateToken(result.token)]));
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.resetPassword.succeed" }), { variant: "success" });
        } catch (error) {
            this.props.enqueueSnackbar(error.message, { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
        }
    };

    public render() {
        if (!this.email || !this.token) {
            return <Redirect to={Path.login} />;
        }

        const initialValues: ResetPasswordFormValues = {
            password: "",
            passwordConfirmation: "",
        };

        return (
            <MinimalLayout>
                <CenteredCard>
                    <BrandLogo />
                    <Box my="30px">
                        <Typography variant="h4" align="center" color="textPrimary">
                            {I18n.formatMessage({ id: "pages.resetPassword.title" })}
                        </Typography>
                    </Box>
                    <Box my="30px">
                        <Typography variant="body2" paragraph align="center" color="textSecondary">
                            {I18n.formatMessage({ id: "pages.resetPassword.description" })}
                        </Typography>
                    </Box>
                    <Formik initialValues={initialValues} onSubmit={this.onSubmit} validate={resetPasswordFormValidator} validateOnBlur={false}>
                        {props => <ResetPasswordForm formProps={props} />}
                    </Formik>
                </CenteredCard>
            </MinimalLayout>
        );
    }
}

export default withSnackbar(connect()(ResetPasswordPage));
