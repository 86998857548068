import { gql } from "@apollo/client";

export const personFragment = gql`
    fragment Person on Person {
        id
        name
        image
        position
        created_at
        updated_at
    }
`;
