export class StringUtils {
    /**
     * Get name initials
     * eg.: Gipsz Borbála -> GB
     * @param name string
     */
    public static getNameInitials(name: string): string {
        const parts: string[] = name.split(" ") || [];
        const chars = parts.map((part: string): string => (part.length > 0 ? part.charAt(0) : ""));
        return chars.join("").toUpperCase();
    }

    /**
     * Truncate text if it reached a limit
     * eg.: if limit is 5, then "lorem" becomes "lorem" and "lorem ipsum" becomes "lorem..."
     * @param text string
     * @param limit number (defaults to 20)
     */
    public static getTruncatedText(text: string, limit = 20): string {
        return text.length <= limit ? text : text.substring(0, limit) + "…";
    }

    /**
     * Converts a string input field into a number. "number" type input fields allow characters like "--", therefore we use this function for text fields that are supposed to be non-negative numbers
     * @param text raw numeric value
     * @returns strictly numeric value
     */
    public static cleanNumericValue(text: string): number {
        const numbersOnlyValue = text.replace(/\D/g, "");
        const leadingZerosTrimmed = numbersOnlyValue.replace(/^[0]+/g, "");
        const converted = Number.parseInt(leadingZerosTrimmed, 10);
        return converted;
    }
}
