import React from "react";
import { PageCard } from "@bigfish/admin-ui/components";
import { Box, Grid, Typography } from "@bigfish/admin-ui/core";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import { I18n } from "I18n/I18n";

type Props = {
    createdAt: string;
    createdBy?: string;
    updatedAt: string;
    updatedBy?: string;
};

export const StatisticsPaper = (props: Props) => {
    return (
        <PageCard.Container>
            <PageCard.Heading title={I18n.formatMessage({ id: "components.statisticsPaper.title" })} />
            <Box mt="30px" mb="30px">
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Typography color="textSecondary" gutterBottom>
                            {I18n.formatMessage({ id: "components.statisticsPaper.createdAtLabel" })}
                        </Typography>
                        <Typography variant="body2">{props.createdAt ? DateUtils.format(props.createdAt, DateFormat.minuteDateTime) : ""}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography color="textSecondary" gutterBottom>
                            {I18n.formatMessage({ id: "components.statisticsPaper.createdByLabel" })}
                        </Typography>
                        <Typography variant="body2">{props.createdBy}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Typography color="textSecondary" gutterBottom>
                            {I18n.formatMessage({ id: "components.statisticsPaper.updatedAtLabel" })}
                        </Typography>
                        <Typography variant="body2">{props.updatedAt ? DateUtils.format(props.updatedAt, DateFormat.minuteDateTime) : ""}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography color="textSecondary" gutterBottom>
                            {I18n.formatMessage({ id: "components.statisticsPaper.updatedByLabel" })}
                        </Typography>
                        <Typography variant="body2">{props.updatedBy}</Typography>
                    </Grid>
                </Grid>
            </Box>
        </PageCard.Container>
    );
};
