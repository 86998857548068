import React from "react";
import { PageLayout } from "Components/PageLayout";
import { I18n } from "I18n/I18n";
import { RouteComponentProps, withRouter } from "react-router-dom";
import CategoryListDataGrid from "./CategoryListDataGrid";
import { TitleBar, PageCard } from "@bigfish/admin-ui/components";
import { Button, Icon } from "@bigfish/admin-ui/core";
import { Path } from "Utils/Path";
import { ApplicationState } from "Redux/rootReducer";
import { AuthSelectors } from "Redux/selectors/authSelectors";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { connect } from "react-redux";
import { PermissionType } from "Api/graphql/admin/types";

type ReduxProps = {
    permissions: PermissionType[];
    isSuperadmin: boolean;
};

type Props = ReduxProps & RouteComponentProps & WithSnackbarProps;

class CategoryListPage extends React.Component<Props> {
    private onAddClick = (): void => {
        this.props.history.push(Path.categoryCreate);
    };

    public render() {
        return (
            <PageLayout>
                <TitleBar
                    title={I18n.formatMessage({ id: "pages.categoryList.title" })}
                    rightButtonsComponent={
                        <Button startIcon={<Icon className="fa fa-plus-circle" />} variant="contained" size="medium" color="secondary" onClick={this.onAddClick}>
                            {I18n.formatMessage({ id: "pages.categoryList.add" })}
                        </Button>
                    }
                />
                <PageCard.Container>
                    <CategoryListDataGrid />
                </PageCard.Container>
            </PageLayout>
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { permissions: AuthSelectors.getPermissions(state.auth), isSuperadmin: AuthSelectors.isSuperadmin(state.auth) };
};

export default withSnackbar(connect(mapStateToProps)(withRouter(CategoryListPage)));
