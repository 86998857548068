import { gql } from "@apollo/client";
import { menuItemFieldsFragment } from "./menuItemFieldsFragment";
import { pageOverlayFragment } from "./pageOverlayFragment";

export const menuItemFragment = gql`
    fragment MenuItem on MenuItem {
        type {
            id
            name
        }
        model_id
        title
        url
        popup
        is_active
        page_overlay {
            ...PageOverlay
        }
        items {
            ...MenuItemFields
            items {
                ...MenuItemFields
                items {
                    ...MenuItemFields
                    items {
                        ...MenuItemFields
                        items {
                            ...MenuItemFields
                        }
                    }
                }
            }
        }
    }
    ${menuItemFieldsFragment}
    ${pageOverlayFragment}
`;
