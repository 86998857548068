import React from "react";
import { TypeEditInfo, TypeFilterValue, TypeRowProps } from "@inovua/reactdatagrid-community/types";
import { Checkbox, Icon, withStyles, WithStyles } from "@bigfish/admin-ui/core";
import { I18n } from "I18n/I18n";
import { dataGridStyles } from "@bigfish/admin-ui/styles";
import UnControlledDataGrid from "Components/DataGrid/UCDataGrid";
import { CustomTypeColumn, DataGridUtils } from "Utils/DataGridUtils";
import { DataSource } from "Components/DataGrid/DataGridUtils";
import { OrderItem, OrderItemType } from "Api/graphql/admin/types";
import { Path } from "Utils/Path";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import { Button, Grid } from "@bigfish/admin-ui/core";
import { SavePanel } from "@bigfish/admin-ui/components";
import { isEqual } from "lodash";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Constants } from "Utils/Constants";

type Props = WithStyles<typeof dataGridStyles> & {
    orderItems: OrderItem[];
    selectedOrderItemIds?: number[];
    isOrderEditable?: boolean;
    onModifyOrderSubmit?: (orderItems: OrderItem[]) => void;
    onRemoveOrderItemIds?: (orderItemIds: number[]) => void;
    onAddOrderItemIds?: (orderItemIds: number[]) => void;
} & RouteComponentProps;

interface State {
    filterValues: TypeFilterValue;
    orderItems: OrderItem[];
    refreshIndex: number;
}

class OrderItemListDataGrid extends React.Component<Props, State> {
    private getDataSource = () => async (): Promise<DataSource<OrderItem>> => {
        const data = this.state.orderItems.filter(orderItem => this.state.filterValues?.every(DataGridUtils.filter(orderItem)));
        return { data, count: data.length };
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            filterValues: [],
            orderItems: props.orderItems,
            refreshIndex: 0,
        };
    }

    public componentDidUpdate(prevProps: Props): void {
        if (!isEqual(prevProps.orderItems, this.props.orderItems)) {
            this.setState({ orderItems: this.props.orderItems });
        }
    }

    private getColumns = (): CustomTypeColumn[] => {
        return [
            ...(this.props.selectedOrderItemIds
                ? [
                      {
                          name: "selected",
                          header: "",
                          sortable: false,
                          defaultWidth: 60,
                          textAlign: "center",
                          filterEditor: () => {
                              const isAllSelected = this.state.orderItems.length > 0 && this.state.orderItems.every(item => this.props.selectedOrderItemIds?.includes(item.id));
                              const indeterminate = !isAllSelected && this.state.orderItems.some(item => this.props.selectedOrderItemIds?.includes(item.id));
                              return (
                                  <Checkbox
                                      checked={isAllSelected}
                                      indeterminate={indeterminate}
                                      onChange={() =>
                                          isAllSelected
                                              ? this.props.onRemoveOrderItemIds?.(this.state.orderItems.map(oi => oi.id))
                                              : this.props.onAddOrderItemIds?.(this.state.orderItems.map(oi => oi.id))
                                      }
                                  />
                              );
                          },
                          filterable: true,
                          render: ({ data }: { data: OrderItem }) => {
                              const isAdded = this.props.selectedOrderItemIds?.includes(data.id);
                              return (
                                  <div>
                                      <Checkbox
                                          checked={isAdded}
                                          onChange={() => (isAdded ? this.props.onRemoveOrderItemIds?.([data.id]) : this.props.onAddOrderItemIds?.([data.id]))}
                                      />
                                  </div>
                              );
                          },
                      } as CustomTypeColumn,
                  ]
                : []),
            {
                name: "product_lfdnr",
                header: I18n.formatMessage({ id: "pages.orderItemList.grid.column.lfdnr" }),
                defaultWidth: 220,
                sortable: false,
                filterEditor: NumberFilter,
                editable: false,
            },
            {
                name: "product_name",
                header: I18n.formatMessage({ id: "pages.orderItemList.grid.column.productName" }),
                defaultFlex: 1,
                minWidth: 250,
                sortable: false,
                filterable: true,
                filterDelay: Constants.filterDelayMS,
                editable: false,
            },
            {
                name: "ean",
                header: I18n.formatMessage({ id: "pages.orderItemList.grid.column.ean" }),
                defaultFlex: 1,
                minWidth: 260,
                sortable: false,
                filterable: true,
                editable: false,
            },
            {
                name: "unit_price",
                header: I18n.formatMessage({ id: "pages.orderItemList.grid.column.unit_price" }),
                defaultWidth: 180,
                sortable: false,
                filterable: true,
                filterEditor: NumberFilter,
                render: ({ value }: { value: number }) => {
                    return <div>{I18n.formatCurrency(value)}</div>;
                },
            },
            {
                name: "quantity",
                header: I18n.formatMessage({ id: "pages.orderItemList.grid.column.quantity" }),
                defaultWidth: 220,
                sortable: false,
                filterEditor: NumberFilter,
            },
            {
                name: "quantity_delivered",
                header: I18n.formatMessage({ id: "pages.orderItemList.grid.column.quantity_delivered" }),
                defaultWidth: 220,
                sortable: false,
                filterEditor: NumberFilter,
            },
            {
                name: "quantity_missing",
                header: I18n.formatMessage({ id: "pages.orderItemList.grid.column.quantity_missing" }),
                defaultWidth: 220,
                sortable: false,
                filterEditor: NumberFilter,
            },
            {
                name: "sub_total",
                header: I18n.formatMessage({ id: "pages.orderItemList.grid.column.sub_total" }),
                defaultWidth: 220,
                sortable: false,
                filterEditor: NumberFilter,
                render: ({ value }: { value: number }) => {
                    return <div>{I18n.formatCurrency(value)}</div>;
                },
                editable: false,
            },
        ];
    };

    private filterValues = (): TypeFilterValue => {
        const filterValues = [
            { name: "product_lfdnr", operator: "inrange", type: "number", value: undefined },
            { name: "product_name", operator: "contains", type: "string", value: "" },
            { name: "unit_price", operator: "inrange", type: "number", value: undefined },
            { name: "quantity", operator: "inrange", type: "number", value: undefined },
            { name: "sub_total", operator: "inrange", type: "number", value: undefined },
        ];

        if (this.props.onAddOrderItemIds && this.props.onRemoveOrderItemIds) {
            filterValues.push({ name: "selected", operator: "eq", type: "select", value: undefined });
        }

        return filterValues;
    };

    private getRowLink = (rowProps: TypeRowProps): string => {
        return Path.orderEdit(rowProps.data.id);
    };

    private onEditComplete = ({ columnId, rowId, value }: TypeEditInfo) => {
        const cleanValue = typeof value !== "number" ? Number.parseInt(value, 10) : value;
        if (cleanValue < 0) return;
        const indexToModify = this.state.orderItems.findIndex(oi => `${oi.id}` === `${rowId}`);

        const originalItemFromProps = this.props.orderItems.find(oi => `${oi.id}` === `${rowId}`);
        const originalItem = this.state.orderItems.find(oi => `${oi.id}` === `${rowId}`);

        if ((originalItem?.type === OrderItemType.shipping && columnId === "quantity") || !originalItemFromProps) return;

        if (originalItemFromProps.type === OrderItemType.fee && columnId === "quantity") return;

        const newUnitPrice = () => {
            if (columnId !== "unit_price") return originalItem?.unit_price ?? 0;

            return originalItemFromProps.type === OrderItemType.shipping ? originalItem?.unit_price ?? 0 : cleanValue;
        };

        const newItems = [...this.state.orderItems];
        newItems[indexToModify] = {
            ...newItems[indexToModify],
            id: typeof rowId === "number" ? rowId : Number.parseInt(rowId, 10),
            quantity: columnId === "quantity" && originalItemFromProps.quantity >= cleanValue ? cleanValue : originalItem?.quantity ?? 0,
            unit_price: newUnitPrice(),
        };

        this.setState({ orderItems: newItems, refreshIndex: this.state.refreshIndex + 1 });
    };

    private isChanged = () => {
        return !isEqual(this.props.orderItems, this.state.orderItems);
    };

    render() {
        return (
            <div key={this.state.refreshIndex}>
                <UnControlledDataGrid<OrderItem>
                    key={this.state.refreshIndex}
                    className={this.props.classes.dataGrid}
                    rowHeight={50}
                    style={{ minHeight: 103 + this.props.orderItems.length * 50 }}
                    dataSource={this.getDataSource()}
                    columns={this.getColumns()}
                    defaultFilterValue={this.filterValues()}
                    onFilterValueChange={filterValues => this.setState({ filterValues })}
                    rowLink={this.getRowLink}
                    activeCell={null}
                    onRowClick={() => {}}
                    pagination={false}
                    editable={!!this.props.isOrderEditable}
                    editStartEvent="click"
                    onEditComplete={this.onEditComplete}
                />
                <SavePanel>
                    <Grid container justify="space-between">
                        <Button variant="outlined" color="primary" onClick={() => this.props.history.push(Path.orderList)}>
                            {I18n.formatMessage({ id: "common.cancel" })}
                        </Button>
                        <Button
                            type="submit"
                            startIcon={<Icon className="fas fa-save" />}
                            variant="contained"
                            color="secondary"
                            disabled={!this.isChanged()}
                            onClick={e => {
                                e.preventDefault();
                                if (this.props.onModifyOrderSubmit) {
                                    this.props.onModifyOrderSubmit([...this.state.orderItems]);
                                }
                            }}
                        >
                            {I18n.formatMessage({ id: "common.save" })}
                        </Button>
                    </Grid>
                </SavePanel>
            </div>
        );
    }
}

export default withRouter(withStyles(dataGridStyles, { withTheme: true })(OrderItemListDataGrid));
