import { gql } from "@apollo/client";
import { productCategoryFragment } from "../fragments/productCategoryFragment";

export const listProductCategoriesByIdQuery = gql`
    query listProductCategoriesById($filters: ProductCategoryFilters, $first: Int!) {
        listProductCategories(first: $first, filters: $filters) {
            data {
                ...ProductCategory
            }
        }
    }
    ${productCategoryFragment}
`;
