import React from "react";
import { ImageUploadField, UploadedImage, ImageSelector } from "@bigfish/admin-ui/components";
import { FormHelperText } from "@bigfish/admin-ui/core";
import { I18n } from "I18n/I18n";
import { MediaLibraryHelper } from "Utils/MediaLibraryHelper";

type Props = {
    id: string;
    name: string;
    value: string | null;
    helperText?: React.ReactNode;
    error?: boolean;
    onChange: (imageUrl: string | null) => void;
};

class MediaLibraryImageInput extends React.Component<Props> {
    private onSelectImage = (): void => {
        MediaLibraryHelper.openLibrary({
            maximumSelectableAsset: 1,
            onSelection: this.onChange,
        });
    };

    private onChange = (assets: string[]) => {
        if (assets[0]) {
            this.props.onChange(assets[0]);
        }
    };

    private onCloseClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        event.stopPropagation();
        this.props.onChange(null);
    };

    public render() {
        return (
            <div>
                <ImageUploadField
                    id={this.props.id}
                    name={this.props.name}
                    preview={
                        <>
                            <label htmlFor={this.props.id} onClick={this.onSelectImage}>
                                {this.props.value ? (
                                    <UploadedImage name={this.props.value} source={MediaLibraryHelper.getImageUrl(this.props.value)} onDelete={this.onCloseClick} />
                                ) : (
                                    <ImageSelector>{I18n.formatMessage({ id: "components.inputs.mediaLibraryImageInput.selectImage" })}</ImageSelector>
                                )}
                            </label>
                        </>
                    }
                    imageFieldsVisible={!!this.props.value}
                />
                {this.props.helperText && <FormHelperText>{this.props.helperText}</FormHelperText>}
            </div>
        );
    }
}

export default MediaLibraryImageInput;
