import { gql } from "@apollo/client";
import { categoryFragment } from "../fragments/categoryFragment";

export const getCategoryQuery = gql`
    query getCategory {
        categories {
            ...Category
        }
    }
    ${categoryFragment}
`;
