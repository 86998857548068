import React from "react";
import { TextField, Button, Typography, Icon, Box } from "@bigfish/admin-ui/core";
import { Form } from "Components/Form";
import { Link } from "Components/Link";
import { Field, FieldProps, FormikProps } from "formik";
import { I18nHelpers } from "I18n/I18nHelpers";
import { I18n } from "Src/i18n/I18n";
import { Path } from "Utils/Path";
import { Validator } from "Utils/Validator";

export type ForgotPasswordFormValues = {
    email: string;
};

type Props = {
    formProps: FormikProps<ForgotPasswordFormValues>;
};

export const ForgotPasswordForm = (props: Props) => {
    return (
        <Form formProps={props.formProps}>
            <Field name="email" validate={I18nHelpers.formatValidator(Validator.email)}>
                {({ field, meta }: FieldProps) => (
                    <Box my="30px">
                        <TextField
                            type="email"
                            label={I18n.formatMessage({ id: "pages.forgotPassword.form.email.label" })}
                            fullWidth
                            variant="outlined"
                            required
                            error={!!meta.error}
                            helperText={meta.error}
                            {...field}
                        />
                    </Box>
                )}
            </Field>
            <Box my="30px">
                <Button
                    startIcon={<Icon className="fa fa-paper-plane" style={{ color: "white" }} />}
                    type="submit"
                    variant="contained"
                    size="large"
                    color="secondary"
                    fullWidth
                    disabled={props.formProps.isSubmitting}
                >
                    {I18n.formatMessage({ id: "pages.forgotPassword.form.submit" })}
                </Button>
            </Box>
            <Typography align="center">
                <Link color="secondary" to={Path.login}>
                    {I18n.formatMessage({ id: "pages.forgotPassword.form.login" })}
                </Link>
            </Typography>
        </Form>
    );
};
