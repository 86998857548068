import { gql } from "@apollo/client";
import { giftCardOrderFragment } from "../fragments/giftCardOrderFragment";

export const giftCardOrderQuery = gql`
    query giftCardOrder($id: Int!) {
        giftCardOrder(id: $id) {
            ...GiftCardOrder
        }
    }
    ${giftCardOrderFragment}
`;
