import React, { useCallback, useState } from "react";
import { Form, FormType } from "Components/Form";
import { Button, Grid, Icon, TextField, Box, FormControl, Typography, FormHelperText, Checkbox, FormControlLabel } from "@bigfish/admin-ui/core";
import { FastField, Field, FieldProps, FormikProps } from "formik";
import { Validator } from "Utils/Validator";
import { I18n } from "Src/i18n/I18n";
import { I18nHelpers } from "I18n/I18nHelpers";
import { PageCard, SavePanel } from "@bigfish/admin-ui/components";
import { Path } from "Utils/Path";
import { useHistory } from "react-router-dom";
import { BenefitListItem, DepartmentListItem, DepartmentSortField, OrderDirection, PersonListItem } from "Api/graphql/admin/types";
import RichTextEditor from "Components/Inputs/RichTextEditor/RichTextEditor";
import { useSnackbar } from "notistack";
import { Api } from "Api/Api";
import RemoteSelect from "Components/Inputs/RemoteSelect";
import BenefitTable from "Components/BenefitTable/BenefitTable";
import AddBenefitModal from "Components/BenefitTable/AddBenefitModal";
import RemoteMultiSelect from "Components/Inputs/RemoteMultiSelect";

export type DivisionFormValues = {
    name: string;
    description: string;
    quote: string;
    department: DepartmentListItem | null;
    benefits: BenefitListItem[];
    leaders: PersonListItem[];
    is_virtual: boolean;
};

type Props = {
    formType: FormType;
    formProps: FormikProps<DivisionFormValues>;
};

export const DivisionForm = (props: Props) => {
    const history = useHistory();
    const onCancelClick = useCallback(() => {
        history.push(Path.divisionList);
    }, [history]);
    const { enqueueSnackbar } = useSnackbar();
    const [isAddModalVisible, setAddModalVisible] = useState<boolean>(false);

    return (
        <Form formProps={props.formProps}>
            <Field name="name" validate={I18nHelpers.formatValidator(Validator.divisionName)}>
                {({ field, meta }: FieldProps) => (
                    <TextField
                        type="text"
                        label={I18n.formatMessage({ id: "pages.division.form.title.label" })}
                        fullWidth
                        variant="outlined"
                        required
                        helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                        error={meta.touched && !!meta.error}
                        {...field}
                    />
                )}
            </Field>

            <Box mt="30px" mb="13px">
                <Typography variant="body1" component="h3" color="textSecondary">
                    {I18n.formatMessage({ id: "pages.division.form.description.label" })}
                </Typography>
            </Box>
            <Box mt="15px">
                <Field name="description" validate={I18nHelpers.formatValidator(Validator.required)}>
                    {({ field, meta }: FieldProps) => (
                        <FormControl fullWidth error={meta.touched && !!meta.error}>
                            <RichTextEditor
                                name="description"
                                config="default"
                                value={field.value}
                                onChange={(description: string) => {
                                    props.formProps.setFieldValue("description", description);
                                }}
                            />
                            <FormHelperText error={meta.touched && !!meta.error}>{Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}</FormHelperText>
                        </FormControl>
                    )}
                </Field>
            </Box>

            <FastField name="department">
                {({ field, meta }: FieldProps) => (
                    <RemoteSelect
                        value={field.value}
                        error={meta.touched && !!meta.error}
                        name={field.name}
                        label={I18n.formatMessage({ id: "pages.division.form.department.label" })}
                        onChange={(value: DepartmentListItem | null) => {
                            props.formProps.setFieldValue(field.name, value);
                        }}
                        accessor="name"
                        helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.optional" }))}
                        fetch={async (): Promise<DepartmentListItem[]> => {
                            try {
                                const departments = await Api.departments({ first: 1000, page: 1, sortBy: { field: DepartmentSortField.name, direction: OrderDirection.ASC } });
                                return departments.data;
                            } catch (error) {
                                enqueueSnackbar(error.message, { variant: "error" });
                                return [];
                            }
                        }}
                    />
                )}
            </FastField>

            <Field name="is_virtual">
                {({ field, meta }: FieldProps) => (
                    <Box mt="30px">
                        <FormControl error={meta.touched && !!meta.error}>
                            <Typography color="textSecondary" gutterBottom>
                                {I18n.formatMessage({ id: "pages.division.form.is_virtual" })}
                            </Typography>
                            <FormControlLabel control={<Checkbox {...field} checked={field.value} />} label={I18n.formatMessage({ id: "common.yes" })} />
                        </FormControl>
                    </Box>
                )}
            </Field>

            <Box mt="30px" mb="13px">
                <Typography variant="body1" component="h3" color="textSecondary">
                    {I18n.formatMessage({ id: "pages.division.form.quote.label" })}
                </Typography>
            </Box>
            <Box mt="15px">
                <FastField name="quote" validate={I18nHelpers.formatValidator(Validator.required)}>
                    {({ field, meta }: FieldProps) => (
                        <FormControl fullWidth error={meta.touched && !!meta.error}>
                            <RichTextEditor
                                name="quote"
                                config="default"
                                value={field.value}
                                onChange={(quote: string) => {
                                    props.formProps.setFieldValue("quote", quote);
                                }}
                            />
                            <FormHelperText error={meta.touched && !!meta.error}>{Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}</FormHelperText>
                        </FormControl>
                    )}
                </FastField>
            </Box>

            <Box mb="15px">
                <FastField name="leaders" validate={I18nHelpers.formatValidator(Validator.required)}>
                    {({ field, meta }: FieldProps) => (
                        <FormControl fullWidth error={meta.touched && !!meta.error}>
                            <RemoteMultiSelect
                                value={field.value}
                                label={I18n.formatMessage({ id: "pages.division.form.leaders.label" })}
                                fetch={async () => {
                                    const response = await Api.persons({ first: 100, page: 1 });
                                    return [...response.data];
                                }}
                                accessor="name"
                                onChange={(value: PersonListItem[]) => {
                                    props.formProps.setFieldValue(field.name, value);
                                }}
                            />
                            <FormHelperText error={meta.touched && !!meta.error}>{Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}</FormHelperText>
                        </FormControl>
                    )}
                </FastField>
            </Box>

            <PageCard.Heading title={I18n.formatMessage({ id: "pages.division.form.benefits.label" })} />

            <Box mt="30px">
                <Button variant="outlined" color="primary" startIcon={<Icon className="fa fa-plus-circle" />} size="medium" onClick={() => setAddModalVisible(true)}>
                    {I18n.formatMessage({ id: "common.add" })}
                </Button>
                <Field name="benefits">
                    {({ field }: FieldProps) => (
                        <AddBenefitModal
                            onClose={() => setAddModalVisible(false)}
                            products={field.value}
                            onProductsSelected={(selectedProducts: BenefitListItem[]): void => {
                                props.formProps.setFieldValue("benefits", selectedProducts);
                                setAddModalVisible(false);
                            }}
                            open={isAddModalVisible}
                        />
                    )}
                </Field>
            </Box>

            <Box my="30px">
                <Field name="benefits">
                    {({ field, meta }: FieldProps) => (
                        <>
                            <BenefitTable
                                benefits={field.value}
                                onRemoveClick={(id: string) => {
                                    props.formProps.setFieldValue(
                                        field.name,
                                        props.formProps.values.benefits.filter((p: BenefitListItem) => p.id !== id)
                                    );
                                }}
                            />
                            <input style={{ visibility: "hidden" }} name={field.name} />
                            <FormHelperText error={meta.touched && !!meta.error}>{meta.touched && meta.error}</FormHelperText>
                        </>
                    )}
                </Field>
            </Box>

            <SavePanel>
                <Grid container justify="space-between">
                    <Button variant="outlined" color="primary" onClick={onCancelClick}>
                        {I18n.formatMessage({ id: "common.cancel" })}
                    </Button>
                    <Button
                        type="submit"
                        startIcon={<Icon className="fas fa-save" />}
                        variant="contained"
                        color="secondary"
                        disabled={!props.formProps.dirty || props.formProps.isSubmitting}
                    >
                        {I18n.formatMessage({ id: "common.save" })}
                    </Button>
                </Grid>
            </SavePanel>
        </Form>
    );
};
