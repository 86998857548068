import React, { useCallback } from "react";
import { Form, FormType } from "Components/Form";
import { Button, Grid, Icon, Box, Typography, FormControl, FormHelperText } from "@bigfish/admin-ui/core";
import { FastField, FieldProps, FormikProps } from "formik";
import { Validator } from "Utils/Validator";
import { I18n } from "Src/i18n/I18n";
import { I18nHelpers } from "I18n/I18nHelpers";
import { SavePanel } from "@bigfish/admin-ui/components";
import { Path } from "Utils/Path";
import { useHistory } from "react-router-dom";
import { DivisionListItem, DivisionSortField, OrderDirection, PersonListItem, PersonSortField } from "Api/graphql/admin/types";
import RichTextEditor from "Components/Inputs/RichTextEditor/RichTextEditor";
import { Api } from "Api/Api";
import RemoteSelect from "Components/Inputs/RemoteSelect";
import { useSnackbar } from "notistack";

export type SuccessStoryFormValues = {
    division: DivisionListItem | null;
    person: PersonListItem | null;
    content: string;
};

type Props = {
    formType: FormType;
    formProps: FormikProps<SuccessStoryFormValues>;
};

export const SuccessStoryForm = (props: Props) => {
    const history = useHistory();
    const onCancelClick = useCallback(() => {
        history.push(Path.personList);
    }, [history]);
    const { enqueueSnackbar } = useSnackbar();

    return (
        <Form formProps={props.formProps}>
            <FastField name="person" validate={I18nHelpers.formatValidator(Validator.required)}>
                {({ field, meta }: FieldProps) => (
                    <RemoteSelect
                        value={field.value}
                        error={meta.touched && !!meta.error}
                        name={field.name}
                        label={I18n.formatMessage({ id: "pages.successStory.form.person.label" })}
                        onChange={(value: PersonListItem | null) => {
                            props.formProps.setFieldValue(field.name, value);
                        }}
                        accessor="name"
                        helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                        fetch={async (): Promise<PersonListItem[]> => {
                            try {
                                const people = await Api.persons({ first: 1000, page: 1, sortBy: { field: PersonSortField.name, direction: OrderDirection.ASC } });
                                return people.data;
                            } catch (error) {
                                enqueueSnackbar(error.message, { variant: "error" });
                                return [];
                            }
                        }}
                    />
                )}
            </FastField>

            <FastField name="division" validate={I18nHelpers.formatValidator(Validator.required)}>
                {({ field, meta }: FieldProps) => (
                    <RemoteSelect
                        value={field.value}
                        error={meta.touched && !!meta.error}
                        name={field.name}
                        label={I18n.formatMessage({ id: "pages.successStory.form.division.label" })}
                        onChange={(value: DivisionListItem | null) => {
                            props.formProps.setFieldValue(field.name, value);
                        }}
                        accessor="name"
                        helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                        fetch={async (): Promise<DivisionListItem[]> => {
                            try {
                                const division = await Api.divisions({ first: 1000, page: 1, sortBy: { field: DivisionSortField.name, direction: OrderDirection.ASC } });
                                return division.data;
                            } catch (error) {
                                enqueueSnackbar(error.message, { variant: "error" });
                                return [];
                            }
                        }}
                    />
                )}
            </FastField>
            <Box mt="30px" mb="13px">
                <Typography variant="body1" component="h3" color="textSecondary">
                    {I18n.formatMessage({ id: "pages.successStory.form.content.label" })}
                </Typography>
            </Box>
            <Box mt="15px">
                <FastField name="content" validate={I18nHelpers.formatValidator(Validator.required)}>
                    {({ field, meta }: FieldProps) => (
                        <FormControl fullWidth error={meta.touched && !!meta.error}>
                            <RichTextEditor
                                name="content"
                                config="default"
                                value={field.value}
                                onChange={(content: string) => {
                                    props.formProps.setFieldValue("content", content);
                                }}
                            />
                            <FormHelperText error={meta.touched && !!meta.error}>{Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}</FormHelperText>
                        </FormControl>
                    )}
                </FastField>
            </Box>

            <SavePanel>
                <Grid container justify="space-between">
                    <Button variant="outlined" color="primary" onClick={onCancelClick}>
                        {I18n.formatMessage({ id: "common.cancel" })}
                    </Button>
                    <Button
                        type="submit"
                        startIcon={<Icon className="fas fa-save" />}
                        variant="contained"
                        color="secondary"
                        disabled={!props.formProps.dirty || props.formProps.isSubmitting}
                    >
                        {I18n.formatMessage({ id: "common.save" })}
                    </Button>
                </Grid>
            </SavePanel>
        </Form>
    );
};
