import React from "react";
import { makeStyles, Card, Typography, Divider, Modal, Grid, IconButton, Icon } from "@bigfish/admin-ui/core";

interface ModalProps {
    title: string | React.ReactNode;
    open: boolean;
    onClose(): void;
    width?: number | string | null;
}

export const FormModal = (props: React.PropsWithChildren<ModalProps>) => {
    const useStyles = makeStyles({
        modal: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        card: {
            width: props.width || 640,
            margin: "0 auto",
            borderRadius: 20,
            padding: "30px 20px",
            outline: "none",
        },
        cardHeader: {
            paddingBottom: 15,
        },
    });
    const classes = useStyles();

    return (
        <Modal open={props.open} onClose={props.onClose} className={classes.modal}>
            <Card raised className={classes.card}>
                <Grid container justify="space-between" alignItems="center" className={classes.cardHeader}>
                    <Typography variant="h3">{props.title}</Typography>
                    <IconButton color="primary" onClick={props.onClose}>
                        <Icon className="fas fa-times" />
                    </IconButton>
                </Grid>
                <Divider />
                {props.children}
            </Card>
        </Modal>
    );
};
