import React, { useCallback, useMemo } from "react";
import { Form, FormType } from "Components/Form";
import { Button, FormControl, FormControlLabel, Grid, Icon, TextField, Typography, Box, InputAdornment } from "@bigfish/admin-ui/core";
import { Field, FieldProps, FormikErrors, FormikProps } from "formik";
import { OrderDirection, ProductParamSelectItem, ProductParamSortField, ProductVariant, ProductVariantChild, ProductVariantInput } from "Api/graphql/admin/types";
import { Checkbox } from "@bigfish/admin-ui/core";
import { I18n } from "Src/i18n/I18n";
import { FullscreenLoader, PageCard, SavePanel } from "@bigfish/admin-ui/components";
import { Path } from "Utils/Path";
import { useHistory } from "react-router-dom";
import { CustomFormHelperText } from "Components/CustomFormHelperText";
import FetchedSelect from "Components/FetchedSelect/FetchedSelect";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { useSnackbar } from "notistack";
import { AddProductVariantChildDialog } from "./AddProductVariantChildDialog";
import ProductVariantChildDataGrid from "./ProductVariantChildDataGrid";
import { Validator } from "Utils/Validator";
import { I18nHelpers } from "I18n/I18nHelpers";
import { EventBusy } from "@bigfish/admin-ui/icons";
import { StatisticsPaper } from "Components/StatisticsPaper";

export type ProductVariantFormValues = Omit<ProductVariantInput, "children"> & {
    children: ProductVariantChild[];
};

type Props = {
    formType: FormType;
    formProps: FormikProps<ProductVariantFormValues>;
    productVariant?: ProductVariant;
};

export const productVariantValidator = () => (values: ProductVariantFormValues): FormikErrors<ProductVariantFormValues> => {
    const errors: { [key in keyof ProductVariantFormValues]?: any } = {};

    if (values.active_from && values.active_to) {
        errors.active_to = Validator.endDateCantBeBeforeStart(values.active_from, values.active_to);
        errors.active_from = errors.active_from || Validator.startDateCantBeAfterEnd(values.active_from, values.active_to);
    }

    return Form.cleanupFormikErrors(errors);
};

export const ProductVariantForm = (props: Props) => {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const onCancelClick = useCallback(() => {
        history.push(Path.productVariantList);
    }, [history]);

    const [isProductAddDialogVisible, setIsProductAddDialogVisible] = React.useState<boolean>(false);

    const handleAddProductsClick = useCallback(
        (newProducts: ProductVariantChild[]) => {
            props.formProps.setFieldValue("children", newProducts);
            props.formProps.setFieldTouched("children", true);
            setIsProductAddDialogVisible(false);
        },
        [props.formProps]
    );

    const renderProductVariantDataGrid = useMemo(() => {
        return <ProductVariantChildDataGrid key="product-variant-data-grid-children" idsFilter={props.formProps.values.children.map(c => c.id)} />;
    }, [props.formProps.values.children]);

    return (
        <Form formProps={props.formProps}>
            <PageCard.Container>
                <PageCard.Heading title={I18n.formatMessage({ id: "common.form.basicData" })} />

                <Box mt="30px" />

                {props.formType === FormType.edit && (
                    <Box mb="30px">
                        <Typography color="textSecondary" gutterBottom>
                            {I18n.formatMessage({ id: "pages.productVariant.form.idLabel" })}
                        </Typography>
                        <Typography variant="subtitle1">{props.productVariant?.id}</Typography>
                    </Box>
                )}

                <Typography variant="subtitle1">{I18n.formatMessage({ id: "pages.productVariant.form.childrenInfo" })}</Typography>

                <Box mt="30px" />

                <Button startIcon={<Icon className="fa fa-plus-circle" />} variant="outlined" size="medium" color="secondary" onClick={() => setIsProductAddDialogVisible(true)}>
                    {I18n.formatMessage({ id: "pages.productList.form.addProductButton" })}
                </Button>

                <Box mt="30px" />

                {renderProductVariantDataGrid}

                <Box mt="30px" />

                <PageCard.Heading title={I18n.formatMessage({ id: "common.form.settings" })} />

                <Field name="name">
                    {({ field, meta }: FieldProps) => (
                        <TextField
                            type="text"
                            label={I18n.formatMessage({ id: "pages.productVariant.form.nameLabel" })}
                            fullWidth
                            variant="outlined"
                            required
                            helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "pages.productVariant.form.nameHint" }))}
                            error={meta.touched && !!meta.error}
                            {...field}
                        />
                    )}
                </Field>

                <Box mt="30px" />

                {props.formType === FormType.edit && (
                    <Box mt="30px" mb="30px">
                        <Typography color="textSecondary" gutterBottom>
                            {I18n.formatMessage({ id: "pages.productVariant.form.slugLabel" })}
                        </Typography>
                        <Typography variant="subtitle1">{props.productVariant?.slug}</Typography>
                    </Box>
                )}

                <Box mt="30px" />

                <Typography variant="subtitle1">{I18n.formatMessage({ id: "pages.productVariant.form.productParamIdInfo" })}</Typography>

                <Box mt="15px" />

                <Field name="product_param_id" validate={I18nHelpers.formatValidator(Validator.required)}>
                    {({ field, meta }: FieldProps) => (
                        <FetchedSelect
                            label={I18n.formatMessage({ id: "pages.productVariant.form.productParamIdLabel" })}
                            fullWidth
                            variant="outlined"
                            getTitle={(param: ProductParamSelectItem) => param.name ?? ""}
                            fetch={async (): Promise<ProductParamSelectItem[]> => {
                                try {
                                    let total = 0;
                                    let paramSelectItems: ProductParamSelectItem[] = [];

                                    let page = 1;
                                    const result = await Api.listProductParamSelectItems({
                                        first: 9999,
                                        sortBy: { field: ProductParamSortField.name, direction: OrderDirection.ASC },
                                        filters: { is_filter: true },
                                    });
                                    total = result.paginatorInfo.total;
                                    paramSelectItems = [...paramSelectItems, ...result.data];

                                    while (paramSelectItems.length < total) {
                                        page = page + 1;
                                        const nextResult = await Api.listProductParamSelectItems({
                                            first: 9999,
                                            page,
                                            sortBy: { field: ProductParamSortField.name, direction: OrderDirection.ASC },
                                            filters: { is_filter: true },
                                        });
                                        total = nextResult.paginatorInfo.total;
                                        paramSelectItems = [...paramSelectItems, ...nextResult.data];
                                    }

                                    return paramSelectItems;
                                } catch (error) {
                                    if (error instanceof ApiError) {
                                        enqueueSnackbar(error.message, { variant: "error" });
                                    }
                                    return [];
                                }
                            }}
                            helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                            {...field}
                            error={meta.touched && !!meta.error}
                        />
                    )}
                </Field>

                <Box mt="30px" />

                <Field name="is_active">
                    {({ field, meta }: FieldProps) =>
                        field.value !== undefined && (
                            <Box ml="15px" mt="15px">
                                <FormControl error={meta.touched && !!meta.error}>
                                    <FormControlLabel
                                        control={<Checkbox {...field} checked={field.value} />}
                                        label={I18n.formatMessage({ id: "pages.productVariant.form.isActiveLabel" })}
                                    />
                                </FormControl>
                                <CustomFormHelperText meta={meta} />
                            </Box>
                        )
                    }
                </Field>

                <Box mt="30px" />

                <Grid container>
                    <Grid item>
                        <Box mr="30px">
                            <Field name="active_from">
                                {({ field, meta }: FieldProps) => (
                                    <TextField
                                        id="active_from"
                                        label={I18n.formatMessage({ id: "pages.productVariant.form.activeFromLabel" })}
                                        type="datetime-local"
                                        className={`datetime-local ${props.formProps.values.active_from ? "datetime-local-filled" : ""}`}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            endAdornment: props.formProps.values.active_from ? (
                                                <InputAdornment
                                                    position="end"
                                                    onClick={() => {
                                                        props.formProps.setFieldValue("active_from", "");
                                                        props.formProps.setFieldTouched("active_from", true);
                                                    }}
                                                >
                                                    <EventBusy />
                                                </InputAdornment>
                                            ) : null,
                                        }}
                                        {...field}
                                        value={field.value}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            props.formProps.setTouched({ ...props.formProps.touched, active_from: true });
                                            field.onChange(e);
                                        }}
                                        error={meta.touched && !!meta.error}
                                        helperText={Form.getHelperText(meta, "")}
                                    />
                                )}
                            </Field>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Field name="active_to">
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    id="active_to"
                                    label={I18n.formatMessage({ id: "pages.productVariant.form.activeToLabel" })}
                                    className={`datetime-local ${props.formProps.values.active_to ? "datetime-local-filled" : ""}`}
                                    type="datetime-local"
                                    placeholder=""
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        endAdornment: props.formProps.values.active_to ? (
                                            <InputAdornment
                                                position="end"
                                                onClick={() => {
                                                    props.formProps.setFieldValue("active_to", "");
                                                    props.formProps.setFieldTouched("active_to", true);
                                                }}
                                            >
                                                <EventBusy />
                                            </InputAdornment>
                                        ) : null,
                                    }}
                                    {...field}
                                    value={field.value}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        props.formProps.setTouched({ ...props.formProps.touched, active_to: true });
                                        field.onChange(e);
                                    }}
                                    error={meta.touched && !!meta.error}
                                    helperText={Form.getHelperText(meta, "")}
                                />
                            )}
                        </Field>
                    </Grid>
                </Grid>
            </PageCard.Container>

            {props.formType === FormType.edit && props.productVariant && (
                <StatisticsPaper
                    createdAt={props.productVariant.created_at}
                    createdBy={props.productVariant.created_by?.name}
                    updatedAt={props.productVariant.updated_at}
                    updatedBy={props.productVariant.updated_by?.name}
                />
            )}

            <SavePanel>
                <Grid container justify="space-between">
                    <Button variant="outlined" color="primary" onClick={onCancelClick}>
                        {I18n.formatMessage({ id: "common.cancel" })}
                    </Button>
                    <Button
                        type="submit"
                        startIcon={<Icon className="fas fa-save" />}
                        variant="contained"
                        color="secondary"
                        disabled={!props.formProps.dirty || !props.formProps.isValid}
                    >
                        {I18n.formatMessage({ id: `common.${props.formType === FormType.create ? "create" : "save"}` })}
                    </Button>
                </Grid>
            </SavePanel>

            <FullscreenLoader visible={props.formProps.isSubmitting} />

            {isProductAddDialogVisible && (
                <AddProductVariantChildDialog
                    description={I18n.formatMessage({ id: "pages.productVariant.form.addProductDialog.description" })}
                    initialSelectedProducts={props.formProps.values.children}
                    onAddClick={handleAddProductsClick}
                    onClose={() => {
                        setIsProductAddDialogVisible(false);
                        history.replace({ pathname: history.location.pathname });
                    }}
                />
            )}
        </Form>
    );
};
