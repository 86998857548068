import { gql } from "@apollo/client";
import { adminSelectItemFragment } from "../fragments/adminSelectItemFragment";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";

export const listAdminsForSelectQuery = gql`
    query listAdminsForSelect($filters: AdminFilters, $sortBy: AdminSort, $first: Int!, $page: Int) {
        listAdmins(first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            data {
                ...AdminSelectItem
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${adminSelectItemFragment}
    ${paginatorInfoFragment}
`;
