import { gql } from "@apollo/client";
import { userFragment } from "../fragments/userFragment";

export const getUserByIdQuery = gql`
    query getUserById($id: Int!) {
        getUser(id: $id) {
            ...User
        }
    }
    ${userFragment}
`;
