import { TypeSingleFilterValue, TypeColumn } from "@inovua/reactdatagrid-community/types";

export const DataGridUtils = {
    numberFilter(filter: TypeSingleFilterValue, value: unknown): boolean {
        if (!filter.value || typeof filter.value !== "string") return true;
        if (typeof value !== "number") return false;
        switch (filter.operator) {
            case "eq":
            default:
                return `${value}` === filter.value;
        }
    },
    stringFilter(filter: TypeSingleFilterValue, value: unknown): boolean {
        if (!filter.value || typeof filter.value !== "string") return true;
        if (typeof value !== "string") return false;
        switch (filter.operator) {
            case "contains":
                return value.toLocaleLowerCase().includes(filter.value.toLocaleLowerCase());
            default:
                return value === filter.value;
        }
    },
    selectFilter(filter: TypeSingleFilterValue, value: unknown): boolean {
        if (typeof filter.value === "undefined" || filter.value === null) return true;
        switch (filter.operator) {
            case "eq":
            default:
                return `${value}` === `${filter.value}`;
        }
    },
    filter<T>(obj: T) {
        return (filter: TypeSingleFilterValue): boolean => {
            const objValue = obj[filter.name as keyof T];
            switch (filter.type) {
                case "string":
                    return DataGridUtils.stringFilter(filter, objValue);
                case "select":
                    return DataGridUtils.selectFilter(filter, objValue);
                case "number":
                    return DataGridUtils.numberFilter(filter, objValue);
            }
            return true;
        };
    },
};

export type CustomTypeColumn = TypeColumn & { filterDelay?: number };
