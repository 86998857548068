import { gql } from "@apollo/client";
import { adminListItemFragment } from "../fragments/adminListItemFragment";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";

export const listAdminsQuery = gql`
    query listAdmins($filters: AdminFilters, $sortBy: AdminSort, $first: Int!, $page: Int) {
        listAdmins(first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            data {
                ...AdminListItem
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${adminListItemFragment}
    ${paginatorInfoFragment}
`;
