import { gql } from "@apollo/client";
import { orderFragment } from "../fragments/orderFragment";

export const modifyOrderMutation = gql`
    mutation modifyOrder($increment_id: Int!, $input: OrderModifyInput!, $csrf: String) {
        modifyOrder(increment_id: $increment_id, input: $input, csrf: $csrf) {
            ...Order
        }
    }
    ${orderFragment}
`;
