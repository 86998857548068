import React from "react";
import { Field, FieldProps, FormikProps } from "formik";
import { GalleryFormValues } from "./GalleryForm";
import { SortEnd, arrayMove } from "react-sortable-hoc";
import GallerySortList from "./GallerySortList";
import { Box } from "@material-ui/core";

export type GalleryImage = {
    url: string;
    caption: string | null;
    credit: string | null;
};

type Props = {
    formProps: FormikProps<GalleryFormValues>;
    itemStyle?: React.CSSProperties;
};

export const GalleryFormOrderTab = (props: Props) => {
    return (
        <Box pt="30px">
            <Field name="images">
                {({ field }: FieldProps) => (
                    <GallerySortList
                        value={field.value}
                        axis="xy"
                        itemStyle={props.itemStyle}
                        onSortEnd={({ oldIndex, newIndex }: SortEnd) => {
                            const newImages = arrayMove(field.value, oldIndex, newIndex);
                            props.formProps.setFieldValue("images", newImages);
                        }}
                    />
                )}
            </Field>
        </Box>
    );
};
