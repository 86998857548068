import { gql } from "@apollo/client";
import { categorySelectItemFragment } from "../fragments/categorySelectItemFragment";

export const getCategoriesForSelectQuery = gql`
    query getCategoriesForSelect {
        categories {
            ...CategorySelectItem
        }
    }
    ${categorySelectItemFragment}
`;
