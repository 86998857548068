import React from "react";
import { FormType } from "Components/Form";
import { FormikProps } from "formik";
import { User, UserInput } from "Api/graphql/admin/types";
import { I18n } from "Src/i18n/I18n";
import { PageCard, TabsComponent } from "@bigfish/admin-ui/components";
import { ProfileTab } from "./ProfileTab";
import OrdersTab from "./OrdersTab";
import "./styles.css";

export type UserFormValues = UserInput & { rplus_card_number: string; remove_rplus_card: boolean; replacement_rplus_card: string };

type Props = {
    formType: FormType;
    formProps: FormikProps<UserFormValues>;
    user?: User;
    onRefetchUser: () => Promise<void>;
};

export const UserForm = (props: Props) => {
    const [formTab, setFormTab] = React.useState(0);

    const changeTab = (_event: React.ChangeEvent<{}>, newValue: number) => {
        setFormTab(newValue);
    };

    return (
        <PageCard.Container>
            <TabsComponent
                ariaLabel="admin-user-form-tabs"
                tabs={[
                    {
                        label: I18n.formatMessage({ id: "pages.user.form.profileTabLabel" }),
                        id: "admin-user-form-data-tab",
                        content: <ProfileTab user={props.user} formProps={props.formProps} formType={props.formType} onRefetchUser={props.onRefetchUser} />,
                    },
                    {
                        label: I18n.formatMessage({ id: "pages.user.form.ordersTabLabel" }),
                        id: "admin-user-form-orders-tab",
                        content: <OrdersTab user={props.user} />,
                    },
                ]}
                currentTab={formTab}
                changeTab={changeTab}
            />
        </PageCard.Container>
    );
};
