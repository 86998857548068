import React from "react";
import { Field, FieldProps, FormikProps } from "formik";

import { FullscreenLoader, SavePanel } from "@bigfish/admin-ui/components";
import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, Icon, TextField, Typography } from "@bigfish/admin-ui/core";

import { PaymentMethod, PaymentMethodInput } from "Api/graphql/admin/types";
import { CustomFormHelperText } from "Components/CustomFormHelperText";
import { Form, FormType } from "Components/Form";
import { I18nHelpers } from "I18n/I18nHelpers";
import { I18n } from "Src/i18n/I18n";
import { Constants } from "Utils/Constants";
import { Validator } from "Utils/Validator";

export type PaymentMethodFromValues = PaymentMethodInput;

type Props = {
    formType: FormType;
    formProps: FormikProps<PaymentMethodFromValues>;
    paymentMethod?: PaymentMethod;
};

export const PaymentMethodForm = (props: Props) => {
    return (
        <Form formProps={props.formProps}>
            <Field name="name" validate={I18nHelpers.formatValidator(Validator.required)}>
                {({ field, meta }: FieldProps) => (
                    <TextField
                        type="text"
                        label={I18n.formatMessage({ id: "pages.paymentMethod.form.nameLabel" })}
                        fullWidth
                        variant="outlined"
                        required
                        helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                        error={meta.touched && !!meta.error}
                        {...field}
                    />
                )}
            </Field>

            <Box mt="30px" />

            <Field name="description" validate={I18nHelpers.formatValidator(Validator.paymentMethodDescription)}>
                {({ field, meta }: FieldProps) => (
                    <TextField
                        type="text"
                        label={I18n.formatMessage({ id: "pages.paymentMethod.form.descriptionLabel" })}
                        multiline
                        fullWidth
                        variant="outlined"
                        error={meta.touched && !!meta.error}
                        helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.maxCharacter" }, { max: Constants.paymentMethodDescriptionMaxLength }))}
                        inputProps={{
                            maxLength: Constants.paymentMethodDescriptionMaxLength,
                        }}
                        {...field}
                    />
                )}
            </Field>

            <Box mt="30px" />

            <Field name="cost" validate={I18nHelpers.formatValidator(Validator.mustBeNumber)}>
                {({ field, meta }: FieldProps) => (
                    <TextField
                        type="number"
                        label={I18n.formatMessage({ id: "pages.paymentMethod.form.priceLabel" })}
                        fullWidth
                        variant="outlined"
                        required
                        helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                        error={meta.touched && !!meta.error}
                        {...field}
                    />
                )}
            </Field>

            {props.paymentMethod?.id === "cod" && (
                <>
                    <Box mt="30px" />

                    <Field name="cart_value_max" validate={I18nHelpers.formatValidator(Validator.mustBeNumber)}>
                        {({ field, meta }: FieldProps) => (
                            <TextField
                                type="number"
                                label={I18n.formatMessage({ id: "pages.paymentMethod.form.cartValueMaxLabel" })}
                                fullWidth
                                variant="outlined"
                                required
                                helperText={Form.getHelperText(meta, "")}
                                error={meta.touched && !!meta.error}
                                {...field}
                            />
                        )}
                    </Field>
                </>
            )}

            <Box mt="30px" />

            <Typography variant="body1" component="h3" color="textSecondary">
                {I18n.formatMessage({ id: "pages.paymentMethod.form.statusLabel" })}
            </Typography>

            <Field name="is_active">
                {({ field, meta }: FieldProps) =>
                    field.value !== undefined && (
                        <Box mt="15px" ml="10px">
                            <FormControl error={meta.touched && !!meta.error}>
                                <FormControlLabel
                                    control={<Checkbox {...field} checked={field.value} />}
                                    label={I18n.formatMessage({ id: "pages.paymentMethod.form.isActiveLabel" })}
                                />
                            </FormControl>
                            <CustomFormHelperText meta={meta} />
                        </Box>
                    )
                }
            </Field>

            <SavePanel>
                <Grid container justify="flex-end">
                    <Button
                        type="submit"
                        startIcon={<Icon className="fas fa-save" />}
                        variant="contained"
                        color="secondary"
                        disabled={!props.formProps.dirty || !props.formProps.isValid}
                    >
                        {I18n.formatMessage({ id: `common.${props.formType === FormType.create ? "create" : "save"}` })}
                    </Button>
                </Grid>
            </SavePanel>
            <FullscreenLoader visible={props.formProps.isSubmitting} />
        </Form>
    );
};
