import { gql } from "@apollo/client";

export const giftCardOrderItemFragment = gql`
    fragment GiftCardOrderItem on GiftCardOrderItem {
        id
        quantity
        value
        design
        sub_total
        name_from
        name_to
        mail_to
    }
`;
