/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import { TypeColumn, TypeDataGridProps, TypeRowProps, TypeSingleFilterValue, TypeSingleSortInfo } from "@inovua/reactdatagrid-community/types";
import { DataGridParams, Filterable, Sortable, UrlQueryParser } from "./UrlQueryParser";
import { DataGridUtils, DataSource, DataSourceProps, FilterValue } from "./DataGridUtils";
import { I18n } from "I18n/I18n";
import { dataGridStyles } from "@bigfish/admin-ui/styles";
import { withStyles, WithStyles } from "@bigfish/admin-ui/core";
import { TypeRowReorder } from "@inovua/reactdatagrid-community/types/TypeDataGridProps";
import { Path } from "Utils/Path";

import "@inovua/reactdatagrid-community/index.css";

export type DataGridProps = Partial<Omit<TypeDataGridProps, "dataSource" | "filterType">>;
export interface IntervalFilterValue {
    start?: string;
    end?: string;
}

type ComponentProps<DataType extends Object, SortOrder extends Sortable, Filter extends Filterable> = {
    columns: TypeColumn[];
    filterValues?: FilterValue[] | null;
    dataSource: (props: DataGridParams<SortOrder, Filter>) => Promise<DataSource<DataType>>;
    urlQueryParser: UrlQueryParser<SortOrder, Filter>;
    sortFieldToSortOrder?: { [key: string]: string };
    rowLink?: (rowProps: TypeRowProps) => string;
    enableSelection?: boolean;
    rowReorderColumn?: boolean;
    onRowReorder?: TypeRowReorder | undefined;
    paginationHidden?: boolean;
};

type Props<DataType extends Object, SortOrder extends Sortable, Filter extends Filterable> = DataGridProps &
    ComponentProps<DataType, SortOrder, Filter> &
    RouteComponentProps &
    WithStyles<typeof dataGridStyles>;

class DataGrid<DataType, SortOrder extends Sortable, Filter extends Filterable> extends React.Component<Props<DataType, SortOrder, Filter>> {
    private defaultSkip: number;
    private defaultLimit: number;

    constructor(props: Props<DataType, SortOrder, Filter>) {
        super(props);
        const params = this.props.urlQueryParser.parse(this.props.location.search);
        this.defaultLimit = params.limit;
        this.defaultSkip = params.page * this.defaultLimit;
    }

    componentDidMount(): void {
        if (!this.props.location.search) {
            const params = this.props.urlQueryParser.parse(this.props.location.search);
            const search = this.props.urlQueryParser.getUrlQueryString(params);
            this.props.history.replace({ pathname: this.props.history.location.pathname, search });
        }
    }

    private onFilterValueChange = (filters: TypeSingleFilterValue[] | null): void => {
        const params = this.props.urlQueryParser.parse(this.props.location.search);
        if (filters) {
            if (!params.filters) {
                params.filters = {} as Filter;
            }
            filters.forEach(v => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                params.filters[v.name] = v.value;
            });
        }
        this.props.history.push({ pathname: this.props.history.location.pathname, search: this.props.urlQueryParser.getUrlQueryString(params) });
    };

    private onSortInfoChange = (sortInfos: TypeSingleSortInfo[] | TypeSingleSortInfo | null): void => {
        const urlParams = this.props.urlQueryParser.parse(this.props.location.search);
        if (!sortInfos) {
            urlParams.sortBy = undefined;
        } else {
            const sortInfo = Array.isArray(sortInfos) ? sortInfos[0] : sortInfos;
            urlParams.sortBy = { direction: DataGridUtils.getOrderDirection(sortInfo.dir), field: sortInfo.name } as SortOrder;
        }
        const searchParams = this.props.urlQueryParser.getUrlQueryString(urlParams);
        if (this.props.location.search !== searchParams) {
            this.props.history.push({ pathname: this.props.history.location.pathname, search: searchParams });
        }
    };

    private onLimitChange = (limit: number): void => {
        const params = this.props.urlQueryParser.parse(this.props.location.search);
        params.limit = limit;
        this.props.history.push({ pathname: this.props.location.pathname, search: this.props.urlQueryParser.getUrlQueryString(params) });
    };

    private onSkipChange = (skip: number): void => {
        const params = this.props.urlQueryParser.parse(this.props.location.search);
        params.page = 1 + Math.round(skip / params.limit); // page value must be at least 1
        this.props.history.push({ pathname: this.props.location.pathname, search: this.props.urlQueryParser.getUrlQueryString(params) });
    };

    private dataSource = (values: DataSourceProps<DataType>): Promise<DataSource<DataType>> => {
        const page = 1 + Math.round(values.skip / values.limit); // page value must be at least 1
        const filterValues = this.props.urlQueryParser.parse(this.props.location.search);
        filterValues.limit = values.limit;
        if (filterValues.page !== page) {
            filterValues.page = page;
        }
        return this.props.dataSource(filterValues);
    };

    private onRowClick = (rowProps: TypeRowProps, event: MouseEvent) => {
        if (this.props.rowLink) {
            const path = this.props.rowLink(rowProps);
            if (event.ctrlKey || event.metaKey) {
                window.open(path, "_blank");
            } else {
                this.props.history.push(path);
            }
        }
    };

    public render() {
        const isOrderPage = [Path.storeOrderList, Path.orderList].includes(this.props.location.pathname);
        const pageSizes = isOrderPage ? [25, 50, 100, 1000, 10000] : [25, 50, 100];

        return (
            <ReactDataGrid
                className={this.props.classes.dataGrid}
                showColumnMenuTool={false}
                idProperty="id"
                pagination={this.props.paginationHidden ? false : "remote"}
                loadingText={I18n.formatMessage({ id: "common.loading" })}
                emptyText={I18n.formatMessage({ id: "components.dataGrid.emptyText" })}
                i18n={I18n.dataGrid}
                remoteSort={true}
                defaultLimit={this.defaultLimit}
                defaultSkip={this.defaultSkip}
                renderColumnFilterContextMenu={() => null}
                resizable={false}
                enableSelection={this.props.enableSelection || false}
                defaultSelected={false}
                showHeader={true}
                showEmptyRows={false}
                showHoverRows={true}
                showZebraRows={false}
                columnUserSelect={true}
                showCellBorders="horizontal"
                remoteFilter={true}
                rowStyle={this.props.rowLink && { cursor: "pointer" }}
                {...this.props}
                columns={this.props.columns.map(column => {
                    return { ...column, resizable: column.resizable ?? true };
                })}
                onRowClick={this.props.onRowClick ?? this.onRowClick}
                {...DataGridUtils.urlQueryToDataGridParams(this.props.urlQueryParser.parse(this.props.location.search), this.props.sortFieldToSortOrder, this.props.filterValues)}
                dataSource={this.dataSource}
                onFilterValueChange={this.onFilterValueChange}
                onSortInfoChange={this.onSortInfoChange}
                onLimitChange={this.onLimitChange}
                onSkipChange={this.onSkipChange}
                activateRowOnFocus={false}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                pageSizes={pageSizes}
                rowHeight={this.props.rowHeight ?? 50}
                style={this.props.style ?? { minHeight: 800 }}
                rowReorderColumn={this.props.rowReorderColumn}
                onRowReorder={this.props.onRowReorder}
            />
        );
    }
}

export default withRouter(withStyles(dataGridStyles)(DataGrid));
