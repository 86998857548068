import { gql } from "@apollo/client";
import { galleryImageFragment } from "../fragments/galleryImageFragment";

export const getGalleryImagesQuery = gql`
    query getGalleryImages($id: ID!) {
        galleries(first: 1, filters: { id: $id }) {
            data {
                id
                images {
                    ...GalleryImage
                }
            }
        }
    }
    ${galleryImageFragment}
`;
