import { gql } from "@apollo/client";
import { permissionFragment } from "./permissionFragment";

export const roleFragment = gql`
    fragment Role on Role {
        id
        name
        title
        created_at
        updated_at
        permissions {
            ...Permission
        }
    }
    ${permissionFragment}
`;
