import { gql } from "@apollo/client";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";
import { storeOrderListItemFragment } from "../fragments/storeOrderListItemFragment";

export const listStoreOrdersQuery = gql`
    query listStoreOrders($filters: OrderFilters, $sortBy: OrderSort, $first: Int!, $page: Int) {
        listOrders(first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            data {
                ...StoreOrderListItem
            }
            aggregations {
                sum_grand_total
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${storeOrderListItemFragment}
    ${paginatorInfoFragment}
`;
