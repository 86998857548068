import { gql } from "@apollo/client";
import { adminFragment } from "../fragments/adminFragment";

export const getAdminQuery = gql`
    query getAdmin($id: Int!) {
        listAdmins(first: 1, filters: { id: $id }) {
            data {
                ...Admin
            }
        }
    }
    ${adminFragment}
`;
