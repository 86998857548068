import React from "react";
import { Api } from "Api/Api";
import { TypeRowProps } from "@inovua/reactdatagrid-community/types";
import DataGrid from "Components/DataGrid/DataGrid";
import { BadgeFilters, BadgeSort, BadgeListItem, BadgeType, BadgeSortField, listBadges_listBadges_data } from "Api/graphql/admin/types";
import { ApiError } from "Api/ApiError";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { Checkbox, withStyles, WithStyles } from "@bigfish/admin-ui/core";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import { Path } from "Utils/Path";
import { dataGridStyles } from "@bigfish/admin-ui/styles";
import { Constants } from "Utils/Constants";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import { I18n } from "I18n/I18n";
import { connect } from "react-redux";
import { AuthSelectors } from "Redux/selectors/authSelectors";
import { ApplicationState } from "Redux/rootReducer";
import { PermissionType } from "Api/graphql/admin/types";
import { DataGridParams } from "Components/DataGrid/UrlQueryParser";
import { DataSource, FilterValue } from "Components/DataGrid/DataGridUtils";
import { DataGridUrlQueryParser, QueryParserType } from "Components/DataGrid/DataGridUrlQueryParser";
import { ListItemActive } from "Components/ListItemActive";
import { ObjectUtils } from "Utils/ObjectUtils";
import { CustomTypeColumn } from "Utils/DataGridUtils";

type ComponentProps = {
    isSingleSelect?: boolean;
    selectedBadgeIds?: number[];
    onAddBadges?(products: BadgeListItem[]): void;
    onRemoveBadges?(products: BadgeListItem[]): void;
    minWidth?: number;
    disableRowClick?: boolean;
};

type ReduxProps = {
    permissions: PermissionType[];
    isSuperadmin: boolean;
};

type Props = ReduxProps & ComponentProps & WithSnackbarProps & WithStyles<typeof dataGridStyles>;

class BadgeListDataGrid extends React.Component<Props> {
    private getColumns = (): CustomTypeColumn[] => {
        return [
            ...(this.props.selectedBadgeIds
                ? [
                      {
                          name: "selected",
                          header: "",
                          sortable: false,
                          defaultWidth: 60,
                          textAlign: "center",
                          filterEditor: () => {
                              const isAllSelected = this.data.length > 0 && this.data.every(data => this.props.selectedBadgeIds?.includes(data.id));
                              const indeterminate = !isAllSelected && this.data.some(data => this.props.selectedBadgeIds?.includes(data.id));
                              return (
                                  <Checkbox
                                      checked={isAllSelected}
                                      indeterminate={indeterminate}
                                      onChange={() => (isAllSelected ? this.props.onRemoveBadges?.(this.data) : this.props.onAddBadges?.(this.data))}
                                  />
                              );
                          },
                          filterable: true,
                          render: ({ data }: { data: BadgeListItem }) => {
                              const isAdded = this.props.selectedBadgeIds?.includes(data.id);
                              return (
                                  <div>
                                      <Checkbox checked={isAdded} onChange={() => (isAdded ? this.props.onRemoveBadges?.([data]) : this.props.onAddBadges?.([data]))} />
                                  </div>
                              );
                          },
                      } as CustomTypeColumn,
                  ]
                : []),
            { name: "id", header: I18n.formatMessage({ id: "pages.badgeList.grid.column.id" }), defaultWidth: 100, filterDelay: Constants.filterDelayMS },
            {
                name: "name",
                header: I18n.formatMessage({ id: "pages.badgeList.grid.column.name" }),
                defaultFlex: 1,
                minWidth: 336,
                filterDelay: Constants.filterDelayMS,
            },
            {
                name: "type",
                defaultFlex: 1,
                minWidth: 336,
                header: I18n.formatMessage({ id: "pages.badgeList.grid.column.type" }),
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: [
                        ...ObjectUtils.enumAsArray<BadgeType>(BadgeType).map((type: BadgeType) => {
                            return { id: type, label: I18n.formatMessage({ id: `enums.badgeType.${type}` }) };
                        }),
                    ],
                },
                render: ({ data }: { data: BadgeListItem }) => {
                    return <div>{I18n.formatMessage({ id: `enums.badgeType.${data.type}` })}</div>;
                },
            },
            {
                name: "created_at",
                filterEditor: DateFilter,
                header: I18n.formatMessage({ id: "pages.badgeList.grid.column.created_at" }),
                defaultWidth: Constants.columnWidth.dateTime,
                render: ({ data }: { data: BadgeListItem }) => {
                    return <div>{data.created_at ? DateUtils.format(data.created_at, DateFormat.minuteDateTime) : ""}</div>;
                },
            },
            {
                name: "is_visible",
                textAlign: "center",
                header: I18n.formatMessage({ id: "pages.badgeList.grid.column.is_visible" }),
                defaultWidth: Constants.columnWidth.statusIcon,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: [
                        { id: "true", label: I18n.formatMessage({ id: "common.form.isActive.true" }).toLowerCase() },
                        { id: "false", label: I18n.formatMessage({ id: "common.form.isActive.false" }).toLowerCase() },
                    ],
                },
                render: ({ data }: { data: BadgeListItem }) => <ListItemActive isActive={data.is_visible} fromDate={data.active_from} />,
            },
        ];
    };

    private filterValues = (): FilterValue[] => {
        const filterValues = [
            { name: "id", operator: "eq", type: "number" },
            { name: "name", operator: "contains", type: "string" },
            { name: "type", operator: "eq", type: "select" },
            { name: "created_at", operator: "inrange", type: "date" },
            { name: "is_visible", operator: "eq", type: "select" },
        ];
        if (!this.props.isSingleSelect) {
            filterValues.push({ name: "selected", operator: "eq", type: "select" });
        }
        return filterValues;
    };

    private urlQueryParser = new DataGridUrlQueryParser({
        filters: {
            id: QueryParserType.integer,
            name: QueryParserType.string,
            type: QueryParserType.enum,
            created_at: QueryParserType.dateRange,
            is_visible: QueryParserType.boolean,
        },
        sortField: BadgeSortField,
    });

    private sortFieldToSortOrder = {
        [BadgeSortField.id]: "id",
        [BadgeSortField.name]: "name",
        [BadgeSortField.type]: "type",
        [BadgeSortField.created_at]: "created_at",
        [BadgeSortField.is_visible]: "is_visible",
    };

    private data: listBadges_listBadges_data[] = [];

    private dataSource = async (props: DataGridParams<BadgeSort, BadgeFilters>): Promise<DataSource<BadgeListItem>> => {
        try {
            const result = await Api.listBadges({
                first: props.limit,
                page: props.page,
                filters: props.filters,
                sortBy: props.sortBy,
            });
            this.data = result.data;
            return { data: result.data, count: result.paginatorInfo.total };
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }
        return { data: [], count: 0 };
    };

    private getRowLink = (rowProps: TypeRowProps): string => {
        return Path.badgeEdit(rowProps.data.id);
    };

    render() {
        return (
            <DataGrid
                className={this.props.classes.dataGrid}
                urlQueryParser={this.urlQueryParser}
                rowHeight={50}
                style={{ minHeight: this.props.minWidth ?? 800 }}
                dataSource={this.dataSource}
                filterValues={this.filterValues()}
                columns={this.getColumns()}
                rowLink={this.props.disableRowClick ? undefined : this.getRowLink}
                activeCell={null}
                sortFieldToSortOrder={this.sortFieldToSortOrder}
                onRowClick={!this.props.disableRowClick && (this.props.isSuperadmin || this.props.permissions.includes(PermissionType.badge_update)) ? undefined : () => {}}
            />
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { permissions: AuthSelectors.getPermissions(state.auth), isSuperadmin: AuthSelectors.isSuperadmin(state.auth) };
};

export default withSnackbar(connect(mapStateToProps)(withStyles(dataGridStyles, { withTheme: true })(BadgeListDataGrid)));
