import React from "react";
import { Api } from "Api/Api";
import { TypeRowProps } from "@inovua/reactdatagrid-community/types";
import DataGrid from "Components/DataGrid/DataGrid";
import { DataGridUrlQueryParser, QueryParserType } from "Components/DataGrid/DataGridUrlQueryParser";
import { ApiError } from "Api/ApiError";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { withStyles, WithStyles } from "@bigfish/admin-ui/core";
import { dataGridStyles } from "@bigfish/admin-ui/styles";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import { SettingFilters, SettingSort, SettingSortField, SettingType, SystemSettingListItem, PermissionType } from "Api/graphql/admin/types";
import { I18n } from "I18n/I18n";
import { DataSource, FilterValue } from "Components/DataGrid/DataGridUtils";
import { DataGridParams } from "Components/DataGrid/UrlQueryParser";
import { Constants } from "Utils/Constants";
import { ApplicationState } from "Redux/rootReducer";
import { AuthSelectors } from "Redux/selectors/authSelectors";
import { connect } from "react-redux";
import { EditModal } from "./EditModal";
import { CustomTypeColumn } from "Utils/DataGridUtils";
import { NameWithAvatar } from "Components/NameWithAvatar/NameWithAvatar";

type ReduxProps = {
    permissions: PermissionType[];
    isSuperadmin: boolean;
};

type Props = WithSnackbarProps & WithStyles<typeof dataGridStyles> & ReduxProps;

export type EditedSetting = {
    id: string;
    type: SettingType;
    value: string;
    description: string;
};

type State = {
    isEditModalVisible: boolean;
    editedSetting: EditedSetting | null;
    refreshIndex: number;
};

class SystemSettingListDataGrid extends React.Component<Props, State> {
    public readonly state: State = {
        isEditModalVisible: false,
        editedSetting: null,
        refreshIndex: 0,
    };

    private getColumns = (): CustomTypeColumn[] => {
        return [
            { name: "id", header: I18n.formatMessage({ id: "pages.systemSettingList.grid.column.id" }), defaultWidth: 294, editable: false, filterDelay: Constants.filterDelayMS },
            {
                name: "value",
                header: I18n.formatMessage({ id: "pages.systemSettingList.grid.column.value" }),
                defaultFlex: 1,
                minWidth: 200,
                sortable: false,
            },
            { name: "description", header: I18n.formatMessage({ id: "pages.systemSettingList.grid.column.description" }), defaultWidth: 294, editable: false, sortable: false },
            {
                name: "updated_by",
                header: I18n.formatMessage({ id: "pages.systemSettingList.grid.column.updated_by" }),
                defaultWidth: 200,
                editable: false,
                sortable: false,
                render: ({ data }: { data: SystemSettingListItem }) => {
                    return data.updated_by ? <NameWithAvatar identifier={data.updated_by.id ?? Math.floor(Math.random() * 1000)} value={data.updated_by?.name || ""} /> : null;
                },
            },
            {
                name: "updated_at",
                defaultWidth: Constants.columnWidth.dateTime,
                header: I18n.formatMessage({ id: "pages.systemSettingList.grid.column.updated_at" }),
                filterEditor: DateFilter,
                dateFormat: "YYYY-MM-DD",
                editable: false,
            },
        ];
    };

    private filterValues: FilterValue[] = [{ name: "id", operator: "contains", type: "string" }];

    private urlQueryParser = new DataGridUrlQueryParser({
        filters: {
            id: QueryParserType.string,
        },
        sortField: SettingSortField,
    });

    private sortFieldToSortOrder = {
        [SettingSortField.id]: "id",
        [SettingSortField.updated_at]: "updated_at",
    };

    private onSubmit = async (id: string, value: string, description: string): Promise<void> => {
        try {
            await Api.updateSetting(id, { value: !value ? [] : [value], description });
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.systemSettingList.onEditSubmit.succeed" }), { variant: "success" });
            this.setState({ refreshIndex: this.state.refreshIndex + 1 }, this.onModalClose);
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.systemSettingList.onEditSubmit.error" }), { variant: "error" });
        }
    };

    private dataSource = async (props: DataGridParams<SettingSort, SettingFilters>): Promise<DataSource<SystemSettingListItem>> => {
        try {
            const result = await Api.listSystemSettings({
                first: props.limit,
                page: props.page,
                filters: props.filters,
                sortBy: props.sortBy,
            });
            return { data: result.data, count: result.paginatorInfo.total };
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }
        return { data: [], count: 0 };
    };

    private onModalClose = () => {
        this.setState({ isEditModalVisible: false, editedSetting: null });
    };

    render() {
        const { editedSetting } = this.state;

        return (
            <div>
                <DataGrid
                    key={this.state.refreshIndex}
                    className={this.props.classes.dataGrid}
                    urlQueryParser={this.urlQueryParser}
                    rowHeight={50}
                    style={{ minHeight: 800 }}
                    dataSource={this.dataSource}
                    filterValues={this.filterValues}
                    columns={this.getColumns()}
                    activeCell={null}
                    sortFieldToSortOrder={this.sortFieldToSortOrder}
                    editable={this.props.isSuperadmin || this.props.permissions.includes(PermissionType.setting_update)}
                    editStartEvent="click"
                    onRowClick={
                        this.props.isSuperadmin || this.props.permissions.includes(PermissionType.setting_update)
                            ? (rowProps: TypeRowProps) => {
                                  this.setState({
                                      isEditModalVisible: true,
                                      editedSetting: {
                                          id: rowProps.data.id,
                                          value: rowProps.data.value[0],
                                          type: rowProps.data.type,
                                          description: rowProps.data.description,
                                      },
                                  });
                              }
                            : undefined
                    }
                />
                {editedSetting && <EditModal isVisible={this.state.isEditModalVisible} onClose={this.onModalClose} onSaveClick={this.onSubmit} editedSetting={editedSetting} />}
            </div>
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { permissions: AuthSelectors.getPermissions(state.auth), isSuperadmin: AuthSelectors.isSuperadmin(state.auth) };
};

export default withSnackbar(connect(mapStateToProps)(withStyles(dataGridStyles, { withTheme: true })(SystemSettingListDataGrid)));
