import { Box, Typography } from "@bigfish/admin-ui/core";
import { ActiveBreadcrumbItem } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { ProductVariantForm, ProductVariantFormValues, productVariantValidator } from "./ProductVariantForm";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { PageLayout } from "Components/PageLayout";
import { Link } from "Components/Link";
import { Breadcrumbs } from "Components/Breadcrumbs";
import Prompt from "Components/Prompt";
import { I18n } from "I18n/I18n";
import { DateFormat, DateUtils } from "Utils/DateUtils";

type Props = RouteComponentProps & WithSnackbarProps;

type State = {
    isCreateSucceed: boolean;
};

class ProductVariantCreatePage extends React.Component<Props, State> {
    public readonly state: State = {
        isCreateSucceed: false,
    };

    private onSubmit = async (values: ProductVariantFormValues, formikHelpers: FormikHelpers<ProductVariantFormValues>, isRedirectHandled?: boolean): Promise<boolean> => {
        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const productVariantId = await Api.createProductVariant({
                ...values,
                children: values.children.map(c => c.id),
                active_from: values.active_from ? DateUtils.format(values.active_from, DateFormat.apiDateTime) : null,
                active_to: values.active_to ? DateUtils.format(values.active_to, DateFormat.apiDateTime) : null,
            });
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.productVariant.onCreateSubmit.succeed" }), { variant: "success" });
            if (!isRedirectHandled) {
                this.setState({ isCreateSucceed: true }, () => this.props.history.push(Path.productVariantEdit(`${productVariantId}`)));
            }
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.productVariant.onCreateSubmit.error" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
            return false;
        }
    };

    public render() {
        if (this.state.isCreateSucceed) {
            return <Redirect to={Path.productVariantList} />;
        }

        const initialValues: ProductVariantFormValues = {
            product_param_id: "",
            children: [],
            name: "",
            is_active: true,
            active_from: null,
            active_to: null,
        };

        return (
            <PageLayout
                breadcrumb={
                    <Breadcrumbs>
                        <Link to={Path.productVariantList} color="inherit">
                            {I18n.formatMessage({ id: "pages.productVariant.createBreadcrumb" })}
                        </Link>
                        <ActiveBreadcrumbItem aria-current="page">{I18n.formatMessage({ id: "pages.productVariant.new" })}</ActiveBreadcrumbItem>
                    </Breadcrumbs>
                }
            >
                <Typography variant="h1">{I18n.formatMessage({ id: "pages.productVariant.new" })}</Typography>
                <Formik initialValues={initialValues} onSubmit={this.onSubmit} validate={productVariantValidator()} validateOnBlur={false}>
                    {props => (
                        <>
                            <ProductVariantForm formType={FormType.create} formProps={props} />
                            <Prompt when={props.dirty && !this.state.isCreateSucceed} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props, true)} />
                        </>
                    )}
                </Formik>
                <Box mt="40px" />
            </PageLayout>
        );
    }
}

export default withRouter(withSnackbar(ProductVariantCreatePage));
