import React from "react";
import { Api } from "Api/Api";
import { TypeRowProps } from "@inovua/reactdatagrid-community/types";
import DataGrid from "Components/DataGrid/DataGrid";
import { AdminFilters, AdminSort, AdminListItem, AdminSortField, RoleListItem } from "Api/graphql/admin/types";
import { StringUtils } from "Utils/StringUtils";
import { DataGridUrlQueryParser, QueryParserType } from "Components/DataGrid/DataGridUrlQueryParser";
import { ApiError } from "Api/ApiError";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { Grid, Icon, withStyles, WithStyles } from "@bigfish/admin-ui/core";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import { Path } from "Utils/Path";
import { I18n } from "I18n/I18n";
import { ColoredAvatar } from "@bigfish/admin-ui/components";
import { connect } from "react-redux";
import { ApplicationState } from "Redux/rootReducer";
import { AuthSelectors } from "Redux/selectors/authSelectors";
import { PermissionType } from "Api/graphql/admin/types";
import { DataGridParams } from "Components/DataGrid/UrlQueryParser";
import { DataSource, FilterValue } from "Components/DataGrid/DataGridUtils";
import { dataGridStyles } from "@bigfish/admin-ui/styles";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import { CustomTypeColumn } from "Utils/DataGridUtils";
import { Constants } from "Utils/Constants";

type ReduxProps = {
    permissions: PermissionType[];
    isSuperadmin: boolean;
};

type Props = ReduxProps & WithSnackbarProps & WithStyles<typeof dataGridStyles>;

type State = {
    roleSelectItems: RoleListItem[];
};

class AdminListDataGrid extends React.Component<Props, State> {
    public readonly state: State = {
        roleSelectItems: [],
    };

    public componentDidMount = async (): Promise<void> => {
        try {
            const { data: roleSelectItems } = await Api.listRoles({ first: 9999 });
            this.setState({ roleSelectItems });
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }
    };

    private getColumns = (): CustomTypeColumn[] => {
        return [
            { name: "id", header: I18n.formatMessage({ id: "pages.adminList.grid.column.id" }), width: 132, filterDelay: Constants.filterDelayMS },
            {
                name: "name",
                header: I18n.formatMessage({ id: "pages.adminList.grid.column.name" }),
                defaultWidth: 380,
                filterDelay: Constants.filterDelayMS,
                render: ({ data, value }: { data: AdminListItem; value: string }) => {
                    return (
                        <Grid container direction="row" alignItems="center" alignContent="center" spacing={3}>
                            <Grid item>
                                <ColoredAvatar identifier={data.id} sizes="20 20">
                                    {StringUtils.getNameInitials(value)}
                                </ColoredAvatar>
                            </Grid>
                            <Grid item>{value}</Grid>
                        </Grid>
                    );
                },
            },
            { name: "email", header: I18n.formatMessage({ id: "pages.adminList.grid.column.email" }), defaultFlex: 1, minWidth: 200, filterDelay: Constants.filterDelayMS },
            {
                name: "last_login",
                filterEditor: DateFilter,
                header: I18n.formatMessage({ id: "pages.adminList.grid.column.lastLogin" }),
                defaultFlex: 1,
                minWidth: 357,
                render: ({ data }: { data: AdminListItem }) => {
                    return <div>{data.last_login ? DateUtils.format(data.last_login, DateFormat.minuteDateTime) : ""}</div>;
                },
            },
            {
                name: "roles",
                sortable: false,
                header: I18n.formatMessage({ id: "pages.adminList.grid.column.role" }),
                defaultFlex: 1,
                minWidth: 258,
                render: ({ data }: { data: AdminListItem }) => {
                    return <div>{data.roles.map((role, i) => `${role.title}${i === data.roles.length - 1 ? "" : ", "}`)}</div>;
                },
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: this.state.roleSelectItems.map(r => {
                        return { id: r.name, label: r.title };
                    }),
                },
            },
            {
                textAlign: "center",
                width: 100,
                render: ({ value }: { value: boolean }) => {
                    if (value) {
                        return <Icon className="fa fa-check-circle" color="secondary" title={I18n.formatMessage({ id: "pages.adminList.grid.column.status.active" })} />;
                    }
                    return (
                        <Icon
                            className="fa fa-times-circle"
                            color="primary"
                            style={{ color: "#BDC2E6" }}
                            title={I18n.formatMessage({ id: "pages.adminList.grid.column.status.inactive" })}
                        />
                    );
                },
                sortable: false,
                name: "is_active",
                header: I18n.formatMessage({ id: "pages.adminList.grid.column.status" }),
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: [
                        { id: "true", label: I18n.formatMessage({ id: "pages.adminList.grid.column.status.active" }) },
                        { id: "false", label: I18n.formatMessage({ id: "pages.adminList.grid.column.status.inactive" }) },
                    ],
                },
            },
        ];
    };

    private filterValues: FilterValue[] = [
        { name: "id", operator: "eq", type: "number" },
        { name: "name", operator: "contains", type: "string" },
        { name: "last_login", operator: "inrange", type: "date" },
        { name: "email", operator: "contains", type: "string" },
        { name: "is_active", operator: "eq", type: "select" },
        { name: "roles", operator: "inlist", type: "select" },
    ];

    private urlQueryParser = new DataGridUrlQueryParser({
        filters: {
            id: QueryParserType.integer,
            name: QueryParserType.string,
            last_login: QueryParserType.dateRange,
            email: QueryParserType.string,
            is_active: QueryParserType.boolean,
            roles: QueryParserType.enum,
        },
        sortField: AdminSortField,
    });

    private sortFieldToSortOrder = {
        [AdminSortField.id]: "id",
        [AdminSortField.email]: "email",
        [AdminSortField.name]: "name",
        [AdminSortField.last_login]: "last_login",
    };

    private dataSource = async (props: DataGridParams<AdminSort, AdminFilters>): Promise<DataSource<AdminListItem>> => {
        try {
            const result = await Api.listAdmins({
                first: props.limit,
                page: props.page,
                filters: {
                    ...props.filters,
                    roles: props.filters?.roles ? `${props.filters.roles}`.split(",") : undefined,
                },
                sortBy: props.sortBy,
            });
            return { data: result.data, count: result.paginatorInfo.total };
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }
        return { data: [], count: 0 };
    };

    private getRowLink = (rowProps: TypeRowProps): string => {
        return Path.adminEdit(rowProps.data.id);
    };

    render() {
        return (
            <DataGrid
                urlQueryParser={this.urlQueryParser}
                rowHeight={50}
                style={{ minHeight: 800 }}
                dataSource={this.dataSource}
                filterValues={this.filterValues}
                columns={this.getColumns()}
                rowLink={this.getRowLink}
                onRowClick={this.props.isSuperadmin || this.props.permissions.includes(PermissionType.admin_update) ? undefined : () => {}}
                activeCell={null}
                sortFieldToSortOrder={this.sortFieldToSortOrder}
            />
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { permissions: AuthSelectors.getPermissions(state.auth), isSuperadmin: AuthSelectors.isSuperadmin(state.auth) };
};

export default withSnackbar(connect(mapStateToProps)(withStyles(dataGridStyles, { withTheme: true })(AdminListDataGrid)));
