import { gql } from "@apollo/client";
import { departmentFragment } from "../fragments/departmentFragment";

export const getDepartmentQuery = gql`
    query getDepartment($id: ID!) {
        department(id: $id) {
            ...Department
        }
    }
    ${departmentFragment}
`;
