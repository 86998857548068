import { gql } from "@apollo/client";
import { badgeListItemFragment } from "./badgeListItemFragment";
import { productCatalogItemFragment } from "./productCatalogItemFragment";
import { productFilterFragment } from "./productFilterFragment";

export const productListFragment = gql`
    fragment ProductList on ProductList {
        id
        type
        name
        slug
        mode
        is_active
        active_from
        active_to
        title
        subtitle
        image
        image_title
        image_mobile
        image_mobile_title
        products {
            ...ProductCatalogItem
        }
        group_by_category
        badge {
            ...BadgeListItem
        }
        og_image
        og_title
        og_description
        meta_description
        created_at
        created_by {
            id
            name
        }
        updated_at
        updated_by {
            id
            name
        }
        filters {
            ...ProductFilter
        }
    }
    ${badgeListItemFragment}
    ${productFilterFragment}
    ${productCatalogItemFragment}
`;
