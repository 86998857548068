import { gql } from "@apollo/client";

export const subscriptionOrderFragment = gql`
    fragment SubscriptionOrder on Order {
        increment_id
        status {
            title
        }
        delivery_day_expected
        created_at
    }
`;
