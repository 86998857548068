import { createStore, applyMiddleware, compose, Store, Middleware } from "redux";
import { persistStore } from "redux-persist";
import { enableBatching } from "redux-batched-actions";
import { rootReducer, ApplicationState } from "./rootReducer";
import { ReduxAction } from "./actions/reduxAction";

const middlewares: Middleware[] = [];

const enableReduxExtension = process.env.REACT_APP_DEBUG_MODE === "true";
const composeEnhancers = (enableReduxExtension && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const enhancer = composeEnhancers(applyMiddleware(...middlewares));

const store: Store<ApplicationState, ReduxAction> = createStore(enableBatching(rootReducer), undefined, enhancer);

const persistor = persistStore(store);

export { persistor, store };
