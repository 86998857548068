import React from "react";
import { Field, FieldProps, FormikProps } from "formik";
import { GalleryFormValues } from "./GalleryForm";
import { FormControl, TextField, FormHelperText, Box } from "@bigfish/admin-ui/core";
import { ImageFieldSet } from "Components/Inputs/ImageFieldSet";
import { I18n } from "I18n/I18n";
import { I18nHelpers } from "I18n/I18nHelpers";
import { Validator } from "Utils/Validator";
import { FormType } from "Components/Form";

export type GalleryImage = {
    url: string;
    caption: string | null;
    credit: string | null;
};

type Props = {
    formProps: FormikProps<GalleryFormValues>;
    formType: FormType;
    onDeleteClick?: () => void;
};

export const GalleryFormContentTab = (props: Props) => {
    return (
        <>
            <Box pt="30px">
                <Field name="title" validate={I18nHelpers.formatValidator(Validator.required)}>
                    {({ field, meta }: FieldProps) => (
                        <TextField
                            type="text"
                            label={I18n.formatMessage({ id: "pages.gallery.form.title" })}
                            fullWidth
                            variant="outlined"
                            helperText={I18n.formatMessage({ id: "common.required" })}
                            {...field}
                            required
                            error={meta.touched && !!meta.error}
                        />
                    )}
                </Field>
            </Box>
            <Box pt="30px">
                <Field name="handle">
                    {({ field, meta }: FieldProps) => (
                        <TextField
                            type="text"
                            label={I18n.formatMessage({ id: "pages.gallery.form.handle" })}
                            fullWidth
                            variant="outlined"
                            helperText={I18n.formatMessage({ id: "common.optional" })}
                            {...field}
                            required
                            error={meta.touched && !!meta.error}
                            disabled={props.formType === FormType.edit}
                        />
                    )}
                </Field>
            </Box>
            <Field name="images" validate={I18nHelpers.formatValidator(Validator.galleryImagesLength)}>
                {({ field, meta }: FieldProps) => {
                    const images = field.value;
                    if (meta.error) {
                        return (
                            <FormControl error={true}>
                                <FormHelperText>{meta.error}</FormHelperText>
                            </FormControl>
                        );
                    }
                    const onChange = (index: number) => (image: GalleryImage | null) => {
                        const newImages = [...images];
                        if (image) {
                            newImages[index] = { ...image };
                        } else {
                            newImages.splice(index, 1);
                        }
                        props.formProps.setFieldValue("images", newImages);
                    };

                    return images.map((image: GalleryImage, index: number) => (
                        <ImageFieldSet key={index} id={field.name + index} name={`image-${index}`} value={image} onChange={onChange(index)} />
                    ));
                }}
            </Field>
        </>
    );
};
