import { gql } from "@apollo/client";
import { giftCardOrderTypeWithTitleFragment } from "../fragments/giftCardOrderTypeWithTitleFragment";

export const getGiftCardOrderTypesQuery = gql`
    query getGiftCardOrderTypes {
        giftCardOrderType {
            ...GiftCardOrderTypeWithTitle
        }
    }
    ${giftCardOrderTypeWithTitleFragment}
`;
