import { store } from "Redux/store";

export class MediaLibraryHelper {
    public static openLibrary(options: { maximumSelectableAsset?: number; onSelection: (assets: string[]) => void }) {
        window.bfml.openLibrary({
            embedAuthToken: store.getState().auth.mediaLibraryToken!,
            maximumSelectableAsset: options.maximumSelectableAsset,
            onSelection: options.onSelection,
            navbarColor: "#D7183C",
        });
    }

    public static getImageUrl(assetUrl: string): string {
        return `${process.env.REACT_APP_MEDIA_LIBRARY_ASSET_URL}${assetUrl}`;
    }
}
