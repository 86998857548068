import { gql } from "@apollo/client";

export const subscriptionItemFragment = gql`
    fragment SubscriptionItem on OrderSubscriptionItem {
        id
        product {
            id
            name
        }
        quantity
    }
`;
