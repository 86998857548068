import { gql } from "@apollo/client";
import { pageForSelectFragment } from "../fragments/pageForSelectFragment";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";

export const listPagesForSelectQuery = gql`
    query listPagesForSelect($filters: PageFilters, $sortBy: PageSort, $first: Int!, $page: Int) {
        listPages(first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            data {
                ...PageForSelect
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${pageForSelectFragment}
    ${paginatorInfoFragment}
`;
