import { gql } from "@apollo/client";
import { shippingMethodFragment } from "../fragments/shippingMethodFragment";

export const getShippingMethodByIdQuery = gql`
    query getShippingMethodById($first: Int!, $filters: ShippingMethodFilters) {
        listShippingMethods(first: $first, filters: $filters) {
            data {
                ...ShippingMethod
            }
        }
    }
    ${shippingMethodFragment}
`;
