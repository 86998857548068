import { gql } from "@apollo/client";

export const productImageFragment = gql`
    fragment ProductImage on ProductImage {
        id
        source
        position
        url_list
        is_active
    }
`;
