import { gql } from "@apollo/client";
import { pageFragment } from "../fragments/pageFragment";

export const getPageByIdQuery = gql`
    query getPageById($id: Int!) {
        page(id: $id) {
            ...Page
        }
    }
    ${pageFragment}
`;
