import { ActiveBreadcrumbItem } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Permission, Role } from "Api/graphql/admin/types";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { RoleForm, RoleFormValues } from "./RoleForm";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { Loading } from "Components/Loading";
import { PageLayout } from "Components/PageLayout";
import { Link } from "Components/Link";
import { Breadcrumbs } from "Components/Breadcrumbs";
import Prompt from "Components/Prompt";
import { DeleteRoleConfirmModal } from "./DeleteRoleConfirmModal";
import { Box } from "@bigfish/admin-ui/core";
import { I18n } from "I18n/I18n";

type RouteParams = {
    id?: string;
};

type Props = WithSnackbarProps & RouteComponentProps<RouteParams>;

type State = {
    isLoading: boolean;
    isDeleteDialogVisible: boolean;
    role: Role | null;
    permissions: Permission[];
};

class RoleEditPage extends React.Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        isDeleteDialogVisible: false,
        role: null,
        permissions: [],
    };

    public async componentDidMount(): Promise<void> {
        try {
            const role = await Api.getRoleById(Number.parseInt(this.props.match.params.id!, 10));
            const { data: permissions } = await Api.listPermissions({ first: 9999 });
            this.setState({ role, permissions, isLoading: false });
        } catch (error) {
            this.props.enqueueSnackbar(error.message, { variant: "error" });
            this.props.history.push(Path.roleList);
        }
    }

    private onSubmit = async (values: RoleFormValues, formikHelpers: FormikHelpers<RoleFormValues>): Promise<boolean> => {
        try {
            const role = await Api.updateRole(this.state.role!.id, {
                title: values.title,
                permissions: values.permissions,
            });
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.role.edit.onSubmit.succeed" }), { variant: "success" });
            this.setState({ role });
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.role.edit.onSubmit.error" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
        }
        return false;
    };

    private getBreadcrumb = () => {
        return (
            <Breadcrumbs>
                <Link to={Path.roleList} color="inherit">
                    {I18n.formatMessage({ id: "pages.role.edit.breadcrumb" })}
                </Link>
                {this.state.role?.title && <ActiveBreadcrumbItem aria-current="page">{this.state.role.title}</ActiveBreadcrumbItem>}
            </Breadcrumbs>
        );
    };

    private onDeleteButtonToggle = (isDeleteDialogVisible: boolean): void => {
        this.setState({ isDeleteDialogVisible });
    };

    private onDeleteClick = async (): Promise<void> => {
        try {
            await Api.deleteRole(Number.parseInt(this.props.match.params.id!, 10));
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.role.delete.onSubmit.succeed" }), { variant: "success" });
            this.setState({ role: null });
            this.onDeleteButtonToggle(false);
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.role.delete.onSubmit.error" }), { variant: "error" });
        }
    };

    public render() {
        const { isLoading, role, permissions } = this.state;

        if (isLoading) {
            return <Loading breadcrumb={this.getBreadcrumb()} />;
        }

        if (!role) {
            return <Redirect to={Path.roleList} />;
        }

        const initialValues: RoleFormValues = {
            name: role.name,
            title: role.title,
            permissions: role.permissions.map(p => p.name),
        };

        return (
            <PageLayout breadcrumb={this.getBreadcrumb()}>
                <DeleteRoleConfirmModal isVisible={this.state.isDeleteDialogVisible} onClose={() => this.onDeleteButtonToggle(false)} onDeleteClick={this.onDeleteClick} />
                <Formik initialValues={initialValues} onSubmit={this.onSubmit} validateOnBlur={false} enableReinitialize={true}>
                    {props => (
                        <>
                            <RoleForm
                                formType={FormType.edit}
                                formProps={props}
                                role={role!}
                                permissions={permissions}
                                onDeleteButtonToggle={isDeleteDialogVisible => this.onDeleteButtonToggle(isDeleteDialogVisible)}
                            />
                            <Prompt when={props.dirty} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props)} />
                        </>
                    )}
                </Formik>
                <Box mt="40px" />
            </PageLayout>
        );
    }
}

export default withSnackbar(withRouter(RoleEditPage));
