import React from "react";
import { PaymentTransaction } from "Api/graphql/admin/types";
import { I18n } from "I18n/I18n";
import UnControlledDataGrid from "Components/DataGrid/UCDataGrid";
import { CustomTypeColumn } from "Utils/DataGridUtils";
import { DataSource } from "Components/DataGrid/DataGridUtils";
import { PageCard } from "@bigfish/admin-ui/components";

type Props = {
    paymentTransactions: PaymentTransaction[];
};

export const TransactionPayments = (props: Props) => {
    const getColumns = (): CustomTypeColumn[] => {
        return [
            { name: "id", header: I18n.formatMessage({ id: "pages.order.form.payments.transactions.column.id" }), defaultWidth: 100 },
            { name: "pmgw_transaction_id", header: I18n.formatMessage({ id: "pages.order.form.payments.transactions.column.pmgw_transaction_id" }), defaultFlex: 1, minWidth: 400 },
            { name: "provider_transaction_id", header: I18n.formatMessage({ id: "pages.order.form.payments.transactions.column.provider_transaction_id" }), defaultWidth: 200 },
            { name: "anum", header: I18n.formatMessage({ id: "pages.order.form.payments.transactions.column.anum" }), defaultWidth: 200 },
            {
                name: "payment_method",
                header: I18n.formatMessage({ id: "pages.order.form.payments.transactions.column.payment_method" }),
                defaultWidth: 200,
                render: ({ data }: { data: PaymentTransaction }) => {
                    return <div>{data.payment_method.name}</div>;
                },
            },
            { name: "status_payment", header: I18n.formatMessage({ id: "pages.order.form.payments.transactions.column.status_payment" }), defaultWidth: 200 },
            {
                name: "amount",
                header: I18n.formatMessage({ id: "pages.order.form.payments.transactions.column.amount" }),
                defaultWidth: 200,
                render: ({ value }: { value: number }) => {
                    return <div>{I18n.formatCurrency(value)}</div>;
                },
            },

            { name: "amount_refund", header: I18n.formatMessage({ id: "pages.order.form.payments.transactions.column.amount_refund" }), defaultWidth: 200 },
            {
                name: "success",
                header: I18n.formatMessage({ id: "pages.order.form.payments.transactions.column.success" }),
                defaultWidth: 200,
                render: ({ value }: { value: boolean }) => {
                    return <div>{I18n.formatMessage({ id: `common.${value ? "yes" : "no"}` })}</div>;
                },
            },
        ];
    };

    return (
        <div>
            <PageCard.Heading title={I18n.formatMessage({ id: "pages.order.form.payments.transactions.title" })} />
            <UnControlledDataGrid<PaymentTransaction>
                rowHeight={50}
                style={{ minHeight: 42 + props.paymentTransactions.length * 50 }}
                dataSource={async (): Promise<DataSource<PaymentTransaction>> => {
                    return { data: props.paymentTransactions, count: props.paymentTransactions.length };
                }}
                columns={getColumns()}
                activeCell={null}
                pagination={false}
            />
        </div>
    );
};
