import { gql } from "@apollo/client";
import { clpePromotionSelectItemFragment } from "../fragments/clpePromotionSelectItemFragment";

export const listClpePromotionSelectItemsQuery = gql`
    query listClpePromotionSelectItems($first: Int!) {
        listPromotions(first: $first) {
            data {
                ...ClpePromotionSelectItem
            }
        }
    }
    ${clpePromotionSelectItemFragment}
`;
