import React, { useCallback, useState } from "react";
import { Form, FormType } from "Components/Form";
import { Button, FormControl, FormControlLabel, Grid, Icon, TextField, Typography, Box } from "@bigfish/admin-ui/core";
import { Field, FieldProps, FormikProps } from "formik";
import { MailTemplate, UpdateMailTemplateInput } from "Api/graphql/admin/types";
import { Checkbox } from "@bigfish/admin-ui/core";
import { I18n } from "Src/i18n/I18n";
import { FullscreenLoader, PageCard, SavePanel } from "@bigfish/admin-ui/components";
import { Path } from "Utils/Path";
import { useHistory } from "react-router-dom";
import { I18nHelpers } from "I18n/I18nHelpers";
import { Validator } from "Utils/Validator";
import { CustomFormHelperText } from "Components/CustomFormHelperText";
import { Api } from "Api/Api";
import { useSnackbar } from "notistack";
import CodeEditor from "@uiw/react-textarea-code-editor";

export type MailTemplateFormValues = UpdateMailTemplateInput;

type Props = {
    formType: FormType;
    formProps: FormikProps<MailTemplateFormValues>;
    mailTemplate?: MailTemplate;
};

export const MailTemplateForm = (props: Props) => {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const onCancelClick = useCallback(() => {
        history.push(Path.mailTemplateList);
    }, [history]);

    const [preview, setPreview] = useState<string | null>(null);
    const [isPreviewLoading, setIsPreviewLoading] = useState<boolean>(false);

    const getPreview = async (): Promise<void> => {
        setIsPreviewLoading(true);
        try {
            const preview = await Api.previewMailTemplate(props.formProps.values.content);
            setPreview(preview);
        } catch (error) {
            enqueueSnackbar(I18n.formatMessage({ id: "pages.mailTemplate.previewError" }), { variant: "error" });
        }
        setIsPreviewLoading(false);
    };

    return (
        <Form formProps={props.formProps}>
            <PageCard.Container>
                <PageCard.Heading title={I18n.formatMessage({ id: "common.form.basicData" })} />

                <Box mt="30px" />
                <Field name="subject" validate={I18nHelpers.formatValidator(Validator.required)}>
                    {({ field, meta }: FieldProps) => (
                        <TextField
                            type="text"
                            label={I18n.formatMessage({ id: "pages.mailTemplate.form.subjectLabel" })}
                            fullWidth
                            variant="outlined"
                            required
                            helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                            error={meta.touched && !!meta.error}
                            {...field}
                        />
                    )}
                </Field>

                <Box mt="30px" />

                <Field name="description" validate={I18nHelpers.formatValidator(Validator.required)}>
                    {({ field, meta }: FieldProps) => (
                        <TextField
                            type="text"
                            label={I18n.formatMessage({ id: "pages.mailTemplate.form.descriptionLabel" })}
                            fullWidth
                            variant="outlined"
                            required
                            multiline
                            helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.required" }))}
                            error={meta.touched && !!meta.error}
                            {...field}
                        />
                    )}
                </Field>

                <Box mt="30px" />

                <PageCard.Heading
                    rightContent={
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<Icon className="fas fa-eye" />}
                            onClick={getPreview}
                            disabled={!props.formProps.values.content || isPreviewLoading}
                        >
                            {I18n.formatMessage({ id: "pages.mailTemplate.form.previewButton" })}
                        </Button>
                    }
                    title={I18n.formatMessage({ id: "common.form.content" })}
                />

                <Box mt="30px" />

                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Field name="content" validate={I18nHelpers.formatValidator(Validator.required)}>
                            {({ field, meta }: FieldProps) => (
                                <div>
                                    <CodeEditor
                                        value={field.value}
                                        language="html"
                                        placeholder={I18n.formatMessage({ id: "pages.mailTemplate.form.contentLabel" })}
                                        onChange={e => {
                                            props.formProps.setFieldValue("content", e.currentTarget.value);
                                            props.formProps.setFieldTouched("content", true);
                                        }}
                                        padding={15}
                                        style={{
                                            fontSize: 12,
                                            backgroundColor: "#f5f5f5",
                                            fontFamily: "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                                        }}
                                    />
                                    <CustomFormHelperText meta={meta} defaultHelperText={I18n.formatMessage({ id: "common.required" })} />
                                </div>
                            )}
                        </Field>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {preview && <iframe width="100%" height="100%" srcDoc={preview} />}
                    </Grid>
                </Grid>

                <Box mt="30px" />

                <Typography variant="body1" component="h3" color="textSecondary">
                    {I18n.formatMessage({ id: "pages.mailTemplate.form.statusLabel" })}
                </Typography>
                <Field name="is_active">
                    {({ field, meta }: FieldProps) =>
                        field.value !== undefined && (
                            <Box mt="15px" ml="10px">
                                <FormControl error={meta.touched && !!meta.error}>
                                    <FormControlLabel
                                        control={<Checkbox {...field} checked={field.value} />}
                                        label={I18n.formatMessage({ id: "pages.mailTemplate.form.isActiveLabel" })}
                                    />
                                </FormControl>
                                <CustomFormHelperText meta={meta} />
                            </Box>
                        )
                    }
                </Field>
            </PageCard.Container>

            <SavePanel>
                <Grid container justify="space-between">
                    <Button variant="outlined" color="primary" onClick={onCancelClick}>
                        {I18n.formatMessage({ id: "common.cancel" })}
                    </Button>
                    <Button
                        type="submit"
                        startIcon={<Icon className="fas fa-save" />}
                        variant="contained"
                        color="secondary"
                        disabled={!props.formProps.dirty || !props.formProps.isValid}
                    >
                        {I18n.formatMessage({ id: `common.${props.formType === FormType.create ? "create" : "save"}` })}
                    </Button>
                </Grid>
            </SavePanel>
            <FullscreenLoader visible={props.formProps.isSubmitting} />
        </Form>
    );
};
