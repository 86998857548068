import { gql } from "@apollo/client";
import { successStoryFragment } from "../fragments/successStoryFragment";

export const getSuccessStoryQuery = gql`
    query getSuccessStory($id: ID!) {
        successStory(id: $id) {
            ...SuccessStory
        }
    }
    ${successStoryFragment}
`;
