import React from "react";
import { Api } from "Api/Api";
import { TypeRowProps } from "@inovua/reactdatagrid-community/types";
import DataGrid from "Components/DataGrid/DataGrid";
import { DataGridUrlQueryParser, QueryParserType } from "Components/DataGrid/DataGridUrlQueryParser";
import { ApiError } from "Api/ApiError";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { Box, Button, CircularProgress, Grid, Typography, withStyles, WithStyles } from "@bigfish/admin-ui/core";
import { Path } from "Utils/Path";
import { dataGridStyles } from "@bigfish/admin-ui/styles";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import { Constants } from "Utils/Constants";
import {
    GiftCardOrderFilters,
    GiftCardOrderSimple,
    GiftCardOrderSort,
    listGiftCardOrders_listGiftCardOrders_data,
    GiftCardOrderSortField,
    OrderStatusEnum,
    PaymentMethodSelectItem,
    PaymentStatus,
    ShipmentStatusSelectItem,
    ShippingMethodSelectItem,
    GiftCardOrderTypeWithTitle,
    GiftCardOrderType,
} from "Api/graphql/admin/types";
import { I18n } from "I18n/I18n";
import { DataSource, FilterValue } from "Components/DataGrid/DataGridUtils";
import { DataGridParams } from "Components/DataGrid/UrlQueryParser";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import { NameWithAvatar } from "Components/NameWithAvatar/NameWithAvatar";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ObjectUtils } from "Utils/ObjectUtils";
import styled from "styled-components";
import { CustomTypeColumn } from "Utils/DataGridUtils";

type Props = WithSnackbarProps & WithStyles<typeof dataGridStyles> & RouteComponentProps;

type AnySelectItem = ShippingMethodSelectItem | PaymentMethodSelectItem | PaymentStatus | ShipmentStatusSelectItem | GiftCardOrderTypeWithTitle;

type State = {
    giftCardOrders: listGiftCardOrders_listGiftCardOrders_data[];
    limit: number;
    total: number;
    sum: number | null;
    selectedIds: number[];
    selectShippingMethods: ShippingMethodSelectItem[];
    selectPaymentMethods: PaymentMethodSelectItem[];
    selectPaymentStatuses: PaymentStatus[];
    selectTimeWindowIntervals: string[];
    selectShipmentStatuses: ShipmentStatusSelectItem[];
    selectTypes: GiftCardOrderTypeWithTitle[];
    areSelectTypesLoading: boolean;
    selectedFilters?: GiftCardOrderFilters | null;
    selectedSort?: GiftCardOrderSort | null;
};

class GiftCardOrderListDataGrid extends React.Component<Props, State> {
    public readonly state: State = {
        giftCardOrders: [],
        limit: 0,
        total: 0,
        sum: null,
        selectedIds: [],
        selectShippingMethods: [],
        selectPaymentMethods: [],
        selectPaymentStatuses: [],
        selectTimeWindowIntervals: [],
        selectShipmentStatuses: [],
        selectTypes: [],
        areSelectTypesLoading: true,
        selectedFilters: null,
        selectedSort: null,
    };

    public async componentDidMount(): Promise<void> {
        const sortFunc = (a: AnySelectItem, b: AnySelectItem): number => {
            const getNameOrTitle = (selectItem: AnySelectItem) => {
                switch (selectItem.__typename) {
                    case "ShippingMethod":
                        return (selectItem.name || "").toUpperCase();
                    case "PaymentMethod":
                        return (selectItem.name || "").toUpperCase();
                    case "PaymentStatus":
                    case "ShipmentStatus":
                    case "GiftCardOrderTypeWithTitle":
                        return selectItem.title.toUpperCase();
                    default:
                        return "";
                }
            };
            const textA = getNameOrTitle(a);
            const textB = getNameOrTitle(b);
            return textA < textB ? -1 : textA > textB ? 1 : 0;
        };

        try {
            const selectShippingMethods = await Api.listShippingMethodSelectItems({ first: 9999 });
            this.setState({
                selectShippingMethods: [...Array.from(selectShippingMethods.data).sort(sortFunc)],
            });
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }

        try {
            const selectPaymentMethods = await Api.listPaymentMethodSelectItems({ first: 9999 });
            this.setState({
                selectPaymentMethods: [...Array.from(selectPaymentMethods.data).sort(sortFunc)],
            });
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }

        try {
            const selectPaymentStatuses = await Api.listPaymentStatusSelectItems();
            this.setState({
                selectPaymentStatuses: [...Array.from(selectPaymentStatuses).sort(sortFunc)],
            });
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }

        try {
            const intervals = await Api.listTimeWindowIntervalSelectItems();
            this.setState({
                selectTimeWindowIntervals: intervals,
            });
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }

        try {
            const shipmentStatuses = await Api.listShipmentStatusSelectItems();
            this.setState({
                selectShipmentStatuses: [...Array.from(shipmentStatuses).sort(sortFunc)],
            });
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }

        try {
            const types = await Api.getGiftCardOrderTypes();
            this.setState({ selectTypes: [...Array.from(types).sort(sortFunc)] });
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        } finally {
            this.setState({ areSelectTypesLoading: false });
        }
    }

    private getColumns = (): CustomTypeColumn[] => {
        const { selectTypes, areSelectTypesLoading, selectPaymentMethods, selectPaymentStatuses } = this.state;

        return [
            { name: "id", header: I18n.formatMessage({ id: "pages.giftCardOrderList.grid.column.id" }), defaultWidth: 130, filterDelay: Constants.filterDelayMS },
            {
                name: "type",
                textAlign: "center",
                header: I18n.formatMessage({ id: "pages.giftCardOrderList.grid.column.type" }),
                defaultWidth: 250,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: selectTypes.map(t => {
                        return { id: t.id, label: t.title };
                    }),
                },
                render: ({ value }: { value: GiftCardOrderTypeWithTitle }) =>
                    areSelectTypesLoading ? (
                        <CircularProgress color="inherit" size={14} />
                    ) : (
                        <span>
                            {selectTypes.find(selectType => selectType.id === value?.id)?.title ??
                                (value?.id === GiftCardOrderType.normal ? I18n.formatMessage({ id: "pages.giftCardOrderList.grid.column.type.normal" }) : "")}
                        </span>
                    ),
            },
            {
                name: "created_at",
                filterEditor: DateFilter,
                header: I18n.formatMessage({ id: "pages.giftCardOrderList.grid.column.created_at" }),
                defaultWidth: Constants.columnWidth.dateTime,
                render: ({ data }: { data: GiftCardOrderSimple }) => {
                    return <div>{data.created_at ? DateUtils.format(data.created_at, DateFormat.minuteDateTime) : ""}</div>;
                },
            },
            {
                name: "user_fullname",
                header: I18n.formatMessage({ id: "pages.giftCardOrderList.grid.column.user_fullname" }),
                defaultFlex: 1,
                minWidth: 250,
                render: ({ data, value }: { data: GiftCardOrderSimple; value: string }) => {
                    return value && !!value.trim() ? <NameWithAvatar identifier={data.id} value={value} /> : null;
                },
                filterDelay: Constants.filterDelayMS,
            },
            { name: "user_email", header: I18n.formatMessage({ id: "pages.giftCardOrderList.grid.column.user_email" }), defaultWidth: 220, filterDelay: Constants.filterDelayMS },
            {
                name: "grand_total",
                header: I18n.formatMessage({ id: "pages.giftCardOrderList.grid.column.grand_total" }),
                defaultFlex: 1,
                minWidth: 250,
                filterEditor: NumberFilter,
                render: ({ data }: { data: GiftCardOrderSimple }) => {
                    return data.grand_total ? I18n.formatCurrency(data.grand_total) : null;
                },
                filterDelay: Constants.filterDelayMS,
            },
            {
                name: "payment_method_name",
                header: I18n.formatMessage({ id: "pages.giftCardOrderList.grid.column.payment_method_name" }),
                defaultWidth: 185,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: selectPaymentMethods.map(spm => {
                        return { id: spm.id, label: spm.name };
                    }),
                },
            },
            {
                name: "payment_status_title",
                header: I18n.formatMessage({ id: "pages.giftCardOrderList.grid.column.payment_status_title" }),
                defaultWidth: 185,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: selectPaymentStatuses.map(sps => {
                        return { id: sps.id, label: sps.title };
                    }),
                },
            },
            {
                name: "user_agent_type",
                header: I18n.formatMessage({ id: "pages.giftCardOrderList.grid.column.user_agent_type" }),
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: [
                        { id: "desktop", label: "desktop" },
                        { id: "android", label: "android" },
                        { id: "ios", label: "ios" },
                        { id: "huawei", label: "huawei" },
                        { id: "mobile", label: "mobile" },
                    ],
                },
                sortable: false,
            },
            {
                name: "status",
                header: I18n.formatMessage({ id: "pages.giftCardOrderList.grid.column.status" }),
                defaultWidth: 200,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: ObjectUtils.enumAsArray<OrderStatusEnum>(OrderStatusEnum)
                        .map((type: OrderStatusEnum) => {
                            return { id: type, label: I18n.formatMessage({ id: `enums.orderStatusEnum.${type}` }) };
                        })
                        .sort((a, b) => a.label.localeCompare(b.label)),
                },
                render: ({ data }: { data: GiftCardOrderSimple }) => {
                    return data.status?.title;
                },
                sortable: false,
            },
            {
                name: "created_by",
                header: I18n.formatMessage({ id: "pages.giftCardOrderList.grid.column.created_by" }),
                defaultFlex: 1,
                minWidth: 250,
                render: ({ data }: { data: GiftCardOrderSimple }) => {
                    const value = data.created_by?.name;
                    return value && !!value.trim() ? <NameWithAvatar identifier={data.id} value={value} /> : null;
                },
                filterDelay: Constants.filterDelayMS,
            },
        ];
    };

    private filterValues: FilterValue[] = [
        { name: "id", operator: "eq", type: "number" },
        { name: "type", operator: "eq", type: "select" },
        { name: "created_at", operator: "inrange", type: "date" },
        { name: "user_fullname", operator: "contains", type: "string" },
        { name: "user_email", operator: "contains", type: "string" },
        { name: "grand_total", operator: "inrange", type: "number" },
        { name: "payment_method_name", operator: "eq", type: "select" },
        { name: "payment_status_title", operator: "eq", type: "select" },
        { name: "user_agent_type", operator: "eq", type: "select" },
        { name: "status", operator: "eq", type: "select" },
    ];

    private urlQueryParser = new DataGridUrlQueryParser({
        filters: {
            id: QueryParserType.integer,
            type: QueryParserType.enum,
            created_at: QueryParserType.dateRange,
            user_fullname: QueryParserType.string,
            user_email: QueryParserType.string,
            grand_total: QueryParserType.integerRange,
            payment_method_name: QueryParserType.enum,
            payment_status_title: QueryParserType.enum,
            user_agent_type: QueryParserType.string,
            status: QueryParserType.enum,
        },
        sortField: GiftCardOrderSortField,
    });

    private sortFieldToSortOrder = {
        [GiftCardOrderSortField.id]: "id",
        [GiftCardOrderSortField.created_at]: "created_at",
        [GiftCardOrderSortField.user_fullname]: "user_fullname",
        [GiftCardOrderSortField.user_email]: "user_email",
        [GiftCardOrderSortField.grand_total]: "grand_total",
        [GiftCardOrderSortField.payment_method_name]: "payment_method_name",
        [GiftCardOrderSortField.payment_status_title]: "payment_status_title",
    };

    private dataSource = async (props: DataGridParams<GiftCardOrderSort, GiftCardOrderFilters>): Promise<DataSource<GiftCardOrderSimple>> => {
        try {
            const cleanFilters = {
                id: props.filters?.id,
                created_at: props.filters?.created_at,
                user_fullname: props.filters?.user_fullname,
                user_email: props.filters?.user_email,
                grand_total: props.filters?.grand_total
                    ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      { min: Number.parseInt(props.filters.grand_total.start, 10), max: Number.parseInt(props.filters.grand_total.end, 10) }
                    : undefined,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                payment_method_id: props.filters?.payment_method_name,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                payment_method_name: undefined,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                payment_status_id: props.filters?.payment_status_title,
                payment_status_title: undefined,
                user_agent_type: props.filters?.user_agent_type,
                status: props.filters?.status,
                type: props.filters?.type,
            };

            const result = await Api.listGiftCardOrders({
                first: props.limit,
                page: props.page,
                filters: cleanFilters,
                sortBy: props.sortBy,
            });
            this.setState({
                giftCardOrders: result.data,
                limit: props.limit,
                total: result.paginatorInfo.total,
                sum: result.aggregations.sum_grand_total,
                selectedFilters: cleanFilters,
                selectedSort: props.sortBy,
            });
            return { data: result.data, count: result.paginatorInfo.total };
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }
        return { data: [], count: 0 };
    };

    private getRowLink = (rowProps: TypeRowProps): string => {
        return Path.giftCardOrderEdit(rowProps.data.id);
    };

    private exportFilteredOrders = async () => {
        const { url, send_in_email } = await Api.listGiftCardsExport(this.state.selectedFilters, this.state.selectedSort);
        if (!send_in_email && url) {
            window.location.assign(url);
        } else {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.giftCardOrderList.exportFilteredSuccess" }), { variant: "success" });
        }
    };

    render() {
        const { total, sum } = this.state;
        return (
            <div>
                {/* TODO */}
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs="auto">
                        <Button variant="contained" size="medium" color="secondary" onClick={this.exportFilteredOrders}>
                            {I18n.formatMessage({ id: "pages.giftCardOrderList.exportFiltered" })}
                        </Button>
                    </Grid>
                    {total > 0 && sum && (
                        <SumGrid item xs>
                            <Typography variant="subtitle1">
                                {I18n.formatMessage({ id: "pages.giftCardOrderList.sum" }, { count: total, sum: I18n.formatCurrency(sum) })}
                            </Typography>
                        </SumGrid>
                    )}
                </Grid>
                <Box mt="30px" />
                <DataGrid
                    className={this.props.classes.dataGrid}
                    urlQueryParser={this.urlQueryParser}
                    rowHeight={50}
                    style={{ minHeight: 800 }}
                    dataSource={this.dataSource}
                    filterValues={this.filterValues}
                    columns={this.getColumns()}
                    rowLink={this.getRowLink}
                    activeCell={null}
                    sortFieldToSortOrder={this.sortFieldToSortOrder}
                />
            </div>
        );
    }
}

export default withRouter(withSnackbar(withStyles(dataGridStyles, { withTheme: true })(GiftCardOrderListDataGrid)));

const SumGrid = styled(Grid)`
    display: flex;
    justify-content: flex-end;
`;
