import { gql } from "@apollo/client";
import { adminFragment } from "../fragments/adminFragment";

export const adminMeQuery = gql`
    query adminMe {
        adminMe {
            ...Admin
        }
    }
    ${adminFragment}
`;
