import React from "react";
import { PageLayout } from "Components/PageLayout";
import { RouteComponentProps } from "react-router-dom";
import OrderListDataGrid from "./OrderListDataGrid";
import { TitleBar, PageCard } from "@bigfish/admin-ui/components";
import { I18n } from "I18n/I18n";

type Props = RouteComponentProps;

class OrderListPage extends React.Component<Props> {
    public render() {
        return (
            <PageLayout>
                <TitleBar title={I18n.formatMessage({ id: "pages.orderList.title" })} />
                <PageCard.Container>
                    <OrderListDataGrid />
                </PageCard.Container>
            </PageLayout>
        );
    }
}

export default OrderListPage;
