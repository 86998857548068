import React from "react";

import { Formik, FormikHelpers } from "formik";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { DispatchProp } from "react-redux";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";

import { ActiveBreadcrumbItem, TitleBar } from "@bigfish/admin-ui/components";
import { Box } from "@bigfish/admin-ui/core";

import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Breadcrumbs } from "Components/Breadcrumbs";
import { Form, FormType } from "Components/Form";
import { Link } from "Components/Link";
import { PageLayout } from "Components/PageLayout";
import Prompt from "Components/Prompt";
import { I18n } from "I18n/I18n";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import { Path } from "Utils/Path";

import { bundleValidator, ProductBundleForm, ProductBundleFormValues } from "./ProductBundleForm";

type RouteParams = {
    id?: string;
};

type Props = DispatchProp & WithSnackbarProps & RouteComponentProps<RouteParams>;

type State = {
    isCreateSucceed: boolean;
};

export enum ProductParamManualValue {
    description = "description",
    og_image = "og_image",
    og_title = "og_title",
    og_description = "og_description",
    meta_description = "meta_description",
}

class ProductBundleEditPage extends React.Component<Props, State> {
    public readonly state: State = {
        isCreateSucceed: false,
    };

    private onSubmit = async (values: ProductBundleFormValues, formikHelpers: FormikHelpers<ProductBundleFormValues>): Promise<boolean> => {
        const getParams = () => {
            const returnedParams = [...values.params];

            // description
            const foundDescriptionParam = returnedParams.find(p => p.product_param_id === ProductParamManualValue.description);
            foundDescriptionParam
                ? (foundDescriptionParam.values = [values.description ?? ""])
                : returnedParams.push({ product_param_id: ProductParamManualValue.description, values: [values.description ?? ""] });

            // og_image
            const foundOgImageParam = returnedParams.find(p => p.product_param_id === ProductParamManualValue.og_image);
            foundOgImageParam
                ? (foundOgImageParam.values = [values.og_image ?? ""])
                : returnedParams.push({ product_param_id: ProductParamManualValue.og_image, values: [values.og_image ?? ""] });

            // og_title
            const foundOgTitleParam = returnedParams.find(p => p.product_param_id === ProductParamManualValue.og_title);
            foundOgTitleParam
                ? (foundOgTitleParam.values = [values.og_title ?? ""])
                : returnedParams.push({ product_param_id: ProductParamManualValue.og_title, values: [values.og_title ?? ""] });

            // og_description
            const foundOgDescriptionParam = returnedParams.find(p => p.product_param_id === ProductParamManualValue.og_description);
            foundOgDescriptionParam
                ? (foundOgDescriptionParam.values = [values.og_description ?? ""])
                : returnedParams.push({ product_param_id: ProductParamManualValue.og_description, values: [values.og_description ?? ""] });

            // meta_description
            const foundMetaDescriptionParam = returnedParams.find(p => p.product_param_id === ProductParamManualValue.meta_description);
            foundMetaDescriptionParam
                ? (foundMetaDescriptionParam.values = [values.meta_description ?? ""])
                : returnedParams.push({ product_param_id: ProductParamManualValue.meta_description, values: [values.meta_description ?? ""] });

            return returnedParams;
        };

        try {
            const productId = await Api.createProductBundle({
                name: values.name,
                slug: values.slug,
                bundle_promotion_id: values.bundle_promotion_id,
                product_brand_id: values.product_brand_id ? values.product_brand_id : null,
                is_active: values.is_active,
                active_from: values.active_from ? DateUtils.format(values.active_from, DateFormat.apiDateTime) : null,
                active_to: values.active_to ? DateUtils.format(values.active_to, DateFormat.apiDateTime) : null,
                images: values.images.map(i => {
                    return {
                        id: i.id,
                        is_new: i.is_new,
                        source: i.source,
                        filename_medialib: i.generic_url,
                        delete: i.delete,
                        is_active: i.is_active,
                    };
                }),
                params: getParams(),
            });
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.productBundle.onCreateSubmit.success" }), { variant: "success" });
            this.setState({ isCreateSucceed: true }, () => this.props.history.push(Path.productBundleEdit(`${productId}`)));
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.productBundle.onCreateSubmit.error" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
        }
        return false;
    };

    private getBreadcrumb = () => {
        return (
            <Breadcrumbs>
                <Link to={Path.productCatalog} color="inherit">
                    {I18n.formatMessage({ id: "pages.productBundle.breadcrumb.title" })}
                </Link>
                <ActiveBreadcrumbItem aria-current="page">{I18n.formatMessage({ id: "pages.productBundle.new" })}</ActiveBreadcrumbItem>
            </Breadcrumbs>
        );
    };

    public render() {
        if (this.state.isCreateSucceed) {
            return <Redirect to={Path.productBundles} />;
        }

        const initialValues: ProductBundleFormValues = {
            name: "",
            slug: "",
            bundle_promotion_id: parseInt("", 10),
            product_brand_id: null,
            is_active: false,
            active_from: null,
            active_to: null,
            images: [],
            params: [],
            og_image: "",
            og_title: "",
            og_description: "",
            meta_description: "",
            description: "",
        };

        return (
            <PageLayout breadcrumb={this.getBreadcrumb()}>
                <TitleBar title={I18n.formatMessage({ id: "pages.productBundle.create.title" })} />
                <Formik initialValues={initialValues} onSubmit={this.onSubmit} validate={bundleValidator()} validateOnBlur={false} enableReinitialize={true}>
                    {props => (
                        <>
                            <ProductBundleForm formType={FormType.create} formProps={props} />
                            <Prompt when={props.dirty} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props)} />
                        </>
                    )}
                </Formik>
                <Box mt="40px" />
            </PageLayout>
        );
    }
}

export default withSnackbar(withRouter(ProductBundleEditPage));
