import React from "react";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { withStyles, WithStyles } from "@bigfish/admin-ui/core";
import { dataGridStyles } from "@bigfish/admin-ui/styles";
import { TypeRowProps } from "@inovua/reactdatagrid-community/types";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import { ProductGratisListItem, ProductFilters, ProductSort, ProductSortField, ProductCategorySelectItem } from "Api/graphql/admin/types";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import DataGrid from "Components/DataGrid/DataGrid";
import { DataGridUrlQueryParser, QueryParserType } from "Components/DataGrid/DataGridUrlQueryParser";
import { DataSource, FilterValue } from "Components/DataGrid/DataGridUtils";
import { DataGridParams } from "Components/DataGrid/UrlQueryParser";
import { ListItemActive } from "Components/ListItemActive";
import { I18n } from "I18n/I18n";
import { Path } from "Utils/Path";
import { CustomTypeColumn } from "Utils/DataGridUtils";
import { Constants } from "Utils/Constants";
import { ProductCategoryUtils } from "Utils/ProductCategoryUtils";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";

type Props = WithSnackbarProps & WithStyles<typeof dataGridStyles>;

type State = {
    categorySelectItems: ProductCategorySelectItem[];
};

class ProductBundlesListDataGrid extends React.Component<Props> {
    public readonly state: State = {
        categorySelectItems: [],
    };

    public componentDidMount = async (): Promise<void> => {
        try {
            const categorySelectItems = await Api.listProductCategorySelectItems();
            this.setState({ categorySelectItems });
        } catch (error) {
            this.props.enqueueSnackbar(error.message, { variant: "error" });
        }
    };
    private getColumns = (): CustomTypeColumn[] => {
        return [
            {
                name: "id",
                header: I18n.formatMessage({ id: "pages.productGratis.grid.column.id" }),
                defaultWidth: 120,
                filterDelay: Constants.filterDelayMS,
            },
            {
                name: "ean",
                header: I18n.formatMessage({ id: "pages.productGratis.grid.column.ean" }),
                defaultFlex: 1,
                minWidth: 160,
                render: ({ data }: { data: ProductGratisListItem }) => data.ean.join(", "),
                filterDelay: Constants.filterDelayMS,
            },
            { name: "lfdnr", header: I18n.formatMessage({ id: "pages.productGratis.grid.column.lfdnr" }), defaultWidth: 160, filterDelay: Constants.filterDelayMS },
            {
                name: "name",
                header: I18n.formatMessage({ id: "pages.productGratis.grid.column.name" }),
                defaultFlex: 1,
                minWidth: 420,
                filterDelay: Constants.filterDelayMS,
            },
            {
                name: "product_category_id_main",
                header: I18n.formatMessage({ id: "pages.productGratis.grid.column.main_categories" }),
                defaultWidth: 292,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: ProductCategoryUtils.getMainCategories(this.state.categorySelectItems).map(c => {
                        return { id: c.id, label: c.name };
                    }),
                },
                sortable: false,
                render: ({ data }: { data: ProductGratisListItem }) => data.main_categories.map(c => c.name).join(", "),
            },
            {
                name: "is_visible",
                textAlign: "center",
                header: I18n.formatMessage({ id: "pages.productGratis.grid.column.is_visible" }),
                defaultWidth: 120,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: [
                        { id: "true", label: I18n.formatMessage({ id: "common.form.isActive.true" }).toLowerCase() },
                        { id: "false", label: I18n.formatMessage({ id: "common.form.isActive.false" }).toLowerCase() },
                    ],
                },
                render: ({ data }: { data: ProductGratisListItem }) => <ListItemActive isActive={data.is_visible} fromDate={data.active_from} />,
            },
            {
                name: "is_image",
                header: I18n.formatMessage({ id: "pages.productGratis.grid.column.is_image" }),
                textAlign: "center",
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: [
                        { id: "true", label: I18n.formatMessage({ id: "common.present" }).toLowerCase() },
                        { id: "false", label: I18n.formatMessage({ id: "common.absent" }).toLowerCase() },
                    ],
                },
                defaultWidth: 120,
                render: ({ data }: { data: ProductGratisListItem }) => {
                    return I18n.formatMessage({ id: `common.${data.is_image ? "present" : "absent"}` }).toLowerCase();
                },
            },
            {
                name: "stock_qty",
                header: I18n.formatMessage({ id: "pages.productGratis.grid.column.stock_qty" }),
                defaultFlex: 1,
                minWidth: 160,
                filterEditor: NumberFilter,
                filterDelay: Constants.filterDelayMS,
                render: ({ data }: { data: ProductGratisListItem }) => {
                    return data.stock_qty ?? null;
                },
            },
            {
                name: "active_from",
                filterEditor: DateFilter,
                header: I18n.formatMessage({ id: "pages.productGratis.grid.column.active_from" }),
                defaultFlex: 1,
                minWidth: 357,
                render: ({ data }: { data: ProductGratisListItem }) => {
                    return <div>{data.active_from ? DateUtils.format(data.active_from, DateFormat.minuteDateTime) : ""}</div>;
                },
                sortable: false,
            },
            {
                name: "active_to",
                filterEditor: DateFilter,
                header: I18n.formatMessage({ id: "pages.productGratis.grid.column.active_to" }),
                defaultFlex: 1,
                minWidth: 357,
                render: ({ data }: { data: ProductGratisListItem }) => {
                    return <div>{data.active_to ? DateUtils.format(data.active_to, DateFormat.minuteDateTime) : ""}</div>;
                },
                sortable: false,
            },
        ];
    };

    private dataSource = async (props: DataGridParams<ProductSort, ProductFilters>): Promise<DataSource<ProductGratisListItem>> => {
        try {
            const result = await Api.listProductGratis({
                first: props.limit,
                page: props.page,
                filters: {
                    ...props.filters,
                    product_category_id_main: props.filters?.product_category_id_main
                        ? `${props.filters?.product_category_id_main}`.split(",").map(id => Number.parseInt(`${id}`, 10))
                        : undefined,
                    stock_qty: props.filters?.stock_qty
                        ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          { min: Number.parseInt(props.filters.stock_qty.start, 10), max: Number.parseInt(props.filters.stock_qty.end, 10) }
                        : undefined,
                },
                sortBy: props.sortBy,
            });
            return { data: result.data, count: result.paginatorInfo.total };
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }
        return { data: [], count: 0 };
    };

    private urlQueryParser = new DataGridUrlQueryParser({
        filters: {
            id: QueryParserType.integer,
            lfdnr: QueryParserType.integer,
            name: QueryParserType.string,
            product_category_id_main: QueryParserType.enum,
            is_visible: QueryParserType.boolean,
            is_image: QueryParserType.boolean,
            ean: QueryParserType.string,
            stock_qty: QueryParserType.integerRange,
            active_from: QueryParserType.dateRange,
            active_to: QueryParserType.dateRange,
        },
        sortField: ProductSortField,
    });

    private sortFieldToSortOrder = {
        [ProductSortField.id]: "id",
        [ProductSortField.lfdnr]: "lfdnr",
        [ProductSortField.name]: "name",
        [ProductSortField.ean]: "ean",
        [ProductSortField.is_visible]: "is_visible",
        [ProductSortField.is_image]: "is_image",
        [ProductSortField.stock_qty]: "stock_qty",
    };

    private filterValues: FilterValue[] = [
        { name: "id", operator: "eq", type: "number" },
        { name: "lfdnr", operator: "eq", type: "number" },
        { name: "ean", operator: "contains", type: "string" },
        { name: "name", operator: "contains", type: "string" },
        { name: "product_category_id_main", operator: "inlist", type: "select" },
        { name: "is_visible", operator: "eq", type: "select" },
        { name: "is_image", operator: "eq", type: "select" },
        { name: "stock_qty", operator: "inrange", type: "number" },
    ];

    private getRowLink = (rowProps: TypeRowProps): string => {
        return Path.productGratisEdit(rowProps.data.id);
    };

    render() {
        return (
            <DataGrid
                className={this.props.classes.dataGrid}
                urlQueryParser={this.urlQueryParser}
                rowHeight={50}
                style={{ minHeight: 800 }}
                dataSource={this.dataSource}
                filterValues={this.filterValues}
                columns={this.getColumns()}
                activeCell={null}
                rowLink={this.getRowLink}
                sortFieldToSortOrder={this.sortFieldToSortOrder}
            />
        );
    }
}

export default withSnackbar(withStyles(dataGridStyles, { withTheme: true })(ProductBundlesListDataGrid));
