import { gql } from "@apollo/client";

export const timeWIndowCostFragment = gql`
    fragment TimeWindowCost on TimeWindowCost {
        interval_hours
        cost {
            cart_limit
            cost
        }
    }
`;
