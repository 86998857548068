import React from "react";
import { Api } from "Api/Api";
import { TypeRowProps } from "@inovua/reactdatagrid-community/types";
import DataGrid from "Components/DataGrid/DataGrid";
import {
    ProductFilters,
    ProductSort,
    ProductCatalogItem,
    ProductSortField,
    listProducts_listProducts_data,
    ProductCategorySelectItem,
    ProductFilterInput,
    OrderDirection,
} from "Api/graphql/admin/types";
import { DataGridUrlQueryParser, QueryParserType } from "Components/DataGrid/DataGridUrlQueryParser";
import { ApiError } from "Api/ApiError";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { Checkbox, Grid, Icon, Tooltip, withStyles, WithStyles } from "@bigfish/admin-ui/core";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import { Path } from "Utils/Path";
import { dataGridStyles } from "@bigfish/admin-ui/styles";
import { I18n } from "I18n/I18n";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import { ProductCategoryUtils } from "Utils/ProductCategoryUtils";
import { DataSource, FilterValue } from "Components/DataGrid/DataGridUtils";
import { DataGridParams } from "Components/DataGrid/UrlQueryParser";
import { ListItemActive } from "Components/ListItemActive";
import UnparsedDataGrid from "Components/DataGrid/UnparsedDataGrid";
import { CustomTypeColumn } from "Utils/DataGridUtils";
import { Constants } from "Utils/Constants";

type ComponentProps = {
    idsFilter?: number[];
    filtersBase?: ProductFilterInput[];
    userId?: number;
    isReadOnly?: boolean;
    productBrandId?: number;
    selectedProductIds?: number[];
    minWidth?: number;
    minHeight?: number;
    onAddProducts?(products: ProductCatalogItem[]): void;
    onRemoveProducts?(products: ProductCatalogItem[]): void;
    isUnparsed?: boolean;
    isEmbedded?: boolean;
    onDeleteWithFunctionalButton?: (id: number) => void;
};

type Props = ComponentProps & WithSnackbarProps & WithStyles<typeof dataGridStyles>;

type State = {
    categorySelectItems: ProductCategorySelectItem[];
    dataGridKey: number;
};

class ProductCatalogDataGrid extends React.Component<Props, State> {
    public readonly state: State = {
        categorySelectItems: [],
        dataGridKey: 0,
    };

    public componentDidMount = async (): Promise<void> => {
        try {
            const categorySelectItems = await Api.listProductCategorySelectItems();
            this.setState({ categorySelectItems });
        } catch (error) {
            this.props.enqueueSnackbar(error.message, { variant: "error" });
        }
    };

    public componentDidUpdate(prevProps: Props) {
        if (prevProps.filtersBase !== this.props.filtersBase || prevProps.idsFilter !== this.props.idsFilter) {
            this.setState({ dataGridKey: this.state.dataGridKey + 1 });
        }
    }

    private getColumns = (): CustomTypeColumn[] => {
        return [
            ...(this.props.selectedProductIds
                ? [
                      {
                          name: "selected",
                          header: "",
                          sortable: false,
                          defaultWidth: 60,
                          textAlign: "center",
                          filterEditor: () => {
                              const isAllSelected = this.data.length > 0 && this.data.every(data => this.props.selectedProductIds?.includes(data.id));
                              const indeterminate = !isAllSelected && this.data.some(data => this.props.selectedProductIds?.includes(data.id));
                              return (
                                  <Checkbox
                                      checked={isAllSelected}
                                      indeterminate={indeterminate}
                                      onChange={() => (isAllSelected ? this.props.onRemoveProducts?.(this.data) : this.props.onAddProducts?.(this.data))}
                                  />
                              );
                          },
                          filterable: true,
                          render: ({ data }: { data: ProductCatalogItem }) => {
                              const isAdded = this.props.selectedProductIds?.includes(data.id);
                              return (
                                  <div>
                                      <Checkbox checked={isAdded} onChange={() => (isAdded ? this.props.onRemoveProducts?.([data]) : this.props.onAddProducts?.([data]))} />
                                  </div>
                              );
                          },
                      } as CustomTypeColumn,
                  ]
                : []),
            { name: "id", header: I18n.formatMessage({ id: "pages.productCatalog.grid.column.id" }), defaultWidth: 120, filterDelay: Constants.filterDelayMS },
            {
                name: "ean",
                header: I18n.formatMessage({ id: "pages.productCatalog.grid.column.ean" }),
                defaultFlex: 1,
                minWidth: 160,
                render: ({ data }: { data: ProductCatalogItem }) => data.ean.join(", "),
                filterDelay: Constants.filterDelayMS,
            },
            { name: "lfdnr", header: I18n.formatMessage({ id: "pages.productCatalog.grid.column.lfdnr" }), defaultWidth: 160, filterDelay: Constants.filterDelayMS },
            { name: "name", header: I18n.formatMessage({ id: "pages.productCatalog.grid.column.name" }), defaultFlex: 1, minWidth: 420, filterDelay: Constants.filterDelayMS },
            {
                name: "product_category_id_main",
                header: I18n.formatMessage({ id: "pages.productCatalog.grid.column.main_categories" }),
                defaultWidth: 292,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: ProductCategoryUtils.getMainCategories(this.state.categorySelectItems).map(c => {
                        return { id: c.id, label: c.name };
                    }),
                },
                sortable: false,
                render: ({ data }: { data: ProductCatalogItem }) => data.main_categories.map(c => c.name).join(", "),
            },
            {
                name: "product_category_id",
                header: I18n.formatMessage({ id: "pages.productCatalog.grid.column.categories" }),
                defaultWidth: 400,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: ProductCategoryUtils.getCategories(this.state.categorySelectItems).map(c => {
                        return { id: c.id, label: c.name };
                    }),
                },
                sortable: false,
                render: ({ data }: { data: ProductCatalogItem }) => data.categories.map(c => c.name).join(", "),
            },
            {
                name: "is_visible",
                textAlign: "center",
                header: I18n.formatMessage({ id: "pages.productCatalog.grid.column.is_visible" }),
                defaultWidth: 120,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: [
                        { id: "true", label: I18n.formatMessage({ id: "common.form.isActive.true" }).toLowerCase() },
                        { id: "false", label: I18n.formatMessage({ id: "common.form.isActive.false" }).toLowerCase() },
                    ],
                },
                render: ({ data }: { data: ProductCatalogItem }) => <ListItemActive isActive={data.is_visible} fromDate={data.active_from} />,
            },
            {
                name: "is_description",
                header: I18n.formatMessage({ id: "pages.productCatalog.grid.column.is_description" }),
                textAlign: "center",
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: [
                        { id: "true", label: I18n.formatMessage({ id: "common.present" }).toLowerCase() },
                        { id: "false", label: I18n.formatMessage({ id: "common.absent" }).toLowerCase() },
                    ],
                },
                defaultWidth: 120,
                render: ({ data }: { data: ProductCatalogItem }) => {
                    return I18n.formatMessage({ id: `common.${data.is_description ? "present" : "absent"}` }).toLowerCase();
                },
            },
            {
                name: "is_image",
                header: I18n.formatMessage({ id: "pages.productCatalog.grid.column.is_image" }),
                textAlign: "center",
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: [
                        { id: "true", label: I18n.formatMessage({ id: "common.present" }).toLowerCase() },
                        { id: "false", label: I18n.formatMessage({ id: "common.absent" }).toLowerCase() },
                    ],
                },
                defaultWidth: 120,
                render: ({ data }: { data: ProductCatalogItem }) => {
                    return I18n.formatMessage({ id: `common.${data.is_image ? "present" : "absent"}` }).toLowerCase();
                },
            },
            {
                name: "price",
                header: I18n.formatMessage({ id: "pages.productCatalog.grid.column.prices" }),
                defaultFlex: 1,
                minWidth: 160,
                filterEditor: NumberFilter,
                filterDelay: Constants.filterDelayMS,
                render: ({ data }: { data: ProductCatalogItem }) => {
                    return data.price ? I18n.formatCurrency(data.price) : null;
                },
            },
            {
                name: "stock_qty",
                header: I18n.formatMessage({ id: "pages.productCatalog.grid.column.stock_qty" }),
                defaultFlex: 1,
                minWidth: 160,
                filterEditor: NumberFilter,
                filterDelay: Constants.filterDelayMS,
                render: ({ data }: { data: ProductCatalogItem }) => {
                    return data.stock_qty ?? null;
                },
            },
            ...(this.props.onDeleteWithFunctionalButton
                ? [
                      {
                          name: "_functions",
                          header: "",
                          minWidth: 50,
                          render: ({ data }: { data: ProductCatalogItem }) => {
                              return (
                                  <Grid container spacing={3}>
                                      <Grid item>
                                          <Tooltip
                                              className="icon-wrapper"
                                              onClick={() => (this.props.onDeleteWithFunctionalButton ? this.props.onDeleteWithFunctionalButton(data.id) : {})}
                                              title={I18n.formatMessage({ id: "common.delete" })}
                                              arrow
                                          >
                                              <Icon className="fas fa-trash" />
                                          </Tooltip>
                                      </Grid>
                                  </Grid>
                              );
                          },
                      },
                  ]
                : []),
        ];
    };

    private filterValues: FilterValue[] = [
        { name: "id", operator: "eq", type: "number" },
        { name: "lfdnr", operator: "eq", type: "number" },
        { name: "name", operator: "contains", type: "string" },
        { name: "product_category_id_main", operator: "inlist", type: "select" },
        { name: "product_category_id", operator: "inlist", type: "select" },
        { name: "is_visible", operator: "eq", type: "select" },
        { name: "is_description", operator: "eq", type: "select" },
        { name: "is_image", operator: "eq", type: "select" },
        { name: "ean", operator: "contains", type: "string" },
        { name: "price", operator: "inrange", type: "number" },
        { name: "stock_qty", operator: "inrange", type: "number" },
    ];

    private urlQueryParser = new DataGridUrlQueryParser({
        filters: {
            id: QueryParserType.integer,
            lfdnr: QueryParserType.integer,
            name: QueryParserType.string,
            product_category_id_main: QueryParserType.enum,
            product_category_id: QueryParserType.enum,
            is_visible: QueryParserType.boolean,
            is_description: QueryParserType.boolean,
            is_image: QueryParserType.boolean,
            ean: QueryParserType.string,
            price: QueryParserType.integerRange,
            stock_qty: QueryParserType.integerRange,
        },
        sortField: ProductSortField,
    });

    private sortFieldToSortOrder = {
        [ProductSortField.id]: "id",
        [ProductSortField.lfdnr]: "lfdnr",
        [ProductSortField.name]: "name",
        [ProductSortField.ean]: "ean",
        [ProductSortField.price]: "price",
        [ProductSortField.is_visible]: "is_visible",
        [ProductSortField.is_image]: "is_image",
        [ProductSortField.is_description]: "is_description",
        [ProductSortField.stock_qty]: "stock_qty",
    };

    private data: listProducts_listProducts_data[] = [];

    private dataSource = async (props: DataGridParams<ProductSort, ProductFilters>): Promise<DataSource<ProductCatalogItem>> => {
        try {
            const result = await Api.listProducts({
                first: props.limit,
                page: props.page,
                filters_base: this.props.filtersBase,
                filters: {
                    ...props.filters,
                    id: this.props.idsFilter ?? props.filters?.id,
                    product_brand_id: this.props.productBrandId ?? undefined,
                    product_category_id_main: props.filters?.product_category_id_main
                        ? `${props.filters?.product_category_id_main}`.split(",").map(id => Number.parseInt(`${id}`, 10))
                        : undefined,
                    product_category_id: props.filters?.product_category_id
                        ? `${props.filters?.product_category_id}`.split(",").map(id => Number.parseInt(`${id}`, 10))
                        : undefined,
                    price: props.filters?.price
                        ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          { min: Number.parseInt(props.filters.price.start, 10), max: Number.parseInt(props.filters.price.end, 10) }
                        : undefined,
                    stock_qty: props.filters?.stock_qty
                        ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          { min: Number.parseInt(props.filters.stock_qty.start, 10), max: Number.parseInt(props.filters.stock_qty.end, 10) }
                        : undefined,
                },
                sortBy:
                    this.props.isEmbedded && (props.sortBy === undefined || props.sortBy?.field === ProductSortField.id)
                        ? { direction: props.sortBy === undefined ? OrderDirection.ASC : props.sortBy.direction, field: ProductSortField.id_by_position }
                        : props.sortBy,
            });

            this.data = result.data;
            return { data: result.data, count: result.paginatorInfo.total };
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }
        return { data: [], count: 0 };
    };

    private getRowLink = (rowProps: TypeRowProps): string => {
        return Path.productEdit(rowProps.data.id);
    };

    render() {
        return this.props.isUnparsed ? (
            <UnparsedDataGrid
                key={this.state.dataGridKey}
                className={this.props.classes.dataGrid}
                rowHeight={50}
                style={{ minHeight: this.props.minWidth ?? 800 }}
                dataSource={this.dataSource}
                filterValues={this.filterValues}
                columns={this.getColumns()}
                rowLink={this.props.isReadOnly ? undefined : this.getRowLink}
                activeCell={null}
                sortFieldToSortOrder={this.sortFieldToSortOrder}
            />
        ) : (
            <DataGrid
                key={this.state.dataGridKey}
                className={this.props.classes.dataGrid}
                urlQueryParser={this.urlQueryParser}
                rowHeight={50}
                style={{ minHeight: this.props.minWidth ?? this.props.minHeight ?? 800 }}
                dataSource={this.dataSource}
                filterValues={this.filterValues}
                columns={this.getColumns()}
                rowLink={this.props.isReadOnly ? undefined : this.getRowLink}
                activeCell={null}
                sortFieldToSortOrder={this.sortFieldToSortOrder}
            />
        );
    }
}

export default withSnackbar(withStyles(dataGridStyles, { withTheme: true })(ProductCatalogDataGrid));
