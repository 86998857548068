import React, { useCallback, useState } from "react";
import { DefaultModal } from "@bigfish/admin-ui/components";
import { Button, Grid, Typography } from "@bigfish/admin-ui/core";
import { I18n } from "I18n/I18n";

type Props = {
    isVisible: boolean;
    title: React.ReactNode;
    onClose: () => void;
    onCloseOrderClick: (delivered: boolean) => void | Promise<void>;
};

export const CloseOrderModal = (props: Props) => {
    const [isLoading, setLoading] = useState(false);
    const [isCloseOrderDelivered, setIsCloseOrderDelivered] = useState<boolean | null>(null);

    const onCloseOrderClick = useCallback(
        async (delivered: boolean): Promise<void> => {
            setLoading(true);
            await props.onCloseOrderClick(delivered);
            setLoading(false);
        },
        [props]
    );

    const onClose = useCallback((): void => {
        if (isLoading) {
            return;
        }
        setIsCloseOrderDelivered(null);
        props.onClose();
    }, [isLoading, props]);

    return (
        <DefaultModal
            title={props.title}
            rightButtonsComponent={
                isCloseOrderDelivered !== null ? (
                    <Button variant="contained" color="primary" onClick={() => onCloseOrderClick(isCloseOrderDelivered)} disabled={isLoading}>
                        {I18n.formatMessage({ id: "common.yes" })}
                    </Button>
                ) : undefined
            }
            leftButtonsComponent={
                isCloseOrderDelivered !== null ? (
                    <Button variant="outlined" color="primary" onClick={() => setIsCloseOrderDelivered(null)} disabled={isLoading}>
                        {I18n.formatMessage({ id: "common.no" })}
                    </Button>
                ) : (
                    <Button variant="outlined" color="primary" onClick={onClose} disabled={isLoading}>
                        {I18n.formatMessage({ id: "common.cancel" })}
                    </Button>
                )
            }
            open={props.isVisible}
            onClose={onClose}
        >
            <Typography variant="body2" color="textSecondary">
                {isCloseOrderDelivered !== null ? (
                    <Typography variant="body2" color="textSecondary">
                        {I18n.formatMessage(
                            { id: "pages.order.edit.closeOrderModal.description" },
                            { status: I18n.formatMessage({ id: `pages.order.edit.closeOrderModal.status.${isCloseOrderDelivered ? "" : "un"}delivered` }) }
                        )}
                    </Typography>
                ) : (
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Button variant="contained" color="primary" onClick={() => setIsCloseOrderDelivered(true)} disabled={isLoading} fullWidth>
                                {I18n.formatMessage({ id: "pages.order.edit.closeOrderModal.closeDelivered" })}
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" color="primary" onClick={() => setIsCloseOrderDelivered(false)} disabled={isLoading} fullWidth>
                                {I18n.formatMessage({ id: "pages.order.edit.closeOrderModal.closeUndelivered" })}
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </Typography>
        </DefaultModal>
    );
};
