import { gql } from "@apollo/client";
import { productCategorySelectItemFieldsFragment } from "./productCategorySelectItemFieldsFragment";

export const childProductCategorySelectItemFieldsFragment = gql`
    fragment ChildProductCategorySelectItemFields on ProductCategorySimple {
        children {
            ...ProductCategorySelectItemFields
            children {
                ...ProductCategorySelectItemFields
                children {
                    ...ProductCategorySelectItemFields
                    children {
                        ...ProductCategorySelectItemFields
                        children {
                            ...ProductCategorySelectItemFields
                        }
                    }
                }
            }
        }
    }
    ${productCategorySelectItemFieldsFragment}
`;
