import { gql } from "@apollo/client";
import { shipmentStatusSelectItemFragment } from "../fragments/shipmentStatusSelectItemFragment";

export const listShipmentStatusSelectItemsQuery = gql`
    query listShipmentStatusSelectItems {
        shipmentStatuses {
            ...ShipmentStatusSelectItem
        }
    }
    ${shipmentStatusSelectItemFragment}
`;
