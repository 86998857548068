import { gql } from "@apollo/client";

export const todoListItemFragment = gql`
    fragment TodoListItem on TodoSimple {
        id
        status
        subject
        message
        role_name
        assigned_to_name
        created_at
        updated_at
    }
`;
