import { gql } from "@apollo/client";

export const clpePromotionTypeDescriptionsQuery = gql`
    query clpePromotionTypeDescriptions {
        __type(name: "ClpePromotionType") {
            enumValues {
                name
                description
            }
        }
    }
`;
