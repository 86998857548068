import React from "react";
import { PageLayout } from "Components/PageLayout";
import { TitleBar, PageCard, TabsComponent } from "@bigfish/admin-ui/components";
import { I18n } from "I18n/I18n";
import CacheClearing from "./CacheClearing";
import Reindexing from "./Reindexing";

type State = {
    currentTab: number;
};

class CacheManagementPage extends React.Component<{}, State> {
    public readonly state: State = {
        currentTab: 0,
    };

    public render() {
        const changeTab = (_event: React.ChangeEvent<{}>, newValue: number) => {
            this.setState({ currentTab: newValue });
        };

        return (
            <PageLayout>
                <TitleBar title={I18n.formatMessage({ id: "pages.cacheManagement.title" })} />
                <PageCard.Container>
                    <TabsComponent
                        ariaLabel="order-form-tabs"
                        tabs={[
                            {
                                label: I18n.formatMessage({ id: "pages.cacheManagement.cacheTitle" }),
                                id: "cache-management-cache-clearing",
                                content: <CacheClearing />,
                            },
                            {
                                label: I18n.formatMessage({ id: "pages.cacheManagement.reindexTitle" }),
                                id: "cache-management-reindexing",
                                content: <Reindexing />,
                            },
                        ]}
                        currentTab={this.state.currentTab}
                        changeTab={changeTab}
                    />
                </PageCard.Container>
            </PageLayout>
        );
    }
}

export default CacheManagementPage;
