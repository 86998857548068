import { gql } from "@apollo/client";
import { websiteNotificationFragment } from "../fragments/websiteNotificationFragment";

export const getWebsiteNotificationByIdQuery = gql`
    query getWebsiteNotificationById($first: Int!, $filters: WebsiteNotificationFilters) {
        listWebsiteNotifications(first: $first, filters: $filters) {
            data {
                ...WebsiteNotification
            }
        }
    }
    ${websiteNotificationFragment}
`;
