import { gql } from "@apollo/client";

export const personListItemFragment = gql`
    fragment PersonListItem on Person {
        id
        name
        position
        created_at
        updated_at
    }
`;
