import { gql } from "@apollo/client";
import { divisionListItemFragment } from "./divisionListItemFragment";
import { personListItemFragment } from "./personListItemFragment";

export const successStoryFragment = gql`
    fragment SuccessStory on SuccessStory {
        id
        content
        division {
            ...DivisionListItem
        }
        person {
            ...PersonListItem
        }
        created_at
        updated_at
    }
    ${divisionListItemFragment}
    ${personListItemFragment}
`;
