import React from "react";
import { Api } from "Api/Api";
import { TypeRowProps } from "@inovua/reactdatagrid-community/types";
import DataGrid from "Components/DataGrid/DataGrid";
import { UserFilters, UserOrder, UserListItem, UserSortField, PermissionType } from "Api/graphql/admin/types";
import { DataGridUrlQueryParser, QueryParserType } from "Components/DataGrid/DataGridUrlQueryParser";
import { ApiError } from "Api/ApiError";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { withStyles, WithStyles } from "@bigfish/admin-ui/core";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import { Path } from "Utils/Path";
import { dataGridStyles } from "@bigfish/admin-ui/styles";
import { Constants } from "Utils/Constants";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import { I18n } from "I18n/I18n";
import { IsActiveIcon } from "Components/IsActive";
import { connect } from "react-redux";
import { AuthSelectors } from "Redux/selectors/authSelectors";
import { ApplicationState } from "Redux/rootReducer";
import { NameWithAvatar } from "Components/NameWithAvatar/NameWithAvatar";
import { ValueWithIcon } from "Components/ValueWithIcon/ValueWithIcon";
import { DataSource, FilterValue } from "Components/DataGrid/DataGridUtils";
import { DataGridParams } from "Components/DataGrid/UrlQueryParser";
import { CustomTypeColumn } from "Utils/DataGridUtils";

type ReduxProps = {
    permissions: PermissionType[];
    isSuperadmin: boolean;
};

type Props = ReduxProps & WithSnackbarProps & WithStyles<typeof dataGridStyles>;

class UserListDataGrid extends React.Component<Props> {
    private getColumns = (): CustomTypeColumn[] => {
        return [
            { name: "id", header: I18n.formatMessage({ id: "pages.userList.grid.column.id" }), defaultWidth: 90, filterDelay: Constants.filterDelayMS },
            {
                name: "name",
                header: I18n.formatMessage({ id: "pages.userList.grid.column.name" }),
                defaultFlex: 1,
                minWidth: 200,
                render: ({ data, value }: { data: UserListItem; value: string }) => {
                    return <NameWithAvatar identifier={data.id} value={value} />;
                },
                filterDelay: Constants.filterDelayMS,
            },
            { name: "email", header: I18n.formatMessage({ id: "pages.userList.grid.column.email" }), defaultWidth: 220, filterDelay: Constants.filterDelayMS },
            { name: "card_number", header: I18n.formatMessage({ id: "pages.userList.grid.column.card_number" }), defaultWidth: 210, filterDelay: Constants.filterDelayMS },
            {
                name: "phone_number_full",
                header: I18n.formatMessage({ id: "pages.userList.grid.column.phone_number_full" }),
                defaultWidth: 180,
                render: ({ value }: { value: string }) => {
                    return <ValueWithIcon fontAwesomeIcon="fas fa-phone-alt" value={value} />;
                },
                filterDelay: Constants.filterDelayMS,
            },
            { name: "zip_code", header: I18n.formatMessage({ id: "pages.userList.grid.column.zip_code" }), defaultWidth: 100, filterDelay: Constants.filterDelayMS },
            {
                name: "created_at",
                filterEditor: DateFilter,
                header: I18n.formatMessage({ id: "pages.userList.grid.column.created_at" }),
                defaultWidth: Constants.columnWidth.dateTime,
                render: ({ data }: { data: UserListItem }) => {
                    return <div>{data.created_at ? DateUtils.format(data.created_at, DateFormat.minuteDateTime) : ""}</div>;
                },
            },
            {
                name: "is_active",
                textAlign: "center",
                header: I18n.formatMessage({ id: "pages.userList.grid.column.is_active" }),
                defaultWidth: Constants.columnWidth.statusIcon,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: [
                        { id: "true", label: I18n.formatMessage({ id: "common.form.isActive.true" }).toLowerCase() },
                        { id: "false", label: I18n.formatMessage({ id: "common.form.isActive.false" }).toLowerCase() },
                    ],
                },
                render: ({ data }: { data: UserListItem }) => <IsActiveIcon value={data.is_active} />,
            },
        ];
    };

    private filterValues: FilterValue[] = [
        { name: "id", operator: "eq", type: "number" },
        { name: "name", operator: "contains", type: "string" },
        { name: "email", operator: "contains", type: "string" },
        { name: "card_number", operator: "contains", type: "string" },
        { name: "phone_number_full", operator: "contains", type: "string" },
        { name: "zip_code", operator: "contains", type: "string" },
        { name: "created_at", operator: "inrange", type: "date" },
        { name: "is_active", operator: "eq", type: "select" },
    ];

    private urlQueryParser = new DataGridUrlQueryParser({
        filters: {
            id: QueryParserType.integer,
            name: QueryParserType.string,
            email: QueryParserType.string,
            card_number: QueryParserType.string,
            phone_number_full: QueryParserType.string,
            zip_code: QueryParserType.string,
            created_at: QueryParserType.dateRange,
            is_active: QueryParserType.boolean,
        },
        sortField: UserSortField,
    });

    private sortFieldToSortOrder = {
        [UserSortField.id]: "id",
        [UserSortField.name]: "name",
        [UserSortField.email]: "email",
        [UserSortField.card_number]: "card_number",
        [UserSortField.phone_number_full]: "phone_number_full",
        [UserSortField.zip_code]: "zip_code",
        [UserSortField.created_at]: "created_at",
        [UserSortField.is_active]: "is_active",
    };

    private dataSource = async (props: DataGridParams<UserOrder, UserFilters>): Promise<DataSource<UserListItem>> => {
        try {
            const result = await Api.listUsers({
                first: props.limit,
                page: props.page,
                filters: props.filters,
                sortBy: props.sortBy,
            });
            return { data: result.data, count: result.paginatorInfo.total };
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }
        return { data: [], count: 0 };
    };

    private getRowLink = (rowProps: TypeRowProps): string => {
        return Path.userEdit(rowProps.data.id);
    };

    render() {
        return (
            <DataGrid
                className={this.props.classes.dataGrid}
                urlQueryParser={this.urlQueryParser}
                rowHeight={50}
                style={{ minHeight: 800 }}
                dataSource={this.dataSource}
                filterValues={this.filterValues}
                columns={this.getColumns()}
                rowLink={this.getRowLink}
                activeCell={null}
                sortFieldToSortOrder={this.sortFieldToSortOrder}
                onRowClick={this.props.isSuperadmin || this.props.permissions.includes(PermissionType.user_update) ? undefined : () => {}}
            />
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { permissions: AuthSelectors.getPermissions(state.auth), isSuperadmin: AuthSelectors.isSuperadmin(state.auth) };
};

export default withSnackbar(connect(mapStateToProps)(withStyles(dataGridStyles, { withTheme: true })(UserListDataGrid)));
