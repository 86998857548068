import { gql } from "@apollo/client";
import { pageForMenuFragment } from "../fragments/pageForMenuFragment";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";

export const listPagesForMenuQuery = gql`
    query listPagesForMenu($filters: PageFilters, $sortBy: PageSort, $first: Int!, $page: Int) {
        listPages(first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            data {
                ...PageForMenu
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${pageForMenuFragment}
    ${paginatorInfoFragment}
`;
