import { gql } from "@apollo/client";
import { roleFragment } from "../fragments/roleFragment";

export const getRoleByIdQuery = gql`
    query getRoleById($filters: RoleFilters, $first: Int!) {
        listRoles(first: $first, filters: $filters) {
            data {
                ...Role
            }
        }
    }
    ${roleFragment}
`;
