import React from "react";
import { TypeColumn, TypeFilterValue } from "@inovua/reactdatagrid-community/types";
import { withStyles, WithStyles } from "@bigfish/admin-ui/core";
import { I18n } from "I18n/I18n";
import { dataGridStyles } from "@bigfish/admin-ui/styles";
import UnControlledDataGrid from "Components/DataGrid/UCDataGrid";
import { ProductStock } from "Api/graphql/admin/types";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import { DataGridUtils } from "Utils/DataGridUtils";
import { DataSource } from "Components/DataGrid/DataGridUtils";

type Props = WithStyles<typeof dataGridStyles> & {
    stocks: ProductStock[];
};

interface State {
    dataSource: () => Promise<DataSource<ProductStock>>;
    filterValues: TypeFilterValue;
}

class ProductStocksDataGrid extends React.Component<Props, State> {
    private getColumns = (): TypeColumn[] => {
        return [
            {
                name: "qty",
                header: I18n.formatMessage({ id: "pages.product.form.tabs.stock.column.qty" }),
                defaultFlex: 1,
                minWidth: 200,
                sortable: false,
            },
            {
                name: "updated_at",
                filterEditor: DateFilter,
                header: I18n.formatMessage({ id: "pages.product.form.tabs.stock.column.updated_at" }),
                defaultFlex: 1,
                minWidth: 200,
                render: ({ data }: { data: ProductStock }) => {
                    return <div>{data.updated_at ? DateUtils.format(data.updated_at, DateFormat.minuteDateTime) : ""}</div>;
                },
                sortable: false,
            },
        ];
    };

    private getDataSource = () => async (): Promise<DataSource<ProductStock>> => {
        return { data: this.props.stocks.filter(stock => this.state.filterValues?.every(DataGridUtils.filter(stock))), count: this.props.stocks.length };
    };

    state: State = {
        dataSource: this.getDataSource(),
        filterValues: [],
    };

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevProps.stocks !== this.props.stocks || this.state.filterValues !== prevState.filterValues) {
            this.setState({ dataSource: this.getDataSource() });
        }
    }

    render() {
        return (
            <UnControlledDataGrid<ProductStock>
                className={this.props.classes.dataGrid}
                rowHeight={50}
                style={{ minHeight: 86 + this.props.stocks.length * 50 }}
                dataSource={this.state.dataSource}
                columns={this.getColumns()}
                activeCell={null}
            />
        );
    }
}

export default withStyles(dataGridStyles, { withTheme: true })(ProductStocksDataGrid);
