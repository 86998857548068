import { gql } from "@apollo/client";

export const successStoryListItemFragment = gql`
    fragment SuccessStoryListItem on SuccessStory {
        id
        person {
            id
            name
        }
        division {
            id
            name
        }
        created_at
        updated_at
    }
`;
