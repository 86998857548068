import { ActiveBreadcrumbItem, PageCard, TitleBar } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Subscription } from "Api/graphql/admin/types";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { Loading } from "Components/Loading";
import { Link } from "Components/Link";
import { Breadcrumbs } from "Components/Breadcrumbs";
import { Box, Grid, Typography } from "@bigfish/admin-ui/core";
import { I18n } from "I18n/I18n";
import { OrderUtils } from "Utils/OrderUtils";
import { PageLayout } from "Components/PageLayout";
import { SubscriptionItems } from "./SubscriptionItems";
import { SubscriptionOrders } from "./SubscriptionOrders";

type RouteParams = {
    id?: string;
};

type Props = RouteComponentProps<RouteParams> & WithSnackbarProps;

type State = {
    isLoading: boolean;
    subscription: Subscription | null;
};

class SubscriptionPage extends React.Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        subscription: null,
    };

    public async componentDidMount(): Promise<void> {
        try {
            const subscription = await Api.getSubscriptionById(Number.parseInt(this.props.match.params.id!));
            this.setState({ subscription, isLoading: false });
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
            this.props.history.push(Path.subscriptionList);
        }
    }

    private getBreadcrumb = () => {
        return (
            <Breadcrumbs>
                <Link to={Path.subscriptionList} color="inherit">
                    {I18n.formatMessage({ id: "pages.subscription.edit.breadcrumb" })}
                </Link>
                {this.state.subscription?.id && <ActiveBreadcrumbItem aria-current="page">{this.state.subscription.id}</ActiveBreadcrumbItem>}
            </Breadcrumbs>
        );
    };

    private renderField = (i18nLabelId: string, value?: JSX.Element | string | null) => (
        <div style={{ wordWrap: "break-word", whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
            <Typography color="textSecondary" gutterBottom>
                {I18n.formatMessage({ id: `pages.subscription.label.${i18nLabelId}` })}
            </Typography>
            <Typography variant="subtitle1">{value || "-"}</Typography>
        </div>
    );

    public render() {
        const { isLoading, subscription } = this.state;

        if (isLoading) {
            return <Loading breadcrumb={this.getBreadcrumb()} />;
        }

        if (!subscription) {
            return <Redirect to={Path.subscriptionList} />;
        }

        return (
            <PageLayout breadcrumb={this.getBreadcrumb()}>
                {subscription.id ? <TitleBar title={I18n.formatMessage({ id: "pages.subscription.form.title" }, { id: subscription.id })} /> : <></>}
                <PageCard.Container>
                    <PageCard.Heading title={I18n.formatMessage({ id: "common.form.basicData" })} />

                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            {this.renderField("id", `${subscription.id}`)}
                        </Grid>
                        <Grid item xs={6}>
                            {this.renderField("user", [subscription.user.lastname, subscription.user.firstname].join(" "))}
                        </Grid>
                    </Grid>

                    <Box mt="30px" />

                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            {this.renderField("email", `${subscription.user.email}`)}
                        </Grid>
                        <Grid item xs={6}>
                            {this.renderField("billing_address", subscription.billing_address ? OrderUtils.renderAddress(subscription.billing_address) : null)}
                        </Grid>
                    </Grid>

                    <Box mt="30px" />

                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            {this.renderField("shipping_address", subscription.shipping_address ? OrderUtils.renderAddress(subscription.shipping_address) : null)}
                        </Grid>
                        <Grid item xs={6}>
                            {this.renderField("created_at", subscription.created_at)}
                        </Grid>
                    </Grid>

                    <Box mt="30px" />

                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            {this.renderField("updated_at", subscription.updated_at)}
                        </Grid>
                        <Grid item xs={6}>
                            {this.renderField("paused_at", subscription.paused_at)}
                        </Grid>
                    </Grid>

                    <Box mt="30px" />

                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            {this.renderField("deleted_at", subscription.deleted_at)}
                        </Grid>
                        <Grid item xs={6}>
                            {this.renderField("notified_at", subscription.notified_at)}
                        </Grid>
                    </Grid>

                    <Box mt="30px" />

                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            {this.renderField("notified_at_sms", subscription.notified_at_sms)}
                        </Grid>
                        <Grid item xs={6}>
                            {this.renderField("date_next", subscription.date_next)}
                        </Grid>
                    </Grid>

                    <Box mt="30px" />

                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            {this.renderField("time_window_date", subscription.time_window_date)}
                        </Grid>
                        <Grid item xs={6}>
                            {this.renderField("time_window_interval", subscription.time_window_interval)}
                        </Grid>
                    </Grid>

                    <Box mt="30px" />

                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            {this.renderField("time_window_changed", subscription.time_window_changed ? `${subscription.time_window_changed}` : null)}
                        </Grid>
                        <Grid item xs={6}>
                            {this.renderField("time_window_shipping_method", subscription.time_window_shipping_method?.name)}
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            {this.renderField("deleted_at", subscription.deleted_at)}
                        </Grid>
                    </Grid>

                    <Box mt="30px" />

                    <PageCard.Heading title={I18n.formatMessage({ id: "pages.subscription.label.items" })} />
                    <SubscriptionItems items={subscription.items} />

                    <Box mt="30px" />

                    <PageCard.Heading title={I18n.formatMessage({ id: "pages.subscription.label.orders" })} />
                    <SubscriptionOrders orders={subscription.orders} />
                </PageCard.Container>
            </PageLayout>
        );
    }
}

export default withSnackbar(withRouter(SubscriptionPage));
