import { gql } from "@apollo/client";
import { categoryListItemFragment } from "../fragments/categoryListItemFragment";

export const categoriesQuery = gql`
    query categories {
        categories {
            ...CategoryListItem
        }
    }
    ${categoryListItemFragment}
`;
