import React, { useState } from "react";
import { ImageSelector, UploadedImage } from "@bigfish/admin-ui/components";
import { Theme, Grid, Box, makeStyles, IconButton, Icon, Popover, Typography } from "@bigfish/admin-ui/core";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { MediaLibraryHelper } from "Utils/MediaLibraryHelper";
import { I18n } from "I18n/I18n";
import { ProductImageSource } from "Api/graphql/admin/types";
import { ProductImageInputWithGenericUrl } from "Utils/ApiUtils";

const useStyles = makeStyles((theme: Theme) => ({
    relativeWrapper: {
        position: "relative",
        width: 176,

        "&:hover $optionsButton": {
            display: "block",
        },
        "&:hover $eyeButton": {
            display: "block",
        },
    },
    imageFrame: {
        position: "absolute",
        top: 12,
        left: 12,
        right: 12,
        bottom: 12,
        borderRadius: 5,
        border: `2px solid ${theme.palette.secondary.main}`,
        color: theme.palette.primary.contrastText,
        zIndex: 1,

        "&:before": {
            content: "''",
            position: "absolute",
            display: "block",
            width: 0,
            height: 0,
            borderStyle: "solid",
            borderWidth: "54px 54px 0 0",
            borderColor: `${theme.palette.secondary.main} transparent transparent transparent`,
        },
    },
    optionsButton: {
        display: "none",
        position: "absolute",
        top: 12,
        left: 12,
    },
    eyeButton: {
        display: "none",
        position: "absolute",
        top: 5,
        right: 5,
        width: 22,
        height: 22,
        padding: 0,
        color: theme.palette.error.contrastText,
        backgroundColor: theme.palette.warning.light,

        "&:hover": {
            color: theme.palette.error.contrastText,
            backgroundColor: theme.palette.warning.main,
        },
    },
    popoverPaper: {
        display: "inline-block",
        borderRadius: 4,
        backgroundColor: theme.palette.primary.dark,
        padding: "4px 8px",
        position: "relative",
        overflow: "visible",

        "&:after": {
            content: "''",
            display: "block",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            bottom: -6,
            width: 0,
            height: 0,
            borderLeft: "6px solid transparent",
            borderRight: "6px solid transparent",
            borderTop: `6px solid ${theme.palette.primary.dark}`,
        },
    },
    popoverText: {
        color: theme.palette.primary.contrastText,
        fontWeight: 500,
        fontSize: 10,
        cursor: "pointer",
    },
    caption: {
        fontSize: 8,
        color: "#445591",
        padding: "6px 10px",
        borderTop: "1px solid #D9E1FF",
    },
}));

type SortableUploadedImageProps = {
    caption: string | null;
    url: string;
    isPrimary?: boolean;
    style?: React.CSSProperties;
    pictureIndex: number;
    value: ProductImageInputWithGenericUrl[];
    onChange: (newImages: Array<ProductImageInputWithGenericUrl | null>) => void;
};

const SortableUploadedImage = SortableElement(({ caption, url, isPrimary, style, value, onChange, pictureIndex }: SortableUploadedImageProps) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const image = value[pictureIndex];

    const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const handleSetPrimary = () => {
        const rest = [...value];
        rest.splice(pictureIndex, 1);
        onChange([image, ...rest]);
    };

    const handleIsActive = (isActive: boolean) => {
        const newArray = [...value];
        newArray[pictureIndex] = {
            ...newArray[pictureIndex],
            is_active: isActive,
        };
        onChange(newArray);
    };

    const handleDelete = () => {
        const newArray = [...value];
        if (newArray[pictureIndex].is_new) {
            newArray.splice(pictureIndex, 1);
        } else {
            newArray[pictureIndex] = {
                ...newArray[pictureIndex],
                delete: true,
            };
        }
        onChange(newArray);
    };

    const open = Boolean(anchorEl);
    const id = open ? "set-image-primary-popover" : undefined;

    const isExternal = () => image.source !== ProductImageSource.webshop_admin;

    return !image.delete ? (
        <Grid item style={style} className={classes.relativeWrapper}>
            <UploadedImage name={url} source={url.startsWith(process.env.REACT_APP_MEDIA_LIBRARY_ASSET_URL) ? url : MediaLibraryHelper.getImageUrl(url)} onDelete={handleDelete} />
            {/* TODO: design szerinti szépítés: https://jira.bigfish.hu/browse/BFA-44 */}
            <Typography className={classes.caption}>{caption}</Typography>
            {!isPrimary && (
                <>
                    <IconButton aria-label="options" aria-describedby={id} size="small" className={classes.optionsButton} color="primary" onClick={handleOpenPopover}>
                        <Icon className="fas fa-ellipsis-v" fontSize="inherit" />
                    </IconButton>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClosePopover}
                        anchorOrigin={{
                            vertical: -20,
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "center",
                            horizontal: "center",
                        }}
                        classes={{
                            paper: classes.popoverPaper,
                        }}
                    >
                        <Typography className={classes.popoverText} onClick={handleSetPrimary}>
                            {I18n.formatMessage({ id: "pages.product.productImagesSortList.setPrimaryLabel" })}
                        </Typography>
                    </Popover>
                </>
            )}

            {isPrimary ? (
                <Box className={classes.imageFrame}>
                    <Icon className="fas fa-star" fontSize="small" style={{ position: "relative" }} />
                </Box>
            ) : (
                isExternal() && (
                    <IconButton className={classes.eyeButton} onClick={() => handleIsActive(!image.is_active)}>
                        <Icon className={image.is_active ? "fas fa-eye-slash" : "fas fa-eye"} style={{ fontSize: 12, position: "relative", right: 1 }} />
                    </IconButton>
                )
            )}
        </Grid>
    ) : null;
});

type Props = {
    value: ProductImageInputWithGenericUrl[];
    itemStyle?: React.CSSProperties;
    onChange: (newImages: Array<ProductImageInputWithGenericUrl | null>) => void;
    noSelect?: boolean;
};

class ProductImagesSortList extends React.Component<Props> {
    private onSelectImage = (): void => {
        MediaLibraryHelper.openLibrary({
            maximumSelectableAsset: 1,
            onSelection: this.onChange,
        });
    };

    private onChange = (assets: string[]) => {
        if (assets[0]) {
            this.props.onChange([
                ...this.props.value,
                {
                    is_new: true,
                    source: ProductImageSource.webshop_admin,
                    generic_url: assets[0],
                    delete: false,
                    is_active: true,
                },
            ]);
        }
    };

    render() {
        return (
            <Grid container spacing={3}>
                <Grid item style={this.props.itemStyle}>
                    {!this.props.noSelect && (
                        <div onClick={this.onSelectImage}>
                            <ImageSelector>{I18n.formatMessage({ id: "components.inputs.mediaLibraryImageInput.selectImage" })}</ImageSelector>
                        </div>
                    )}
                </Grid>
                {this.props.value.map((image: ProductImageInputWithGenericUrl, index: number) => (
                    <SortableUploadedImage
                        key={image.id}
                        caption={I18n.formatMessage(
                            { id: "pages.product.productImagesSortList.sourceLabel" },
                            { source: I18n.formatMessage({ id: `enums.productImageSort.${image.source}` }) }
                        )}
                        url={image.generic_url}
                        index={index}
                        pictureIndex={index}
                        isPrimary={index === 0}
                        style={this.props.itemStyle}
                        value={this.props.value}
                        onChange={this.props.onChange}
                    />
                ))}
            </Grid>
        );
    }
}

export default SortableContainer(ProductImagesSortList);
