import React from "react";
import { Api } from "Api/Api";
import DataGrid from "Components/DataGrid/DataGrid";
import { DataGridUrlQueryParser, QueryParserType } from "Components/DataGrid/DataGridUrlQueryParser";
import { ApiError } from "Api/ApiError";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { Box, Button, Checkbox, Grid, Tooltip, withStyles, WithStyles } from "@bigfish/admin-ui/core";
import { dataGridStyles } from "@bigfish/admin-ui/styles";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import { Constants } from "Utils/Constants";
import { OrderFilters, StoreOrderListItem, OrderSort, OrderSortField, listStoreOrders_listOrders_data, ShipmentStatusStoreEnum } from "Api/graphql/admin/types";
import { I18n } from "I18n/I18n";
import { DataSource, FilterValue } from "Components/DataGrid/DataGridUtils";
import { DataGridParams } from "Components/DataGrid/UrlQueryParser";
import SelectInput from "Components/SelectInput";
import "./styles.css";
import { ApplicationState } from "Redux/rootReducer";
import { Admin_roles } from "Api/graphql/auth/types";
import { connect } from "react-redux";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import { CustomTypeColumn } from "Utils/DataGridUtils";

type ComponentProps = {
    onlyClosed?: boolean;
};

type ReduxProps = {
    currentAdminRoles: Admin_roles[];
};

type Props = ComponentProps & WithSnackbarProps & WithStyles<typeof dataGridStyles> & ReduxProps;

type State = {
    orders: listStoreOrders_listOrders_data[];
    limit: number;
    selectedOrderIds: number[];
    activeFunction: StoreOrderFunction | "null";
    isUpdateDeliveredEnabled: "-" | "true" | "false";
    isUpdateHandedEnabled: "-" | "true" | "false";
    functionIndex: number; // To update the datagrid when changing statuses
};

enum StoreOrderFunction {
    updateDelivered = "updateDelivered",
    printOrders = "printOrders",
    updateHanded = "updateHanded",
}

class StoreOrderListDataGrid extends React.Component<Props, State> {
    private wrapRef1: React.RefObject<HTMLDivElement>;
    private wrapRef2: React.RefObject<HTMLDivElement>;

    public readonly state: State = {
        orders: [],
        limit: 0,
        selectedOrderIds: [],
        activeFunction: Constants.nullFormValue,
        isUpdateDeliveredEnabled: "-",
        isUpdateHandedEnabled: "-",
        functionIndex: 0,
    };

    constructor(props: Props) {
        super(props);
        this.wrapRef1 = React.createRef();
        this.wrapRef2 = React.createRef();
    }

    private removeOrderIds = (ids: number[]) => {
        this.setState({ selectedOrderIds: this.state.selectedOrderIds.filter(id => !ids.includes(id)) });
    };

    private addOrderIds = (ids: number[]) => {
        const newOrderIds = [...this.state.selectedOrderIds, ...ids];
        this.setState({ selectedOrderIds: [...new Set(newOrderIds)] });
    };

    private getColumns = (): CustomTypeColumn[] => {
        const columns = [
            {
                name: "selected",
                header: "",
                sortable: false,
                defaultWidth: 60,
                textAlign: "center",
                filterEditor: () => {
                    const isAllSelected = this.state.orders.every(order => this.state.selectedOrderIds?.includes(order.id));
                    return (
                        <Checkbox
                            checked={isAllSelected}
                            onChange={() => (isAllSelected ? this.removeOrderIds(this.state.selectedOrderIds) : this.addOrderIds(this.state.orders.map(o => o.id)))}
                        />
                    );
                },
                filterable: true,
                render: ({ data }: { data: StoreOrderListItem }) => {
                    const isAdded = this.state.selectedOrderIds?.includes(data.id);
                    return (
                        <div>
                            <Checkbox checked={isAdded} onChange={() => (isAdded ? this.removeOrderIds([data.id]) : this.addOrderIds([data.id]))} />
                        </div>
                    );
                },
            },
            {
                name: "increment_id",
                header: I18n.formatMessage({ id: "pages.storeOrderList.grid.column.increment_id" }),
                defaultWidth: Constants.columnWidth.id,
                filterDelay: Constants.filterDelayMS,
            },
            {
                name: "created_at",
                header: (
                    <Tooltip title={I18n.formatMessage({ id: "pages.storeOrderList.grid.column.created_at.long" })}>
                        <span>{I18n.formatMessage({ id: "pages.storeOrderList.grid.column.created_at.short" })}</span>
                    </Tooltip>
                ),
                defaultWidth: 100,
                render: ({ data }: { data: StoreOrderListItem }) => {
                    return <div>{data.created_at ? DateUtils.format(data.created_at, DateFormat.default) : ""}</div>;
                },
            },
            {
                name: "billing_fullname",
                header: I18n.formatMessage({ id: "pages.storeOrderList.grid.column.billing_fullname" }),
                defaultFlex: 1,
                minWidth: 200,
                sortable: false,
                filterDelay: Constants.filterDelayMS,
            },
            {
                name: "shipping_fullname",
                header: I18n.formatMessage({ id: "pages.storeOrderList.grid.column.shipping_fullname" }),
                defaultFlex: 1,
                minWidth: 200,
                sortable: false,
                filterDelay: Constants.filterDelayMS,
            },
            {
                name: "delivery_day_expected",
                header: (
                    <Tooltip title={I18n.formatMessage({ id: "pages.storeOrderList.grid.column.delivery_day_expected.long" })}>
                        <span>{I18n.formatMessage({ id: "pages.storeOrderList.grid.column.delivery_day_expected.short" })}</span>
                    </Tooltip>
                ),
                defaultWidth: 100,
                render: ({ data }: { data: StoreOrderListItem }) => {
                    return <div>{data.delivery_day_expected ? DateUtils.format(data.delivery_day_expected, DateFormat.default) : ""}</div>;
                },
            },
            {
                name: "packages",
                header: I18n.formatMessage({ id: "pages.storeOrderList.grid.column.packages" }),
                defaultWidth: 100,
                render: ({ data }: { data: StoreOrderListItem }) => {
                    return <div>{data.packages.length}</div>;
                },
                sortable: false,
            },
            {
                name: "is_in_store",
                textAlign: "center",
                header: (
                    <Tooltip title={I18n.formatMessage({ id: "pages.storeOrderList.grid.column.is_in_store.long" })}>
                        <span>{I18n.formatMessage({ id: "pages.storeOrderList.grid.column.is_in_store.short" })}</span>
                    </Tooltip>
                ),
                defaultWidth: 110,
                filterEditor: SelectFilter,
                filterEditorProps: {
                    dataSource: [
                        { id: "true", label: I18n.formatMessage({ id: "common.yes" }).toLowerCase() },
                        { id: "false", label: I18n.formatMessage({ id: "common.no" }).toLowerCase() },
                    ],
                },
                render: ({ data }: { data: StoreOrderListItem }) => <div>{I18n.formatMessage({ id: `common.${data.is_in_store ? "yes" : "no"}` })}</div>,
            },
            {
                name: "store_admin_is_took_over",
                textAlign: "center",
                header: I18n.formatMessage({ id: "pages.storeOrderList.grid.column.store_admin_is_took_over" }),
                defaultWidth: 200,
                filterEditor: SelectFilter,
                filterEditorProps: this.props.onlyClosed
                    ? {
                          dataSource: [
                              { id: "true", label: I18n.formatMessage({ id: "pages.storeOrderList.grid.column.store_admin_is_took_over.true" }).toLowerCase() },
                              { id: "false", label: I18n.formatMessage({ id: "pages.storeOrderList.grid.column.store_admin_is_took_over.false" }).toLowerCase() },
                          ],
                      }
                    : undefined,
                render: ({ data }: { data: StoreOrderListItem }) => {
                    if ([null, true, false].includes(data.store_admin_is_took_over)) {
                        return <div>{I18n.formatMessage({ id: `pages.storeOrderList.grid.column.store_admin_is_took_over.${data.store_admin_is_took_over}` })}</div>;
                    }
                    return null;
                },
            },
            {
                name: "delivered_at",
                defaultWidth: 100,
                header: (
                    <Tooltip title={I18n.formatMessage({ id: "pages.storeOrderList.grid.column.delivered_at.long" })}>
                        <span>{I18n.formatMessage({ id: "pages.storeOrderList.grid.column.delivered_at.short" })}</span>
                    </Tooltip>
                ),
                render: ({ data }: { data: StoreOrderListItem }) => {
                    return <div>{data.delivered_at ? DateUtils.format(data.delivered_at, DateFormat.default) : ""}</div>;
                },
            },
            {
                name: "shipment_status",
                defaultWidth: 200,
                header: I18n.formatMessage({ id: "pages.storeOrderList.grid.column.shipment_status" }),
                render: ({ data }: { data: StoreOrderListItem }) => {
                    return <div>{data.shipment_status?.title ?? ""}</div>;
                },
            },
        ];

        return columns
            .filter(column => (this.isUserStrictlyStoreAdmin() ? true : column.name !== "selected"))
            .map(column => {
                return { ...column, resizable: false };
            }) as CustomTypeColumn[];
    };

    private filterValues: FilterValue[] = [
        { name: "increment_id", operator: "eq", type: "number" },
        { name: "billing_fullname", operator: "contains", type: "string" },
        { name: "shipping_fullname", operator: "contains", type: "string" },
        { name: "is_in_store", operator: "eq", type: "select" },
    ];

    private urlQueryParser = new DataGridUrlQueryParser({
        filters: {
            increment_id: QueryParserType.integer,
            billing_fullname: QueryParserType.string,
            shipping_fullname: QueryParserType.string,
            is_in_store: QueryParserType.boolean,
        },
        sortField: OrderSortField,
    });

    private sortFieldToSortOrder = {
        [OrderSortField.increment_id]: "increment_id",
        [OrderSortField.created_at]: "created_at",
        [OrderSortField.delivery_day_expected]: "delivery_day_expected",
        [OrderSortField.is_in_store]: "is_in_store",
        [OrderSortField.store_admin_is_took_over]: "store_admin_is_took_over",
        [OrderSortField.delivered_at]: "delivered_at",
    };

    private dataSource = async (props: DataGridParams<OrderSort, OrderFilters>): Promise<DataSource<StoreOrderListItem>> => {
        try {
            const isTookOver = props.filters?.store_admin_is_took_over;
            const result = await Api.listStoreOrders({
                first: props.limit,
                page: props.page,
                filters: {
                    ...props.filters,
                    store_admin_is_took_over:
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        isTookOver === "true" ? true : isTookOver === "false" ? false : isTookOver === Constants.nullFormValue ? null : undefined,
                    is_closed: !!this.props.onlyClosed,
                },
                sortBy: props.sortBy,
            });

            // Filtering open/closed orders. Closed order = store_admin_is_took_over = true or false, but not null
            this.setState({ orders: result.data, limit: props.limit });
            return { data: result.data, count: result.paginatorInfo.total };
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }
        return { data: [], count: 0 };
    };

    private sendFunction = async () => {
        const { activeFunction } = this.state;

        if (!this.isUserStrictlyStoreAdmin() && (activeFunction === StoreOrderFunction.updateDelivered || activeFunction === StoreOrderFunction.updateHanded)) {
            return;
        }

        try {
            if (activeFunction === StoreOrderFunction.updateDelivered) {
                await Api.updateShipmentStatus(
                    this.state.selectedOrderIds,
                    this.state.isUpdateDeliveredEnabled === "true" ? ShipmentStatusStoreEnum.ready_to_pickup : ShipmentStatusStoreEnum.not_found
                );
                this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.storeOrderList.updateDeliveredSucceed" }), { variant: "success" });
            } else if (activeFunction === StoreOrderFunction.printOrders) {
                const pdfUrl = await Api.storeAdminPrintOrders(this.state.selectedOrderIds);
                if (pdfUrl) {
                    window.open(pdfUrl, "_new");
                }
            } else if (activeFunction === StoreOrderFunction.updateHanded) {
                const selectedOrders = this.state.orders.filter(order => this.state.selectedOrderIds.includes(order.id));
                const allOrdersInStore = selectedOrders.every(order => order.is_in_store);

                if (allOrdersInStore) {
                    await Api.updateShipmentStatus(
                        this.state.selectedOrderIds,
                        this.state.isUpdateHandedEnabled === "true"
                            ? ShipmentStatusStoreEnum.delivered
                            : this.state.isUpdateHandedEnabled === "false"
                            ? ShipmentStatusStoreEnum.failed
                            : ShipmentStatusStoreEnum.ready_to_pickup
                    );
                    this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.storeOrderList.updateHandedSucceed" }), { variant: "success" });
                } else {
                    this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.storeOrderList.updateHandedError" }), { variant: "error" });
                }
            }
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }

        if (activeFunction === StoreOrderFunction.updateHanded || activeFunction === StoreOrderFunction.updateDelivered) {
            this.setState({ functionIndex: this.state.functionIndex + 1 });
        }
    };

    private isUserStrictlyStoreAdmin = () => {
        return this.props.currentAdminRoles.find(r => r.name === "store") && !this.props.currentAdminRoles.find(r => r.name === "superadmin");
    };

    private getFunctions = () => {
        return this.isUserStrictlyStoreAdmin()
            ? [
                  { id: Constants.nullFormValue, title: "-" },
                  {
                      id: StoreOrderFunction.updateDelivered,
                      title: I18n.formatMessage({ id: "pages.storeOrderList.functions.updateDelivered" }),
                  },
                  {
                      id: StoreOrderFunction.printOrders,
                      title: I18n.formatMessage({ id: "pages.storeOrderList.functions.printOrders" }),
                  },
                  {
                      id: StoreOrderFunction.updateHanded,
                      title: I18n.formatMessage({ id: "pages.storeOrderList.functions.updateHanded" }),
                  },
              ]
            : [];
    };

    private renderFunctions = () => {
        if (!this.isUserStrictlyStoreAdmin()) {
            return null;
        }

        const functionSelector = (
            <SelectInput
                fullWidth
                variant="outlined"
                options={this.getFunctions()}
                onChange={(event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) =>
                    this.setState({ activeFunction: event.target.value as StoreOrderFunction | "null" })
                }
                value={this.state.activeFunction}
            />
        );

        const sendButton = (
            <Button
                variant="contained"
                size="medium"
                color="secondary"
                onClick={this.sendFunction}
                disabled={
                    this.state.activeFunction === Constants.nullFormValue ||
                    (this.state.activeFunction === StoreOrderFunction.updateDelivered && this.state.isUpdateDeliveredEnabled === "-") ||
                    (this.state.activeFunction === StoreOrderFunction.updateHanded && this.state.isUpdateHandedEnabled === "-")
                }
            >
                {I18n.formatMessage({ id: "pages.storeOrderList.sendFunction" })}
            </Button>
        );

        return this.state.activeFunction === StoreOrderFunction.updateDelivered || this.state.activeFunction === StoreOrderFunction.updateHanded ? (
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    {functionSelector}
                </Grid>
                <Grid item xs={3}>
                    <SelectInput
                        fullWidth
                        variant="outlined"
                        options={
                            this.state.activeFunction === StoreOrderFunction.updateDelivered
                                ? [
                                      { id: "-", title: "-" },
                                      { id: "true", title: I18n.formatMessage({ id: "common.yes" }) },
                                      { id: "false", title: I18n.formatMessage({ id: "common.no" }) },
                                  ]
                                : [
                                      { id: "-", title: "-" },
                                      { id: "true", title: I18n.formatMessage({ id: "pages.storeOrderList.handed.true" }) },
                                      { id: "false", title: I18n.formatMessage({ id: "pages.storeOrderList.handed.false" }) },
                                      { id: Constants.nullFormValue, title: I18n.formatMessage({ id: "pages.storeOrderList.handed.null" }) },
                                  ]
                        }
                        onChange={(event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
                            if (this.state.activeFunction === StoreOrderFunction.updateDelivered) {
                                this.setState({ isUpdateDeliveredEnabled: event.target.value as "true" | "false" });
                            } else {
                                this.setState({ isUpdateHandedEnabled: event.target.value as "true" | "false" });
                            }
                        }}
                        value={this.state.activeFunction === StoreOrderFunction.updateDelivered ? this.state.isUpdateDeliveredEnabled : this.state.isUpdateHandedEnabled}
                    />
                </Grid>
                <Grid className="store-order-send-button" item xs={3}>
                    {sendButton}
                </Grid>
            </Grid>
        ) : (
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    {functionSelector}
                </Grid>
                <Grid className="store-order-send-button" item xs={3}>
                    {sendButton}
                </Grid>
            </Grid>
        );
    };

    private selectAllOrders = () => {
        const ids = this.state.orders.map(order => order.id);
        this.setState({ selectedOrderIds: ids });
    };

    render() {
        return (
            <div>
                <Box mt="30px" />
                {this.renderFunctions()}
                <Box mt="30px" />

                {this.isUserStrictlyStoreAdmin() && (
                    <Button variant="contained" size="medium" color="secondary" onClick={this.selectAllOrders}>
                        {I18n.formatMessage({ id: "pages.orderList.selectAll" })}
                    </Button>
                )}
                <Box mt="30px" />

                <DataGrid
                    key={this.state.functionIndex}
                    className={this.props.classes.dataGrid}
                    urlQueryParser={this.urlQueryParser}
                    rowHeight={50}
                    style={{ minHeight: 800 }}
                    dataSource={this.dataSource}
                    filterValues={this.filterValues}
                    columns={this.getColumns()}
                    rowLink={undefined}
                    activeCell={null}
                    sortFieldToSortOrder={this.sortFieldToSortOrder}
                    onRowClick={() => {}}
                    rowStyle={row => {
                        const isTookOver = row.data.store_admin_is_took_over;
                        if (row.data.is_in_store && isTookOver === null) {
                            return { backgroundColor: Constants.color.lightYellow };
                        } else if (isTookOver !== null) {
                            return { backgroundColor: isTookOver ? Constants.color.lightGreen : Constants.color.lightRed };
                        }
                        return undefined;
                    }}
                />

                <Box mt="15px" />
            </div>
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { currentAdminRoles: state.auth.admin?.roles ?? [] };
};

export default withSnackbar(connect(mapStateToProps)(withStyles(dataGridStyles, { withTheme: true })(StoreOrderListDataGrid)));
