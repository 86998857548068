import { gql } from "@apollo/client";
import { orderFragment } from "../fragments/orderFragment";

// TODO: Prod backend not ready yet: https://bigfishhu.atlassian.net/browse/ROS-3917
export const correctInvoiceMutation = gql`
    mutation correctInvoice($increment_id: Int!, $items: [InvoiceCorrectionItemInput!]!, $csrf: String) {
        correctInvoice(increment_id: $increment_id, items: $items, csrf: $csrf) {
            ...Order
        }
    }
    ${orderFragment}
`;

// export const correctInvoiceMutation = gql`
//     mutation correctInvoice($increment_id: Int!, $items: [OrderItemInputV2!]!) {
//         correctInvoice(increment_id: $increment_id, items: $items) {
//             ...Order
//         }
//     }
//     ${orderFragment}
// `;
