import { gql } from "@apollo/client";

export const galleryListItemFragment = gql`
    fragment GalleryListItem on Gallery {
        id
        title
        handle
        image_count
        updated_at
    }
`;
