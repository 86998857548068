import { Box } from "@bigfish/admin-ui/core";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { ModalProductListForm, productListValidator } from "./ModalProductListForm";
import Prompt from "Components/Prompt";
import { TitleBar } from "@bigfish/admin-ui/components";
import { I18n } from "I18n/I18n";
import { ProductListMode, ProductListType } from "Api/graphql/admin/types";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import { ProductListFormValues } from "Pages/ProductList/ProductListForm";

type ComponentProps = {
    productListType: string;
    onModalClose: () => void;
};

type Props = ComponentProps & WithSnackbarProps;

type State = {
    isCreateSucceed: boolean;
};

class ModalProductListCreatePage extends React.Component<Props, State> {
    public readonly state: State = {
        isCreateSucceed: false,
    };

    private onSubmit = async (values: ProductListFormValues, formikHelpers: FormikHelpers<ProductListFormValues>, isRedirectHandled?: boolean): Promise<boolean> => {
        try {
            await Api.createProductList({
                type: values.type,
                mode: values.mode,
                name: values.name,
                title: values.title,
                subtitle: values.subtitle,
                slug: values.slug,
                is_active: values.is_active,
                active_from: values.active_from ? DateUtils.format(values.active_from, DateFormat.apiDateTime) : null,
                active_to: values.active_to ? DateUtils.format(values.active_to, DateFormat.apiDateTime) : null,
                description: values.description,
                image: values.image,
                image_title: values.image_title,
                image_mobile: values.image_mobile,
                image_mobile_title: values.image_mobile_title,
                og_title: values.og_title,
                og_description: values.og_description,
                og_image: values.og_image,
                group_by_category: values.group_by_category,
                filters: values.filters,
                products: values.products,
                badge_id: values.badge?.id ?? null,
            });
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.productList.create.onSubmit.success" }), { variant: "success" });
            if (!isRedirectHandled) {
                this.setState({ isCreateSucceed: true }, this.props.onModalClose);
            }
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.productList.create.onSubmit.error" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
            return false;
        }
    };

    public render() {
        const initialValues: ProductListFormValues = {
            type: this.props.productListType as ProductListType,
            mode: ProductListMode.manual,
            name: "",
            title: "",
            subtitle: "",
            slug: "",
            is_active: false,
            active_from: null,
            active_to: null,
            description: "",
            image: "",
            image_mobile: "",
            og_image: "",
            products: [],
            filters: [],
            group_by_category: false,
            badge: null,
        };

        return (
            <div className="modal-grey-background">
                <TitleBar title={I18n.formatMessage({ id: "pages.productList.add" })} />
                <Formik initialValues={initialValues} onSubmit={this.onSubmit} validate={productListValidator} validateOnBlur={false}>
                    {props => (
                        <>
                            <ModalProductListForm formType={FormType.create} productListType={this.props.productListType as ProductListType} formProps={props} />
                            <Prompt when={props.dirty} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props, true)} />
                        </>
                    )}
                </Formik>
                <Box mt="40px" />
            </div>
        );
    }
}

export default withSnackbar(ModalProductListCreatePage);
