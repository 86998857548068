import { PermissionType, Admin_roles, Admin_roles_permissions } from "Api/graphql/admin/types";
import { AuthReducerType } from "Redux/reducers/authReducer";

export class AuthSelectors {
    public static isLoggedIn(state: AuthReducerType): boolean {
        return state.authToken !== null;
    }

    public static isSuperadmin(state: AuthReducerType): boolean {
        return (!!state.admin?.roles && !!state.admin.roles.find(r => r.name === "superadmin")) ?? false;
    }

    public static isStoreAdmin(state: AuthReducerType): boolean {
        return AuthSelectors.isSuperadmin(state) || !!state.admin?.roles.find(r => r.name === "store");
    }

    public static isStoreOnlyAdmin(state: AuthReducerType): boolean {
        return !!state.admin?.roles && state.admin.roles.length === 1 && !!state.admin.roles.find(r => r.name === "store");
    }

    public static getPermissions(state: AuthReducerType): PermissionType[] {
        const roles = state.admin?.roles;
        if (!roles) return [];
        return roles
            .map((role: Admin_roles) => {
                return role.permissions.map((permission: Admin_roles_permissions) => permission.name);
            })
            .flat();
    }
}
