import React, { useCallback, useState } from "react";

import { FormikErrors, FormikProps } from "formik";
import { useHistory } from "react-router-dom";

import { FullscreenLoader, PageCard, TabsComponent } from "@bigfish/admin-ui/components";
import { Box, Button, Grid, Icon } from "@bigfish/admin-ui/core";

import { Banner, BannerInput, BannerType } from "Api/graphql/admin/types";
import { Form, FormType } from "Components/Form";
import LogListDataGrid from "Pages/LogList/LogListDataGrid";
import { I18nHelpers } from "I18n/I18nHelpers";
import { I18n } from "Src/i18n/I18n";
import { Validator } from "Utils/Validator";
import { GeneralTab } from "Pages/Banner/GeneralTab";
import { StatisticsPaper } from "Components/StatisticsPaper";

export type BannerFormValues = BannerInput;

type Props = {
    formType: FormType;
    formProps: FormikProps<BannerFormValues>;
    banner?: Banner;
    onClose: () => void;
};

export const bannerValidator = (formType: FormType) => (values: BannerFormValues): FormikErrors<BannerFormValues> => {
    const errors: { [key in keyof BannerFormValues]?: any } = {};

    if (!errors.active_from && formType === FormType.create) {
        errors.active_from = I18nHelpers.formatValidator(Validator.startDatePast)(values.active_from);
    }

    if (values.active_from && values.active_to) {
        errors.active_to = Validator.endDateCantBeBeforeStart(values.active_from, values.active_to);
        errors.active_from = errors.active_from || Validator.startDateCantBeAfterEnd(values.active_from, values.active_to);
    }

    const urlErrors = new Array(values.urls.length).fill(null);
    if (values.type === BannerType.product_list && values.urls) {
        for (let i = 0; i < values.urls.length; i++) {
            if (!values.urls[i]) {
                urlErrors[i] = I18n.formatMessage({ id: "enums.validatorMessage.required" });
            }
        }
    }
    if (values.target_url) {
        errors.target_url = I18nHelpers.formatValidator(Validator.slug)(values.target_url);
    }
    errors.urls = urlErrors.find(ue => ue !== null) ? urlErrors : undefined;

    return Form.cleanupFormikErrors(errors);
};

export const ModalBannerForm = (props: Props) => {
    const history = useHistory();
    const onCancelClick = useCallback(() => {
        props.onClose();
    }, [history]);
    const [formTab, setFormTab] = useState(0);

    const changeTab = (_event: React.ChangeEvent<{}>, newValue: number) => {
        setFormTab(newValue);
    };

    const bannerTabs =
        props.formType === FormType.edit
            ? [
                  {
                      label: I18n.formatMessage({ id: "pages.product.form.tabs.general" }),
                      id: "product-form-general-tab",
                      content: <GeneralTab banner={props.banner} formProps={props.formProps} formType={props.formType} />,
                  },
                  {
                      label: I18n.formatMessage({ id: "pages.product.form.tabs.log" }),
                      id: "product-form-log-tab",
                      content: (
                          <Box mt="30px">
                              <LogListDataGrid parentId={props.banner?.id} />
                          </Box>
                      ),
                  },
              ]
            : [
                  {
                      label: I18n.formatMessage({ id: "pages.product.form.tabs.general" }),
                      id: "product-form-general-tab",
                      content: <GeneralTab banner={props.banner} formProps={props.formProps} formType={props.formType} />,
                  },
              ];

    return (
        <div>
            <PageCard.Container>
                <Form formProps={props.formProps}>
                    {props.formType === FormType.edit ? (
                        <TabsComponent ariaLabel="product-form-tabs" tabs={bannerTabs} currentTab={formTab} changeTab={changeTab} />
                    ) : (
                        <GeneralTab banner={props.banner} formProps={props.formProps} formType={props.formType} />
                    )}
                    <Box mt="30px" />
                    <Grid container justify="space-between">
                        <Button variant="outlined" color="primary" onClick={onCancelClick}>
                            {I18n.formatMessage({ id: "common.cancel" })}
                        </Button>
                        <Button
                            onClick={() => props.formProps.submitForm()}
                            startIcon={<Icon className="fas fa-save" />}
                            variant="contained"
                            color="secondary"
                            disabled={!props.formProps.dirty || !props.formProps.isValid}
                        >
                            {I18n.formatMessage({ id: `common.${props.formType === FormType.create ? "create" : "save"}` })}
                        </Button>
                    </Grid>
                    <FullscreenLoader visible={props.formProps.isSubmitting} />
                </Form>
            </PageCard.Container>
            {props.formType === FormType.edit && props.banner && (
                <StatisticsPaper
                    createdAt={props.banner.created_at}
                    createdBy={props.banner.created_by?.name}
                    updatedAt={props.banner.updated_at}
                    updatedBy={props.banner.updated_by?.name}
                />
            )}
        </div>
    );
};
