import React, { useEffect, useState } from "react";
import { Button } from "@bigfish/admin-ui/core";
import { I18n } from "I18n/I18n";
import { Icon, Paper, RootRef, Table, TableBody, TableCell, TableContainer, TableRow } from "@bigfish/admin-ui/core";
import { DragDropContext, Draggable, DraggingStyle, Droppable, DropResult, NotDraggingStyle } from "react-beautiful-dnd";
import { ProductCatalogItem } from "Api/graphql/admin/types";
import { createModal } from "Components/createModal";
import "./styles.css";
import { ListItemActive } from "Components/ListItemActive";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { useSnackbar } from "notistack";

type Props = {
    productIds: number[];
    onClose: () => void;
    onConfirmClick: (products: number[]) => void;
};

const ReorderModalComponent = createModal({ modalWidth: 1280, modalHeight: 800 });

export const ReorderModal = (props: Props) => {
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(true);
    const [products, setProducts] = useState<ProductCatalogItem[]>([]);

    useEffect(() => {
        (async () => {
            try {
                const result = await Api.listProducts({
                    first: props.productIds.length,
                    page: 1,
                    filters: { id: props.productIds },
                });

                const sorted: ProductCatalogItem[] = [];
                props.productIds.forEach(id => {
                    const foundProduct = result.data.find(d => d.id === id);
                    if (foundProduct) sorted.push(foundProduct);
                });

                setProducts(sorted);
            } catch (error) {
                if (error instanceof ApiError) {
                    enqueueSnackbar(error.message, { variant: "error" });
                }
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    const onConfirmClick = () => {
        setLoading(true);
        props.onConfirmClick(products.map(p => p.id));
        setLoading(false);
    };

    const onClose = (): void => {
        if (isLoading) {
            return;
        }
        props.onClose();
    };

    const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined) => ({
        ...draggableStyle,
        ...(isDragging && {
            background: "rgb(235,235,235)",
        }),
    });

    const reorder = (list: ProductCatalogItem[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) return;

        const newProducts = reorder(products, result.source.index, result.destination.index);
        setProducts(newProducts);
    };

    return (
        <ReorderModalComponent
            title={I18n.formatMessage({ id: "pages.productList.form.reorderModal.title" })}
            rightButtonsComponent={
                <Button variant="contained" color="primary" onClick={onConfirmClick} disabled={isLoading}>
                    {I18n.formatMessage({ id: "pages.productList.form.reorderModal.confirmButtonLabel" })}
                </Button>
            }
            leftButtonsComponent={
                <Button variant="outlined" color="primary" onClick={onClose} disabled={isLoading}>
                    {I18n.formatMessage({ id: "common.cancel" })}
                </Button>
            }
            open={true}
            onClose={onClose}
        >
            <TableContainer component={Paper}>
                {isLoading ? (
                    <i className="fas fa-spinner fa-pulse" />
                ) : (
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {provided => (
                                <RootRef rootRef={provided.innerRef}>
                                    <Table>
                                        <TableBody>
                                            <TableRow className="reorder-table-header">
                                                <TableCell align="left" />
                                                <TableCell align="left">{I18n.formatMessage({ id: "pages.productCatalog.grid.column.id" })}</TableCell>
                                                <TableCell align="left">{I18n.formatMessage({ id: "pages.productCatalog.grid.column.ean" })}</TableCell>
                                                <TableCell align="left">{I18n.formatMessage({ id: "pages.productCatalog.grid.column.lfdnr" })}</TableCell>
                                                <TableCell align="left">{I18n.formatMessage({ id: "pages.productCatalog.grid.column.name" })}</TableCell>
                                                <TableCell align="left">{I18n.formatMessage({ id: "pages.productCatalog.grid.column.main_categories" })}</TableCell>
                                                <TableCell align="left">{I18n.formatMessage({ id: "pages.productCatalog.grid.column.categories" })}</TableCell>
                                                <TableCell align="left">{I18n.formatMessage({ id: "pages.productCatalog.grid.column.is_visible" })}</TableCell>
                                                <TableCell align="left">{I18n.formatMessage({ id: "pages.productCatalog.grid.column.is_description" })}</TableCell>
                                                <TableCell align="left">{I18n.formatMessage({ id: "pages.productCatalog.grid.column.is_image" })}</TableCell>
                                                <TableCell align="left">{I18n.formatMessage({ id: "pages.productCatalog.grid.column.prices" })}</TableCell>
                                                <TableCell align="left">{I18n.formatMessage({ id: "pages.productCatalog.grid.column.stock_qty" })}</TableCell>
                                            </TableRow>
                                            {products.map((product, index) => (
                                                <Draggable key={product.id} draggableId={`${product.id}`} index={index}>
                                                    {(provided, snapshot) => (
                                                        <TableRow
                                                            className="reorder-cell-row"
                                                            key={product.id}
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                        >
                                                            <TableCell className="draggable-table-icon-cell" align="left">
                                                                <Icon className="fas fa-ellipsis-v draggable-icon draggable-ellipsis-icon" />
                                                                <Icon className="fas fa-ellipsis-v draggable-icon draggable-ellipsis-icon" />
                                                            </TableCell>
                                                            <TableCell align="left">{product.id}</TableCell>
                                                            <TableCell align="left">{product.ean.join(", ")}</TableCell>
                                                            <TableCell align="left">{product.lfdnr}</TableCell>
                                                            <TableCell align="left">{product.name}</TableCell>
                                                            <TableCell align="left">{product.main_categories.map(c => c.name).join(", ")}</TableCell>
                                                            <TableCell align="left">{product.categories.map(c => c.name).join(", ")}</TableCell>
                                                            <TableCell align="left">{<ListItemActive isActive={product.is_visible} fromDate={product.active_from} />}</TableCell>
                                                            <TableCell align="left">
                                                                {I18n.formatMessage({ id: `common.${product.is_description ? "present" : "absent"}` }).toLowerCase()}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {I18n.formatMessage({ id: `common.${product.is_image ? "present" : "absent"}` }).toLowerCase()}
                                                            </TableCell>
                                                            <TableCell align="left" className="reorder-cell-price">
                                                                {product.price ? I18n.formatCurrency(product.price) : null}
                                                            </TableCell>
                                                            <TableCell align="left">{product.stock_qty ?? null}</TableCell>
                                                        </TableRow>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </TableBody>
                                    </Table>
                                </RootRef>
                            )}
                        </Droppable>
                    </DragDropContext>
                )}
            </TableContainer>
        </ReorderModalComponent>
    );
};
