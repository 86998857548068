import { gql } from "@apollo/client";

export const pageBlockBannerFragment = gql`
    fragment PageBlockBanner on PageBlockBanner {
        id
        type
        layout
        title
        banners {
            ... on BannerNormal {
                id
                name
            }
            ... on BannerProductList {
                id
                name
            }
            ... on BannerProduct {
                id
                name
            }
        }
    }
`;
