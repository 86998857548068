import React from "react";
import { Api } from "Api/Api";
import { TypeRowProps } from "@inovua/reactdatagrid-community/types";
import DataGrid from "Components/DataGrid/DataGrid";
import { DepartmentFilters, DepartmentListItem, DepartmentSort, DepartmentSortField, OrderDirection } from "Api/graphql/admin/types";
import { DataGridUrlQueryParser, QueryParserType } from "Components/DataGrid/DataGridUrlQueryParser";
import { ApiError } from "Api/ApiError";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { Path } from "Utils/Path";
import { I18n } from "I18n/I18n";
import { DataSource, FilterValue } from "Components/DataGrid/DataGridUtils";
import { DataGridParams } from "Components/DataGrid/UrlQueryParser";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import { CustomTypeColumn } from "Utils/DataGridUtils";
import { Constants } from "Utils/Constants";

type Props = WithSnackbarProps;

class DepartmentListDataGrid extends React.Component<Props> {
    private getColumns = (): CustomTypeColumn[] => {
        return [
            { name: "id", sortable: false, header: I18n.formatMessage({ id: "pages.departmentList.grid.column.id" }), width: 132, filterDelay: Constants.filterDelayMS },
            {
                name: "name",
                header: I18n.formatMessage({ id: "pages.departmentList.grid.column.name" }),
                defaultFlex: 1,
                minWidth: 200,
                sortable: false,
                filterDelay: Constants.filterDelayMS,
            },
            {
                name: "created_at",
                width: 300,
                header: I18n.formatMessage({ id: "pages.departmentList.grid.column.created_at" }),
                filterEditor: DateFilter,
                dateFormat: "YYYY-MM-DD",
                sortable: false,
            },
            {
                name: "updated_at",
                width: 500,
                header: I18n.formatMessage({ id: "pages.departmentList.grid.column.updated_at" }),
                filterEditor: DateFilter,
                dateFormat: "YYYY-MM-DD",
                sortable: false,
            },
        ];
    };

    private filterValues: FilterValue[] = [
        { name: "id", operator: "eq", type: "number" },
        { name: "name", operator: "contains", type: "string" },
        { name: "created_at", operator: "inrange", type: "date" },
        { name: "updated_at", operator: "inrange", type: "date" },
    ];

    private urlQueryParser = new DataGridUrlQueryParser({
        filters: {
            id: QueryParserType.integer,
            name: QueryParserType.string,
            created_at: QueryParserType.dateRange,
            updated_at: QueryParserType.dateRange,
        },
        sortField: DepartmentSortField,
    });

    private dataSource = async (props: DataGridParams<DepartmentSort, DepartmentFilters>): Promise<DataSource<DepartmentListItem>> => {
        try {
            const result = await Api.departments({
                first: props.limit,
                page: props.page,
                filters: props.filters,
                sortBy: { direction: OrderDirection.DESC, field: DepartmentSortField.order },
            });
            return { data: result.data, count: result.paginatorInfo.total };
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }
        return { data: [], count: 0 };
    };

    private getRowLink = (rowProps: TypeRowProps): string => {
        return Path.departmentEdit(rowProps.data.id);
    };

    render() {
        return (
            <DataGrid
                urlQueryParser={this.urlQueryParser}
                rowHeight={50}
                style={{ minHeight: 800 }}
                dataSource={this.dataSource}
                filterValues={this.filterValues}
                columns={this.getColumns()}
                rowLink={this.getRowLink}
                activeCell={null}
                sortFieldToSortOrder={{}}
            />
        );
    }
}

export default withSnackbar(DepartmentListDataGrid);
