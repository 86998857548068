import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { adminTheme } from "@bigfish/admin-ui";
import { ThemeProvider, CssBaseline } from "@bigfish/admin-ui/core";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { SnackbarProvider } from "notistack";
import SnackbarCloseButton from "Components/SnackbarCloseButton";
import App from "./App";
import { persistor, store } from "Redux/store";

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={adminTheme}>
            <CssBaseline />

            <SnackbarProvider
                maxSnack={5}
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
                autoHideDuration={4000}
                action={key => <SnackbarCloseButton snackbarKey={key} />}
            >
                <Provider store={store}>
                    <PersistGate persistor={persistor}>
                        <BrowserRouter>
                            <App />
                        </BrowserRouter>
                    </PersistGate>
                </Provider>
            </SnackbarProvider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById("root")
);
