import React, { useCallback, useState } from "react";
import { DefaultModal } from "@bigfish/admin-ui/components";
import { Box, Button, TextField } from "@bigfish/admin-ui/core";
import styled from "styled-components";
import { I18n } from "I18n/I18n";
import { LocalGiftCardCompany } from "./GiftCardOrderCompanyForm";
import { NumberUtils } from "Utils/NumberUtils";
import { useSnackbar } from "notistack";

type Props = {
    isVisible: boolean;
    title: React.ReactNode;
    editedItem: LocalGiftCardCompany | "new";
    onClose: () => void;
    onSave: (value: string, mail_to: string) => void | Promise<void>;
};

export const GiftCardCompanyModal = (props: Props) => {
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setLoading] = useState(false);

    const [value, setValue] = useState<string>(props.editedItem !== "new" && props.editedItem.value ? `${props.editedItem.value}` : "");
    const [valueError, setValueError] = useState<string | null>(null);

    const [quantity, setQuantity] = useState<string>(props.editedItem !== "new" && props.editedItem.quantity ? `${props.editedItem.quantity}` : "");
    const [quantityError, setQuantityError] = useState<string | null>(null);

    const onFunctionClick = useCallback(async (): Promise<void> => {
        setLoading(true);
        await props.onSave(value, quantity);
        setLoading(false);
    }, [props, value, quantity]);

    const onClose = useCallback((): void => {
        if (isLoading) {
            return;
        }
        props.onClose();
    }, [isLoading, props]);

    return (
        <GiftCardModalWrapper>
            <DefaultModal
                title={props.title}
                rightButtonsComponent={
                    <Button variant="contained" color="primary" onClick={onFunctionClick} disabled={isLoading || !value || !!valueError || !quantity || !!quantityError}>
                        {I18n.formatMessage({ id: "common.save" })}
                    </Button>
                }
                leftButtonsComponent={
                    <Button variant="outlined" color="primary" onClick={onClose} disabled={isLoading}>
                        {I18n.formatMessage({ id: "common.cancel" })}
                    </Button>
                }
                open={props.isVisible}
                onClose={onClose}
            >
                <GiftCardModalInnerWrapper>
                    <TextField
                        type="number"
                        label={I18n.formatMessage({ id: "pages.giftCardOrderCompany.form.giftCard.valueLabel" })}
                        fullWidth
                        variant="outlined"
                        required
                        helperText={valueError || I18n.formatMessage({ id: "common.required" })}
                        error={!!valueError}
                        value={value}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (NumberUtils.isStringNumber(e.currentTarget.value)) {
                                const leadingZerosTrimmed = e.currentTarget.value.replace(/^[0]+/g, "");
                                const converted = Number.parseInt(leadingZerosTrimmed, 10);

                                setValue(converted ? `${converted}` : "");
                            }
                        }}
                        onBlur={(e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                            if (!e.currentTarget.value) {
                                setValueError(I18n.formatMessage({ id: "common.required" }));
                                return;
                            } else {
                                setValueError(null);
                            }
                            const rounded = NumberUtils.getRoundedValue(e.currentTarget.value);
                            if (!rounded) return;

                            // Notify user about the value being rounded
                            if (e.currentTarget.value !== `${rounded}`) {
                                enqueueSnackbar(
                                    I18n.formatMessage(
                                        { id: "pages.giftCardOrderCompany.modal.roundedNotification" },
                                        { notRounded: I18n.formatCurrency(Number.parseInt(e.currentTarget.value, 10)), rounded: I18n.formatCurrency(rounded) }
                                    ),
                                    { variant: "warning" }
                                );
                            }

                            setValue(`${NumberUtils.getRoundedValue(e.currentTarget.value)}`);
                        }}
                    />

                    <Box mt="30px" />

                    <TextField
                        type="number"
                        label={I18n.formatMessage({ id: "pages.giftCardOrderCompany.form.giftCard.quantityLabel" })}
                        fullWidth
                        variant="outlined"
                        required
                        helperText={quantityError || I18n.formatMessage({ id: "common.required" })}
                        error={!!quantityError}
                        value={quantity}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (NumberUtils.isStringNumber(e.currentTarget.value)) {
                                const leadingZerosTrimmed = e.currentTarget.value.replace(/^[0]+/g, "");
                                const converted = Number.parseInt(leadingZerosTrimmed, 10);

                                setQuantity(converted ? `${converted}` : "");
                            }
                        }}
                        onBlur={(e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                            if (!e.currentTarget.value) {
                                setQuantityError(I18n.formatMessage({ id: "common.required" }));
                                return;
                            } else {
                                setQuantityError(null);
                            }
                        }}
                    />
                </GiftCardModalInnerWrapper>
            </DefaultModal>
        </GiftCardModalWrapper>
    );
};

const GiftCardModalInnerWrapper = styled.div`
    padding-top: 20px;
    max-height: 500px;
    overflow-y: scroll;
`;

const GiftCardModalWrapper = styled.div`
    .MuiCard-root {
        overflow: hidden !important;
    }
`;
