import { gql } from "@apollo/client";
import { modelUpdateLogFragment } from "./../fragments/modelUpdateLogFragment";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";

export const modelUpdateLogQuery = gql`
    query modelUpdateLog($filters: LogFilters, $sortBy: LogSort, $first: Int!, $page: Int) {
        modelUpdateLog(filters: $filters, sortBy: $sortBy, first: $first, page: $page) {
            data {
                ...ModelUpdateLog
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${modelUpdateLogFragment}
    ${paginatorInfoFragment}
`;
