import { Box, Typography } from "@bigfish/admin-ui/core";
import { ActiveBreadcrumbItem } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Form } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { GiftCardOrderCompanyForm, GiftCardOrderCompanyInputLocal } from "./GiftCardOrderCompanyForm";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { PageLayout } from "Components/PageLayout";
import { Link } from "Components/Link";
import { Breadcrumbs } from "Components/Breadcrumbs";
import Prompt from "Components/Prompt";
import { I18n } from "I18n/I18n";
import { GiftCardDesign, GiftCardOrderCompanyInput, GiftCardOrderType, GiftCardOrderTypeWithTitle } from "Api/graphql/admin/types";
import { Loading } from "Components/Loading";

type Props = RouteComponentProps & WithSnackbarProps;

type State = {
    isCreateSucceed: boolean;
    isLoading: boolean;
    giftCardDesigns: GiftCardDesign[];
    types: GiftCardOrderTypeWithTitle[];
};

class GiftCardOrderCompanyCreatePage extends React.Component<Props, State> {
    public readonly state: State = {
        isCreateSucceed: false,
        isLoading: true,
        giftCardDesigns: [],
        types: [],
    };

    public async componentDidMount(): Promise<void> {
        try {
            const giftCardDesigns = await Api.getGiftCardDesigns();
            const types = await Api.getGiftCardOrderTypes();
            if (giftCardDesigns.length === 0 || types.length === 0) {
                const snack = (message: string) => this.props.enqueueSnackbar(message, { variant: "error" });

                if (giftCardDesigns.length === 0) snack(I18n.formatMessage({ id: "pages.giftCardOrderCompany.getGiftCardDesignsError" }));
                if (types.length === 0) snack(I18n.formatMessage({ id: "pages.giftCardOrderCompany.getGiftCardOrderTypesError" }));

                this.props.history.push(Path.giftCardOrderList);
                return;
            }
            this.setState({ giftCardDesigns, types });
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
            this.props.history.push(Path.giftCardOrderList);
        } finally {
            this.setState({ isLoading: false });
        }
    }

    private onSubmit = async (values: GiftCardOrderCompanyInputLocal, formikHelpers: FormikHelpers<GiftCardOrderCompanyInputLocal>): Promise<boolean> => {
        try {
            // GiftCardOrderCompanyInputLocal -> GiftCardOrderCompanyInput
            const convertedValues: GiftCardOrderCompanyInput = {
                ...values,
                gift_cards: values.gift_cards.map(gc => ({
                    value: gc.value ? Number.parseInt(`${gc.value}`, 10) : 0,
                    quantity: gc.quantity ? Number.parseInt(`${gc.quantity}`, 10) : 0,
                })),
            };
            const id = await Api.createGiftCardOrderCompany(convertedValues);
            if (id) {
                this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.giftCardOrderCompany.succeed" }), { variant: "success" });
            }
            this.setState({ isCreateSucceed: true }, () => this.props.history.push(Path.giftCardOrderList));
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.giftCardOrderCompany.error" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
            return false;
        }
    };

    public render() {
        if (this.state.isCreateSucceed) {
            return <Redirect to={Path.giftCardOrderList} />;
        }

        if (this.state.isLoading) {
            return <Loading />;
        }

        const initialValues: GiftCardOrderCompanyInputLocal = {
            design: this.state.giftCardDesigns[0].id,
            type: this.state.types[0].id as GiftCardOrderType,
            name_from: "",
            message: "",
            payment_reference: "",
            name_to: "",
            mail_to: "",
            gift_cards: [],
        };

        return (
            <PageLayout
                breadcrumb={
                    <Breadcrumbs>
                        <Link to={Path.giftCardOrderList} color="inherit">
                            {I18n.formatMessage({ id: "pages.giftCardOrder.info.breadcrumb" })}
                        </Link>
                        <ActiveBreadcrumbItem aria-current="page">{I18n.formatMessage({ id: "pages.giftCardOrderCompany.createBreadcrumb" })}</ActiveBreadcrumbItem>
                    </Breadcrumbs>
                }
            >
                <Typography variant="h1">{I18n.formatMessage({ id: "pages.giftCardOrderCompany.createBreadcrumb" })}</Typography>
                <Formik initialValues={initialValues} onSubmit={this.onSubmit} validateOnBlur={false}>
                    {props => (
                        <>
                            <GiftCardOrderCompanyForm designs={this.state.giftCardDesigns} types={this.state.types} formProps={props} />
                            <Prompt when={props.dirty && !this.state.isCreateSucceed} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props)} />
                        </>
                    )}
                </Formik>
                <Box mt="40px" />
            </PageLayout>
        );
    }
}

export default withRouter(withSnackbar(GiftCardOrderCompanyCreatePage));
