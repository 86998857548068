import { ActiveBreadcrumbItem } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { ProductList, ProductListMinimal } from "Api/graphql/admin/types";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { ModalProductListForm, productListValidator } from "./ModalProductListForm";
import { Redirect } from "react-router-dom";
import { Path } from "Utils/Path";
import { Loading } from "Components/Loading";
import { Link } from "Components/Link";
import { Breadcrumbs } from "Components/Breadcrumbs";
import Prompt from "Components/Prompt";
import { Box } from "@bigfish/admin-ui/core";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import { I18n } from "I18n/I18n";
import { ProductListUtils } from "Utils/ProductListUtils";
import { ProductListFormValues } from "Pages/ProductList/ProductListForm";

type ComponentProps = {
    id: number;
    onModalClose: () => void;
    onUpdate: (productList: ProductListMinimal | null) => void;
};

type Props = ComponentProps & WithSnackbarProps;

type State = {
    isLoading: boolean;
    productList: ProductList | null;
};

class ModalProductListPage extends React.Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        productList: null,
    };

    public async componentDidMount(): Promise<void> {
        try {
            const productList = await Api.getProductListById(this.props.id);
            this.setState({ productList, isLoading: false });
        } catch (error) {
            this.props.enqueueSnackbar(error.message, { variant: "error" });
        }
    }

    private onSubmit = async (values: ProductListFormValues, formikHelpers: FormikHelpers<ProductListFormValues>): Promise<boolean> => {
        try {
            const productList = await Api.updateProductList(this.state.productList!.id, {
                type: values.type,
                mode: values.mode,
                name: values.name,
                title: values.title,
                subtitle: values.subtitle,
                slug: values.slug,
                is_active: values.is_active,
                active_from: values.active_from ? DateUtils.format(values.active_from, DateFormat.apiDateTime) : null,
                active_to: values.active_to ? DateUtils.format(values.active_to, DateFormat.apiDateTime) : null,
                description: values.description,
                image: values.image,
                image_title: values.image_title,
                image_mobile: values.image_mobile,
                image_mobile_title: values.image_mobile_title,
                og_title: values.og_title,
                og_description: values.og_description,
                og_image: values.og_image,
                group_by_category: values.group_by_category,
                filters: values.filters.map(f => ProductListUtils.cleanFilter(f)),
                products: values.products,
                badge_id: values.badge?.id ?? null,
            });
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.productList.edit.onSubmit.success" }), { variant: "success" });
            this.setState({ productList });
            if (productList) {
                this.props.onUpdate({ __typename: "ProductList", id: productList.id, name: productList.name });
            }
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.productList.edit.onSubmit.error" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
        }
        return false;
    };

    private getBreadcrumb = () => {
        return (
            <Breadcrumbs>
                <Link to={Path.productListList} color="inherit">
                    {I18n.formatMessage({ id: "pages.productList.edit.breadcrumb" })}
                </Link>
                {this.state.productList?.name && <ActiveBreadcrumbItem aria-current="page">{this.state.productList.name}</ActiveBreadcrumbItem>}
            </Breadcrumbs>
        );
    };

    public render() {
        const { isLoading, productList } = this.state;

        if (isLoading) {
            return <Loading breadcrumb={this.getBreadcrumb()} />;
        }

        if (!productList) {
            return <Redirect to={Path.productListList} />;
        }

        const initialValues: ProductListFormValues = {
            type: productList.type,
            mode: productList.mode,
            name: productList.name,
            title: productList.title,
            subtitle: productList.subtitle,
            slug: productList.slug,
            is_active: productList.is_active,
            active_from: productList.active_from ? DateUtils.format(productList.active_from, DateFormat.input) : null,
            active_to: productList.active_to ? DateUtils.format(productList.active_to, DateFormat.input) : null,
            image: productList.image,
            image_mobile: productList.image_mobile,
            og_image: productList.og_image,
            products: productList.products.map(p => p.id),
            filters: productList.filters.map(f => {
                return { ...f, created_at: f.created_at ? DateUtils.format(f.created_at, DateFormat.input) : null };
            }),
            group_by_category: productList.group_by_category,
            badge: productList.badge,
        };

        return (
            <div className="modal-grey-background">
                <Formik initialValues={initialValues} onSubmit={this.onSubmit} validate={productListValidator} validateOnBlur={false} enableReinitialize={true}>
                    {props => (
                        <>
                            <ModalProductListForm
                                formType={FormType.edit}
                                productListType={productList.type}
                                formProps={props}
                                productList={productList!}
                                onCancel={this.props.onModalClose}
                            />
                            <Prompt when={props.dirty} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props)} />
                        </>
                    )}
                </Formik>
                <Box mt="40px" />
            </div>
        );
    }
}

export default withSnackbar(ModalProductListPage);
