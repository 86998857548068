import { gql } from "@apollo/client";
import { siteFragment } from "../fragments/siteFragment";

export const listSitesQuery = gql`
    query listSites {
        sites {
            ...Site
        }
    }
    ${siteFragment}
`;
