import { gql } from "@apollo/client";
import { galleryFragment } from "../fragments/galleryFragment";

export const getGalleryQuery = gql`
    query getGallery($id: ID!) {
        galleries(first: 1, filters: { id: $id }) {
            data {
                ...Gallery
            }
        }
    }
    ${galleryFragment}
`;
