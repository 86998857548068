import { gql } from "@apollo/client";
import { galleryImageFragment } from "./galleryImageFragment";

export const galleryFragment = gql`
    fragment Gallery on Gallery {
        id
        title
        handle
        images {
            ...GalleryImage
        }
        image_count
        updated_at
    }
    ${galleryImageFragment}
`;
