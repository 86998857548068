import { gql } from "@apollo/client";

export const orderInvoiceFragment = gql`
    fragment OrderInvoice on OrderInvoice {
        type
        invoice_number
        url
        url_remote
        created_at
        invoiced_at
    }
`;
