import { gql } from "@apollo/client";

export const textContentListItemFragment = gql`
    fragment TextContentListItem on TextContent {
        id
        is_visible
        is_active
        active_from
        updated_at
        title
        author
        category {
            id
            title
        }
    }
`;
