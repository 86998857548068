import React from "react";
import { ActiveBreadcrumbItem } from "@bigfish/admin-ui/components";
import { ApiError } from "Api/ApiError";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import { I18n } from "Src/i18n/I18n";
import { GalleryForm, GalleryFormValues } from "./GalleryForm";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { PageLayout } from "Components/PageLayout";
import { Link } from "Components/Link";
import { Breadcrumbs } from "Components/Breadcrumbs";
import { Api } from "Api/Api";
import Prompt from "Components/Prompt";

type Props = RouteComponentProps & WithSnackbarProps;

type State = {
    isCreateSucceed: boolean;
};

class GalleryCreatePage extends React.Component<Props, State> {
    public readonly state: State = {
        isCreateSucceed: false,
    };

    private onSubmit = async (values: GalleryFormValues, formikHelpers: FormikHelpers<GalleryFormValues>, isRedirectHandled?: boolean): Promise<boolean> => {
        try {
            await Api.createGallery({ title: values.title, images: values.images, handle: values.handle || undefined });
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.gallery.create.succeed" }), { variant: "success" });
            if (!isRedirectHandled) {
                this.setState({ isCreateSucceed: true });
            }
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.gallery.create.failed" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
            return false;
        }
    };

    public render() {
        if (this.state.isCreateSucceed) {
            return <Redirect to={Path.galleryList} />;
        }
        const initialValues: GalleryFormValues = {
            title: "",
            handle: "",
            images: [],
        };

        return (
            <PageLayout
                breadcrumb={
                    <Breadcrumbs>
                        <Link to={Path.galleryList} color="inherit">
                            {I18n.formatMessage({ id: "pages.galleryList.breadcrumb" })}
                        </Link>
                        <ActiveBreadcrumbItem aria-current="page">{I18n.formatMessage({ id: "pages.gallery.create.breadcrumb" })}</ActiveBreadcrumbItem>
                    </Breadcrumbs>
                }
            >
                <Formik initialValues={initialValues} onSubmit={this.onSubmit} validateOnBlur={false}>
                    {props => (
                        <>
                            <GalleryForm title={I18n.formatMessage({ id: "pages.gallery.create.title" })} formType={FormType.create} formProps={props} />
                            <Prompt when={props.dirty} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props, true)} />
                        </>
                    )}
                </Formik>
            </PageLayout>
        );
    }
}

export default withRouter(withSnackbar(GalleryCreatePage));
