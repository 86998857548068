import React from "react";

export const WelcomeIllustration = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="300" height="252" fill="none" viewBox="0 0 300 252">
            <circle cx="127.5" cy="112.5" r="94.5" fill="#fff"></circle>
            <path
                fill="#A9AFDB"
                d="M246.9 121.4c-.2-1.1-1.2-1.9-2.4-1.7-1.1.2-1.9 1.2-1.7 2.4.3 1.9.7 8-1.4 10.5-.4.5-1 .9-1.7 1l.2-11.6c0-1.7-1.3-3.1-3-3.1s-3 1.4-3 3.1l.5 22c-.7-.1-1.3-.4-1.8-1-2.1-2.5-1.7-8.5-1.4-10.5.2-1.1-.6-2.2-1.7-2.4-1.1-.2-2.2.6-2.4 1.7-.1 1-1.4 9.5 2.4 13.8 1.3 1.5 3 2.4 5.1 2.5l.2 9.5h4.5l.3-20c2-.2 3.7-1 4.9-2.5 3.8-4.3 2.6-12.8 2.4-13.7zM52.7 140c-.1-.7-.8-1.2-1.5-1.1-.7.1-1.2.8-1.1 1.5.2 1.2.4 5.1-.9 6.7-.3.3-.6.5-1.1.6l.1-7.4c0-1.1-.9-1.9-1.9-1.9-1.1 0-1.9.9-1.9 2l.3 14c-.4-.1-.8-.3-1.1-.6-1.3-1.6-1.1-5.4-.9-6.7.1-.7-.4-1.4-1.1-1.5-.7-.1-1.4.4-1.5 1.1-.1.6-.9 6 1.5 8.8.8 1 1.9 1.5 3.2 1.6l.1 6.1h2.9l.2-12.7c1.3-.1 2.4-.6 3.1-1.6 2.4-2.9 1.7-8.3 1.6-8.9z"
            ></path>
            <path fill="url(#paint0_linear)" d="M150 251.2c82.8 0 150-23.8 150-53.1S232.8 145 150 145 0 168.8 0 198.1s67.2 53.1 150 53.1z"></path>
            <path
                fill="#082954"
                d="M153.6 52.6c-1.6-.7-3.2-1.1-4.7-1.1h-1c-1.5.1-3.1.4-4.7 1.1-6.5 3-4.6 11-4.4 13.9.2 2.9 2.8 5.8 4.2 6.1 0 0 .4-8.1 1.1-7.7.7.3.8-1.1 3.4-3.2.2-.2.5-.4.8-.5.3.2.6.3.8.5 2.6 2.1 2.8 3.5 3.4 3.2.7-.4 1.1 7.7 1.1 7.7 1.4-.3 4-3.2 4.2-6.1.4-2.9 2.3-10.9-4.2-13.9z"
            ></path>
            <path fill="#C6CDE7" d="M114.9 93.6l17.1-5.2-7.2-5.9-7.7 3.3-2.2 7.8z"></path>
            <path fill="#D9DFF3" d="M121.6 69.2l-2.9-1.6-8.7 20.8c-1.1 2.2.1 4.9 2.5 5.5 2 .5 4-.7 4.6-2.6l4.5-22.1z"></path>
            <path
                fill="#D9DFF3"
                d="M121.6 69.2l1.7-1.4c.9-.7 1.6-1.7 2.1-2.7l.8-1.9c.2-.4-.1-1-.5-1.1-.4-.1-.8 0-1.1.4l-1.3 1.7 1-8.4c.1-.4-.2-.7-.6-.7h-.2c-.3 0-.5.2-.6.5l-.7 5 .1-5.8c0-.4-.3-.7-.6-.7h-.2c-.3 0-.6.3-.6.6l-.3 6.1-.7-4.9c0-.4-.4-.6-.8-.5h-.1c-.3.1-.4.3-.4.6l.2 5.2-.5-3.1c-.1-.4-.6-.7-.9-.4-.2.1-.3.3-.3.5l.8 11.5 3.7-.5z"
            ></path>
            <path fill="#C6CDE7" d="M165.5 186.4s-.1 5.1 0 5.6-2 3.5-2.9 3.4c-.9-.1-2.6-.4-2.8-.7-.2-.3-.1-1 0-1.6.1-.6.7-6.2.7-6.4 0-.2 4.1-1.1 5-.3z"></path>
            <path
                fill="url(#paint1_linear)"
                d="M162.2 191.7c-1.1.6-1.7 2.3-1.8 2.4-.1 0-.4 0-.4-.2s0-.8-.1-.8c-.2 0-.4 1-.4 2.4s-.5 4.7 0 5.1c.3.3 3.4 1.9 3.5 2.1.1.2-.1 1.7.2 2 .3.2.9.8 1.3.9.4.1 3.2.3 4.5 0 1.3-.3 2.9-.9 3-1.1 0-.2-.3-3.9-.8-4.3-.4-.4-3.4-4.5-3.6-4.9-.2-.4-.4-1.3-.4-1.8s-.5-1.7-1.3-1.9c-1-.4-2.9-.3-3.7.1z"
            ></path>
            <path fill="#C6CDE7" d="M134.4 189.3s0 3-.1 3.5 2 3.5 2.9 3.4c.9-.1 2.6-.4 2.8-.7.2-.3.1-1 0-1.6-.1-.6-.6-4-.6-4.3-.1-.3-4.1-1-5-.3z"></path>
            <path
                fill="#082954"
                d="M137.1 123.7c-1.2 4.4-3.5 19.4-3.5 26.7 0 7.3-.7 25.9-.7 28.7 0 2.8-.3 9.9.4 10.2.7.4 2.4 1.5 4.7 1.1 2.3-.4 2.9-1.8 3-3.2.1-1.3.6-11 1.2-14.7.6-3.7 2.5-10.9 3.8-15.5 1.3-4.6 4.3-13.9 5.1-13.8.8.1 5.7 20.4 6.1 21.5.4 1.2 1.2 17.5 1.3 18.9 0 1.4 0 2.7.9 3.2s3.5 1.9 4.9 2c1.4.1 2.3-2.1 2.6-2.8.2-.6.7-22.5.5-28.5-.2-6 .3-17.2 0-20.8-.3-3.6-1.2-11.5-1.7-13.1-.7-1.5-28.6.1-28.6.1z"
            ></path>
            <path
                fill="url(#paint2_linear)"
                d="M137.6 192.5c1.1.6 1.7 2.3 1.8 2.4.1 0 .4 0 .4-.2s0-.8.1-.8c.2 0 .4 1 .4 2.4s.5 4.7 0 5.1c-.3.3-3.4 1.9-3.5 2.1-.1.2.1 1.7-.2 2-.3.2-.9.8-1.3.9-.5 0-3.2.3-4.5 0-1.3-.3-2.9-.9-3-1.1 0-.2.3-3.9.8-4.3.4-.4 3.4-4.5 3.6-4.9.2-.4.4-1.3.4-1.8s.5-1.7 1.3-1.9c1-.4 2.9-.3 3.7.1z"
            ></path>
            <path fill="#C6CDE7" d="M168.8 106.6l1.5 6.6-5.7 17.9.7 8.1s10.3-19.3 10.8-25.3-.6-9.4-.6-9.4l-6.7 2.1z"></path>
            <path
                fill="url(#paint3_linear)"
                d="M121.6 83.1c-.7.6-1.3 1.2-1.7 2.1-1.1 2-.4 4.6.8 6.5.3.4.6.8 1 1.1.6.4 1.3.5 1.9.4 1.1-.1 2.8-.4 3.9-.6.9-.1 1.1-.1 1.9-.3 2.2-.5 4.1-.5 6.3-.7.4 2.8-1.3 31.7-.3 32.7 1 1 11.6 4.8 16.9 5.1 5.3.3 14.6-1.5 15.2-2.9.5-1.4-2.6-28.3-2.2-28.3.4 0 .5 8.5 4.5 10.7.7.4 6.3 0 7.1-.9.7-.8-2.5-12.1-4-15.9-1.3-3.4-1.9-7-3.4-10.3-.8-1.7-1.9-3.4-3.5-4.5-2.9-2.1-6.3-1.9-9.6-2.7-2.4-.6-5.2 0-7.6-.2-1.3-.1-5.3.2-5.3.2-1.1.1-2.4.4-3.5.6-1.3.2-2.7.8-4 1.2-5.7 2.1-9.9 2.9-14.4 6.7z"
            ></path>
            <path
                fill="url(#paint4_linear)"
                d="M176.8 106.2c.5.6.8 1.4.8 2.1-.8.7-1.8 1.2-2.9 1.5-1.8.5-3.8.4-5.7.4-.1 0-.3-.3-.3-.4-.1-.1-.2-.3-.3-.4-.2-.3-.3-.6-.4-.9-.1-.3-.1-.6-.1-.9 0-.2 0-.4.3-.5.4-.1.9 0 1.3-.1.5 0 .9 0 1.4-.1.9-.1 1.8-.2 2.8-.3 1.1-.1 2.1-.2 3.1-.4z"
            ></path>
            <path
                fill="url(#paint5_linear)"
                d="M121.1 88.4c.3 1.7.9 3.5 2.7 4.7-1 .5-2.2.8-3.4 1 .1-.1.1-.4-.1-.5.1.1.1.3.2.4-.6-.5-1.1-1-1.5-1.5-.3-.4-.4-.9-.5-1.4-.5-2.2-1-4.5-.2-6.6.1-.3.2-.6.5-.8.3-.3.8-.4 1.3-.4.3 0 1.2.1 1.1.5-.1.2-.2.5-.3.7-.1.2-.1.5-.2.7-.1.5-.1 1 0 1.5.2.6.3 1.2.4 1.7z"
            ></path>
            <path fill="#C6CDE7" d="M143.3 67.9c-.1 1.3-.4 7.6.1 8.6.5.9 1.4 3.3 4.9 3.2 3.5-.1 5.8-3.6 6-4 .2-.4-.2-5.5-.2-6.3 0-.9-4.7-2.9-6.6-2.8-1.9.1-4.1.1-4.2 1.3z"></path>
            <path fill="#D9DFF3" d="M148.8 75.8c3.4 0 6.3-3.5 6.7-6.9l.6-5.3c.4-3.5-2.3-6.5-5.8-6.5h-3c-3.5 0-6.2 3.1-5.8 6.5l.6 5.3c.4 3.4 3.3 6.9 6.7 6.9z"></path>
            <path
                fill="#D9DFF3"
                d="M141.8 68.9c.884 0 1.6-1.209 1.6-2.7s-.716-2.7-1.6-2.7c-.884 0-1.6 1.209-1.6 2.7s.716 2.7 1.6 2.7zM155.7 68.9c.884 0 1.6-1.209 1.6-2.7s-.716-2.7-1.6-2.7c-.884 0-1.6 1.209-1.6 2.7s.716 2.7 1.6 2.7z"
            ></path>
            <path
                fill="#082954"
                d="M147.9 60.8l-.9.6c-.5.3-1 .5-1.5.6-1.7.6-3.6.9-5.4.9.3-1.8 1.2-3.4 2.3-4.9.2-.3.5-.6.8-.9.4-.4.8-.6 1.3-.9 1.8-1 3.9-1.4 5.8-.9.4.1.7.2 1 .5.2.1.3.3.4.5.1.1.2.4.1.5 0 .1-.1.1-.1.2-.1.1-.1.2-.2.3-.1.2-.3.4-.4.5-.3.4-.6.7-.9 1-.3.3-.7.6-1 .9-.4.3-.7.6-1.1.9-.1.1-.2.1-.2.2z"
            ></path>
            <defs>
                <linearGradient id="paint0_linear" x1="150" x2="150" y1="121.073" y2="204.389" gradientUnits="userSpaceOnUse">
                    <stop offset="0.245" stopColor="#CFD3F2"></stop>
                    <stop offset="0.932" stopColor="#F6F9FF"></stop>
                </linearGradient>
                <linearGradient id="paint1_linear" x1="168.839" x2="159.6" y1="195.405" y2="205.883" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#70C4ED"></stop>
                    <stop offset="0.516" stopColor="#70C4ED"></stop>
                    <stop offset="0.775" stopColor="#019EDA"></stop>
                    <stop offset="0.984" stopColor="#019EDA"></stop>
                </linearGradient>
                <linearGradient id="paint2_linear" x1="130.801" x2="140.075" y1="196.687" y2="205.329" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#70C4ED"></stop>
                    <stop offset="0.516" stopColor="#70C4ED"></stop>
                    <stop offset="0.872" stopColor="#019EDA"></stop>
                    <stop offset="0.984" stopColor="#019EDA"></stop>
                </linearGradient>
                <linearGradient id="paint3_linear" x1="126.196" x2="178.448" y1="82.124" y2="121.768" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#70C4ED"></stop>
                    <stop offset="0.984" stopColor="#019EDA"></stop>
                </linearGradient>
                <linearGradient id="paint4_linear" x1="167.93" x2="177.637" y1="108.196" y2="108.196" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#70C4ED"></stop>
                    <stop offset="0.244" stopColor="#70C4ED"></stop>
                    <stop offset="0.872" stopColor="#019EDA"></stop>
                    <stop offset="0.984" stopColor="#019EDA"></stop>
                </linearGradient>
                <linearGradient id="paint5_linear" x1="117.938" x2="123.793" y1="88.696" y2="88.696" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#70C4ED"></stop>
                    <stop offset="0.24" stopColor="#70C4ED"></stop>
                    <stop offset="0.872" stopColor="#019EDA"></stop>
                    <stop offset="0.984" stopColor="#019EDA"></stop>
                </linearGradient>
            </defs>
        </svg>
    );
};
