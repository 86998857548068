import React from "react";
import { FormControl, FormControlLabel, Grid, TextField, Typography, Box, Checkbox } from "@bigfish/admin-ui/core";
import { Field, FieldProps, FormikProps } from "formik";
import { PageCard } from "@bigfish/admin-ui/components";
import { I18n } from "I18n/I18n";
import { CustomFormHelperText } from "Components/CustomFormHelperText";
import { Product, ProductGratisInput } from "Api/graphql/admin/types";
import ProductImagesSortList from "Pages/Product/ProductImagesSortList";
import { ApiUtils, ProductImageInputWithGenericUrl } from "Utils/ApiUtils";

type Props = {
    product: Product;
    formProps: FormikProps<ProductGratisInput>;
};

export const GeneralTab = (props: Props) => {
    const [images, setImages] = React.useState<ProductImageInputWithGenericUrl[]>(ApiUtils.convertProductImagesIntoInput(props.product.images));

    return (
        <>
            <Box mt="30px" />
            <PageCard.Heading title={I18n.formatMessage({ id: "common.form.basicData" })} />
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Typography color="textSecondary" gutterBottom>
                        {I18n.formatMessage({ id: "pages.product.form.tabs.general.name" })}
                    </Typography>
                    <Typography variant="body2">{props.product.name}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography color="textSecondary" gutterBottom>
                        {I18n.formatMessage({ id: "pages.product.form.tabs.general.id" })}
                    </Typography>
                    <Typography variant="body2">{props.product.id}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography color="textSecondary" gutterBottom>
                        {I18n.formatMessage({ id: "pages.product.form.tabs.general.lfdnr" })}
                    </Typography>
                    <Typography variant="body2">{props.product.lfdnr}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography color="textSecondary" gutterBottom>
                        {I18n.formatMessage({ id: "pages.product.form.tabs.general.ean" })}
                    </Typography>
                    <Typography variant="body2">{props.product.ean.join(", ")}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography color="textSecondary" gutterBottom>
                        {I18n.formatMessage({ id: "pages.product.form.tabs.general.categories" })}
                    </Typography>
                    <Typography variant="body2">
                        {props.product.categories.length > 0
                            ? props.product.categories.map(c => {
                                  return (
                                      <li className="product-form-category-list-item" key={c.id}>
                                          {c.category_path.map(c => c.name).join(" / ")}
                                      </li>
                                  );
                              })
                            : "-"}
                    </Typography>
                </Grid>
            </Grid>

            <Box mt="30px" />

            <PageCard.Heading title={I18n.formatMessage({ id: "pages.product.form.imagesHeading" })} />
            <ProductImagesSortList
                value={images}
                axis="xy"
                distance={10}
                onChange={(newImages: Array<ProductImageInputWithGenericUrl | null>) => setImages(newImages.filter(i => i !== null) as ProductImageInputWithGenericUrl[])}
                noSelect
            />

            <Box mt="30px" />

            <PageCard.Heading title={I18n.formatMessage({ id: "common.form.settings" })} />

            <Typography variant="body1" component="h3" color="textSecondary">
                {I18n.formatMessage({ id: "pages.product.form.statusLabel" })}
            </Typography>

            <Field name="is_force_active">
                {({ field, meta }: FieldProps) =>
                    field.value !== undefined && (
                        <Box mt="15px" ml="10px">
                            <FormControl error={meta.touched && !!meta.error}>
                                <FormControlLabel control={<Checkbox {...field} checked={field.value} />} label={I18n.formatMessage({ id: "pages.product.form.isActiveLabel" })} />
                            </FormControl>
                            <CustomFormHelperText meta={meta} />
                        </Box>
                    )
                }
            </Field>

            <Box mt="30px" />

            <Typography variant="body1" component="h3" color="textSecondary">
                {I18n.formatMessage({ id: "pages.productGratis.form.activeDate" })}
            </Typography>

            <Box mt="25px" ml="10px">
                <Field name="active_from">
                    {({ field, meta }: FieldProps) => (
                        <TextField
                            id="active-from-date"
                            label={I18n.formatMessage({ id: "pages.productGratis.grid.column.active_from" })}
                            type="datetime-local"
                            variant="outlined"
                            helperText={meta.error}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                max: props.formProps.values.active_to || undefined,
                            }}
                            {...field}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                props.formProps.setTouched({ ...props.formProps.touched, active_from: true });
                                field.onChange(e);
                            }}
                            error={!!meta.error}
                        />
                    )}
                </Field>

                <Field name="active_to">
                    {({ field, meta }: FieldProps) => (
                        <TextField
                            id="active-to-date"
                            style={{ marginLeft: "30px" }}
                            label={I18n.formatMessage({ id: "pages.productGratis.grid.column.active_to" })}
                            type="datetime-local"
                            variant="outlined"
                            helperText={meta.error}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                min: props.formProps.values.active_from || undefined,
                            }}
                            {...field}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                props.formProps.setTouched({ ...props.formProps.touched, active_to: true });
                                field.onChange(e);
                            }}
                            error={!!meta.error}
                        />
                    )}
                </Field>
            </Box>
        </>
    );
};
