import { gql } from "@apollo/client";
import { productBrandListItemFragment } from "../fragments/productBrandListItemFragment";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";

export const listProductBrandsQuery = gql`
    query listProductBrands($filters: ProductBrandFilters, $sortBy: ProductBrandSort, $first: Int!, $page: Int) {
        listProductBrands(first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            data {
                ...ProductBrandListItem
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${productBrandListItemFragment}
    ${paginatorInfoFragment}
`;
