import { gql } from "@apollo/client";
import { productVariantFragment } from "../fragments/productVariantFragment";

export const updateProductVariantMutation = gql`
    mutation updateProductVariant($id: Int!, $input: ProductVariantInput!, $csrf: String) {
        updateProductVariant(id: $id, input: $input, csrf: $csrf) {
            ...ProductVariant
        }
    }
    ${productVariantFragment}
`;
