/* eslint-disable @typescript-eslint/no-explicit-any */
import { TypeSingleFilterValue, TypeSingleSortInfo } from "@inovua/reactdatagrid-community/types";
import { OrderDirection } from "Api/graphql/admin/types";
import { DateUtils, DateFormat } from "Utils/DateUtils";
import { DataGridParams, Filterable, Sortable } from "./UrlQueryParser";

/**
 * Filter value of DateFilter type column
 */
export type DateFilterValue = {
    from?: string;
    to?: string;
};

export type IntegerRangeFilterValue = {
    start?: string;
    end?: string;
};

export type DataSourceProps<DataType> = {
    currentData: DataType[];
    filterValue?: TypeSingleFilterValue[] | null;
    groupBy: any;
    limit: number;
    skip: number;
    sortInfo: any | null;
};

export type DataSource<DataType> = { data: DataType[]; count: number };

export type FilterValue = {
    name: string;
    type: string;
    operator: string;
};

export class DataGridUtils {
    public static readonly DEFAULT_LIMIT = 20;
    public static readonly DEFAULT_PAGE_SIZES = [25, 50, 100];

    public static getOrderDirection(direction: 1 | 0 | -1): OrderDirection | undefined {
        switch (direction) {
            case 1:
                return OrderDirection.ASC;
            case -1:
                return OrderDirection.DESC;
            case 0:
            default:
                return undefined;
        }
    }

    public static getDirection(direction: OrderDirection | undefined): 1 | 0 | -1 {
        switch (direction) {
            case OrderDirection.ASC:
                return 1;
            case OrderDirection.DESC:
                return -1;
            default:
                return 0;
        }
    }

    public static urlQueryToDataGridParams<SortOrder extends Sortable, Filter extends Filterable>(
        params: DataGridParams<SortOrder, Filter>,
        sortFieldToSortOrder?: { [key: string]: string },
        filterValues?: FilterValue[] | null
    ): { defaultFilterValue?: TypeSingleFilterValue[] | null | undefined; sortInfo: TypeSingleSortInfo | null; limit: number; skip: number } {
        const limit = params.limit || DataGridUtils.DEFAULT_LIMIT;
        const skip = limit * ((params.page || 1) - 1);

        const result: { defaultFilterValue: TypeSingleFilterValue[] | null | undefined; sortInfo: TypeSingleSortInfo | null; limit: number; skip: number } = {
            defaultFilterValue: undefined,
            sortInfo: null,
            limit,
            skip,
        };

        if (params.sortBy && sortFieldToSortOrder) {
            const id = sortFieldToSortOrder[params.sortBy.field];
            result.sortInfo = { id, name: id, dir: DataGridUtils.getDirection(params.sortBy.direction) };
        }

        if (!filterValues) {
            return result;
        }
        result.defaultFilterValue = filterValues.map(f => {
            let value = params.filters && params.filters[f.name] !== "" ? params.filters[f.name] : "";
            if (value === "" && f.type === "select") {
                value = null;
            } else if (typeof value === "string" && value && f.type === "select" && value.split(",").length > 1) {
                value = value.split(",");
            }
            return { ...f, value };
        });
        return result;
    }

    public static dateFilterToDateRangeFilter(dateFilterValue?: DateFilterValue | null): DateFilterValue | undefined {
        if (!dateFilterValue || (!dateFilterValue.from && !dateFilterValue.to)) {
            return undefined;
        }
        return {
            from: dateFilterValue.from ? DateUtils.format(DateUtils.parseISO(dateFilterValue.from), DateFormat.apiDateTime) : undefined,
            to: dateFilterValue.to ? DateUtils.format(DateUtils.parseISO(dateFilterValue.to), DateFormat.apiDateTime) : undefined,
        };
    }
}
