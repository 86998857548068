import { createStyles } from "@bigfish/admin-ui/core";

export const dashboardStyles = () =>
    createStyles({
        container: {
            paddingTop: "10%",
        },
        content: {
            textAlign: "center",
        },
        title: {
            fontSize: 48,
            lineHeight: "56px",
            fontWeight: 700,
        },
        subtitle: {
            fontSize: 14,
        },
    });
