import React from "react";
import { Breadcrumbs as MuiBreadcrumbs } from "@bigfish/admin-ui/core";
import { Icon } from "@bigfish/admin-ui/core";

type Props = {
    children: React.ReactNode;
};

export const Breadcrumbs = (props: Props) => {
    return (
        <MuiBreadcrumbs color="inherit" separator={<Icon className="fa fa-chevron-right" style={{ fontSize: 14, opacity: "60%" }} />}>
            {props.children}
        </MuiBreadcrumbs>
    );
};
