import { gql } from "@apollo/client";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";
import { giftCardOrderSimpleFragment } from "../fragments/giftCardOrderSimpleFragment";

export const listGiftCardOrdersQuery = gql`
    query listGiftCardOrders($filters: GiftCardOrderFilters, $sortBy: GiftCardOrderSort, $first: Int!, $page: Int) {
        listGiftCardOrders(first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            data {
                ...GiftCardOrderSimple
            }
            aggregations {
                sum_grand_total
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${giftCardOrderSimpleFragment}
    ${paginatorInfoFragment}
`;
