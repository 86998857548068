import { gql } from "@apollo/client";

export const createContentMutation = gql`
    mutation createContent($input: CreateContentInput!, $csrf: String) {
        createContent(input: $input, csrf: $csrf) {
            ... on TextContent {
                id
            }
        }
    }
`;
