import { gql } from "@apollo/client";
import { galleryListItemFragment } from "../fragments/galleryListItemFragment";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";

export const galleriesQuery = gql`
    query galleries($filters: GalleryFilters, $sortBy: GalleryOrder, $first: Int!, $page: Int) {
        galleries(first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            data {
                ...GalleryListItem
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${galleryListItemFragment}
    ${paginatorInfoFragment}
`;
