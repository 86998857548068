import React from "react";
import { Api } from "Api/Api";
import { TypeRowProps } from "@inovua/reactdatagrid-community/types";
import { CategoryListItem, PermissionType } from "Api/graphql/admin/types";
import { ApiError } from "Api/ApiError";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { Path } from "Utils/Path";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { I18n } from "I18n/I18n";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import { AuthSelectors } from "Redux/selectors/authSelectors";
import { ApplicationState } from "Redux/rootReducer";
import { connect } from "react-redux";
import { withStyles, WithStyles } from "@bigfish/admin-ui/core";
import { dataGridStyles } from "@bigfish/admin-ui/styles";
import { DataSource } from "Components/DataGrid/DataGridUtils";
import { CustomTypeColumn } from "Utils/DataGridUtils";
import { Constants } from "Utils/Constants";

type ReduxProps = {
    permissions: PermissionType[];
    isSuperadmin: boolean;
};

type Props = ReduxProps & RouteComponentProps & WithSnackbarProps & WithStyles<typeof dataGridStyles>;

class CategoryListDataGrid extends React.Component<Props> {
    private getColumns = (): CustomTypeColumn[] => {
        return [
            { name: "id", header: I18n.formatMessage({ id: "pages.categoryList.grid.column.id" }), width: 132, filterDelay: Constants.filterDelayMS },
            { name: "title", header: I18n.formatMessage({ id: "pages.categoryList.grid.column.title" }), defaultFlex: 1, filterDelay: Constants.filterDelayMS },
        ];
    };

    private dataSource = async (): Promise<DataSource<CategoryListItem>> => {
        try {
            const result = await Api.categories();
            return { data: result, count: result.length };
        } catch (error) {
            if (error instanceof ApiError) {
                this.props.enqueueSnackbar(error.message, { variant: "error" });
            }
        }
        return { data: [], count: 0 };
    };

    private onRowClick = (rowProps: TypeRowProps, event: MouseEvent): void => {
        if (!this.props.isSuperadmin && !this.props.permissions.includes(PermissionType.content_update)) return undefined;

        const path = Path.categoryEdit(rowProps.data.id);
        if (event.ctrlKey || event.metaKey) {
            window.open(path, "_blank");
        } else {
            this.props.history.push(path);
        }
    };

    render() {
        return (
            // Using ReactDataGrid because don't want url params
            <ReactDataGrid
                showColumnMenuTool={false}
                idProperty="id"
                loadingText={I18n.formatMessage({ id: "common.loading" })}
                emptyText={I18n.formatMessage({ id: "components.dataGrid.emptyText" })}
                i18n={I18n.dataGrid}
                renderColumnFilterContextMenu={() => null}
                resizable={false}
                enableSelection={false}
                showEmptyRows={true}
                showHoverRows={true}
                showZebraRows={false}
                activateRowOnFocus={false}
                columnUserSelect={true}
                showHeader={true}
                rowHeight={50}
                showCellBorders="horizontal"
                style={{ minHeight: 800 }}
                dataSource={this.dataSource}
                columns={this.getColumns()}
                onRowClick={this.onRowClick}
                rowStyle={{ cursor: "pointer" }}
                activeCell={null}
            />
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { permissions: AuthSelectors.getPermissions(state.auth), isSuperadmin: AuthSelectors.isSuperadmin(state.auth) };
};

export default withRouter(withSnackbar(connect(mapStateToProps)(withStyles(dataGridStyles, { withTheme: true })(CategoryListDataGrid))));
