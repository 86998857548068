import { gql } from "@apollo/client";

export const adminListItemFragment = gql`
    fragment AdminListItem on Admin {
        id
        name
        email
        is_active
        last_login
        roles {
            title
        }
    }
`;
