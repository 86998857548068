import { gql } from "@apollo/client";
import { userAddressSmallFragment } from "./userAddressSmallFragment";
import { subscriptionItemFragment } from "./subscriptionItemFragment";
import { subscriptionOrderFragment } from "./subscriptionOrderFragment";

export const subscriptionFragment = gql`
    fragment Subscription on OrderSubscription {
        id
        user {
            firstname
            lastname
            email
        }
        billing_address {
            ...UserAddressSmall
        }
        shipping_address {
            ...UserAddressSmall
        }
        created_at
        updated_at
        paused_at
        deleted_at
        notified_at
        notified_at_sms
        date_next
        time_window_date
        time_window_interval
        time_window_changed
        time_window_shipping_method {
            name
        }
        items {
            ...SubscriptionItem
        }
        orders {
            ...SubscriptionOrder
        }
        deleted_at
    }
    ${userAddressSmallFragment}
    ${subscriptionItemFragment}
    ${subscriptionOrderFragment}
`;
