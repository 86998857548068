import { gql } from "@apollo/client";
import { categoryFragment } from "../fragments/categoryFragment";

export const updateCategoryMutation = gql`
    mutation updateCategory($id: ID!, $input: UpdateCategoryInput!, $csrf: String) {
        updateCategory(id: $id, input: $input, csrf: $csrf) {
            ...Category
        }
    }
    ${categoryFragment}
`;
