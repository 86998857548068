import React from "react";

import { Field, FieldProps, Formik } from "formik";
import { withSnackbar, WithSnackbarProps } from "notistack";

import { PageCard } from "@bigfish/admin-ui/components";
import { Box, Button, TextField } from "@bigfish/admin-ui/core";

import { MailTemplate } from "Api/graphql/admin/types";
import { Api } from "Api/Api";
import { Form } from "Components/Form";
import { I18n } from "Src/i18n/I18n";
import { Validator } from "Utils/Validator";

interface TestMailProps {
    allEmail?: boolean;
    mailTemplate?: MailTemplate;
}

type Props = TestMailProps & WithSnackbarProps;

type TestMailValues = {
    recipients: string;
};

const TestMail: React.FC<Props> = ({ allEmail, mailTemplate, enqueueSnackbar }) => {
    const onSubmit = async (values: TestMailValues): Promise<void> => {
        const emails = values.recipients.split(" ");
        const validEmails = emails.every(recipient => Validator.emailRE.test(recipient));
        if (!validEmails) {
            enqueueSnackbar(I18n.formatMessage({ id: "enums.validatorMessage.invalidEmail" }), { variant: "error" });
            return;
        }
        if (!allEmail && mailTemplate) {
            const success = await Api.testMailTemplate({
                id: mailTemplate.id,
                input: { subject: mailTemplate.subject, content: mailTemplate.content },
                recipients: emails,
            });
            if (success) {
                enqueueSnackbar(I18n.formatMessage({ id: "pages.mailTemplate.sendTestMail.succeed" }), { variant: "success" });
            } else {
                enqueueSnackbar(I18n.formatMessage({ id: "pages.mailTemplate.sendTestMail.error" }), { variant: "error" });
            }
        } else {
            const successAll = await Api.testMailTemplateAll({
                recipients: emails,
            });
            if (successAll) {
                enqueueSnackbar(I18n.formatMessage({ id: "pages.mailTemplate.sendTestMail.succeed" }), { variant: "success" });
            } else {
                enqueueSnackbar(I18n.formatMessage({ id: "pages.mailTemplate.sendTestMail.error" }), { variant: "error" });
            }
        }
    };

    const initialValues: TestMailValues = {
        recipients: "",
    };

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize={true}>
            {props => (
                <form onSubmit={props.handleSubmit}>
                    <PageCard.Container>
                        <PageCard.Heading title={I18n.formatMessage({ id: `pages.mailTemplate.form.${allEmail ? "testMailTitleAll" : "testMailTitle"}` })} />
                        <Field name="recipients">
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    type="text"
                                    label={I18n.formatMessage({ id: "pages.admin.form.email.label" })}
                                    fullWidth
                                    variant="outlined"
                                    required
                                    helperText={Form.getHelperText(meta, I18n.formatMessage({ id: "common.requiredTestMail" }))}
                                    error={meta.touched && !!meta.error}
                                    {...field}
                                />
                            )}
                        </Field>
                        <Box mt="20px" />
                        <Button type="submit" variant="contained" color="secondary" disabled={!props.dirty || !props.isValid || props.isSubmitting}>
                            {I18n.formatMessage({ id: "common.send" })}
                        </Button>
                    </PageCard.Container>
                </form>
            )}
        </Formik>
    );
};

export default withSnackbar(TestMail);
