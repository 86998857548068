import { ActiveBreadcrumbItem, PageCard, TitleBar } from "@bigfish/admin-ui/components";
import { Api } from "Api/Api";
import { ApiError } from "Api/ApiError";
import { Admin, RoleListItem } from "Api/graphql/admin/types";
import { Form, FormType } from "Components/Form";
import { Formik, FormikHelpers } from "formik";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React from "react";
import { I18n } from "Src/i18n/I18n";
import { AdminForm, AdminFormValues } from "./AdminForm";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "Utils/Path";
import { Loading } from "Components/Loading";
import { PageLayout } from "Components/PageLayout";
import { Link } from "Components/Link";
import { Breadcrumbs } from "Components/Breadcrumbs";
import { ApplicationState } from "Redux/rootReducer";
import { connect, DispatchProp } from "react-redux";
import Prompt from "Components/Prompt";
import { AuthActions } from "Redux/actions/authActions";
import { FunctionalButton } from "Components/FunctionalButton";
import { FunctionConfirmModal } from "Components/FunctionConfirmModal";
import { DeleteForever } from "@bigfish/admin-ui/icons";

type RouteParams = {
    id?: string;
};

type ReduxProps = { admin: Admin };

type Props = ReduxProps & DispatchProp & WithSnackbarProps & RouteComponentProps<RouteParams>;

type State = {
    isLoading: boolean;
    admin?: Admin | null;
    selectableRoles: RoleListItem[];
    isDeleteDialogVisible: boolean;
};

class AdminEditPage extends React.Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        admin: null,
        selectableRoles: [],
        isDeleteDialogVisible: false,
    };

    public async componentDidMount(): Promise<void> {
        try {
            const id = Number.parseInt(this.props.match.params.id!, 10);
            const admin = await Api.getAdmin(id);
            const { data: selectableRoles } = await Api.listRoles({ first: 9999 });
            this.setState({ admin, selectableRoles, isLoading: false });
        } catch (error) {
            this.props.enqueueSnackbar(error.message, { variant: "error" });
            this.props.history.push(Path.adminList);
        }
    }

    private onSubmit = async (values: AdminFormValues, formikHelpers: FormikHelpers<AdminFormValues>): Promise<boolean> => {
        try {
            const admin = await Api.updateAdmin(Number.parseInt(this.props.match.params.id!, 10), {
                email: values.email,
                name: values.name,
                phone: `${values.phone}`,
                is_active: values.is_active,
                roles: values.roles,
                store_id: values.store_id,
            });
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.admin.edit.succeed" }), { variant: "success" });
            this.setState({ admin });

            if (this.props.admin.id === admin.id) {
                this.props.dispatch(AuthActions.updateAdmin(admin));
            }
            return true;
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.admin.edit.failed" }), { variant: "error" });
            if (error instanceof ApiError) {
                Form.submitFailed(formikHelpers, error);
            }
        }
        return false;
    };

    private onDeleteButtonClick = (): void => {
        this.setState({ isDeleteDialogVisible: true });
    };

    private onDeleteDialogClose = (): void => {
        this.setState({ isDeleteDialogVisible: false });
    };

    private onDeleteClick = async (): Promise<void> => {
        try {
            await Api.deleteAdmin(this.state.admin!.id);
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.admin.edit.deleteSucceed" }), { variant: "success" });
            this.setState({ admin: null });
        } catch (error) {
            this.props.enqueueSnackbar(I18n.formatMessage({ id: "pages.admin.edit.deleteError" }), { variant: "error" });
        }
    };

    private getBreadcrumb = () => {
        return (
            <Breadcrumbs>
                <Link to={Path.adminList} color="inherit">
                    {I18n.formatMessage({ id: "pages.adminList.breadcrumb" })}
                </Link>
                <ActiveBreadcrumbItem aria-current="page">{I18n.formatMessage({ id: "pages.admin.edit.breadcrumb" })}</ActiveBreadcrumbItem>
            </Breadcrumbs>
        );
    };

    public render() {
        if (this.state.isLoading) {
            return <Loading breadcrumb={this.getBreadcrumb()} />;
        }
        if (!this.state.admin) {
            return <Redirect to={Path.adminList} />;
        }

        const initialValues: AdminFormValues = {
            name: this.state.admin.name,
            email: this.state.admin.email,
            phone: this.state.admin.phone,
            is_active: this.state.admin.is_active,
            store_id: this.state.admin.store?.id ?? null,
            roles: this.state.admin.roles.map(r => r.name),
        };

        return (
            <PageLayout breadcrumb={this.getBreadcrumb()}>
                <TitleBar title={this.state.admin.name} rightButtonsComponent={<FunctionalButton onClick={this.onDeleteButtonClick} icon={<DeleteForever />} />} />
                <PageCard.Container>
                    <Formik initialValues={initialValues} onSubmit={this.onSubmit} validateOnBlur={false} enableReinitialize={true}>
                        {props => (
                            <>
                                <AdminForm
                                    isMe={this.props.admin.id === this.state.admin!.id}
                                    admin={this.state.admin}
                                    selectableRoles={this.state.selectableRoles}
                                    formType={FormType.edit}
                                    formProps={props}
                                />
                                <Prompt when={props.dirty} hasSaveButton={props.isValid} onSave={() => this.onSubmit(props.values, props)} />
                            </>
                        )}
                    </Formik>
                </PageCard.Container>
                <FunctionConfirmModal
                    title={I18n.formatMessage({ id: "pages.admin.edit.deleteModal.title" })}
                    description={I18n.formatMessage({ id: "pages.admin.edit.deleteModal.description" })}
                    isVisible={this.state.isDeleteDialogVisible}
                    onClose={this.onDeleteDialogClose}
                    onFunctionClick={this.onDeleteClick}
                    leftButtonLabel={I18n.formatMessage({ id: "common.cancel" })}
                    rightButtonLabel={I18n.formatMessage({ id: "common.delete" })}
                />
            </PageLayout>
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { admin: state.auth.admin! };
};

export default withSnackbar(withRouter(connect(mapStateToProps)(AdminEditPage)));
