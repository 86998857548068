import { gql } from "@apollo/client";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";
import { orderListItemFragment } from "../fragments/orderListItemFragment";

export const listOrdersQuery = gql`
    query listOrders($filters: OrderFilters, $sortBy: OrderSort, $first: Int!, $page: Int) {
        listOrders(first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            data {
                ...OrderListItem
            }
            aggregations {
                sum_grand_total
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${orderListItemFragment}
    ${paginatorInfoFragment}
`;
