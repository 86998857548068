import React from "react";
import { createIntl, createIntlCache, MessageDescriptor } from "react-intl";
import { localeHu as messages } from "./locales/hu";

/** Type for fixing autocomplete and also accept string-templates */
type LiteralUnion<T extends U, U = string> = T | (U & { _?: never });

export class I18n {
    private static readonly cache = createIntlCache();
    private static readonly instance = createIntl(
        {
            locale: "hu-HU",
            messages,
        },
        I18n.cache
    );

    /**
     * Returns a span, injected html message
     *
     * reason: Intl.formattedHTMLMessage not work
     * @param messageDescriptor FormattedMessage.MessageDescriptor
     * @param values ?{ [key: string]: MessageValue }
     */
    public static asHtml(
        descriptor: MessageDescriptor & { id: LiteralUnion<keyof typeof messages> },
        values?: Record<string, string | number | boolean | Date | null | undefined> | undefined
    ) {
        return <span dangerouslySetInnerHTML={{ __html: I18n.instance.formatMessage(descriptor, values) }} />;
    }

    public static formatMessage(
        descriptor: MessageDescriptor & { id: LiteralUnion<keyof typeof messages> },
        values?: Record<string, string | number | boolean | Date | null | undefined> | undefined
    ) {
        return I18n.instance.formatMessage(descriptor, values);
    }

    public static readonly formatCurrency = (value: number): string => {
        return I18n.instance.formatNumber(value, {
            currency: "HUF",
            useGrouping: true,
            style: "currency",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });
    };

    public static readonly dataGrid = {
        pageText: I18n.formatMessage({ id: "components.dataGrid.pageText" }),
        ofText: I18n.formatMessage({ id: "components.dataGrid.ofText" }),
        perPageText: I18n.formatMessage({ id: "components.dataGrid.perPageText" }),
        showingText: I18n.formatMessage({ id: "components.dataGrid.showingText" }),
        clearAll: I18n.formatMessage({ id: "components.dataGrid.clearAll" }),
        clear: I18n.formatMessage({ id: "components.dataGrid.clear" }),
        showFilteringRow: I18n.formatMessage({ id: "components.dataGrid.showFilteringRow" }),
        hideFilteringRow: I18n.formatMessage({ id: "components.dataGrid.hideFilteringRow" }),
        dragHeaderToGroup: I18n.formatMessage({ id: "components.dataGrid.dragHeaderToGroup" }),
        disable: I18n.formatMessage({ id: "components.dataGrid.disable" }),
        enable: I18n.formatMessage({ id: "components.dataGrid.enable" }),
        sortAsc: I18n.formatMessage({ id: "components.dataGrid.sortAsc" }),
        sortDesc: I18n.formatMessage({ id: "components.dataGrid.sortDesc" }),
        unsort: I18n.formatMessage({ id: "components.dataGrid.unsort" }),
        group: I18n.formatMessage({ id: "components.dataGrid.group" }),
        ungroup: I18n.formatMessage({ id: "components.dataGrid.ungroup" }),
        lockStart: I18n.formatMessage({ id: "components.dataGrid.lockStart" }),
        lockEnd: I18n.formatMessage({ id: "components.dataGrid.lockEnd" }),
        unlock: I18n.formatMessage({ id: "components.dataGrid.unlock" }),
        columns: I18n.formatMessage({ id: "components.dataGrid.columns" }),
        contains: I18n.formatMessage({ id: "components.dataGrid.contains" }),
        startsWith: I18n.formatMessage({ id: "components.dataGrid.startsWith" }),
        endsWith: I18n.formatMessage({ id: "components.dataGrid.endsWith" }),
        notContains: I18n.formatMessage({ id: "components.dataGrid.notContains" }),
        neq: I18n.formatMessage({ id: "components.dataGrid.neq" }),
        eq: I18n.formatMessage({ id: "components.dataGrid.eq" }),
        notEmpty: I18n.formatMessage({ id: "components.dataGrid.notEmpty" }),
        empty: I18n.formatMessage({ id: "components.dataGrid.empty" }),
        lt: I18n.formatMessage({ id: "components.dataGrid.lt" }),
        lte: I18n.formatMessage({ id: "components.dataGrid.lte" }),
        gt: I18n.formatMessage({ id: "components.dataGrid.gt" }),
        gte: I18n.formatMessage({ id: "components.dataGrid.gte" }),
        "calendar.todayButtonText": I18n.formatMessage({ id: "components.dataGrid.calendar.todayButtonText" }),
        "calendar.clearButtonText": I18n.formatMessage({ id: "components.dataGrid.calendar.clearButtonText" }),
        "calendar.okButtonText": I18n.formatMessage({ id: "components.dataGrid.calendar.okButtonText" }),
        "calendar.cancelButtonText": I18n.formatMessage({ id: "components.dataGrid.calendar.cancelButtonText" }),
    };
}
