import React, { useCallback, useMemo, useState } from "react";
import { I18n } from "I18n/I18n";
import { Button, Icon, Menu, MenuItem, Typography } from "@bigfish/admin-ui/core";
import { ProductListListItem, ProductListMinimal, ProductListType } from "Api/graphql/admin/types";
import { createModal } from "Components/createModal";
import uniqWith from "lodash/uniqWith";
import ProductListListDataGrid from "Pages/ProductListList/ProductListListDataGrid";
import { Constants } from "Utils/Constants";
import ModalProductListCreatePage from "Pages/ModalProductList/ModalProductListCreatePage";
import "./styles.css";

const ProductListAddDialogModal = createModal({ modalWidth: 1280 });
const CreateProductListDialog = createModal(Constants.getModalPageStyles());

type Props = {
    isSingleSelect?: boolean;
    initialSelectedProductLists: ProductListMinimal[];
    onClose: () => void;
    onAddClick: (selectedProductLists: Array<ProductListMinimal | ProductListListItem>) => void;
    onlyActive?: boolean;
};

export const ProductListAddDialog = ({ isSingleSelect, initialSelectedProductLists, onClose, onAddClick, onlyActive }: Props) => {
    const [selectedProductLists, setSelectedProductLists] = useState(initialSelectedProductLists);
    const selectedProductListIds = useMemo(() => selectedProductLists.map(container => container.id), [selectedProductLists]);
    const [newProductListType, setNewProductListType] = useState<ProductListType | null>(null);
    const [anchorElement, setAnchorElement] = useState<(EventTarget & HTMLButtonElement) | null>(null);
    const [listKey, setListKey] = useState<number>(0);

    const handleAddProductLists = (containersToAdd: ProductListListItem[]) => {
        isSingleSelect ? setSelectedProductLists(containersToAdd) : setSelectedProductLists(uniqWith([...selectedProductLists, ...containersToAdd], (p1, p2) => p1.id === p2.id));
    };

    const handleRemoveProductLists = useCallback(
        (containersToRemove: ProductListListItem[]) => {
            setSelectedProductLists(selectedProductLists.filter(container => !containersToRemove.some(containerToRemove => containerToRemove.id === container.id)));
        },
        [selectedProductLists]
    );

    const onNewClick = (productListType: ProductListType): void => {
        setNewProductListType(productListType);
        setAnchorElement(null);
    };

    const onAddMenuClose = () => {
        setAnchorElement(null);
    };

    return (
        <ProductListAddDialogModal
            title={I18n.formatMessage({ id: "components.productListAddDialog.title" })}
            leftButtonsComponent={
                <div className="product-list-add-dialog-left-buttons-component">
                    <div>
                        <Button
                            aria-controls="add-menu"
                            aria-haspopup="true"
                            startIcon={<Icon className="fa fa-plus-circle" />}
                            variant="contained"
                            size="medium"
                            color="secondary"
                            onClick={event => {
                                setAnchorElement(event.currentTarget);
                            }}
                        >
                            {I18n.formatMessage({ id: "components.productListAddDialog.createProductList" })}
                        </Button>
                        <Menu id="product-list-list-page-menu" anchorEl={anchorElement} keepMounted open={!!anchorElement} onClose={onAddMenuClose}>
                            <MenuItem onClick={() => onNewClick(ProductListType.offer)}>{I18n.formatMessage({ id: "pages.productListList.add.offer" })}</MenuItem>
                            <MenuItem onClick={() => onNewClick(ProductListType.promotion)}>{I18n.formatMessage({ id: "pages.productListList.add.promotion" })}</MenuItem>
                        </Menu>
                    </div>
                    {!isSingleSelect && (
                        <Typography variant="subtitle2">{I18n.formatMessage({ id: "common.selectedItems" }, { selectedItems: selectedProductListIds.length })}</Typography>
                    )}
                </div>
            }
            rightButtonsComponent={
                <>
                    <Button variant="outlined" color="primary" onClick={onClose} style={{ marginRight: "20px" }}>
                        {I18n.formatMessage({ id: "common.cancel" })}
                    </Button>
                    <Button variant="contained" color="primary" onClick={() => onAddClick(selectedProductLists)}>
                        {I18n.formatMessage({ id: "common.add" })}
                    </Button>
                </>
            }
            open
            onClose={onClose}
        >
            <ProductListListDataGrid
                key={listKey}
                selectedProductListIds={selectedProductListIds}
                onAddProductLists={handleAddProductLists}
                onRemoveProductLists={handleRemoveProductLists}
                disableRowClick
                isSingleSelect={isSingleSelect}
                minWidth={500}
                onlyActive={onlyActive}
            />

            {newProductListType && (
                <CreateProductListDialog title={null} open onClose={() => setNewProductListType(null)}>
                    <ModalProductListCreatePage
                        productListType={newProductListType}
                        onModalClose={() => {
                            setNewProductListType(null);
                            setListKey(listKey + 1);
                        }}
                    />
                </CreateProductListDialog>
            )}
        </ProductListAddDialogModal>
    );
};
