import { gql } from "@apollo/client";

export const textContentFragment = gql`
    fragment TextContent on TextContent {
        id
        url
        title
        lead
        lead_image
        meta_image
        content
        meta_title
        meta_keywords
        meta_description
        author
        is_active
        is_visible
        active_from
        active_to
        created_at
        updated_at
        last_published_at
        category {
            id
            title
            url
        }
        documents {
            id
            file_name
        }
    }
`;
