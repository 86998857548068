import { gql } from "@apollo/client";
import { subscriptionFragment } from "../fragments/subscriptionFragment";

export const getSubscriptionByIdQuery = gql`
    query getSubscriptionById($id: Int!) {
        orderSubscription(id: $id) {
            ...Subscription
        }
    }
    ${subscriptionFragment}
`;
