import { gql } from "@apollo/client";

export const permissionFragment = gql`
    fragment Permission on Permission {
        id
        name
        title
        group
    }
`;
