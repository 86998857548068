import { gql } from "@apollo/client";
import { websiteNotificationListItemFragment } from "../fragments/websiteNotificationListItemFragment";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";

export const listWebsiteNotificationsQuery = gql`
    query listWebsiteNotifications($filters: WebsiteNotificationFilters, $sortBy: WebsiteNotificationSort, $first: Int!, $page: Int) {
        listWebsiteNotifications(first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            data {
                ...WebsiteNotificationListItem
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${websiteNotificationListItemFragment}
    ${paginatorInfoFragment}
`;
