import { gql } from "@apollo/client";
import { giftCardLogFragment } from "../fragments/giftCardLogFragment";

export const giftCardFragment = gql`
    fragment GiftCard on GiftCard {
        id
        value
        barcode
        expire_at
        mail_to
        sent_at
        log_sent_to {
            ...GiftCardLog
        }
    }
    ${giftCardLogFragment}
`;
