import { gql } from "@apollo/client";
import { successStoryListItemFragment } from "../fragments/successStoryListItemFragment";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";

export const successStoriesQuery = gql`
    query successStories($filters: SuccessStoryFilters, $sortBy: SuccessStorySort, $first: Int!, $page: Int) {
        successStories(first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            data {
                ...SuccessStoryListItem
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${successStoryListItemFragment}
    ${paginatorInfoFragment}
`;
