import { gql } from "@apollo/client";
import { productForMenuFragment } from "../fragments/productForMenuFragment";
import { paginatorInfoFragment } from "../fragments/paginatorInfoFragment";

export const listProductsForMenuQuery = gql`
    query listProductsForMenu($filters_base: [ProductFilterInput!], $filters: ProductFilters, $sortBy: ProductSort, $first: Int!, $page: Int) {
        listProducts(filters_base: $filters_base, first: $first, filters: $filters, sortBy: $sortBy, page: $page) {
            data {
                ...ProductForMenu
            }
            paginatorInfo {
                ...PaginatorInfo
            }
        }
    }
    ${productForMenuFragment}
    ${paginatorInfoFragment}
`;
