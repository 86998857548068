import { gql } from "@apollo/client";
import { badgeFragment } from "../fragments/badgeFragment";

export const updateBadgeMutation = gql`
    mutation updateBadge($id: Int!, $input: BadgeInput!, $csrf: String) {
        updateBadge(id: $id, input: $input, csrf: $csrf) {
            ...Badge
        }
    }
    ${badgeFragment}
`;
