import React from "react";
import { ImageUploadField, UploadedImage } from "@bigfish/admin-ui/components";
import { TextField } from "@bigfish/admin-ui/core";
import { I18n } from "I18n/I18n";
import { MediaLibraryHelper } from "Utils/MediaLibraryHelper";

type ImageValue = {
    url: string;
    caption: string | null;
    credit: string | null;
};

type Props = {
    id: string;
    name: string;
    imageFieldsVisible?: boolean;
    value: ImageValue;
    onChange: (image: ImageValue | null) => void;
};

export const ImageFieldSet = (props: Props) => {
    const onChange = (name: "caption" | "credit") => (event: React.ChangeEvent<HTMLInputElement>): void => {
        props.onChange({ ...props.value, [name]: event.currentTarget.value });
    };

    const onCloseClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        props.onChange(null);
    };

    const imageSubFields = {
        preview: (
            <label htmlFor={props.id}>
                <UploadedImage name={props.value.url} source={MediaLibraryHelper.getImageUrl(props.value.url)} onDelete={onCloseClick} />
            </label>
        ),
        caption: (
            <TextField
                type="text"
                label={I18n.formatMessage({ id: "pages.gallery.form.image.caption" })}
                fullWidth
                name="caption"
                variant="outlined"
                helperText={`${I18n.formatMessage({ id: "common.required" })}. ${I18n.formatMessage({ id: "common.maxCharacter" }, { max: 80 })}`}
                inputProps={{
                    maxLength: 80,
                }}
                value={props.value.caption}
                onChange={onChange("caption")}
            />
        ),
        alt: (
            <TextField
                type="text"
                label={I18n.formatMessage({ id: "pages.gallery.form.image.credit" })}
                fullWidth
                name="alt"
                variant="outlined"
                helperText={I18n.formatMessage({ id: "common.optional" })}
                value={props.value.credit}
                onChange={onChange("credit")}
            />
        ),
        imageFieldsVisible: true,
    };

    return <ImageUploadField id={props.id} name={props.name} {...imageSubFields} />;
};
