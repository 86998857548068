import React from "react";
import { I18n } from "I18n/I18n";
import UnControlledDataGrid from "Components/DataGrid/UCDataGrid";
import { CustomTypeColumn } from "Utils/DataGridUtils";
import { DataSource } from "Components/DataGrid/DataGridUtils";
import { SubscriptionItem } from "Api/graphql/admin/types";
import { TypeRowProps } from "@inovua/reactdatagrid-community/types";
import { Path } from "Utils/Path";

type Props = {
    items: SubscriptionItem[];
};

export const SubscriptionItems = (props: Props) => {
    const getColumns = (): CustomTypeColumn[] => {
        return [
            { name: "id", header: I18n.formatMessage({ id: "pages.subscription.form.items.column.id" }), defaultWidth: 100 },
            {
                name: "product",
                header: I18n.formatMessage({ id: "pages.subscription.form.items.column.product" }),
                defaultFlex: 1,
                minWidth: 336,
                render: ({ data }: { data: SubscriptionItem }) => {
                    return <div>{data.product.name}</div>;
                },
            },
            { name: "quantity", header: I18n.formatMessage({ id: "pages.subscription.form.items.column.quantity" }), defaultWidth: 125 },
        ];
    };

    return (
        <UnControlledDataGrid<SubscriptionItem>
            rowHeight={50}
            style={{ minHeight: 42 + props.items.length * 50 }}
            dataSource={async (): Promise<DataSource<SubscriptionItem>> => {
                return { data: props.items, count: props.items.length };
            }}
            columns={getColumns()}
            activeCell={null}
            pagination={false}
            rowLink={(rowProps: TypeRowProps): string => {
                return Path.productEdit(rowProps.data.product.id);
            }}
        />
    );
};
